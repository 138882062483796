import {GetProps, View, XStack} from 'tamagui';

import {Label3} from '@/components/texts/Label';
import {ComparisonNumber} from '@/modules/orders/components/ChangeStrategy/ComparisonNumber';

export type AllocationRatioCompare = {
  key: string;
  title: string;
  color: GetProps<typeof View>['backgroundColor'];
  currRatio?: number;
  newRatio?: number;
};

type Props = {
  item: AllocationRatioCompare;
};

export const ComparisonStrategyAllocationsRow = ({item}: Props) => (
  <XStack justifyContent="space-between">
    <XStack gap="$2" alignItems="center" maxWidth="45%">
      <View height="$3" width="$3" borderRadius="$full" backgroundColor={item.color} />
      <Label3>{item.title}</Label3>
    </XStack>
    <XStack minWidth="25%" maxWidth="45%" justifyContent="space-between">
      <XStack flexGrow={1}>
        <ComparisonNumber value={item.currRatio} />
      </XStack>
      <XStack flexGrow={1} justifyContent="flex-end">
        <ComparisonNumber value={item.newRatio} />
      </XStack>
    </XStack>
  </XStack>
);
