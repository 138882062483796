import {Modal} from '@/components/modal/Modal';
import {Label3} from '@/components/texts/Label';
import {Paragraph2} from '@/components/texts/Paragraph';
import {AccountActivityList} from '@/modules/account-activity/components/AccountActivityList';
import useInfiniteScroll from '@/modules/account-activity/hooks/useInfiniteScroll';
import {InfoBox} from '@/modules/orders/components/InfoBox';
import {useGetCustomerInboxQuery} from '@/store/queries/customersApi';
import {InboxDocumentResponse} from '@/types/api/customers.v2';
import {useTranslate} from '@tolgee/react';
import {YStack} from 'tamagui';

type DocumentsListProps = {
  customerId: string;
  category: string;
  source: string;
};

export default function DocumentsList({customerId, category, source}: DocumentsListProps) {
  type TransformedInboxDocumentResponse = InboxDocumentResponse & {
    id: string;
  };

  const {
    data: documents,
    isFetching,
    isLoading,
    loadMore,
    refetch,
    allDataFetched,
  } = useInfiniteScroll<TransformedInboxDocumentResponse>(useGetCustomerInboxQuery, {
    customerId,
    limit: 40,
    category,
    sourceFilter: source,
  });

  const {t} = useTranslate();

  return (
    <YStack flex={1} gap="$2">
      <Modal>
        <Modal.Trigger>
          <InfoBox
            withBackground
            wrapperProps={{hoverStyle: {opacity: 0.8}, marginHorizontal: '$4'}}
          >
            <Label3 color="$primary">{t('DOCUMENTS.HINT_TITLE')}</Label3>
          </InfoBox>
        </Modal.Trigger>
        <Modal.Content>
          <Modal.Title variant="medium" textAlign="center" padding="$2">
            {t('DOCUMENTS.HINT_BODY.TITLE')}
          </Modal.Title>
          <Paragraph2 color="$neutral400" padding="$2">
            {t('DOCUMENTS.HINT_BODY.TEXT_1_1')}
            <Paragraph2 color="$neutral400">{t('DOCUMENTS.HINT_BODY.TEXT_1_2')}</Paragraph2>
            {t('DOCUMENTS.HINT_BODY.TEXT_1_3')}
          </Paragraph2>
          <Paragraph2 color="$neutral400" variant="medium" padding="$1">
            <Paragraph2 color="$primary" variant="medium">
              1.
            </Paragraph2>{' '}
            {t('DOCUMENTS.HINT_BODY.POINT_1')}
          </Paragraph2>
          <Paragraph2 color="$neutral400" variant="medium" padding="$1">
            <Paragraph2 color="$primary" variant="medium">
              2.
            </Paragraph2>{' '}
            {t('DOCUMENTS.HINT_BODY.POINT_2')}
          </Paragraph2>
          <Paragraph2 color="$neutral400" variant="medium" padding="$1">
            <Paragraph2 color="$primary" variant="medium">
              3.
            </Paragraph2>{' '}
            {t('DOCUMENTS.HINT_BODY.POINT_3')}
          </Paragraph2>
          <Paragraph2 color="$neutral400" padding="$2">
            {t('DOCUMENTS.HINT_BODY.TEXT_2')}
          </Paragraph2>
        </Modal.Content>
      </Modal>
      <AccountActivityList
        documentsResponse={documents}
        isFetching={isFetching}
        onRefresh={refetch}
        onLoadMore={loadMore}
        customerId={customerId}
      />
    </YStack>
  );
}
