import {ProductBadge} from '@/components/buttons/ProductBadge';
import {Label3} from '@/components/texts/Label';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {WithLabel} from '@/components/texts/WithLabel';
import {Skeleton} from '@/components/views/Skeleton';
import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {useGetCustomerAccountQuery} from '@/store/queries/customersApi';
import {useInvestmentStrategiesQuery} from '@/store/queries/referenceApi';
import {isThemeCustomer} from '@/types/isThemeCustomer';
import {useTranslate} from '@tolgee/react';
import {YStack} from 'tamagui';

type CustomerSummaryInformationProps = {
  customerId: string;
  internalTransfer?: boolean;
  amount?: number;
};

export function CustomerSummaryInformation({
  customerId,
  internalTransfer = false,
  amount,
}: CustomerSummaryInformationProps) {
  const {t} = useTranslate();
  const {data: customer, isFetching: isCustomerFetching} = useGetCustomerAccountQuery(customerId);
  const {data: investmentStrategies, isFetching} = useInvestmentStrategiesQuery();
  const {getByCode} = useRefdataLocalizable();

  const currentInvestmentStrategy =
    !isThemeCustomer(customer) &&
    customer?.currentInvestmentStrategy &&
    getByCode(investmentStrategies, customer?.currentInvestmentStrategy)?.label;

  return (
    <Skeleton width="100%" height={140}>
      {!isFetching && !isCustomerFetching && (
        <YStack gap="$4">
          {customer?.displayName && (
            <WithLabel
              header={
                internalTransfer
                  ? t('ORDER.INTERNAL_ASSETS_TRANSFER.FROM')
                  : t('ORDER_SUMMARY.ACCOUNT-NAME')
              }
              horizontal
            >
              {customer?.displayName}
            </WithLabel>
          )}
          {customer?.product && (
            <WithLabel header={t('ORDER_SUMMARY.PRODUCT')} horizontal>
              <ProductBadge product={customer?.product} textComponent={Label3} />
            </WithLabel>
          )}
          {currentInvestmentStrategy && (
            <WithLabel header={t('ORDER_SUMMARY.INVESTMENT-STRATEGY')} horizontal>
              {currentInvestmentStrategy}
            </WithLabel>
          )}
          {internalTransfer && amount && (
            <WithLabel header={t('ORDER_SUMMARY.INTERNAL_ASSETS_TRANSFER.AMOUNT-TITLE')} horizontal>
              <LocalizedNumbers value={amount} textComponent={Label3} />
            </WithLabel>
          )}
        </YStack>
      )}
    </Skeleton>
  );
}
