import {MINUTE, baseApi} from '@/store/queries/baseApi';
import {
  ChildAccountCreationOrderRequest,
  ChildAccountCreationOrderResponse,
  InvestmentAccountCreationOrderRequest,
  InvestmentAccountCreationOrderResponse,
  OrderListResponse,
  OrderResponse,
} from '@/types/api/orders';

const BASE_PATH = 'orders/v2';

const extendedOrdersApi = baseApi.injectEndpoints({
  endpoints: build => ({
    getValidOrders: build.query<OrderListResponse, void>({
      query: () => {
        const params = new URLSearchParams();
        params.append('limit', '100');
        params.append('offset', '0');
        params.append('status', 'SUBMITTED');
        params.append('status', 'CREATED');
        params.append('status', 'REJECTED');
        return {url: BASE_PATH, params};
      },
      keepUnusedDataFor: 5 * MINUTE,
      providesTags: ['ORDERS'],
    }),
    createUserOrder: build.mutation<
      InvestmentAccountCreationOrderResponse | ChildAccountCreationOrderResponse,
      AccountCreationOrderRequest
    >({
      query: data => ({
        url: BASE_PATH,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['ORDERS'],
    }),
    patchOrder: build.mutation<OrderResponse, {orderId: string; payload: string}>({
      query: ({orderId, payload}) => ({
        url: `${BASE_PATH}/${orderId}`,
        method: 'PATCH',
        body: payload,
        headers: {
          'content-type': 'application/json-patch+json',
        },
      }),
      invalidatesTags: ['ORDERS'],
    }),
  }),
  overrideExisting: false,
});

export type AccountCreationOrderRequest =
  | Required<InvestmentAccountCreationOrderRequest>
  | Required<ChildAccountCreationOrderRequest>;

export const {useGetValidOrdersQuery, useCreateUserOrderMutation, usePatchOrderMutation} =
  extendedOrdersApi;
