export const DocumentTypes: Record<string, 'PDF' | 'JPEG' | 'PNG'> = {
  PDF: 'PDF',
  JPEG: 'JPEG',
  PNG: 'PNG',
};

export const VALID_MIMES: Set<string> = new Set(['application/pdf', 'image/jpeg', 'image/png']);

export const VALID_MIMES_WITHOUT_PDF: Set<string> = new Set(['image/jpeg', 'image/png']);

export const POR_DOCUMENT_TYPES = [
  'ID_CARD',
  'INTERNET_BILL',
  'REGISTRATION_CERT',
  'RESIDENCE_PERMIT',
  'TELEPHONE_BILL',
  'UTILITY_BILL',
] as const;
