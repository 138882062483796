import {Chart, Line, XAxis} from '@/components/skia';
import {WebGlFallback} from '@/components/skia/WebGlFallback';
import {Label3} from '@/components/texts/Label';
import {AnimatedHeightView} from '@/components/views/AnimatedHeightView';
import {Skeleton} from '@/components/views/Skeleton';
import {HomeChartBalance} from '@/modules/overview/components/home-chart/HomeChartBalance';
import {HomeChartDate} from '@/modules/overview/components/home-chart/HomeChartDate';
import {HomeChartPerformance} from '@/modules/overview/components/home-chart/HomeChartPerformance';
import {HomeChartPeriodSelect} from '@/modules/overview/components/home-chart/HomeChartPeriodSelect';
import {BalanceAndPerformance} from '@/modules/overview/components/PerformanceChartsLegend';
import {CrossAccSummaryResponse} from '@/types/api/apeiron';
import {GetTextLabelFunc, Point, SelectedPoint} from '@/types/skia';
import {useTranslate} from '@tolgee/react';
import React, {useCallback, useState} from 'react';
import {Platform} from 'react-native';
import {useSharedValue} from 'react-native-reanimated';
import {useMedia, useTheme, View, XStack, YStack} from 'tamagui';

type HomeChartProps = {
  filteredPoints: Point[] | undefined;
  balance: number;
  performance: number;
  performanceSummary: CrossAccSummaryResponse[] | undefined;
  setFilteredPoints: React.Dispatch<React.SetStateAction<Point[] | undefined>>;
  isLoading: boolean;
};

export function HomeChart({
  filteredPoints,
  balance,
  performance,
  performanceSummary,
  setFilteredPoints,
  isLoading,
}: HomeChartProps) {
  const theme = useTheme();
  const media = useMedia();
  const {t} = useTranslate();
  const selectedPoint = useSharedValue<BalanceAndPerformance | undefined>(undefined);
  const [axisLabel, updateAxisLabel] = useState<GetTextLabelFunc>();
  const handlePointSelected = useCallback(
    (selectedPoints: SelectedPoint[]) => {
      'worklet';

      const point = selectedPoints.at(0);

      if (!point) {
        selectedPoint.value = undefined;

        return;
      }
      selectedPoint.value = {
        time: point.x,
        balance: point.y,
        performance: point.extra,
      };
    },
    [selectedPoint]
  );

  return (
    <AnimatedHeightView>
      {isLoading ? (
        media.sm ? (
          <YStack gap="$2" padding="$4">
            <Skeleton color="onPrimary" width="25%" height={15} />
            <Skeleton color="onPrimary" width="50%" />
            <Skeleton color="onPrimary" width="35%" height={25} />
          </YStack>
        ) : (
          <XStack gap="$2" paddingHorizontal="$4">
            <View gap="$2" flexGrow={1}>
              <Skeleton width="50%" height={15} />
              <Skeleton width="75%" />
            </View>
            <View gap="$2" flexGrow={1}>
              <Skeleton width="50%" height={15} />
              <Skeleton width="75%" />
            </View>
            <XStack gap="$4">
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </XStack>
          </XStack>
        )
      ) : (
        <View width="100%">
          {media.sm ? (
            <View paddingHorizontal="$4" gap="$2" paddingTop="$4">
              <Label3 color="$white">{t('HOME.CURRENT-BALANCE')}</Label3>
              <HomeChartBalance legendValue={selectedPoint} balance={balance} />
              <HomeChartPerformance legendValue={selectedPoint} performanceValue={performance} />
              <HomeChartDate legendValue={selectedPoint} />
            </View>
          ) : (
            <View paddingHorizontal="$6" gap="$2">
              <XStack gap="$4" justifyContent="space-between" flexWrap="wrap">
                <View gap="$2" width={275}>
                  <Label3 color="$neutral500">{t('HOME.CURRENT-BALANCE')}</Label3>
                  <HomeChartBalance legendValue={selectedPoint} balance={balance} />
                </View>
                <View gap="$2" width={200}>
                  <Label3 color="$neutral500">{t('HOME.RATE-OF-RETURN')}</Label3>
                  <HomeChartPerformance
                    legendValue={selectedPoint}
                    performanceValue={performance}
                  />
                </View>
                <HomeChartPeriodSelect
                  performanceSummary={performanceSummary}
                  setFilteredPoints={setFilteredPoints}
                  variant="large"
                  updateAxisLabel={updateAxisLabel}
                />
              </XStack>
              <HomeChartDate legendValue={selectedPoint} />
            </View>
          )}
          {filteredPoints && filteredPoints.length > 1 && (
            <View height={media.sm ? 150 : 230} $gtSm={{paddingHorizontal: '$4'}}>
              <Chart
                onSelect={handlePointSelected}
                removeSelectActionOnEnd
                hapticEffect={Platform.OS === 'ios'}
                removeHorizontalOffset={media.sm}
                webGlFallback={<WebGlFallback />}
                theme={theme}
              >
                <Line
                  points={filteredPoints}
                  name="line-1"
                  color={media.sm ? theme.white.val : theme.primary.val}
                  activePoint={{
                    show: true,
                    borderColor: `${theme.primary.val}33`,
                  }}
                  clipCursorColor={media.sm ? theme.primaryPressed?.val : theme.neutral300?.val}
                />

                {axisLabel && (
                  <XAxis
                    position="bottom"
                    countTicks={4}
                    getTexLabel={axisLabel}
                    fontSize={12}
                    color={theme.neutral500.val}
                  />
                )}
              </Chart>
            </View>
          )}
          <HomeChartPeriodSelect
            performanceSummary={performanceSummary}
            setFilteredPoints={setFilteredPoints}
            variant="small"
          />
        </View>
      )}
    </AnimatedHeightView>
  );
}
