import {Card} from '@/components/cards/Card';
import {Heading1} from '@/components/texts/Heading';
import {Label2} from '@/components/texts/Label';
import {StrategyInfoFactCard} from '@/modules/interview/components/planner/strategy-info/StrategyInfoFactCard';
import {useTranslate} from '@tolgee/react';
import React, {PropsWithChildren} from 'react';
import {View, XStack, YStack} from 'tamagui';

export default function StrategyInfoMotive({}: PropsWithChildren) {
  const {t} = useTranslate();

  return (
    <Card padding="$6">
      <View justifyContent="space-between" flexDirection="row" flexWrap="wrap" gap="$3">
        <YStack minWidth={300} flex={1} paddingRight="$9" gap="$3">
          <Heading1 variant="medium">{t('PLANNER.MOTIVE.PRODUCT_HEADER.MOTIVE')}</Heading1>

          <Label2 color="$neutral400">{t('PLANNER.MOTIVE.DESCRIPTION')}</Label2>
        </YStack>

        <YStack minWidth={400} maxWidth="100%" flex={1} gap="$3">
          <StrategyInfoFactCard
            title={t('PLANNER.MOTIVE.STRATEGY-INFO.BLOCK_2.TITLE')}
            icon="flexibility"
            label={t('PLANNER.MOTIVE.STRATEGY-INFO.BLOCK_2.TEXT')}
          />

          <XStack gap="$3">
            <StrategyInfoFactCard
              title={t('PLANNER.MOTIVE.STRATEGY-INFO.BLOCK_3.TITLE')}
              icon="transaction-fee"
              label={t('PLANNER.MOTIVE.STRATEGY-INFO.BLOCK_3.TEXT')}
              containerProps={{
                flex: 1,
              }}
            />

            <StrategyInfoFactCard
              title={t('PLANNER.MOTIVE.STRATEGY-INFO.BLOCK_4.TITLE')}
              icon="service-fee"
              label={t('PLANNER.MOTIVE.STRATEGY-INFO.BLOCK_4.TEXT')}
              containerProps={{
                flex: 1,
              }}
            />
          </XStack>
        </YStack>
      </View>
    </Card>
  );
}
