import {useTranslate} from '@tolgee/react';
import {Href, useRouter} from 'expo-router';
import {useCallback, useEffect, useMemo, useRef} from 'react';
import {YStack} from 'tamagui';

import {Heading4} from '@/components/texts/Heading';
import {Skeleton} from '@/components/views/Skeleton';
import {useHasDabAccounts} from '@/hooks/useHasDabAccounts';
import {useHasUpvestAccounts} from '@/hooks/useHasUpvestAccounts';
import {useIsUpvest} from '@/hooks/useIsUpvest';
import {useUserFinishedOnboardingBefore} from '@/hooks/useUserFinishedOnboardingBefore';
import {SubmitFormRef} from '@/modules/interview/components/FinancialSituationForm';
import {InterviewContinueButton} from '@/modules/interview/components/InterviewContinueButton';
import {UserAgreementsForm} from '@/modules/onboarding/components/forms/agreements/UserAgreementsForm';
import {useCreateOrder} from '@/modules/onboarding/hooks/useCreateOrder';
import {useAppSelector} from '@/store';
import {Planner} from '@/store/reducers/onboarding/state';
import {Product} from '@/types/fixedTypes/customers.v2/Product';

type Props = {
  product: Product;
  plannerState?: Planner;
  href: Href;
  orderId?: string;
  childId?: string;
  isSecondGuardian?: boolean;
};

export const UserAgreementsScreen = ({
  orderId,
  childId,
  product,
  plannerState,
  isSecondGuardian,
  href,
}: Props) => {
  const {t} = useTranslate();
  const isUpvest = useIsUpvest(product);
  const router = useRouter();
  const personalDetails = useAppSelector(state => state.onboarding.personalDetails);
  const {createOrder, isLoading, creating} = useCreateOrder(
    product,
    plannerState,
    orderId,
    isSecondGuardian
  );

  const {finishedBefore, isLoading: isUserFinishedOnboardingBeforeLoading} =
    useUserFinishedOnboardingBefore();

  const hasDabAccounts = useHasDabAccounts();

  const hasUpvestAccounts = useHasUpvestAccounts();

  const showIsSelf = useMemo(() => {
    return !finishedBefore || product === 'CHILD_ACCOUNT';
  }, [finishedBefore, product]);

  const showGinmonDocs = useMemo(() => {
    return !finishedBefore || (!hasDabAccounts && !hasUpvestAccounts) || product === 'VL_ACCOUNT';
  }, [finishedBefore, hasDabAccounts, hasUpvestAccounts, product]);

  const showSupplementaryAgreement = useMemo(() => {
    return isSecondGuardian || (product === 'CHILD_ACCOUNT' && !!personalDetails.anotherGuardian);
  }, [isSecondGuardian, product, personalDetails]);

  const ref = useRef<SubmitFormRef>(null);

  const onPress = useCallback(() => {
    ref.current?.submit();
  }, []);

  const handleSuccessOrderCreation = useCallback(() => {
    router.replace(href);
  }, [href, router]);

  const onSubmitValid = useCallback(async () => {
    await createOrder(handleSuccessOrderCreation, childId);
  }, [createOrder, handleSuccessOrderCreation, childId]);

  const shouldAutoSubmit = useMemo(() => {
    return (
      !creating &&
      !isLoading &&
      !isUserFinishedOnboardingBeforeLoading &&
      !showSupplementaryAgreement &&
      !showIsSelf &&
      !showGinmonDocs &&
      ((isUpvest && hasUpvestAccounts) || (!isUpvest && hasDabAccounts))
    );
  }, [
    creating,
    hasDabAccounts,
    hasUpvestAccounts,
    isLoading,
    isUpvest,
    isUserFinishedOnboardingBeforeLoading,
    showGinmonDocs,
    showIsSelf,
    showSupplementaryAgreement,
  ]);

  useEffect(() => {
    if (shouldAutoSubmit) {
      createOrder(handleSuccessOrderCreation, childId, true);
    }
  }, [childId, createOrder, handleSuccessOrderCreation, shouldAutoSubmit]);

  if (isLoading || isUserFinishedOnboardingBeforeLoading) {
    return (
      <YStack gap="$6">
        <Skeleton width="50%" height={50} />
        <Skeleton width="100%" height={400} />
        <Skeleton width="100%" height={50} />
      </YStack>
    );
  }

  return (
    <YStack gap="$6">
      {!shouldAutoSubmit && (
        <>
          <Heading4 variant="medium">{t('ACCOUNT_SETUP.TITLE_AGREEMENTS')}</Heading4>
          <UserAgreementsForm
            ref={ref}
            onValid={onSubmitValid}
            product={product}
            showSupplementaryAgreement={showSupplementaryAgreement}
            showIsSelf={showIsSelf}
            showGinmonDocs={showGinmonDocs}
            hasDabAccounts={hasDabAccounts}
            hasUpvestAccounts={hasUpvestAccounts}
            isUpvest={isUpvest}
          />
          <InterviewContinueButton isLoading={creating} onPress={onPress} />
        </>
      )}
    </YStack>
  );
};
