import {FetchBaseQueryArgs} from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

import {refreshTokens} from '@/providers/session-provider/utils';
import {Mutex} from 'async-mutex';
import {RootState} from '../reducers';

const mutex = new Mutex();
export const MINUTE = 60;

export const HOUR = 60 * MINUTE;

export const preparingHeader: FetchBaseQueryArgs['prepareHeaders'] = async (headers, api) => {
  const state = api.getState() as RootState;

  if (state.auth.accessToken) {
    headers.set('Authorization', `Bearer ${state.auth.accessToken}`);
  }

  return headers;
};

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.EXPO_PUBLIC_API_BASE_PATH,
  prepareHeaders: preparingHeader,
});

// TODO: In the future, such requests should be processed more specifically (at the place of the call).
//  For now, I think, we can leave it like this
const SILENT_URLS = ['interviews/v3'];

const customBaseQuery: BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  const silent = SILENT_URLS.includes(args.url);

  if (result.error && result.error.status === 401 && !silent) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      await refreshTokens();
      result = await baseQuery(args, api, extraOptions);
      release();
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: customBaseQuery,
  endpoints: () => ({}),
  tagTypes: [
    'USERS',
    'CUSTOMERS',
    'CUSTOMERS_ORDERS',
    'ORDERS',
    'TRANSACTIONS',
    'INTERVIEWS',
    'TAX_EXEMPTIONS',
    'MFA_METHODS',
  ],
});
