import {Accordion, styled} from 'tamagui';

export const AccordionTrigger = styled(Accordion.Trigger, {
  backgroundColor: 'transparent',
  borderWidth: 0,
  borderRadius: '$0.5',
  paddingHorizontal: '$2',
  paddingVertical: '$3',
  unstyled: true,
  variants: {
    selected: {
      false: {
        hoverStyle: {backgroundColor: '$neutral50'},
        pressStyle: {backgroundColor: '$neutral50'},
        cursor: 'pointer',
      },
    },
  } as const,
  defaultVariants: {
    selected: false,
  },
});
