import {useBottomSheetInternal} from '@gorhom/bottom-sheet';
import {useFocusEffect} from 'expo-router';
import {useCallback} from 'react';
import {NativeSyntheticEvent, Platform, TextInputFocusEventData} from 'react-native';

type Args = {
  onFocus?: (event: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  onBlur?: (event: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  modal?: boolean;
};

export const useTextInput = ({onFocus: argsOnFocus, onBlur: argsOnBlur, modal}: Args) => {
  const bottomSheetContext = useBottomSheetInternal(true);

  const keyboardEvent = useCallback(
    (value: boolean) => {
      const isBottomSheet = Platform.OS !== 'web' && modal;

      if (!bottomSheetContext || !isBottomSheet) return;

      const {shouldHandleKeyboardEvents} = bottomSheetContext;

      shouldHandleKeyboardEvents.value = value;
    },
    [bottomSheetContext, modal]
  );

  const focusEffect = useCallback(() => {
    return () => {
      keyboardEvent(false);
    };
  }, [keyboardEvent]);

  useFocusEffect(focusEffect);

  const onFocus = useCallback(
    (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
      keyboardEvent(true);

      if (argsOnFocus) {
        argsOnFocus(event);
      }
    },
    [keyboardEvent, argsOnFocus]
  );

  const onBlur = useCallback(
    (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
      keyboardEvent(false);

      if (argsOnBlur) {
        argsOnBlur(event);
      }
    },
    [argsOnBlur]
  );

  return {
    onFocus,
    onBlur,
    keyboardEvent,
  };
};
