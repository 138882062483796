import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {getDefaultLocale} from '@/providers/tolgee-translation-provider/utils';
import {ThemeSelectionOptionsType} from '@/types/ThemeSelectionOptions';
import {LanguagesType} from '@/types/languages';
import {Href} from 'expo-router';
import {AppState} from './state';

const initialState: AppState = {
  discreteMode: false,
  language: getDefaultLocale(),
  theme: 'system',
  lastAgreementPostponedAt: undefined,
  biometricEnabled: true,
  migrated: false,
  dismissedRecommendations: {
    mfa: false,
    topzins: false,
    savingsrate: false,
    friends: false,
  },
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setDiscreteMode: (state, action: PayloadAction<boolean>) => {
      state.discreteMode = action.payload;
    },
    toggleDiscreteMode: state => {
      state.discreteMode = !state.discreteMode;
    },
    setLanguage: (state, action: PayloadAction<LanguagesType>) => {
      state.language = action.payload;
    },
    setTheme: (state, action: PayloadAction<ThemeSelectionOptionsType>) => {
      state.theme = action.payload;
    },
    setLastAgreementPostponedAt: (
      state,
      action: PayloadAction<AppState['lastAgreementPostponedAt']>
    ) => {
      state.lastAgreementPostponedAt = action.payload;
    },
    setRedirectUrl: (state, action: PayloadAction<AppState['redirectUrl']>) => {
      state.redirectUrl = action.payload;
    },
    setBiometricEnabled: (state, action: PayloadAction<boolean>) => {
      state.biometricEnabled = action.payload;
    },
    setMigrated: state => {
      state.migrated = true;
    },
    setDeeplink: (state, action: PayloadAction<Href | undefined>) => {
      state.deeplink = action.payload;
    },
    setDismissedRecommendationsMfa: (state, action: PayloadAction<boolean>) => {
      state.dismissedRecommendations.mfa = action.payload;
    },
    setDismissedRecommendationsTopzins: (state, action: PayloadAction<boolean>) => {
      state.dismissedRecommendations.topzins = action.payload;
    },
    setDismissedRecommendationsSavingsrate: (state, action: PayloadAction<boolean>) => {
      state.dismissedRecommendations.savingsrate = action.payload;
    },
    setDismissedRecommendationsFriends: (state, action: PayloadAction<boolean>) => {
      state.dismissedRecommendations.friends = action.payload;
    },
  },
});

export const {
  setDiscreteMode,
  setLanguage,
  toggleDiscreteMode,
  setTheme,
  setLastAgreementPostponedAt,
  setRedirectUrl,
  setBiometricEnabled,
  setMigrated,
  setDeeplink,
  setDismissedRecommendationsMfa,
  setDismissedRecommendationsTopzins,
  setDismissedRecommendationsSavingsrate,
  setDismissedRecommendationsFriends,
} = appSlice.actions;

export default appSlice.reducer;
