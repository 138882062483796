import {useTranslate} from '@tolgee/react';
import {PropsWithChildren} from 'react';
import {Platform} from 'react-native';
import {ScrollView, YStack, useMedia} from 'tamagui';

import {Modal} from '@/components/modal/Modal';
import {Heading5} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {PerformanceDetailsData} from '@/modules/overview/components/PerformanceDetailsData';
import {PerformanceDetailsRow} from '@/modules/overview/components/PerformanceDetailsRow';
import {useGetPerformanceSummaryQuery} from '@/store/queries/apeironApi';

type Props = {
  customerId: string;
};

export const PerformanceDetailsModal = ({children, customerId}: PropsWithChildren<Props>) => {
  const {t} = useTranslate();
  const {data: summary} = useGetPerformanceSummaryQuery({customerId});

  const media = useMedia();

  return (
    <Modal>
      <Modal.Trigger alignSelf="flex-end" $sm={{alignSelf: 'stretch'}}>
        {children}
      </Modal.Trigger>
      <Modal.Content typeContainer="SCROLL" side>
        <ScrollView>
          <YStack gap="$8" paddingTop={Platform.OS === 'web' && !media.sm ? '$6' : '$0'}>
            <PerformanceDetailsData
              title={t('PERFORMANCE_CHART.BOTTOM_SHEET.TIME_WEIGHTED_RETURN')}
              description={t('PERFORMANCE_CHART.BOTTOM_SHEET.TIME_WEIGHTED_RETURN_DESC')}
              keyData="timeWeighted"
              data={summary?.rateOfReturn}
            />
            <PerformanceDetailsData
              title={t('PERFORMANCE_CHART.BOTTOM_SHEET.MONEY_WEIGHTED_RETURN')}
              description={t('PERFORMANCE_CHART.BOTTOM_SHEET.MONEY_WEIGHTED_RETURN_DESC')}
              keyData="moneyWeighted"
              data={summary?.rateOfReturn}
            />
            <YStack gap="$4">
              <Modal.Title asChild="except-style">
                <Heading5 variant="medium">
                  {t('PERFORMANCE_CHART.BOTTOM_SHEET.SIMPLIFIED_PERF')}
                </Heading5>
              </Modal.Title>
              <Modal.Description asChild="except-style">
                <Paragraph2 color="$neutral400">
                  {t('PERFORMANCE_CHART.BOTTOM_SHEET.SIMPLIFIED_PERF_DESC')}
                </Paragraph2>
              </Modal.Description>
              <PerformanceDetailsRow
                title={t('PERFORMANCE_CHART.BOTTOM_SHEET.ALL_TIME')}
                value={summary?.performance?.ratio}
                heading
              />
            </YStack>
            <YStack gap="$4">
              <Modal.Title asChild="except-style">
                <Heading5 variant="medium">
                  {t('PERFORMANCE_CHART.BOTTOM_SHEET.TOTAL_DEPOSITS')}
                </Heading5>
              </Modal.Title>
              <PerformanceDetailsRow
                title={t('PERFORMANCE_CHART.BOTTOM_SHEET.ALL_TIME')}
                value={summary?.netInflow}
                heading
                percentage={false}
              />
            </YStack>
          </YStack>
        </ScrollView>
        <Modal.IconClose />
      </Modal.Content>
    </Modal>
  );
};
