import {useTranslate} from '@tolgee/react';
import React from 'react';
import {Control, Path} from 'react-hook-form';

import {AcademicTitleSelect} from '@/components/inputs/form/AcademicTitleSelect';
import {FormTextInput} from '@/components/inputs/form/FormTextInput';
import {FormTitleSelect} from '@/components/inputs/form/FormTitleSelect';

import {View, XStack, YStack} from 'tamagui';

export type AcademicTitles = 'DR' | 'PRF' | 'NONE';

type MainUserValues = {
  title?: string;
  firstName: string;
  lastName: string;
  academicTitle?: AcademicTitles;
};

type ChildUserValues = {
  childTitle?: string;
  childFirstName: string;
  childLastName: string;
};

export type UserNameValues = {
  main: boolean;
  child: boolean;
} & MainUserValues &
  ChildUserValues;

type Props<T extends MainUserValues | ChildUserValues> = {
  control: Control<T>;
  child?: boolean;
  showAcademicTitle?: boolean;
};

export const UserDetailsForm = <T extends MainUserValues | ChildUserValues>({
  control,
  child,
  showAcademicTitle = true,
}: Props<T>) => {
  const {t} = useTranslate();

  return (
    <YStack gap="$6">
      <XStack gap="$3">
        <View flex={1} flexBasis={1 / 2}>
          <FormTitleSelect
            label={t('INTERVIEW.SALUTATION')}
            control={control}
            name={(child ? 'childTitle' : 'title') as Path<T>}
          />
        </View>
        {showAcademicTitle && (
          <View flex={1} flexBasis={1 / 2}>
            <AcademicTitleSelect
              control={control}
              name={'academicTitle' as Path<T>}
              label={t('FORM.LABELS.ACADEMIC_TITLE')}
            />
          </View>
        )}
      </XStack>
      <FormTextInput
        control={control}
        name={(child ? 'childFirstName' : 'firstName') as Path<T>}
        label={t('FORM.LABELS.FIRST_NAME')}
      />
      <FormTextInput
        control={control}
        name={(child ? 'childLastName' : 'lastName') as Path<T>}
        label={t('FORM.LABELS.LAST_NAME')}
      />
    </YStack>
  );
};
