import {Button} from '@/components/buttons/Button';
import {FormAmountInput} from '@/components/inputs/form/FormAmountInput';
import {Label3} from '@/components/texts/Label';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {SavingsRateOrderSchemaType, useValidations} from '@/hooks/useValidations';
import {WarningBox} from '@/modules/orders/components/WarningBox';
import {useGetSpecificOrderConstraint} from '@/modules/orders/hooks/useGetSpecificOrderConstraint';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useGetPerformanceSummaryQuery} from '@/store/queries/apeironApi';
import {useGetCustomerAccountQuery} from '@/store/queries/customersApi';
import {isThemeCustomer} from '@/types/isThemeCustomer';
import {yupResolver} from '@hookform/resolvers/yup';
import {skipToken} from '@reduxjs/toolkit/query';
import {useTranslate} from '@tolgee/react';
import {useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {YStack} from 'tamagui';

type SavingsRateOrderFormProps = {
  customerId: string;
  onSubmit: (data: SavingsRateOrderSchemaType) => void;
};

export function SavingsRateOrderForm({customerId, onSubmit}: SavingsRateOrderFormProps) {
  const {t} = useTranslate();
  const {savingsRateOrderSchema} = useValidations();
  const {data: customer} = useGetCustomerAccountQuery(customerId);
  const {data: performanceSummary} = useGetPerformanceSummaryQuery(
    customerId
      ? {
          customerId,
        }
      : skipToken
  );
  const posthog = usePostHog();

  const currentBalance = useMemo(() => {
    return performanceSummary?.balance ?? 0;
  }, [performanceSummary]);

  const recurringDeposit = useMemo(() => {
    if (!isThemeCustomer(customer)) {
      return customer?.currentRecurringDeposit?.amount ?? 0;
    }
  }, [customer]);

  const {handleSubmit, control} = useForm({
    resolver: yupResolver(savingsRateOrderSchema),
    mode: 'onChange',
    context: {
      currentBalance,
      recurringDeposit,
    },
  });

  const {constraint: {canBeCreated, firstViolationLabel} = {}, loading} =
    useGetSpecificOrderConstraint(customerId, 'RECURRING_DEPOSIT_CHANGE');

  if (isThemeCustomer(customer)) return null;

  return (
    <YStack gap="$4">
      <FormAmountInput
        bordered
        control={control}
        name="amount"
        label={t('PERSONAL_DETAILS.SAVINGS_RATE')}
        decimal
        modal
      />
      <Label3 color="$neutral500">
        {`${t('ORDER_SUMMARY.CURRENT-MONTHLY-SAVINGS-RATE')}: `}
        <LocalizedNumbers
          value={recurringDeposit}
          textComponent={Label3}
          numberStyle={{color: '$neutral500'}}
        />
      </Label3>
      <WarningBox show={!loading && !canBeCreated} text={firstViolationLabel} />
      <Button
        disabled={!canBeCreated}
        onPress={handleSubmit(onSubmit, data => {
          posthog?.capture('form_submit_failed', data);
        })}
      >
        {t('CONTINUE')}
      </Button>
    </YStack>
  );
}
