import {BaseInput} from '@/components/inputs/form/BaseInput';
import {InputErrorMessage} from '@/components/texts/InputErrorMessage';
import {Label3} from '@/components/texts/Label';
import {FormInputProps} from '@/types/FormInputProps';
import {FieldValues, useController} from 'react-hook-form';
import {Label, YStack} from 'tamagui';

type Props<T extends FieldValues> = {
  modal?: boolean;
  smFullWidth?: boolean;
} & FormInputProps<T>;

export function FormTextInput<T extends FieldValues>({
  control,
  name,
  label,
  bordered,
  textInputProps,
  wrapperProps,
  modal,
  smFullWidth = false,
}: Props<T>) {
  const {
    field,
    fieldState: {error},
  } = useController({
    name,
    control,
  });

  return (
    <YStack gap="$2" maxWidth={500} $sm={{width: smFullWidth ? '100%' : 'auto'}} {...wrapperProps}>
      <Label htmlFor={name}>
        <Label3 color="$neutral500">{label}</Label3>
      </Label>
      <BaseInput
        ref={field.ref}
        onChangeText={field.onChange}
        onBlur={field.onBlur}
        value={field.value || ''}
        id={name}
        bordered={bordered}
        modal={modal}
        testID={name}
        {...textInputProps}
      />
      <InputErrorMessage error={error?.message} />
    </YStack>
  );
}
