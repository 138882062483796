import {Heading5} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {Skeleton} from '@/components/views/Skeleton';
import {TipWithSideModal} from '@/modules/interview/components/TipWithSideModal';
import {UserTaxScreen} from '@/modules/onboarding/components/UserTaxScreen';
import {useChildScreen} from '@/modules/onboarding/hooks/useChildScreen';
import {useTranslate} from '@tolgee/react';
import {Href} from 'expo-router';
import {useMemo} from 'react';
import {YStack} from 'tamagui';

const TaxScreen = () => {
  const {
    params: {product, screenType, childID, onboardingType, orderId},
    nextParams,
    navigateToChildScreen,
  } = useChildScreen();
  const {t} = useTranslate();
  const tipContent = useMemo(
    () => (
      <YStack gap="$5" marginVertical="$5">
        <Heading5 variant="medium">{t('PERSONAL_DETAILS.TAXES_FAQ.HEADER')}</Heading5>
        <Paragraph2 variant="medium">{t('PERSONAL_DETAILS.TAXES_FAQ.LINE_1')}</Paragraph2>
        <Paragraph2 color="$neutral400">{t('PERSONAL_DETAILS.TAXES_FAQ.LINE_2')}</Paragraph2>
        <Paragraph2 variant="medium">{t('PERSONAL_DETAILS.TAXES_FAQ.LINE_3')}</Paragraph2>
        <Paragraph2 color="$neutral400">{t('PERSONAL_DETAILS.TAXES_FAQ.LINE_4')}</Paragraph2>
      </YStack>
    ),
    [t]
  );

  const pathname: Href = useMemo(() => {
    if (product === 'VL_ACCOUNT') {
      return '/onboarding/personal-details/vl-start';
    }

    if (product === 'THEME_ACCOUNT') {
      return '/onboarding/personal-details/bank';
    }

    if (navigateToChildScreen) {
      return '/onboarding/personal-details/tax';
    }

    if (product === 'CHILD_ACCOUNT') {
      if (onboardingType === 'second_guardian') {
        return '/interview/question';
      }

      return '/onboarding/personal-details/summary';
    }

    return '/onboarding/personal-details/deposits';
  }, [onboardingType, navigateToChildScreen, product]);

  return (
    <FullscreenScrollView variant="small" useKeyboardAwareScrollView>
      <Skeleton width="100%" height={200}>
        <UserTaxScreen
          href={{
            pathname,
            params: nextParams,
          }}
          childID={childID}
          isChildScreen={screenType === 'child'}
        />
      </Skeleton>
      <TipWithSideModal tipTitle={t('PERSONAL_DETAILS.TAXES_FAQ.HEADER')} tipContent={tipContent} />
    </FullscreenScrollView>
  );
};

export default TaxScreen;
