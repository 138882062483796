import {captureException} from '@sentry/react-native';
import {useTranslate} from '@tolgee/react';
import {toast} from 'burnt';
import {useCallback, useMemo, useState} from 'react';

import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {useGetInterviewByProduct} from '@/modules/interview/hooks/useGetInterviewByProduct';
import {useAppDispatch, useAppSelector} from '@/store';
import {
  AccountCreationOrderRequest,
  useCreateUserOrderMutation,
  usePatchOrderMutation,
} from '@/store/queries/ordersApi';
import {useGetStaticDocumentsQuery, useProductsQuery} from '@/store/queries/referenceApi';
import {useGetAvailableUsersQuery, useUpdateUserMutation} from '@/store/queries/usersApi';
import {resetInterviewState} from '@/store/reducers/interview';
import {resetOnboardingState} from '@/store/reducers/onboarding';
import {Planner} from '@/store/reducers/onboarding/state';
import {BankAccount} from '@/types/api/orders';
import {UserResponseV3} from '@/types/api/users';
import {Product} from '@/types/fixedTypes/customers.v2/Product';
import {ProductAccount} from '@/types/fixedTypes/orders/ProductAccount';

export const useCreateOrder = (
  product: Product,
  plannerState?: Planner,
  orderId?: string,
  isSecondGuardian?: boolean
) => {
  const {t} = useTranslate();

  const [creating, setCreating] = useState(false);

  const {data: availableUsers, isLoading: isGetAvailableUsersFetching} =
    useGetAvailableUsersQuery();
  const {data: staticDocuments, isLoading: isGetStaticDocumentsFetching} =
    useGetStaticDocumentsQuery();
  const {data: products, isLoading: isProductsFetching} = useProductsQuery();

  const [updateUser] = useUpdateUserMutation();
  const [createUserOrder] = useCreateUserOrderMutation();
  const [patchOrder] = usePatchOrderMutation();

  const dispatch = useAppDispatch();

  const personalDetails = useAppSelector(state => state.onboarding.personalDetails);

  const {getByCode} = useRefdataLocalizable();

  const {getInterviewByProduct, isLoading: isLatestAcceptableInterviewsFetching} =
    useGetInterviewByProduct();

  const finalChildUser = useCallback(
    async (childID: number) => {
      if (!availableUsers) return undefined;

      const childUser = availableUsers.find(user => user.id === childID);

      if (!childUser) return undefined;

      const finalChildData = {
        complete: true,
        accessToInsiderInformation: false,
        profession: 'MIN',
        industry: 'EUP',
        beneficialOwner: false,
        politicallyExposedPerson: false,
        incomeSources: ['OTH'],
        wealthSources: ['DON'],
      };

      return await updateUser({currentUser: childUser, changes: finalChildData}).unwrap();
    },
    [availableUsers, updateUser]
  );

  const finalSelfUser = useCallback(
    async (skipDocuments?: boolean) => {
      if (!availableUsers) return undefined;

      const selfUser = availableUsers.find(user => user.type === 'SELF');

      if (!selfUser) return undefined;

      if (skipDocuments) return selfUser;

      const contractTermsDoc = staticDocuments?.find(
        doc => doc.code === process.env.EXPO_PUBLIC_GINMON_CONTRACT_TERMS
      );
      const termsAndCondDoc = staticDocuments?.find(
        doc => doc.code === process.env.EXPO_PUBLIC_GINMON_TERMS_AND_CONDITIONS
      );

      if (!contractTermsDoc || !termsAndCondDoc) return undefined;

      const documentAgreements = [
        {type: contractTermsDoc.code, uuid: contractTermsDoc.uuid},
        {type: termsAndCondDoc.code, uuid: termsAndCondDoc.uuid},
      ];

      const {documentAgreements: prev, ...selfWithoutDocs} = selfUser;

      return await updateUser({
        currentUser: selfWithoutDocs,
        changes: {
          complete: true,
          accessToInsiderInformation: false,
          documentAgreements,
        },
      }).unwrap();
    },
    [availableUsers, staticDocuments, updateUser]
  );

  const confirmChildOrderBySecondGuardian = useCallback(
    async (interviewId: string) => {
      if (!orderId || interviewId === undefined) {
        throw new Error(
          `Failed while patch child order: orderId=${orderId ?? 'undefined'} interviewId=${interviewId ?? 'undefined'}`
        );
      }

      const payload = [
        {op: 'add', path: '/secondGuardianInterviewId', value: interviewId},
        {op: 'replace', path: '/confirmedBySecondGuardian', value: true},
      ];

      await patchOrder({orderId, payload: JSON.stringify(payload)});
    },
    [orderId, patchOrder]
  );

  const createAccountPayload = useCallback(
    (product: Product, holderName: string, displayName: string, interviewId: string) => {
      if (interviewId === undefined) {
        throw new Error('Cannot find interviewId:createAccountPayload');
      }

      const {bic, iban, bank} = personalDetails.account;

      if (!bic || !iban || !bank) {
        throw new Error('Cannot find bic or iban:createAccountPayload');
        // TODO: redirect to bank?
      }
      const bankAccount: BankAccount = {
        bic,
        iban: iban.replaceAll(' ', '').toUpperCase(),
        bank,
        holderFullName: holderName,
      };

      let account: ProductAccount | undefined;
      const baseAccount = {
        bankAccount,
        displayName,
        interviewId: String(interviewId),
      };

      switch (product) {
        case 'INVESTMENT_ACCOUNT':
        case 'SAVINGS_ACCOUNT': {
          if (!plannerState) {
            throw new Error(`plannerState is undefined: product=${product}`);
          }

          const {selectedStrategy, initialRecurringDepositAmount} = plannerState;

          if (
            !personalDetails.initialDeposit ||
            initialRecurringDepositAmount === undefined ||
            !selectedStrategy
          ) {
            throw new Error(
              `Cannot find personalDetails.initialDeposit or onboardingSelectedStrategy:product=${product};initialDeposit=${JSON.stringify(personalDetails.initialDeposit)};onboardingSelectedStrategy=${plannerState.selectedStrategy ?? 'undefined'}`
            );
          }

          const {amount, method} = personalDetails.initialDeposit;
          account = {
            ...baseAccount,
            product,
            initialDepositAmount: amount,
            initialDepositMethod: method,
            initialRecurringDeposit: {
              amount: initialRecurringDepositAmount,
              schedule: 'MONTHLY',
            },
            initialInvestmentStrategy: selectedStrategy,
          };

          break;
        }
        case 'VL_ACCOUNT': {
          if (!plannerState || !plannerState.selectedStrategy || !personalDetails.employer) {
            throw new Error(
              `Cannot find onboardingSelectedStrategy or personalDetails.employer:product=${product};onboardingSelectedStrategy=${plannerState?.selectedStrategy ?? 'undefined'};personalDetails.employer=${JSON.stringify(personalDetails.employer)}`
            );
          }

          const {address, name, savingsRate, savingsStartDate} = personalDetails.employer;

          if (!address || !name || !savingsStartDate || savingsRate === undefined) {
            throw new Error(
              `Not all employer information provided:product=${product};address=${JSON.stringify(address)}; name=${name ?? 'undefined'}; savingsRate=${savingsRate ?? 'undefined'}; savingsStartDate=${savingsStartDate ?? 'undefined'}`
            );
          }

          const {postCode, ...rest} = address;

          account = {
            ...baseAccount,
            product,
            initialInvestmentStrategy: plannerState.selectedStrategy,
            employer: {
              name,
              savingsStartDate,
              address: {
                ...rest,
                postalCode: postCode,
              },
              savingsRate,
            },
          };

          break;
        }
        case 'THEME_ACCOUNT': {
          account = {
            ...baseAccount,
            product,
            deposit: {
              themeDistribution: {},
            },
          };

          break;
        }
        case 'CHILD_ACCOUNT': {
          if (!plannerState) {
            throw new Error(`plannerState is undefined: product=${product}`);
          }

          const {selectedStrategy, initialRecurringDepositAmount} = plannerState;
          const {initialDeposit, guardianDocuments, anotherGuardian} = personalDetails;

          if (!initialDeposit || !selectedStrategy || !guardianDocuments) {
            throw new Error(
              `Cannot find personalDetails.initialDeposit or onboardingSelectedStrategy or guardianDocuments:product=${product}initialDeposit=${JSON.stringify(initialDeposit)};onboardingSelectedStrategy=${selectedStrategy ?? 'undefined'};guardianDocuments=${JSON.stringify(guardianDocuments)}`
            );
          }

          const {amount, method} = initialDeposit;
          const {birthCertificate, soloOrSharedGuardianCertificate} = guardianDocuments;

          account = {
            ...baseAccount,
            product,
            initialDepositAmount: amount,
            initialDepositMethod: method,
            initialRecurringDeposit: {
              amount: initialRecurringDepositAmount!,
              schedule: 'MONTHLY',
            },
            initialInvestmentStrategy: selectedStrategy,
            childBirthCertificateUploadKey: birthCertificate.objectKey,
            guardianshipProofUploadKey: soloOrSharedGuardianCertificate.objectKey,
          };

          if (anotherGuardian) {
            const {firstName, lastName, dateOfBirth, email} = anotherGuardian;

            account.secondGuardianDateOfBirth = dateOfBirth;
            account.secondGuardianEmail = email;
            account.secondGuardianFirstName = firstName;
            account.secondGuardianLastName = lastName;
          }

          break;
        }
        default: {
          account = undefined;
        }
      }

      return account;
    },
    [personalDetails, plannerState]
  );

  const createProductOrder = useCallback(
    async (
      product: Product,
      holderName: string,
      displayName: string,
      interviewID: string,
      childId?: number
    ) => {
      const accountPayload = createAccountPayload(product, holderName, displayName, interviewID);

      if (!accountPayload) {
        throw new Error('Failed to create account:createProductOrder');
      }

      let payload: AccountCreationOrderRequest;

      if (product === 'CHILD_ACCOUNT') {
        if (childId === undefined) {
          throw new Error('Cannot find childID:createProductOrder');
        }

        payload = {
          type: 'CHILD_ACCOUNT_CREATION_ORDER',
          childUserId: String(childId),
          account: accountPayload,
        };
      } else {
        payload = {
          type: 'INVESTMENT_ACCOUNT_CREATION_ORDER',
          account: accountPayload,
        };
      }

      await createUserOrder(payload).unwrap();
    },
    [createAccountPayload, createUserOrder]
  );

  const createOrder = useCallback(
    async (onSuccess: () => void, childID?: string, skipDocuments?: boolean) => {
      setCreating(true);

      try {
        const selfUser = await finalSelfUser(skipDocuments);

        if (!selfUser) return;

        const holderName =
          personalDetails.account.holderFullName !== undefined
            ? personalDetails.account.holderFullName
            : `${selfUser.firstName ?? ''} ${selfUser.lastName ?? ''}`;

        let childUser: UserResponseV3 | undefined;

        if (product === 'CHILD_ACCOUNT' && !!childID && !isSecondGuardian) {
          childUser = await finalChildUser(Number(childID));
        }

        const mainUser = product === 'CHILD_ACCOUNT' && !isSecondGuardian ? childUser : selfUser;

        if (!mainUser) return;

        const interview = getInterviewByProduct(product, isSecondGuardian);

        if (!interview || !interview.id) {
          throw new Error(
            `Failed to find interviewID:product=${product}; isSecondGuardian=${isSecondGuardian}`
          );
        }

        if (isSecondGuardian) {
          await confirmChildOrderBySecondGuardian(interview.id);
        } else {
          const productName = getByCode(products, product);

          if (!productName) captureException('Failed to get productName: createOrder');

          const displayName = `${mainUser.firstName ?? ''} - ${productName?.label ?? ''}`;

          await createProductOrder(product, holderName, displayName, interview.id, childUser?.id);
        }

        toast({
          preset: 'done',
          title: t('SNACKBAR.ACCOUNT-CREATED.TITLE'),
          message: t('SNACKBAR.ACCOUNT-CREATED.MESSAGE'),
        });

        dispatch(resetInterviewState());
        dispatch(resetOnboardingState());

        onSuccess();
      } catch (e) {
        toast({
          preset: 'error',
          title: t('SNACKBAR.ERROR_TITLE'),
          message: t('SNACKBAR.ERROR_MESSAGE'),
        });
        captureException(e);
      } finally {
        setCreating(false);
      }
    },
    [
      finalSelfUser,
      personalDetails.account.holderFullName,
      product,
      isSecondGuardian,
      getInterviewByProduct,
      t,
      dispatch,
      finalChildUser,
      confirmChildOrderBySecondGuardian,
      getByCode,
      products,
      createProductOrder,
    ]
  );

  const isLoading = useMemo(
    () =>
      isGetAvailableUsersFetching ||
      isGetStaticDocumentsFetching ||
      isProductsFetching ||
      isLatestAcceptableInterviewsFetching,
    [
      isGetAvailableUsersFetching,
      isGetStaticDocumentsFetching,
      isProductsFetching,
      isLatestAcceptableInterviewsFetching,
    ]
  );

  return {createOrder, isLoading, creating};
};
