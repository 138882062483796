import * as React from 'react';

import * as SecureStore from 'expo-secure-store';

import {useCallback, useEffect} from 'react';
import {Platform} from 'react-native';

type UseStateHook<T> = [[boolean, T | null], (value: T | null) => void];

function useAsyncState<T>(initialValue: [boolean, T | null] = [true, null]): UseStateHook<T> {
  return React.useReducer(
    (state: [boolean, T | null], action: T | null = null): [boolean, T | null] => [false, action],
    initialValue
  ) as UseStateHook<T>;
}

export async function setStorageItemAsync(key: string, value: string | null) {
  if (Platform.OS === 'web') {
    try {
      if (value === null) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, value);
      }
    } catch (e) {
      console.error('Local storage is unavailable:', e);
    }
  } else {
    if (value == null) {
      await SecureStore.deleteItemAsync(key);
    } else {
      await SecureStore.setItemAsync(key, value);
    }
  }
}

export function getSecureValue(key: string) {
  if (Platform.OS === 'web') {
    try {
      return Promise.resolve(localStorage.getItem(key));
    } catch (e) {
      console.error('Local storage is unavailable:', e);
      return null;
    }
  } else {
    return SecureStore.getItemAsync(key);
  }
}

export function useStorageState(key: string): UseStateHook<string> {
  const [state, setState] = useAsyncState<string>();

  const setSecureValue = useCallback(async () => {
    const secureValue = await getSecureValue(key);
    setState(secureValue);
  }, [key, setState]);

  useEffect(() => {
    setSecureValue().catch(console.error);
  }, [setSecureValue]);

  const setValue = React.useCallback(
    (value: string | null) => {
      setState(value);
      setStorageItemAsync(key, value);
    },
    [key, setState]
  );

  return [state, setValue];
}
