import {Label2} from '@/components/texts/Label';
import {useTokenUserInfo} from '@/hooks/useTokenUserInfo';
import {useAppSelector} from '@/store';
import {useFetchReferrerQuery} from '@/store/queries/referralApi';
import {skipToken} from '@reduxjs/toolkit/query';
import {useTranslate} from '@tolgee/react';
import React, {useMemo} from 'react';

type FriendsProgressTextProps = {
  friendsGoal: number;
};

export function FriendsProgressText({friendsGoal}: FriendsProgressTextProps) {
  const userInfo = useTokenUserInfo();
  const {t} = useTranslate();
  const locale = useAppSelector(state => state.app.language);
  const {data: referrer} = useFetchReferrerQuery(
    userInfo?.user_id ? {id: userInfo?.user_id} : skipToken
  );

  const getProgressTextAttr = useMemo(() => {
    const refs = referrer?.confirmedReferrals ?? 0;
    if (refs >= friendsGoal) {
      const sum = Intl.NumberFormat(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'EUR',
      }).format(refs * 1000);

      return {
        a: refs,
        b: sum,
      };
    }

    let msg = '';
    if (refs < 5) {
      msg = locale === 'en' ? '€5,000' : '5.000\u00A0€';
    } else if (refs < 10) {
      msg = locale === 'en' ? '€10,000' : '10.000\u00A0€';
    } else msg = locale === 'en' ? 'for free' : 'völlig kostenfrei';

    return {
      a: friendsGoal - refs,
      b: msg,
    };
  }, [friendsGoal, locale, referrer]);

  const renderProgressText = useMemo(() => {
    const translationKey =
      friendsGoal > (referrer?.confirmedReferrals ?? 0)
        ? 'NAVIGATION.INVITE_FRIENDS.BLOCK_3.TEXT_2'
        : 'NAVIGATION.INVITE_FRIENDS.DONE_TEXT';
    return t(translationKey, getProgressTextAttr);
  }, [friendsGoal, getProgressTextAttr, referrer, t]);

  return <Label2 flexWrap="wrap">{renderProgressText}</Label2>;
}
