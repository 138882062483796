import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {UserBirthScreen} from '@/modules/onboarding/components/UserBirthScreen';
import {useChildScreen} from '@/modules/onboarding/hooks/useChildScreen';

const BirthScreen = () => {
  const {
    params: {screenType, childID, onboardingType},
    nextParams,
    navigateToChildScreen,
  } = useChildScreen();

  return (
    <FullscreenScrollView variant="small" useKeyboardAwareScrollView>
      <UserBirthScreen
        href={{
          pathname: navigateToChildScreen
            ? '/onboarding/personal-details/birth'
            : '/onboarding/personal-details/address',
          params: nextParams,
        }}
        childID={childID}
        isChildScreen={screenType === 'child' && !onboardingType}
      />
    </FullscreenScrollView>
  );
};

export default BirthScreen;
