import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {MultiAnswer} from '@/modules/interview/components/MultiAnswer';
import {useOnboardingSearchParams} from '@/modules/onboarding/hooks/useOnboardingSearchParams';

export default function ExpV2Page() {
  const params = useOnboardingSearchParams();

  return (
    <FullscreenScrollView variant="small">
      <MultiAnswer
        href={{pathname: '/interview/question/trades', params}}
        questionCode="EXP_V2"
        params={params}
      />
    </FullscreenScrollView>
  );
}
