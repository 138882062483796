import {store, useAppSelector} from '@/store';
import {LanguagesType} from '@/types/languages';
import {BackendFetch, DevTools, FormatSimple, Tolgee, TolgeeProvider} from '@tolgee/react';
import {PropsWithChildren} from 'react';
import {Platform} from 'react-native';

const tolgee = Tolgee()
  .use(Platform.OS === 'web' ? DevTools() : undefined)
  .use(FormatSimple())
  .use(
    BackendFetch({
      prefix: 'https://i18n.ginmon.com/7607fe4eba40500040b443b885ee830d',
    })
  )
  .init({
    language: (store.getState().app.language ?? 'de') satisfies LanguagesType,
    apiUrl: process.env.EXPO_PUBLIC_TOLGEE_API_URL,
    apiKey: process.env.EXPO_PUBLIC_TOLGEE_API_KEY,
  });

export function TolgeeTranslationProvider({children}: PropsWithChildren) {
  const language = useAppSelector(state => state.app.language);
  tolgee.changeLanguage(language);
  return <TolgeeProvider tolgee={tolgee}>{children}</TolgeeProvider>;
}
