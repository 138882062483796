import {SvgName} from '@/components/icons/SvgFile';
import {useGetCustomerAccountsQuery} from '@/store/queries/customersApi';
import {useGetValidOrdersQuery} from '@/store/queries/ordersApi';
import {ShortenedCustomerInformationResponse} from '@/types/api/customers.v2';
import {skipToken as rtkSkipToken} from '@reduxjs/toolkit/query';
import {useTranslate} from '@tolgee/react';
import {useCallback, useMemo} from 'react';

export default function useAvailableProducts(skipToken?: typeof rtkSkipToken) {
  const {t} = useTranslate();
  const {data: customers, isFetching: isCustomerFetching} = useGetCustomerAccountsQuery(skipToken);
  const {data: orders, isFetching: isOrdersFetching} = useGetValidOrdersQuery(skipToken);

  const userHasProduct = useCallback(
    (product: ShortenedCustomerInformationResponse['product']) =>
      customers?.items.some(item => item.product === product) ||
      orders?.items?.some(
        order =>
          order.account.product === product &&
          order.status !== 'REJECTED' &&
          order.status !== 'CANCELED'
      ),
    [customers, orders]
  );

  const products = useMemo(() => {
    if (isCustomerFetching || isOrdersFetching) return [];

    const tmp: {
      product: ShortenedCustomerInformationResponse['product'];
      title: string;
      description: string;
      icon: SvgName;
    }[] = [
      {
        product: 'INVESTMENT_ACCOUNT',
        title: t('STRATEGY_SELECTION.GLOBAL_INVESTMENT.NAME'),
        description: t('STRATEGY_SELECTION.GLOBAL_INVESTMENT.DESC'),
        icon: 'ginmon-invest',
      },
    ];

    if (!userHasProduct('THEME_ACCOUNT') || skipToken) {
      tmp.push({
        product: 'THEME_ACCOUNT',
        title: t('STRATEGY_SELECTION.THEME_ACCOUNT.NAME'),
        description: t('STRATEGY_SELECTION.THEME_ACCOUNT.DESC'),
        icon: 'ginmon-motive',
      });
    }
    if (!userHasProduct('SAVINGS_ACCOUNT') || skipToken) {
      tmp.push({
        product: 'SAVINGS_ACCOUNT',
        title: t('STRATEGY_SELECTION.SAVINGS_ACCOUNT.NAME'),
        description: t('STRATEGY_SELECTION.SAVINGS_ACCOUNT.DESC'),
        icon: 'ginmon-topzins',
      });
    }

    if (!userHasProduct('VL_ACCOUNT') || skipToken) {
      tmp.push({
        product: 'VL_ACCOUNT',
        title: t('STRATEGY_SELECTION.VL_ACCOUNT.NAME'),
        description: t('STRATEGY_SELECTION.VL_ACCOUNT.DESC'),
        icon: 'ginmon-vl',
      });
    }

    tmp.push({
      product: 'CHILD_ACCOUNT',
      title: t('STRATEGY_SELECTION.CHILD_ACCOUNT.NAME'),
      description: t('STRATEGY_SELECTION.CHILD_ACCOUNT.DESC'),
      icon: 'ginmon-junior',
    });

    return tmp;
  }, [isCustomerFetching, isOrdersFetching, skipToken, t, userHasProduct]);

  return products;
}
