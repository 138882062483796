import {Heading4} from '@/components/texts/Heading';
import {TaxesDetailsSchemaType} from '@/hooks/useValidations';
import {SubmitFormRef} from '@/modules/interview/components/FinancialSituationForm';
import {InterviewContinueButton} from '@/modules/interview/components/InterviewContinueButton';
import {UserTaxForm} from '@/modules/onboarding/components/forms/UserTaxForm';
import {useGetParentOrChildUser} from '@/modules/onboarding/hooks/useGetParentOrChildUser';
import {useUpdateUserMutation} from '@/store/queries/usersApi';
import {useTranslate} from '@tolgee/react';
import {Href, useRouter} from 'expo-router';
import {useCallback, useMemo, useRef, useState} from 'react';
import {YStack} from 'tamagui';

export function UserTaxScreen({
  href,
  childID,
  isChildScreen,
}: {
  href: Href;
  childID?: string;
  isChildScreen: boolean;
}) {
  const ref = useRef<SubmitFormRef>(null);
  const {t} = useTranslate();
  const [updateUser] = useUpdateUserMutation();
  const router = useRouter();
  const user = useGetParentOrChildUser(isChildScreen, childID);
  const [isLoading, setIsLoading] = useState(false);

  const onPress = useCallback(() => {
    ref.current?.submit();
  }, []);

  const onValidSubmit = useCallback(
    async (data: TaxesDetailsSchemaType) => {
      if (user) {
        await updateUser({
          currentUser: user,
          changes: {
            taxIdentification: data.taxIdentification?.map(_ => ({
              ..._,
              number: _?.number === '' ? undefined : _?.number,
            })),
            unitedStatesTax: false,
          },
        });
      }
      router.navigate(href);
    },
    [href, router, updateUser, user]
  );

  const title = useMemo(() => {
    if (isChildScreen) {
      return t('PERSONAL_DETAILS.CHILD.TITLE_TAXES_PAY', {firstName: user?.firstName});
    } else {
      return t('PERSONAL_DETAILS.TITLE_TAXES_PAY');
    }
  }, [user?.firstName, isChildScreen, t]);

  return (
    <YStack gap="$6" marginTop="$6">
      <Heading4 variant="medium">{title}</Heading4>

      <UserTaxForm
        ref={ref}
        onValid={onValidSubmit}
        user={user}
        child={isChildScreen}
        setIsLoading={setIsLoading}
      />

      <InterviewContinueButton isLoading={isLoading} onPress={onPress} />
    </YStack>
  );
}
