import {useTranslate} from '@tolgee/react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {OpaqueColorValue} from 'react-native';
import {Gesture, GestureDetector} from 'react-native-gesture-handler';
import Animated, {
  ReduceMotion,
  runOnJS,
  useAnimatedReaction,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import {View, XStack, useTheme} from 'tamagui';

import {Label3} from '@/components/texts/Label';
import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {RecommendedStrategyTuple} from '@/modules/onboarding/types/selected-strategy';
import {useInvestmentStrategiesQuery} from '@/store/queries/referenceApi';

const PADDING = 8;

const SLIDER_STRATEGIES = [
  'AG01',
  'AG02',
  'AG03',
  'AG04',
  'AG05',
  'AG06',
  'AG07',
  'AG08',
  'AG09',
  'AG10',
  'AP11',
  'AP12',
  'AP13',
  'AP14',
  'AP15',
  'AP16',
  'AP17',
  'AP18',
  'AP19',
  'AP20',
];

type Props = {
  isGreen: boolean;
  recommendedStrategy?: RecommendedStrategyTuple;
  strategy?: string;
  setStrategy: (strategy?: string) => void;
  defaultStrategy?: string;
};

export const StrategySelector = ({
  isGreen,
  recommendedStrategy,
  strategy,
  setStrategy,
  defaultStrategy,
}: Props) => {
  const {t} = useTranslate();
  const [width, setWidth] = useState(0);
  const {getByCode} = useRefdataLocalizable();
  const {data: investmentStrategies} = useInvestmentStrategiesQuery();
  const position = useSharedValue(0);
  const theme = useTheme();
  const typeColor = useMemo(
    () => (isGreen ? theme.emerald.val : theme.primary.val),
    [isGreen, theme.emerald.val, theme.primary.val]
  );
  const [isDragging, setIsDragging] = useState(false);

  const allStrategies = useMemo(
    () => SLIDER_STRATEGIES.filter(_ => _.startsWith(isGreen ? 'AG' : 'AP')),
    [isGreen]
  );

  const length = useMemo(
    () => (allStrategies?.length ? allStrategies?.length - 1 : 1),
    [allStrategies]
  );

  const elWidth = useMemo(() => Math.max(0, width / length - PADDING / length), [width, length]);

  const onPan = Gesture.Pan()
    .onBegin(() => runOnJS(setIsDragging)(true))
    .onUpdate(e => {
      position.value = Math.max(PADDING, Math.min(e.x, width - PADDING));
    })
    .onEnd(e => {
      const posIdx = Math.round(e.x / elWidth);
      const pos = Math.max(PADDING, Math.min(posIdx * elWidth, width - PADDING));
      position.value = withTiming(pos, {duration: 500, reduceMotion: ReduceMotion.Never});
      runOnJS(setIsDragging)(false);
    });

  const onTap = Gesture.Tap().onEnd(e => {
    const posIdx = Math.round(e.x / elWidth);
    const pos = Math.max(PADDING, Math.min(posIdx * elWidth, width - PADDING));
    position.value = withTiming(pos, {duration: 500, reduceMotion: ReduceMotion.Never});
  });

  const animatedOverflowStyle = useAnimatedStyle(() => {
    return {width: position.value};
  });

  const animatedCircleStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateX: position.value - 12,
        },
        {
          translateY: isDragging ? -12 : -10,
        },
      ],
    };
  });

  const animatedFloatingStrategyStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateX: Math.min(Math.max(0, position.value - 35), width - PADDING - 70),
        },
      ],
    };
  }, [width]);

  useAnimatedReaction(
    () => position.value,
    p => {
      const strategy = allStrategies?.at(Math.min(length, Math.max(0, Math.round(p / elWidth))));
      if (strategy) runOnJS(setStrategy)(strategy);
    }
  );

  const getStrategyIndexFromCode = useCallback(
    (code: string | undefined) => allStrategies.findIndex(_ => _ === code),
    [allStrategies]
  );

  const initialStrategyPosition = useMemo(() => {
    let strategyIndex = -1;

    if (defaultStrategy) {
      strategyIndex = getStrategyIndexFromCode(defaultStrategy);
    }

    if (strategyIndex === -1 && recommendedStrategy) {
      const strategyCode = recommendedStrategy.at(isGreen ? 1 : 0)?.code;

      if (!strategyCode) return PADDING;

      strategyIndex = getStrategyIndexFromCode(strategyCode);
    }

    if (strategyIndex === -1) return PADDING;

    return strategyIndex * elWidth;
  }, [elWidth, getStrategyIndexFromCode, isGreen, recommendedStrategy, defaultStrategy]);

  useEffect(() => {
    if (!initialStrategyPosition) return;

    position.value = withTiming(initialStrategyPosition, {
      duration: 500,
      reduceMotion: ReduceMotion.Never,
    });
  }, [position, initialStrategyPosition]);

  return (
    <GestureDetector gesture={Gesture.Race(onPan, onTap)}>
      <View cursor="pointer">
        {strategy && (
          <Animated.View style={animatedFloatingStrategyStyle}>
            <View
              animation="slow"
              alignSelf="flex-start"
              borderRadius="$0.5"
              paddingHorizontal="$2"
              paddingVertical="$1"
              marginBottom="$4"
              backgroundColor={typeColor as unknown as OpaqueColorValue}
            >
              <Label3 variant="medium" color="$white" numberOfLines={1}>
                {getByCode(investmentStrategies, strategy)?.label}
              </Label3>
            </View>
          </Animated.View>
        )}
        <View onLayout={e => setWidth(e.nativeEvent.layout.width)} gap="$4">
          <View position="relative">
            <XStack position="absolute" paddingHorizontal={PADDING} gap={PADDING}>
              {allStrategies.map((_, i) => {
                if (i === 0) return null;
                return (
                  <View
                    width={elWidth - PADDING}
                    height={5}
                    key={_}
                    backgroundColor="$neutralBG"
                    borderRadius="$1"
                  />
                );
              })}
            </XStack>
          </View>
          <Animated.View
            style={[{position: 'absolute', overflow: 'hidden', height: 5}, animatedOverflowStyle]}
          >
            <XStack position="absolute" paddingHorizontal={PADDING} gap={PADDING}>
              {allStrategies.map((_, i) => {
                if (i === 0) return null;
                return (
                  <View
                    animation="slow"
                    width={elWidth - PADDING}
                    height={5}
                    key={_}
                    backgroundColor={typeColor as unknown as OpaqueColorValue}
                    borderRadius="$1"
                  />
                );
              })}
            </XStack>
          </Animated.View>
          <Animated.View
            style={[
              {
                position: 'absolute',
                height: isDragging ? 30 : 26,
                width: isDragging ? 30 : 26,
                borderRadius: 30,
                backgroundColor: `${typeColor}1A`,
                alignItems: 'center',
                justifyContent: 'center',
              },
              animatedCircleStyle,
            ]}
          >
            <View
              height={isDragging ? 14 : 12}
              width={isDragging ? 14 : 12}
              borderRadius={14}
              backgroundColor={typeColor as unknown as OpaqueColorValue}
            />
          </Animated.View>

          <XStack justifyContent="space-between">
            <Label3 color="$neutral400">{t('INVESTMENT_PLANNER.LOWER_RISK')}</Label3>
            <Label3 color="$neutral400">{t('INVESTMENT_PLANNER.HIGHER_RISK')}</Label3>
          </XStack>
        </View>
      </View>
    </GestureDetector>
  );
};
