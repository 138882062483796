import {Card} from '@/components/cards/Card';
import SvgFile from '@/components/icons/SvgFile';
import {Heading6} from '@/components/texts/Heading';
import {Label3sm2} from '@/components/texts/Label';
import {Paragraph3} from '@/components/texts/Paragraph';
import {Skeleton} from '@/components/views/Skeleton';
import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {StrategySelector} from '@/modules/interview/components/planner/StrategySelector';
import {StrategyTypeToggle} from '@/modules/interview/components/planner/StrategyTypeToggle';
import {calculateRecommendedStrategy} from '@/modules/onboarding/components/helpers/calculateRecommendedStrategy';
import {InvestmentAccountStrategyType} from '@/modules/onboarding/types/investment-account-strategy-type';
import {
  RecommendedStrategyTuple,
  SelectedStrategy,
} from '@/modules/onboarding/types/selected-strategy';
import {useAppSelector} from '@/store';
import {useInvestmentStrategiesQuery, useRiskProfilesQuery} from '@/store/queries/referenceApi';
import {useTranslate} from '@tolgee/react';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {View, XStack, YStack} from 'tamagui';

type RecommendedStrategyCardProps = {
  riskProfileCode?: string;
  isSV: boolean;
  isVL: boolean;
  onStrategyChange: (strategy: SelectedStrategy, recommended?: SelectedStrategy) => void;
  changeStrategyNote?: boolean;
  defaultType?: InvestmentAccountStrategyType;
  defaultStrategy?: string;
  planner?: boolean;
};

export function RecommendedStrategyCard({
  riskProfileCode,
  isSV = false,
  isVL = false,
  onStrategyChange,
  changeStrategyNote,
  defaultType,
  defaultStrategy,
  planner = true,
}: RecommendedStrategyCardProps) {
  const {t} = useTranslate();
  const interview = useAppSelector(state => state.interview);
  const [type, setType] = useState<InvestmentAccountStrategyType>(
    interview.SUSTAIN_PREF !== 'SUSTAIN_PREF_NO' ? 'green' : 'global'
  );
  const {data: investmentStrategies} = useInvestmentStrategiesQuery();
  const {data: riskProfiles} = useRiskProfilesQuery();
  const {getByCode} = useRefdataLocalizable();
  const [strategy, setStrategy] = useState<string>();
  const [recommended, setRecommended] = useState<RecommendedStrategyTuple>();
  const isGreen = useMemo(() => type === 'green', [type]);

  const setTypeChanged = useRef(false);
  const setStrategyChanged = useRef(false);

  useEffect(() => {
    if (setTypeChanged.current || !defaultType) return;

    setType(defaultType);
  }, [defaultType]);

  useEffect(() => {
    if (!riskProfiles || !investmentStrategies) return;

    const recommendedRiskProfile = riskProfiles.find(profile => profile.code === riskProfileCode);

    if (!recommendedRiskProfile) {
      setRecommended(undefined);

      return;
    }

    const [recommendedGlobalCode, recommendedGreenCode] = calculateRecommendedStrategy(
      recommendedRiskProfile.acceptableInvestmentStrategies
    );

    if (!recommendedGlobalCode && !recommendedGreenCode) {
      setRecommended(undefined);

      return;
    }

    const recommendedGlobalInvestStrategy = getByCode(
      investmentStrategies,
      recommendedGlobalCode ?? ''
    );
    const recommendedGreenInvestStrategy = getByCode(
      investmentStrategies,
      recommendedGreenCode ?? ''
    );

    let recommendedGlobalStrategy: SelectedStrategy | undefined;
    let recommendedGreenStrategy: SelectedStrategy | undefined;

    if (recommendedGlobalInvestStrategy) {
      recommendedGlobalStrategy = {
        code: recommendedGlobalInvestStrategy.code,
        label: recommendedGlobalInvestStrategy.label,
      };
    }

    if (recommendedGreenInvestStrategy) {
      recommendedGreenStrategy = {
        code: recommendedGreenInvestStrategy.code,
        label: recommendedGreenInvestStrategy.label,
      };
    }

    setRecommended([recommendedGlobalStrategy, recommendedGreenStrategy]);
  }, [riskProfiles, riskProfileCode, investmentStrategies, getByCode]);

  const changeStrategyByType = (type: InvestmentAccountStrategyType) => (prev?: string) => {
    if (!prev) return prev;

    const prevBase = prev.slice(0, 2);
    const prevNum = Number(prev.slice(-2));

    if (Number.isNaN(prevNum)) return prev;

    const newBase = type === 'global' ? 'AP' : 'AG';

    if (prevBase === newBase) return prev;

    const sign = prevBase === 'AP' ? -1 : 1;
    const newNum = prevNum + 10 * sign;
    const newNumStr = newNum > 9 ? newNum.toString() : `0${newNum}`;

    return `${newBase}${newNumStr}`;
  };

  useEffect(() => {
    if (isSV) {
      setStrategy('APSV');

      return;
    }

    if (!type) return;

    if (isVL) {
      const newStrategy = type === 'global' ? 'APVL' : 'AGVL';

      setStrategy(newStrategy);

      return;
    }

    setStrategy(changeStrategyByType(type));
  }, [type, isSV, isVL]);

  useEffect(() => {
    if (!strategy || !investmentStrategies) return;

    let recommendedStrategy: SelectedStrategy | undefined;

    if (!isSV && !isVL && recommended) {
      recommendedStrategy = recommended.at(type === 'global' ? 0 : 1);
    }

    const selectedInvestStrategy = getByCode(investmentStrategies, strategy);

    if (!selectedInvestStrategy) return;

    const selectedStrategy = {
      code: selectedInvestStrategy.code,
      label: selectedInvestStrategy.label,
    };

    onStrategyChange(selectedStrategy, recommendedStrategy);
  }, [onStrategyChange, isSV, isVL, type, investmentStrategies, strategy, getByCode, recommended]);

  const handleTypeChanged = useCallback((type: InvestmentAccountStrategyType) => {
    if (!setTypeChanged.current) {
      setTypeChanged.current = true;
    }

    setType(type);
  }, []);

  const handleStrategyChanged = useCallback((strategy?: string) => {
    if (!setStrategyChanged.current) {
      setStrategyChanged.current = true;
    }

    setStrategy(strategy);
  }, []);

  if (isSV) return null;

  if (planner && !isVL && recommended === undefined) {
    return <Skeleton width="100%" height={120} />;
  }

  return (
    <Card gap="$4">
      {changeStrategyNote && (
        <View alignItems="flex-start">
          <YStack backgroundColor="$neutralBG" maxWidth="100%" borderRadius="$1" padding="$4">
            <XStack gap="$4" justifyContent="flex-start">
              <YStack alignItems="center">
                <SvgFile name="info" color="$primary" size={20} />
              </YStack>
              <YStack flexShrink={1} gap="$2">
                <Label3sm2 color="$primary">{t('CHANGE-STRATEGY.NOTE.TITLE')}</Label3sm2>
                <Paragraph3>{t('STRATEGY.NOTE.DESC')}</Paragraph3>
              </YStack>
            </XStack>
          </YStack>
        </View>
      )}
      <XStack justifyContent="space-between" gap="$4">
        <Heading6 variant="medium">{t('INVESTMENT_PLANNER.PROPOSED_STRATEGY')}</Heading6>
        <StrategyTypeToggle isGreen={isGreen} onChangeType={handleTypeChanged} />
      </XStack>

      {!isVL && (
        <StrategySelector
          isGreen={isGreen}
          recommendedStrategy={recommended}
          strategy={strategy}
          setStrategy={handleStrategyChanged}
          defaultStrategy={defaultStrategy}
        />
      )}
    </Card>
  );
}
