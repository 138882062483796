import {FormAllCountrySelect} from '@/components/inputs/form/FormAllCountrySelect';
import {FormDateInput} from '@/components/inputs/form/FormDateInput';
import {FormTextInput} from '@/components/inputs/form/FormTextInput';
import {FormNationalitySelect} from '@/components/inputs/form/nationality/FormNationalitySelect';
import {BirthDetailsSchemaType, useValidations} from '@/hooks/useValidations';
import {mapUserToFormNationalities} from '@/modules/onboarding/components/helpers/mapUserToFormNationalities';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useIdentityProofsQuery} from '@/store/queries/referenceApi';
import {UserResponseV3} from '@/types/api/users';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslate} from '@tolgee/react';
import React, {forwardRef, useEffect, useImperativeHandle} from 'react';
import {useForm} from 'react-hook-form';

import {View, XStack, YStack} from 'tamagui';

type UserBirthFormProps = {
  onValid: (data: BirthDetailsSchemaType) => void;
  user: UserResponseV3 | undefined;
  child?: boolean;
  setIsLoading?: (loading: boolean) => void;
};

export type SubmitFormRef = {submit: () => Promise<void>};

export const UserBirthForm = forwardRef<any, UserBirthFormProps>(function UserBirthForm(
  {onValid, user, child = false, setIsLoading},
  ref
) {
  const {t} = useTranslate();
  const {birthDetailsFormSchema} = useValidations();
  const posthog = usePostHog();
  const {data: proofs} = useIdentityProofsQuery();

  useImperativeHandle(ref, () => ({
    submit: handleSubmit(onValid, data => {
      posthog?.capture('form_submit_failed', data);
    }),
  }));

  const {
    handleSubmit,
    control,
    formState: {errors},
    setValue,
    formState: {isSubmitting},
  } = useForm({
    resolver: yupResolver(birthDetailsFormSchema),
    mode: 'onChange',
    context: {
      child,
      proofs,
    },
  });

  useEffect(() => {
    setIsLoading?.(isSubmitting);
  }, [isSubmitting, setIsLoading]);

  useEffect(() => {
    setValue('dateOfBirth', user?.dateOfBirth ?? '');
    setValue('placeOfBirth', user?.placeOfBirth ?? '');
    setValue('countryOfBirthCode', user?.countryOfBirth ?? 'DE');
    setValue('nationalities', mapUserToFormNationalities(user));
  }, [setValue, user]);

  return (
    <YStack gap="$6">
      <FormDateInput control={control} name="dateOfBirth" label={t('FORM.LABELS.BIRTH_DATE')} />
      <FormTextInput control={control} name="placeOfBirth" label={t('FORM.LABELS.BIRTH_PLACE')} />
      <XStack>
        <View flex={1}>
          <FormAllCountrySelect
            control={control}
            name="countryOfBirthCode"
            label={t('FORM.LABELS.LAND_OF_BIRTH')}
          />
        </View>
      </XStack>
      <XStack>
        <View flex={1}>
          <FormNationalitySelect
            control={control}
            name="nationalities"
            label={t('FORM.LABELS.NATIONALITY')}
            userID={user?.id}
          />
        </View>
      </XStack>
    </YStack>
  );
});
