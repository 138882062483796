import {Pressable} from 'react-native';
import {View} from 'tamagui';

import {Paragraph2} from '@/components/texts/Paragraph';

type Props = {
  text: string;
  onPress: () => void;
};

export const SustainLevelModalTrigger = ({text, onPress}: Props) => (
  <View paddingTop="$3">
    <Pressable onPress={onPress}>
      <Paragraph2 variant="medium" color="$primary">
        {text}
      </Paragraph2>
    </Pressable>
  </View>
);
