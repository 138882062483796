import {useTranslate} from '@tolgee/react';
import {forwardRef, useCallback, useEffect, useMemo} from 'react';
import {Control, useController} from 'react-hook-form';
import {ScrollView, View, XStack, YStack} from 'tamagui';

import {Button} from '@/components/buttons/Button';
import {Modal} from '@/components/modal/Modal';
import {Heading5} from '@/components/texts/Heading';
import {Note} from '@/components/views/Note';
import {
  Answer,
  useGetQuestionWithAnswers,
} from '@/modules/interview/hooks/useGetQuestionWithAnswers';
import {SustainLevelFormValues} from '@/modules/interview/types/sustainLevel';
import {ModalRef} from '@/types/modal';

type Props = {
  control: Control<SustainLevelFormValues>;
  onQuestionLabel: (title: string) => void;
};

export const SustainLevelPercentageModal = forwardRef<ModalRef, Props>(
  ({control, onQuestionLabel}, ref) => {
    const {t} = useTranslate();

    const {
      field: {value, onChange},
    } = useController({name: 'SUSTAIN_PERCENTAGE', control});

    const {question, answers} = useGetQuestionWithAnswers('SUSTAIN_PERCENTAGE');

    useEffect(() => {
      if (!question) return;

      onQuestionLabel(question.label);
    }, [question, onQuestionLabel]);

    const bunchAnswers = useMemo(() => {
      if (!answers) return [];

      const newAnswers: Answer[][] = [];

      for (let i = 0; i < answers.length; i += 2) {
        const curr = answers.at(i);
        const next = answers.at(i + 1);

        if (!curr) continue;

        const bunch = [curr];

        if (next) {
          bunch.push(next);
        }

        newAnswers.push(bunch);
      }

      return newAnswers;
    }, [answers]);

    const handlePercentageButtonPressed = useCallback(
      (code: string) => {
        if (value === code) {
          onChange(undefined);
        } else {
          onChange(code);
        }
      },
      [value, onChange]
    );

    return (
      <Modal ref={ref}>
        <Modal.Content typeContainer="SCROLL" side>
          <ScrollView>
            <YStack>
              <Modal.Title asChild="except-style">
                <Heading5 variant="medium">{question?.label}</Heading5>
              </Modal.Title>
              <YStack paddingTop="$4" gap="$2">
                {bunchAnswers.map((bunchAnswer, index) => (
                  <XStack key={`bunchAnswer-${index}`} gap="$2">
                    {bunchAnswer.map(answer => (
                      <View key={answer.code} flexGrow={1}>
                        <Button
                          secondary={value !== answer.code}
                          backgroundColor={value === answer.code ? '$primary' : '$neutralBG'}
                          onPress={() => handlePercentageButtonPressed(answer.code)}
                          width="100%"
                        >
                          {answer.label}
                        </Button>
                      </View>
                    ))}
                  </XStack>
                ))}
              </YStack>
              {value === 'SUSTAIN_PERCENTAGE_100' && (
                <View paddingTop="$2">
                  <Note
                    icon={{name: 'warning', size: 18, color: '$yellowDark'}}
                    title={t('INTERVIEW.WHAT_LEVEL_OF_SUSTAINABILITY.PERCENTAGE_SELECT.NOTE')}
                    color="$yellowDark"
                  />
                </View>
              )}
            </YStack>
            <Modal.IconClose />
          </ScrollView>
        </Modal.Content>
      </Modal>
    );
  }
);
