import {Button} from '@/components/buttons/Button';
import {Card} from '@/components/cards/Card';
import {Modal} from '@/components/modal/Modal';
import {Heading5} from '@/components/texts/Heading';
import {Label3} from '@/components/texts/Label';
import {Paragraph2} from '@/components/texts/Paragraph';
import {ResponsiveScrollView} from '@/components/views/ResponsiveScrollView';
import {Header} from '@/modules/navigation/components/header/Header';
import {DeleteAccountNote} from '@/modules/profile/components/DeleteAccountNote';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';
import {ModalRef} from '@/types/modal';
import {captureException} from '@sentry/react-native';
import {useTranslate} from '@tolgee/react';
import {openURL} from 'expo-linking';
import {Stack} from 'expo-router';
import React, {useCallback, useRef} from 'react';
import {Platform} from 'react-native';
import {YStack} from 'tamagui';

export default function TabDeleteAccount() {
  const {t} = useTranslate();
  const {data: user} = useGetSelfUserQuery();
  const modalRef = useRef<ModalRef>(null);
  const email = process.env.EXPO_PUBLIC_MAILTO_SERVICE;

  const onAccept = useCallback(() => {
    modalRef.current?.open(false);
    const url = `${email}?subject=${t(
      'SETTINGS.DELETE_ACCOUNT.EMAIL_SUBJECT'
    )}&body=${t('SETTINGS.DELETE_ACCOUNT.EMAIL_TEXT')}`;
    openURL(encodeURI(url)).catch(captureException);
  }, [email, t]);

  return (
    <ResponsiveScrollView>
      <YStack gap="$5">
        <Stack.Screen
          options={{
            headerShown: true,
            header: () => (
              <Header
                title={t('NAVIGATION.PROFILE.ACCOUNT.DELETE')}
                backButton={Platform.OS !== 'web'}
              />
            ),
          }}
        />
        <Card headerText={t('NAVIGATION.PROFILE.ACCOUNT.DELETE.DETAILS')} gap="$6" padding="$6">
          <YStack gap="$4">
            <Label3>{user?.firstName + ' ' + user?.lastName}</Label3>
            <Label3>{user?.email}</Label3>
          </YStack>
        </Card>

        <Card gap="$6" padding="$6">
          <YStack gap="$4">
            <DeleteAccountNote />
            <Button
              onPress={() => {
                modalRef.current?.open(true);
              }}
            >
              {t('NAVIGATION.PROFILE.ACCOUNT.DELETE.CONFIRM')}
            </Button>
          </YStack>
        </Card>

        <Modal ref={modalRef}>
          <Modal.Content typeContainer="SCROLL">
            <YStack gap="$4">
              <Modal.Title asChild="except-style">
                <Heading5 variant="medium">
                  {t('NAVIGATION.PROFILE.ACCOUNT.DELETE.MODAL.TITLE')}
                </Heading5>
              </Modal.Title>
              <Paragraph2 color="$neutral400">
                {t('NAVIGATION.PROFILE.ACCOUNT.DELETE.MODAL.DESCRIPTION_1')}
                <Paragraph2
                  color="$primary"
                  cursor="pointer"
                  onPress={() => {
                    openURL(email);
                  }}
                >
                  {t('FOOTER.EMAIL')}
                  <Paragraph2 color="$neutral400">
                    {t('NAVIGATION.PROFILE.ACCOUNT.DELETE.MODAL.DESCRIPTION_3')}
                  </Paragraph2>
                </Paragraph2>
              </Paragraph2>
              <Paragraph2 color="$neutral400" variant="medium">
                {t('NAVIGATION.PROFILE.ACCOUNT.DELETE.MODAL.NOTE')}
              </Paragraph2>
              <Button secondary alignSelf="stretch" onPress={onAccept}>
                {t('NAVIGATION.PROFILE.ACCOUNT.DELETE.MODAL.BUTTON')}
              </Button>
            </YStack>
          </Modal.Content>
        </Modal>
      </YStack>
    </ResponsiveScrollView>
  );
}
