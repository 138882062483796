import {Card} from '@/components/cards/Card';
import {Label2} from '@/components/texts/Label';
import {Skeleton} from '@/components/views/Skeleton';
import React from 'react';
import {XStack} from 'tamagui';

type StrategyInfoScrollCardProps = {
  title: string;
  label: string | React.ReactNode;
  children: React.ReactNode | undefined;
};

export function StrategyInfoScrollCard({title, label, children}: StrategyInfoScrollCardProps) {
  return (
    <Card
      flex={0}
      flexGrow={1}
      $sm={{flexGrow: 0}}
      minWidth={200}
      alignContent="space-between"
      gap="$1"
    >
      <Label2 flex={1} variant="medium">
        {title}
      </Label2>
      <XStack gap="$3" alignItems="flex-end">
        <Skeleton height={35}>{children}</Skeleton>
        {typeof label === 'string' ? (
          <Label2 color="$neutral400" marginBottom="$1">
            {label}
          </Label2>
        ) : (
          label
        )}
      </XStack>
    </Card>
  );
}
