import {useTranslate} from '@tolgee/react';
import {Stack} from 'expo-router';
import React from 'react';
import {Platform} from 'react-native';
import {YStack} from 'tamagui';

import {ResponsiveScrollView} from '@/components/views/ResponsiveScrollView';
import {WebFooter} from '@/modules/navigation/components/footer/WebFooter';
import {Header} from '@/modules/navigation/components/header/Header';
import {LanguageDisplayMarketingChange} from '@/modules/profile/components/LanguageDisplayMarketingChange';

export default function TabDisplayAndLanguage() {
  const {t} = useTranslate();

  return (
    <ResponsiveScrollView>
      <YStack gap="$4">
        <Stack.Screen
          options={{
            headerShown: true,
            header: () => (
              <Header
                title={t('SETTINGS.DISPLAY_AND_LANGUAGE')}
                backButton={Platform.OS !== 'web'}
              />
            ),
          }}
        />
        <LanguageDisplayMarketingChange />
      </YStack>
      <WebFooter withCalendly />
    </ResponsiveScrollView>
  );
}
