import {useTranslate} from '@tolgee/react';
import {Href} from 'expo-router';
import React, {useCallback, useMemo} from 'react';
import {View, XStack, YStack, useMedia} from 'tamagui';

import {StrategyBadge} from '@/components/buttons/StrategyBadge';
import SvgFile from '@/components/icons/SvgFile';
import {Label2} from '@/components/texts/Label';
import {LocalizedDate} from '@/components/texts/LocalizedDate';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {Skeleton} from '@/components/views/Skeleton';
import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {useGetInterviewByProduct} from '@/modules/interview/hooks/useGetInterviewByProduct';
import {SummaryCard} from '@/modules/onboarding/components/summary/SummaryCard';
import {INCOME_WEALTH_ICONS} from '@/modules/onboarding/constants/income-wealth-icons';
import {useAppSelector} from '@/store';
import {
  useAcademicTitlesQuery,
  useCountriesQuery,
  useIncomeSourcesQuery,
  useIndustriesQuery,
  useProfessionsQuery,
  useTitlesQuery,
  useWealthSourcesQuery,
} from '@/store/queries/referenceApi';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';
import {OnboardingParams} from '@/types/routeParams';

type SummaryCardsProps = {
  params: OnboardingParams;
};

export function SummaryCards({params}: SummaryCardsProps) {
  const {t} = useTranslate();
  const media = useMedia();
  const {data: user} = useGetSelfUserQuery();
  const {data: titles} = useTitlesQuery();
  const {data: academicTitles} = useAcademicTitlesQuery();
  const {data: countries} = useCountriesQuery();
  const {data: professions} = useProfessionsQuery();
  const {data: industries} = useIndustriesQuery();
  const {data: incomeSources} = useIncomeSourcesQuery();
  const {data: wealthSources} = useWealthSourcesQuery();

  const planner = useAppSelector(state => state.onboarding.planner);
  const accountSettings = useAppSelector(state => state.onboarding.personalDetails.account);

  const {getByCode} = useRefdataLocalizable();

  const {getInterviewByProduct, isFetching} = useGetInterviewByProduct();

  const getPlannerHref = useCallback((): Href | undefined => {
    if (!planner) return undefined;

    const {product, onboardingType} = params;

    const interviewSummary = getInterviewByProduct(product, onboardingType === 'second_guardian');

    if (!interviewSummary) return undefined;

    return {
      pathname: '/interview/planner',
      params: {
        riskProfile: interviewSummary.riskProfile,
        initialDeposit: planner.initialDepositAmount?.toString(),
        initialMonthlySavings: planner.initialRecurringDepositAmount?.toString(),
        product,
        selectedStrategy: planner.selectedStrategy,
      },
    };
  }, [getInterviewByProduct, params, planner]);

  const mainTaxId = user?.taxIdentification?.find(_ => _.country === user?.address?.countryCode);
  const otherTaxIds = user?.taxIdentification?.filter(
    _ => _.country !== user?.address?.countryCode
  );

  const leftColumnCards = useMemo(() => {
    if (user && !isFetching) {
      return (
        <>
          <SummaryCard
            title={t('PERSONAL_DETAILS.NAME')}
            href={{pathname: '/onboarding/personal-details/name', params}}
            row={{
              [t('PERSONAL_DETAILS.SALUTATION')]: (
                <Label2 variant="medium">
                  {user.title && getByCode(titles, user.title)?.label}
                </Label2>
              ),
              [t('PERSONAL_DETAILS.NAME')]: (
                <Label2 variant="medium">{`${user.firstName} ${user.lastName}`}</Label2>
              ),
              [t('PERSONAL_DETAILS.ACADEMIC_TITLE')]: (
                <Label2 variant="medium">
                  {user.academicTitle ? getByCode(academicTitles, user.academicTitle)?.label : '--'}
                </Label2>
              ),
            }}
          />

          <SummaryCard
            title={t('PERSONAL_DETAILS.ADDRESS')}
            href={{pathname: '/onboarding/personal-details/address', params}}
            row={{
              [t('PERSONAL_DETAILS.ADDRESS')]: (
                <View alignItems="flex-end">
                  <Label2 variant="medium">
                    {user.address?.street} {user.address?.streetNumber}
                  </Label2>
                  <Label2 variant="medium">
                    {user.address?.postCode} {user.address?.city}
                  </Label2>
                  <Label2 variant="medium">
                    {user.address?.countryCode &&
                      getByCode(countries, user.address?.countryCode)?.label}
                  </Label2>
                </View>
              ),
            }}
          />

          <SummaryCard
            title={t('PERSONAL_DETAILS.DATE_AND_PLACE_OF_BIRTH')}
            href={{pathname: '/onboarding/personal-details/birth', params}}
            row={{
              [t('PERSONAL_DETAILS.DATE_OF_BIRTH')]: (
                <Label2 variant="medium">
                  {user.dateOfBirth && (
                    <LocalizedDate
                      color="$text"
                      date={user.dateOfBirth}
                      textComponent={Label2}
                      variant="medium"
                    />
                  )}
                </Label2>
              ),
              [t('PERSONAL_DETAILS.PLACE_OF_BIRTH')]: (
                <Label2 variant="medium">
                  {user.placeOfBirth},{' '}
                  {user.countryOfBirth && getByCode(countries, user.countryOfBirth)?.label}
                </Label2>
              ),
              ...(user.nationalities
                ? {
                    [t('PERSONAL_DETAILS.NATIONALITY')]: (
                      <YStack alignItems="flex-end" gap="$2">
                        {user.nationalities &&
                          Object.keys(user.nationalities).map((nationality, index) => (
                            <Label2 key={index} variant="medium">
                              {nationality && getByCode(countries, nationality)?.label}
                            </Label2>
                          ))}
                      </YStack>
                    ),
                  }
                : {}),
            }}
          />

          <SummaryCard
            title={t('PERSONAL_DETAILS.INCOME_AND_WEALTH')}
            href={{pathname: '/onboarding/personal-details/income', params}}
            row={{
              [t('PERSONAL_DETAILS.SOURCE_OF_INCOME')]: (
                <YStack alignItems="flex-end" gap="$2">
                  {user.incomeSources &&
                    user.incomeSources.map((incomeSource, index) => (
                      <XStack gap="$2" key={index}>
                        <SvgFile
                          size={16}
                          name={INCOME_WEALTH_ICONS[incomeSource]}
                          withCircle="onSurface"
                          color="$text"
                        />
                        <Label2 variant="medium">
                          {getByCode(incomeSources, incomeSource)?.label}
                        </Label2>
                      </XStack>
                    ))}
                </YStack>
              ),
              [t('PERSONAL_DETAILS.SOURCE_OF_WEALTH')]: (
                <YStack alignItems="flex-end" gap="$2">
                  {user.wealthSources &&
                    user.wealthSources.map((wealthSource, index) => (
                      <XStack gap="$2" key={index}>
                        <SvgFile
                          size={16}
                          name={INCOME_WEALTH_ICONS[wealthSource]}
                          withCircle="onSurface"
                          color="$text"
                        />
                        <Label2 variant="medium">
                          {getByCode(wealthSources, wealthSource)?.label}
                        </Label2>
                      </XStack>
                    ))}
                </YStack>
              ),
            }}
          />
        </>
      );
    } else return null;
  }, [
    academicTitles,
    countries,
    getByCode,
    incomeSources,
    isFetching,
    params,
    t,
    titles,
    user,
    wealthSources,
  ]);

  const rightColumnCards = useMemo(() => {
    if (user && !isFetching) {
      return (
        <>
          {planner && (
            <SummaryCard
              title={t('PERSONAL_DETAILS.INVESTMENT_DETAILS')}
              href={getPlannerHref()}
              row={{
                [t('PERSONAL_DETAILS.STARTING_INVESTMENT')]: (
                  <LocalizedNumbers
                    value={planner.initialDepositAmount}
                    variant="currency"
                    precision="decimals"
                    textComponent={Label2}
                    numberStyle={{variant: 'medium'}}
                  />
                ),
                [t('PERSONAL_DETAILS.USER_SUMMARY.MONTHLY_SAVINGS')]: (
                  <LocalizedNumbers
                    value={planner.initialRecurringDepositAmount}
                    variant="currency"
                    precision="decimals"
                    textComponent={Label2}
                    numberStyle={{variant: 'medium'}}
                  />
                ),
                [t('ORDER_SUMMARY.INVESTMENT-STRATEGY')]: planner.selectedStrategy ? (
                  <StrategyBadge strategy={planner.selectedStrategy} />
                ) : null,
              }}
            />
          )}

          <SummaryCard
            title={t('PERSONAL_DETAILS.TAX_RESIDENCE')}
            href={{pathname: '/onboarding/personal-details/tax', params}}
            row={
              user?.taxIdentification
                ? [...(mainTaxId ? [mainTaxId] : []), ...(otherTaxIds ?? [])].reduce(
                    (prev, curr, i) => {
                      const index = i === 0 ? '' : i + 1;
                      return {
                        ...prev,
                        ...{
                          [`${t('PERSONAL_DETAILS.COUNTRY')} ${index}`]: (
                            <Label2 variant="medium">
                              {curr.country && getByCode(countries, curr.country)?.label}
                            </Label2>
                          ),
                          [`${t('PERSONAL_DETAILS.TAX_IDENTIFICATION_NUMBER')} ${index}`]: (
                            <Label2 variant="medium">{curr.number}</Label2>
                          ),
                        },
                      };
                    },
                    {}
                  )
                : {}
            }
          />

          <SummaryCard
            title={t('PERSONAL_DETAILS.PROFESSION_AND_INDUSTRY')}
            href={{pathname: '/onboarding/personal-details/job', params}}
            row={{
              [t('PERSONAL_DETAILS.PROFESSION')]: (
                <Label2 variant="medium" textAlign="right">
                  {user.profession && getByCode(professions, user.profession)?.label}
                </Label2>
              ),
              [t('PERSONAL_DETAILS.INDUSTRY')]: (
                <Label2 variant="medium" textAlign="right">
                  {user.industry && getByCode(industries, user.industry)?.label}
                </Label2>
              ),
              [t('PERSONAL_DETAILS.BENEFICIAL_OWNER')]: (
                <Label2 variant="medium">
                  {user.beneficialOwner ? t('PERSONAL_DETAILS.YES') : t('PERSONAL_DETAILS.NO')}
                </Label2>
              ),
              [t('PERSONAL_DETAILS.TITLE_POLITICALLY_EXPOSED_PERSON')]: (
                <Label2 variant="medium">
                  {user.politicallyExposedPerson
                    ? t('PERSONAL_DETAILS.YES')
                    : t('PERSONAL_DETAILS.NO')}
                </Label2>
              ),
            }}
          />

          {params.product !== 'CHILD_ACCOUNT' && (
            <SummaryCard
              title={t('PERSONAL_DETAILS.BANK_DETAILS')}
              href={{pathname: '/onboarding/personal-details/bank', params}}
              row={{
                [t('ACCOUNT_DETAILS.ACCOUNT_HOLDER')]: (
                  <Label2 variant="medium">{`${user.firstName} ${user.lastName}`}</Label2>
                ),
                [t('ACCOUNT_DETAILS.IBAN')]: (
                  <Label2 variant="medium">{accountSettings.iban}</Label2>
                ),
                ...(accountSettings.bic
                  ? {
                      [t('ACCOUNT_DETAILS.BIC')]: (
                        <Label2 variant="medium">{accountSettings.bic}</Label2>
                      ),
                    }
                  : {}),
              }}
            />
          )}
        </>
      );
    } else return null;
  }, [
    accountSettings.bic,
    accountSettings.iban,
    countries,
    getByCode,
    getPlannerHref,
    industries,
    isFetching,
    params,
    planner,
    professions,
    t,
    user,
  ]);

  return (
    <YStack gap="$4">
      <Skeleton width="100%" height={140}>
        {media.sm ? (
          <XStack gap="$4" $sm={{flexWrap: 'wrap'}}>
            {leftColumnCards}
            {rightColumnCards}
          </XStack>
        ) : (
          <XStack gap="$4">
            <YStack gap="$4" flex={1}>
              {leftColumnCards}
            </YStack>

            <YStack gap="$4" flex={1}>
              {rightColumnCards}
            </YStack>
          </XStack>
        )}
      </Skeleton>
    </YStack>
  );
}
