import {AmountDecimalInput} from '@/components/inputs/AmountDecimalInput';
import {LabelCheckBox} from '@/components/inputs/LabelCheckbox';
import {Label3} from '@/components/texts/Label';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {Paragraph2} from '@/components/texts/Paragraph';
import {
  WithdrawalWarningBoxFirst,
  WithdrawalWarningBoxSecond,
  WithdrawalWarningBoxThird,
} from '@/modules/orders/components/Withdrawal/WithdrawalWarningBox';
import {roundValue} from '@/modules/orders/helpers/roundvalue';
import {
  GetValueFromInputType,
  WithDrawalOrderCase,
} from '@/modules/orders/hooks/useGetValueFromInput';
import {useGetPerformanceSummaryQuery} from '@/store/queries/apeironApi';
import {useGetCustomerAccountQuery} from '@/store/queries/customersApi';
import {isThemeCustomer} from '@/types/isThemeCustomer';
import {skipToken} from '@reduxjs/toolkit/query';
import {useTranslate} from '@tolgee/react';
import {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import {View, XStack, YStack, useMedia} from 'tamagui';

type WithdrawOrderInputProps = {
  customerId: string;
  onError: (valid: boolean) => void;
  setCase: (val: WithDrawalOrderCase) => void;
};

export const WithdrawOrderInput = forwardRef<GetValueFromInputType, WithdrawOrderInputProps>(
  function WithdrawOrderInput({customerId, onError, setCase}, ref) {
    const {data: customer} = useGetCustomerAccountQuery(customerId);
    const {data: performanceSummary, isFetching} = useGetPerformanceSummaryQuery(
      customerId
        ? {
            customerId,
          }
        : skipToken
    );

    const {t} = useTranslate();
    const [amount, setAmount] = useState<number>(0);
    const [withdrawalCase, setWithdrawalCase] = useState<WithDrawalOrderCase | undefined>(
      undefined
    );
    const [checked, setChecked] = useState<boolean>(false);
    const media = useMedia();

    const {currentBalance, balance50, balance90} = useMemo(() => {
      const currentBalance = roundValue(performanceSummary?.balance ?? 0);
      const balance50 = roundValue(currentBalance * 0.5);
      const balance90 = roundValue(currentBalance * 0.9);
      return {
        currentBalance,
        balance50,
        balance90,
      };
    }, [performanceSummary]);

    const remainder = useMemo(() => currentBalance - amount, [currentBalance, amount]);

    useImperativeHandle(ref, () => ({
      getValue: () => amount,
    }));

    useEffect(() => {
      if (withdrawalCase === undefined || withdrawalCase === '91to100') {
        onError(true);
      } else {
        onError(false);
      }
      setCase(withdrawalCase ?? 'NORMAL');
    }, [onError, setCase, withdrawalCase]);

    useEffect(() => {
      if (amount === 0) {
        setWithdrawalCase(undefined);
        if (checked) setChecked(false);
        return;
      }
      if (amount > 0 && amount < balance50) {
        setWithdrawalCase('NORMAL');
        if (checked) setChecked(false);
        return;
      }
      if (amount >= balance50 && amount <= balance90) {
        setWithdrawalCase('50to90');
        if (checked) setChecked(false);
        return;
      }
      if (amount > balance90 && amount < currentBalance) {
        setWithdrawalCase('91to100');
        if (checked) setChecked(false);
        return;
      }
      if (amount === currentBalance) {
        setWithdrawalCase('FULL');
        if (!checked) setChecked(true);
        return;
      }
      setWithdrawalCase(undefined);
    }, [amount, t, remainder, currentBalance, balance50, balance90, checked]);

    if (isThemeCustomer(customer)) return null;

    const onCheck = (flag: boolean) => {
      if (flag) {
        setAmount(currentBalance);
      } else {
        setAmount(0);
      }
    };

    const maximiseAmount = () => {
      setAmount(balance90);
    };

    return (
      <YStack gap="$6" marginBottom="$3">
        <View flexDirection="row" gap="$4" $sm={{flexDirection: 'column'}}>
          <YStack gap="$2">
            <AmountDecimalInput
              bordered
              value={amount}
              onValueChange={val => setAmount(val)}
              maxValue={currentBalance}
              onAboveMax={() => {}}
            />
            {currentBalance <= 0 && (
              <Label3 color="$red">{t('WITHDRAWAL_PAGE.FORM.AMOUNT.ZERO')}</Label3>
            )}
          </YStack>
          <View flexDirection="column" gap="$1" $sm={{flexDirection: 'row', alignSelf: 'center'}}>
            <Label3 color="$neutral400">{t('WITHDRAWAL_PAGE.FORM.REST.TITLE')}</Label3>
            <LocalizedNumbers value={remainder} textComponent={Label3} />
          </View>
        </View>
        {withdrawalCase === '91to100' && (
          <YStack gap="$3">
            <WithdrawalWarningBoxThird />
            <Paragraph2 color="$primary" onPress={maximiseAmount} cursor="pointer" flex="unset">
              {t('ORDER_SUMMARY.WITHDRAWAL.MAXIMISE-AMOUNT', '', {number: balance90})}
            </Paragraph2>
          </YStack>
        )}
        {media.sm === true && (
          <XStack
            justifyContent="space-between"
            backgroundColor="$neutralBG"
            padding="$4"
            borderRadius="$1"
          >
            <Label3 color="$neutral400">{t('ACCOUNT_DETAILS.ACCOUNT_NAME')}</Label3>
            <Label3>{customer?.displayName}</Label3>
          </XStack>
        )}
        {withdrawalCase === '91to100' && <WithdrawalWarningBoxSecond />}
        <LabelCheckBox
          label={t('ORDER_SUMMARY.FULL_WITHDRAWAL.CHECKBOX')}
          checked={checked}
          onCheckedChange={() => onCheck(!checked)}
        />
        {(withdrawalCase === '50to90' || withdrawalCase === 'FULL') && (
          <WithdrawalWarningBoxFirst />
        )}
      </YStack>
    );
  }
);
