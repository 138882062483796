import {baseApi} from '@/store/queries/baseApi';
import {UploadUrlRequest, UploadUrlResponse} from '@/types/api/media';

const BASE_PATH = 'media';

const extendedMediaApi = baseApi.injectEndpoints({
  endpoints: build => ({
    uploadFile: build.mutation<UploadUrlResponse, UploadUrlRequest>({
      query: data => ({
        url: `${BASE_PATH}/upload-urls`,
        method: 'POST',
        body: data,
        headers: {'Content-Type': 'application/json'},
      }),
    }),
  }),
});

export const {useUploadFileMutation} = extendedMediaApi;
