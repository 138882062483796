import {
  DepositOrderResponse,
  OrderResponse,
  WithdrawalOrderResponse,
} from '@/types/api/customers.v2';

export const isOrderWithAmount = (
  order: OrderResponse
): order is DepositOrderResponse | WithdrawalOrderResponse => {
  return ['DEPOSIT', 'WITHDRAWAL' satisfies OrderResponse['orderType']].includes(order.orderType);
};
