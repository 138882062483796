import {Button} from '@/components/buttons/Button';
import SvgFile from '@/components/icons/SvgFile';
import {Modal} from '@/components/modal/Modal';
import {Heading6} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {WithDrawalOrderCase} from '@/modules/orders/hooks/useGetValueFromInput';
import {useTranslate} from '@tolgee/react';
import {PropsWithChildren, useEffect, useMemo, useState} from 'react';
import {View, XStack, YStack} from 'tamagui';

type Props = {
  open: boolean;
  handleAgree: () => void;
  handleCancel: () => void;
  onClose: () => void;
  withdrawalCase: WithDrawalOrderCase;
};

export default function WithdrawalWarningModal({
  open,
  handleAgree,
  handleCancel,
  onClose,
  withdrawalCase,
}: PropsWithChildren<Props>) {
  const [internalOpen, setInternalOpen] = useState(false);
  const {t} = useTranslate();

  useEffect(() => {
    if (open === true) {
      setInternalOpen(true);
    }
  }, [open]);

  const handleAgreeInternal = () => {
    setInternalOpen(false);
    handleAgree();
    onClose();
  };

  const handleCancelInternal = () => {
    setInternalOpen(false);
    handleCancel();
    onClose();
  };

  const warningText = useMemo(() => {
    switch (withdrawalCase) {
      case '50to90':
        return {
          title: t(`ORDER_SUMMARY.WITHDRAWAL.MODAL.50to90.TITLE`),
          text: t(`ORDER_SUMMARY.WITHDRAWAL.MODAL.50to90.TEXT`),
        };
      case 'FULL':
        return {
          title: t(`ORDER_SUMMARY.WITHDRAWAL.MODAL.FULL.TITLE`),
          text: t(`ORDER_SUMMARY.WITHDRAWAL.MODAL.FULL.TEXT`),
        };
    }
  }, [t, withdrawalCase]);

  return (
    <>
      <Modal open={internalOpen}>
        <Modal.Content>
          <YStack padding="$3">
            <View
              width={64}
              height={64}
              backgroundColor="$yellowTransparent"
              borderRadius="$full"
              alignItems="center"
              justifyContent="center"
              alignSelf="center"
              margin="$5"
            >
              <SvgFile name="warning" size={32} color="$yellow" />
            </View>
            <Heading6 textAlign="center" variant="medium" marginBottom="$3">
              {warningText?.title}
            </Heading6>
            <Paragraph2 textAlign="center" marginBottom="$3">
              {warningText?.text}
            </Paragraph2>
            <XStack marginTop="$3">
              <YStack gap="$4" flex={1}>
                <Button alignSelf="stretch" onPress={handleAgreeInternal}>
                  {t('ORDER_SUMMARY.WITHDRAWAL.MODAL.AGREE')}
                </Button>
                <Button alignSelf="stretch" secondary onPress={handleCancelInternal}>
                  {t('ORDER_SUMMARY.WITHDRAWAL.MODAL.CANCEL')}
                </Button>
              </YStack>
            </XStack>
          </YStack>
        </Modal.Content>
      </Modal>
    </>
  );
}
