import {Button2} from '@/components/texts/Button';
import {LoadingSpinner} from '@/components/views/LoadingSpinner';
import {toast} from 'burnt';
import {useState} from 'react';
import {GestureResponderEvent} from 'react-native';
import {GetProps, View, YStack, createStyledContext, styled, withStaticProperties} from 'tamagui';

type ButtonComponentProps = Omit<GetProps<typeof ButtonFrame>, 'disabled'> & {
  /**
   * Only use boolean value if necessary, string will show a toast with explanation
   */
  disabled?: string | boolean;
};

export const ButtonContext = createStyledContext({
  secondary: false as boolean | undefined,
  tertiary: false as boolean | undefined,
  isLoading: false as boolean | undefined,
  size: 'small' as 'large' | 'small' | undefined,
});

// TODO: add proper pressStyle which works on web and native
const ButtonFrame = styled(View, {
  name: 'Button',
  context: ButtonContext,
  role: 'button',
  focusable: true,

  borderRadius: '$0.5',
  cursor: 'pointer',
  alignSelf: 'flex-start',
  alignItems: 'center',
  justifyContent: 'center',
  $sm: {
    alignSelf: 'stretch',
  },
  pressStyle: {
    scale: 0.97,
  },
  disabledStyle: {
    opacity: 0.6,
  },
  animation: 'fast',
  backgroundColor: '$primary',
  variants: {
    secondary: {
      true: {
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: '$primary',
      },
    },
    tertiary: {
      true: {
        borderColor: 'transparent',
        backgroundColor: '$neutralBG',
      },
    },
    isLoading: {
      true: {},
    },
    size: {
      large: {
        height: 50,
        paddingHorizontal: '$7',
        $sm: {
          height: 52,
          paddingHorizontal: '$7',
        },
      },
      small: {
        height: 40,
        paddingHorizontal: '$6',
        $sm: {
          height: 44,
          paddingHorizontal: '$6',
        },
      },
    },
  } as const,
});

// TODO: I have not found out yet how to pass it as direct children without needing this component
const ButtonText = styled(Button2, {
  name: 'ButtonText',
  context: ButtonContext,
  color: '$white',
  userSelect: 'none',
  variant: 'medium',
  variants: {
    secondary: {
      true: {
        color: '$primary',
      },
    },
    tertiary: {
      true: {
        color: '$primary',
      },
    },
    isLoading: {
      true: {
        color: 'transparent',
      },
    },
    size: {
      large: {
        fontSize: 16,
        lineHeight: 24,
        $sm: {
          fontSize: 16,
          lineHeight: 24,
        },
      },
      small: {
        fontSize: 14,
        lineHeight: 22,
        $sm: {
          fontSize: 14,
          lineHeight: 20,
        },
      },
    },
  } as const,
});

const ButtonSpinner = styled(LoadingSpinner, {
  name: 'ButtonSpinner',
  size: 24,
  color: '$white',
  variants: {
    secondary: {
      true: {
        color: '$primary',
      },
    },
    tertiary: {
      true: {
        color: '$primary',
      },
    },
  } as const,
});

const ButtonComponent = ButtonFrame.styleable<ButtonComponentProps>(function Button(props, ref) {
  const [isLoading, setIsLoading] = useState(false);
  const newIsLoading = props.isLoading !== undefined ? props.isLoading : isLoading;
  const [buttonLayout, setButtonLayout] = useState({width: 0, height: 0});
  const modifiedOnPress = async (e: GestureResponderEvent) => {
    setIsLoading(true);
    try {
      await props?.onPress?.(e);
    } catch (e) {
      throw e;
    } finally {
      setIsLoading(false);
    }
  };
  const children =
    typeof props.children === 'string' ? <ButtonText>{props.children}</ButtonText> : props.children;
  return (
    <YStack>
      <ButtonFrame
        {...props}
        isLoading={newIsLoading}
        onPress={modifiedOnPress}
        disabled={newIsLoading || Boolean(props.disabled)}
        ref={ref}
        onLayout={e =>
          setButtonLayout({width: e.nativeEvent.layout.width, height: e.nativeEvent.layout.height})
        }
      >
        <>
          {children}
          {newIsLoading && (
            <View
              position="absolute"
              alignItems="center"
              justifyContent="center"
              top={0}
              bottom={0}
            >
              <ButtonSpinner secondary={props.secondary} tertiary={props.tertiary} />
            </View>
          )}
        </>
      </ButtonFrame>
      {Boolean(props.disabled) && typeof props.disabled === 'string' && (
        <View
          cursor="pointer"
          position="absolute"
          height={buttonLayout.height}
          width={buttonLayout.width}
          onPress={() => {
            toast({
              title: props.disabled as string,
              preset: 'error',
              duration: 2,
              shouldDismissByDrag: true,
              from: 'bottom',
            });
          }}
        />
      )}
    </YStack>
  );
});

/**
 * If async onPress is passed it will automatically throttle and show loading spinner
 * disabled prop can be a string which will show a toast with the string as the title
 */
export const Button = withStaticProperties(ButtonComponent, {
  Props: ButtonContext.Provider,
});

export type ButtonProps = GetProps<typeof ButtonFrame>;
