import {IconButton} from '@/components/buttons/IconButton';
import {Label2} from '@/components/texts/Label';
import {LocalizedDate} from '@/components/texts/LocalizedDate';
import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {
  useInboxDocumentCategoriesQuery,
  useInboxDocumentSourcesQuery,
} from '@/store/queries/referenceApi';
import {InboxDocumentResponse} from '@/types/api/customers.v2/types.gen';
import {Linking} from 'react-native';
import {View, XStack, useMedia} from 'tamagui';

type DocumentItemProps = {
  item: InboxDocumentResponse;
};

export function DocumentItem({item: {category, link, source, publishDate}}: DocumentItemProps) {
  const {data: inboxDocumentCategories} = useInboxDocumentCategoriesQuery();
  const {data: inboxDocumentSources} = useInboxDocumentSourcesQuery();
  const {getByCode} = useRefdataLocalizable();

  const media = useMedia();
  return (
    <XStack alignItems="center" cursor="pointer" onPress={() => Linking.openURL(link)}>
      <Label2 flex={1} flexBasis={0}>
        {getByCode(inboxDocumentCategories, category)?.label}
      </Label2>
      <View flexDirection={media.sm ? 'column' : 'row'} flex={1} flexBasis={0}>
        <Label2 flex={1} flexBasis={0}>
          {getByCode(inboxDocumentSources, source)?.label}
        </Label2>
        <LocalizedDate color="$text" date={publishDate} flex={1} flexBasis={0} />
      </View>
      <IconButton
        name="download"
        size={24}
        color="$primary"
        onPress={() => Linking.openURL(link)}
      />
    </XStack>
  );
}
