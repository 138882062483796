import {Children, PropsWithChildren, useCallback, useMemo, useRef, useState} from 'react';
import {LayoutChangeEvent} from 'react-native';
import {GetProps, ScrollView, View, XStack, YStack, getTokenValue, useMedia} from 'tamagui';

import {FixedTabbar} from '@/modules/navigation/components/tabbar/FixedTabbar';
import {ScrolledTabbar} from '@/modules/navigation/components/tabbar/ScrolledTabbar';
import {TabbarItem} from '@/modules/navigation/components/tabbar/TabbarItem';
import {Variant} from '@/modules/navigation/types/Tabbar';

export type Tab = {
  label: string;
  onPress?: () => void;
};

type Props = {
  scroll?: boolean;
  forcedFixed?: boolean;
  elements: Tab[];
  defaultSelectedIndex?: number;
  wrapperProps?: GetProps<typeof YStack>;
  /**
   * If placed on background directly the tabbar has different colors
   * Defaults to 'onSurface' to be used on cards
   */
  variant?: Variant;
  /**
   * If true, the tab items will only take the width of the label
   */
  fitContent?: boolean;
};

export const Tabbar = ({
  elements,
  defaultSelectedIndex = 0,
  wrapperProps,
  children,
  variant = 'onSurface',
  fitContent = false,
  scroll,
  forcedFixed,
}: PropsWithChildren<Props>) => {
  const media = useMedia();

  const PADDING = getTokenValue('$2');

  const [selectedIndex, setSelectedIndex] = useState(
    defaultSelectedIndex > elements.length - 1 ? 0 : defaultSelectedIndex
  );
  const [containerHeight, setContainerHeight] = useState(0);
  const [sizes, setSizes] = useState<number[]>(elements.map(() => 0));

  const scrollViewRef = useRef<ScrollView>(null);

  const scrollInner = useMemo(() => {
    if (forcedFixed) return false;

    return media.sm ? true : scroll;
  }, [scroll, media, forcedFixed]);

  const TabbarContainer = scrollInner ? ScrolledTabbar : FixedTabbar;

  const handleContainerLayout = useCallback((e: LayoutChangeEvent) => {
    setContainerHeight(e.nativeEvent.layout.height);
  }, []);

  const handleTabbarItemLayout = useCallback(
    (index: number) => (event: LayoutChangeEvent) => {
      if (event.persist) {
        event.persist();
      }

      setSizes(_ => {
        const tmp = _;

        tmp[index] = event.nativeEvent.layout.width;

        return [...tmp];
      });
    },
    []
  );

  const getLeftPosition = useCallback(
    (index: number) => {
      const paddings = (index + 1) * PADDING;
      const sumElementsSize = sizes.slice(0, index).reduce((a, b) => a + b, 0);

      return paddings + sumElementsSize;
    },
    [PADDING, sizes]
  );

  const handleTabbarItemPressed = useCallback(
    (index: number, onPress?: () => void) => () => {
      setSelectedIndex(index);

      if (scrollInner && scrollViewRef.current) {
        let x = getLeftPosition(index);

        if (index === 0) {
          x = 0;
        }

        scrollViewRef.current.scrollTo({x, y: 0, animated: true});
      }

      if (onPress) {
        onPress();
      }
    },
    [getLeftPosition, scrollInner]
  );

  const sliderPosition = useMemo(() => {
    return getLeftPosition(selectedIndex);
  }, [getLeftPosition, selectedIndex]);

  return (
    <YStack gap="$3" {...wrapperProps}>
      <TabbarContainer ref={scrollViewRef} variant={variant} onLayout={handleContainerLayout}>
        <View
          backgroundColor={variant === 'onSurface' ? '$surface' : '$surfaceRaised'}
          borderRadius="$0.5"
          height={containerHeight - 1.5 * PADDING}
          width={sizes[selectedIndex]}
          position="absolute"
          left={sliderPosition}
          top={0.75 * PADDING}
          animation="slow"
        />
        {elements.map(({label, onPress}, index) => (
          <TabbarItem
            key={index}
            label={label}
            fitContent={fitContent}
            onLayout={handleTabbarItemLayout(index)}
            onPress={handleTabbarItemPressed(index, onPress)}
            selected={selectedIndex === index}
          />
        ))}
      </TabbarContainer>
      <XStack width="100%">
        {Children.map(children, (child, i) => {
          return i === selectedIndex ? child : null;
        })}
      </XStack>
    </YStack>
  );
};
