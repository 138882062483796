import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import {isDateInPast} from '@/helpers/isDateInPast';
import useAppState from '@/hooks/useAppState';
import {useInterval} from '@/hooks/useInterval';
import {useStorageState} from '@/hooks/useStorageState';
import {setSentryUser} from '@/providers/SentryProvider';
import {DISCOVERY, STORAGE_KEYS} from '@/providers/session-provider/constants';
import {useCustomAuthRequest} from '@/providers/session-provider/useCustomAuthRequest';
import {refreshTokens, signOut} from '@/providers/session-provider/utils';
import {useAppDispatch, useAppSelector} from '@/store';
import {baseApi} from '@/store/queries/baseApi';
import {setRedirectUrl} from '@/store/reducers/app';
import {setAccessToken, setTokenEmail} from '@/store/reducers/auth';
import {resetInterviewState} from '@/store/reducers/interview';
import {resetOnboardingState} from '@/store/reducers/onboarding';
import {KeycloakUserInfo} from '@/types/KeycloakUserInfo';
import {JWTUserData} from '@/types/jwt';
import {fetchUserInfoAsync} from 'expo-auth-session/build/TokenRequest';
import {useRouter} from 'expo-router';
import {jwtDecode} from 'jwt-decode';

const AuthContext = createContext<{
  signIn: () => void;
  signOut: () => void;
  session?: boolean;
  isLoading: boolean;
  isMfaSession: () => boolean;
  isUserExists: boolean;
  isUpvestFeatureFlagEnabled: boolean;
  getUserInfo: () => Promise<KeycloakUserInfo | null>;
  signUp: () => void;
  getIsActive: () => boolean;
  setIsActive: () => boolean;
} | null>(null);

// This hook can be used to access the user info.
export function useSession() {
  const value = useContext(AuthContext);
  if (!value) {
    throw new Error('useSession must be wrapped in a <SessionProvider />');
  }

  return value;
}

export function SessionProvider(props: PropsWithChildren) {
  const isActive = useRef(false);
  const [[idLoading, idToken], setIdToken] = useStorageState(STORAGE_KEYS.ID_TOKEN);
  const [[refreshTokenLoading, refreshToken], setRefreshToken] = useStorageState(
    STORAGE_KEYS.REFRESH_TOKEN
  );
  const accessToken = useAppSelector(state => state.auth.accessToken);
  const router = useRouter();
  const dispatch = useAppDispatch();
  const {appState} = useAppState();
  const tokenEmail = useAppSelector(state => state.auth.tokenEmail);

  const {
    response: signUpResponse,
    promptAsync: signUp,
    reset: resetSignUp,
  } = useCustomAuthRequest('signup');
  const {
    response: signInResponse,
    promptAsync: signIn,
    reset: resetSignIn,
  } = useCustomAuthRequest('login');

  const getIsActive = useCallback(() => isActive.current, []);
  const setIsActive = useCallback(() => (isActive.current = true), []);

  const handleSuccessAuth = useCallback(async () => {
    // response either coming from login or signup auth request
    const authRes = signInResponse || signUpResponse;
    if (authRes) {
      // Redirected back from keycloak
      if (authRes.idToken && authRes.refreshToken) {
        dispatch(baseApi.util.resetApiState()); // needed to refresh cache when switching between users
        setIdToken(authRes.idToken);
        dispatch(setAccessToken(authRes.accessToken));
        setIsActive();
        setRefreshToken(authRes.refreshToken);
        setSentryUser(authRes.accessToken);
        const sub = jwtDecode(authRes.idToken).sub;
        dispatch(setTokenEmail(sub));
        if (tokenEmail !== undefined && sub !== undefined && sub !== tokenEmail) {
          dispatch(resetInterviewState());
          dispatch(resetOnboardingState());
          dispatch(setRedirectUrl());
        }
      }
      resetSignUp();
      resetSignIn();
      dispatch(setRedirectUrl());
      router.replace('/');
    } else {
      // App was opened but accessToken still is in storage
    }
  }, [
    signInResponse,
    signUpResponse,
    resetSignUp,
    resetSignIn,
    router,
    dispatch,
    setIdToken,
    setIsActive,
    setRefreshToken,
    tokenEmail,
  ]);

  useEffect(() => {
    handleSuccessAuth().catch(console.error);
  }, [handleSuccessAuth]);

  const refreshSession = useCallback(async () => {
    // only update when app is active
    if (appState === 'active' && refreshToken && accessToken) {
      await refreshTokens();
    }
  }, [accessToken, appState, refreshToken]);

  useInterval(refreshSession, {delay: 30000, focus: false});

  const isMfaSession = useCallback(() => {
    if (!accessToken) return false;
    const tokenConfig = jwtDecode<JWTUserData>(accessToken);
    if (!tokenConfig?.mfa_session?.expires_at) return false;
    // We add 1 minute to the expiry date to avoid edge cases
    const expiryDate = Date.parse(`${tokenConfig?.mfa_session?.expires_at}Z`) + 60 * 1000;
    return !isDateInPast(expiryDate);
  }, [accessToken]);

  const isUpvestFeatureFlagEnabled = useMemo(() => {
    if (!accessToken) return false;
    const tokenConfig = jwtDecode<JWTUserData>(accessToken);
    return tokenConfig?.features?.['upvest.investment_account.enabled'];
  }, [accessToken]);

  const getUserInfo = useCallback(async () => {
    if (!accessToken) return null;
    try {
      const userInfo = await fetchUserInfoAsync(
        {
          accessToken,
        },
        DISCOVERY
      );
      return userInfo as KeycloakUserInfo;
    } catch (error) {
      await refreshTokens();
      return null;
    }
  }, [accessToken]);

  const isUserExists = useMemo(() => {
    if (!accessToken) return false;
    const tokenConfig = jwtDecode<JWTUserData>(accessToken);
    return tokenConfig.user_exists;
  }, [accessToken]);

  return (
    <AuthContext.Provider
      value={{
        signOut,
        /**
         * Returns true if user has a active or inactive session
         */
        session: !!accessToken,
        isLoading: refreshTokenLoading,
        isMfaSession,
        isUserExists,
        isUpvestFeatureFlagEnabled,
        getUserInfo,
        signIn,
        signUp,
        getIsActive,
        setIsActive,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
