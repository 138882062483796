import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {Proposal} from '@/modules/onboarding/types/proposal';
import {CreateInterviewResponse} from '@/store/queries/interviewApi';
import {
  AccountSettings,
  AnotherGuardianInfo,
  Employer,
  GuardianDocuments,
  InitialDeposit,
  OnboardingState,
  Planner,
} from '@/store/reducers/onboarding/state';

const initialState: OnboardingState = {
  personalDetails: {
    account: {
      bic: '',
      iban: '',
      bank: '',
    },
  },
};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    saveAccountSettings: (state, action: PayloadAction<Partial<AccountSettings>>) => {
      state.personalDetails.account = {...state.personalDetails.account, ...action.payload};
    },
    saveInitialDeposit: (state, action: PayloadAction<InitialDeposit | undefined>) => {
      state.personalDetails.initialDeposit = action.payload;
    },
    saveInitialDepositAmount: (state, action: PayloadAction<number>) => {
      if (state.personalDetails.initialDeposit !== undefined) {
        state.personalDetails.initialDeposit.amount = action.payload;
      }
    },
    saveEmployer: (state, action: PayloadAction<Partial<Employer>>) => {
      state.personalDetails.employer = {...state.personalDetails.employer, ...action.payload};
    },
    savePlanner: (state, action: PayloadAction<Partial<Planner>>) => {
      state.planner = {...state.planner, ...action.payload};
    },
    saveInterviewId: (state, action: PayloadAction<string>) => {
      state.interviewId = action.payload;
    },
    saveProposal: (state, action: PayloadAction<Proposal>) => {
      state.proposal = action.payload;
    },
    savePublicInterview: (state, action: PayloadAction<CreateInterviewResponse>) => {
      state.publicInterview = action.payload;
    },
    setAnotherGuardianInfo: (state, action: PayloadAction<AnotherGuardianInfo | undefined>) => {
      state.personalDetails.anotherGuardian = action.payload;
    },
    setGuardianDocuments: (state, action: PayloadAction<GuardianDocuments | undefined>) => {
      state.personalDetails.guardianDocuments = action.payload;
    },
    setReferralCode: (state, action: PayloadAction<string | undefined>) => {
      state.referralCode = action.payload;
    },
    resetOnboardingState: () => {
      return {
        ...initialState,
      };
    },
  },
});

export const {
  saveAccountSettings,
  saveInitialDeposit,
  saveInitialDepositAmount,
  saveEmployer,
  savePlanner,
  saveInterviewId,
  saveProposal,
  savePublicInterview,
  setAnotherGuardianInfo,
  setGuardianDocuments,
  setReferralCode,
  resetOnboardingState,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;
