import {LabelCard} from '@/components/cards/LabelCard';
import {Paragraph2} from '@/components/texts/Paragraph';
import {DocumentItem} from '@/modules/account-activity/components/DocumentItem';
import {OrderItem} from '@/modules/account-activity/components/OrderItem';
import {TransactionItem} from '@/modules/account-activity/components/TransactionItem';
import {useGroupByMonth} from '@/modules/account-activity/hooks/useGroupByMonth';
import {
  TransformedInboxDocumentListResponse,
  TransformedInboxDocumentResponse,
} from '@/modules/account-activity/types/TransformedInboxDocumentResponse';
import {PaginatedResponse, TransactionsDTO} from '@/types/api/apeiron';
import {
  FixedOrderListResponse,
  FixedOrderResponse,
} from '@/types/fixedTypes/customers.v2/OrderListResponse';
import {FlashList} from '@shopify/flash-list';
import {useTranslate} from '@tolgee/react';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {RefreshControl} from 'react-native';
import {Spinner, YStack, getTokens, useMedia, useTheme} from 'tamagui';

type AccountActivityListProps = {
  isFetching: boolean;
  onRefresh: () => void;
  onLoadMore: () => void;
  customerId: string;
} & (TransactionListProps | OrdersListProps | DocumentsListProps);

type TransactionListProps = {
  transactionsResponse?: PaginatedResponse | undefined;
  ordersResponse?: never;
  documentsResponse?: never;
};

type OrdersListProps = {
  transactionsResponse?: never;
  ordersResponse?: FixedOrderListResponse | undefined;
  documentsResponse?: never;
};

type DocumentsListProps = {
  transactionsResponse?: never;
  ordersResponse?: never;
  documentsResponse?: TransformedInboxDocumentListResponse | undefined;
};

const isTransactionList = (
  transactionsResponse: PaginatedResponse | undefined,
  item: ListItemType[]
): item is TransactionsDTO[] => {
  return transactionsResponse !== undefined;
};

const isOrdersList = (
  ordersResponse: FixedOrderListResponse | undefined,
  item: ListItemType[]
): item is FixedOrderResponse[] => {
  return ordersResponse !== undefined;
};

const isDocumentsList = (
  documentsResponse: TransformedInboxDocumentListResponse | undefined,
  item: ListItemType[]
): item is TransformedInboxDocumentResponse[] => {
  return documentsResponse != undefined;
};

type ListItemType = TransactionsDTO | FixedOrderResponse | TransformedInboxDocumentResponse;

export function AccountActivityList({
  transactionsResponse,
  ordersResponse,
  documentsResponse,
  isFetching,
  customerId,
  onRefresh,
  onLoadMore,
}: AccountActivityListProps) {
  const media = useMedia();

  const response = useMemo(
    () => transactionsResponse || ordersResponse || documentsResponse,
    [transactionsResponse, ordersResponse, documentsResponse]
  );
  const items = useMemo(() => response?.items, [response]);

  const itemsCount = useMemo(() => {
    return response?.totalCount;
  }, [response]);

  const listRef = useRef<FlashList<ListItemType[]>>(null);
  const listData = useGroupByMonth<ListItemType>(
    items || [],
    documentsResponse === undefined ? 'createdAt' : 'publishDate'
  );
  const theme = useTheme();
  const {t} = useTranslate();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const isEndReached = items && itemsCount !== undefined && items.length >= itemsCount;

  // TODO: rewrite this to be per activity type
  // TODO: render method should be as fast as possible
  const renderItem = useCallback(
    ({item: items, index}: {item: ListItemType[]; index: number}) => {
      if (isTransactionList(transactionsResponse, items)) {
        const date = items[0].createdAt;
        return (
          <LabelCard date={date} marginBottom={index === listData.length - 1 ? 0 : '$3'}>
            <YStack gap="$3">
              {items.map((item, index) => (
                <TransactionItem key={item.id} item={item} />
              ))}
            </YStack>
          </LabelCard>
        );
      } else if (isOrdersList(ordersResponse, items)) {
        const date = items[0].createdAt;
        return (
          <LabelCard date={date} marginBottom={index === listData.length - 1 ? 0 : '$3'}>
            <YStack gap="$3">
              {items.map((item, index) => (
                <OrderItem key={item.id} item={item} customerId={customerId} />
              ))}
            </YStack>
          </LabelCard>
        );
      } else if (isDocumentsList(documentsResponse, items)) {
        const date = items[0].publishDate;
        return (
          <LabelCard date={date} marginBottom={index === listData.length - 1 ? 0 : '$3'}>
            <YStack gap="$3">
              {items.map((item, index) => (
                <DocumentItem key={item.id} item={item} />
              ))}
            </YStack>
          </LabelCard>
        );
      } else return null;
    },
    [transactionsResponse, ordersResponse, documentsResponse, listData.length, customerId]
  );

  const handleLoadMore = useCallback(() => {
    if (!isEndReached) {
      onLoadMore();
    }
  }, [onLoadMore, isEndReached]);

  useEffect(() => {
    setIsRefreshing(false);
  }, [isFetching, setIsRefreshing]);

  return (
    <FlashList<ListItemType[]>
      ref={listRef}
      data={listData}
      renderItem={renderItem}
      estimatedItemSize={90}
      onEndReachedThreshold={0.5}
      keyExtractor={item => item.at(0)?.id!}
      contentContainerStyle={{
        paddingLeft: getTokens().size.$4.val,
        paddingRight: media.sm ? getTokens().size.$4.val : getTokens().size.$6.val,
      }}
      onEndReached={handleLoadMore}
      ListFooterComponent={
        !isEndReached && isFetching ? <Spinner size="large" marginTop="$3" /> : undefined
      }
      refreshControl={
        <RefreshControl
          refreshing={itemsCount !== 0 && isRefreshing}
          onRefresh={() => {
            setIsRefreshing(true);
            onRefresh();
          }}
          tintColor={theme.primary.val}
        />
      }
      ListEmptyComponent={
        itemsCount === 0 ? (
          <Paragraph2>{t('NO_ELEMENTS_EXIST')}</Paragraph2> // TODO: add illustration when it's ready
        ) : null
      }
    />
  );
}
