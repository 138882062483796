import {SvgName} from '@/components/icons/SvgFile';

export const INCOME_WEALTH_ICONS = {
  SAL: 'suitcase',
  SEE: 'laptop',
  AGF: 'tree',
  CAA: 'money-coins',
  REE: 'houses',
  BUS: 'stall',
  OTH: 'sparkle',
  INH: 'building',
  DON: 'hand-with-coins',
  REL: 'houses',
} as Record<string, SvgName>;
