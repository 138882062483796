import {LanguageSvg} from '@/components/icons/LanguageSvg';
import {SelectInput} from '@/components/inputs/select-input/SelectInput';
import {Label2} from '@/components/texts/Label';
import {SideNavigationItemWrapper} from '@/modules/navigation/components/SideNavigationItem';
import {ProfileListItem} from '@/modules/profile/components/ProfileListItem';
import {useAppDispatch, useAppSelector} from '@/store';
import {setLanguage} from '@/store/reducers/app';
import {SelectInputItem} from '@/types/FormInputProps';
import {LanguagesType} from '@/types/languages';
import {useTranslate} from '@tolgee/react';
import React, {useMemo} from 'react';
import {GetProps, isTouchable, useMedia} from 'tamagui';

export function LanguageSelector({
  wrapperProps,
}: {
  wrapperProps?: GetProps<typeof SelectInput>['wrapperProps'];
}) {
  const language = useAppSelector(state => state.app.language);
  const dispatch = useAppDispatch();
  const media = useMedia();
  const {t} = useTranslate();
  const items = useMemo(
    () =>
      [
        {
          key: 'de',
          name: t('SETTINGS.LANGUAGE.DE'),
          icon: 'flag-de',
        },
        {
          key: 'en',
          name: t('SETTINGS.LANGUAGE.EN'),
          icon: 'flag-gb',
        },
      ] satisfies SelectInputItem[],
    [t]
  );

  return (
    <SelectInput
      items={items}
      placeholder={t('SETTINGS.SELECT_APP_LANGUAGE')}
      onSelect={(item: string) => {
        dispatch(setLanguage(item as LanguagesType));
      }}
      selectedItem={language}
      cardProps={{
        minWidth: 150,
      }}
      wrapperProps={{
        unstyled: true,
        ...wrapperProps,
      }}
    >
      {media.sm || isTouchable ? (
        <ProfileListItem
          label={t('SETTINGS.SELECT_APP_LANGUAGE')}
          value={language === 'de' ? t('SETTINGS.LANGUAGE.DE') : t('SETTINGS.LANGUAGE.EN')}
        />
      ) : (
        <SideNavigationItemWrapper paddingLeft="$7" x={5} paddingVertical="$4" width="100%">
          <LanguageSvg language={language} />
          {media.gtSm && !isTouchable && (
            <Label2 variant="medium" userSelect="none" numberOfLines={1}>
              {language === 'de' ? t('SETTINGS.LANGUAGE.DE') : t('SETTINGS.LANGUAGE.EN')}
            </Label2>
          )}
        </SideNavigationItemWrapper>
      )}
    </SelectInput>
  );
}
