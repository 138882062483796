import {Heading6} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {TipWithSideModal} from '@/modules/interview/components/TipWithSideModal';
import {UserNameScreen} from '@/modules/onboarding/components/UserNameScreen';
import {useOnboardingSearchParams} from '@/modules/onboarding/hooks/useOnboardingSearchParams';
import {useTranslate} from '@tolgee/react';
import {YStack} from 'tamagui';

const NameScreen = () => {
  const params = useOnboardingSearchParams();
  const {t} = useTranslate();
  const tipContent = (
    <YStack gap="$5" marginVertical="$5">
      <Heading6 variant="medium">{t('ONBOARDING.FAQ.NAME.HEADER')}</Heading6>
      <Paragraph2 variant="medium">{t('ONBOARDING.FAQ.NAME.TITLE_DESCRIPTION_1')}</Paragraph2>
      <Paragraph2 color="$neutral400">{t('ONBOARDING.FAQ.NAME.DESCRIPTION_1')}</Paragraph2>
    </YStack>
  );

  return (
    <FullscreenScrollView variant="small" useKeyboardAwareScrollView>
      <UserNameScreen params={params} />
      <TipWithSideModal tipTitle={t('ONBOARDING.FAQ.NAME.TITLE')} tipContent={tipContent} />
    </FullscreenScrollView>
  );
};

export default NameScreen;
