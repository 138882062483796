import SvgFile from '@/components/icons/SvgFile';
import {Label2} from '@/components/texts/Label';
import {AnimatedHeightView} from '@/components/views/AnimatedHeightView';
import {Href, usePathname, useRouter} from 'expo-router';
import {PropsWithChildren} from 'react';
import {GetProps, View, XStack, styled} from 'tamagui';

type LinkProps = {
  href: Href;
  onPress?: never;
};

type ButtonProps = {
  onPress: () => void;
  href?: Href;
};

export type SideNavigationItemProps = {
  title: string;
  icon: GetProps<typeof SvgFile>['name'];
  children?: React.ReactNode;
} & (LinkProps | ButtonProps);

export function SideNavigationItem({
  title,
  href,
  onPress,
  icon,
  children,
}: PropsWithChildren<SideNavigationItemProps>) {
  const path = usePathname();
  const router = useRouter();
  const isActive = href && path.split('/')[1] === href.toString().split('/')[1];

  return (
    <>
      <SideNavigationItemWrapper onPress={onPress || (() => router.navigate(href))}>
        <View
          backgroundColor="$primary"
          width={5}
          x={isActive ? 0 : -10}
          opacity={isActive ? 1 : 0}
          height="100%"
          marginRight="$6"
          paddingVertical="$7"
          borderTopRightRadius={16}
          borderBottomRightRadius={16}
          animation="slow"
        />
        <SvgFile name={icon} size={24} color={isActive ? '$primary' : '$text'} />
        <Label2
          variant="medium"
          userSelect="none"
          color={isActive ? '$primary' : '$text'}
          numberOfLines={1}
        >
          {title}
        </Label2>
      </SideNavigationItemWrapper>
      <AnimatedHeightView>
        {isActive && (
          <XStack
            marginLeft="$8"
            paddingRight="$4"
            animation="slow"
            enterStyle={{
              opacity: 0,
              y: -10,
            }}
            gap="$3"
          >
            <View backgroundColor="$neutral100" width="$0.5" />
            <View width={205}>{children}</View>
          </XStack>
        )}
      </AnimatedHeightView>
    </>
  );
}

export const SideNavigationItemWrapper = styled(XStack, {
  gap: '$2',
  alignItems: 'center',
  cursor: 'pointer',
  overflow: 'hidden',
  paddingRight: '$8',
  hoverStyle: {
    opacity: 0.8,
  },
});
