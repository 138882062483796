import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {IncomeOrWealthScreen} from '@/modules/onboarding/components/IncomeOrWealthScreen';
import {useOnboardingSearchParams} from '@/modules/onboarding/hooks/useOnboardingSearchParams';

const Wealth = () => {
  const params = useOnboardingSearchParams();

  return (
    <FullscreenScrollView variant="small">
      <IncomeOrWealthScreen
        type="wealth"
        href={{pathname: '/onboarding/personal-details/tax', params}}
      />
    </FullscreenScrollView>
  );
};

export default Wealth;
