import {useFocusEffect, useRouter} from 'expo-router';
import {useCallback} from 'react';
import {View} from 'tamagui';

import {LoadingSpinner} from '@/components/views/LoadingSpinner';
import {useOnboardingSearchParams} from '@/modules/onboarding/hooks/useOnboardingSearchParams';
import {useGetLatestAcceptableInterviewsQuery} from '@/store/queries/interviewApi';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';

const PersonalDetails = () => {
  const router = useRouter();

  const params = useOnboardingSearchParams();

  const {data: selfUser} = useGetSelfUserQuery();
  const {data: latestAcceptableInterviewsQuery} = useGetLatestAcceptableInterviewsQuery();

  const focusCallback = useCallback(() => {
    if (!selfUser || !latestAcceptableInterviewsQuery) return;

    if (!selfUser.complete) {
      router.replace({pathname: '/onboarding/personal-details/name', params});

      return;
    }

    if (params.onboardingType === 'second_guardian') {
      const interview = latestAcceptableInterviewsQuery.find(
        interview => interview.type === 'BASIC_V2'
      );

      if (!interview) {
        router.replace({pathname: '/interview/question', params});

        return;
      }

      router.replace({
        pathname: '/onboarding/personal-details/summary',
        params: {...params, screenType: 'child'},
      });

      return;
    }

    if (params.product === 'VL_ACCOUNT') {
      router.replace({pathname: '/onboarding/personal-details/vl-start', params});

      return;
    }

    if (params.product === 'THEME_ACCOUNT') {
      router.replace({pathname: '/onboarding/personal-details/bank', params});

      return;
    }

    if (params.product === 'CHILD_ACCOUNT') {
      router.replace({pathname: '/onboarding/personal-details/name', params});

      return;
    }

    router.replace({pathname: '/onboarding/personal-details/deposits', params});
  }, [latestAcceptableInterviewsQuery, params, router, selfUser]);

  useFocusEffect(focusCallback);

  return (
    <View
      flex={1}
      position="absolute"
      top={0}
      right={0}
      bottom={0}
      left={0}
      justifyContent="center"
      alignItems="center"
    >
      <LoadingSpinner color="$primary" size={64} />
    </View>
  );
};

export default PersonalDetails;
