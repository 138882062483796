import {Label3} from '@/components/texts/Label';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';

type Props = {
  value: number | undefined;
};

export const ComparisonNumber = ({value}: Props) => {
  if (value === undefined) {
    return <Label3 variant="medium">-</Label3>;
  }

  return (
    <LocalizedNumbers
      value={value}
      variant="percentage"
      numberStyle={{variant: 'medium'}}
      textComponent={Label3}
    />
  );
};
