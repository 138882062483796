import {useMemo} from 'react';
import {Gesture} from 'react-native-gesture-handler';
import {SharedValue} from 'react-native-reanimated';

import {REMOVE_SELECT_POS} from '@/constants/skia';

export const useChartTouchHandler = (
  x: SharedValue<number>,
  offset: number,
  enabled: boolean,
  removeOnEnd?: boolean
) => {
  return useMemo(() => {
    const hover = Gesture.Hover()
      .activeCursor('pointer')
      .enabled(enabled)
      .onStart(pos => {
        x.value = pos.x - 1 - offset;
      })
      .onChange(pos => {
        x.value = pos.x - 1 - offset;
      })
      .onEnd(pos => {
        x.value = pos.x - 1 - offset;

        if (removeOnEnd) {
          x.value = REMOVE_SELECT_POS;
        }
      });
    const pan = Gesture.Pan()
      .activateAfterLongPress(1)
      .enabled(enabled)
      .onStart(pos => {
        x.value = pos.x - 1 - offset;
      })
      .onChange(pos => {
        x.value = pos.x - 1 - offset;
      })
      .onEnd(pos => {
        x.value = pos.x - 1 - offset;

        if (removeOnEnd) {
          x.value = REMOVE_SELECT_POS;
        }
      });
    const composed = Gesture.Race(hover, pan);
    return composed;
  }, [enabled, x, offset, removeOnEnd]);
};
