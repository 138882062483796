import {useTranslate} from '@tolgee/react';
import {Stack, useLocalSearchParams} from 'expo-router';
import {useMemo, useRef, useState} from 'react';
import {Platform} from 'react-native';
import {View, YStack, useMedia} from 'tamagui';

import {Card} from '@/components/cards/Card';
import {CardCheckbox} from '@/components/inputs/CardCheckbox';
import {Heading4} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {ResponsiveScrollView} from '@/components/views/ResponsiveScrollView';
import {useCustomerIdParamOrRedirect} from '@/hooks/useCustomerIdParamOrRedirect';
import {WebFooter} from '@/modules/navigation/components/footer/WebFooter';
import {Header} from '@/modules/navigation/components/header/Header';
import {OrderSummaryModal} from '@/modules/orders/components/OrderSummaryModal';
import {
  WithdrawalReasonLeave,
  WithdrawalReasonNeedMoney,
  WithdrawalReasonOther,
  WithdrawalReasonPerformance,
  WithdrawalReasonStrategyChange,
} from '@/modules/orders/components/Withdrawal/WithdrawalReasonBox';
import {WithDrawalOrderCase} from '@/modules/orders/hooks/useGetValueFromInput';
import {useAppSelector} from '@/store';
import {useWithdrawalReasonsQuery} from '@/store/queries/referenceApi';
import {ModalRef} from '@/types/modal';

export default function WithdrawalPage() {
  const {t} = useTranslate();
  const customerId = useCustomerIdParamOrRedirect();
  const media = useMedia();
  const {data: reasonsData} = useWithdrawalReasonsQuery();
  const language = useAppSelector(state => state.app.language);
  const [selectedReasonCode, setSelectedReasonCode] = useState<string | null>(null);
  const [feedback, setFeedback] = useState<string>();
  const modalRef = useRef<ModalRef>(null);

  const {value, orderCase} = useLocalSearchParams<{
    value: string;
    orderCase: Required<WithDrawalOrderCase>;
  }>();

  const reasonsBoxes = useMemo(() => {
    return reasonsData
      ?.filter(reason => reason.code !== 'DO_NOT_WANT_TO_PROVIDE_A_REASON')
      .map(reason => (
        <CardCheckbox
          variant={media.sm ? 'radio' : 'radioCompact'}
          checked={selectedReasonCode === reason.code}
          onCheckedChange={val => {
            if (val) setSelectedReasonCode(reason.code);
          }}
          key={reason.code}
          label={reason.label[language]}
          wrapperProps={{shadowOpacity: 0}}
          borderColor="$neutral200"
        />
      ));
  }, [language, media.sm, reasonsData, selectedReasonCode]);

  function onConfirm() {
    modalRef.current?.open(true);
  }

  return (
    <ResponsiveScrollView useKeyboardAwareScrollView>
      <YStack gap="$5">
        <Stack.Screen
          options={{
            headerShown: true,
            header: () => (
              <Header
                title={t('ORDER_SUMMARY.WITHDRAWAL.TITLE')}
                backButton={Platform.OS !== 'web'}
              />
            ),
          }}
        />
        <Card gap="$4" padding="$4">
          <Heading4>{t('ORDER_SUMMARY.WITHDRAWAL.REASON')}</Heading4>
          <Paragraph2>{t('ORDER_SUMMARY.WITHDRAWAL.REASON_DESC')}</Paragraph2>
          <View flexDirection={media.sm ? 'column' : 'row'} gap="$2">
            {reasonsBoxes}
          </View>
        </Card>
        {selectedReasonCode === 'PRODUCT_CHANGE' && (
          <WithdrawalReasonStrategyChange onConfirm={onConfirm} />
        )}
        {selectedReasonCode === 'NOT_HAPPY_WITH_PERFORMANCE' && (
          <WithdrawalReasonPerformance onConfirm={onConfirm} />
        )}
        {selectedReasonCode === 'USE_MONEY_ELSEWHERE' && (
          <WithdrawalReasonNeedMoney onConfirm={onConfirm} />
        )}
        {selectedReasonCode === 'THINKING_ABOUT_LEAVING' && (
          <WithdrawalReasonLeave onConfirm={onConfirm} />
        )}
        {selectedReasonCode === 'OTHER' && (
          <WithdrawalReasonOther onConfirm={onConfirm} setOtherReason={setFeedback} />
        )}
      </YStack>
      {selectedReasonCode !== null && (
        <OrderSummaryModal
          ref={modalRef}
          customerId={customerId}
          value={parseFloat(value)}
          orderType={orderCase === 'FULL' ? 'FULL_WITHDRAWAL' : 'WITHDRAWAL'}
          reason={
            selectedReasonCode !== 'OTHER'
              ? selectedReasonCode
              : feedback === undefined || feedback === ''
                ? 'OTHER'
                : feedback
          }
        />
      )}
      <WebFooter />
    </ResponsiveScrollView>
  );
}
