import {
  BottomSheetBackdrop,
  BottomSheetBackdropProps,
  BottomSheetModal,
} from '@gorhom/bottom-sheet';
import {
  ComponentProps,
  PropsWithChildren,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  GestureResponderEvent,
  ViewStyle as RNViewStyle,
  StyleProp,
  useWindowDimensions,
} from 'react-native';
import {
  DialogHandle,
  View,
  YStackProps,
  useControllableState,
  usePropsAndStyle,
  withStaticProperties,
} from 'tamagui';

import {Button} from '@/components/buttons/Button';
import {DialogDescriptionFrame, DialogTitleFrame} from '@/components/dialog/Text';
import {
  ContextValue,
  ModalBottomSheetProvider,
  useBottomSheetContext,
} from '@/components/dialog/dialog-bottom-sheet/context';
import {ModalBottomSheet} from '@/components/modal-bottom-sheet/ModalBottomSheet';
import {DialogRootProps} from '@/types/dialog';

const TriggerWrapper = View.styleable((props, forwardedRef) => {
  const {open, setOpen} = useBottomSheetContext();

  const {onPress, ...rest} = props;

  const handlePressed = useCallback(
    (event: GestureResponderEvent) => {
      setOpen(!open);

      if (onPress) {
        onPress(event);
      }
    },
    [onPress, setOpen, open]
  );

  return <View ref={forwardedRef} {...rest} onPress={handlePressed} />;
});

const CloseWrapper = View.styleable((props, forwardedRef) => {
  const {setOpen} = useBottomSheetContext();

  const {onPress, ...rest} = props;

  const handlePressed = useCallback(
    (event: GestureResponderEvent) => {
      setOpen(false);

      if (onPress) {
        onPress(event);
      }
    },
    [onPress, setOpen]
  );

  return <View ref={forwardedRef} {...rest} onPress={handlePressed} />;
});

const DialogBottomSheetTrigger = TriggerWrapper.styleable((props, forwardedRef) => {
  return <TriggerWrapper ref={forwardedRef} {...props} />;
});

const DialogBottomSheetButtonTrigger = Button.styleable((props, forwardedRef) => {
  return (
    <TriggerWrapper asChild>
      <Button ref={forwardedRef} {...props} />
    </TriggerWrapper>
  );
});

const DialogBottomSheetClose = CloseWrapper.styleable((props, forwardedRef) => {
  return <CloseWrapper ref={forwardedRef} {...props} />;
});

const DialogBottomSheetButtonClose = Button.styleable((props, forwardedRef) => {
  return (
    <CloseWrapper asChild>
      <Button ref={forwardedRef} {...props} />
    </CloseWrapper>
  );
});

const DialogBottomSheetTitle = DialogTitleFrame;

const DialogBottomSheetDescription = DialogDescriptionFrame;

type DialogBottomSheetModalContentProps = Omit<
  ComponentProps<typeof ModalBottomSheet>,
  'onDismiss'
>;

type Props = DialogBottomSheetModalContentProps &
  Omit<YStackProps, 'children' | 'asChild' | 'inset'>;

const DialogBottomSheetContent = ({children, ...rest}: PropsWithChildren<Props>) => {
  const [props, style] = usePropsAndStyle(rest);

  const {height} = useWindowDimensions();

  const context = useBottomSheetContext();
  const {open, setOpen, controllable, onDismiss} = context;

  const ref = useRef<BottomSheetModal>(null);

  useEffect(() => {
    if (!ref.current) return;

    if (open) {
      ref.current.present();
    } else {
      ref.current.close();
    }
  }, [open]);

  const handleBottomSheetDismissed = useCallback(() => {
    if (!controllable) {
      setOpen(false);
    }

    if (onDismiss) {
      onDismiss();
    }
  }, [onDismiss, setOpen, controllable]);

  const handleBackdropPressed = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const renderBackdropComponent = useCallback(
    (props: BottomSheetBackdropProps) => (
      <BottomSheetBackdrop
        {...props}
        appearsOnIndex={0}
        disappearsOnIndex={-1}
        pressBehavior={controllable ? 'none' : 'close'}
      >
        <View
          position="absolute"
          onPress={handleBackdropPressed}
          top={0}
          left={0}
          bottom={0}
          right={0}
        />
      </BottomSheetBackdrop>
    ),
    [handleBackdropPressed, controllable]
  );

  return (
    <ModalBottomSheet
      ref={ref}
      closable={!controllable}
      backdropComponent={controllable ? renderBackdropComponent : undefined}
      maxDynamicContentSize={height * 0.8}
      {...props}
      style={style as StyleProp<RNViewStyle>}
      onDismiss={handleBottomSheetDismissed}
    >
      <ModalBottomSheetProvider value={context}>{children}</ModalBottomSheetProvider>
    </ModalBottomSheet>
  );
};

export const Root = forwardRef<DialogHandle, PropsWithChildren<DialogRootProps>>(
  ({children, open: openProp, defaultOpen = false, onOpenChange, onDismiss}, ref) => {
    const [open, setOpen] = useControllableState({
      prop: openProp,
      defaultProp: defaultOpen,
      onChange: onOpenChange,
    });

    const context: ContextValue = {
      open,
      setOpen,
      onDismiss,
      controllable: openProp !== undefined,
    };

    useImperativeHandle(
      ref,
      () => ({
        open: open => {
          setOpen(open);
        },
      }),
      [setOpen]
    );

    return <ModalBottomSheetProvider value={context}>{children}</ModalBottomSheetProvider>;
  }
);

export const DialogBottomSheet = withStaticProperties(Root, {
  Content: DialogBottomSheetContent,
  Trigger: DialogBottomSheetTrigger,
  ButtonTrigger: DialogBottomSheetButtonTrigger,
  Close: DialogBottomSheetClose,
  ButtonClose: DialogBottomSheetButtonClose,
  Title: DialogBottomSheetTitle,
  Description: DialogBottomSheetDescription,
});
