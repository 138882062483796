import {AnimatedSplashLoader} from '@/components/views/AnimatedSplashLoader';
import {useSession} from '@/providers/session-provider/SessionProvider';
import {resetSession} from '@/providers/session-provider/utils';
import {useRouter} from 'expo-router';
import {useEffect, useRef} from 'react';

export default function LogoutRedirect() {
  const {isLoading} = useSession();
  const router = useRouter();
  const ref = useRef<boolean>(false);

  useEffect(() => {
    if (!isLoading && !ref.current) {
      ref.current = true;
      resetSession();
      router.replace('/login');
    }
  }, [isLoading, router]);

  return <AnimatedSplashLoader viewKey="logout-loader" />;
}
