import SvgFile from '@/components/icons/SvgFile';
import {Modal} from '@/components/modal/Modal';
import {Heading5} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {useTranslate} from '@tolgee/react';
import React, {PropsWithChildren} from 'react';
import {ScrollView, XStack, YStack} from 'tamagui';

export function RejectedOrderModal({children}: PropsWithChildren) {
  const {t} = useTranslate();
  return (
    <Modal>
      <Modal.Trigger asChild>
        <XStack gap="$2" hoverStyle={{opacity: 0.8}}>
          <Paragraph2>{children}</Paragraph2>
          <SvgFile color="$primary" name="info" size={22} />
        </XStack>
      </Modal.Trigger>
      <Modal.Content side>
        <ScrollView contentContainerStyle={{gap: '$3'}}>
          <Modal.Title asChild="except-style">
            <Heading5 variant="medium">{t('HOME.REJECT.TITLE')}</Heading5>
          </Modal.Title>

          <Paragraph2>{t('HOME.REJECT.DESCRIPTION')}</Paragraph2>
          <YStack paddingTop="$3" gap="$3">
            <Paragraph2 color="$neutral400">{t('HOME.REJECT.POINT_1')}</Paragraph2>
            <Paragraph2 color="$neutral400">{t('HOME.REJECT.POINT_2')}</Paragraph2>
            <Paragraph2 color="$neutral400">{t('HOME.REJECT.POINT_3')}</Paragraph2>
          </YStack>
          <Paragraph2>{t('HOME.REJECT.RESTART')}</Paragraph2>
        </ScrollView>
      </Modal.Content>
    </Modal>
  );
}
