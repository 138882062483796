import {useTranslate} from '@tolgee/react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {View, XStack, YStack} from 'tamagui';

import {ThemeIcon} from '@/components/icons/ThemeIcon';
import {AmountDecimalInput} from '@/components/inputs/AmountDecimalInput';
import {InputErrorMessage} from '@/components/texts/InputErrorMessage';
import {Label2} from '@/components/texts/Label';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {roundValue} from '@/modules/orders/helpers/roundvalue';
import {useOrderContext} from '@/modules/orders/hooks/useOrderContext';
import {ThemeCode} from '@/modules/portfolio/types/ThemeCode';

type ThemeItemWithdrawalProps = {
  code: ThemeCode;
  amount: number;
  onError?: (code: ThemeCode, error?: string) => void;
};

export function ThemeItemWithdrawal({code, amount, onError}: ThemeItemWithdrawalProps) {
  const {t} = useTranslate();

  const [inputAmount, setInputAmount] = useState<number>(0);
  const [error, setError] = useState<string>();

  const {setThemeWithdrawalDistribution} = useOrderContext();

  const roundedAmount = useMemo(() => roundValue(amount), [amount]);

  const onRemoveTheme = useCallback(() => {
    setInputAmount(roundedAmount);
  }, [roundedAmount]);

  useEffect(() => {
    if (inputAmount === undefined) return;

    setThemeWithdrawalDistribution(prev => {
      if (inputAmount === 0) {
        return {...prev, [code]: undefined};
      }

      return {
        ...prev,
        [code]:
          inputAmount === roundedAmount
            ? {full: true}
            : {
                amount: inputAmount,
              },
      };
    });
  }, [roundedAmount, code, inputAmount, setThemeWithdrawalDistribution]);

  useEffect(() => {
    if (inputAmount !== roundedAmount && inputAmount > roundValue(roundedAmount * 0.85)) {
      setError(t('THEME_WITHDRAWAL_ORDER.ERRORS.MAX_PARTIAL_WITHDRAWAL'));
    } else {
      setError(undefined);
    }
  }, [roundedAmount, inputAmount, t]);

  useEffect(() => {
    if (!onError) return;

    onError(code, error);
  }, [onError, error, code]);

  return (
    <XStack
      gap="$2"
      flexWrap="wrap"
      justifyContent="space-between"
      alignItems="center"
      animation="slow"
      key={`animated-${code}`}
      enterStyle={{
        opacity: 0,
        y: 20,
      }}
      exitStyle={{
        opacity: 0,
        y: 20,
        height: 0,
      }}
    >
      <YStack flex={1}>
        <XStack>
          <XStack width={250}>
            <ThemeIcon
              code={code}
              showLabel
              onPress={onRemoveTheme}
              textProps={inputAmount === roundedAmount ? {textDecorationLine: 'line-through'} : {}}
            />
          </XStack>
          <View
            flex={1}
            gap="$2"
            justifyContent="flex-end"
            alignItems="center"
            flexDirection="row"
            $sm={{
              flexDirection: 'column',
              alignItems: 'flex-end',
              justifyContent: 'center',
            }}
          >
            <LocalizedNumbers
              variant="currency"
              value={roundedAmount}
              precision="decimals"
              textComponent={Label2}
              numberStyle={{variant: 'medium'}}
            />
            {/* TODO: @tlow92 Replace with proper input field not primitive */}
            <AmountDecimalInput
              bordered
              value={inputAmount}
              onValueChange={val => {
                setInputAmount(val);
              }}
              maxValue={roundedAmount}
            />
          </View>
        </XStack>
        <InputErrorMessage error={error} />
      </YStack>
    </XStack>
  );
}
