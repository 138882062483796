import {useTranslate} from '@tolgee/react';
import {useCallback, useMemo} from 'react';
import {View} from 'tamagui';

import {Modal} from '@/components/modal/Modal';
import {Heading5} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {PerformanceDetailsRow} from '@/modules/overview/components/PerformanceDetailsRow';
import {RateOfReturn} from '@/types/api/apeiron';

type Props = {
  title: string;
  description: string;
  keyData: keyof RateOfReturn;
  data?: RateOfReturn;
};

export const PerformanceDetailsData = ({title, description, keyData, data}: Props) => {
  const {t} = useTranslate();

  const currYear = useMemo(() => new Date().getFullYear().toString(), []);

  const getTitle = useCallback(
    (year: string) => {
      if (year === currYear) {
        return t('PERFORMANCE_CHART.BOTTOM_SHEET.CURR_YEAR');
      }

      return year;
    },
    [currYear, t]
  );

  return (
    <View gap="$4">
      <Modal.Title asChild="except-style">
        <Heading5 variant="medium">{title}</Heading5>
      </Modal.Title>
      <Modal.Description asChild="except-style">
        <Paragraph2 color="$neutral400">{description}</Paragraph2>
      </Modal.Description>
      <View gap="$3">
        <PerformanceDetailsRow
          title={t('PERFORMANCE_CHART.BOTTOM_SHEET.ALL_TIME')}
          value={data?.[keyData]?.overall}
          heading
        />
        {Object.entries(data?.[keyData] ?? {})
          .filter(([key]) => key !== 'overall')
          .sort((a, b) => Number(b[0]) - Number(a[0]))
          .map(([year, value]) => (
            <PerformanceDetailsRow
              key={`${keyData}-${year}`}
              title={getTitle(year)}
              value={value}
            />
          ))}
      </View>
    </View>
  );
};
