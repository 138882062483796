import {scaleLinear, scaleTime} from 'd3-scale';
import {useCallback, useEffect, useState} from 'react';

import {getMinMaxXYTuple} from '@/components/skia/utils';
import {AxisTick, DateRange, Domain, GetTextLabelFunc, NumberTuple} from '@/types/skia';
import {useChartContext} from '../context';
import {useGetChartRange} from './useGetChartRange';

export const useAxisUtils = (x: boolean, time?: boolean, countTicks = 3, nice = true) => {
  const {linesDomain, lang} = useChartContext();

  const [axisTicks, setAxisTicks] = useState<AxisTick[]>([]);
  const [dateRange, setDateRange] = useState<DateRange>();

  const range = useGetChartRange();

  const createNumberTickText = useCallback(
    (value: number, index: number, self: AxisTick[], renderText?: GetTextLabelFunc) => {
      if (renderText) {
        return renderText(value, index, self);
      }

      const opt: Intl.NumberFormatOptions = {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      };

      return new Intl.NumberFormat(lang, opt).format(value);
    },
    [lang]
  );

  const createTimeTickText = useCallback(
    (
      unixTime: number,
      index: number,
      self: AxisTick[],
      renderText?: GetTextLabelFunc,
      dateRange?: 'month' | 'year'
    ) => {
      if (renderText) {
        return renderText(unixTime, index, self);
      }

      if (!dateRange) return undefined;

      const options: Intl.DateTimeFormatOptions = {
        month: 'short',
      };

      if (dateRange === 'year') {
        options.year = 'numeric';
      } else {
        options.day = 'numeric';
      }

      return new Intl.DateTimeFormat(lang, options).format(new Date(unixTime));
    },
    [lang]
  );

  const getTicks = useCallback(
    (time: boolean, x: boolean, countTicks = 3, nice?: boolean) => {
      const domain = x ? linesDomain.x : linesDomain.y;
      let ticks: number[];

      if (time) {
        const scale = scaleTime().domain(domain);

        if (nice) {
          scale.nice();
        }
        ticks = scale.ticks(countTicks).map(date => date.getTime());
        if (ticks.length === 1) {
          ticks = scale
            .nice()
            .ticks(countTicks)
            .map(date => date.getTime());
        }
      } else {
        const scale = scaleLinear().domain(domain);

        if (nice) {
          scale.nice();
        }

        ticks = scale.ticks(countTicks);
      }

      return ticks;
    },
    [linesDomain]
  );

  const getAxisTicks = useCallback(
    (ticks: number[], x: boolean, size: number) => {
      const first = ticks.at(0);
      const last = ticks.at(-1);

      if (first === undefined || last === undefined || first === last) return [];

      const ticksAxesDomain: NumberTuple = [first, last];
      const ticksDomain: Domain = {
        x: x ? ticksAxesDomain : linesDomain.x,
        y: x ? linesDomain.y : ticksAxesDomain,
      };
      const {xyTuple: maxDomain} = getMinMaxXYTuple([linesDomain, ticksDomain]);

      const min = x ? maxDomain.x[0] : maxDomain.y[1];
      const domainRange = x ? maxDomain.x[1] - maxDomain.x[0] : maxDomain.y[0] - maxDomain.y[1];

      return ticks.map<AxisTick>(value => ({
        tick: value,
        position: ((value - min) / domainRange) * size,
      }));
    },
    [linesDomain]
  );

  const createAxisTicks = useCallback(
    (countTicks?: number, nice?: boolean) => {
      if (time === undefined) return [];

      const ticks = getTicks(time, x, countTicks, nice);
      const axisRange = x ? range.x : range.y;
      const size = x ? axisRange[1] - axisRange[0] : axisRange[1] - axisRange[0];

      return getAxisTicks(ticks, x, size);
    },
    [time, getTicks, x, range, getAxisTicks]
  );

  const createTickText = useCallback(
    (value: number, index: number, self: AxisTick[], renderText?: GetTextLabelFunc) => {
      if (time === undefined) return undefined;

      return time
        ? createTimeTickText(value, index, self, renderText, dateRange)
        : createNumberTickText(value, index, self, renderText);
    },
    [time, createTimeTickText, createNumberTickText, dateRange]
  );

  useEffect(() => {
    if (time === undefined) return;

    const axisTicks = createAxisTicks(countTicks, nice);

    setAxisTicks(axisTicks);
  }, [time, countTicks, nice, createAxisTicks]);

  useEffect(() => {
    if (!time) return;

    if (axisTicks.length === 0) {
      setDateRange(undefined);

      return;
    }

    const first = axisTicks.at(0);
    const second = axisTicks.at(1);

    if (!first || !second) {
      setDateRange(undefined);

      return;
    }

    const firstDate = new Date(first.tick * 1000);
    const secondDate = new Date(second.tick * 1000);
    const diffYear = secondDate.getFullYear() - firstDate.getFullYear();

    if (diffYear > 0) {
      setDateRange('year');

      return;
    }

    setDateRange('month');
  }, [time, axisTicks]);

  return {
    axisTicks,
    createAxisTicks,
    createTickText,
  };
};
