import SvgFile, {SvgName} from '@/components/icons/SvgFile';
import {Heading3} from '@/components/texts/Heading';
import {Label2} from '@/components/texts/Label';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {Paragraph1, Paragraph3} from '@/components/texts/Paragraph';
import {Skeleton} from '@/components/views/Skeleton';
import React from 'react';
import {GetProps, View, XStack} from 'tamagui';

type StrategyInfoFactCardProps = {
  title: string;
  label: string | undefined;
  value?: number | null;
  icon: SvgName;
  suffix?: string;
  containerProps?: GetProps<typeof View>;
};

export function StrategyInfoFactCard({
  title,
  label,
  value,
  icon,
  suffix,
  containerProps,
}: StrategyInfoFactCardProps) {
  return (
    <View
      backgroundColor="$neutralBG"
      borderRadius="$1"
      padding="$3"
      height={180}
      {...containerProps}
    >
      <View flex={1} flexBasis={1 / 3} justifyContent="center">
        <View
          width={40}
          height={40}
          backgroundColor="$white"
          borderRadius="$full"
          alignItems="center"
          justifyContent="center"
        >
          <SvgFile size={24} name={icon} color="$primary" />
        </View>
      </View>
      <Paragraph1 variant="medium" flex={1} flexBasis={1 / 3} alignContent="center">
        {title}
      </Paragraph1>
      <XStack justifyContent="space-between" alignItems="flex-end" flex={1} flexBasis={1 / 3}>
        {label !== undefined && (
          <Paragraph3 color="$neutral400" flexWrap="wrap" marginBottom="$0.5">
            {label}
          </Paragraph3>
        )}
        <XStack gap="$3">
          {value !== undefined && (
            <Skeleton height={35}>
              {value !== null && (
                <LocalizedNumbers
                  value={value / 100}
                  variant="percentage"
                  textComponent={Heading3}
                  numberStyle={{
                    color: '$primary',
                    variant: 'medium',
                  }}
                />
              )}
            </Skeleton>
          )}
          {suffix && (
            <Label2 color="$neutral400" alignSelf="flex-end" marginBottom="$1">
              {suffix}
            </Label2>
          )}
        </XStack>
      </XStack>
    </View>
  );
}
