import {skipToken} from '@reduxjs/toolkit/query';
import {useFocusEffect, useRouter} from 'expo-router';
import {useCallback} from 'react';

import {Skeleton} from '@/components/views/Skeleton';
import {useIsUserComplete} from '@/hooks/useIsUserComplete';
import {isProductBasic} from '@/modules/interview/helpers/isProductBasic';
import useAvailableProducts from '@/modules/interview/hooks/useAvailableProducts';
import {useOnboardingSearchParams} from '@/modules/onboarding/hooks/useOnboardingSearchParams';
import {useSession} from '@/providers/session-provider/SessionProvider';
import {useAppDispatch} from '@/store';
import {useGetLatestAcceptableInterviewsQuery} from '@/store/queries/interviewApi';
import {useProductsQuery} from '@/store/queries/referenceApi';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';
import {updateInterview} from '@/store/reducers/interview';
import {Product} from '@/types/fixedTypes/customers.v2/Product';
import {View} from 'tamagui';

const UNUSED_PRODUCTS: Product[] = ['COMPANY_INVESTMENT_ACCOUNT', 'COMPANY_SAVINGS_ACCOUNT'];

const InitialScreen = () => {
  const params = useOnboardingSearchParams();

  const router = useRouter();

  const {isUserExists} = useSession();

  const {data: user, isFetching: fetchingUser} = useGetSelfUserQuery(
    !isUserExists ? skipToken : undefined
  ); // TODO: FRONTEND-196 - for junior we need to use different userid
  const {data: interviews, isFetching: fetchingInterviews} = useGetLatestAcceptableInterviewsQuery(
    !isUserExists ? skipToken : undefined
  );
  const {data: allProducts, isFetching: isProductsFetching} = useProductsQuery();

  const dispatch = useAppDispatch();

  const {isUserComplete, isFetching} = useIsUserComplete();

  const availableProducts = useAvailableProducts(!isUserComplete ? skipToken : undefined);

  const focusCallback = useCallback(() => {
    if (!allProducts || availableProducts.length === 0 || isFetching || fetchingInterviews) return;

    const {product, onboardingType} = params;

    const productsCodes = allProducts.map(product => product.code);
    const availableProductsCodes = availableProducts.map(product => product.product);

    if (
      product === undefined ||
      !productsCodes.includes(product) ||
      UNUSED_PRODUCTS.includes(product) ||
      !availableProductsCodes.includes(product)
    ) {
      router.replace('/interview/product-choice');

      return;
    }

    const isBasicProduct = isProductBasic(product) || onboardingType === 'second_guardian';

    const interviewType = isBasicProduct ? 'BASIC_V2' : 'RISK_PROFILE_EVALUATION_V2';

    const interview = interviews?.find(interview => interview.type === interviewType);

    if (interview) {
      if (onboardingType === 'second_guardian') {
        const screenType = user?.complete ? 'child' : undefined;

        router.replace({
          pathname: '/onboarding/personal-details/summary',
          params: {...params, screenType},
        });

        return;
      }

      let riskProfile: string | undefined;

      if (interview.type === 'RISK_PROFILE_EVALUATION_V2') {
        riskProfile = interview.riskProfile;
      }

      if (product === 'THEME_ACCOUNT') {
        router.replace({
          pathname: '/interview/planner',
          params: {...params, riskProfile},
        });
      } else {
        router.replace({
          pathname: '/interview/question/deposits',
          params: {product, riskProfile},
        });
      }

      return;
    }

    if (isBasicProduct) {
      router.replace({pathname: `/interview/question/exp-v2`, params});

      dispatch(
        updateInterview({type: 'BASIC_V2', userId: user?.id ? String(user?.id) : undefined})
      );
    } else {
      router.replace({pathname: `/interview/question/invest-years`, params});

      dispatch(
        updateInterview({
          type: 'RISK_PROFILE_EVALUATION_V2',
          userId: user?.id ? String(user?.id) : undefined,
        })
      );
    }
  }, [
    allProducts,
    availableProducts,
    isFetching,
    fetchingInterviews,
    params,
    interviews,
    router,
    user?.complete,
    user?.id,
    dispatch,
  ]);

  useFocusEffect(focusCallback);

  if (fetchingInterviews || fetchingUser || isProductsFetching || isFetching) {
    return (
      <View justifyContent="center" alignItems="center" paddingHorizontal="$4">
        <View width="100%" maxWidth={500}>
          <Skeleton height={200} width="100%" />
        </View>
      </View>
    );
  }

  return null;
};

export default InitialScreen;
