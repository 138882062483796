import {captureException} from '@sentry/react-native';
import {Href, Redirect} from 'expo-router';
import {useMemo} from 'react';

import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {TradesOrVolumesQuestions} from '@/modules/interview/components/TradesOrVolumeQuestions';
import {isProductBasic} from '@/modules/interview/helpers/isProductBasic';
import {useOnboardingSearchParams} from '@/modules/onboarding/hooks/useOnboardingSearchParams';

export default function TradesPage() {
  const params = useOnboardingSearchParams();

  const href: Href | undefined = useMemo(() => {
    if (!params.product) return;

    return isProductBasic(params.product) || params.onboardingType === 'second_guardian'
      ? {pathname: '/interview/question/inputs', params}
      : {pathname: '/interview/question/risk', params};
  }, [params]);

  if (!href) {
    captureException(new Error('Missing href'));
    return <Redirect href="/interview/product-choice" />;
  }

  return (
    <FullscreenScrollView variant="small">
      <TradesOrVolumesQuestions href={href} />
    </FullscreenScrollView>
  );
}
