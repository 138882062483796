import {CardCheckbox} from '@/components/inputs/CardCheckbox';
import {Skeleton} from '@/components/views/Skeleton';
import React, {useCallback} from 'react';
import {Control, FieldValues, Path, PathValue, useController} from 'react-hook-form';
import {YStack} from 'tamagui';

type FormCheckboxGroupInputProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  items?: {code: Path<T>; label: string; caption?: string}[];
  variant?: 'radio' | 'checkbox';
};

export function FormCheckboxGroupInput<T extends FieldValues>({
  control,
  name,
  items,
  variant = 'checkbox',
}: FormCheckboxGroupInputProps<T>) {
  const {
    field,
    fieldState: {error},
  } = useController({
    name,
    control,
    defaultValue: [] as PathValue<T, Path<T>>,
  });

  const onCheckedChange = useCallback(
    (checked: boolean, code: Path<T>) => {
      if (variant === 'radio') {
        field.onChange([code]);
        return;
      }

      if (!checked) {
        field.onChange(field.value.filter((_: Path<T>) => _ !== code));
      } else {
        field.onChange([...field.value, code]);
      }
    },
    [field, variant]
  );

  const getChecked = useCallback(
    (code: Path<T>) => {
      return field.value.includes(code);
    },
    [field.value]
  );
  return (
    <YStack gap="$4">
      {items ? (
        items?.map(item => {
          if (!item.code) return null;
          return (
            <CardCheckbox
              variant={variant}
              checked={getChecked(item.code)}
              onCheckedChange={_ => onCheckedChange(_, item.code)}
              key={item.code}
              label={item.label}
              caption={item.caption}
            />
          );
        })
      ) : (
        <>
          <Skeleton width="100%" show height={75} />
          <Skeleton width="100%" show height={75} />
          <Skeleton width="100%" show height={75} />
          <Skeleton width="100%" show height={75} />
          <Skeleton width="100%" show height={50} />
        </>
      )}
    </YStack>
  );
}
