import {SelectInput} from '@/components/inputs/select-input/SelectInput';
import {THEME_SELECTION_OPTIONS} from '@/constants/theme-selection-options';
import {ProfileListItem} from '@/modules/profile/components/ProfileListItem';
import {ProfileSelectInput} from '@/modules/profile/components/ProfileSelectInput';
import {useAppDispatch, useAppSelector} from '@/store';
import {setTheme} from '@/store/reducers/app';
import {SelectInputItem} from '@/types/FormInputProps';
import {ThemeSelectionOptionsType} from '@/types/ThemeSelectionOptions';

import {useTranslate} from '@tolgee/react';
import {useMemo} from 'react';
import {GetProps, View, isTouchable, useMedia} from 'tamagui';

export function ThemeSelect({
  wrapperProps,
}: {
  wrapperProps?: GetProps<typeof SelectInput>['wrapperProps'];
}) {
  const media = useMedia();
  const {t} = useTranslate();
  const dispatch = useAppDispatch();
  const currentTheme = useAppSelector(state => state.app.theme);

  const items = useMemo(
    () =>
      THEME_SELECTION_OPTIONS.map((theme: ThemeSelectionOptionsType): SelectInputItem => {
        switch (theme) {
          case 'light':
            return {
              name: t('THEME.LIGHT'),
              key: theme,
            };
          case 'dark':
            return {
              name: t('THEME.DARK'),
              key: theme,
            };
          case 'system':
            return {
              name: t('THEME.SYSTEM'),
              key: theme,
            };
        }
      }) satisfies SelectInputItem[],
    [t]
  );

  const onSelected = (value: string) => {
    dispatch(setTheme(value as ThemeSelectionOptionsType));
  };

  const translatedMode = useMemo(() => {
    switch (currentTheme.toUpperCase()) {
      case 'DARK':
        return t('SETTINGS.MODE.DARK');
      case 'LIGHT':
        return t('SETTINGS.MODE.LIGHT');
      case 'SYSTEM':
      default:
        return t('SETTINGS.MODE.SYSTEM');
    }
  }, [currentTheme, t]);

  return (
    <View width={media.sm || isTouchable ? '100%' : 350}>
      <ProfileSelectInput
        label={t('SETTINGS.SELECT_THEME')}
        items={items}
        placeholder={translatedMode}
        onSelect={onSelected}
        selectedItem={currentTheme}
        wrapperProps={{
          ...wrapperProps,
        }}
      >
        {(media.sm || isTouchable) && (
          <ProfileListItem label={t('SETTINGS.SELECT_THEME')} value={translatedMode} />
        )}
      </ProfileSelectInput>
    </View>
  );
}
