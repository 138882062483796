import {Button} from '@/components/buttons/Button';
import {Modal} from '@/components/modal/Modal';
import {Heading5} from '@/components/texts/Heading';
import {WithLabel} from '@/components/texts/WithLabel';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';
import {UserResponseV3} from '@/types/api/users';
import {ModalRef} from '@/types/modal';
import {useTranslate} from '@tolgee/react';
import {forwardRef, PropsWithChildren, useImperativeHandle, useRef} from 'react';
import {View, XStack, YStack} from 'tamagui';

type UserAddressSameAddressModalProps = {
  childUser: UserResponseV3;
  onNo: () => void;
  onYes: () => void;
};

export const UserAddressSameAddressModal = forwardRef(function UserAddressSameAddressModal(
  {childUser, children, onNo, onYes}: PropsWithChildren<UserAddressSameAddressModalProps>,
  ref
) {
  const {t} = useTranslate();
  const {data: user} = useGetSelfUserQuery();
  const modalRef = useRef<ModalRef>(null);

  useImperativeHandle(ref, () => ({
    open: modalRef.current?.open,
  }));

  return (
    <Modal ref={modalRef}>
      <Modal.Trigger>{children}</Modal.Trigger>
      <Modal.Content>
        <YStack gap="$4">
          <Modal.Title asChild="except-style">
            <Heading5 variant="medium">
              {t('PERSONAL_DETAILS.ADDRESS.SAME_ADDRESS.TITLE', {
                childFirstName: childUser.firstName,
              })}
            </Heading5>
          </Modal.Title>

          {user && (
            <YStack gap="$2">
              <WithLabel header={t('PERSONAL_DETAILS.ADDRESS.SAME_ADDRESS.STREET')} horizontal>
                {`${user.address?.street} ${user.address?.streetNumber}`}
              </WithLabel>
              <WithLabel header={t('PERSONAL_DETAILS.ADDRESS.SAME_ADDRESS.CITY')} horizontal>
                {`${user.address?.postCode} ${user.address?.city}`}
              </WithLabel>
              <WithLabel header={t('PERSONAL_DETAILS.ADDRESS.SAME_ADDRESS.COUNTRY')} horizontal>
                {`${user.address?.countryCode}`}
              </WithLabel>
            </YStack>
          )}
          <XStack gap="$2">
            <View flex={1}>
              <Button
                secondary
                alignSelf="stretch"
                onPress={() => {
                  modalRef?.current?.open?.(false);
                  onNo();
                }}
              >
                {t('PERSONAL_DETAILS.ADDRESS.SAME_ADDRESS.NO')}
              </Button>
            </View>
            <View flex={1}>
              <Button
                alignSelf="stretch"
                onPress={() => {
                  modalRef?.current?.open?.(false);
                  onYes();
                }}
              >
                {t('PERSONAL_DETAILS.ADDRESS.SAME_ADDRESS.YES')}
              </Button>
            </View>
          </XStack>
        </YStack>
      </Modal.Content>
    </Modal>
  );
});
