import {SvgName} from '@/components/icons/SvgFile';
import {ThemeCode} from '@/modules/portfolio/types/ThemeCode';

export const THEME_ICON_MAP: Record<ThemeCode, SvgName> = {
  BLOCKCHAIN: 'blockchain',
  BLUE_ECONOMY: 'blue-economy',
  CLEAN_ENERGY: 'clean-energy',
  COMMODITIES: 'commodities',
  DIVIDEND_STOCKS_EUROPE: 'dividend-stocks',
  DIVIDENDS_EURO: 'dividend-stocks',
  DIVIDENDS_USA: 'dividend-stocks',
  ELECTRIC_MOBILITY: 'electric-mobility',
  ENERGY_INDUSTRY: 'energy-industry',
  FINANCIAL_INDUSTRY: 'financial-industry',
  GLOBAL_WATER: 'water',
  WATER: 'water',
  HEALTHCARE: 'healthcare',
  INFORMATION_TECH: 'IT',
  STOCKS_CHINA: 'stocks',
  STOCKS_EM: 'stocks',
  STOCKS_EUROPE: 'stocks',
  STOCKS_GERMANY: 'stocks',
  STOCKS_USA: 'stocks',
  MONEY_MARKET: 'topzins-us-vector',
  DEFAULT: 'ginmon-invest',
};
