import {createAnimations} from '@tamagui/animations-moti';
import {config} from '@tamagui/config/v3';
import {createMedia} from '@tamagui/react-native-media-driver';
import {Easing} from 'react-native-reanimated';
import {createFont, createTamagui, createTokens} from 'tamagui';

const regularFontConfig = {
  weight: {
    normal: 'normal',
  },
  lineHeight: config.fonts.body.lineHeight,
  letterSpacing: config.fonts.body.letterSpacing,
  size: config.fonts.body.size,
};

const headingFontConfig = {
  weight: {
    normal: 'normal',
  },
  lineHeight: config.fonts.heading.lineHeight,
  letterSpacing: config.fonts.heading.letterSpacing,
  size: config.fonts.heading.size,
};

const regularHeading = createFont({
  ...headingFontConfig,
  family: 'InterRegular',
});

const mediumHeading = createFont({
  ...headingFontConfig,
  family: 'InterMedium',
});

const boldHeading = createFont({
  ...headingFontConfig,
  family: 'InterBold',
});

const regularBody = createFont({
  ...regularFontConfig,
  family: 'InterRegular',
});

const mediumBody = createFont({
  ...regularFontConfig,
  family: 'InterMedium',
});

const boldBody = createFont({
  ...regularFontConfig,
  family: 'InterBold',
});

// Set up our tokens

// The keys can be whatever you want, but we do recommend keeping them
// consistent across the different token categories and intended for
// usage together to make nice designs - eg for a Button to use.

const size = {
  0: 0,
  0.25: 1,
  0.5: 2,
  1: 4,
  1.5: 6,
  2: 8,
  3: 12,
  4: 16,
  5: 20,
  6: 24,
  7: 32,
  8: 48,
  9: 64,
  10: 80,
  true: 8,
};

export const tokens = createTokens({
  size,
  space: size, // TODO: maybe we can should use defaults
  radius: {0: 0, 0.25: 6, 0.5: 8, 0.75: 12, 1: 16, 2: 18, 3: 20, full: 100_000_000}, // TODO: maybe we can should use defaults
  zIndex: {0: 0, 1: 100, 2: 200}, // TODO: maybe we can should use defaults
  color: {},
});

const tamaguiConfig = createTamagui({
  fonts: {
    // for tamagui, heading and body are assumed
    heading: regularHeading,
    headingMedium: mediumHeading,
    headingBold: boldHeading,
    body: regularBody,
    bodyMedium: mediumBody,
    bodyBold: boldBody,
  },
  tokens,
  defaultFont: 'body',

  // For more on themes, see the Themes page
  themes: {
    light: {
      primaryGradientEnd: '#5388FF',
      primary: '#2663EB',
      primaryHover: '#5085f9',
      primaryPressed: '#114ac7',
      primaryTransparent: '#f5f8ff',
      primarySkeleton: '#1557e5',

      neutral0: '#ffffff',
      neutral50: '#f6f8fc',
      neutral100: '#eff1f6',
      neutral200: '#dcdee4',
      neutral300: '#b6b9c2',
      neutral400: '#999ca2',
      neutral500: '#80848a',
      neutral600: '#5a5c63',
      neutral700: '#3d3f47',
      neutral800: '#2b2e36',
      neutral900: '#22252e',
      neutral950: '#161a23',
      neutralBG: '#fafafa',

      text: '#22252e',
      surface: '#ffffff',
      surfaceRaised: '#fafafa',
      surfaceHover: '#fcfcfc',
      shadow: '#e0e8eb',

      white: '#ffffff',
      white50: '#ffffff32',
      white10: '#ffffff1A',

      green: '#39bf6f',
      greenDark: '#1e9a50',
      greenLight: '#93dfb2',
      greenTransparent: '#f3fcf7',
      emerald: '#3ecea1',
      emeraldDark: '#2db58b',
      emeraldLight: '#9fe7d1',
      emeraldTransparent: '#f5fffc',
      red: '#ff4d4d',
      redDark: '#ef3c3a',
      redLight: '#ffbcbc',
      redTransparent: '#fef7f6',
      orange: '#ffa64d',
      orangeDark: '#ff8a15',
      orangeLight: '#ffe1c2',
      orangeTransparent: '#fff9f2',
      yellow: '#ffd74d',
      yellowDark: '#f5c125',
      yellowLight: '#fff3c7',
      yellowTransparent: '#FFFDF5',
      violet: '#6f4bf9',
      violetDark: '#461cea',
      violetLight: '#d1c5fd',
      violetTransparent: '#f6f4fe',
      pink: '#d64dff',
      pinkDark: '#c914ff',
      pinkLight: '#f1c2ff',
      pinkTransparent: '#fdf5ff',
      cerulean: '#4b72fc',
      ceruleanDark: '#1543e6',
      ceruleanLight: '#c2d0fe',
      ceruleanTransparent: '#f5f7ff',
    },
    dark: {
      primaryGradientEnd: '#5388FF',
      primary: '#4779eb',
      primaryHover: '#6f9cff',
      primaryPressed: '#2a4073',
      primaryTransparent: '#262d41',
      primarySkeleton: '#3068e8',

      neutral0: '#22252e',
      neutral50: '#2b2e36',
      neutral100: '#3d3f47',
      neutral200: '#5a5c63',
      neutral300: '#80848a',
      neutral400: '#999ca2',
      neutral500: '#b6b9c2',
      neutral600: '#dcdee4',
      neutral700: '#eff1f6',
      neutral800: '#f6f8fc',
      neutral900: '#ffffff',
      neutral950: '#ffffff',

      neutralBG: '#161a23',

      text: '#f6f8fc',
      surface: '#22252e',
      surfaceHover: '#2b2f3b',
      surfaceRaised: '#2b2e36',
      shadow: '#161a23',

      white: '#ffffff',
      white50: '#ffffff32',
      white10: '#ffffff1A',

      green: '#54bf7f',
      greenDark: '#74d69b',
      greenLight: '#2f5c48',
      greenTransparent: '#273436',
      emerald: '#5bceaa',
      emeraldDark: '#7fdfc0',
      emeraldLight: '#316259',
      emeraldTransparent: '#28363a',
      red: '#ff7070',
      redDark: '#ff9f9f',
      redLight: '#733c42',
      redTransparent: '#382d35',
      orange: '#ffb870',
      orangeDark: '#ffcf9f',
      orangeLight: '#735942',
      orangeTransparent: '#383435',
      yellow: '#ffdf70',
      yellowDark: '#ffe99f',
      yellowLight: '#736942',
      yellowTransparent: '#383835',
      violet: '#8a6ef9',
      violetDark: '#ae9afb',
      violetLight: '#453b79',
      violetTransparent: '#2c2c42',
      pink: '#de70ff',
      pinkDark: '#e99fff',
      pinkLight: '#663c7b',
      pinkTransparent: '#352d43',
      cerulean: '#6f8efc',
      ceruleanDark: '#9bb1fd',
      ceruleanLight: '#3a487a',
      ceruleanTransparent: '#2a3043',
    },
  },

  // For web-only, media queries work out of the box and you can avoid the
  // `createMedia` call here by passing the media object directly.
  // If you are going to target React Native, use `createMedia` (it's an identity
  // function on web so you can import it there without concern).
  media: createMedia({
    xs: {maxWidth: 660},
    sm: {maxWidth: 767},
    md: {maxWidth: 1020},
    lg: {maxWidth: 1280},
    xl: {maxWidth: 1420},
    xxl: {maxWidth: 1600},
    gtXs: {minWidth: 660 + 1},
    gtSm: {minWidth: 767 + 1},
    gtMd: {minWidth: 1020 + 1},
    gtLg: {minWidth: 1280 + 1},
    short: {maxHeight: 820},
    tall: {minHeight: 820},
    hoverNone: {hover: 'none'},
    pointerCoarse: {pointer: 'coarse'},
  }),

  // Shorthands
  // Adds <View m={10} /> to <View margin={10} />
  // See Settings section on this page to only allow shorthands
  // Be sure to have `as const` at the end
  shorthands: {} as const,

  animations: createAnimations({
    fast: {
      type: 'timing',
      easing: Easing.out(Easing.exp),
      duration: 150,
    },
    slow: {
      type: 'timing',
      easing: Easing.out(Easing.exp),
      duration: 500,
    },
    verySlow: {
      type: 'timing',
      easing: Easing.out(Easing.exp),
      duration: 1000,
    },
  }),
  settings: {
    disableSSR: true,
    allowedStyleValues: 'somewhat-strict',
    styleCompat: 'react-native',
  },
});

export default tamaguiConfig;

export type Conf = typeof tamaguiConfig;

declare module 'tamagui' {
  interface TamaguiCustomConfig extends Conf {}
}
