import {Badge} from '@/components/buttons/Badge';
import SvgFile from '@/components/icons/SvgFile';
import {Label2, Label4} from '@/components/texts/Label';
import {Skeleton} from '@/components/views/Skeleton';
import {PRODUCT_ICON_MAP} from '@/constants/product-icon-map';
import {Product} from '@/types/fixedTypes/customers.v2/Product';
import {media} from '@tamagui/config/v3';
import {useTranslate} from '@tolgee/react';
import React, {useMemo} from 'react';
import {GetProps} from 'tamagui';

type BadgeProps = Omit<GetProps<typeof Badge>, 'children' | 'ref'> & {
  product: Product | undefined;
  onPrimary?: boolean;
  textComponent?: typeof Label2 | typeof Label4;
};

export const ProductBadge: React.FC<BadgeProps> = ({product, textComponent, ...props}) => {
  const {t} = useTranslate();
  const TextComponent = textComponent || Label4;

  const text = useMemo(() => {
    switch (product) {
      case 'INVESTMENT_ACCOUNT':
        return t('INVESTMENT_PLANNER.PRODUCT_HEADER.INVEST');
      case 'SAVINGS_ACCOUNT':
        return t('INVESTMENT_PLANNER.PRODUCT_HEADER.TOPZINS');
      case 'CHILD_ACCOUNT':
        return t('INVESTMENT_PLANNER.PRODUCT_HEADER.CHILD');
      case 'THEME_ACCOUNT':
        return t('INVESTMENT_PLANNER.PRODUCT_HEADER.MOTIVE');
      case 'VL_ACCOUNT':
        return t('INVESTMENT_PLANNER.PRODUCT_HEADER.VL');
    }
  }, [product, t]);

  return (
    <Skeleton
      width={144}
      radius="round"
      height={22}
      color={props.onPrimary ? 'onPrimary' : undefined}
    >
      {product && (
        <Badge alignItems="center" gap="$2" bordered {...props}>
          <SvgFile
            name={PRODUCT_ICON_MAP[product]}
            size={media.sm ? 14 : 16}
            color={props.onPrimary ? '$white' : '$primary'}
          />
          <TextComponent color={props.onPrimary ? '$white' : '$primary'}>{text}</TextComponent>
        </Badge>
      )}
    </Skeleton>
  );
};
