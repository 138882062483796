import {useTranslate} from '@tolgee/react';
import {YStack} from 'tamagui';

import {Heading6} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {SingleAnswer} from '@/modules/interview/components/SingleAnswer';
import {TipWithSideModal} from '@/modules/interview/components/TipWithSideModal';
import {useOnboardingSearchParams} from '@/modules/onboarding/hooks/useOnboardingSearchParams';

export default function InvestYearsPage() {
  const params = useOnboardingSearchParams();
  const {t} = useTranslate();
  const tipContent = (
    <YStack gap="$5" marginVertical="$5">
      <Heading6 variant="medium">{t('INTERVIEW.FAQ.HOW_LONG_INVEST.TITLE')}</Heading6>
      <Paragraph2 color="$neutral400">
        {t('INTERVIEW.FAQ.HOW_LONG_INVEST.DESCRIPTION_1')}
      </Paragraph2>
      <Paragraph2 color="$neutral400">
        {t('INTERVIEW.FAQ.HOW_LONG_INVEST.DESCRIPTION_2')}
      </Paragraph2>
      <Paragraph2 color="$neutral400">
        {t('INTERVIEW.FAQ.HOW_LONG_INVEST.DESCRIPTION_3')}
      </Paragraph2>
    </YStack>
  );

  return (
    <FullscreenScrollView variant="small">
      <SingleAnswer
        href={{pathname: '/interview/question/exp-v2', params}}
        questionCode="INVEST_YEARS"
      />
      <TipWithSideModal
        tipTitle={t('INTERVIEW.FAQ.HOW_LONG_INVEST.TITLE')}
        tipContent={tipContent}
      />
    </FullscreenScrollView>
  );
}
