import {FormAmountInput} from '@/components/inputs/form/FormAmountInput';
import {FinancialSituationSchemaType, useValidations} from '@/hooks/useValidations';
import useExistingFinancialSitationAnswers from '@/modules/interview/hooks/useExistingFinancialSitationAnswers';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslate} from '@tolgee/react';
import React, {forwardRef, useEffect, useImperativeHandle} from 'react';
import {useForm} from 'react-hook-form';
import {YStack} from 'tamagui';

type FinancialSituationFormProps = {
  onValid: (data: FinancialSituationSchemaType) => void;
};

export type SubmitFormRef = {submit: () => Promise<void>};

export const FinancialSituationForm = forwardRef<any, FinancialSituationFormProps>(
  function FinancialSituationFormForm({onValid}, ref) {
    const {t} = useTranslate();
    const defaultValues = useExistingFinancialSitationAnswers();
    const {financialSituationFormSchema} = useValidations();
    const posthog = usePostHog();

    useImperativeHandle(ref, () => ({
      submit: handleSubmit(onValid, data => {
        posthog?.capture('form_submit_failed', data);
      }),
    }));

    const {
      handleSubmit,
      control,
      formState: {errors},
      trigger,
      watch,
      setValue,
      getFieldState,
    } = useForm({
      resolver: yupResolver(financialSituationFormSchema),
      mode: 'onChange',
      defaultValues,
    });

    useEffect(() => {
      setValue(
        'monthlyCalculated',
        (defaultValues.MONTHLY_NET_INCOME || 0) - (defaultValues.MONTHLY_SAVING || 0)
      );
      setValue(
        'sumAssets',
        (defaultValues.DISPOSABLE_SAVING || 0) +
          (defaultValues.OTHER_ASSETS || 0) -
          (defaultValues.CREDITS || 0)
      );
    }, [defaultValues, setValue]);

    useEffect(() => {
      const {unsubscribe} = watch((data, {name}) => {
        if (name === 'MONTHLY_NET_INCOME' || name === 'MONTHLY_SAVING') {
          if (name === 'MONTHLY_NET_INCOME' && getFieldState('MONTHLY_SAVING').isDirty) {
            trigger('MONTHLY_SAVING');
          }
          setValue(
            'monthlyCalculated',
            (data.MONTHLY_NET_INCOME || 0) - (data.MONTHLY_SAVING || 0)
          );
        }
        if (name === 'DISPOSABLE_SAVING' || name === 'OTHER_ASSETS' || name === 'CREDITS') {
          setValue(
            'sumAssets',
            (data.DISPOSABLE_SAVING || 0) + (data.OTHER_ASSETS || 0) - (data.CREDITS || 0)
          );
          trigger('sumAssets');
        }
      });
      return () => unsubscribe();
    }, [getFieldState, setValue, trigger, watch]);

    return (
      <YStack gap="$4">
        <FormAmountInput
          control={control}
          name="MONTHLY_NET_INCOME"
          label={t('ONBOARDING.INTERVIEW.FINANCIAL-SITUATION.MONTHLY-NET-INCOME')}
        />
        <FormAmountInput
          control={control}
          name="MONTHLY_SAVING"
          label={t('ONBOARDING.INTERVIEW.FINANCIAL-SITUATION.MONTHLY-EXPENSES')}
        />
        <FormAmountInput
          control={control}
          name="monthlyCalculated"
          label={t('ONBOARDING.INTERVIEW.FINANCIAL-SITUATION.MONTHLY-CALCULATED')}
          disabled
        />
        <FormAmountInput
          control={control}
          name="DISPOSABLE_SAVING"
          label={t('ONBOARDING.INTERVIEW.FINANCIAL-SITUATION.LIQUID-ASSETS')}
        />
        <FormAmountInput
          control={control}
          name="OTHER_ASSETS"
          label={t('ONBOARDING.INTERVIEW.FINANCIAL-SITUATION.OTHER-ASSETS')}
        />
        <FormAmountInput
          control={control}
          name="CREDITS"
          label={t('ONBOARDING.INTERVIEW.FINANCIAL-SITUATION.LOANS')}
        />
        <FormAmountInput
          control={control}
          name="sumAssets"
          label={t('ONBOARDING.INTERVIEW.FINANCIAL-SITUATION.SUM-ASSETS')}
          disabled
        />
      </YStack>
    );
  }
);
