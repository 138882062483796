import {CustomThemeDistribution} from '@/modules/orders/types/CustomThemeDistribution';
import {
  ThemeDepositDistribution,
  ThemeDepositOrderRequest,
  ThemeWithdrawalDistribution,
} from '@/types/api/customers.v2';
import {PropsWithChildren, createContext, useMemo, useState} from 'react';

type OrderContextType = {
  themeDepositDistribution: CustomThemeDistribution<ThemeDepositDistribution>;
  setThemeDepositDistribution: React.Dispatch<
    React.SetStateAction<{
      [key: string]: ThemeDepositDistribution;
    }>
  >;
  themeWithdrawalDistribution: CustomThemeDistribution<ThemeWithdrawalDistribution>;
  setThemeWithdrawalDistribution: React.Dispatch<
    React.SetStateAction<{
      [key: string]: ThemeWithdrawalDistribution | undefined;
    }>
  >;
};

export const OrderContext = createContext<OrderContextType | null>(null);

export function OrderContextProvider({children}: PropsWithChildren) {
  const [themeDepositDistribution, setThemeDepositDistribution] = useState<
    ThemeDepositOrderRequest['themeDistribution']
  >({});
  const [themeWithdrawalDistribution, setThemeWithdrawalDistribution] = useState<
    Record<string, ThemeWithdrawalDistribution | undefined>
  >({});
  const value = useMemo(
    () => ({
      themeDepositDistribution,
      setThemeDepositDistribution,
      themeWithdrawalDistribution,
      setThemeWithdrawalDistribution,
    }),
    [themeDepositDistribution, themeWithdrawalDistribution]
  );
  return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>;
}
