import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {EmployerStartDate} from '@/modules/onboarding/components/EmployerStartDate';
import {useOnboardingSearchParams} from '@/modules/onboarding/hooks/useOnboardingSearchParams';

const VlStartScreen = () => {
  const {product} = useOnboardingSearchParams();

  return (
    <FullscreenScrollView variant="small">
      <EmployerStartDate
        href={{pathname: '/onboarding/personal-details/vl-address', params: {product}}}
      />
    </FullscreenScrollView>
  );
};

export default VlStartScreen;
