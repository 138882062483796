import {SelectInput} from '@/components/inputs/select-input/SelectInput';
import {Modal} from '@/components/modal/Modal';
import {InputLabel} from '@/components/texts/InputLabel';
import {InternalTransferTipDisabledAccountModalContent} from '@/modules/orders/components/InternalTransfer/InternalTransferTipDisabledAccountModalContent';
import {InternalTransferTipOneAccountOnlyModalContent} from '@/modules/orders/components/InternalTransfer/InternalTransferTipOneAccountOnlyModalContent';
import {
  useGetCustomerAccountQuery,
  useGetCustomerAccountsQuery,
} from '@/store/queries/customersApi';
import {ModalRef} from '@/types/modal';
import {useTranslate} from '@tolgee/react';
import {useMemo, useRef, useState} from 'react';
import {useController} from 'react-hook-form';
import {GetProps, View} from 'tamagui';

type InternalTransferCustomerSelectorProps = {
  currentCustomerId: string;
  onSelect: (customerId: string) => void;
  wrapperProps?: GetProps<typeof SelectInput>['wrapperProps'];
  control: any;
  name: string;
};

export function InternalTransferCustomerSelector({
  currentCustomerId,
  onSelect,
  wrapperProps,
  control,
  name,
}: InternalTransferCustomerSelectorProps) {
  const {t} = useTranslate();
  const {data: customers} = useGetCustomerAccountsQuery();
  const {data: currentCustomer} = useGetCustomerAccountQuery(currentCustomerId);
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>();
  const modalRefEmptyList = useRef<ModalRef>(null);
  const modalRefDisabledSelected = useRef<ModalRef>(null);
  const {
    field,
    fieldState: {error},
  } = useController({
    name,
    control,
  });

  const items = useMemo(() => {
    return (
      customers?.items
        ?.filter(customer => customer.id !== currentCustomerId)
        .map(customer => {
          const disabled =
            customer.custodian !== currentCustomer?.custodian ||
            (customer.product !== 'INVESTMENT_ACCOUNT' && customer.product !== 'SAVINGS_ACCOUNT');
          return {
            name: customer.displayName,
            key: customer.id,
            disabled,
          };
        }) || []
    );
  }, [currentCustomer?.custodian, currentCustomerId, customers?.items]);

  const onSelectInternal = (option: string) => {
    const disabledItemSelected = items.find(item => item.key === option)?.disabled;
    if (disabledItemSelected) {
      modalRefDisabledSelected.current?.open(true);
      field.onChange('');
    } else {
      setSelectedCustomerId(option);
      field.onChange(option);
      onSelect(option);
    }
  };

  const onSelectEmpty = () => {
    if (items.length === 0) {
      modalRefEmptyList.current?.open(true);
      field.onChange('');
    }
  };

  return (
    <>
      <InputLabel label={t('ORDER.INTERNAL_ASSETS_TRANSFER.TO')}>
        <View onPress={onSelectEmpty}>
          <SelectInput
            items={items}
            placeholder={t('ORDER.INTERNAL_ASSETS_TRANSFER.TO_PLACEHOLDER')}
            onSelect={onSelectInternal}
            selectedItem={selectedCustomerId}
            error={error?.message}
            wrapperProps={{
              bordered: true,
              ...wrapperProps,
            }}
            disabled={items.length === 0}
          />
        </View>
      </InputLabel>
      <Modal ref={modalRefEmptyList}>
        <Modal.Content typeContainer="SCROLL" side>
          <InternalTransferTipOneAccountOnlyModalContent />
        </Modal.Content>
      </Modal>
      <Modal ref={modalRefDisabledSelected}>
        <Modal.Content typeContainer="SCROLL" side>
          <InternalTransferTipDisabledAccountModalContent />
        </Modal.Content>
      </Modal>
    </>
  );
}
