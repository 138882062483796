import {useTranslate} from '@tolgee/react';
import {GetProps, XStack, YStack, useMedia} from 'tamagui';

import {ProductBadge} from '@/components/buttons/ProductBadge';
import {StrategyBadge} from '@/components/buttons/StrategyBadge';
import SvgFile from '@/components/icons/SvgFile';
import {Heading4, Heading5} from '@/components/texts/Heading';
import {Label1} from '@/components/texts/Label';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {PerformanceBadge} from '@/components/texts/PerformanceBadge';
import {PrimaryHeader} from '@/components/views/PrimaryHeader';
import {Skeleton} from '@/components/views/Skeleton';
import {useBackNavigation} from '@/modules/navigation/hooks/useBackNavigation';
import {useGetPerformanceSummaryQuery} from '@/store/queries/apeironApi';
import {useGetCustomerAccountQuery} from '@/store/queries/customersApi';
import {isThemeCustomer} from '@/types/isThemeCustomer';

type CustomerCardProps = {
  customerId: string;
  containerStyle?: GetProps<typeof PrimaryHeader>['containerStyle'];
};

export function CustomerCard({customerId, containerStyle}: CustomerCardProps) {
  const {t} = useTranslate();

  const media = useMedia();

  const {data: customer} = useGetCustomerAccountQuery(customerId);
  const {data: performanceSummary, isFetching: isApeironFetching} = useGetPerformanceSummaryQuery({
    customerId,
  });

  const handleBack = useBackNavigation();

  return (
    <PrimaryHeader containerStyle={containerStyle}>
      {media.sm && (
        <XStack
          paddingTop="$4"
          alignItems="center"
          onPress={handleBack}
          pressStyle={{opacity: 0.5}}
        >
          <SvgFile name="chevron-left" size={40} color="$white" />
          <Heading5 variant="medium" color="$white">
            {t('TABBAR.OVERVIEW')}
          </Heading5>
        </XStack>
      )}
      <YStack gap="$3" padding="$5">
        {/* TODO: I think it would be nice if we could add Skeleton to text component itself, so that height always matches the lineHeight */}
        <Skeleton color="onPrimary" width={300} height={24}>
          {customer?.displayName !== undefined && (
            <Label1 variant="medium" color="$white" numberOfLines={1}>
              {customer?.displayName}
            </Label1>
          )}
        </Skeleton>
        <XStack gap="$2">
          <ProductBadge product={customer?.product} onPrimary />
          {!isThemeCustomer(customer) && (
            <StrategyBadge strategy={customer?.currentInvestmentStrategy} onPrimary />
          )}
        </XStack>
        <Skeleton color="onPrimary" width={150}>
          {!isApeironFetching && (
            <LocalizedNumbers
              value={performanceSummary?.balance}
              showZero
              numberStyle={{color: '$white', variant: 'medium'}}
              textComponent={Heading4}
            />
          )}
        </Skeleton>
        <Skeleton color="onPrimary" width={150}>
          {!isApeironFetching && (
            <XStack>
              <PerformanceBadge
                backgroundColor={media.sm ? '$white10' : undefined}
                color="$white"
                value={performanceSummary?.performance?.amount}
                textComponent={Label1}
                showZero
              />
            </XStack>
          )}
        </Skeleton>
      </YStack>
    </PrimaryHeader>
  );
}
