import {useMemo} from 'react';

import {INITIAL_RANGE} from '@/constants/skia';
import {Range} from '@/types/skia';
import {useChartContext} from '../context';

export const useGetChartRange = () => {
  const {size, yAxis, xAxis, offsetData, removeHorizontalOffset, overlapYAxes, chartHeight} =
    useChartContext();

  return useMemo<Range>(() => {
    if (size.height === size.width) return INITIAL_RANGE;

    const yOffset = offsetData.width / 2 + offsetData.pointSize / 2;
    const xOffset = removeHorizontalOffset ? offsetData.width / 2 : yOffset;
    const leftYAxisOffset = overlapYAxes ? 0 : yAxis?.left?.size?.width ?? 0;
    const rightYAxisOffset = overlapYAxes ? 0 : yAxis?.right?.size?.width ?? 0;

    const leftBound = leftYAxisOffset + xOffset;
    const rightBound = size.width - rightYAxisOffset - xOffset;

    if (leftBound === rightBound) return INITIAL_RANGE;

    const topBound = (xAxis?.top?.size?.height ?? 0) + yOffset;
    let bottomBound = size.height - (xAxis?.bottom?.size?.height ?? 0) - yOffset;

    if (chartHeight !== undefined) {
      bottomBound = topBound + chartHeight;
    }

    if (topBound === bottomBound) return INITIAL_RANGE;

    return {
      x: [leftBound, rightBound],
      y: [topBound, bottomBound],
    };
  }, [removeHorizontalOffset, size, yAxis, xAxis, offsetData, overlapYAxes, chartHeight]);
};
