import {Card} from '@/components/cards/Card';
import {ThemeSelect} from '@/components/inputs/ThemeSelect';
import {CommunicationLanguageInput} from '@/modules/profile/components/CommunicationLanguageInput';
import {MarketingConsent} from '@/modules/profile/components/MarketingConsent';

export const LanguageDisplayMarketingChange = () => {
  return (
    <Card gap="$6">
      <CommunicationLanguageInput />
      <ThemeSelect />
      <MarketingConsent containerProps={{maxWidth: 500}} />
    </Card>
  );
};
