import {MINUTE, baseApi} from '@/store/queries/baseApi';
import {
  AccSummaryResponse,
  CrossAccSummaryResponse,
  DataPoint,
  GetAbsoluteReturnResponse,
  GetBalancesResponse,
  PaginatedResponseTransactionsDTO,
  RatesOfReturn,
  TransactionsSummary,
} from '@/types/api/apeiron';
import {captureException} from '@sentry/react-native';

const BASE_PATH = '/apeiron/';

const extendedApeironApi = baseApi.injectEndpoints({
  endpoints: build => ({
    getPerformanceSummaries: build.query<CrossAccSummaryResponse[], GetPerformanceSummariesParams>({
      query: ({customerIds, attachHistory = false}) => {
        const params = new URLSearchParams();

        for (const id of customerIds) {
          params.append('customerId', id);
        }

        if (attachHistory) {
          params.append('verbosity', 'HISTORY');
        }

        return {url: `${BASE_PATH}v4/summary/?${params.toString()}`};
      },
      keepUnusedDataFor: 5 * MINUTE,
    }),
    getPerformanceSummary: build.query<AccSummaryResponse, GetPerformanceSummaryParams>({
      query: ({customerId}) => {
        const params = new URLSearchParams();
        params.append('customerId', customerId);

        return {url: `${BASE_PATH}v4/summary/?${params.toString()}`};
      },
      transformResponse: (response: CrossAccSummaryResponse[], meta, arg) => {
        if (response?.[0]?.items[0]) {
          return response?.[0].items[0];
        }
        captureException('Chould not extract single performance summary from response');
        return response as unknown as AccSummaryResponse;
      },
      keepUnusedDataFor: 5 * MINUTE,
    }),
    getPerformanceSummaryWithHistory: build.query<
      GetPerformanceSummaryWithHistoryResponse,
      GetPerformanceSummaryWithHistoryParams
    >({
      query: ({customerId, history = true, historyDays}) => {
        const params = new URLSearchParams();

        params.append('customerId', customerId);

        if (history) {
          params.append('verbosity', 'HISTORY');

          if (historyDays) {
            params.append('historyDays', historyDays.toString());
          }
        }

        return {url: `${BASE_PATH}v4/summary/?${params.toString()}`};
      },
      transformResponse: (response?: CrossAccSummaryResponse[]) => {
        const customerSummery = response?.at(0);

        if (!customerSummery) {
          captureException('getPerformanceSummaryWithHistory customerSummery is undefined');

          return response as unknown as GetPerformanceSummaryWithHistoryResponse;
        }

        const performanceSummary = customerSummery.items.at(0);

        if (!performanceSummary) {
          captureException('getPerformanceSummaryWithHistory performanceSummary is undefined');

          return response as unknown as GetPerformanceSummaryWithHistoryResponse;
        }

        return {
          ...performanceSummary,
          history: customerSummery.history,
        };
      },
      keepUnusedDataFor: 15 * MINUTE,
    }),
    getPortfolioBalance: build.query<GetBalancesResponse, string>({
      query: (customerId: string) => {
        return {url: `${BASE_PATH}v3/balances`, params: {customerId}};
      },
      keepUnusedDataFor: 5 * MINUTE,
    }),
    getPortfolioPerformance: build.query<GetAbsoluteReturnResponse, string>({
      query: (customerId: string) => {
        return {url: `${BASE_PATH}v3/absolute-returns`, params: {customerId}};
      },
      keepUnusedDataFor: 5 * MINUTE,
    }),
    getRatesOfReturn: build.query<DataPoint[], string>({
      query: (customerId: string) => {
        return {url: `${BASE_PATH}v3/rates-of-return`, params: {customerId}};
      },
      transformResponse: (res: RatesOfReturn[]) => {
        return res?.[0]?.timeWeighted?.overall ?? [];
      },
      keepUnusedDataFor: 5 * MINUTE,
    }),
    getTransactions: build.query<PaginatedResponseTransactionsDTO, GetTransactionsParams>({
      query: args => {
        const {category, ...params} = args;
        let newParams: GetTransactionsParams = {...params};
        if (category && category !== 'ALL') newParams = {...params, category};
        return {url: `${BASE_PATH}v3/transactions`, params: newParams};
      },
      providesTags: ['TRANSACTIONS'],
      keepUnusedDataFor: 5 * MINUTE,
    }),
    getTransactionsSummary: build.query<TransactionsSummary[], string>({
      query: customerId => {
        const params = new URLSearchParams();
        params.append('customerId', customerId);

        return {url: `${BASE_PATH}v3/transactions-summary`, params};
      },
      keepUnusedDataFor: 5 * MINUTE,
    }),
  }),
  overrideExisting: false,
});

export type GetTransactionsParams = {
  limit: number;
  offset: number;
  category?: string;
  customerId: string;
};

type GetPerformanceSummariesParams = {
  customerIds: string[];
  attachHistory?: boolean;
};

type GetPerformanceSummaryParams = {
  customerId: string;
};

type GetPerformanceSummaryWithHistoryParams = GetPerformanceSummaryParams & {
  history?: boolean;
  historyDays?: number;
};

type GetPerformanceSummaryWithHistoryResponse = AccSummaryResponse & {
  history?: CrossAccSummaryResponse['history'];
};

export const {
  useGetTransactionsQuery,
  useGetPerformanceSummaryQuery,
  useGetPerformanceSummariesQuery,
  useGetPerformanceSummaryWithHistoryQuery,
  useGetTransactionsSummaryQuery,
  useGetPortfolioBalanceQuery,
  useGetPortfolioPerformanceQuery,
  useGetRatesOfReturnQuery,
} = extendedApeironApi;
