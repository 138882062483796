import {useTranslate} from '@tolgee/react';
import {Stack} from 'expo-router';
import React from 'react';
import {Platform} from 'react-native';

import {ResponsiveScrollView} from '@/components/views/ResponsiveScrollView';
import {WebFooter} from '@/modules/navigation/components/footer/WebFooter';
import {Header} from '@/modules/navigation/components/header/Header';
import {TaxIdDataCard} from '@/modules/profile/components/TaxIdDataCard';
import {TaxExemptionCard} from '@/modules/profile/components/taxes/TaxExemptionCard';
import {YStack} from 'tamagui';

export default function TabTaxes() {
  const {t} = useTranslate();

  return (
    <ResponsiveScrollView>
      <YStack gap="$5">
        <Stack.Screen
          options={{
            headerShown: true,
            header: () => (
              <Header title={t('NAVIGATION.TAX.TITLE')} backButton={Platform.OS !== 'web'} />
            ),
          }}
        />
        <TaxIdDataCard />
        <TaxExemptionCard />
      </YStack>
      <WebFooter withCalendly />
    </ResponsiveScrollView>
  );
}
