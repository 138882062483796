import {useTranslate} from '@tolgee/react';
import {Platform} from 'react-native';
import {useTheme} from 'tamagui';

import {useBackNavigation} from '@/modules/navigation/hooks/useBackNavigation';
import {HeaderBackButton} from '@react-navigation/elements';

type Props = {
  onBackPress?: () => void;
};

export const BackButton = ({onBackPress}: Props) => {
  const {t} = useTranslate();

  const theme = useTheme();

  const handleBackButtonPressed = useBackNavigation(onBackPress);

  return (
    <HeaderBackButton
      displayMode={Platform.OS === 'web' ? 'default' : 'minimal'}
      label={t('BACK')}
      style={Platform.select({
        ios: {marginRight: -22, paddingLeft: 8},
        android: {marginRight: -16, paddingLeft: 0},
      })}
      onPress={handleBackButtonPressed}
      tintColor={theme.primary.val}
    />
  );
};
