import SvgFile from '@/components/icons/SvgFile';
import {Label2, Label3} from '@/components/texts/Label';
import {LocalizedDate} from '@/components/texts/LocalizedDate';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {getPrecision} from '@/modules/orders/helpers/renderAmount';
import {isOrderWithAmount} from '@/modules/orders/types/isOrderWithAmount';
import {useGetCustomerAccountQuery} from '@/store/queries/customersApi';
import {
  useInvestmentStrategiesQuery,
  useInvestmentThemesQuery,
  useOrderTypesQuery,
} from '@/store/queries/referenceApi';
import {OrderResponse, ThemeWithdrawalDistribution} from '@/types/api/customers.v2';
import {skipToken} from '@reduxjs/toolkit/query';
import {useTranslate} from '@tolgee/react';
import React, {useMemo} from 'react';
import {View, XStack, YStack} from 'tamagui';

type DetailedViewProps = React.ComponentPropsWithoutRef<typeof View> & {
  order: OrderResponse;
  customerId: string;
};

export const DetailView: React.FC<DetailedViewProps> = ({order, customerId, ...props}) => {
  const {t} = useTranslate();
  const {data: orderTypes} = useOrderTypesQuery();
  const {data: investmentThemes} = useInvestmentThemesQuery();
  const {data: investmentStrategies} = useInvestmentStrategiesQuery();
  const {data: customer} = useGetCustomerAccountQuery(customerId);
  const {data: targetCustomer} = useGetCustomerAccountQuery(
    ('targetCustomerId' in order && order?.targetCustomerId) || skipToken
  );
  const {getByCode} = useRefdataLocalizable();

  const addressChange = useMemo(
    () =>
      order.orderType === 'ADDRESS_CHANGE' && (
        <View>
          <Label3 color="$neutral500">{t('ORDERS_PAGE.NEW_ADDRESS')}</Label3>
          <Label3>{order.street + ' ' + order.streetNumber}</Label3>
          <Label3>{order.postalCode + ' ' + order.city}</Label3>
          <Label3>{order.country}</Label3>
        </View>
      ),
    [order, t]
  );

  const taxExemption = useMemo(
    () =>
      order.orderType === 'TAX_EXEMPTION' && (
        <YStack marginTop="$4" gap="$4">
          <View>
            <Label3 color="$neutral500">{t('ORDERS_PAGE.TAX_ID')}</Label3>
            <Label3>{order.germanTin}</Label3>
          </View>

          <View>
            <Label3 color="$neutral500">{t('ORDERS_PAGE.START_DATE')}</Label3>
            <LocalizedDate textComponent={Label3} date={order.startDate} />
          </View>

          {order.endDate && (
            <View>
              <Label3 color="$neutral500">{t('ORDERS_PAGE.END_DATE')}</Label3>
              <LocalizedDate textComponent={Label3} date={order.endDate} />
            </View>
          )}
        </YStack>
      ),
    [order, t]
  );

  const themeAccount = useMemo(
    () =>
      (order.orderType === 'THEME_WITHDRAWAL' || order.orderType === 'THEME_DEPOSIT') &&
      order.themeDistribution && (
        <View>
          <Label3 color="$neutral500">{t('PORTFOLIO.THEMES')}</Label3>
          {Object.entries(order.themeDistribution).map(
            ([key, value]: [string, ThemeWithdrawalDistribution]) => (
              <XStack justifyContent="space-between" key={key} gap="$2">
                <Label3>{getByCode(investmentThemes, key)?.label}</Label3>
                {value.amount && (
                  <LocalizedNumbers
                    variant="currency"
                    precision={getPrecision(order.orderType)}
                    value={value.amount}
                    textComponent={Label3}
                  />
                )}
                {value?.full && <Label3>{t('THEME_WITHDRAWAL_ORDER.DETAILS-FULL')}</Label3>}
              </XStack>
            )
          )}
        </View>
      ),
    [order, t, getByCode, investmentThemes]
  );

  const strategyChange = useMemo(
    () =>
      order.orderType === 'STRATEGY_CHANGE' && (
        <View>
          <Label3 color="$neutral500">{t('ORDERS_PAGE.NEW_STRATEGY')}</Label3>
          <Label3>{getByCode(investmentStrategies, order.strategy)?.label}</Label3>
        </View>
      ),
    [order, t, getByCode, investmentStrategies]
  );

  const internalTransfer = useMemo(() => {
    return (
      order.orderType === 'INTERNAL_TRANSFER' && (
        <YStack gap="$4">
          <View>
            <Label3 color="$neutral500">{t('INTERNAL_TRANSFER.FROM')}</Label3>
            <Label3>{customer?.displayName}</Label3>
          </View>
          <View>
            <Label3 color="$neutral500">{t('INTERNAL_TRANSFER.TO')}</Label3>
            <Label3>{targetCustomer?.displayName}</Label3>
          </View>
        </YStack>
      )
    );
  }, [order.orderType, t, customer?.displayName, targetCustomer?.displayName]);

  const amount = useMemo(
    () =>
      isOrderWithAmount(order) && (
        <View>
          <Label3 color="$neutral500">{t('TAX_INFO.LIST_ITEM.AMOUNT')}</Label3>
          <LocalizedNumbers
            variant="currency"
            precision={getPrecision(order.orderType)}
            value={order.amount}
            textComponent={Label3}
          />
        </View>
      ),
    [order, t]
  );

  const indicatorCircle = useMemo(
    () => (
      <View
        borderRadius="$full"
        alignSelf="flex-start"
        alignItems="center"
        justifyContent="center"
        padding="$3"
        backgroundColor={
          order.status === 'REJECTED'
            ? '$redTransparent'
            : order.status === 'CANCELED'
              ? '$neutral50'
              : order.status === 'COMPLETED'
                ? '$greenTransparent'
                : '$ceruleanTransparent'
        }
      >
        <SvgFile
          name="circle"
          color={
            order.status === 'REJECTED'
              ? '$red'
              : order.status === 'CANCELED'
                ? '$neutral500'
                : order.status === 'COMPLETED'
                  ? '$green'
                  : '$cerulean'
          }
          size={10}
        />
      </View>
    ),
    [order]
  );

  const statusLabel = useMemo(() => {
    switch (order.status) {
      case 'REJECTED':
        return t('ORDERS_PAGE.ORDER_STATUS.REJECTED');
      case 'CREATED':
        return t('ORDERS_PAGE.ORDER_STATUS.CREATED');
      case 'SUBMITTED':
        return t('ORDERS_PAGE.ORDER_STATUS.SUBMITTED');
      case 'COMPLETED':
        return t('ORDERS_PAGE.ORDER_STATUS.COMPLETED');
      case 'CANCELED':
        return t('ORDERS_PAGE.ORDER_STATUS.CANCELED');
    }
  }, [order.status, t]);

  return (
    <View>
      <XStack marginBottom="$5">
        <Label2 variant="medium">{getByCode(orderTypes, order.orderType)?.label}</Label2>
      </XStack>

      <YStack>
        <XStack gap="$4">
          <View
            borderRadius="$full"
            alignSelf="flex-start"
            alignItems="center"
            justifyContent="center"
            padding="$3"
            backgroundColor="$neutral50"
          >
            <SvgFile name="circle" color="$primary" size={10} />
          </View>
          <View>
            <Label3 color="$neutral500">{t('ORDERS_PAGE.ORDER_CREATED')}</Label3>
            <LocalizedDate textComponent={Label3} date={order.createdAt} />
          </View>
        </XStack>

        <XStack gap="$4">
          <View width="10%" alignItems="center">
            <View flex={1} width={1} backgroundColor="$primaryTransparent" />
          </View>

          <View marginVertical="$6" paddingRight="$3">
            {amount}
            {addressChange}
            {taxExemption}
            {themeAccount}
            {strategyChange}
            {internalTransfer}
          </View>
        </XStack>

        <XStack gap="$4">
          {indicatorCircle}
          <View>
            <Label3 color="$neutral500">{statusLabel}</Label3>
            <LocalizedDate date={order.updatedAt} textComponent={Label3} />
          </View>
        </XStack>
      </YStack>
    </View>
  );
};

export default DetailView;
