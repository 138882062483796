import {Modal} from '@/components/modal/Modal';
import {Heading5} from '@/components/texts/Heading';
import {Label4} from '@/components/texts/Label';
import {Divider} from '@/components/views/Divider';
import {CustomerSummaryInformation} from '@/modules/orders/components/CustomerSummaryInformation';
import {DepositOrderSummaryCard} from '@/modules/orders/components/DepositOrderSummaryCard';
import {InternalTransferOrderSummaryCard} from '@/modules/orders/components/InternalTransfer/InternalTransferOrderSummaryCard';
import {ReferenceAccountChangeSummaryCard} from '@/modules/orders/components/ReferenceAccountChangeSummaryCard';
import {ThemeDepositSummaryCard} from '@/modules/orders/components/Theme/ThemeDepositSummaryCard';
import {ThemeWithdrawalSummaryCard} from '@/modules/orders/components/Theme/ThemeWithdrawalSummaryCard';
import {FullWithdrawalOrderSummaryCard} from '@/modules/orders/components/Withdrawal/FullWithdrawalOrderSummaryCard';
import {WithdrawalOrderSummaryCard} from '@/modules/orders/components/Withdrawal/WithdrawalOrderSummaryCard';
import {CustomThemeDistribution} from '@/modules/orders/types/CustomThemeDistribution';
import {
  OrderRequest,
  ThemeDepositDistribution,
  ThemeWithdrawalDistribution,
} from '@/types/api/customers.v2';
import {ModalRef} from '@/types/modal';
import {useTranslate} from '@tolgee/react';
import React, {forwardRef, useCallback, useImperativeHandle, useMemo, useRef} from 'react';
import {YStack} from 'tamagui';

type OrderSummaryModalProps = {
  customerId: string;
  value?: number;
  orderType: Extract<
    Required<OrderRequest>['orderType'],
    | 'DEPOSIT'
    | 'REFERENCE_ACCOUNT_CHANGE'
    | 'THEME_DEPOSIT'
    | 'THEME_WITHDRAWAL'
    | 'WITHDRAWAL'
    | 'FULL_WITHDRAWAL'
    | 'INTERNAL_TRANSFER'
  >;
  reason?: string;
  iban?: string;
  bic?: string;
  bank?: string;
  holderFullName?: string;
  themeDepositDistribution?: CustomThemeDistribution<ThemeDepositDistribution>;
  themeWithdrawalDistribution?: CustomThemeDistribution<ThemeWithdrawalDistribution>;
  targetCustomerId?: string;
};

export const OrderSummaryModal = forwardRef(function OrderSummaryModal(
  {
    customerId,
    value,
    orderType,
    reason,
    iban,
    bic,
    bank,
    holderFullName,
    themeDepositDistribution,
    themeWithdrawalDistribution,
    targetCustomerId,
  }: OrderSummaryModalProps,
  ref: any
) {
  const {t} = useTranslate();
  const modalRef = useRef<ModalRef>(null);

  useImperativeHandle(ref, () => ({
    open: modalRef.current?.open,
  }));

  const onFinished = useCallback(() => {
    modalRef.current?.open(false);
  }, []);

  const summary = useMemo(() => {
    switch (orderType) {
      case 'DEPOSIT':
        return (
          <DepositOrderSummaryCard customerId={customerId} value={value} onFinished={onFinished} />
        );
      case 'REFERENCE_ACCOUNT_CHANGE':
        return (
          <ReferenceAccountChangeSummaryCard
            customerId={customerId}
            iban={iban ?? ''}
            bic={bic ?? ''}
            bank={bank ?? ''}
            holderFullName={holderFullName ?? ''}
            onFinished={onFinished}
          />
        );
      case 'THEME_DEPOSIT':
        return (
          <ThemeDepositSummaryCard
            customerId={customerId}
            orderType={orderType}
            value={value}
            onFinished={onFinished}
            themeDepositDistribution={themeDepositDistribution}
          />
        );
      case 'THEME_WITHDRAWAL':
        return (
          <ThemeWithdrawalSummaryCard
            customerId={customerId}
            orderType={orderType}
            value={value}
            onFinished={onFinished}
            themeWithdrawalDistribution={themeWithdrawalDistribution}
          />
        );
      case 'WITHDRAWAL':
        return (
          <WithdrawalOrderSummaryCard
            customerId={customerId}
            value={value}
            reason={reason ?? ''}
            onFinished={onFinished}
          />
        );
      case 'FULL_WITHDRAWAL':
        return (
          <FullWithdrawalOrderSummaryCard
            customerId={customerId}
            reason={reason ?? ''}
            onFinished={onFinished}
          />
        );
      case 'INTERNAL_TRANSFER':
        return (
          <InternalTransferOrderSummaryCard
            customerId={customerId}
            targetCustomerId={targetCustomerId ?? ''}
            value={value ?? 0}
            onFinished={onFinished}
          />
        );
    }
  }, [
    bank,
    bic,
    customerId,
    holderFullName,
    iban,
    onFinished,
    orderType,
    reason,
    targetCustomerId,
    themeDepositDistribution,
    themeWithdrawalDistribution,
    value,
  ]);

  const info = useMemo(() => {
    switch (orderType) {
      case 'DEPOSIT':
        return t('ORDER_SUMMARY.TIME-INFO.DEPOSIT');
      case 'THEME_DEPOSIT':
        return t('ORDER_SUMMARY.TIME-INFO.THEME_DEPOSIT');
      case 'REFERENCE_ACCOUNT_CHANGE':
        return t('ORDER_SUMMARY.TIME-INFO.REFERENCE_ACCOUNT_CHANGE');
      case 'WITHDRAWAL':
        return t('ORDER_SUMMARY.TIME-INFO.WITHDRAWAL');
      case 'THEME_WITHDRAWAL':
        return t('ORDER_SUMMARY.TIME-INFO.THEME_WITHDRAWAL');
      case 'FULL_WITHDRAWAL':
        return t('ORDER_SUMMARY.TIME-INFO.FULL_WITHDRAWAL');
      case 'INTERNAL_TRANSFER':
        return t('ORDER_SUMMARY.TIME-INFO.INTERNAL_TRANSFER');
    }
  }, [orderType, t]);

  const title = useMemo(() => {
    switch (orderType) {
      case 'DEPOSIT':
        return t('ORDER_SUMMARY.DEPOSIT.TITLE');
      case 'REFERENCE_ACCOUNT_CHANGE':
        return t('ORDER_SUMMARY.REFERENCE_ACCOUNT_CHANGE.TITLE');
      case 'THEME_DEPOSIT':
        return t('ORDER_SUMMARY.THEME_DEPOSIT.TITLE');
      case 'THEME_WITHDRAWAL':
        return t('ORDER_SUMMARY.THEME_WITHDRAWAL.TITLE');
      case 'WITHDRAWAL':
        return t('ORDER_SUMMARY.WITHDRAWAL.TITLE');
      case 'FULL_WITHDRAWAL':
        return t('ORDER_SUMMARY.FULL_WITHDRAWAL.TITLE');
      case 'INTERNAL_TRANSFER':
        return t('ORDER_SUMMARY.INTERNAL_TRANSFER.TITLE');
    }
  }, [orderType, t]);

  return (
    <Modal ref={modalRef}>
      <Modal.Content $gtSm={{maxWidth: 500, width: '100%', alignSelf: 'center'}}>
        <YStack gap="$4">
          <Heading5 variant="medium">{title}</Heading5>
          <CustomerSummaryInformation
            customerId={customerId}
            internalTransfer={orderType === 'INTERNAL_TRANSFER'}
            amount={value}
          />
          <Divider backgroundColor="$neutral100" />
          {summary}
          <Label4 color="$neutral400" textAlign="center">
            {info}
          </Label4>
        </YStack>
      </Modal.Content>
    </Modal>
  );
});
