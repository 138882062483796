import {Card} from '@/components/cards/Card';
import SvgFile from '@/components/icons/SvgFile';
import {Heading5} from '@/components/texts/Heading';
import {Label3sm2} from '@/components/texts/Label';
import {Paragraph2} from '@/components/texts/Paragraph';
import {Skeleton} from '@/components/views/Skeleton';
import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {AddressChangeCard} from '@/modules/profile/components/AddressChangeCard';
import {useCountriesQuery} from '@/store/queries/referenceApi';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';
import {useTranslate} from '@tolgee/react';
import {useState} from 'react';
import {XStack, YStack} from 'tamagui';

export function AddressDataCard() {
  const {t} = useTranslate();
  const {data: countries} = useCountriesQuery();
  const {getByCode} = useRefdataLocalizable();
  const {data: user} = useGetSelfUserQuery();
  const [showChange, setShowChange] = useState(false);
  const onPress = () => {
    setShowChange(true);
  };

  return (
    <Card gap="$6">
      <XStack justifyContent="space-between">
        <Heading5 variant="medium">{t('PERSONAL_DETAILS.ADDRESS_DATA.TITLE')}</Heading5>
        {!showChange && (
          <XStack gap="$1.5" alignItems="center" onPress={onPress} cursor="pointer">
            <SvgFile size={14} name="pen" color="$primary" />
            <Paragraph2 color="$primary">{t('PERSONAL_DETAILS.ADDRESS_DATA.CHANGE')}</Paragraph2>
          </XStack>
        )}
      </XStack>

      {!showChange ? (
        <YStack gap="$3">
          <Label3sm2 color="$neutral500">{t('PERSONAL_DETAILS.ADDRESS_DATA.ACTUAL')}</Label3sm2>
          {user === undefined || user.address === undefined || user.address.street === undefined ? (
            <Skeleton height={35} width={200} />
          ) : (
            <YStack gap="$0.5">
              <Label3sm2>
                {user?.address?.street}, {user?.address?.streetNumber}
              </Label3sm2>
              <Label3sm2>
                {user?.address?.postCode} {user?.address?.city}
              </Label3sm2>
              <Label3sm2>
                {user?.address?.countryCode &&
                  getByCode(countries, user?.address?.countryCode)?.label}
              </Label3sm2>
            </YStack>
          )}
        </YStack>
      ) : (
        <AddressChangeCard onClose={() => setShowChange(false)} />
      )}
    </Card>
  );
}
