import {Button} from '@/components/buttons/Button';
import {Modal} from '@/components/modal/Modal';
import {Heading4} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {BaseRecommendationCard} from '@/modules/recommendations/components/BaseRecommendationCard';
import {RecommendationModalBulletPoint} from '@/modules/recommendations/components/RecommendationModalBulletPoint';
import {RecommendationsProps} from '@/modules/recommendations/components/Recommendations';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useAppDispatch} from '@/store';
import {useGetCustomerAccountsQuery} from '@/store/queries/customersApi';
import {setDismissedRecommendationsSavingsrate} from '@/store/reducers/app';
import {ModalRef} from '@/types/modal';
import {useTranslate} from '@tolgee/react';
import {useRouter} from 'expo-router';
import {useRef} from 'react';
import {YStack} from 'tamagui';

export function SavingsrateRecommendationCard({type}: RecommendationsProps) {
  const {t} = useTranslate();
  const router = useRouter();
  const ref = useRef<ModalRef>(null);
  const posthog = usePostHog();
  const {data: customers} = useGetCustomerAccountsQuery();
  const dispatch = useAppDispatch();

  const onDismiss = () => {
    dispatch(setDismissedRecommendationsSavingsrate(true));
  };

  return (
    <Modal ref={ref}>
      <Modal.Trigger asChild>
        <BaseRecommendationCard
          onPress={() => {
            posthog?.capture('recommendation_savingsrate_click');
          }}
          onDismiss={onDismiss}
          title={t('RECOMMENDATION.SAVINGSRATE.TITLE')}
          description={t('RECOMMENDATION.SAVINGSRATE.DESCRIPTION')}
          iconPath={require('@/assets/images/recommendation-savingsrate.png')}
          type={type}
        />
      </Modal.Trigger>
      <Modal.Content side>
        <YStack gap="$5">
          <YStack gap="$3">
            <Heading4 variant="medium">{t('RECOMMENDATION.SAVINGSRATE.MODAL.TITLE')}</Heading4>
            <Paragraph2 color="$neutral400">
              {t('RECOMMENDATION.SAVINGSRATE.MODAL.DESCRIPTION')}
            </Paragraph2>
          </YStack>

          <YStack gap="$3">
            <RecommendationModalBulletPoint
              iconName="savings-wealthbuilding"
              title={t('RECOMMENDATION.SAVINGSRATE.MODAL.POINT_1.TITLE')}
              description={t('RECOMMENDATION.SAVINGSRATE.MODAL.POINT_1.DESCRIPTION')}
            />

            <RecommendationModalBulletPoint
              iconName="savings-retirement"
              title={t('RECOMMENDATION.SAVINGSRATE.MODAL.POINT_2.TITLE')}
              description={t('RECOMMENDATION.SAVINGSRATE.MODAL.POINT_2.DESCRIPTION')}
            />
          </YStack>
          <Button
            isLoading={customers?.items[0].id === undefined}
            onPress={() => {
              ref.current?.open(false);
              if (customers?.items[0].id !== undefined) {
                posthog?.capture('recommendation_savingsrate_button_click');
                router.navigate({
                  pathname: '/overview/[customerId]',
                  params: {customerId: customers?.items[0].id, triggerSavingsRateChange: 'true'},
                });
              }
            }}
          >
            {t('RECOMMENDATION.SAVINGSRATE.MODAL.CTA')}
          </Button>
        </YStack>
      </Modal.Content>
    </Modal>
  );
}
