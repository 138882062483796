import {useAppSelector} from '@/store';
import {InvestmentStrategy, LocalizableValue} from '@/types/api/refdata.v2';
import {useCallback} from 'react';

export type Base = {
  code: string;
  label: LocalizableValue;
};

export type LocalizableRefData<T extends Base> = {
  code: string;
  label: string;
} & Omit<T, 'label'>;

export default function useRefdataLocalizable() {
  const currentLanguage = useAppSelector(state => state.app.language);

  const getByCode = useCallback(
    <T extends Base>(
      data: T[] | undefined,
      code: string,
      identifier: string = 'code'
    ): LocalizableRefData<T> | undefined => {
      // @ts-ignore
      const tmp = data?.find(_ => _[identifier] === code);
      const label = tmp?.label[currentLanguage];
      if (!label) return undefined;
      return {
        ...tmp,
        label,
      };
    },
    [currentLanguage]
  );

  const getStrategyByCode = useCallback(
    <T extends Base>(
      data: T[] | undefined,
      code: string,
      identifier: string = 'code'
    ): LocalizableRefData<T> | undefined => {
      // @ts-ignore
      const tmp = data?.find(_ => _[identifier] === code);
      const label = tmp?.label[currentLanguage];
      if (isInvestmentStrategy(tmp)) {
        const explanation = tmp.explanation[currentLanguage];
        const description = tmp.description[currentLanguage];
        const valueAtRisk = tmp.valueAtRisk ? Math.round(tmp.valueAtRisk * 10000) / 100 : undefined;
        const varDeltaVsDax = tmp.varDeltaVsDax
          ? Math.round(tmp.varDeltaVsDax * 10000) / 100
          : undefined;
        const yearlyReturn = tmp.yearlyReturn
          ? Math.round(tmp.yearlyReturn * 100) / 100
          : undefined;
        const etfFees = tmp.etfFees ? Math.round(tmp.etfFees * 100) / 100 : undefined;
        return {
          ...tmp,
          label,
          explanation,
          description,
          valueAtRisk,
          varDeltaVsDax,
          yearlyReturn,
          etfFees,
        };
      }
      if (!label) return undefined;
      return {
        ...tmp,
        label,
      };
    },
    [currentLanguage]
  );

  const getAll = useCallback(
    <T extends Base>(data: T[] | undefined): LocalizableRefData<T>[] | undefined => {
      return data
        ?.map(item => {
          return {
            ...item,
            label: item?.label[currentLanguage],
          } as {
            code: string;
            label: string;
          } & T;
        })
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        });
    },
    [currentLanguage]
  );

  return {getByCode, getAll, getStrategyByCode};
}

function isInvestmentStrategy(data: any): data is InvestmentStrategy {
  return data?.explanation;
}
