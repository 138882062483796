import {SelectInput} from '@/components/inputs/select-input/SelectInput';
import {InputErrorMessage} from '@/components/texts/InputErrorMessage';
import {Label3} from '@/components/texts/Label';
import {Surface} from '@/components/views/Surface';
import {FormInputProps, SelectInputItem} from '@/types/FormInputProps';
import {FieldValues, useController} from 'react-hook-form';
import {YStack} from 'tamagui';

type FormSelectInputProps<T extends FieldValues> = FormInputProps<T> & {
  items: SelectInputItem[];
  placeholder?: string;
};

export function FormSelectInput<T extends FieldValues>({
  control,
  name,
  label,
  bordered,
  items,
  placeholder,
  wrapperProps,
}: FormSelectInputProps<T>) {
  const {
    field,
    fieldState: {error},
  } = useController({
    name,
    control,
  });

  return (
    <YStack gap="$2">
      <Label3 color="$neutral500">{label}</Label3>
      <Surface flexGrow={0}>
        {items && (
          <SelectInput
            items={items}
            selectedItem={field.value}
            onSelect={field.onChange}
            placeholder={placeholder ?? '--'}
            wrapperProps={{
              borderWidth: 0,
              bordered,
              ...wrapperProps,
            }}
            testID={name}
          />
        )}
      </Surface>
      <InputErrorMessage error={error?.message} />
    </YStack>
  );
}
