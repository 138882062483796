import {IconButton} from '@/components/buttons/IconButton';
import {Card} from '@/components/cards/Card';
import SvgFile from '@/components/icons/SvgFile';
import {Heading5} from '@/components/texts/Heading';
import {Label3} from '@/components/texts/Label';
import {Skeleton} from '@/components/views/Skeleton';
import {isUpvestCustomer} from '@/helpers/isUpvestCustomer';
import {ClearingAccountBlock} from '@/modules/orders/components/DepositMethod/ClearingAccountBlock';
import {ChangeDisplayNameModal} from '@/modules/profile/components/ChangeDisplayNameModal';
import {KWG} from '@/modules/profile/constants/kwg';
import {useGetCustomerAccountQuery} from '@/store/queries/customersApi';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';
import {skipToken} from '@reduxjs/toolkit/query';
import {useTranslate} from '@tolgee/react';
import {router} from 'expo-router';
import {useMedia, View, XStack, YStack} from 'tamagui';

export function AccountDetails({customerId}: {customerId: string | undefined}) {
  const {data: customer, isFetching} = useGetCustomerAccountQuery(customerId || skipToken);
  const {data: user} = useGetSelfUserQuery();
  const media = useMedia();
  const isUpvest = isUpvestCustomer(customer);

  const {t} = useTranslate();
  return (
    <YStack flex={1} gap="$4">
      <Skeleton width="100%" height={180}>
        {!isFetching && (
          <Card gap="$6" padding="$6">
            <XStack gap="$4">
              <View
                padding="$1"
                width={32}
                height={32}
                backgroundColor="$ceruleanTransparent"
                borderRadius="$full"
                alignItems="center"
                justifyContent="center"
              >
                <SvgFile name="account-details" size={20} color="$cerulean" />
              </View>
              <Heading5 variant="medium">{t('PERSONAL_DETAILS.ACCOUNT_INFO')}</Heading5>
            </XStack>
            <View
              flexDirection={media.sm ? 'column' : 'row'}
              justifyContent="space-between"
              flexWrap="wrap"
              gap="$4"
            >
              <XStack justifyContent="space-between" minWidth="45%" gap="$2">
                <YStack gap="$4">
                  <Label3 color="$neutral500">{t('ACCOUNT_DETAILS.ACCOUNT_NAME')}</Label3>
                  <Label3 color="$neutral500">{t('ACCOUNT_DETAILS.KONTO_ID')}</Label3>
                  {!isUpvest && (
                    <Label3 color="$neutral500">{t('ACCOUNT_DETAILS.DAB_ACCOUNT_ID')}</Label3>
                  )}
                </YStack>
                <YStack gap="$4" maxWidth={250} flexShrink={1}>
                  {customer && <ChangeDisplayNameModal customer={customer} />}
                  <Label3 variant="medium" textAlign="right">
                    {customer?.customerNumber}
                  </Label3>
                  {!isUpvest && (
                    <Label3 variant="medium" textAlign="right">
                      {customer?.custodianCustomerId}
                    </Label3>
                  )}
                </YStack>
              </XStack>
              <XStack justifyContent="space-between" minWidth="45%" gap="$2">
                <YStack gap="$4">
                  <Label3 color="$neutral500">{t('ACCOUNT_DETAILS.USER_NAME')}</Label3>
                  <Label3 color="$neutral500">{t('ACCOUNT_DETAILS.LICENSE')}</Label3>
                </YStack>
                <YStack gap="$4" maxWidth={250} flexShrink={1}>
                  <Skeleton width="100%">
                    {user && (
                      <Label3
                        variant="medium"
                        textAlign="right"
                      >{`${user.firstName} ${user.lastName}`}</Label3>
                    )}
                  </Skeleton>
                  <Label3 variant="medium" textAlign="right">
                    {KWG}
                  </Label3>
                </YStack>
              </XStack>
            </View>
          </Card>
        )}
      </Skeleton>

      <Skeleton width="100%" height={180}>
        {!isFetching && (
          <Card gap="$6" padding="$6">
            <XStack justifyContent="space-between">
              <XStack gap="$4">
                <View
                  padding="$1"
                  width={32}
                  height={32}
                  backgroundColor="$greenTransparent"
                  borderRadius="$full"
                  alignItems="center"
                  justifyContent="center"
                >
                  <SvgFile name="bank" size={20} color="$green" />
                </View>
                <Heading5 variant="medium">{t('ORDER_SUMMARY.REFERENCE-ACCOUNT')}</Heading5>
              </XStack>
              {customerId && (
                <IconButton
                  name="edit"
                  color="$primary"
                  onPress={() => {
                    router.navigate({
                      pathname: '/overview/[customerId]/order/change-reference-account',
                      params: {customerId},
                    });
                  }}
                />
              )}
            </XStack>

            <View
              flexDirection={media.sm ? 'column' : 'row'}
              justifyContent="space-between"
              flexWrap="wrap"
              gap="$4"
            >
              <XStack justifyContent="space-between" minWidth="45%" gap="$2">
                <YStack gap="$4">
                  <Label3 color="$neutral500">{t('ACCOUNT_DETAILS.ACCOUNT_HOLDER')}</Label3>
                  <Label3 color="$neutral500">{t('ACCOUNT_DETAILS.IBAN')}</Label3>
                  <Label3 color="$neutral500">{t('ACCOUNT_DETAILS.BIC')}</Label3>
                </YStack>
                <YStack gap="$4" maxWidth={250} flexShrink={1}>
                  <Label3 variant="medium" textAlign="right">
                    {customer?.bankAccount?.holderFullName}
                  </Label3>
                  <Label3 variant="medium" textAlign="right">
                    {customer?.bankAccount?.iban}
                  </Label3>
                  <Label3 variant="medium" textAlign="right">
                    {customer?.bankAccount?.bic}
                  </Label3>
                </YStack>
              </XStack>
              <XStack justifyContent="space-between" minWidth="45%" gap="$2">
                <YStack gap="$4">
                  {customer?.bankAccount?.bank && (
                    <Label3 color="$neutral500">{t('ACCOUNT_DETAILS.BANK_NAME')}</Label3>
                  )}
                  {customer?.mandateReference && (
                    <Label3 color="$neutral500">{t('ACCOUNT_DETAILS.MANDATE_REFERENCE')}</Label3>
                  )}
                </YStack>
                <YStack gap="$4" maxWidth={250} flexShrink={1}>
                  {customer?.bankAccount?.bank && (
                    <Label3 variant="medium" textAlign="right">
                      {customer?.bankAccount?.bank}
                    </Label3>
                  )}
                  {customer?.mandateReference && (
                    <Label3 variant="medium" textAlign="right">
                      {customer?.mandateReference}
                    </Label3>
                  )}
                </YStack>
              </XStack>
            </View>
          </Card>
        )}
      </Skeleton>
      {!isUpvest && (
        <Card gap="$6" padding="$6">
          <XStack gap="$4">
            <View
              padding="$1"
              width={32}
              height={32}
              backgroundColor="$greenTransparent"
              borderRadius="$full"
              alignItems="center"
              justifyContent="center"
            >
              <SvgFile name="bank" size={20} color="$green" />
            </View>
            <Heading5 variant="medium">{t('ORDER_SUMMARY.CLEARING-ACCOUNT')}</Heading5>
          </XStack>
          <View
            flexDirection={media.sm ? 'column' : 'row'}
            justifyContent="space-between"
            flexWrap="wrap"
            gap="$4"
          >
            <ClearingAccountBlock customerId={customer?.id ?? ''} withOutCopyButtons />
          </View>
        </Card>
      )}
    </YStack>
  );
}
