import {Canvas, Paragraph, Skia, useFonts} from '@shopify/react-native-skia';
import {SkTextStyle} from '@shopify/react-native-skia/src/skia/types/Paragraph/TextStyle';
import {SharedValue, useDerivedValue, useSharedValue} from 'react-native-reanimated';
import {useMedia, useTheme} from 'tamagui';

import {createParagraph} from '@/components/skia/utils';
import {BalanceAndPerformance} from '@/modules/overview/components/PerformanceChartsLegend';
import {useAppSelector} from '@/store';

type HomeChartDateProps = {
  legendValue: Readonly<SharedValue<BalanceAndPerformance | undefined>>;
};

export function HomeChartDate({legendValue}: HomeChartDateProps) {
  const theme = useTheme();
  const language = useAppSelector(state => state.app.language);
  const legendWidth = useSharedValue(125);
  const media = useMedia();

  const DATE_FONT_SIZE = media.sm ? 12 : 14;

  const textStyle: SkTextStyle = {
    fontSize: DATE_FONT_SIZE,
    color: media.sm ? Skia.Color(theme.white.val) : Skia.Color(theme.neutral500.val),
    fontFamilies: ['Inter'],
  };

  const fonts = useFonts({
    // @ts-ignore
    Inter: [require('@tamagui/font-inter/otf/Inter-Regular.otf')],
  });

  const paragraph = useDerivedValue(() => {
    if (!legendValue.value || !fonts || legendWidth.value === 0) return null;

    const text = Intl.DateTimeFormat(language, {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    }).format(legendValue.value.time);

    return createParagraph(fonts, textStyle, text, legendWidth.value);
  });

  const width = useDerivedValue(() => {
    if (!paragraph.value) return 0;

    return Math.ceil(paragraph.value.getLongestLine());
  });

  return (
    <Canvas style={{height: DATE_FONT_SIZE + 4}}>
      <Paragraph paragraph={paragraph} x={0} y={0} width={width} />
    </Canvas>
  );
}
