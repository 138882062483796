import {XStack, YStack} from 'tamagui';

import {Label3} from '@/components/texts/Label';
import {LocalizableRefData} from '@/hooks/useRefdataLocalizable';
import {Security} from '@/types/api/refdata.v2';

export function SecurityDetails({security}: {security: LocalizableRefData<Security>}) {
  return (
    <YStack gap="$2">
      <XStack justifyContent="space-between">
        <Label3 color="$neutral500" flex={1}>
          {security.name}
        </Label3>
        <Label3 color="$neutral500">{security.code}</Label3>
      </XStack>
      <Label3>{security.label}</Label3>
    </YStack>
  );
}
