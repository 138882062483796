import SvgFile from '@/components/icons/SvgFile';
import {FilterSelect} from '@/components/inputs/FilterSelect';
import {FilterSelectCustomerId} from '@/components/inputs/FilterSelectCustomerId';
import {SelectItem} from '@/components/inputs/select-input/SelectItem';
import {Modal} from '@/components/modal/Modal';
import {Label3} from '@/components/texts/Label';
import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {
  useGetCustomerAccountsQuery,
  useGetCustomerInboxSummariesQuery,
} from '@/store/queries/customersApi';
import {
  useInboxDocumentCategoriesQuery,
  useInboxDocumentSourcesQuery,
} from '@/store/queries/referenceApi';
import {BottomSheetFlashListRef} from '@/types/refs';
import {BottomSheetFlashList} from '@gorhom/bottom-sheet';
import {skipToken} from '@reduxjs/toolkit/query';
import {useTranslate} from '@tolgee/react';
import {useEffect, useMemo, useRef, useState} from 'react';
import {GetProps, View, XStack, YStack, useMedia} from 'tamagui';

export type DocumentFilterOnSelect = {
  customerId: string;
  source: string;
  category: string;
};

type DocumentsFilterProps = {
  onSelect: ({customerId, source, category}: DocumentFilterOnSelect) => void;
  containerProps?: GetProps<typeof XStack>;
};

export function DocumentsFilter({onSelect, containerProps}: DocumentsFilterProps) {
  const {t} = useTranslate();
  const media = useMedia();
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>();
  const [selectedSource, setSelectedSource] = useState<string>('ALL');
  const [selectedCategory, setSelectedCategory] = useState<string>('ALL');
  const {data: customers} = useGetCustomerAccountsQuery();
  const {data: categories} = useInboxDocumentCategoriesQuery();
  const {data: sources} = useInboxDocumentSourcesQuery();
  const {data: inboxDocumentCategories} = useGetCustomerInboxSummariesQuery(
    selectedCustomerId || skipToken
  );
  const {getByCode} = useRefdataLocalizable();

  const flashListRef = useRef<BottomSheetFlashListRef>();
  const listData = useMemo(
    () =>
      categories
        ?.filter(_ =>
          inboxDocumentCategories?.items.some(idc => {
            if (selectedSource === 'ALL') {
              return idc.category === _.code;
            } else {
              return idc.category === _.code && idc.source === selectedSource;
            }
          })
        )
        .map(_ => {
          return {..._, selected: selectedCategory === _.code};
        }),
    [categories, inboxDocumentCategories?.items, selectedCategory, selectedSource]
  );

  const customersContent = useMemo(() => {
    return customers?.items?.map(customer => {
      return (
        <SelectItem
          key={customer.id}
          item={{
            key: customer.id,
            name: customer.displayName,
          }}
          selected={customer.id === selectedCustomerId}
          wrapperProps={{onPress: () => setSelectedCustomerId(customer.id)}}
        />
      );
    });
  }, [customers?.items, selectedCustomerId]);

  useEffect(() => {
    setSelectedCustomerId(customers?.items?.[0]?.id);
  }, [customers?.items]);

  useEffect(() => {
    setSelectedCategory('ALL');
    setSelectedSource('ALL');
  }, [selectedCustomerId]);

  useEffect(() => {
    setSelectedCategory('ALL');
  }, [selectedSource]);

  const sourcesContent = useMemo(() => {
    return sources
      ?.filter(source => inboxDocumentCategories?.items.some(_ => _.source === source.code))
      .map(source => {
        return (
          <SelectItem
            key={source.code}
            item={{
              key: source.code,
              name: getByCode(sources, source.code)?.label as string,
            }}
            selected={source.code === selectedSource}
            wrapperProps={{
              onPress: () => setSelectedSource(_ => (_ === source.code ? 'ALL' : source.code)),
            }}
          />
        );
      });
  }, [getByCode, inboxDocumentCategories?.items, selectedSource, sources]);

  useEffect(() => {
    if (selectedCustomerId)
      onSelect({
        customerId: selectedCustomerId,
        source: selectedSource,
        category: selectedCategory,
      });
  }, [onSelect, selectedCategory, selectedCustomerId, selectedSource]);

  if (inboxDocumentCategories === undefined || inboxDocumentCategories.items.length === 0) {
    return null;
  }

  if (media.sm) {
    return (
      <Modal>
        <Modal.Trigger asChild>
          <View alignSelf="flex-end" paddingHorizontal="$4" {...containerProps}>
            <SvgFile name="filter" color="$primary" size={24} />
          </View>
        </Modal.Trigger>
        <Modal.Content typeContainer="CUSTOM">
          <BottomSheetFlashList
            ref={flashListRef as unknown as BottomSheetFlashListRef}
            estimatedItemSize={52}
            renderItem={({item}) => {
              return (
                <SelectItem
                  key={item.code}
                  item={{key: item.code, name: getByCode(categories, item.code)?.label as string}}
                  selected={item.selected}
                  wrapperProps={{
                    onPress: () => {
                      setSelectedCategory(item.code);
                    },
                  }}
                />
              );
            }}
            ListHeaderComponent={() => {
              return (
                <YStack>
                  <Label3 color="$neutral500">{t('DOCUMENTS.BOTTOM-SHEET.CUSTOMERS')}</Label3>
                  {customersContent}
                  <Label3 color="$neutral500">{t('DOCUMENTS.BOTTOM-SHEET.SOURCE')}</Label3>
                  {sourcesContent}
                  <Label3 color="$neutral500">{t('DOCUMENTS.BOTTOM-SHEET.CATEGORY')}</Label3>
                </YStack>
              );
            }}
            data={listData}
            contentContainerStyle={{paddingBottom: 8, paddingHorizontal: 16}}
          />
        </Modal.Content>
      </Modal>
    );
  } else {
    return (
      <XStack justifyContent="flex-end" gap="$4" paddingHorizontal="$4" {...containerProps}>
        <FilterSelectCustomerId
          onFilterSelected={(option: string) => {
            setSelectedCustomerId(option);
            setSelectedSource('ALL');
            setSelectedCategory('ALL');
          }}
          preselected={selectedCustomerId}
        />
        <FilterSelect.DocumentSources
          onFilterSelected={option => {
            setSelectedSource(option);
          }}
          customerId={selectedCustomerId}
          documentSource={selectedSource}
        />
        <FilterSelect.DocumentCategories
          onFilterSelected={option => {
            setSelectedCategory(option);
          }}
          customerId={selectedCustomerId}
          sourceFilter={selectedSource}
          documentCategory={selectedCategory}
        />
      </XStack>
    );
  }
}
