import {EmailVerificationFailure} from '@/modules/orders/components/EmailVerification/EmailVerificationFailure';
import useEmailVerifiedInterval from '@/providers/session-provider/useEmailVerificationState';
import {Redirect, useRouter} from 'expo-router';
import {useCallback} from 'react';

export default function EmailVerificationFailurePage() {
  const {emailVerified, isResending, onEmailResend} = useEmailVerifiedInterval();
  const router = useRouter();

  const onLogin = useCallback(() => {
    router.replace('/');
  }, [router]);

  if (emailVerified) {
    return <Redirect href={{pathname: '/'}} />;
  }

  return (
    <EmailVerificationFailure
      isResending={isResending}
      onEmailResend={onEmailResend}
      onLogin={onLogin}
    />
  );
}
