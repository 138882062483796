import {Button} from '@/components/buttons/Button';
import {ReferenceAccountInformation} from '@/components/cards/ReferenceAccountInformation';
import {Label3} from '@/components/texts/Label';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {WithLabel} from '@/components/texts/WithLabel';
import {usePostCustomerOrderMutation} from '@/store/queries/customersApi';
import {useTranslate} from '@tolgee/react';
import {toast} from 'burnt';
import {useRouter} from 'expo-router';
import React, {useCallback} from 'react';
import {YStack} from 'tamagui';

type RegularOrderSummaryCardProps = {
  customerId: string;
  value?: number;
  onFinished?: () => void;
  unstyled?: boolean;
};

export function DepositOrderSummaryCard({
  customerId,
  value,
  onFinished,
  unstyled = false,
}: RegularOrderSummaryCardProps) {
  const {t} = useTranslate();
  const [postOrder] = usePostCustomerOrderMutation();
  const router = useRouter();

  const submitOrder = useCallback(async () => {
    if (value) {
      const res = await postOrder({
        orderType: 'DEPOSIT',
        customerId,
        amount: value,
      });
      if (res.error) {
        toast({
          preset: 'error',
          title: t('SNACKBAR.ORDER-NOT-CREATED'),
        });
      } else {
        onFinished?.();
        router.dismissTo({pathname: '/overview/[customerId]', params: {customerId}});
        toast({
          preset: 'done',
          title: t('SNACKBAR.DEPOSIT-CREATED'),
        });
      }
    }
  }, [value, postOrder, customerId, router, t, onFinished]);

  return (
    <YStack gap="$3">
      <WithLabel header={t('ORDER_SUMMARY.DEPOSIT.AMOUNT-TITLE')} horizontal>
        {value && <LocalizedNumbers value={value} textComponent={Label3} />}
      </WithLabel>
      <ReferenceAccountInformation customerId={customerId} variant="deposit" column />
      <Button alignSelf="stretch" onPress={submitOrder}>
        {t('ORDER_SUMMARY.CONFIRM')}
      </Button>
    </YStack>
  );
}
