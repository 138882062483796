import {UserResponseV3} from '@/types/api/users';

export function mapUserToFormNationalities(user: UserResponseV3 | undefined) {
  return user?.nationalities
    ? (Object.keys(user.nationalities)
        .map(_ => {
          const nats = user.nationalities;
          if (!nats) return undefined;
          const document = {
            document: nats[_].uploadKey
              ? [{objectKey: nats[_].uploadKey, docName: 'uploaded-document.png'}]
              : [],
          };
          const number = nats[_].number ? {number: nats[_].number} : {};
          return {
            country: _,
            ...document,
            ...number,
          };
        })
        .filter(_ => _ !== undefined) as {
        number?: undefined;
        document: {
          objectKey: string;
          docName: string;
        }[];
        country: string;
      }[])
    : [{country: 'DE', document: []}];
}
