import {FormCustomCheckbox} from '@/components/inputs/form/FormCustomCheckbox';
import {TaxResidenceSelect} from '@/components/inputs/form/tax-residence/TaxResidenceSelect';
import {Label2} from '@/components/texts/Label';
import {TaxesDetailsSchemaType, useValidations} from '@/hooks/useValidations';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {UserResponseV3} from '@/types/api/users';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslate} from '@tolgee/react';
import React, {forwardRef, useEffect, useImperativeHandle} from 'react';
import {useForm} from 'react-hook-form';

import {YStack} from 'tamagui';

type UserTaxFormProps = {
  onValid: (data: TaxesDetailsSchemaType) => void;
  user: UserResponseV3 | undefined;
  child?: boolean;
  setIsLoading?: (loading: boolean) => void;
};

export type SubmitFormRef = {submit: () => Promise<void>};

export const UserTaxForm = forwardRef<any, UserTaxFormProps>(function UserTaxForm(
  {onValid, user, child = false, setIsLoading},
  ref
) {
  const {t} = useTranslate();
  const {taxesDetailsFormSchema} = useValidations();
  const posthog = usePostHog();

  useImperativeHandle(ref, () => ({
    submit: handleSubmit(onValid, data => {
      posthog?.capture('form_submit_failed', data);
    }),
  }));

  const {
    handleSubmit,
    control,
    setValue,
    formState: {isSubmitting},
  } = useForm({
    resolver: yupResolver(taxesDetailsFormSchema),
    mode: 'onChange',
    defaultValues: {
      unitedStatesTax: true,
    },
  });

  useEffect(() => {
    setIsLoading?.(isSubmitting);
  }, [isSubmitting, setIsLoading]);

  useEffect(() => {
    const tins =
      user?.taxIdentification?.filter(_ => _.country !== user?.address?.countryCode) ?? [];
    if (user?.address?.countryCode) {
      setValue('taxIdentification', [
        {
          country: user?.address?.countryCode,
          number: user?.taxIdentification?.find(_ => _.country === user?.address?.countryCode)
            ?.number,
        },
        ...tins,
      ]);
    }
  }, [setValue, user]);

  return (
    <YStack gap="$6">
      <TaxResidenceSelect control={control} name="taxIdentification" />
      <FormCustomCheckbox
        control={control}
        name="unitedStatesTax"
        label={
          <Label2 variant="medium">
            {child
              ? t('PERSONAL_DETAILS.CHILD.NOT_US_PERSON', {firstName: user?.firstName})
              : t('PERSONAL_DETAILS.NOT_US_PERSON')}
          </Label2>
        }
      />
    </YStack>
  );
});
