import {SvgName} from '@/components/icons/SvgFile';
import {Product} from '@/types/fixedTypes/customers.v2/Product';

export const PRODUCT_ICON_MAP: Record<Product, SvgName> = {
  INVESTMENT_ACCOUNT: 'ginmon-invest',
  SAVINGS_ACCOUNT: 'ginmon-topzins',
  CHILD_ACCOUNT: 'ginmon-junior',
  THEME_ACCOUNT: 'ginmon-motive',
  VL_ACCOUNT: 'ginmon-vl',
  COMPANY_INVESTMENT_ACCOUNT: 'ginmon-invest',
  COMPANY_SAVINGS_ACCOUNT: 'ginmon-invest',
};
