import {Blur, Group, Paint, Paragraph, SkParagraph} from '@shopify/react-native-skia';
import {ComponentProps} from 'react';

type Props = {
  blur?: boolean;
  blurRadius?: number;
  paragraph?: SkParagraph | null;
  width?: number;
} & Omit<ComponentProps<typeof Paragraph>, 'paragraph' | 'width'>;

export const Text = ({blur, blurRadius = 5, paragraph, width, ...paragraphProps}: Props) => {
  if (!paragraph) return null;

  return (
    <Group
      layer={
        blur ? (
          <Paint>
            <Blur blur={blurRadius} mode="clamp" />
          </Paint>
        ) : null
      }
    >
      <Paragraph
        {...paragraphProps}
        width={width ?? Math.ceil(paragraph.getLongestLine())}
        paragraph={paragraph}
      />
    </Group>
  );
};
