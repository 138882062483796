import {useSession} from '@/providers/session-provider/SessionProvider';

import {AnimatedSplashLoader} from '@/components/views/AnimatedSplashLoader';
import {useAppDispatch} from '@/store';
import {setDeeplink} from '@/store/reducers/app';
import {Href, useGlobalSearchParams, useLocalSearchParams} from 'expo-router';
import {useEffect, useRef} from 'react';

export function LoginScreen() {
  const {signIn} = useSession();
  const params = useGlobalSearchParams();
  const {url} = useLocalSearchParams<{url: string}>();
  const dispatch = useAppDispatch();
  const ref = useRef<boolean>(false);

  useEffect(() => {
    if (!ref.current) {
      dispatch(setDeeplink(url as Href));
      signIn();
      ref.current = true;
    }
  }, [dispatch, params, signIn, url]);

  return <AnimatedSplashLoader viewKey="login-web-loader" />;
}
