import {AccordionContent} from '@/components/inputs/AccordionContent';
import {AccordionItem} from '@/components/inputs/AccordionItem';
import {AccordionTrigger} from '@/components/inputs/AccordionTrigger';
import {DepositMethodAccordionContent} from '@/modules/orders/components/DepositMethod/DepositMethodAccordionContent';
import {DepositMethodAccordionTriggerContent} from '@/modules/orders/components/DepositMethod/DepositMethodAccordionTriggerContent';
import {DepositMethodType} from '@/modules/orders/types/DepositMethod';
import React from 'react';
import {Control} from 'react-hook-form';

export function DepositMethodAccordionRow({
  customerId,
  selected,
  depositMethod,
  variant,
  control,
}: {
  depositMethod: DepositMethodType;
  selected: boolean;
  customerId: string;
  variant?: 'radio';
  control: Control;
}) {
  const isRadio = variant === 'radio';
  return (
    <AccordionItem
      value={depositMethod}
      borderColor={selected ? '$primary' : isRadio ? '$neutral200' : 'transparent'}
    >
      <AccordionTrigger selected={selected}>
        {({open}: {open: boolean}) => (
          <DepositMethodAccordionTriggerContent depositMethod={depositMethod} />
        )}
      </AccordionTrigger>
      <AccordionContent>
        <DepositMethodAccordionContent
          customerId={customerId}
          depositMethod={depositMethod}
          control={control}
        />
      </AccordionContent>
    </AccordionItem>
  );
}
