import {useEffect} from 'react';
import {Control, useWatch} from 'react-hook-form';

export function useHandleNoExperienceOption(
  questionCode: string,
  setValue: (code: string, value: string[]) => void,
  control: Control
) {
  const value = useWatch({name: questionCode, disabled: questionCode !== 'EXP_V2', control});
  useEffect(() => {
    if (value && value?.length > 1) {
      const idx = value.findIndex((_: string) => _ === 'ASSET_CLASS_NONE');
      if (idx === 0) {
        setValue(
          questionCode,
          value?.filter((_: string) => _ !== 'ASSET_CLASS_NONE')
        );
      } else if (idx !== -1) {
        setValue(questionCode, ['ASSET_CLASS_NONE']);
      }
    }
  }, [questionCode, setValue, value]);
}
