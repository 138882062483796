import {skipToken} from '@reduxjs/toolkit/query';
import {useTranslate} from '@tolgee/react';
import {Href, useRouter} from 'expo-router';
import {useCallback, useMemo} from 'react';
import {YStack} from 'tamagui';

import {Button} from '@/components/buttons/Button';
import {Heading4} from '@/components/texts/Heading';
import {RejectChildAccountModal} from '@/modules/onboarding/components/summary/RejectChildAccountModal';
import {SummaryCardsChild} from '@/modules/onboarding/components/summary/SummaryCardsChild';
import {useGetLatestAcceptableInterviewsQuery} from '@/store/queries/interviewApi';
import {useGetValidOrdersQuery, usePatchOrderMutation} from '@/store/queries/ordersApi';
import {useGetSpecificUserQuery} from '@/store/queries/usersApi';
import {Planner} from '@/store/reducers/onboarding/state';
import {ChildAccountCreationOrderResponse} from '@/types/api/orders';

export function UserSummaryScreenChild({href, orderId}: {href: Href; orderId: string}) {
  const {t} = useTranslate();
  const router = useRouter();
  const {data: orders} = useGetValidOrdersQuery();
  const order = orders?.items.find(order => order.id === orderId);
  const {data: childUser} = useGetSpecificUserQuery(
    order ? {userID: (order as ChildAccountCreationOrderResponse).childUserId} : skipToken
  );
  const {data: interviews} = useGetLatestAcceptableInterviewsQuery();
  const [patchOrder] = usePatchOrderMutation();

  const interviewId = useMemo(() => {
    return interviews?.find(interview => interview.type === 'BASIC_V2')?.id;
  }, [interviews]);

  const onPress = useCallback(() => {
    router.navigate(href);
  }, [href, router]);

  const onReject = useCallback(async () => {
    if (!interviewId || !orderId) return;
    const payload = [
      {op: 'add', path: '/secondGuardianInterviewId', value: interviewId},
      {op: 'replace', path: '/declinedBySecondGuardian', value: true},
      {op: 'add', path: '/declinationReason', value: 'OTHER'},
    ];
    await patchOrder({orderId, payload: JSON.stringify(payload)}).unwrap();
    router.replace('/');
  }, [interviewId, orderId, patchOrder, router]);

  let planner: Planner | undefined;

  if (order) {
    const {account} = order as ChildAccountCreationOrderResponse;

    planner = {
      initialDepositAmount: account.initialDepositAmount,
      initialRecurringDepositAmount: account.initialRecurringDeposit?.amount ?? 0,
      selectedStrategy: account.initialInvestmentStrategy,
    };
  }

  return (
    <YStack gap="$6" marginTop="$6">
      <Heading4 variant="medium" paddingHorizontal="$2">
        {t('PERSONAL_DETAILS.SECOND_GUARDIAN.USER_SUMMARY.TITLE', {
          firstName: childUser?.firstName || '',
        })}
      </Heading4>

      <SummaryCardsChild
        childUser={childUser}
        planner={planner}
        bank={order?.account?.bankAccount}
      />

      <YStack gap="$4" paddingHorizontal="$2">
        <Button
          size="large"
          onPress={onPress}
          alignSelf="stretch"
          $gtSm={{maxWidth: 500, width: '100%', alignSelf: 'center'}}
        >
          {t('PERSONAL_DETAILS.CHILD.USER_SUMMARY.CONTINUE')}
        </Button>
        <RejectChildAccountModal
          childFirstName="childname"
          onContinue={onPress}
          onReject={onReject}
        >
          <Button
            size="large"
            secondary
            alignSelf="stretch"
            $gtSm={{maxWidth: 500, width: '100%', alignSelf: 'center'}}
          >
            {t('PERSONAL_DETAILS.CHILD.USER_SUMMARY.REJECT')}
          </Button>
        </RejectChildAccountModal>
      </YStack>
    </YStack>
  );
}
