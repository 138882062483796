import {yupResolver} from '@hookform/resolvers/yup';
import {skipToken} from '@reduxjs/toolkit/query';
import {captureException} from '@sentry/react-native';
import {useTranslate} from '@tolgee/react';
import {Href, useRouter} from 'expo-router';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {YStack} from 'tamagui';

import {Heading4} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import useRefdataLocalizable, {LocalizableRefData} from '@/hooks/useRefdataLocalizable';
import {useValidations} from '@/hooks/useValidations';
import {InterviewContinueButton} from '@/modules/interview/components/InterviewContinueButton';
import {IncomeOrWealthCards} from '@/modules/onboarding/components/IncomeOrWealthCards';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useIncomeSourcesQuery, useWealthSourcesQuery} from '@/store/queries/referenceApi';
import {useGetSelfUserQuery, useUpdateUserMutation} from '@/store/queries/usersApi';
import {ShortenedCustomerInformationResponse} from '@/types/api/customers.v2';
import {IncomeSource} from '@/types/api/refdata.v2';

type LocalizableIncomeOrWealthSource = LocalizableRefData<IncomeSource>;

type IncomeOrWealthValues = {
  sources: string[];
};

const INCOME_ORDER = ['SAL', 'SEE', 'REL', 'CAA', 'AGF', 'BUS', 'OTH'];

const WEALTH_ORDER = ['SAL', 'SEE', 'REE', 'CAA', 'BUS', 'INH', 'AGF', 'DON'];

type Props = {
  type: 'income' | 'wealth';
  product?: ShortenedCustomerInformationResponse['product'];
  href: Href;
};

export const IncomeOrWealthScreen = ({type, href}: Props) => {
  const {t} = useTranslate();

  const {incomeOrWealthFormSchema} = useValidations();
  const posthog = usePostHog();

  const router = useRouter();

  const [localizebleIncomeSources, setLocalizebleIncomeSources] = useState<
    LocalizableIncomeOrWealthSource[]
  >([]);
  const [loading, setLoading] = useState(false);

  const {data: currentUser} = useGetSelfUserQuery();
  const {data: incomeSources} = useIncomeSourcesQuery(type !== 'income' ? skipToken : undefined);
  const {data: wealthSources} = useWealthSourcesQuery(type !== 'wealth' ? skipToken : undefined);

  const [updateUser] = useUpdateUserMutation();

  const {control, reset, handleSubmit} = useForm<IncomeOrWealthValues>({
    defaultValues: {sources: []},
    resolver: yupResolver(incomeOrWealthFormSchema),
  });

  const {getAll} = useRefdataLocalizable();

  useEffect(() => {
    if (!currentUser) return;

    const sources = type === 'income' ? currentUser.incomeSources : currentUser.wealthSources;

    reset({sources}, {keepDirty: true, keepTouched: true});
  }, [currentUser, reset, type]);

  useEffect(() => {
    const data = type === 'income' ? incomeSources : wealthSources;

    const localizableIncomeSources = getAll(data);

    if (!localizableIncomeSources) {
      setLocalizebleIncomeSources([]);

      return;
    }

    const order = type === 'income' ? INCOME_ORDER : WEALTH_ORDER;

    const sorted = [...localizableIncomeSources].sort(
      (a, b) => order.indexOf(a.code) - order.indexOf(b.code)
    );

    setLocalizebleIncomeSources(sorted);
  }, [incomeSources, getAll, type, wealthSources]);

  const handleValidSubmit = useCallback(
    async ({sources}: IncomeOrWealthValues) => {
      if (sources.length === 0 || !currentUser) return;

      try {
        setLoading(true);

        let changes;

        if (type === 'income') {
          changes = {incomeSources: sources};
        } else {
          changes = {wealthSources: sources};
        }

        await updateUser({currentUser, changes}).unwrap();

        router.navigate(href);
      } catch (error) {
        captureException(error);
      } finally {
        setLoading(false);
      }
    },
    [currentUser, type, updateUser, router, href]
  );

  const title = useMemo(
    () =>
      type === 'income'
        ? t('PERSONAL_DETAILS.TITLE_INCOME_SOURCE')
        : t('PERSONAL_DETAILS.TITLE_WEALTH_SOURCE'),
    [t, type]
  );

  return (
    <YStack gap="$4">
      <Heading4 variant="medium">{title}</Heading4>
      <Paragraph2 color="$neutral500">
        {t('PERSONAL_DETAILS.YOU_CAN_SELECT_MULTIPLE_OPTIONS')}
      </Paragraph2>
      <IncomeOrWealthCards items={localizebleIncomeSources} name="sources" control={control} />
      <YStack gap="$3">
        <InterviewContinueButton
          onPress={handleSubmit(handleValidSubmit, data => {
            posthog?.capture('form_submit_failed', data);
          })}
          isLoading={loading}
        >
          {t('CONTINUE')}
        </InterviewContinueButton>
      </YStack>
    </YStack>
  );
};
