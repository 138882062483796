import {Label3} from '@/components/texts/Label';
import {useMfaAvailability} from '@/modules/mfa/hooks/useMfaAvailability';
import {InfoBox} from '@/modules/orders/components/InfoBox';
import {useTranslate} from '@tolgee/react';
import {router} from 'expo-router';
import {useMedia, YStack} from 'tamagui';

export function MFARequiredNote() {
  const {t} = useTranslate();
  const {isMFAUsable, isFetching} = useMfaAvailability();
  const media = useMedia();

  if (isFetching || isMFAUsable) return null;
  return (
    <InfoBox wrapperProps={{alignItems: 'flex-start', backgroundColor: '$neutralBG'}}>
      <YStack gap="$2" flex={1}>
        <Label3 color="$primary" variant="medium">
          {t('HOME.VERIFICATION.PLEASE_NOTE')}
        </Label3>
        <Label3>
          {`${t('MFA.INITIAL-SETUP-REQUIRED.NOTE')} `}
          <Label3
            variant="medium"
            color="$primary"
            cursor="pointer"
            hoverStyle={{opacity: 0.8}}
            onPress={() => {
              if (media.sm) router.push('/profile/mfa');
              else router.push('/profile/security');
            }}
          >
            {t('MFA.INITIAL-SETUP-REQUIRED.NOTE-CTA')}
          </Label3>
        </Label3>
      </YStack>
    </InfoBox>
  );
}
