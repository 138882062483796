import {SelectInput} from '@/components/inputs/select-input/SelectInput';
import {InputLabel} from '@/components/texts/InputLabel';
import {GetProps, isTouchable, useMedia} from 'tamagui';

type ProfileSelectInputProps = {
  label?: string;
} & GetProps<typeof SelectInput>;

export function ProfileSelectInput({label, wrapperProps, ...rest}: ProfileSelectInputProps) {
  const media = useMedia();
  if (media.sm || isTouchable)
    return <SelectInput {...rest} wrapperProps={{unstyled: true, ...wrapperProps}} />;

  if (label)
    return (
      <InputLabel label={label}>
        <SelectInput wrapperProps={{bordered: true, ...wrapperProps}} {...rest} />
      </InputLabel>
    );

  return <SelectInput wrapperProps={wrapperProps} {...rest} />;
}
