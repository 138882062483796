import SvgFile from '@/components/icons/SvgFile';
import {SelectInput} from '@/components/inputs/select-input/SelectInput';
import {InputErrorMessage} from '@/components/texts/InputErrorMessage';
import {Label2, Label3} from '@/components/texts/Label';
import {LoadingSpinner} from '@/components/views/LoadingSpinner';
import {DocumentUploadErrorReason, UploadedDoc, useUploadFile} from '@/hooks/useUploadFile';
import {SelectInputItem} from '@/types/FormInputProps';
import {ModalRef} from '@/types/modal';
import {useTranslate} from '@tolgee/react';
import {useCallback, useMemo, useRef} from 'react';
import {Control, FieldValues, Path, useController} from 'react-hook-form';
import {Platform} from 'react-native';
import {View, ViewProps, XStack, YStack, YStackProps} from 'tamagui';

type FormDocumentUploadProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  userID?: number;
  fileContextPrefix: string;
  onSuccess?: (doc?: UploadedDoc) => void;
  onError?: (reason: DocumentUploadErrorReason) => void;
  container?: YStackProps;
  multi?: boolean;
  uploadAreaStyle?: ViewProps;
  pdf?: boolean;
  maxFileSize?: number;
};

export const FormDocumentUpload = <T extends FieldValues>({
  control,
  name,
  fileContextPrefix,
  userID,
  onSuccess,
  onError,
  container,
  multi = true,
  uploadAreaStyle,
  pdf = true,
  maxFileSize,
}: FormDocumentUploadProps<T>) => {
  const {t} = useTranslate();

  // TODO: for multiple documents we probably want to use `useFieldArray`
  const {
    field,
    fieldState: {error},
  } = useController({
    name,
    control,
  });

  const modalRef = useRef<ModalRef>(null);

  const updateDocuments = useCallback(
    (document?: UploadedDoc) => {
      if (!document) return;

      const currUploadedDocs = field.value as UploadedDoc[] | undefined;
      const prevDocuments = [...(currUploadedDocs ?? [])];

      if (multi) {
        field.onChange([...prevDocuments, document]);
      } else {
        field.onChange([document]);
      }
    },
    [field, multi]
  );

  const handleUploadSuccess = useCallback(
    (document?: UploadedDoc) => {
      updateDocuments(document);

      if (onSuccess) {
        onSuccess(document);
      }
    },
    [onSuccess, updateDocuments]
  );

  const {handleUploadSelectedDoc, status} = useUploadFile({
    fileContextPrefix,
    userID,
    onSuccess: handleUploadSuccess,
    onError,
    pdf,
    maxFileSize,
  });

  const removeDocument = useCallback(
    (index: number) => () => {
      const currUploadedDocs = field.value as UploadedDoc[] | undefined;

      if (!currUploadedDocs || currUploadedDocs.length === 0) return;

      const uploadedDocs = [...currUploadedDocs];

      uploadedDocs.splice(index, 1);

      field.onChange(uploadedDocs);
    },
    [field]
  );

  const showFiles = useMemo(() => {
    const currUploadedDocs = field.value as UploadedDoc[] | undefined;

    return !!currUploadedDocs && currUploadedDocs.length !== 0;
  }, [field.value]);

  const closeModal = useCallback(() => {
    if (!modalRef.current) return;

    modalRef.current.open(false);
  }, []);

  const onSelect = useCallback(
    (key: string) => {
      closeModal();
      if (key === 'camera' || key === 'photos') {
        handleUploadSelectedDoc(key);
      }
    },
    [closeModal, handleUploadSelectedDoc]
  );

  const handleSelectItem = useCallback(
    (item: string) => {
      onSelect(item);
      closeModal();
    },
    [onSelect, closeModal]
  );

  const data = [
    {key: 'camera', name: t('PROOF_OF_RESIDENCE.DOCUMENT.OPEN_CAMERA'), icon: 'camera'},
    {key: 'photos', name: t('PROOF_OF_RESIDENCE.DOCUMENT.OPEN_PHOTOS'), icon: 'attachment-photo'},
  ] as SelectInputItem[];

  const onPress = useCallback(() => {
    if (Platform.OS === 'web') {
      handleUploadSelectedDoc('library');
    } else {
      modalRef.current?.open(true);
    }
  }, [handleUploadSelectedDoc]);

  return (
    <YStack gap="$3" {...container}>
      <View
        backgroundColor="$primaryTransparent"
        borderColor="$primary"
        borderWidth="$0.25"
        borderRadius="$1"
        justifyContent="center"
        alignItems="center"
        padding="$4"
        cursor="pointer"
        hoverStyle={{
          opacity: 0.75,
        }}
        pressStyle={{
          opacity: 0.75,
        }}
        testID={name}
        {...uploadAreaStyle}
        onPress={onPress}
      >
        {status === 'loading' ? (
          <LoadingSpinner color="$primary" />
        ) : (
          <>
            <SvgFile name="plus" color="$primary" />
            <Label2 variant="medium" color="$primary">
              {t('PROOF_OF_RESIDENCE.UPLOAD_NEW_DOCUMENT')}
            </Label2>
          </>
        )}
      </View>
      {showFiles && (
        <YStack gap="$2">
          {(field.value as UploadedDoc[]).map((doc, index) => (
            <XStack
              key={doc.objectKey}
              backgroundColor="$greenTransparent"
              borderRadius="$1"
              padding="$2"
              gap="$3"
              justifyContent="space-between"
              alignItems="center"
            >
              <YStack flexGrow={1} flexShrink={1}>
                <Label3 color="$green">{doc.docName ?? doc.objectKey.split('/').at(-1)}</Label3>
              </YStack>
              <View onPress={removeDocument(index)} pressStyle={{opacity: 0.5}}>
                <SvgFile name="close" color="$emeraldLight" size={24} />
              </View>
            </XStack>
          ))}
        </YStack>
      )}
      <InputErrorMessage error={error?.message} />
      <SelectInput
        ref={modalRef}
        items={data}
        onSelect={handleSelectItem}
        cardProps={{
          borderRadius: '$1',
        }}
        wrapperProps={{
          unstyled: true,
          flex: 1,
        }}
        containerProps={{
          flex: 1,
        }}
        minWidth={350}
      >
        <View />
      </SelectInput>
    </YStack>
  );
};
