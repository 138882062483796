import {AccordionContent} from '@/components/inputs/AccordionContent';
import {AccordionTrigger} from '@/components/inputs/AccordionTrigger';
import {Label3} from '@/components/texts/Label';
import {LocalizedDate} from '@/components/texts/LocalizedDate';
import {PreviousTaxItemRow} from '@/modules/profile/components/taxes/PreviousTaxItemRow';
import {TaxExemptionOrderChangeResponse} from '@/types/api/users';
import {useTranslate} from '@tolgee/react';
import {Accordion, XStack, YStack} from 'tamagui';

type Props = {
  order: TaxExemptionOrderChangeResponse;
  index: number;
};

export const PreviousTaxItem = ({order, index}: Props) => {
  const {t} = useTranslate();

  return (
    <Accordion.Item value={index.toString()}>
      <AccordionTrigger>
        {({open}: {open: boolean}) => <PreviousTaxItemRow open={open} order={order} />}
      </AccordionTrigger>
      <AccordionContent>
        <XStack gap="$3" flexWrap="wrap">
          <YStack gap="$3" minWidth={100}>
            <Label3 color="$neutral400">{t('TAXES.TAX-EXEMPTION.PREVIOUS.VALID-UNTIL')}</Label3>
            {order.endYear ? (
              <Label3>{order.endYear}</Label3>
            ) : (
              <Label3>{t('TAX_INFO.LIST_ITEM.UNTIL_NEW_ORDER')}</Label3>
            )}
          </YStack>
          {order.spouse?.firstName && (
            <YStack gap="$3" minWidth={100}>
              <Label3 color="$neutral400">
                {t('TAXES.TAX-EXEMPTION.PREVIOUS.SPOUSE.FIRST-NAME')}
              </Label3>
              <Label3>{order.spouse?.firstName}</Label3>
            </YStack>
          )}
          {order.spouse?.lastName && (
            <YStack gap="$3" minWidth={100}>
              <Label3 color="$neutral400">
                {t('TAXES.TAX-EXEMPTION.PREVIOUS.SPOUSE.FLAST-NAME')}
              </Label3>
              <Label3>{order.spouse?.lastName}</Label3>
            </YStack>
          )}
          {order.spouse?.germanTin && (
            <YStack gap="$3" minWidth={100}>
              <Label3 color="$neutral400">{t('TAXES.TAX-EXEMPTION.PREVIOUS.SPOUSE.TIN')}</Label3>
              <Label3>{order.spouse?.germanTin}</Label3>
            </YStack>
          )}
          {order.spouse?.dateOfBirth && (
            <YStack gap="$3" minWidth={100}>
              <Label3 color="$neutral400">{t('TAXES.TAX-EXEMPTION.PREVIOUS.SPOUSE.DOB')}</Label3>
              <LocalizedDate date={order.spouse?.dateOfBirth} textComponent={Label3} />
            </YStack>
          )}
        </XStack>
      </AccordionContent>
    </Accordion.Item>
  );
};
