import {useSession} from '@/providers/session-provider/SessionProvider';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';
import {skipToken} from '@reduxjs/toolkit/query';

export function useIsUserComplete() {
  const {session, isLoading} = useSession();
  const {
    data: selfUser,
    isFetching,
    isLoading: isUserLoading,
  } = useGetSelfUserQuery(isLoading || !session ? skipToken : undefined);
  return {
    isUserComplete: !!selfUser?.complete,
    isFetching: isLoading || isFetching,
    isLoading: isLoading || isUserLoading,
  };
}
