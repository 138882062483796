export const removeStringSpaces = (t?: string): string => (t ? t.replace(/\s+/g, '') : '');

const zeroTen = (n: number) => (n === 0 ? 10 : n);

export const germanTaxIdChecksum = (taxId: string): boolean => {
  const calcY = (c: number, prev: number) => (zeroTen((c + prev) % 10) * 2) % 11;
  const c1 = Number.parseInt(taxId.charAt(0), 10);
  const c11 = Number.parseInt(taxId.charAt(10), 10);
  let x = (zeroTen(c1 % 10) * 2) % 11;

  for (let i = 1; i < 10; i++) {
    const c = Number.parseInt(taxId.charAt(i), 10);
    x = calcY(c, x);
  }

  return 11 - x === zeroTen(c11);
};

export const spaceIban = (iban: string): string => {
  return iban
    .replace(/ /g, '')
    .replace(/(.{4})/g, '$1 ')
    .trim();
};

export const spacePaymentReference = (paymentReference: string): string => {
  return paymentReference.replace(/(\/)/g, (match: string) => {
    return match + ' ';
  });
};
