import {isDateInPast} from '@/helpers/isDateInPast';
import {useGetMfaMethodsQuery} from '@/store/queries/authApi';
import {useEffect, useState} from 'react';

export type MethodWithTime = {
  phoneNumber: string;
  time: number;
};
export const useMfaAvailability = () => {
  const {
    data,
    isFetching,
    isLoading,
    refetch: updateAvailability,
  } = useGetMfaMethodsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [invalidMethod, setInvalidMethod] = useState<MethodWithTime>();
  const [isSetup, setIsSetup] = useState(false);

  useEffect(() => {
    if (!data || data.length === 0) {
      setIsSetup(false);
      setInvalidMethod(undefined);
    } else {
      setIsSetup(true);
      // Need to add buffer to avoid edge case where server time is in the future
      const method = data.find(method => method.validFrom && !isDateInPast(method.validFrom, 5000));

      if (method && method.phoneNumber && method.validFrom) {
        setInvalidMethod({
          phoneNumber: method.phoneNumber,
          time: Date.parse(method.validFrom),
        });
      } else {
        setInvalidMethod(undefined);
      }
    }
  }, [data]);

  return {
    /**
     * returns if user set up at least one number (even if if it's not usable yet)
     */
    isSetup,
    /**
     * returns the first number that is not usable yet
     */
    invalidMethod,
    isFetching,
    isLoading,
    /**
     * method to refetch mfa methods
     */
    updateAvailability,
    /**
     * returns if one-time-code can be used, so at least one valid number is set up
     */
    isMFAUsable: isSetup && invalidMethod === undefined,
  };
};
