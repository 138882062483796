export function substractFromDate(date: Date, val: number, periodType: 'years' | 'months') {
  const newDate = new Date(date);
  switch (periodType) {
    case 'years':
      newDate.setFullYear(newDate.getFullYear() - val);
      break;
    case 'months':
      newDate.setMonth(newDate.getMonth() - val);
      break;
    default:
      break;
  }
  return newDate;
}
