import {yupResolver} from '@hookform/resolvers/yup';
import {captureException} from '@sentry/react-native';
import {useTranslate} from '@tolgee/react';
import {toast} from 'burnt';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {YStack} from 'tamagui';

import {Button} from '@/components/buttons/Button';
import {FormPhonenumberInput} from '@/components/inputs/form/FormPhonenumberInput';
import {InputErrorMessage} from '@/components/texts/InputErrorMessage';
import {Paragraph2} from '@/components/texts/Paragraph';
import {convertToE164PhoneNumber} from '@/helpers/convertToE164PhoneNumber';
import {useGetViolationError} from '@/hooks/useGetViolationError';
import {useValidations} from '@/hooks/useValidations';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {
  useCreateAdditionalMethodMutation,
  useCreateInitialMethodMutation,
} from '@/store/queries/authApi';
import {MfaMethodSmsRequest, MfaMethodSmsResponse} from '@/types/api/auth';
import {MFAActionType} from '@/types/mfaAction';

type MFAEnableSecondFactorProps = {
  action: MFAActionType;
  onSuccess: (method: MfaMethodSmsResponse) => void;
  modal?: boolean;
};

export const MFAPhoneInput = ({action, onSuccess, modal}: MFAEnableSecondFactorProps) => {
  const {t} = useTranslate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const [createInitMethodTrigger] = useCreateInitialMethodMutation();
  const [createAdditionalMethodTrigger] = useCreateAdditionalMethodMutation();

  const {getViolationError, isFetching} = useGetViolationError();

  const posthog = usePostHog();

  const {phoneNumberSchema} = useValidations();

  const {control, handleSubmit} = useForm({
    resolver: yupResolver(phoneNumberSchema),
    defaultValues: {phoneNumber: '+49 '},
  });

  const createMFAMethod = async (phoneNumber: string) => {
    const payload: MfaMethodSmsRequest = {type: 'SMS', phoneNumber};

    try {
      setLoading(true);

      let response: MfaMethodSmsResponse;

      if (action.name === 'initial') {
        response = await createInitMethodTrigger(payload).unwrap();
      } else {
        response = await createAdditionalMethodTrigger(payload).unwrap();
      }

      onSuccess(response);
    } catch (error) {
      const errorText = getViolationError(error);

      if (errorText) {
        setError(errorText);
      } else {
        captureException(error);

        toast({
          preset: 'error',
          title: t('MFA.SOMETHING_WENT_WRONG'),
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = handleSubmit(
    ({phoneNumber}) => {
      if (error || !phoneNumber) return;

      const updatePhoneNumber = convertToE164PhoneNumber(phoneNumber);

      if (updatePhoneNumber === null) {
        setError(t('PERSONAL_DETAILS.INCORRECT_PHONE_NUMBER'));
      } else {
        createMFAMethod(updatePhoneNumber).catch(captureException);
      }
    },
    data => {
      posthog?.capture('form_submit_failed', data);
    }
  );

  return (
    <YStack gap="$2">
      <Paragraph2>{t('MFA_ENABLE_SECOND_FACTOR.DESCRIPTION')}</Paragraph2>
      <FormPhonenumberInput
        control={control}
        name="phoneNumber"
        label={t('MFA_ENABLE_SECOND_FACTOR.ADDITIONAL_TITLE')}
        bordered
        modal={modal}
      />
      <InputErrorMessage error={error} />
      <Button isLoading={loading || isFetching} onPress={onSubmit}>
        {t('MFA_ENABLE_SECOND_FACTOR.GET_SMS_CODE')}
      </Button>
    </YStack>
  );
};
