import {useTranslate} from '@tolgee/react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDerivedValue, useSharedValue} from 'react-native-reanimated';
import {View, XStack, YStack, useMedia, useTheme} from 'tamagui';

import {Card} from '@/components/cards/Card';
import SvgFile from '@/components/icons/SvgFile';
import {Chart, Line, XAxis, YAxis} from '@/components/skia';
import {WebGlFallback} from '@/components/skia/WebGlFallback';
import {Label3} from '@/components/texts/Label';
import {Skeleton} from '@/components/views/Skeleton';
import {Tab, Tabbar} from '@/modules/navigation/components/tabbar/Tabbar';
import {PerformanceChartLegend} from '@/modules/overview/components/PerformanceChartsLegend';
import {PerformanceDetailsModal} from '@/modules/overview/components/PerformanceDetailsModal';
import {useAppSelector} from '@/store';
import {
  useGetPerformanceSummaryWithHistoryQuery,
  useGetRatesOfReturnQuery,
} from '@/store/queries/apeironApi';
import {GetTextLabelFunc, SelectedPoint} from '@/types/skia';

type TimeRange = 'all' | 'curr_year' | 'year' | 'month' | 'week';

type Period = {
  type: TimeRange;
  value: number;
};

type BalanceAndPerformance = {
  time: number;
  balance: number;
  performance: number;
};

type ChartType = 'performance_percentage' | 'balance';

type Props = {
  customerId: string;
  tab?: 'balance' | 'performance';
};

export const PerformanceCharts = ({customerId, tab}: Props) => {
  const {t} = useTranslate();
  const theme = useTheme();
  const media = useMedia();

  const [chartType, setChartType] = useState<ChartType>('performance_percentage');
  const [period, setPeriod] = useState<Period>({type: 'all', value: 0});
  const [balanceAndPerformance, setBalanceAndPerformance] = useState<BalanceAndPerformance[]>();
  const [lastDate, setLastDate] = useState<number>();

  const defaultPoint = useSharedValue<BalanceAndPerformance | undefined>(undefined);
  const selectedPoint = useSharedValue<BalanceAndPerformance | undefined>(undefined);

  const language = useAppSelector(state => state.app.language);

  const {data: summary, isLoading: isSummaryLoading} = useGetPerformanceSummaryWithHistoryQuery({
    customerId,
    history: true,
    historyDays: period.type === 'all' ? undefined : period.value,
  });
  const {data: ratesOfReturn} = useGetRatesOfReturnQuery(customerId);

  useEffect(() => {
    if (!tab) return;

    switch (tab) {
      case 'performance': {
        setChartType('performance_percentage');

        return;
      }
      case 'balance': {
        setChartType('balance');

        return;
      }
      default: {
        setChartType('performance_percentage');
      }
    }
  }, [tab]);

  useEffect(() => {
    if (!summary || !summary.history || !ratesOfReturn) return;

    const rates =
      period.type === 'all' ? ratesOfReturn : ratesOfReturn.slice(-summary.history.length);

    const data: BalanceAndPerformance[] = [];

    for (let i = 0; i < summary.history.length - 1; i++) {
      const summaryData = summary.history.at(i);
      const rateData = rates.at(i);

      if (!summaryData) continue;

      data.push({
        time: Date.parse(summaryData.date),
        balance: summaryData?.balance || 0,
        performance: rateData?.value || 0,
      });
    }

    setBalanceAndPerformance(data);

    const lastItem = data.at(-1);

    if (lastItem) {
      setLastDate(lastItem.time);

      defaultPoint.value = lastItem;
    }
  }, [summary, ratesOfReturn, period, defaultPoint]);

  const performanceData = useMemo(() => {
    return (
      balanceAndPerformance?.map(data => {
        const extra = {
          balance: data.balance,
          performance: data.performance,
        };

        return {
          x: data.time,
          y: data.performance,
          extra,
        };
      }) || []
    );
  }, [balanceAndPerformance]);

  const balanceData = useMemo(() => {
    return (
      balanceAndPerformance?.map(data => {
        const extra = {
          balance: data.balance,
          performance: data.performance,
        };

        return {
          x: data.time,
          y: data.balance,
          extra,
        };
      }) || []
    );
  }, [balanceAndPerformance]);

  const handleTabPressed = useCallback(
    (chartType: ChartType) => () => {
      setChartType(chartType);
    },
    []
  );

  const chartsTabs = useMemo<Tab[]>(
    () => [
      {
        label: t('PERFORMANCE_CHART.BOTTOM_SHEET.TIME_WEIGHTED_RETURN'),
        onPress: handleTabPressed('performance_percentage'),
      },
      {
        label: t('OVERVIEW.CURRENT-BALANCE'),
        onPress: handleTabPressed('balance'),
      },
    ],
    [t, handleTabPressed]
  );

  const handlePeriodPressed = useCallback(
    (timeRange: TimeRange) => () => {
      if (timeRange === 'all') {
        setPeriod({type: timeRange, value: 0});

        return;
      }

      if (timeRange === 'curr_year') {
        const last = lastDate ?? new Date().getTime();
        const first = new Date(new Date(last).getFullYear(), 0, 1);

        const diffDays = Math.floor(Math.abs(last - first.getTime()) / (1000 * 60 * 60 * 24));

        setPeriod({type: timeRange, value: diffDays});

        return;
      }

      if (timeRange === 'year') {
        const last = lastDate ?? new Date().getTime();
        const first = new Date(last);

        first.setFullYear(first.getFullYear() - 1);

        const diffDays = Math.floor(Math.abs(last - first.getTime()) / (1000 * 60 * 60 * 24));

        setPeriod({type: timeRange, value: diffDays});

        return;
      }

      const diff = timeRange === 'month' ? 30 : 7;

      setPeriod({type: timeRange, value: diff});
    },
    [lastDate]
  );

  const periodsTabs = useMemo<Tab[]>(
    () => [
      {label: t('OVERVIEW.CHART-PERIODS.1W'), onPress: handlePeriodPressed('week')},
      {label: t('OVERVIEW.CHART-PERIODS.1M'), onPress: handlePeriodPressed('month')},
      {label: t('OVERVIEW.CHART-PERIODS.1Y'), onPress: handlePeriodPressed('year')},
      {label: t('OVERVIEW.CHART-PERIODS.YTD'), onPress: handlePeriodPressed('curr_year')},
      {label: t('OVERVIEW.CHART-PERIODS.ALL'), onPress: handlePeriodPressed('all')},
    ],
    [t, handlePeriodPressed]
  );

  const handlePointSelected = useCallback((selectedPoints: SelectedPoint[]) => {
    'worklet';

    const point = selectedPoints.at(0);

    if (!point) {
      selectedPoint.value = undefined;

      return;
    }

    selectedPoint.value = {
      time: point.x,
      balance: point.extra.balance,
      performance: point.extra.performance,
    };
  }, []);

  const yAxisLabel = useCallback<(percentage?: boolean) => GetTextLabelFunc>(
    percentage => value => {
      const options: Intl.NumberFormatOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: percentage ? 'percent' : 'currency',
      };

      if (!percentage) {
        options.currency = 'EUR';
      }

      return Intl.NumberFormat(language, options).format(value);
    },
    [language]
  );

  const xAxisLabel = useCallback<GetTextLabelFunc>(
    (value, index) => {
      let show = true;

      if (period.type === 'all') {
        show = index % 2 === 0;
      }

      const options: Intl.DateTimeFormatOptions = {
        month: 'short',
      };

      if (period.type === 'week' || period.type === 'month') {
        options.day = 'numeric';
      } else {
        options.year = 'numeric';
      }

      return show ? Intl.DateTimeFormat(language, options).format(value) : undefined;
    },
    [language, period]
  );

  const legendValue = useDerivedValue(() => {
    return selectedPoint.value ? selectedPoint.value : defaultPoint.value;
  });

  return (
    <YStack gap="$3">
      {!media.sm && (
        <PerformanceDetailsModal customerId={customerId}>
          <XStack gap="$1">
            <SvgFile color="$primary" name="info" size={16} />
            <Label3 color="$primary">{t('OVERVIEW.PERFORMANCE.DETAILS')}</Label3>
          </XStack>
        </PerformanceDetailsModal>
      )}
      <Card gap="$2" $sm={{paddingHorizontal: '$2', elevation: 0, shadowOpacity: 0}}>
        {media.sm ? (
          <Tabbar elements={chartsTabs} defaultSelectedIndex={tab === 'balance' ? 1 : 0} />
        ) : (
          <XStack justifyContent="space-between" gap="$4" flexWrap="wrap">
            <Tabbar
              elements={chartsTabs}
              defaultSelectedIndex={tab === 'balance' ? 1 : 0}
              wrapperProps={{flex: 1}}
            />
            <Tabbar
              elements={periodsTabs}
              defaultSelectedIndex={periodsTabs.length - 1}
              variant="onBackground"
              fitContent
            />
          </XStack>
        )}
        {isSummaryLoading ? (
          <Skeleton width="100%" height={240} />
        ) : (
          <>
            <PerformanceChartLegend legendValue={legendValue} />
            <View width="100%" height={240}>
              {chartType === 'performance_percentage' && (
                <Chart
                  onSelect={handlePointSelected}
                  removeSelectActionOnEnd
                  overlapYAxes
                  webGlFallback={<WebGlFallback />}
                  theme={theme}
                >
                  <Line
                    points={performanceData}
                    color={theme.primary.val}
                    activePoint={{
                      show: true,
                      size: 8,
                      borderColor: `${theme.primary.val}33`,
                    }}
                  />

                  <XAxis
                    position="bottom"
                    countTicks={3}
                    color={theme.neutral500.val}
                    getTexLabel={xAxisLabel}
                    fontSize={12}
                  />
                  <YAxis
                    position="right"
                    countTicks={3}
                    color={theme.neutral500.val}
                    fontSize={12}
                    getTexLabel={yAxisLabel(true)}
                  />
                </Chart>
              )}
              {chartType === 'balance' && (
                <Chart
                  onSelect={handlePointSelected}
                  removeSelectActionOnEnd
                  overlapYAxes
                  webGlFallback={<WebGlFallback />}
                  theme={theme}
                >
                  <Line
                    points={balanceData}
                    color={theme.yellow.val}
                    activePoint={{
                      show: true,
                      size: 8,
                      borderColor: `${theme.yellow.val}33`,
                    }}
                  />
                  <XAxis
                    position="bottom"
                    countTicks={3}
                    color={theme.neutral500.val}
                    getTexLabel={xAxisLabel}
                    fontSize={12}
                  />
                  <YAxis
                    position="right"
                    countTicks={3}
                    color={theme.neutral500.val}
                    fontSize={12}
                    getTexLabel={yAxisLabel(false)}
                  />
                </Chart>
              )}
            </View>
          </>
        )}
        {media.sm && (
          <Tabbar elements={periodsTabs} defaultSelectedIndex={periodsTabs.length - 1} />
        )}
      </Card>
    </YStack>
  );
};
