import SvgFile from '@/components/icons/SvgFile';
import {Label2} from '@/components/texts/Label';
import {SideNavigationItem} from '@/modules/navigation/components/SideNavigationItem';
import {useSession} from '@/providers/session-provider/SessionProvider';
import {useTranslate} from '@tolgee/react';
import {isTouchable, useMedia, XStack} from 'tamagui';

export function LogoutButton({variant}: {variant: 'small' | 'large'}) {
  const {signOut} = useSession();
  const media = useMedia();
  const {t} = useTranslate();

  if (media.sm || isTouchable)
    return (
      <XStack onPress={signOut} alignItems="center" gap="$2" pressStyle={{opacity: 0.8}}>
        <SvgFile name="logout" size={21} color="$red" />
        {variant === 'large' && <Label2 variant="medium">{t('NAVIGATION.LOGOUT')}</Label2>}
      </XStack>
    );
  return <SideNavigationItem onPress={signOut} title={t('NAVIGATION.LOGOUT')} icon="logout" />;
}
