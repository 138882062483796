import {ActionButton} from '@/components/buttons/ActionButton';
import {Card} from '@/components/cards/Card';
import {OtherOrdersSelector} from '@/modules/profile/components/OtherOrdersSelector';
import {useTranslate} from '@tolgee/react';
import {Link, useLocalSearchParams} from 'expo-router';
import React from 'react';
import {XStack, useMedia} from 'tamagui';

export default function ActionBar() {
  const {customerId} = useLocalSearchParams<{customerId: string}>();
  const {t} = useTranslate();
  const media = useMedia();

  const Component = media.sm ? Card : XStack;

  return (
    <Component
      flexGrow={1}
      maxHeight="50%"
      flexDirection="row"
      gap="$3"
      $sm={{
        maxHeight: '100%',
        gap: 'unset',
        backgroundColor: '$surface',
        paddingHorizontal: '$2',
        paddingVertical: '$3',
        borderRadius: '$1',
        justifyContent: 'space-around',
      }}
    >
      <Link asChild href={{pathname: '/overview/[customerId]/order/deposit', params: {customerId}}}>
        <ActionButton iconName="deposit" label={t('OVERVIEW.ACTIONS.DEPOSIT')} primary />
      </Link>
      <Link
        asChild
        href={{pathname: '/overview/[customerId]/order/withdrawal', params: {customerId}}}
      >
        <ActionButton iconName="withdrawal" label={t('OVERVIEW.ACTIONS.WITHDRAWAL')} />
      </Link>
      <OtherOrdersSelector customerId={customerId} />
      <Link asChild href={{pathname: '/overview/[customerId]/information', params: {customerId}}}>
        <ActionButton iconName="info" label={t('OVERVIEW.ACTIONS.INFO')} />
      </Link>
    </Component>
  );
}
