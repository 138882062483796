export const MONTHS = [
  {key: '1', translationKey: 'COMMON.MONTHS.JANUARY'},
  {key: '2', translationKey: 'COMMON.MONTHS.FEBRUARY'},
  {key: '3', translationKey: 'COMMON.MONTHS.MARCH'},
  {key: '4', translationKey: 'COMMON.MONTHS.APRIL'},
  {key: '5', translationKey: 'COMMON.MONTHS.MAY'},
  {key: '6', translationKey: 'COMMON.MONTHS.JUNE'},
  {key: '7', translationKey: 'COMMON.MONTHS.JULY'},
  {key: '8', translationKey: 'COMMON.MONTHS.AUGUST'},
  {key: '9', translationKey: 'COMMON.MONTHS.SEPTEMBER'},
  {key: '10', translationKey: 'COMMON.MONTHS.OCTOBER'},
  {key: '11', translationKey: 'COMMON.MONTHS.NOVEMBER'},
  {key: '12', translationKey: 'COMMON.MONTHS.DECEMBER'},
] as const;
