import {LayoutChangeEvent} from 'react-native';
import {View} from 'tamagui';

import {Label3} from '@/components/texts/Label';

type Props = {
  label: string;
  onLayout?: (event: LayoutChangeEvent) => void;
  onPress?: () => void;
  fitContent?: boolean;
  selected?: boolean;
};

export const TabbarItem = ({label, onLayout, onPress, fitContent, selected}: Props) => {
  return (
    <View
      onLayout={event => {
        if (!onLayout) return;

        onLayout(event);
      }}
      paddingVertical="$1"
      paddingHorizontal="$4"
      alignItems="center"
      hoverStyle={{
        opacity: 0.8,
      }}
      pressStyle={{
        opacity: 0.8,
        scale: 0.95,
      }}
      onPress={() => {
        if (onPress) {
          onPress();
        }
      }}
      cursor="pointer"
      animation="slow"
      flex={fitContent ? 'unset' : 1}
    >
      <Label3
        userSelect="none"
        variant={selected ? 'medium' : undefined}
        color={selected ? '$primary' : '$neutral400'}
        textAlign="center"
      >
        {label}
      </Label3>
    </View>
  );
};
