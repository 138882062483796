import {ActionButton} from '@/components/buttons/ActionButton';
import {SvgName} from '@/components/icons/SvgFile';
import {SelectInput} from '@/components/inputs/select-input/SelectInput';
import {SelectInputItem} from '@/types/FormInputProps';
import {ModalRef} from '@/types/modal';
import {useTranslate} from '@tolgee/react';
import {Href, useRouter} from 'expo-router';
import {useMemo, useRef} from 'react';
import {View} from 'tamagui';

export const OtherOrdersSelector = ({customerId}: {customerId: string}) => {
  const {t} = useTranslate();
  const router = useRouter();
  const modalRef = useRef<ModalRef>(null);

  const moreOrders: Record<string, {iconName: SvgName; href: Href}> = useMemo(() => {
    return {
      changeStrategy: {
        iconName: 'conversion-exchange-checkmark',
        href: {pathname: '/overview/[customerId]/order/change-strategy', params: {customerId}},
      },
      changeReferenceAccount: {
        iconName: 'bank',
        href: {
          pathname: '/overview/[customerId]/order/change-reference-account',
          params: {customerId},
        },
      },
      internalAssetsTransfer: {
        iconName: 'internal-transfer',
        href: {
          pathname: '/overview/[customerId]/order/internal-assets-transfer',
          params: {customerId},
        },
      },
    };
  }, [customerId]);

  const items = useMemo(
    () =>
      [
        {
          key: 'changeStrategy',
          name: t('MENU.ORDER.OTHER.CHANGE_STRATEGY'),
          icon: 'conversion-exchange-checkmark',
        },
        {
          key: 'changeReferenceAccount',
          name: t('MENU.ORDER.OTHER.REFERENCE_ACCOUNT_CHANGE'),
          icon: 'bank',
        },
        {
          key: 'internalAssetsTransfer',
          name: t('MENU.ORDER.OTHER.INTERNAL_ASSETS_TRANSFER'),
          icon: 'internal-transfer',
        },
      ] satisfies SelectInputItem[],
    [t]
  );

  return (
    <SelectInput
      ref={modalRef}
      items={items}
      onSelect={(key: string) => {
        router.navigate(moreOrders[key].href);
      }}
      cardProps={{
        borderRadius: '$1',
      }}
      wrapperProps={{
        unstyled: true,
        flex: 1,
      }}
      containerProps={{
        flex: 1,
      }}
      minWidth={350}
    >
      <View height="100%" width="100%">
        <ActionButton
          iconName="orders"
          label={t('OVERVIEW.ACTIONS.MORE')}
          onPress={() => {
            modalRef.current?.open(true);
          }}
        />
      </View>
    </SelectInput>
  );
};
