import SvgFile from '@/components/icons/SvgFile';
import {Paragraph2} from '@/components/texts/Paragraph';
import {useAppSelector} from '@/store';
import {useTranslate} from '@tolgee/react';
import React from 'react';
import {Linking} from 'react-native';
import {View, XStack, YStack} from 'tamagui';

export function WithdrawalWarningBoxFirst() {
  const {t} = useTranslate();
  const locale = useAppSelector(state => state.app.language);

  return (
    <YStack borderRadius="$1" padding="$5" gap="$4" backgroundColor="$redTransparent">
      <Paragraph2 variant="medium" color="$red">
        {t('ORDER_SUMMARY.FULL_WITHDRAWAL.NOTE.TITLE')}
      </Paragraph2>

      <XStack alignItems="flex-start" gap="$2">
        <View
          padding="$1"
          backgroundColor="$neutralBG"
          borderRadius="$full"
          alignItems="center"
          justifyContent="center"
        >
          <SvgFile name="clock" size={16} color="$red" />
        </View>
        <YStack gap="$2" flexShrink={1}>
          <Paragraph2 variant="medium">
            {t('ORDER_SUMMARY.FULL_WITHDRAWAL.NOTE.POINT_1_TITLE')}
          </Paragraph2>
          <Paragraph2 width="100%">{t('ORDER_SUMMARY.FULL_WITHDRAWAL.NOTE.POINT_1')}</Paragraph2>
        </YStack>
      </XStack>

      <XStack alignItems="flex-start" gap="$2">
        <View
          padding="$1"
          backgroundColor="$neutralBG"
          borderRadius="$full"
          alignItems="center"
          justifyContent="center"
        >
          <SvgFile name="trending-down" size={16} color="$red" />
        </View>
        <YStack gap="$2" flexShrink={1}>
          <Paragraph2 variant="medium">
            {t('ORDER_SUMMARY.FULL_WITHDRAWAL.NOTE.POINT_2_TITLE')}
          </Paragraph2>
          <Paragraph2 width="100%">{t('ORDER_SUMMARY.FULL_WITHDRAWAL.NOTE.POINT_2')}</Paragraph2>
        </YStack>
      </XStack>

      <XStack alignItems="flex-start" gap="$2">
        <View
          padding="$1"
          backgroundColor="$neutralBG"
          borderRadius="$full"
          alignItems="center"
          justifyContent="center"
        >
          <SvgFile name="deposit-wire" size={16} color="$red" />
        </View>
        <YStack gap="$2" flexShrink={1}>
          <Paragraph2 variant="medium">
            {t('ORDER_SUMMARY.FULL_WITHDRAWAL.NOTE.POINT_3_TITLE')}
          </Paragraph2>
          <Paragraph2 width="100%">{t('ORDER_SUMMARY.FULL_WITHDRAWAL.NOTE.POINT_3')}</Paragraph2>
        </YStack>
      </XStack>

      <Paragraph2>{t('ORDER_SUMMARY.FULL_WITHDRAWAL.NOTE.POINT_4')}</Paragraph2>
      <XStack
        alignItems="center"
        alignSelf="center"
        gap="$2"
        cursor="pointer"
        onPress={() =>
          Linking.openURL(
            locale === 'de'
              ? process.env.EXPO_PUBLIC_MAILTO_CALENDLY_DE
              : process.env.EXPO_PUBLIC_MAILTO_CALENDLY_EN
          )
        }
      >
        <SvgFile name="call" size={20} color="$primary" />
        <Paragraph2 color="$primary">
          {t('ORDER_SUMMARY.FULL_WITHDRAWAL.NOTE.BOOK_A_APPOINTMENT')}
        </Paragraph2>
      </XStack>
    </YStack>
  );
}

export function WithdrawalWarningBoxSecond() {
  const {t} = useTranslate();
  return (
    <XStack
      borderRadius="$1"
      padding="$5"
      gap="$2"
      backgroundColor="$neutralBG"
      alignItems="flex-start"
    >
      <SvgFile name="info" size={20} color="$primary" marginTop="$1" />
      <YStack gap="$0.5" flexShrink={1}>
        <Paragraph2 variant="medium" color="$primary">
          {t('ORDER_SUMMARY.FULL_WITHDRAWAL.NOTE.FULL_TITLE')}
        </Paragraph2>
        <Paragraph2>{t('ORDER_SUMMARY.FULL_WITHDRAWAL.NOTE.FULL')}</Paragraph2>
      </YStack>
    </XStack>
  );
}

export function WithdrawalWarningBoxThird() {
  const {t} = useTranslate();
  return (
    <XStack borderRadius="$1" gap="$2" alignItems="center">
      <SvgFile name="info" size={20} color="$red" />
      <Paragraph2 color="$red">{t('ORDER_SUMMARY.WITHDRAWAL.MAX_PARTIAL_WITHDRAWAL_1')}</Paragraph2>
    </XStack>
  );
}
