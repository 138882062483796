import {useTranslate} from '@tolgee/react';
import {Stack} from 'expo-router';
import {Platform} from 'react-native';
import {YStack} from 'tamagui';

import {ResponsiveScrollView} from '@/components/views/ResponsiveScrollView';
import {WebFooter} from '@/modules/navigation/components/footer/WebFooter';
import {Header} from '@/modules/navigation/components/header/Header';
import {EmailDataCard} from '@/modules/profile/components/EmailDataCard';
import {MFAStatusOverview} from '@/modules/profile/components/MFAStatusOverview';
import {PasswordDataCard} from '@/modules/profile/components/PasswordDataCard';

export default function TabSecurity() {
  const {t} = useTranslate();
  return (
    <ResponsiveScrollView>
      <YStack gap="$5">
        <Stack.Screen
          options={{
            headerShown: true,
            header: () => (
              <Header title={t('PROFILE.SECURITY')} backButton={Platform.OS !== 'web'} />
            ),
          }}
        />
        <EmailDataCard />
        <PasswordDataCard />
        <MFAStatusOverview />
      </YStack>
      <WebFooter withCalendly />
    </ResponsiveScrollView>
  );
}
