import {captureException} from '@sentry/react-native';
import {useTranslate} from '@tolgee/react';
import {toast} from 'burnt';
import {useFocusEffect} from 'expo-router';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  CodeField,
  useBlurOnFulfill,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field';
import {Spinner, View, XStack, YStack} from 'tamagui';

import {Button} from '@/components/buttons/Button';
import SvgFile from '@/components/icons/SvgFile';
import {useTextInput} from '@/components/modal-bottom-sheet/hooks/useTextInput';
import {InputErrorMessage} from '@/components/texts/InputErrorMessage';
import {Label2} from '@/components/texts/Label';
import {Paragraph2} from '@/components/texts/Paragraph';
import {useGetViolationError} from '@/hooks/useGetViolationError';
import {CodeFieldCell} from '@/modules/mfa/components/CodeFieldCell';
import {Timer} from '@/modules/mfa/components/Timer';
import {refreshTokens} from '@/providers/session-provider/utils';
import {
  useCreateSessionMutation,
  useDeleteMethodMutation,
  useSendCodeMutation,
  useVerifyCodeMutation,
} from '@/store/queries/authApi';
import {MfaMethodSmsResponse, MfaSessionRequest} from '@/types/api/auth';
import {MFAActionType} from '@/types/mfaAction';

const CELL_COUNT = 6;
const TIMER_SECONDS = 60;

type MFAActionCodeEnterProps = {
  method: MfaMethodSmsResponse;
  action: MFAActionType;
  onSuccess: (action: MFAActionType) => void;
  modal?: boolean;
};

export function MFAActionCodeEnter({method, action, onSuccess, modal}: MFAActionCodeEnterProps) {
  const {t} = useTranslate();

  const [code, setCode] = useState('');
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  // const [handledSms, setHandledSms] = useState(false);
  const [autoSending, setAutoSending] = useState(true);
  const [timer, setTimer] = useState<number | undefined>(TIMER_SECONDS);
  // const [smsTimeoutError, setSmsTimeoutError] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);
  const timerRef = useRef<NodeJS.Timeout>();

  const [verifyCodeTrigger] = useVerifyCodeMutation();
  const [createSessionTrigger] = useCreateSessionMutation();
  const [sendCodeTrigger] = useSendCodeMutation();
  const [deleteMethodTrigger] = useDeleteMethodMutation();

  const ref = useBlurOnFulfill({value: code, cellCount: CELL_COUNT});
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value: code,
    setValue: setCode,
  });

  const {getViolationError, isFetching} = useGetViolationError();

  const {onFocus, onBlur} = useTextInput({modal});

  const clear = () => {
    // TODO: to be done in https://ginmon.atlassian.net/browse/FRONTEND-126
    // removeListener();
    setTimer(undefined);
    setShowResendButton(false);
    clearTimeout(timerRef.current);
    setError(undefined);
  };
  // TODO: to be done in https://ginmon.atlassian.net/browse/FRONTEND-126
  // const handleOtpSms = (message: string) => {
  //   if (!message || message === 'Timeout Error') {
  //     setSmsTimeoutError(true);
  //     return;
  //   }

  //   setHandledSms(true);

  //   const regexp = new RegExp(`(\\d{${CELL_COUNT}})`, 'g');
  //   const otpDigits = regexp.exec(message);

  //   if (otpDigits && otpDigits[0]) {
  //     setCode(otpDigits[0]);
  //   }
  // };

  // useEffect(() => {
  //   if (!smsTimeoutError && !handledSms) return;
  // TODO: to be done in https://ginmon.atlassian.net/browse/FRONTEND-126
  //   startOtpListener(handleOtpSms).catch(handleError('Failed while starting listen sms'));
  // }, [smsTimeoutError, handledSms]);

  useFocusEffect(
    useCallback(() => {
      setTimer(TIMER_SECONDS);
      // TODO: to be done in https://ginmon.atlassian.net/browse/FRONTEND-126
      // startOtpListener(handleOtpSms).catch(handleError('Failed while starting listen sms'));

      // setTimeout(() => {
      //   if (ref.current) {
      //     ref.current.focus();
      //   }
      // }, 0);

      return () => clear();
    }, [])
  );

  const onSuccessResendCode = () => {
    setTimer(TIMER_SECONDS);

    toast({
      preset: 'done',
      title: t('MFA_CODE_ENTER.RESEND_CODE_TOAST_TEXT'),
    });
  };

  const handleResendButton = async () => {
    try {
      await sendCodeTrigger({method: method.id}).unwrap();

      onSuccessResendCode();
    } catch (error) {
      captureException(error);
    }
  };

  const handleChangeCode = (code: string) => {
    if (error) {
      setError(undefined);
    }

    setCode(code);
  };

  const handleEndTimer = () => {
    setShowResendButton(true);
    setTimer(0);
  };

  const disabledResendButton = timer === undefined || timer > 0;

  const handleNextPressed = useCallback(async () => {
    try {
      setLoading(true);

      const payload: MfaSessionRequest = {
        tan: code,
      };

      if (action.name === 'initial' || action.name === 'finish') {
        await verifyCodeTrigger(payload).unwrap();
      } else {
        await createSessionTrigger(payload).unwrap();
      }

      await refreshTokens();

      switch (action.name) {
        case 'initial':
        case 'finish': {
          onSuccess(action);
          break;
        }
        case 'additional': {
          onSuccess(action);
          break;
        }
        case 'deletion': {
          await deleteMethodTrigger({methodId: action.id}).unwrap();
          onSuccess(action);
          break;
        }
        case 'one-time-code': {
          onSuccess(action);
          break;
        }
      }
    } catch (error) {
      const errorText = getViolationError(error);

      if (errorText) {
        setError(errorText);
      } else {
        captureException(error);
        toast({
          preset: 'error',
          title: t('MFA.SOMETHING_WENT_WRONG'),
        });
      }
    } finally {
      setLoading(false);
    }
  }, [
    action,
    code,
    createSessionTrigger,
    deleteMethodTrigger,
    getViolationError,
    onSuccess,
    t,
    verifyCodeTrigger,
  ]);

  useEffect(() => {
    setError(undefined);

    if (!autoSending || !code || code.length < 6) return;

    setAutoSending(false);

    handleNextPressed().catch(captureException);
  }, [code, action, autoSending, handleNextPressed]);

  const sendButton = useMemo(() => {
    return (
      <Button onPress={handleNextPressed} isLoading={loading} disabled={code.length !== 6}>
        {t('MFA_CODE_ENTER.CONFIRM_SECOND_FACTOR')}
      </Button>
    );
  }, [handleNextPressed, loading, code.length, t]);

  return (
    <YStack gap="$4">
      <Paragraph2>{t('MFA_CODE_ENTER.DESCRIPTION')}</Paragraph2>
      <XStack gap="$2">
        <SvgFile name="mobile-message" color="$text" />
        <Label2 variant="medium">{`(•••) ••• ${method.phoneNumber?.slice(-3)}`}</Label2>
      </XStack>
      {loading || isFetching ? (
        <Spinner />
      ) : (
        <YStack gap="$2">
          <CodeField
            ref={ref}
            {...props}
            autoFocus
            value={code}
            onFocus={onFocus}
            onBlur={onBlur}
            onChangeText={handleChangeCode}
            cellCount={CELL_COUNT}
            textContentType="oneTimeCode"
            keyboardType="number-pad"
            textInputStyle={
              {
                outline: 'none',
              } as any // TODO: check on native
            }
            rootStyle={{
              gap: 8,
            }}
            renderCell={({index, symbol, isFocused}) => (
              <XStack key={index} onLayout={getCellOnLayoutHandler(index)}>
                <CodeFieldCell value={symbol} isFocused={isFocused} error={!!error} />
              </XStack>
            )}
          />
          <InputErrorMessage error={error} />
        </YStack>
      )}
      <XStack alignItems="center" justifyContent="space-between">
        <View flexShrink={1}>
          <Timer
            ref={timerRef}
            onEndTimer={handleEndTimer}
            initialTime={timer}
            timerText={time =>
              time === undefined || time === 0
                ? t('MFA_CODE_ENTER.REMAINING_TIME_WITHOUT_TIME', {time})
                : t('MFA_CODE_ENTER.REMAINING_TIME_WITH_TIME', {time})
            }
          />
        </View>
        {showResendButton && (
          <Button disabled={disabledResendButton} onPress={handleResendButton}>
            {t('MFA_CODE_ENTER.RESEND_CODE')}
          </Button>
        )}
      </XStack>
      {!autoSending && sendButton}
    </YStack>
  );
}
