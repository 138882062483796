import {usePathname} from 'expo-router';
import {PropsWithChildren, forwardRef, useMemo} from 'react';
import {Platform} from 'react-native';
import {KeyboardAwareScrollView} from 'react-native-keyboard-controller';
import {
  ScrollView,
  ScrollViewProps,
  View,
  XStack,
  YStack,
  isTouchable,
  isWeb,
  useMedia,
} from 'tamagui';

const EXCLUDE_FROM_SROLL_VIEW = ['orders', 'documents', 'transactions'];

/**
 * This component is used for cases where we have SideNavigation which makes centering the
 * content but making scrollview use fullwidth and inner content having maxWidth very hard
 */
export const ResponsiveScrollViewContent = ({children}: PropsWithChildren) => {
  const media = useMedia();
  return (
    <XStack flex={1} flexBasis={0}>
      <View
        maxWidth={80}
        minWidth={0}
        flexShrink={1}
        flexGrow={0}
        alignSelf="flex-start"
        width={media.sm || isTouchable ? 0 : '100%'}
      />
      <YStack
        height="100%"
        maxWidth={isTouchable && !isWeb ? '100%' : 1000}
        width="100%"
        alignSelf="flex-start"
        justifyContent="space-between"
        gap="$6"
        paddingBottom="$2"
        {...(media.sm || isTouchable
          ? {}
          : {
              paddingVertical: '$5',
              paddingLeft: '$2',
              paddingRight: '$4',
              paddingBottom: '$4',
            })}
      >
        {children}
      </YStack>
    </XStack>
  );
};

export const ResponsiveScrollView = forwardRef<
  any,
  PropsWithChildren<ScrollViewProps & {useKeyboardAwareScrollView?: boolean}>
>(function ({children, useKeyboardAwareScrollView, ...props}, ref) {
  const path = usePathname();
  const media = useMedia();
  const exclude = useMemo(() => {
    // TODO: I think for flashlist we have to think about a more comprehensive approach
    // e.g. replace flashlist on web or move styles (maxWidth, etc.) to flashlist renderItem
    return (
      Platform.OS === 'web' && !media.sm && EXCLUDE_FROM_SROLL_VIEW.some(_ => path.includes(_))
    );
  }, [media.sm, path]);

  if (exclude) return <ResponsiveScrollViewContent>{children}</ResponsiveScrollViewContent>;

  if (Platform.OS !== 'web' && useKeyboardAwareScrollView) {
    return (
      <KeyboardAwareScrollView
        contentContainerStyle={{
          padding: media.sm || isTouchable ? 16 : 0,
        }}
        ref={ref}
        bottomOffset={80}
        keyboardShouldPersistTaps="handled"
      >
        <ResponsiveScrollViewContent>{children}</ResponsiveScrollViewContent>
      </KeyboardAwareScrollView>
    );
  }
  return (
    <ScrollView
      ref={ref}
      contentContainerStyle={{
        padding: media.sm || isTouchable ? '$4' : '$0',
      }}
      height="100%"
      {...props}
    >
      <ResponsiveScrollViewContent>{children}</ResponsiveScrollViewContent>
    </ScrollView>
  );
});
