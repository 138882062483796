import {Button} from '@/components/buttons/Button';
import {ProductBadge} from '@/components/buttons/ProductBadge';
import {Label3} from '@/components/texts/Label';
import {Paragraph3} from '@/components/texts/Paragraph';
import {WithLabel} from '@/components/texts/WithLabel';
import {Skeleton} from '@/components/views/Skeleton';
import {useAppSelector} from '@/store';
import {
  useGetCustomerAccountQuery,
  usePostCustomerOrderMutation,
} from '@/store/queries/customersApi';
import {useTranslate} from '@tolgee/react';
import {toast} from 'burnt';
import {useRouter} from 'expo-router';
import React, {useCallback, useMemo} from 'react';
import {YStack} from 'tamagui';

type InternalTransferOrderSummaryCardProps = {
  customerId: string;
  targetCustomerId: string;
  value: number;
  onFinished?: () => void;
};

export function InternalTransferOrderSummaryCard({
  customerId,
  value,
  onFinished,
  targetCustomerId,
}: InternalTransferOrderSummaryCardProps) {
  const {t} = useTranslate();
  const [postOrder] = usePostCustomerOrderMutation();
  const router = useRouter();
  const currentLanguage = useAppSelector(state => state.app.language);

  const submitOrder = useCallback(async () => {
    if (value) {
      const res = await postOrder({
        orderType: 'INTERNAL_TRANSFER',
        customerId,
        amount: value,
        targetCustomerId,
      });
      if (res.error) {
        toast({
          preset: 'error',
          title: t('SNACKBAR.ORDER-NOT-CREATED'),
        });
      } else {
        onFinished?.();
        router.dismissTo({pathname: '/overview/[customerId]', params: {customerId}});
        toast({
          preset: 'done',
          title: t('SNACKBAR.INTERNAL_TRANSFER-CREATED'),
        });
      }
    }
  }, [value, postOrder, customerId, targetCustomerId, t, onFinished, router]);

  const stringAmount = useMemo(() => {
    const options: Intl.NumberFormatOptions = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'EUR',
    };
    return new Intl.NumberFormat(currentLanguage, options).format(value);
  }, [currentLanguage, value]);

  const {data: sourceAccount, isFetching: sourceAccountFetching} =
    useGetCustomerAccountQuery(customerId);
  const {data: targetAccount, isFetching: targetAccountFetching} =
    useGetCustomerAccountQuery(targetCustomerId);

  if (sourceAccountFetching || targetAccountFetching) {
    return (
      <>
        <Skeleton width="75%" height={50} />
      </>
    );
  } else {
    return (
      <YStack gap="$3">
        {targetAccount?.displayName && (
          <WithLabel header={t('ORDER.INTERNAL_ASSETS_TRANSFER.TO')} horizontal>
            {targetAccount?.displayName}
          </WithLabel>
        )}
        {targetAccount?.product && (
          <WithLabel header={t('ORDER_SUMMARY.PRODUCT')} horizontal>
            <ProductBadge product={targetAccount?.product} textComponent={Label3} />
          </WithLabel>
        )}
        <YStack gap="$5" paddingVertical="$5">
          <Paragraph3 color="$neutral400">
            {t('ORDER_SUMMARY.INTERNAL_ASSETS_TRANSFER.TEXT_1', {
              sourceAccount: sourceAccount?.displayName,
            })}
          </Paragraph3>
          <Paragraph3 color="$neutral400">
            {t('ORDER_SUMMARY.INTERNAL_ASSETS_TRANSFER.TEXT_2', {
              orderAmount: stringAmount,
              targetAccount: targetAccount?.displayName,
            })}
          </Paragraph3>
        </YStack>
        <Button alignSelf="stretch" onPress={submitOrder}>
          {t('ORDER_SUMMARY.CONFIRM')}
        </Button>
      </YStack>
    );
  }
}
