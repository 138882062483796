import SvgFile from '@/components/icons/SvgFile';
import React from 'react';
import {Checkbox, GetProps, styled} from 'tamagui';

const StyledPressableCheckBox = styled(Checkbox, {
  name: 'Checkbox',
  role: 'checkbox',
  cursor: 'pointer',
  backgroundColor: '$primary',
  height: 20,
  width: 20,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  borderWidth: 1,
  borderRadius: '$0.25',
  userSelect: 'none',
  pressStyle: {
    opacity: 0.8,
    scale: 0.95,
  },
  variants: {
    checked: {
      true: {
        backgroundColor: '$primary',
        borderColor: '$primary',
        hoverStyle: {
          backgroundColor: '$primaryHover',
          borderColor: '$primaryHover',
        },
      },
      false: {
        backgroundColor: 'transparent',
        borderColor: '$neutral200',
        hoverStyle: {
          borderColor: '$neutral300',
        },
      },
    },
  } as const,
});

export const CheckBox: React.FC<GetProps<typeof StyledPressableCheckBox>> = ({
  checked,
  onCheckedChange,
  ...props
}) => {
  return (
    <StyledPressableCheckBox checked={checked} onCheckedChange={onCheckedChange} {...props}>
      <SvgFile
        animation="fast"
        name="check"
        color="$white"
        size={20}
        containerStyle={{scale: checked ? 1 : 0}}
      />
    </StyledPressableCheckBox>
  );
};
