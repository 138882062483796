import {ReactNode} from 'react';
import {GetProps, XStack, YStack, YStackProps} from 'tamagui';

import SvgFile from '@/components/icons/SvgFile';
import {Label2, ParagraphProps} from '@/components/texts/Label';

type Props = {
  icon: GetProps<typeof SvgFile>;
  iconWrapper?: YStackProps;
  title: ReactNode;
} & ParagraphProps;

export const Note = ({title, icon, iconWrapper, ...labelProps}: Props) => {
  return (
    <XStack gap="$4">
      <YStack alignItems="center" {...iconWrapper}>
        <SvgFile {...icon} />
      </YStack>
      <XStack flexShrink={1}>
        <Label2 {...labelProps}>{title}</Label2>
      </XStack>
    </XStack>
  );
};
