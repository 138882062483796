import {PartnerBank} from '@/constants/partner-bank';
import {getPartnerBank} from '@/helpers/getPartnerBank';
import {useSession} from '@/providers/session-provider/SessionProvider';
import {ShortenedCustomerInformationResponse} from '@/types/api/customers.v2';

export const useIsUpvest = (product?: ShortenedCustomerInformationResponse['product']) => {
  const {isUpvestFeatureFlagEnabled} = useSession();

  const partnerBank = getPartnerBank(isUpvestFeatureFlagEnabled, product);

  return partnerBank === PartnerBank.UPVEST;
};
