import {useTranslate} from '@tolgee/react';
import {View, YStack} from 'tamagui';

import {Card} from '@/components/cards/Card';
import {Heading5} from '@/components/texts/Heading';
import {Label3sm2} from '@/components/texts/Label';
import {Divider} from '@/components/views/Divider';
import {
  AllocationRatioCompare,
  ComparisonStrategyAllocationsRow,
} from '@/modules/orders/components/ChangeStrategy/ComparisonStrategyAllocationsRow';
import {
  ComparisonStrategyName,
  Strategy,
} from '@/modules/orders/components/ChangeStrategy/ComparisonStrategyName';
import {
  ComparisonStrategyProfile,
  EquityQuote,
} from '@/modules/orders/components/ChangeStrategy/ComparisonStrategyProfile';

type Props = {
  currentStrategy: Strategy | undefined;
  newStrategy: Strategy | undefined;
  equityQuote: EquityQuote | undefined;
  ratioCompareData: AllocationRatioCompare[];
};

export const ComparisonTab = ({
  currentStrategy,
  newStrategy,
  equityQuote,
  ratioCompareData,
}: Props) => {
  const {t} = useTranslate();

  return (
    <View flex={1}>
      <Card gap="$4">
        <Heading5 variant="medium" flexShrink={1}>
          {t('CHANGE_STRATEGY.COMPARISON.TITLE')}
        </Heading5>
        <YStack gap="$4">
          <ComparisonStrategyName
            currentStrategyLabel={currentStrategy?.label}
            newStrategyLabel={newStrategy?.label}
          />
          <Divider />
          <ComparisonStrategyProfile
            currentStrategyRiskProfileLabel={currentStrategy?.riskProfileLabel}
            newStrategyRiskProfileLabel={newStrategy?.riskProfileLabel}
            equityQuote={equityQuote}
          />
          <Divider />
          <YStack gap="$4">
            <Label3sm2>{t('CHANGE_STRATEGY.COMPARISON.PORTFOLIO_ALLOCATION')}</Label3sm2>
            <YStack gap="$4">
              {ratioCompareData.map(ratioCompareItem => (
                <ComparisonStrategyAllocationsRow
                  key={ratioCompareItem.key}
                  item={ratioCompareItem}
                />
              ))}
            </YStack>
          </YStack>
        </YStack>
      </Card>
    </View>
  );
};
