import {Circle, Group, LinearGradient, Path, rect, Skia} from '@shopify/react-native-skia';
import {useEffect, useId, useMemo} from 'react';
import {
  clamp,
  Easing,
  interpolate,
  useAnimatedReaction,
  useDerivedValue,
  useSharedValue,
  withDelay,
  withTiming,
} from 'react-native-reanimated';

import {REMOVE_SELECT_POS} from '@/constants/skia';
import {LineProps, NumberTuple} from '@/types/skia';
import {useChartContext} from './context';
import {useBuildPath, useGetChartRange, useLinesTransformProperties} from './hooks';
import {getClosetXIndexInSortedArray, getYForX} from './math';
import {getDomainFromPoints} from './utils';

export const Line = ({
  points,
  name,
  width = 2,
  opacity,
  color,
  activePoint,
  gradient,
  strategy = 'complex',
  smoothing = 0.1,
  clipCursorColor,
}: LineProps) => {
  const {size, updateLineDomain, updateOffsetData, x, updateSelectedPoint, touchEnabled, theme} =
    useChartContext();
  const {
    show: showPoint = true,
    color: pointColor,
    size: pointSize = 10,
    borderColor: pointBorderColor,
  } = activePoint ?? {};
  const {
    show: showGradient = false,
    colors: gradientColors,
    fullHeight: gradientFullHeight = true,
  } = gradient ?? {};

  const lineKey = useId();

  const range = useGetChartRange();

  const buildPath = useBuildPath();

  const mainColor = useMemo(() => color ?? theme.primary?.val, [theme, color]);

  const currIndexInterpolatedX = useSharedValue(-1);
  const appearingTransition = useSharedValue(0);

  useEffect(() => {
    if (appearingTransition.value !== 0) return;

    appearingTransition.value = withDelay(
      300,
      withTiming(1, {duration: 750, easing: Easing.inOut(Easing.exp)})
    );
  }, []);

  useEffect(() => {
    if (points.length === 0) {
      updateLineDomain(lineKey);

      return;
    }

    const lineDomain = getDomainFromPoints(points);

    updateLineDomain(lineKey, lineDomain);

    return () => {
      updateLineDomain(lineKey);
    };
  }, [lineKey, points, updateLineDomain]);

  useEffect(() => {
    updateOffsetData(lineKey, {pointSize, width});
  }, [updateOffsetData, lineKey, pointSize, width]);

  useEffect(() => {
    return () => {
      updateLineDomain(lineKey);
      updateOffsetData(lineKey);
    };
  }, []);

  const path = useDerivedValue(() => buildPath(points, strategy, smoothing));

  useEffect(() => {
    appearingTransition.value = 0;
    appearingTransition.value = withTiming(1, {duration: 750, easing: Easing.inOut(Easing.exp)});
  }, [points]);

  const rangeX = useDerivedValue((): NumberTuple => {
    const firstPoint = path.value.getPoint(0);
    const lastPoint = path.value.getLastPt();

    return [firstPoint.x, lastPoint.x];
  });

  const {translateX, translateY, rotateX, lineTranslateY, gradientTranslateY} =
    useLinesTransformProperties();

  const gradientPath = useDerivedValue(() => {
    const linePath = path.value.copy();

    if (linePath.isEmpty()) {
      return Skia.Path.Make().close();
    }

    const firstPoint = linePath.getPoint(0);
    const lastPoint = linePath.getLastPt();

    linePath
      .lineTo(lastPoint.x + width / 2, lastPoint.y)
      .lineTo(lastPoint.x + width / 2, gradientFullHeight ? gradientTranslateY : 0)
      .lineTo(firstPoint.x - width / 2, gradientFullHeight ? gradientTranslateY : 0)
      .lineTo(firstPoint.x - width / 2, firstPoint.y)
      .close();

    return linePath;
  }, [path, gradientFullHeight, gradientTranslateY]);

  const gradientGroupParentRect = useDerivedValue(() => {
    const x = rangeX.value[0] - width / 2;
    const y = gradientTranslateY;
    const fullWidth = rangeX.value[1] + width;
    const height = size.height;

    const animatedWidth = fullWidth * appearingTransition.value;

    return rect(x, y, animatedWidth, height);
  }, [appearingTransition, gradientPath, range, width]);

  const y = useDerivedValue(() => {
    if (!x || x.value === REMOVE_SELECT_POS) return undefined;

    const cmds = path.value.toCmds();

    if (cmds.length === 0) return undefined;

    if (cmds.some(cmd => !cmd)) return undefined;

    const leftBound = path.value.getPoint(0).x;
    const rightBound = path.value.getLastPt().x;

    const clampedX = clamp(x.value, leftBound, rightBound);

    return getYForX(cmds, clampedX);
  }, [x, path]);

  useAnimatedReaction(
    () => {
      if (!x || x.value === REMOVE_SELECT_POS || y.value === undefined) return undefined;

      return {x: x.value, y: y.value};
    },
    data => {
      if (!data || !showPoint || !touchEnabled) {
        updateSelectedPoint(lineKey);

        return;
      }

      const allX = points.map(point => point.x);

      const first = allX.at(0);
      const last = allX.at(-1);

      if (first === undefined || last === undefined) return;

      const interpolatedX = interpolate(data.x, rangeX.value, [first, last], 'clamp');

      const xIndex = getClosetXIndexInSortedArray(interpolatedX, allX);

      if (xIndex === undefined) return;

      if (currIndexInterpolatedX.value === xIndex) return;

      currIndexInterpolatedX.value = xIndex;

      const point = points[xIndex];

      updateSelectedPoint(lineKey, {key: lineKey, xIndex, name, ...point});
    },
    [name, x, y, points, showPoint, updateSelectedPoint, lineKey, touchEnabled]
  );

  const pointTransform = useDerivedValue(() => {
    if (!x || x.value === REMOVE_SELECT_POS || y.value === undefined) return undefined;

    const clampedX = clamp(x.value, rangeX.value[0], rangeX.value[1]);

    return [{translateX: clampedX}, {translateY: y.value}];
  }, [x, y]);

  const pointRadius = useDerivedValue(() => {
    if (!x || x.value === REMOVE_SELECT_POS) return 0;

    return pointSize / 2;
  }, [x, pointSize]);

  const pointBorderRadius = useDerivedValue(() => {
    if (!x || x.value === REMOVE_SELECT_POS) return 0;

    return pointRadius.value + 2.5;
  }, [pointRadius]);

  const clipBeforeCursor = useDerivedValue(() => {
    if (clipCursorColor) return rect(-2, -2, size.width + 2, size.height + 2);
    return rect(
      x?.value ? x.value + pointRadius.value * 0.5 : -2,
      -2,
      size.width + 2,
      size.height + 2
    );
  }, [x, clipCursorColor, size]);

  const clipAfterCursor = useDerivedValue(() => {
    if (!clipCursorColor) return rect(-2, -2, size.width + 2, size.height + 2);
    return rect(
      -2,
      -2,
      x?.value ? x.value + pointRadius.value * 0.5 : size.width + 2,
      size.height + 2
    );
  }, [x, clipCursorColor, size]);

  return (
    <Group key={lineKey} transform={[{translateX}, {translateY}, {rotateX}]}>
      <Group transform={[{translateY: lineTranslateY}]}>
        {showGradient && (
          <Group clip={gradientGroupParentRect}>
            <Path path={gradientPath}>
              <LinearGradient
                start={{x: 0, y: size.height}}
                end={{x: 0, y: gradientTranslateY}}
                colors={gradientColors ?? [`${mainColor}60`, `${mainColor}00`]}
              />
            </Path>
          </Group>
        )}
        {clipCursorColor && (
          <Group clip={clipBeforeCursor}>
            <Path
              path={path}
              style="stroke"
              strokeWidth={width}
              strokeJoin="round"
              strokeCap="round"
              color={clipCursorColor}
              start={0}
              end={appearingTransition}
              opacity={0.3}
            />
          </Group>
        )}
        <Group clip={clipAfterCursor}>
          <Path
            path={path}
            style="stroke"
            strokeWidth={width}
            strokeJoin="round"
            strokeCap="round"
            color={mainColor}
            start={0}
            end={appearingTransition}
            opacity={opacity}
          />
        </Group>
        {touchEnabled && showPoint && (
          <Group transform={pointTransform}>
            {pointBorderColor && (
              <Circle r={pointBorderRadius} cx={0} cy={0} color={pointBorderColor} />
            )}
            <Circle r={pointRadius} cx={0} cy={0} color={pointColor ?? mainColor} />
          </Group>
        )}
      </Group>
    </Group>
  );
};
