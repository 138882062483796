import {useAppSelector} from '@/store';
import {InterviewState} from '@/store/reducers/interview/state';

export default function useExistingStoreAnswers(questionCode: string) {
  const existingAnswers = useAppSelector(
    state => state.interview[questionCode as keyof InterviewState]
  );

  return existingAnswers
    ? {[questionCode]: Array.isArray(existingAnswers) ? existingAnswers : [existingAnswers]}
    : {};
}
