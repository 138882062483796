import {captureException} from '@sentry/react-native';
import {useTranslate} from '@tolgee/react';
import {Link, Redirect, Stack} from 'expo-router';
import React from 'react';
import {Linking, Platform, Switch} from 'react-native';
import {XStack, YStack, isTouchable, useMedia, useTheme} from 'tamagui';

import {LogoutButton} from '@/components/buttons/LogoutButton';
import {Card} from '@/components/cards/Card';
import {LabelCard} from '@/components/cards/LabelCard';
import SvgFile from '@/components/icons/SvgFile';
import {ThemeSelect} from '@/components/inputs/ThemeSelect';
import {Label2, Label3} from '@/components/texts/Label';
import {ResponsiveScrollView} from '@/components/views/ResponsiveScrollView';
import {useBiometricOptions} from '@/hooks/useBiometricOptions';
import {useMfaAvailability} from '@/modules/mfa/hooks/useMfaAvailability';
import {Header} from '@/modules/navigation/components/header/Header';
import {CommunicationLanguageInput} from '@/modules/profile/components/CommunicationLanguageInput';
import {LanguageSelector} from '@/modules/profile/components/LanguageSelector';
import {MarketingConsent} from '@/modules/profile/components/MarketingConsent';
import {ProfileListItem} from '@/modules/profile/components/ProfileListItem';
import {useAppDispatch, useAppSelector} from '@/store';
import {setBiometricEnabled} from '@/store/reducers/app';

export default function TabProfile() {
  const {t} = useTranslate();
  const media = useMedia();
  const theme = useTheme();
  const language = useAppSelector(state => state.app.language);
  const handledOpenAGB = () => {
    Linking.openURL(process.env.EXPO_PUBLIC_GINMON_GENERAL_TERMS_AND_CONDITIONS).catch(
      captureException
    );
  };
  const biometricEnabled = useAppSelector(state => state.app.biometricEnabled);
  const options = useBiometricOptions();
  const dispatch = useAppDispatch();
  const {isSetup} = useMfaAvailability();

  if (media.gtSm && !isTouchable) {
    // Used to redirect from sm profile tabs to web profile screens
    return <Redirect href="/profile/personal-data" />;
  }

  const handleBiometricEnabledChange = (value: boolean) => {
    dispatch(setBiometricEnabled(value));
  };

  return (
    <ResponsiveScrollView>
      <Stack.Screen
        options={{
          headerShown: true,
          header: () => <Header title={t('NAVIGATION.PROFILE')} backButton={false} />,
        }}
      />
      <LabelCard label={t('NAVIGATION.PROFILE.PERSONAL_DATA')} gap="$5">
        <Link href="/profile/taxes" asChild>
          <ProfileListItem
            label={t('NAVIGATION.PROFILE.TAXES')}
            value={t('NAVIGATION.PROFILE.TAXES.TAX')}
          />
        </Link>
        <Link href="/profile/personal-data" asChild>
          <ProfileListItem
            label={t('NAVIGATION.PROFILE.ADDRESS')}
            value={t('NAVIGATION.PROFILE.ADDRESS.CHANGE')}
          />
        </Link>
        <Link href="/profile/documents" asChild>
          <ProfileListItem
            label={t('NAVIGATION.PROFILE.DOCUMENTS')}
            value={t('NAVIGATION.PROFILE.DOCUMENTS.DOCUMENTS')}
          />
        </Link>
      </LabelCard>
      <Card gap="$2" padding="$4">
        <XStack
          gap="$2"
          justifyContent="space-between"
          alignItems="center"
          onPress={() => {
            Linking.openURL(
              language === 'en'
                ? process.env.EXPO_PUBLIC_CONTACT_SERVICE_EN
                : process.env.EXPO_PUBLIC_CONTACT_SERVICE_DE
            );
          }}
        >
          <YStack gap="$2">
            <Label2 variant="medium" color="$primary">
              {t('PROFILE.SOME_MORE_QUESTIONS')}
            </Label2>
            <Label3 color="$neutral400">{t('PROFILE.SOME_MORE_QUESTIONS_DESCRIPTION')}</Label3>
          </YStack>
          <SvgFile name="ask-more" size={59} />
        </XStack>
      </Card>
      <LabelCard label={t('NAVIGATION.PROFILE.SECURITY')} gap="$5">
        {Platform.OS !== 'web' && options !== null && (
          <ProfileListItem
            label={t('NAVIGATION.PROFILE.FACE_ID')}
            value={options?.biometricText ?? t('BIOMETRIC.GENERIC-BIOMETRIC.SHOW-POPUP-BUTTON')}
          >
            <Switch
              trackColor={{true: theme.neutral50.val, false: theme.neutral50.val}}
              thumbColor={biometricEnabled ? theme.primary.val : theme.neutral300.val}
              ios_backgroundColor={theme.neutral50.val}
              value={biometricEnabled}
              onValueChange={handleBiometricEnabledChange}
            />
          </ProfileListItem>
        )}
        <Link href="/profile/email-change" asChild>
          <ProfileListItem
            label={t('NAVIGATION.PROFILE.EMAIL')}
            value={t('NAVIGATION.PROFILE.EMAIL.CHANGE')}
          />
        </Link>
        <Link href="/profile/password-change" asChild>
          <ProfileListItem
            label={t('NAVIGATION.PROFILE.PASSWORD')}
            value={t('NAVIGATION.PROFILE.PASSWORD.CHANGE')}
          />
        </Link>
        <Link href="/profile/mfa" asChild>
          <ProfileListItem
            label={t('NAVIGATION.PROFILE.TWO_FACTOR')}
            value={isSetup ? t('MFA.ENABLED') : t('MFA.DISABLED')}
          />
        </Link>
        {Platform.OS === 'ios' && (
          <Link href="/(user)/profile/delete-account" asChild>
            <ProfileListItem
              label={t('NAVIGATION.PROFILE.ACCOUNT')}
              value={t('NAVIGATION.PROFILE.ACCOUNT.DELETE')}
            />
          </Link>
        )}
      </LabelCard>
      <LabelCard label={t('SETTINGS.DISPLAY_AND_LANGUAGE')} gap="$5">
        <ThemeSelect />
        <CommunicationLanguageInput />
        <LanguageSelector />
        <MarketingConsent />
      </LabelCard>
      <LabelCard label={t('NAVIGATION.PROFILE.ABOUT')} gap="$6">
        <ProfileListItem
          value={t('NAVIGATION.PROFILE.ABOUT.AGBS')}
          onPress={handledOpenAGB}
          cursor="pointer"
        />
      </LabelCard>
      <Card>
        <LogoutButton variant="large" />
      </Card>
    </ResponsiveScrollView>
  );
}
