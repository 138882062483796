import {JWTUserData} from '@/types/jwt';
import * as Sentry from '@sentry/react-native';
import {useNavigationContainerRef} from 'expo-router';
import {jwtDecode} from 'jwt-decode';
import {PropsWithChildren, useEffect} from 'react';

// Construct a new instrumentation instance. This is needed to communicate between the integration and React
const reactNavigationIntegration = Sentry.reactNavigationIntegration();

if (!__DEV__) {
  type BeforeSendArgs = Required<Sentry.ReactNativeOptions>['beforeSend'];
  Sentry.init({
    dsn: process.env.EXPO_PUBLIC_SENTRY_DSN,
    environment: process.env.EXPO_PUBLIC_SENTRY_ENVIRONMENT,
    integrations: [
      Sentry.reactNativeTracingIntegration(),
      Sentry.appStartIntegration(),
      reactNavigationIntegration,
    ],
    beforeSend: (event: Parameters<BeforeSendArgs>['0'], hint: Parameters<BeforeSendArgs>['1']) => {
      if (
        typeof hint.originalException === 'object' &&
        hint.originalException != null &&
        'key' in hint.originalException
      ) {
        event.extra = {
          ...event.extra,
          ...hint.originalException,
        };
      }
      return event;
    },
  });
}

const MockProvider = ({children}: PropsWithChildren) => children;

const SentryProvider = ({children}: PropsWithChildren) => {
  const ref = useNavigationContainerRef();
  useEffect(() => {
    if (ref?.current) {
      reactNavigationIntegration.registerNavigationContainer(ref);
    }
  }, [ref]);
  return children;
};

export const setSentryUser = (accessToken: string) => {
  try {
    const at = jwtDecode<JWTUserData>(accessToken);
    Sentry.setUser({id: at.user_id});
  } catch (e) {
    Sentry.captureException(e);
  }
};

export default __DEV__ ? MockProvider : Sentry.wrap(SentryProvider);
