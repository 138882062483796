import {Group, Path, Skia, rect} from '@shopify/react-native-skia';
import {useEffect, useId} from 'react';
import {
  Easing,
  ReduceMotion,
  useDerivedValue,
  useSharedValue,
  withDelay,
  withTiming,
} from 'react-native-reanimated';

import {CurveStrategy, Point} from '@/types/skia';
import {useChartContext} from './context';
import {useBuildPath, useGetChartRange, useLinesTransformProperties} from './hooks';
import {getDomainFromPoints, getMinMaxXYTuple} from './utils';

type Props = {
  color: string;
  upperBound: Point[];
  lowerBound: Point[];
  strategy?: CurveStrategy;
  smoothing?: number;
  opacity?: number;
};

export const Area = ({color, upperBound, lowerBound, strategy, smoothing, opacity}: Props) => {
  const {updateLineDomain} = useChartContext();

  const areaKey = useId();

  const appearingTransition = useSharedValue(0);

  const range = useGetChartRange();

  const buildPath = useBuildPath();

  useEffect(() => {
    if (appearingTransition.value !== 0) return;

    appearingTransition.value = withDelay(
      300,
      withTiming(1, {
        duration: 750,
        easing: Easing.out(Easing.exp),
        reduceMotion: ReduceMotion.Never,
      })
    );
  }, []);

  useEffect(() => {
    appearingTransition.value = 0;
    appearingTransition.value = withTiming(1, {
      duration: 750,
      easing: Easing.inOut(Easing.exp),
      reduceMotion: ReduceMotion.Never,
    });
  }, [upperBound, lowerBound]);

  useEffect(() => {
    return () => {
      updateLineDomain(areaKey);
    };
  }, []);

  useEffect(() => {
    if (upperBound.length === 0 || lowerBound.length === 0) {
      updateLineDomain(areaKey);

      return;
    }

    const upperBoundDomain = getDomainFromPoints(upperBound);
    const lowerBoundDomain = getDomainFromPoints(lowerBound);

    if (!upperBoundDomain || !lowerBoundDomain) {
      updateLineDomain(areaKey);

      return;
    }

    const {xyTuple: maxDomain} = getMinMaxXYTuple([upperBoundDomain, lowerBoundDomain]);

    updateLineDomain(areaKey, maxDomain);
  }, [areaKey, upperBound, lowerBound, updateLineDomain]);

  const path = useDerivedValue(() => {
    const upperBoundPath = buildPath(upperBound, strategy, smoothing);

    if (upperBoundPath.isEmpty()) {
      return Skia.Path.Make().close();
    }

    const lowerBoundPath = buildPath(lowerBound, strategy, smoothing);

    if (lowerBoundPath.isEmpty()) {
      return Skia.Path.Make().close();
    }

    const upperBoundPathFirstPoint = upperBoundPath.getPoint(0);
    const lowerBoundPathFirstPoint = lowerBoundPath.getPoint(0);

    return upperBoundPath
      .lineTo(lowerBoundPathFirstPoint.x, lowerBoundPathFirstPoint.y)
      .addPath(lowerBoundPath)
      .lineTo(upperBoundPathFirstPoint.x, upperBoundPathFirstPoint.y);
  });

  const areaGroupParentRect = useDerivedValue(() => {
    const x = 0;
    const y = 0;
    const width = range.x[1] * appearingTransition.value;
    const height = range.y[1];

    return rect(x, y, width, height);
  });

  const {translateX, translateY, rotateX, lineTranslateY} = useLinesTransformProperties();

  return (
    <Group key={areaKey} transform={[{translateX}, {translateY}, {rotateX}]}>
      <Group clip={areaGroupParentRect} transform={[{translateY: lineTranslateY}]}>
        <Path path={path} style="fill" color={color} opacity={opacity} />
      </Group>
    </Group>
  );
};
