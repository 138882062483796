import {OrderResponse} from '@/types/api/customers.v2';
/**
 * @param orderType
 * @returns 'decimals' or 'integer' depending on the order
 */
export function getPrecision(orderType: OrderResponse['orderType']) {
  return orderType === 'FULL_WITHDRAWAL' ||
    orderType === 'WITHDRAWAL' ||
    orderType === 'THEME_WITHDRAWAL'
    ? 'decimals'
    : 'integer';
}
