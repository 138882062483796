import {Skeleton} from '@/components/views/Skeleton';
import {UPVEST_BIC, UPVEST_HOLDER, UPVEST_IBAN} from '@/constants/upvest';
import {isUpvestCustomer} from '@/helpers/isUpvestCustomer';
import {useGetCustomerAccountQuery} from '@/store/queries/customersApi';
// @ts-ignore
import ErrorCorrectLevel from 'qr.js/lib/ErrorCorrectLevel';
// @ts-ignore
import QRCodeImpl from 'qr.js/lib/QRCode';
import React from 'react';
import Svg, {Path} from 'react-native-svg';
import {View, XStack} from 'tamagui';

type WireQRCodeProps = {
  customerId: string;
  amount: string;
  bgColor?: string;
  fgColor?: string;
  size?: number;
};

export function WireQRCode({
  bgColor = '#FFFFFF',
  fgColor = '#000000',
  size = 128,
  customerId,
  amount,
}: WireQRCodeProps) {
  const {data: customer} = useGetCustomerAccountQuery(customerId);
  const isUpvest = isUpvestCustomer(customer);

  if (!customer)
    return (
      <XStack flexWrap="wrap" height={size} width={size} gap="$2" justifyContent="space-between">
        <Skeleton width={size / 2.3} height={size / 2.3} />
        <Skeleton width={size / 2.3} height={size / 2.3} />
        <Skeleton width={size / 2.3} height={size / 2.3} />
        <Skeleton width={size / 2.3} height={size / 2.3} />
      </XStack>
    );

  const qrAmount = amount === '' ? '' : `EUR${amount}.00`;

  const qrdata = isUpvest
    ? `BCD
001
1
SCT
${UPVEST_BIC}
${UPVEST_HOLDER}
${UPVEST_IBAN}
${qrAmount}
SECU
${customer?.paymentReference}`
    : `BCD
001
1
SCT
${customer?.clearingAccount?.bic}
${customer?.clearingAccount?.holderFullName}
${customer?.clearingAccount?.iban}
${qrAmount}
SECU`;

  const qrcode = new QRCodeImpl(-1, ErrorCorrectLevel.L);
  qrcode.addData(qrdata);
  qrcode.make();
  const cells = qrcode.modules;
  const viewBoxSize = qrcode.getModuleCount();

  const bgD = cells
    .map((row: number[], rowIndex: number) =>
      row
        .map((cell, cellIndex) => (!cell ? `M ${cellIndex} ${rowIndex} l 1 0 0 1 -1 0 Z` : ''))
        .join(' ')
    )
    .join(' ');

  const fgD = cells
    .map((row: number[], rowIndex: number) =>
      row
        .map((cell, cellIndex) => (cell ? `M ${cellIndex} ${rowIndex} l 1 0 0 1 -1 0 Z` : ''))
        .join(' ')
    )
    .join(' ');

  return (
    <View padding="$2">
      <Svg height={size} width={size} viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}>
        <Path d={bgD} fill={bgColor} />
        <Path d={fgD} fill={fgColor} />
      </Svg>
    </View>
  );
}
