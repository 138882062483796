import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {useAppSelector} from '@/store';
import {useInterviewAnswersQuery, useInterviewQuestionsQuery} from '@/store/queries/referenceApi';
import {useEffect, useState} from 'react';

export type Question = {
  code: string;
  label: string;
  description?: string;
};

export type Answer = {
  code: string;
  label: string;
};

export const useGetQuestionWithAnswers = (code: string) => {
  const [question, setQuestion] = useState<Question>();
  const [answers, setAnswers] = useState<Answer[]>();
  const {data: interviewQuestions} = useInterviewQuestionsQuery();
  const {data: interviewAnswers} = useInterviewAnswersQuery();
  const {getByCode} = useRefdataLocalizable();
  const language = useAppSelector(state => state.app.language);

  useEffect(() => {
    const question = getByCode(interviewQuestions, code);
    if (!code || !question?.possibleAnswers) {
      setQuestion(undefined);
      setAnswers(undefined);

      return;
    }

    setQuestion({
      ...question,
      description: question.description ? question.description[language] : undefined,
    });

    const newAnswers: {code: string; label: string}[] = [];

    for (const possibleAnswer of question.possibleAnswers) {
      const answer = getByCode(interviewAnswers, possibleAnswer);

      if (!answer) continue;

      newAnswers.push(answer);
    }

    setAnswers(newAnswers);
  }, [code, language, getByCode, interviewQuestions, interviewAnswers]);

  if (!question?.code || answers?.[0]?.code === undefined)
    return {question: undefined, answers: undefined};
  return {question, answers};
};
