import {Heading5} from '@/components/texts/Heading';
import {Surface} from '@/components/views/Surface';
import {PropsWithChildren, forwardRef, useEffect, useState} from 'react';
import {GetProps, styled} from 'tamagui';

const StyledView = styled(Surface, {
  padding: '$6',
  $sm: {padding: '$4'},
  enterStyle: {
    y: 10,
    opacity: 0,
  },
  exitStyle: {
    y: 10,
    opacity: 0,
  },
  animation: 'fast',
  disabledStyle: {
    opacity: 0.5,
    scale: 1,
  },
  variants: {
    button: {
      true: {
        hoverStyle: {
          backgroundColor: '$surfaceHover',
        },
        pressStyle: {
          scale: 0.97,
        },
        userSelect: 'none',
        cursor: 'pointer',
      },
      false: {
        userSelect: 'auto',
        cursor: 'auto',
      },
    },
  } as const,
  defaultVariants: {
    button: false,
  },
});

export type CardProps = {
  onPress?: () => void;
  headerText?: string;
  testID?: string;
  disabled?: boolean;
  isSelected?: boolean;
  onPressIn?: () => void;
  onPressOut?: () => void;
};

export const Card = forwardRef<any, PropsWithChildren<GetProps<typeof StyledView> & CardProps>>(
  ({children, onPress, disabled, isSelected, headerText, onPressIn, onPressOut, ...rest}, ref) => {
    const [throttled, setThrottled] = useState(false);

    useEffect(() => {
      if (throttled) {
        const timer = setTimeout(() => {
          setThrottled(false);
        }, 250);
        return () => clearTimeout(timer);
      }
    }, [throttled]);

    const isButton = onPress && !throttled;

    return (
      <StyledView
        ref={ref}
        onPress={() => {
          if (!isButton) return;
          onPress();
          setThrottled(true);
        }}
        onPressIn={onPressIn}
        onPressOut={onPressOut}
        disabled={disabled}
        button={Boolean(onPress)}
        {...rest}
      >
        {headerText && <Heading5 variant="medium">{headerText}</Heading5>}
        {children}
      </StyledView>
    );
  }
);
