import {combineReducers} from 'redux';

import {validationApi} from '@/store/queries/validationApi';
import {baseApi} from '../queries/baseApi';
import appReducer from './app';
import authReducer from './auth';
import interviewReducer from './interview';
import onboardingReducer from './onboarding';

export const rootReducers = combineReducers({
  app: appReducer,
  auth: authReducer,
  interview: interviewReducer,
  onboarding: onboardingReducer,
  [baseApi.reducerPath]: baseApi.reducer,
  [validationApi.reducerPath]: validationApi.reducer,
});

export type RootState = ReturnType<typeof rootReducers>;
