import SvgFile from '@/components/icons/SvgFile';
import {BaseInput} from '@/components/inputs/form/BaseInput';
import {InputErrorMessage} from '@/components/texts/InputErrorMessage';
import {Label3} from '@/components/texts/Label';
import {FormInputProps} from '@/types/FormInputProps';
import {useTranslate} from '@tolgee/react';
import {FieldValues, useController} from 'react-hook-form';
import {Platform} from 'react-native';
import {Label, View, XStack, YStack} from 'tamagui';

export function FormIbanInput<T extends FieldValues>({control, name, bordered}: FormInputProps<T>) {
  const {t} = useTranslate();
  const {
    field,
    fieldState: {error, isDirty},
  } = useController({
    name,
    control,
  });

  return (
    <YStack gap="$2">
      <Label htmlFor={name}>
        <Label3 color="$neutral500">{t('ACCOUNT_SETUP.IBAN')}</Label3>
      </Label>
      <XStack width="100%">
        <View flex={1}>
          <BaseInput
            ref={field.ref}
            id={name}
            value={field.value.toUpperCase() || ''}
            onChangeText={text => field.onChange(text.replaceAll(' ', '').toUpperCase())}
            // issue - https://github.com/facebook/react-native/issues/27449
            keyboardType={Platform.OS === 'ios' ? 'default' : 'visible-password'}
            onBlur={field.onBlur}
            bordered={bordered}
            paddingRight={40}
            testID={name}
          />

          <View
            animation="slow"
            height="100%"
            position="absolute"
            right={8}
            justifyContent="center"
            alignItems="center"
            opacity={isDirty && !error ? 1 : 0}
          >
            <SvgFile name="validation-success" color="$green" size={24} />
          </View>
        </View>
      </XStack>
      <InputErrorMessage error={error?.message} />
    </YStack>
  );
}
