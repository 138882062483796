import SvgFile from '@/components/icons/SvgFile';
import {FormCustomCheckbox} from '@/components/inputs/form/FormCustomCheckbox';
import {Label2} from '@/components/texts/Label';
import {AgreementsSchemaType} from '@/hooks/useValidations';
import {useTranslate} from '@tolgee/react';
import {openBrowserAsync} from 'expo-web-browser';
import {Control} from 'react-hook-form';
import {XStack, YStack} from 'tamagui';

export function DabAgreement({control}: {control: Control<AgreementsSchemaType>}) {
  const {t} = useTranslate();

  return (
    <FormCustomCheckbox
      control={control}
      name="dabTerms"
      label={<Label2 variant="medium">{t('ACCOUNT_SETUP.AGREEMENTS.DAB.TITLE')}</Label2>}
    >
      <YStack paddingLeft={36} gap="$4">
        <XStack
          gap="$2"
          cursor="pointer"
          onPress={() => openBrowserAsync(process.env.EXPO_PUBLIC_DAB_AGREEMENT_GENERAL_TERMS)}
        >
          <SvgFile name="document" size={20} color="$neutral700" />
          <Label2 variant="medium" color="$primary">
            {t('ACCOUNT_SETUP.AGREEMENTS.DAB.TERMS')}
          </Label2>
        </XStack>
        <XStack
          gap="$2"
          cursor="pointer"
          onPress={() => openBrowserAsync(process.env.EXPO_PUBLIC_DAB_AGREEMENT_DATA_PROTECTION)}
        >
          <SvgFile name="document" size={20} color="$neutral700" />
          <Label2 variant="medium" color="$primary">
            {t('ACCOUNT_SETUP.AGREEMENTS.DAB.PRIVACY')}
          </Label2>
        </XStack>
        <XStack
          gap="$2"
          cursor="pointer"
          onPress={() =>
            openBrowserAsync(process.env.EXPO_PUBLIC_DAB_AGREEMENT_INFORMATION_FOR_DEPOSITOR)
          }
        >
          <SvgFile name="document" size={20} color="$neutral700" />
          <Label2 variant="medium" color="$primary">
            {t('ACCOUNT_SETUP.AGREEMENTS.DAB.DEPOSITOR')}
          </Label2>
        </XStack>
      </YStack>
    </FormCustomCheckbox>
  );
}
