import {useMemo} from 'react';
import {View, XStack, getTokenValue} from 'tamagui';

import {Skeleton} from '@/components/views/Skeleton';
import {RatioItem} from '@/modules/portfolio/hooks/useGetAssetAllocation';

export function BarLineSegments({items}: {items: RatioItem[]}) {
  const gap = getTokenValue('$2');

  const elements = useMemo<RatioItem[]>(() => {
    if (items.length === 0) {
      return [];
    }

    const nonZero: RatioItem[] = items.filter(ratioItem => ratioItem.ratio > 0);

    const sum = nonZero.reduce((sum, curr) => sum + curr.ratio, 0);

    const newItems = nonZero.map(ratioItem => ({...ratioItem, ratio: ratioItem.ratio / sum}));

    return newItems.sort((a, b) => b.ratio - a.ratio);
  }, [items]);

  return (
    <Skeleton width="100%" height={24}>
      {elements.length !== 0 && (
        <XStack gap={gap} paddingRight={gap * (elements.length - 1)}>
          {elements.map(({color, ratio, key}) => (
            <View
              key={`${key}-barline-segment`}
              enterStyle={{
                width: '0%',
                opacity: 0,
              }}
              animation="slow"
              height="$7"
              borderRadius="$0.5"
              backgroundColor={color}
              width={`${ratio * 100}%`}
              opacity={1}
            />
          ))}
        </XStack>
      )}
    </Skeleton>
  );
}
