import {Heading4} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {InterviewContinueButton} from '@/modules/interview/components/InterviewContinueButton';
import {SummaryCards} from '@/modules/onboarding/components/summary/SummaryCards';
import {SummaryCardsChild} from '@/modules/onboarding/components/summary/SummaryCardsChild';
import {useGetSelfUserQuery, useGetSpecificUserQuery} from '@/store/queries/usersApi';
import {OnboardingParams} from '@/types/routeParams';
import {skipToken} from '@reduxjs/toolkit/query';
import {useTranslate} from '@tolgee/react';
import {Href, useRouter} from 'expo-router';
import {useCallback, useEffect} from 'react';
import {YStack} from 'tamagui';

export function UserSummaryScreen({href, params}: {href: Href; params: OnboardingParams}) {
  const {t} = useTranslate();
  const router = useRouter();

  const {data: selfUser} = useGetSelfUserQuery();
  const {data: childUser} = useGetSpecificUserQuery(
    params.childID ? {userID: params.childID} : skipToken
  );

  const onPress = useCallback(() => {
    router.navigate(href);
  }, [href, router]);

  useEffect(() => {
    if (selfUser?.complete && !childUser?.firstName && !childUser) {
      onPress();
    }
  }, [childUser, onPress, selfUser?.complete]);

  return (
    <YStack gap="$6" marginTop="$6">
      {!selfUser?.complete && (
        <>
          <YStack gap="$2">
            <Heading4 variant="medium">{t('PERSONAL_DETAILS.USER_SUMMARY.TITLE')}</Heading4>
            <Paragraph2 color="$neutral500">
              {t('PERSONAL_DETAILS.USER_SUMMARY.SUBTITLE')}
            </Paragraph2>
          </YStack>

          <SummaryCards params={params} />
        </>
      )}

      {childUser?.firstName && (
        <Heading4 variant="medium">
          {t('PERSONAL_DETAILS.CHILD.USER_SUMMARY.TITLE', {firstName: childUser.firstName})}
        </Heading4>
      )}

      {childUser && <SummaryCardsChild childUser={childUser} />}

      <InterviewContinueButton onPress={onPress} />
    </YStack>
  );
}
