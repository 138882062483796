import {useTranslate} from '@tolgee/react';
import {toast} from 'burnt';
import {router} from 'expo-router';
import {useCallback} from 'react';
import {YStack} from 'tamagui';

import {Card} from '@/components/cards/Card';
import {ProofOfResidenceForm} from '@/components/forms/ProofOfResidenceForm';
import {Heading4, Heading6} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {ResponsiveScrollView} from '@/components/views/ResponsiveScrollView';
import {ProofOfResidenceSchemaType} from '@/hooks/useValidations';
import {useGetSelfUserQuery, useSavePoRMutation} from '@/store/queries/usersApi';
import {ProofOfResidenceDocument} from '@/types/api/users';

export default function ProofOfResidence() {
  const {t} = useTranslate();
  const {data: user} = useGetSelfUserQuery();
  const [savePoR] = useSavePoRMutation();

  const onSubmit = useCallback(
    async (data: ProofOfResidenceSchemaType) => {
      if (!user) return;
      const documents: ProofOfResidenceDocument[] = data.uploadKeys.map(({objectKey}) => ({
        uploadKey: objectKey,
        category: data.category,
        issuanceDate: data.issuanceDate,
      }));
      const result = await savePoR({
        userID: user.id,
        documents,
      });
      if (!result.error) {
        router.navigate({pathname: '/overview', params: {action: 'refreshPoR'}});
        toast({
          preset: 'done',
          title: t('PERSONAL_DETAILS.POR.SUCCESS'),
        });
      } else {
        toast({
          preset: 'error',
          title: t('PERSONAL_DETAILS.POR.ERROR'),
        });
      }
    },
    [savePoR, t, user]
  );

  return (
    <ResponsiveScrollView>
      <YStack gap="$5">
        <Heading4 variant="medium">{t('NAVIGATION.PROOF-OF-RESIDENCE')}</Heading4>
        <Card>
          <YStack gap="$4">
            <Heading6 variant="medium">{t('PROOF-OF-RESIDENCE.TITLE')}</Heading6>
            <Paragraph2 color="$neutral400">{t('PROOF-OF-RESIDENCE.DESCRIPTION_1')}</Paragraph2>
            <ProofOfResidenceForm onSubmit={onSubmit} newAddress={user?.address} />
          </YStack>
        </Card>
      </YStack>
    </ResponsiveScrollView>
  );
}
