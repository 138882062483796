import {Card} from '@/components/cards/Card';
import SvgFile from '@/components/icons/SvgFile';
import {ThemeIcon} from '@/components/icons/ThemeIcon';
import {Label3} from '@/components/texts/Label';
import {Skeleton} from '@/components/views/Skeleton';
import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {ThemeInfoModal} from '@/modules/interview/components/planner/ThemeInfoModal';
import {ThemeCode} from '@/modules/portfolio/types/ThemeCode';
import {useInvestmentThemesQuery, useSecuritiesQuery} from '@/store/queries/referenceApi';
import {useTranslate} from '@tolgee/react';
import React, {useMemo} from 'react';
import {View, XStack} from 'tamagui';

export function ThemeInfoCard({code}: {code: string}) {
  const {t} = useTranslate();
  const {data: investmentThemes} = useInvestmentThemesQuery();
  const {data: securities} = useSecuritiesQuery();
  const {getByCode} = useRefdataLocalizable();

  const theme = useMemo(
    () => getByCode(investmentThemes, code),
    [code, getByCode, investmentThemes]
  );

  const security = useMemo(
    () => theme && getByCode(securities, theme?.security),
    [getByCode, securities, theme]
  );

  if (!theme || !security)
    return (
      <View width="32%" marginVertical="$3">
        <Skeleton width="100%" height={58} />
      </View>
    );

  return (
    <ThemeInfoModal theme={theme} security={security} triggerProps={{minWidth: '32%'}}>
      <Card
        backgroundColor="$neutralBG"
        borderRadius="$1"
        flexDirection="row"
        alignItems="center"
        padding="$2"
        marginVertical="$3"
        width="100%"
        shadowOpacity={0}
        onPress={() => {}}
      >
        <XStack gap="$3" alignItems="center" flex={1}>
          <ThemeIcon
            code={code as ThemeCode}
            size={30}
            containerProps={{
              backgroundColor: '$surface',
              height: 42,
              width: 42,
              borderRadius: '$0.75',
            }}
          />
          <Label3>{theme.label}</Label3>
        </XStack>
        <SvgFile name="info" color="$primary" />
      </Card>
    </ThemeInfoModal>
  );
}
