import {Header as NativeHeader} from '@react-navigation/elements';
import {
  GetProps,
  View,
  XStack,
  XStackProps,
  isTouchable,
  styled,
  useMedia,
  useTheme,
} from 'tamagui';

import {Heading4} from '@/components/texts/Heading';
import {BackButton} from '@/modules/navigation/components/header/BackButton';

const Container = styled(XStack, {
  width: '100%',
  variants: {
    withSidePanel: {
      true: {
        $gtSm: {
          paddingTop: '$8',
        },
      },
    },
  } as const,
  defaultVariants: {
    withSidePanel: true,
  },
});

const Wrapper = styled(XStack, {
  width: '100%',
  variants: {
    withSidePanel: {
      true: {
        maxWidth: 1000,
      },
    },
  } as const,
});

type Props = {
  onBackPress?: () => void;
  title?: string;
  backButton?: boolean;
  style?: Omit<XStackProps, 'children'>;
  withSidePanel?: GetProps<typeof Container>['withSidePanel'];
};

export const Header = ({
  onBackPress,
  title = '',
  backButton = true,
  withSidePanel = true,
  style,
}: Props) => {
  const theme = useTheme();
  const media = useMedia();

  return (
    <Container withSidePanel={withSidePanel} {...style}>
      {withSidePanel && (
        <View
          maxWidth={80}
          minWidth={0}
          flexShrink={1}
          flexGrow={0}
          alignSelf="flex-start"
          width={media.sm || isTouchable ? 0 : '100%'}
        />
      )}
      <Wrapper withSidePanel={withSidePanel}>
        <NativeHeader
          title={title}
          headerShadowVisible={false}
          headerTitle={({children}) => (
            <View paddingLeft={backButton ? '$4' : 0}>
              <Heading4 variant="medium">{children}</Heading4>
            </View>
          )}
          headerTitleAlign="left"
          headerStyle={{backgroundColor: theme.neutralBG.val}}
          headerLeft={backButton ? () => <BackButton onBackPress={onBackPress} /> : undefined}
        />
      </Wrapper>
    </Container>
  );
};
