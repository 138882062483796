import {isAccountExpired} from '@/helpers/isAccountExpired';
import {useIsUserComplete} from '@/hooks/useIsUserComplete';
import {useGetCustomerAccountsQuery} from '@/store/queries/customersApi';
import {useGetValidOrdersQuery} from '@/store/queries/ordersApi';
import {skipToken as rtkSkipToken} from '@reduxjs/toolkit/query';

export function useProductChoiceGuard(skipToken?: typeof rtkSkipToken) {
  const {isUserComplete} = useIsUserComplete();
  const {data: customers, isFetching: customersFetching} = useGetCustomerAccountsQuery(
    isUserComplete && !skipToken ? undefined : rtkSkipToken
  );
  const {data: orders, isFetching: ordersFetching} = useGetValidOrdersQuery(
    isUserComplete && !skipToken ? undefined : rtkSkipToken
  );

  return {
    isFetching: customersFetching || ordersFetching,
    result:
      !customersFetching &&
      !ordersFetching &&
      (!customers || customers.totalCount === 0) &&
      !!orders?.items &&
      orders.items.filter(order => !isAccountExpired(order)).length === 0,
  };
}
