import {FormRefdataSelect} from '@/components/inputs/form/FormRefdataSelect';
import {useAcademicTitlesQuery} from '@/store/queries/referenceApi';
import {SelectFormInputProps} from '@/types/FormInputProps';
import {FieldValues} from 'react-hook-form';

export function AcademicTitleSelect<T extends FieldValues>({
  control,
  name,
  bordered,
  label,
}: SelectFormInputProps<T>) {
  const {data: academicTitles} = useAcademicTitlesQuery();

  return (
    <FormRefdataSelect
      control={control}
      name={name}
      label={label}
      type={{data: academicTitles?.concat([{code: 'NONE', label: {en: '--', de: '--'}}])}}
      bordered={bordered}
    />
  );
}
