import {THEME_ICON_SIZE} from '@/components/icons/ThemeIcon';
import {PropsWithChildren, useEffect} from 'react';
import {Gesture, GestureDetector} from 'react-native-gesture-handler';
import Animated, {
  Easing,
  ReduceMotion,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import {View, XStack} from 'tamagui';

function clamp(value: number, min: number, max: number) {
  'worklet';
  return Math.min(Math.max(value, min), max);
}

export const DraggableBar = ({
  percentage,
  children,
  handleChangedTheme,
  updateAmount,
  color,
  sum,
}: PropsWithChildren<{
  percentage: number;
  handleChangedTheme: (value: number) => void;
  updateAmount: (value: number) => void;
  color: string;
  sum: number;
}>) => {
  const offset = useSharedValue(0);
  const pressed = useSharedValue(false);
  const width = useSharedValue(273);

  useEffect(() => {
    updateAmount(percentage);
    offset.value = withTiming(percentage, {
      duration: 350,
      easing: Easing.inOut(Easing.quad),
      reduceMotion: ReduceMotion.Never,
    });
  }, [offset, percentage, updateAmount]);

  const pan = Gesture.Pan()
    .activeOffsetX([-10, 10])
    .onBegin(() => {
      pressed.value = true;
    })
    .onUpdate(event => {
      offset.value = clamp((event.x / width.value) * 100, 0, 100 - (sum - percentage));
      runOnJS(updateAmount)(offset.value);
    })
    .onFinalize(() => {
      runOnJS(handleChangedTheme)(offset.value);
      pressed.value = false;
    });

  const animatedStyles = useAnimatedStyle(() => ({
    width: `${Math.round(offset.value)}%`,
  }));

  return (
    <View cursor="col-resize" flex={1}>
      <GestureDetector gesture={pan}>
        <Animated.View
          onLayout={({nativeEvent}) => {
            width.value = nativeEvent.layout.width;
          }}
          style={[
            {
              flex: 1,
              height: '100%',
              backgroundColor: `${color}1A`,
              borderRadius: 16,
              overflow: 'hidden',
            },
          ]}
        >
          <Animated.View
            style={[
              {
                position: 'absolute',
                backgroundColor: `${color}55`,
                height: '100%',
                borderRightWidth: 1,
                borderColor: 'transparent',
              },
              animatedStyles,
            ]}
          />
          <XStack
            height={THEME_ICON_SIZE}
            justifyContent="space-between"
            alignItems="center"
            paddingHorizontal="$2"
          >
            {children}
          </XStack>
        </Animated.View>
      </GestureDetector>
    </View>
  );
};
