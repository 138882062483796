import {BiometryType} from '@/types/BiometryType';
import {useTranslate} from '@tolgee/react';
import * as LocalAuthentication from 'expo-local-authentication';
import {SecurityLevel} from 'expo-local-authentication';
import {useEffect, useState} from 'react';
import {Platform} from 'react-native';

export function useBiometricOptions() {
  const {t} = useTranslate();
  const [options, setOptions] = useState<{
    iconName: string;
    biometricText: string;
    authenticationOptions: {promptMessage: string; cancelLabel: string};
  } | null>(null);

  useEffect(() => {
    getBiometricProperties()
      .then(options => {
        switch (options) {
          case 'iris':
          case 'face-id':
            return {
              iconName: 'face-id',
              biometricText: t('BIOMETRIC.FACE-ID.SHOW-POPUP-BUTTON'),
              authenticationOptions: {
                promptMessage: t('BIOMETRIC.FACE-ID.PROMPT-TEXT'),
                cancelLabel: t('BIOMETRIC.CANCEL'),
              },
            };
          case 'face-unlock':
            return {
              iconName: 'face-id',
              biometricText: t('BIOMETRIC.FACE-UNLOCK.SHOW-POPUP-BUTTON'),
              authenticationOptions: {
                promptMessage: t('BIOMETRIC.FACE-UNLOCK.PROMPT-TEXT'),
                cancelLabel: t('BIOMETRIC.CANCEL'),
              },
            };
          case 'touch-id':
            return {
              iconName: 'touch-id',
              biometricText: t('BIOMETRIC.TOUCH-ID.SHOW-POPUP-BUTTON'),
              authenticationOptions: {
                promptMessage: t('BIOMETRIC.TOUCH-ID.PROMPT-TEXT'),
                cancelLabel: t('BIOMETRIC.CANCEL'),
              },
            };
          case 'fingerprint':
            return {
              iconName: 'touch-id',
              biometricText: t('BIOMETRIC.FINGERPRINT.SHOW-POPUP-BUTTON'),
              authenticationOptions: {
                promptMessage: t('BIOMETRIC.FINGERPRINT.PROMPT-TEXT'),
                cancelLabel: t('BIOMETRIC.CANCEL'),
              },
            };
          case 'biometric':
            return {
              iconName: 'touch-id',
              biometricText: t('BIOMETRIC.GENERIC-BIOMETRIC.SHOW-POPUP-BUTTON'),
              authenticationOptions: {
                promptMessage: t('BIOMETRIC.GENERIC-BIOMETRIC.PROMPT-TEXT'),
                cancelLabel: t('BIOMETRIC.CANCEL'),
              },
            };
          default:
            return null;
        }
      })
      .then(opts => {
        setOptions(opts);
      });
  }, [t]);

  return options;
}

export type IconNameType = 'face-id' | 'touch-id' | undefined;
export type AuthenticateResponse = {
  success: boolean;
  error?: string;
};

const getBiometricProperties = (): Promise<BiometryType> =>
  LocalAuthentication.supportedAuthenticationTypesAsync().then(authenticationTypes =>
    LocalAuthentication.getEnrolledLevelAsync().then(securityLevel => {
      if (
        securityLevel === SecurityLevel.BIOMETRIC_WEAK ||
        securityLevel === SecurityLevel.BIOMETRIC_STRONG
      ) {
        if (authenticationTypes.includes(3) && authenticationTypes.length === 1) {
          return 'iris';
        }

        if (authenticationTypes.includes(2) && authenticationTypes.length === 1) {
          if (Platform.OS === 'android') {
            return 'face-unlock';
          }
          return 'face-id';
        }

        if (authenticationTypes.includes(1) && authenticationTypes.length === 1) {
          if (Platform.OS === 'android') {
            return 'fingerprint';
          }
          return 'touch-id';
        }

        // if multiple biometrics supported, we do not know which one is enrolled
        if (authenticationTypes.length > 1) {
          return 'biometric';
        }
      }
      return 'none';
    })
  );
