import {useAppSelector} from '@/store';
import {useMemo} from 'react';

export default function useExistingFinancialSitationAnswers() {
  const answers = useAppSelector(state => state.interview);
  return useMemo(() => {
    if (!answers) return {};
    return JSON.parse(
      JSON.stringify(
        (({MONTHLY_NET_INCOME, MONTHLY_SAVING, DISPOSABLE_SAVING, OTHER_ASSETS, CREDITS}) => ({
          MONTHLY_NET_INCOME,
          MONTHLY_SAVING,
          DISPOSABLE_SAVING,
          OTHER_ASSETS,
          CREDITS,
        }))(answers)
      )
    );
  }, [answers]);
}
