import {yupResolver} from '@hookform/resolvers/yup';
import {captureException} from '@sentry/react-native';
import {useTranslate} from '@tolgee/react';
import {useRouter} from 'expo-router';
import {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {View, YStack} from 'tamagui';

import {Heading4} from '@/components/texts/Heading';
import {LoadingSpinner} from '@/components/views/LoadingSpinner';
import {useValidations} from '@/hooks/useValidations';
import {InterviewContinueButton} from '@/modules/interview/components/InterviewContinueButton';
import {
  AcademicTitles,
  UserDetailsForm,
  type UserNameValues,
} from '@/modules/onboarding/components/forms/UserDetailsForm';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {
  useCreateChildMutation,
  useGetAvailableUsersQuery,
  useGetSelfUserQuery,
  useUpdateUserMutation,
} from '@/store/queries/usersApi';
import {UserResponseV3} from '@/types/api/users';
import {OnboardingParams} from '@/types/routeParams';

type Props = {
  params: OnboardingParams;
};

export const UserNameScreen = ({params}: Props) => {
  const {t} = useTranslate();

  const [childID, setChildID] = useState<number>();

  const router = useRouter();

  const {data: availableUsers, isFetching} = useGetAvailableUsersQuery();
  const {data: selfUser} = useGetSelfUserQuery();
  const [createPlainChild] = useCreateChildMutation();
  const [updateUser] = useUpdateUserMutation();
  const {userDetailsFormSchema} = useValidations();
  const posthog = usePostHog();

  const {
    handleSubmit,
    control,
    reset,
    formState: {isSubmitting},
  } = useForm<UserNameValues>({
    resolver: yupResolver(userDetailsFormSchema),
    defaultValues: {
      main: true,
      child: params.product === 'CHILD_ACCOUNT' && !params.onboardingType,
    },
    mode: 'all',
  });

  const createChildUser = useCallback(async () => {
    try {
      const childUser = await createPlainChild().unwrap();

      setChildID(childUser.id);
    } catch (error) {
      captureException(error);
    }
  }, [createPlainChild]);

  useEffect(() => {
    if (!availableUsers || !selfUser) return;

    let child: UserResponseV3 | undefined;

    if (params.product === 'CHILD_ACCOUNT' && !params.onboardingType) {
      child = availableUsers.find(user => user.type === 'CHILD' && !user.complete);

      if (!child) {
        createChildUser().catch(captureException);

        return;
      } else {
        setChildID(child.id);
      }
    }

    const {title, academicTitle, firstName, lastName} = selfUser;
    const {title: childTitle, firstName: childFirstName, lastName: childLastName} = child ?? {};

    reset(
      {
        main: true,
        child: params.product === 'CHILD_ACCOUNT' && !params.onboardingType,
        title,
        academicTitle: (academicTitle ?? 'NONE') as AcademicTitles,
        firstName,
        lastName,
        childTitle,
        childFirstName,
        childLastName,
      },
      {keepDirty: true, keepTouched: true}
    );
  }, [availableUsers, reset, createChildUser, selfUser, params.product, params.onboardingType]);

  const handleValidSubmit = useCallback(
    async (data: UserNameValues) => {
      if (!availableUsers || !selfUser) return;

      const {
        title,
        academicTitle: newAcademicTitle,
        firstName,
        lastName,
        childTitle,
        childFirstName,
        childLastName,
      } = data;

      const selfChanges: Partial<UserResponseV3> = {
        title,
        academicTitle: newAcademicTitle === 'NONE' ? undefined : newAcademicTitle,
        firstName,
        lastName,
      };

      if (!selfUser.complete) {
        await updateUser({currentUser: selfUser, changes: selfChanges});
      }

      if (params.product === 'CHILD_ACCOUNT' && !params.onboardingType) {
        const childUser = availableUsers.find(user => user.id === childID);

        if (!childUser) return;

        const childChanges: Partial<UserResponseV3> = {
          title: childTitle,
          firstName: childFirstName,
          lastName: childLastName,
        };

        await updateUser({currentUser: childUser, changes: childChanges});
      }

      router.navigate({
        pathname: '/onboarding/personal-details/birth',
        params: {...params, screenType: selfUser.complete ? 'child' : undefined, childID},
      });
    },
    [availableUsers, childID, params, router, selfUser, updateUser]
  );

  return (
    <YStack gap="$6">
      {selfUser && !selfUser.complete && (
        <>
          <Heading4 variant="medium">{t('ONBOARDING.WHATS_YOUR_NAME')}</Heading4>
          <UserDetailsForm control={control} />
        </>
      )}
      {params.product === 'CHILD_ACCOUNT' && !params.onboardingType && (
        <YStack gap="$6">
          <Heading4 variant="medium">
            {t('PERSONAL_DETAILS.TITLE_CHILD_NAME_SECONDARY_ACC')}
          </Heading4>
          <UserDetailsForm control={control} child showAcademicTitle={false} />
        </YStack>
      )}
      <InterviewContinueButton
        isLoading={isSubmitting}
        onPress={handleSubmit(handleValidSubmit, data => {
          posthog?.capture('form_submit_failed', data);
        })}
      />
      {isFetching && (
        <View
          flex={1}
          position="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          justifyContent="center"
          alignItems="center"
        >
          <LoadingSpinner color="$primary" size={64} />
        </View>
      )}
    </YStack>
  );
};
