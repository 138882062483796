import {Heading5} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {useTranslate} from '@tolgee/react';
import React from 'react';
import {YStack} from 'tamagui';

export function GinmonSepaContent() {
  const {t} = useTranslate();
  return (
    <YStack gap="$4">
      <Heading5 variant="medium">{t('ACCOUNT_SETUP.AGREEMENTS.GINMON.SEPA')}</Heading5>
      <YStack gap="$2">
        <Paragraph2 variant="medium">
          {t('ACCOUNT_SETUP.AGREEMENTS.GINMON.SEPA.ADDRESS_NAME')}
        </Paragraph2>
        <Paragraph2 variant="medium">
          {t('ACCOUNT_SETUP.AGREEMENTS.GINMON.SEPA.ADDRESS_STREET')}
        </Paragraph2>
        <Paragraph2 variant="medium">
          {t('ACCOUNT_SETUP.AGREEMENTS.GINMON.SEPA.ADDRESS_CITY')}
        </Paragraph2>

        <Paragraph2>{t('ACCOUNT_SETUP.AGREEMENTS.GINMON.SEPA.CREDITOR')}</Paragraph2>
        <Paragraph2>{t('ACCOUNT_SETUP.AGREEMENTS.GINMON.SEPA.MANDATE')}</Paragraph2>
        <Paragraph2>{t('ACCOUNT_SETUP.AGREEMENTS.GINMON.SEPA.TEXT')}</Paragraph2>
        <Paragraph2 variant="medium">
          {t('ACCOUNT_SETUP.AGREEMENTS.GINMON.SEPA.NOTE_PREFIX')}{' '}
          <Paragraph2>{t('ACCOUNT_SETUP.AGREEMENTS.GINMON.SEPA.NOTE')}</Paragraph2>
        </Paragraph2>
      </YStack>
    </YStack>
  );
}
