import {LabelCard} from '@/components/cards/LabelCard';
import {Skeleton} from '@/components/views/Skeleton';
import {TransactionItem} from '@/modules/account-activity/components/TransactionItem';
import {useGetTransactionsQuery} from '@/store/queries/apeironApi';
import {useTranslate} from '@tolgee/react';
import React from 'react';
import {YStack} from 'tamagui';

type TransactionsPreviewCardProps = {
  customerId: string;
};

export function TransactionsPreviewCard({customerId}: TransactionsPreviewCardProps) {
  const {data, isLoading} = useGetTransactionsQuery({
    customerId,
    offset: 0,
    limit: 3,
  });

  const {t} = useTranslate();

  return (
    <LabelCard
      gap="$5"
      label={t('OVERVIEW.TRANSACTRIONS-OVERVIEW')}
      labelLink={{
        href: {
          pathname: '/overview/[customerId]/transactions',
          params: {customerId},
        },
        label: t('OVERVIEW.TRANSACTIONS-OVERVIEW-MORE'),
      }}
    >
      <YStack gap="$3">
        {isLoading ? (
          <>
            <Skeleton width="100%" />
            <Skeleton width="100%" />
          </>
        ) : null}
        {data?.items.map((item, index) => (
          <TransactionItem key={`transaction-${item.createdAt}-${index}`} item={item} />
        ))}
      </YStack>
    </LabelCard>
  );
}
