import {Country} from '@/types/api/refdata.v2';
import {ImageSource} from 'expo-image';

/**
 * Used as default country for tax residence usage
 */
export const DEFAULT_COUNTRY: Country = {
  taxResidenceUsageAllowed: true,
  label: {
    de: 'Deutschland',
    en: 'Germany',
  },
  code: 'DE',
} as const;

/**
 * Countries that are listed at the very top of lists
 */
export const IMPORTANT_COUNTRIES = ['DE', 'AT', 'CH'];

export const TAX_RESIDENCIES = [
  'AT',
  'BE',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GR',
  'HR',
  'IT',
  'LU',
  'NL',
  'PL',
  'PT',
  'SE',
];

export const CALLING_CODES = [
  {countryCode: 'AD', callingCode: '+376'},
  {countryCode: 'AE', callingCode: '+971'},
  {countryCode: 'AF', callingCode: '+93'},
  {countryCode: 'AG', callingCode: '+1-268'},
  {countryCode: 'AI', callingCode: '+1-264'},
  {countryCode: 'AL', callingCode: '+355'},
  {countryCode: 'AM', callingCode: '+374'},
  {countryCode: 'AN', callingCode: '+599'},
  {countryCode: 'AO', callingCode: '+244'},
  {countryCode: 'AQ', callingCode: '+672'},
  {countryCode: 'AR', callingCode: '+54'},
  {countryCode: 'AS', callingCode: '+1-684'},
  {countryCode: 'AT', callingCode: '+43'},
  {countryCode: 'AU', callingCode: '+61'},
  {countryCode: 'AW', callingCode: '+297'},
  {countryCode: 'AX', callingCode: '+358'},
  {countryCode: 'AZ', callingCode: '+994'},
  {countryCode: 'BA', callingCode: '+387'},
  {countryCode: 'BB', callingCode: '+1-246'},
  {countryCode: 'BD', callingCode: '+880'},
  {countryCode: 'BE', callingCode: '+32'},
  {countryCode: 'BF', callingCode: '+226'},
  {countryCode: 'BG', callingCode: '+359'},
  {countryCode: 'BH', callingCode: '+973'},
  {countryCode: 'BI', callingCode: '+257'},
  {countryCode: 'BJ', callingCode: '+229'},
  {countryCode: 'BL', callingCode: '+590'},
  {countryCode: 'BM', callingCode: '+1-441'},
  {countryCode: 'BN', callingCode: '+673'},
  {countryCode: 'BO', callingCode: '+591'},
  {countryCode: 'BQ', callingCode: '+599'},
  {countryCode: 'BR', callingCode: '+55'},
  {countryCode: 'BS', callingCode: '+1-242'},
  {countryCode: 'BT', callingCode: '+975'},
  {countryCode: 'BV', callingCode: '+47'},
  {countryCode: 'BW', callingCode: '+267'},
  {countryCode: 'BY', callingCode: '+375'},
  {countryCode: 'BZ', callingCode: '+501'},
  {countryCode: 'CC', callingCode: '+61'},
  {countryCode: 'CD', callingCode: '+243'},
  {countryCode: 'CF', callingCode: '+236'},
  {countryCode: 'CG', callingCode: '+242'},
  {countryCode: 'CH', callingCode: '+41'},
  {countryCode: 'CI', callingCode: '+225'},
  {countryCode: 'CK', callingCode: '+682'},
  {countryCode: 'CL', callingCode: '+56'},
  {countryCode: 'CM', callingCode: '+237'},
  {countryCode: 'CN', callingCode: '+86'},
  {countryCode: 'CO', callingCode: '+57'},
  {countryCode: 'CR', callingCode: '+506'},
  {countryCode: 'CU', callingCode: '+53'},
  {countryCode: 'CV', callingCode: '+238'},
  {countryCode: 'CW', callingCode: '+599'},
  {countryCode: 'CX', callingCode: '+61'},
  {countryCode: 'CY', callingCode: '+357'},
  {countryCode: 'CZ', callingCode: '+420'},
  {countryCode: 'DE', callingCode: '+49'},
  {countryCode: 'DJ', callingCode: '+253'},
  {countryCode: 'DK', callingCode: '+45'},
  {countryCode: 'DM', callingCode: '+1-767'},
  {countryCode: 'DO', callingCode: '+1-809'},
  {countryCode: 'DZ', callingCode: '+213'},
  {countryCode: 'EC', callingCode: '+593'},
  {countryCode: 'EE', callingCode: '+372'},
  {countryCode: 'EG', callingCode: '+20'},
  {countryCode: 'EH', callingCode: '+212'},
  {countryCode: 'ER', callingCode: '+291'},
  {countryCode: 'ES', callingCode: '+34'},
  {countryCode: 'ET', callingCode: '+251'},
  {countryCode: 'FI', callingCode: '+358'},
  {countryCode: 'FJ', callingCode: '+679'},
  {countryCode: 'FK', callingCode: '+500'},
  {countryCode: 'FM', callingCode: '+691'},
  {countryCode: 'FO', callingCode: '+298'},
  {countryCode: 'FR', callingCode: '+33'},
  {countryCode: 'GA', callingCode: '+241'},
  {countryCode: 'GB', callingCode: '+44'},
  {countryCode: 'GD', callingCode: '+1-473'},
  {countryCode: 'GE', callingCode: '+995'},
  {countryCode: 'GF', callingCode: '+594'},
  {countryCode: 'GG', callingCode: '+44'},
  {countryCode: 'GH', callingCode: '+233'},
  {countryCode: 'GI', callingCode: '+350'},
  {countryCode: 'GL', callingCode: '+299'},
  {countryCode: 'GM', callingCode: '+220'},
  {countryCode: 'GN', callingCode: '+224'},
  {countryCode: 'GP', callingCode: '+590'},
  {countryCode: 'GQ', callingCode: '+240'},
  {countryCode: 'GR', callingCode: '+30'},
  {countryCode: 'GS', callingCode: '+500'},
  {countryCode: 'GT', callingCode: '+502'},
  {countryCode: 'GU', callingCode: '+1-671'},
  {countryCode: 'GW', callingCode: '+245'},
  {countryCode: 'GY', callingCode: '+592'},
  {countryCode: 'HK', callingCode: '+852'},
  {countryCode: 'HM', callingCode: '+672'},
  {countryCode: 'HN', callingCode: '+504'},
  {countryCode: 'HR', callingCode: '+385'},
  {countryCode: 'HT', callingCode: '+509'},
  {countryCode: 'HU', callingCode: '+36'},
  {countryCode: 'ID', callingCode: '+62'},
  {countryCode: 'IE', callingCode: '+353'},
  {countryCode: 'IL', callingCode: '+972'},
  {countryCode: 'IM', callingCode: '+44'},
  {countryCode: 'IN', callingCode: '+91'},
  {countryCode: 'IO', callingCode: '+246'},
  {countryCode: 'IQ', callingCode: '+964'},
  {countryCode: 'IR', callingCode: '+98'},
  {countryCode: 'IS', callingCode: '+354'},
  {countryCode: 'IT', callingCode: '+39'},
  {countryCode: 'JE', callingCode: '+44'},
  {countryCode: 'JM', callingCode: '+1-876'},
  {countryCode: 'JO', callingCode: '+962'},
  {countryCode: 'JP', callingCode: '+81'},
  {countryCode: 'KE', callingCode: '+254'},
  {countryCode: 'KG', callingCode: '+996'},
  {countryCode: 'KH', callingCode: '+855'},
  {countryCode: 'KI', callingCode: '+686'},
  {countryCode: 'KM', callingCode: '+269'},
  {countryCode: 'KN', callingCode: '+1-869'},
  {countryCode: 'KP', callingCode: '+850'},
  {countryCode: 'KR', callingCode: '+82'},
  {countryCode: 'KW', callingCode: '+965'},
  {countryCode: 'KY', callingCode: '+1-345'},
  {countryCode: 'LA', callingCode: '+856'},
  {countryCode: 'LB', callingCode: '+961'},
  {countryCode: 'LC', callingCode: '+1-758'},
  {countryCode: 'LI', callingCode: '+423'},
  {countryCode: 'LK', callingCode: '+94'},
  {countryCode: 'LR', callingCode: '+231'},
  {countryCode: 'LS', callingCode: '+266'},
  {countryCode: 'LT', callingCode: '+370'},
  {countryCode: 'LU', callingCode: '+352'},
  {countryCode: 'LV', callingCode: '+371'},
  {countryCode: 'LY', callingCode: '+218'},
  {countryCode: 'MA', callingCode: '+212'},
  {countryCode: 'MC', callingCode: '+377'},
  {countryCode: 'MD', callingCode: '+373'},
  {countryCode: 'ME', callingCode: '+382'},
  {countryCode: 'MF', callingCode: '+590'},
  {countryCode: 'MG', callingCode: '+261'},
  {countryCode: 'MH', callingCode: '+692'},
  {countryCode: 'MK', callingCode: '+389'},
  {countryCode: 'ML', callingCode: '+223'},
  {countryCode: 'MM', callingCode: '+95'},
  {countryCode: 'MN', callingCode: '+976'},
  {countryCode: 'MO', callingCode: '+853'},
  {countryCode: 'MP', callingCode: '+1-670'},
  {countryCode: 'MQ', callingCode: '+596'},
  {countryCode: 'MR', callingCode: '+222'},
  {countryCode: 'MS', callingCode: '+1-664'},
  {countryCode: 'MT', callingCode: '+356'},
  {countryCode: 'MU', callingCode: '+230'},
  {countryCode: 'MV', callingCode: '+960'},
  {countryCode: 'MW', callingCode: '+265'},
  {countryCode: 'MX', callingCode: '+52'},
  {countryCode: 'MY', callingCode: '+60'},
  {countryCode: 'MZ', callingCode: '+258'},
  {countryCode: 'NA', callingCode: '+264'},
  {countryCode: 'NC', callingCode: '+687'},
  {countryCode: 'NE', callingCode: '+227'},
  {countryCode: 'NF', callingCode: '+672'},
  {countryCode: 'NG', callingCode: '+234'},
  {countryCode: 'NI', callingCode: '+505'},
  {countryCode: 'NL', callingCode: '+31'},
  {countryCode: 'NO', callingCode: '+47'},
  {countryCode: 'NP', callingCode: '+977'},
  {countryCode: 'NR', callingCode: '+674'},
  {countryCode: 'NU', callingCode: '+683'},
  {countryCode: 'NZ', callingCode: '+64'},
  {countryCode: 'OM', callingCode: '+968'},
  {countryCode: 'PA', callingCode: '+507'},
  {countryCode: 'PE', callingCode: '+51'},
  {countryCode: 'PF', callingCode: '+689'},
  {countryCode: 'PG', callingCode: '+675'},
  {countryCode: 'PH', callingCode: '+63'},
  {countryCode: 'PK', callingCode: '+92'},
  {countryCode: 'PL', callingCode: '+48'},
  {countryCode: 'PM', callingCode: '+508'},
  {countryCode: 'PN', callingCode: '+64'},
  {countryCode: 'PR', callingCode: '+1-787'},
  {countryCode: 'PS', callingCode: '+970'},
  {countryCode: 'PT', callingCode: '+351'},
  {countryCode: 'PW', callingCode: '+680'},
  {countryCode: 'PY', callingCode: '+595'},
  {countryCode: 'QA', callingCode: '+974'},
  {countryCode: 'RE', callingCode: '+262'},
  {countryCode: 'RO', callingCode: '+40'},
  {countryCode: 'RS', callingCode: '+381'},
  {countryCode: 'RU', callingCode: '+7'},
  {countryCode: 'RW', callingCode: '+250'},
  {countryCode: 'SA', callingCode: '+966'},
  {countryCode: 'SB', callingCode: '+677'},
  {countryCode: 'SC', callingCode: '+248'},
  {countryCode: 'SD', callingCode: '+249'},
  {countryCode: 'SE', callingCode: '+46'},
  {countryCode: 'SG', callingCode: '+65'},
  {countryCode: 'SH', callingCode: '+290'},
  {countryCode: 'SI', callingCode: '+386'},
  {countryCode: 'SJ', callingCode: '+47'},
  {countryCode: 'SK', callingCode: '+421'},
  {countryCode: 'SL', callingCode: '+232'},
  {countryCode: 'SM', callingCode: '+378'},
  {countryCode: 'SN', callingCode: '+221'},
  {countryCode: 'SO', callingCode: '+252'},
  {countryCode: 'SR', callingCode: '+597'},
  {countryCode: 'SS', callingCode: '+211'},
  {countryCode: 'ST', callingCode: '+239'},
  {countryCode: 'SV', callingCode: '+503'},
  {countryCode: 'SX', callingCode: '+1-721'},
  {countryCode: 'SY', callingCode: '+963'},
  {countryCode: 'SZ', callingCode: '+268'},
  {countryCode: 'TC', callingCode: '+1-649'},
  {countryCode: 'TD', callingCode: '+235'},
  {countryCode: 'TF', callingCode: '+262'},
  {countryCode: 'TG', callingCode: '+228'},
  {countryCode: 'TH', callingCode: '+66'},
  {countryCode: 'TJ', callingCode: '+992'},
  {countryCode: 'TK', callingCode: '+690'},
  {countryCode: 'TL', callingCode: '+670'},
  {countryCode: 'TM', callingCode: '+993'},
  {countryCode: 'TN', callingCode: '+216'},
  {countryCode: 'TO', callingCode: '+676'},
  {countryCode: 'TR', callingCode: '+90'},
  {countryCode: 'TT', callingCode: '+1-868'},
  {countryCode: 'TV', callingCode: '+688'},
  {countryCode: 'TW', callingCode: '+886'},
  {countryCode: 'TZ', callingCode: '+255'},
  {countryCode: 'UA', callingCode: '+380'},
  {countryCode: 'UG', callingCode: '+256'},
  {countryCode: 'US', callingCode: '+1'},
  {countryCode: 'UY', callingCode: '+598'},
  {countryCode: 'UZ', callingCode: '+998'},
  {countryCode: 'VA', callingCode: '+39'},
  {countryCode: 'VC', callingCode: '+1-784'},
  {countryCode: 'VE', callingCode: '+58'},
  {countryCode: 'VG', callingCode: '+1-284'},
  {countryCode: 'VI', callingCode: '+1-340'},
  {countryCode: 'VN', callingCode: '+84'},
  {countryCode: 'VU', callingCode: '+678'},
  {countryCode: 'WF', callingCode: '+681'},
  {countryCode: 'WS', callingCode: '+685'},
  {countryCode: 'YE', callingCode: '+967'},
  {countryCode: 'YT', callingCode: '+262'},
  {countryCode: 'ZA', callingCode: '+27'},
  {countryCode: 'ZM', callingCode: '+260'},
  {countryCode: 'ZW', callingCode: '+263'},
  {countryCode: 'KZ', callingCode: '+7'},
  {countryCode: 'CA', callingCode: '+1'},
] as const;

export const FLAGS: Record<string, ImageSource> = {
  TG: require('@/assets/flags/TG.png'),
  ME: require('@/assets/flags/ME.png'),
  LA: require('@/assets/flags/LA.png'),
  MR: require('@/assets/flags/MR.png'),
  NI: require('@/assets/flags/NI.png'),
  LV: require('@/assets/flags/LV.png'),
  OM: require('@/assets/flags/OM.png'),
  AF: require('@/assets/flags/AF.png'),
  CY: require('@/assets/flags/CY.png'),
  BJ: require('@/assets/flags/BJ.png'),
  AQ: require('@/assets/flags/AQ.png'),
  CN: require('@/assets/flags/CN.png'),
  CO: require('@/assets/flags/CO.png'),
  CX: require('@/assets/flags/CX.png'),
  AG: require('@/assets/flags/AG.png'),
  MS: require('@/assets/flags/MS.png'),
  MD: require('@/assets/flags/MD.png'),
  ZM: require('@/assets/flags/ZM.png'),
  VN: require('@/assets/flags/VN.png'),
  TF: require('@/assets/flags/TF.png'),
  TD: require('@/assets/flags/TD.png'),
  YT: require('@/assets/flags/YT.png'),
  LB: require('@/assets/flags/LB.png'),
  MF: require('@/assets/flags/MF.png'),
  LU: require('@/assets/flags/LU.png'),
  MQ: require('@/assets/flags/MQ.png'),
  CZ: require('@/assets/flags/CZ.png'),
  AE: require('@/assets/flags/AE.png'),
  CM: require('@/assets/flags/CM.png'),
  BI: require('@/assets/flags/BI.png'),
  AR: require('@/assets/flags/AR.png'),
  AS: require('@/assets/flags/AS.png'),
  BH: require('@/assets/flags/BH.png'),
  CL: require('@/assets/flags/CL.png'),
  AD: require('@/assets/flags/AD.png'),
  MP: require('@/assets/flags/MP.png'),
  LT: require('@/assets/flags/LT.png'),
  MG: require('@/assets/flags/MG.png'),
  LC: require('@/assets/flags/LC.png'),
  TR: require('@/assets/flags/TR.png'),
  UA: require('@/assets/flags/UA.png'),
  TV: require('@/assets/flags/TV.png'),
  VI: require('@/assets/flags/VI.png'),
  MT: require('@/assets/flags/MT.png'),
  NO: require('@/assets/flags/NO.png'),
  MC: require('@/assets/flags/MC.png'),
  CH: require('@/assets/flags/CH.png'),
  BL: require('@/assets/flags/BL.png'),
  AW: require('@/assets/flags/AW.png'),
  BZ: require('@/assets/flags/BZ.png'),
  BM: require('@/assets/flags/BM.png'),
  CI: require('@/assets/flags/CI.png'),
  MU: require('@/assets/flags/MU.png'),
  US: require('@/assets/flags/US.png'),
  TW: require('@/assets/flags/TW.png'),
  YE: require('@/assets/flags/YE.png'),
  MW: require('@/assets/flags/MW.png'),
  NL: require('@/assets/flags/NL.png'),
  LS: require('@/assets/flags/LS.png'),
  BO: require('@/assets/flags/BO.png'),
  AT: require('@/assets/flags/AT.png'),
  CK: require('@/assets/flags/CK.png'),
  BY: require('@/assets/flags/BY.png'),
  AU: require('@/assets/flags/AU.png'),
  BN: require('@/assets/flags/BN.png'),
  MA: require('@/assets/flags/MA.png'),
  NZ: require('@/assets/flags/NZ.png'),
  LR: require('@/assets/flags/LR.png'),
  MV: require('@/assets/flags/MV.png'),
  TC: require('@/assets/flags/TC.png'),
  UG: require('@/assets/flags/UG.png'),
  TT: require('@/assets/flags/TT.png'),
  PL: require('@/assets/flags/PL.png'),
  RS: require('@/assets/flags/RS.png'),
  IN: require('@/assets/flags/IN.png'),
  GE: require('@/assets/flags/GE.png'),
  GR: require('@/assets/flags/GR.png'),
  GS: require('@/assets/flags/GS.png'),
  GD: require('@/assets/flags/GD.png'),
  HK: require('@/assets/flags/HK.png'),
  KP: require('@/assets/flags/KP.png'),
  KG: require('@/assets/flags/KG.png'),
  SV: require('@/assets/flags/SV.png'),
  RE: require('@/assets/flags/RE.png'),
  SA: require('@/assets/flags/SA.png'),
  SC: require('@/assets/flags/SC.png'),
  ST: require('@/assets/flags/ST.png'),
  KE: require('@/assets/flags/KE.png'),
  IM: require('@/assets/flags/IM.png'),
  KR: require('@/assets/flags/KR.png'),
  DJ: require('@/assets/flags/DJ.png'),
  GQ: require('@/assets/flags/GQ.png'),
  DK: require('@/assets/flags/DK.png'),
  GG: require('@/assets/flags/GG.png'),
  IL: require('@/assets/flags/IL.png'),
  PN: require('@/assets/flags/PN.png'),
  SB: require('@/assets/flags/SB.png'),
  PY: require('@/assets/flags/PY.png'),
  RU: require('@/assets/flags/RU.png'),
  KW: require('@/assets/flags/KW.png'),
  DO: require('@/assets/flags/DO.png'),
  GT: require('@/assets/flags/GT.png'),
  GB: require('@/assets/flags/GB.png'),
  GU: require('@/assets/flags/GU.png'),
  JE: require('@/assets/flags/JE.png'),
  SG: require('@/assets/flags/SG.png'),
  PK: require('@/assets/flags/PK.png'),
  SR: require('@/assets/flags/SR.png'),
  SE: require('@/assets/flags/SE.png'),
  JP: require('@/assets/flags/JP.png'),
  GW: require('@/assets/flags/GW.png'),
  EH: require('@/assets/flags/EH.png'),
  DZ: require('@/assets/flags/DZ.png'),
  GA: require('@/assets/flags/GA.png'),
  FR: require('@/assets/flags/FR.png'),
  DM: require('@/assets/flags/DM.png'),
  HN: require('@/assets/flags/HN.png'),
  SD: require('@/assets/flags/SD.png'),
  RW: require('@/assets/flags/RW.png'),
  PH: require('@/assets/flags/PH.png'),
  SS: require('@/assets/flags/SS.png'),
  QA: require('@/assets/flags/QA.png'),
  PE: require('@/assets/flags/PE.png'),
  PR: require('@/assets/flags/PR.png'),
  SI: require('@/assets/flags/SI.png'),
  HT: require('@/assets/flags/HT.png'),
  ES: require('@/assets/flags/ES.png'),
  GL: require('@/assets/flags/GL.png'),
  GM: require('@/assets/flags/GM.png'),
  ER: require('@/assets/flags/ER.png'),
  FI: require('@/assets/flags/FI.png'),
  EE: require('@/assets/flags/EE.png'),
  KN: require('@/assets/flags/KN.png'),
  HU: require('@/assets/flags/HU.png'),
  IQ: require('@/assets/flags/IQ.png'),
  KY: require('@/assets/flags/KY.png'),
  SH: require('@/assets/flags/SH.png'),
  PS: require('@/assets/flags/PS.png'),
  PF: require('@/assets/flags/PF.png'),
  ID: require('@/assets/flags/ID.png'),
  IS: require('@/assets/flags/IS.png'),
  EG: require('@/assets/flags/EG.png'),
  FK: require('@/assets/flags/FK.png'),
  FJ: require('@/assets/flags/FJ.png'),
  GN: require('@/assets/flags/GN.png'),
  GY: require('@/assets/flags/GY.png'),
  IR: require('@/assets/flags/IR.png'),
  KM: require('@/assets/flags/KM.png'),
  IE: require('@/assets/flags/IE.png'),
  KZ: require('@/assets/flags/KZ.png'),
  RO: require('@/assets/flags/RO.png'),
  SK: require('@/assets/flags/SK.png'),
  PG: require('@/assets/flags/PG.png'),
  PT: require('@/assets/flags/PT.png'),
  SO: require('@/assets/flags/SO.png'),
  SX: require('@/assets/flags/SX.png'),
  HR: require('@/assets/flags/HR.png'),
  KI: require('@/assets/flags/KI.png'),
  JM: require('@/assets/flags/JM.png'),
  EU: require('@/assets/flags/EU.png'),
  EC: require('@/assets/flags/EC.png'),
  ET: require('@/assets/flags/ET.png'),
  FO: require('@/assets/flags/FO.png'),
  KH: require('@/assets/flags/KH.png'),
  SY: require('@/assets/flags/SY.png'),
  SN: require('@/assets/flags/SN.png'),
  PW: require('@/assets/flags/PW.png'),
  SL: require('@/assets/flags/SL.png'),
  FM: require('@/assets/flags/FM.png'),
  GI: require('@/assets/flags/GI.png'),
  DE: require('@/assets/flags/DE.png'),
  GH: require('@/assets/flags/GH.png'),
  IC: require('@/assets/flags/IC.png'),
  JO: require('@/assets/flags/JO.png'),
  IT: require('@/assets/flags/IT.png'),
  PA: require('@/assets/flags/PA.png'),
  SZ: require('@/assets/flags/SZ.png'),
  SM: require('@/assets/flags/SM.png'),
  TN: require('@/assets/flags/TN.png'),
  ML: require('@/assets/flags/ML.png'),
  CG: require('@/assets/flags/CG.png'),
  AX: require('@/assets/flags/AX.png'),
  AO: require('@/assets/flags/AO.png'),
  BT: require('@/assets/flags/BT.png'),
  AN: require('@/assets/flags/AN.png'),
  BB: require('@/assets/flags/BB.png'),
  CF: require('@/assets/flags/CF.png'),
  MM: require('@/assets/flags/MM.png'),
  LI: require('@/assets/flags/LI.png'),
  NA: require('@/assets/flags/NA.png'),
  MZ: require('@/assets/flags/MZ.png'),
  TO: require('@/assets/flags/TO.png'),
  VG: require('@/assets/flags/VG.png'),
  VE: require('@/assets/flags/VE.png'),
  TZ: require('@/assets/flags/TZ.png'),
  TM: require('@/assets/flags/TM.png'),
  MX: require('@/assets/flags/MX.png'),
  NC: require('@/assets/flags/NC.png'),
  MO: require('@/assets/flags/MO.png'),
  LK: require('@/assets/flags/LK.png'),
  CD: require('@/assets/flags/CD.png'),
  AL: require('@/assets/flags/AL.png'),
  BW: require('@/assets/flags/BW.png'),
  CR: require('@/assets/flags/CR.png'),
  AM: require('@/assets/flags/AM.png'),
  AZ: require('@/assets/flags/AZ.png'),
  BA: require('@/assets/flags/BA.png'),
  MN: require('@/assets/flags/MN.png'),
  NU: require('@/assets/flags/NU.png'),
  MY: require('@/assets/flags/MY.png'),
  TL: require('@/assets/flags/TL.png'),
  WS: require('@/assets/flags/WS.png'),
  TH: require('@/assets/flags/TH.png'),
  NF: require('@/assets/flags/NF.png'),
  LY: require('@/assets/flags/LY.png'),
  AI: require('@/assets/flags/AI.png'),
  BR: require('@/assets/flags/BR.png'),
  CV: require('@/assets/flags/CV.png'),
  BE: require('@/assets/flags/BE.png'),
  CA: require('@/assets/flags/CA.png'),
  BD: require('@/assets/flags/BD.png'),
  CW: require('@/assets/flags/CW.png'),
  BS: require('@/assets/flags/BS.png'),
  NG: require('@/assets/flags/NG.png'),
  MK: require('@/assets/flags/MK.png'),
  NP: require('@/assets/flags/NP.png'),
  VA: require('@/assets/flags/VA.png'),
  UZ: require('@/assets/flags/UZ.png'),
  TK: require('@/assets/flags/TK.png'),
  VC: require('@/assets/flags/VC.png'),
  ZW: require('@/assets/flags/ZW.png'),
  NR: require('@/assets/flags/NR.png'),
  NE: require('@/assets/flags/NE.png'),
  CU: require('@/assets/flags/CU.png'),
  BF: require('@/assets/flags/BF.png'),
  BG: require('@/assets/flags/BG.png'),
  CC: require('@/assets/flags/CC.png'),
  CT: require('@/assets/flags/CT.png'),
  MH: require('@/assets/flags/MH.png'),
  ZA: require('@/assets/flags/ZA.png'),
  UY: require('@/assets/flags/UY.png'),
  WF: require('@/assets/flags/WF.png'),
  VU: require('@/assets/flags/VU.png'),
  TJ: require('@/assets/flags/TJ.png'),
} as const;
