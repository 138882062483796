import {Card} from '@/components/cards/Card';
import SvgFile from '@/components/icons/SvgFile';
import {Heading5} from '@/components/texts/Heading';
import {Label3} from '@/components/texts/Label';
import {Paragraph2} from '@/components/texts/Paragraph';
import {ChangePassword} from '@/modules/profile/components/ChangePassword';
import {useTranslate} from '@tolgee/react';
import {useState} from 'react';
import {XStack, YStack} from 'tamagui';

export function PasswordDataCard() {
  const {t} = useTranslate();
  const [showChange, setShowChange] = useState(false);
  const onPress = () => {
    setShowChange(true);
  };

  return (
    <Card gap="$6">
      <XStack justifyContent="space-between">
        <Heading5 variant="medium">{t('PROFILE.SECURITY.PASSWORD')}</Heading5>
        {!showChange && (
          <XStack gap="$1.5" alignItems="center" onPress={onPress} cursor="pointer">
            <SvgFile size={14} name="pen" color="$primary" />
            <Paragraph2 color="$primary">{t('PERSONAL_DETAILS.ADDRESS_DATA.CHANGE')}</Paragraph2>
          </XStack>
        )}
      </XStack>

      {!showChange ? (
        <YStack justifyContent="space-between" gap="$4">
          <YStack gap="$3">
            <Label3 color="$neutral500">{t('PROFILE.SECURITY.CHANGE-PASSWORD.OLD')}</Label3>
            <Label3 variant="medium">********</Label3>
          </YStack>
        </YStack>
      ) : (
        <ChangePassword onClose={() => setShowChange(false)} />
      )}
    </Card>
  );
}
