import {useTranslate} from '@tolgee/react';
import {useRouter} from 'expo-router';
import {useCallback, useMemo} from 'react';
import {YStack} from 'tamagui';

import {Heading5} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {useUserFinishedOnboardingBefore} from '@/hooks/useUserFinishedOnboardingBefore';
import {TipWithSideModal} from '@/modules/interview/components/TipWithSideModal';
import {UserBankScreen} from '@/modules/onboarding/components/UserBankScreen';
import {useOnboardingSearchParams} from '@/modules/onboarding/hooks/useOnboardingSearchParams';

const BankScreen = () => {
  const {t} = useTranslate();

  const router = useRouter();

  const params = useOnboardingSearchParams();

  const {finishedBefore, isFetching: isUserFinishedOnboardingBeforeFetching} =
    useUserFinishedOnboardingBefore();

  const tipContent = useMemo(
    () => (
      <YStack gap="$5" marginVertical="$5">
        <Heading5 variant="medium">{t('ONBOARDING.FAQ.BANK.HEADER')}</Heading5>
        <Paragraph2 color="$neutral400">{t('ONBOARDING.FAQ.BANK.LINE_1')}</Paragraph2>
        <Paragraph2 color="$neutral400">{t('ONBOARDING.FAQ.BANK.LINE_2')}</Paragraph2>
        <Paragraph2 color="$neutral400">{t('ONBOARDING.FAQ.BANK.LINE_3')}</Paragraph2>
        <Paragraph2 variant="medium">{t('ONBOARDING.FAQ.BANK.LINE_4')}</Paragraph2>
        <Paragraph2 color="$neutral400">{t('ONBOARDING.FAQ.BANK.LINE_5')}</Paragraph2>
      </YStack>
    ),
    [t]
  );

  const handleContinuePressed = useCallback(() => {
    if (isUserFinishedOnboardingBeforeFetching) return;

    if (finishedBefore) {
      router.navigate({
        pathname: '/onboarding/personal-details/agreements',
        params,
      });

      return;
    }

    router.navigate({
      pathname:
        params.product === 'CHILD_ACCOUNT'
          ? '/onboarding/personal-details/agreements'
          : '/onboarding/personal-details/summary',
      params,
    });
  }, [finishedBefore, isUserFinishedOnboardingBeforeFetching, params, router]);

  return (
    <FullscreenScrollView variant="small" useKeyboardAwareScrollView>
      <UserBankScreen onSuccess={handleContinuePressed} />
      <TipWithSideModal tipTitle={t('ONBOARDING.FAQ.BANK.TITLE')} tipContent={tipContent} />
    </FullscreenScrollView>
  );
};

export default BankScreen;
