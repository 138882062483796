import {CheckBox} from '@/components/inputs/Checkbox';
import {Label3} from '@/components/texts/Label';
import {Skeleton} from '@/components/views/Skeleton';
import {ProfileListItem} from '@/modules/profile/components/ProfileListItem';
import {ProfileSelectInput} from '@/modules/profile/components/ProfileSelectInput';
import {useGetSelfUserQuery, useUpdateUserMutation} from '@/store/queries/usersApi';
import {useTranslate} from '@tolgee/react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Label, XStack, XStackProps, isTouchable, useMedia} from 'tamagui';

export function MarketingConsent({containerProps}: {containerProps?: XStackProps}) {
  const {t} = useTranslate();
  const {data: user} = useGetSelfUserQuery();
  const [updateUser] = useUpdateUserMutation();
  const [checked, setChecked] = useState<boolean | null>(null);
  const media = useMedia();

  const items = useMemo(
    () => [
      {
        key: 'true',
        name: t('NPS.YES'),
      },
      {
        key: 'false',
        name: t('NPS.NO'),
      },
    ],
    [t]
  );

  useEffect(() => {
    if (checked === null && user) setChecked(user.marketingConsent || false);
  }, [checked, user]);

  const onCheckedChange = useCallback(
    (checked: boolean) => {
      setChecked(checked);
      if (user) updateUser({currentUser: user, changes: {marketingConsent: checked}});
    },
    [updateUser, user]
  );
  const mobileMarketingConsent = useMemo(() => {
    if (!media.sm && !isTouchable) return null;
    return (
      <ProfileSelectInput
        label={t('SETTINGS.SELECT_COMMUNICATION_LANGUAGE')}
        items={items}
        placeholder="-"
        onSelect={(item: string) => {
          onCheckedChange(item === 'true');
        }}
        selectedItem={checked ? 'true' : 'false'}
        additionalContent={
          <Label3 textAlign="center" padding="$2" color="$neutral500">
            {t('SETTINGS.COMMUNICATION_MODE.DESCRIPTION')}
          </Label3>
        }
      >
        <ProfileListItem
          label={t('SETTINGS.COMMUNICATION_MODE.MARKETING_CONSENT')}
          value={checked ? t('NPS.YES') : t('NPS.NO')}
        />
      </ProfileSelectInput>
    );
  }, [checked, items, media.sm, onCheckedChange, t]);

  if (media.sm || isTouchable) return mobileMarketingConsent;
  else
    return (
      <XStack alignItems="center" gap="$4" cursor="pointer" {...containerProps}>
        <Skeleton height={20} width={20}>
          {checked !== null && (
            <CheckBox
              checked={checked}
              onCheckedChange={onCheckedChange}
              id="marketing-conset-checkbox"
            />
          )}
        </Skeleton>
        <Label htmlFor="marketing-conset-checkbox" cursor="inherit">
          <Label3 color="$neutral500">{t('SETTINGS.COMMUNICATION_MODE.DESCRIPTION')}</Label3>
        </Label>
      </XStack>
    );
}
