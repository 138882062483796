import {Button} from '@/components/buttons/Button';
import {useTranslate} from '@tolgee/react';
import React from 'react';
import {Keyboard} from 'react-native';
import {GetProps} from 'tamagui';

export function InterviewContinueButton(props: GetProps<typeof Button>) {
  const {t} = useTranslate();

  return (
    <>
      <Button
        testID="continue-button"
        size="large"
        alignSelf="stretch"
        {...props}
        onPress={() => {
          Keyboard.dismiss();
          // @ts-ignore
          props?.onPress?.();
        }}
        userSelect="none"
      >
        {props.children ? props.children : t('CONTINUE')}
      </Button>
    </>
  );
}
