import SvgFile from '@/components/icons/SvgFile';
import {Modal} from '@/components/modal/Modal';
import {Label3} from '@/components/texts/Label';
import {ChangeDisplayNameSchemaType} from '@/hooks/useValidations';
import {ChangeDisplayNameForm} from '@/modules/profile/components/ChangeDisplayNameForm';
import {usePatchDisplayNameMutation} from '@/store/queries/customersApi';
import {GetCustomerResponse} from '@/types/api/customers.v2';
import {ModalRef} from '@/types/modal';
import {useTranslate} from '@tolgee/react';
import {toast} from 'burnt';
import React, {useRef} from 'react';
import {XStack} from 'tamagui';

type ChangeDisplayNameModalProps = {
  customer: GetCustomerResponse;
};

export function ChangeDisplayNameModal({customer}: ChangeDisplayNameModalProps) {
  const {t} = useTranslate();
  const modalRef = useRef<ModalRef>(null);
  const [patchDisplayName] = usePatchDisplayNameMutation();
  return (
    <Modal ref={modalRef}>
      <Modal.Trigger>
        <XStack
          gap="$2"
          alignItems="center"
          justifyContent="flex-end"
          hoverStyle={{opacity: 0.8}}
          width="100%"
        >
          <Label3 variant="medium" textAlign="right">
            {customer.displayName}
          </Label3>
          <SvgFile name="edit" size={18} color="$primary" />
        </XStack>
      </Modal.Trigger>
      <Modal.Content>
        <ChangeDisplayNameForm
          customer={customer}
          modal
          onSubmit={async (data: ChangeDisplayNameSchemaType) => {
            const res = await patchDisplayName({
              customerId: customer.id,
              displayName: data.displayName,
            });
            if (!res.error) {
              modalRef.current?.open(false);
              toast({
                preset: 'done',
                title: t('SNACKBAR.DISPLAYNAME-CHANGED'),
              });
            } else {
              toast({
                preset: 'error',
                title: t('SNACKBAR.ERROR_TITLE'),
              });
            }
          }}
        />
      </Modal.Content>
    </Modal>
  );
}
