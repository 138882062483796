import SvgFile from '@/components/icons/SvgFile';
import {Heading4, Heading5} from '@/components/texts/Heading';
import {Label2, Label3} from '@/components/texts/Label';
import React from 'react';
import {View, XStack, YStack} from 'tamagui';

type FriendsProgressBlockProps = {
  title: string;
  description?: string;
  subtitle: string;
  reached?: boolean;
  variant?: 'default' | 'primary';
};

export function FriendsProgressBlock({
  title,
  description,
  subtitle,
  reached = false,
  variant = 'default',
}: FriendsProgressBlockProps) {
  const Heading = variant === 'primary' ? Heading5 : Heading4;
  return (
    <View
      width="10%"
      minWidth={150}
      flexGrow={1}
      flexShrink={1}
      backgroundColor={variant === 'primary' ? '$primary' : '$neutralBG'}
      gap="$2"
      borderRadius="$1"
      padding="$4"
      justifyContent="space-between"
      opacity={reached || variant === 'primary' ? 1 : 0.75}
    >
      <YStack gap="$2">
        <XStack justifyContent="space-between">
          <Heading
            flexWrap="wrap"
            variant="bold"
            color={variant === 'primary' ? '$white' : '$primary'}
          >
            {title}
          </Heading>
          {reached && variant !== 'primary' && <SvgFile name="check" size={32} color="$green" />}
        </XStack>
        {description && (
          <Label3 flexWrap="wrap" color="$neutral400">
            {description}
          </Label3>
        )}
      </YStack>
      <Label2 flexWrap="wrap" variant="medium" color={variant === 'primary' ? '$white' : '$text'}>
        {subtitle}
      </Label2>
    </View>
  );
}
