import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {UserAgreementsScreen} from '@/modules/onboarding/components/UserAgreementsScreen';
import {useOnboardingSearchParams} from '@/modules/onboarding/hooks/useOnboardingSearchParams';
import {useAppSelector} from '@/store';
import {useGetValidOrdersQuery} from '@/store/queries/ordersApi';
import {Planner} from '@/store/reducers/onboarding/state';
import {ChildAccountCreationOrderResponse} from '@/types/api/orders';

const AgreementsScreen = () => {
  const {
    product: paramsProduct,
    orderId,
    childID: paramsChildID,
    onboardingType,
  } = useOnboardingSearchParams();

  const plannerState = useAppSelector(state => state.onboarding.planner);

  const {data: orders, isFetching: ordersFetching} = useGetValidOrdersQuery();

  const order = orders?.items.find(order => order.id === orderId) as
    | ChildAccountCreationOrderResponse
    | undefined;

  const childID =
    onboardingType === 'second_guardian' && order
      ? (order as ChildAccountCreationOrderResponse).childUserId
      : paramsChildID;

  let product = paramsProduct;
  let orderPlannerState: Planner | undefined;

  if (onboardingType === 'second_guardian' && order) {
    orderPlannerState = {
      selectedStrategy: order?.account.initialInvestmentStrategy,
      initialDepositAmount: order.account.initialDepositAmount,
      initialRecurringDepositAmount: order.account.initialRecurringDeposit?.amount,
    };
    product = order.account.product;
  }

  const combinedPlannerState = plannerState ? plannerState : orderPlannerState;

  if (!product) {
    throw new Error('TODO: params missing');
  }

  return (
    <FullscreenScrollView variant="large">
      <UserAgreementsScreen
        orderId={orderId}
        childId={childID}
        product={product}
        plannerState={combinedPlannerState}
        href={{pathname: '/'}}
        isSecondGuardian={onboardingType === 'second_guardian'}
      />
    </FullscreenScrollView>
  );
};

export default AgreementsScreen;
