import {FormCustomCheckbox} from '@/components/inputs/form/FormCustomCheckbox';
import {Label2} from '@/components/texts/Label';
import {AgreementsSchemaType} from '@/hooks/useValidations';
import {ShortenedCustomerInformationResponse} from '@/types/api/customers.v2';
import {useTranslate} from '@tolgee/react';
import {Control} from 'react-hook-form';

export function GeneralAgreement({
  control,
  product,
}: {
  control: Control<AgreementsSchemaType>;
  product: ShortenedCustomerInformationResponse['product'];
}) {
  const {t} = useTranslate();
  return (
    <FormCustomCheckbox
      control={control}
      name="generalTerms"
      label={
        <Label2 variant="medium">
          {product === 'CHILD_ACCOUNT'
            ? t('ACCOUNT_SETUP.YES_IM_OPENING_THIS_ACCOUNT_FOR_MYSELF_CHILD')
            : t('ACCOUNT_SETUP.YES_IM_OPENING_THIS_ACCOUNT_FOR_MYSELF')}
        </Label2>
      }
    />
  );
}
