import {Canvas, Paragraph, Skia, useFonts} from '@shopify/react-native-skia';
import {SkTextStyle} from '@shopify/react-native-skia/src/skia/types/Paragraph/TextStyle';
import {SharedValue, useDerivedValue, useSharedValue} from 'react-native-reanimated';
import {useMedia, useTheme} from 'tamagui';

import {createParagraph} from '@/components/skia/utils';
import {BalanceAndPerformance} from '@/modules/overview/components/PerformanceChartsLegend';
import {useAppSelector} from '@/store';
import {useWindowDimensions} from 'react-native';

type HomeChartBalanceProps = {
  legendValue: Readonly<SharedValue<BalanceAndPerformance | undefined>>;
  balance: number;
};

const BALANCE_FONT_SIZE = 32;

export function HomeChartBalance({legendValue, balance}: HomeChartBalanceProps) {
  const theme = useTheme();
  const language = useAppSelector(state => state.app.language);
  const {width} = useWindowDimensions();
  const legendWidth = useSharedValue(width);
  const media = useMedia();

  const fonts = useFonts({
    // @ts-ignore
    Inter: [require('@tamagui/font-inter/otf/Inter-Medium.otf')],
  });

  const paragraph = useDerivedValue(() => {
    if (!fonts) return null;

    const textStyle: SkTextStyle = {
      fontSize: BALANCE_FONT_SIZE,
      color: media.sm ? Skia.Color(theme.white.val) : Skia.Color(theme.text.val),
      fontFamilies: ['Inter'],
    };

    const text = Intl.NumberFormat(language, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'EUR',
    }).format(legendValue?.value?.balance || balance);

    return createParagraph(fonts, textStyle, text, legendWidth.value);
  });

  const paragraphWidth = useDerivedValue(() => {
    if (!paragraph.value) return 0;

    return Math.ceil(paragraph.value.getLongestLine());
  });

  return (
    <Canvas style={{height: BALANCE_FONT_SIZE + 8}}>
      <Paragraph paragraph={paragraph} x={0} y={0} width={paragraphWidth} />
    </Canvas>
  );
}
