import {NativeLogoutButton} from '@/modules/navigation/components/header/NativeLogoutButton';
import {OnboardingHeader} from '@/modules/navigation/components/header/OnboardingHeader';
import {EmailVerificationStart} from '@/modules/orders/components/EmailVerification/EmailVerificationStart';
import useEmailVerifiedInterval from '@/providers/session-provider/useEmailVerificationState';
import {useAppDispatch} from '@/store';
import {baseApi} from '@/store/queries/baseApi';
import {Redirect} from 'expo-router';
import {SafeAreaView} from 'react-native';

export default function EmailVerificationStartPage() {
  const {emailVerified, isResending, onEmailResend} = useEmailVerifiedInterval();
  const dispatch = useAppDispatch();

  if (emailVerified) {
    dispatch(baseApi.util.invalidateTags(['USERS']));
    return <Redirect href={{pathname: '/'}} />;
  }

  return (
    <SafeAreaView style={{flex: 1}}>
      <OnboardingHeader />
      <EmailVerificationStart isResending={isResending} onEmailResend={onEmailResend} />
      <NativeLogoutButton />
    </SafeAreaView>
  );
}
