import {PropsWithChildren, useCallback, useMemo} from 'react';
import {Control, useController} from 'react-hook-form';

import {CardCheckbox} from '@/components/inputs/CardCheckbox';
import {Answer} from '@/modules/interview/hooks/useGetQuestionWithAnswers';
import {SustainLevelFormValues} from '@/modules/interview/types/sustainLevel';

type Props = {
  control: Control<SustainLevelFormValues>;
  answer: Answer;
};

export const SustainLevelCheckboxControl = ({
  children,
  control,
  answer,
}: PropsWithChildren<Props>) => {
  const {
    field: {onChange: onLevelsChange, value: levels},
  } = useController<SustainLevelFormValues>({name: 'SUSTAIN_LEVEL', control});
  const {
    field: {onChange: onPercentageChange},
  } = useController<SustainLevelFormValues>({name: 'SUSTAIN_PERCENTAGE', control});
  const {
    field: {onChange: onFactorsChange},
  } = useController<SustainLevelFormValues>({name: 'SUSTAIN_FACTOR', control});

  const handleCheckboxPressed = useCallback(
    (code: string) => (checked: boolean) => {
      let newLevels = [...(levels ?? [])];

      if (checked) {
        newLevels.push(code);
      } else {
        newLevels = newLevels.filter(level => level !== code);

        if (code === 'SUSTAIN_LEVEL_SIMPLE') {
          onPercentageChange(undefined);
        }

        if (code === 'SUSTAIN_LEVEL_PAI') {
          onFactorsChange(undefined);
        }
      }

      onLevelsChange(newLevels);
    },
    [levels, levels, onFactorsChange, onPercentageChange]
  );

  const checked = useMemo(() => levels?.includes(answer.code) ?? false, [answer.code, levels]);

  return (
    <CardCheckbox
      label={answer.label}
      checked={checked}
      onCheckedChange={handleCheckboxPressed(answer.code)}
    >
      {checked && children}
    </CardCheckbox>
  );
};
