import {yupResolver} from '@hookform/resolvers/yup';
import {captureException} from '@sentry/react-native';
import {useTranslate} from '@tolgee/react';
import {toast} from 'burnt';
import {Stack, useRouter} from 'expo-router';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Platform} from 'react-native';
import {View, XStack, YStack, useMedia} from 'tamagui';

import {Button} from '@/components/buttons/Button';
import {Card} from '@/components/cards/Card';
import SvgFile from '@/components/icons/SvgFile';
import {RadioButton} from '@/components/inputs/RadioButton';
import {FormAmountInput} from '@/components/inputs/form/FormAmountInput';
import {FormDateInput} from '@/components/inputs/form/FormDateInput';
import {FormSelectInput} from '@/components/inputs/form/FormSelectInput';
import {FormTextInput} from '@/components/inputs/form/FormTextInput';
import {FormTitleSelect} from '@/components/inputs/form/FormTitleSelect';
import {SelectInput} from '@/components/inputs/select-input/SelectInput';
import {Label2, Label3, Label3sm2} from '@/components/texts/Label';
import {Paragraph3} from '@/components/texts/Paragraph';
import {useValidations} from '@/hooks/useValidations';
import {MFAModal} from '@/modules/mfa/components/MFAModal';
import {Header} from '@/modules/navigation/components/header/Header';
import {CustodianCodeToBank} from '@/modules/profile/components/taxes/PreviousTaxItemRow';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useGetCustomerAccountsQuery} from '@/store/queries/customersApi';
import {
  useCreateTaxExemptionOrderChangeMutation,
  useGetSelfUserQuery,
} from '@/store/queries/usersApi';
import {TaxExemptionOrderChangeRequest, custodian} from '@/types/api/users';
import {MFAActionType} from '@/types/mfaAction';

export default function NewTaxExemption() {
  const {t} = useTranslate();
  const {data: user} = useGetSelfUserQuery();
  const {data: customers} = useGetCustomerAccountsQuery();
  const currYear = new Date().getFullYear();
  const [checked, setChecked] = useState(0);
  const [createTaxExemptionOrderChange] = useCreateTaxExemptionOrderChangeMutation();
  const [custodian, setCustodian] = useState<string>();
  const {taxExemptionCreationSchema} = useValidations();
  const [action, setAction] = useState<MFAActionType | null>(null);
  const router = useRouter();
  const posthog = usePostHog();
  const media = useMedia();
  const [usedAmount, setUsedAmount] = useState(0);
  const [singleAlreadyCreated, setSingleAlreadyCreated] = useState(false);

  const validStartItems = useMemo(() => {
    const items = [];
    for (let i = currYear; i <= currYear + 4; i++) {
      const item = '01/01/' + i.toString();
      items.push({name: item, key: i.toString()});
    }
    return items;
  }, [currYear]);

  const validUntilItems = useMemo(() => {
    const items = [];
    items.push({name: t('TAXES.TAX-EXEMPTION.INTIL'), key: 'infinite'});
    for (let i = currYear; i <= currYear + 4; i++) {
      const item = '31/12/' + i.toString();
      items.push({name: item, key: i.toString()});
    }
    return items;
  }, [currYear, t]);

  const [withSpouse, setWithSpouse] = useState(false);

  const [maxAmount, setMaxAmount] = useState(1000);

  const {handleSubmit, control, setValue, getValues, reset, trigger} = useForm({
    resolver: yupResolver(taxExemptionCreationSchema),
    mode: 'onChange',
    context: {withSpouse, usedAmount},
    defaultValues: {validFrom: validStartItems[0].key, validUntil: validUntilItems[0].key},
  });

  const custodianItems = useMemo(() => {
    const items: any[] = [];
    const uniqueBanks = new Set(customers?.items?.map(item => item.custodian));
    uniqueBanks.forEach(bank => {
      items.push({name: CustodianCodeToBank[bank], key: bank});
    });
    if (items.length > 0) {
      setCustodian(items[0].key);
    }
    return items;
  }, [customers?.items]);

  useEffect(() => {
    if (user) {
      const taxExemptionOrder = user.taxExemptionOrders?.find(item => item.custodian === custodian);
      setUsedAmount(taxExemptionOrder?.usedAmount ?? 0);
      if (
        taxExemptionOrder?.usedAmount &&
        taxExemptionOrder?.usedAmount > 0 &&
        taxExemptionOrder?.category === 'SINGLE'
      ) {
        setSingleAlreadyCreated(true);
      } else {
        setSingleAlreadyCreated(false);
      }
    }
  }, [user, usedAmount, custodian]);

  useEffect(() => {
    if (checked === 0) {
      setValue('amount', maxAmount);
    } else {
      setValue('amount', getValues('amount') ?? 0);
    }
  }, [checked, getValues, maxAmount, setValue, withSpouse]);

  useEffect(() => {
    setMaxAmount(withSpouse ? 2000 : 1000);
    trigger('amount');
  }, [getValues, trigger, withSpouse]);

  useEffect(() => {
    setValue('custodian', custodian || '');
    if (custodian === 'UPVEST') {
      setWithSpouse(false);
    }
  }, [custodian, setValue, trigger]);

  const onSuccessTaxExemptionOrderChange = useCallback(() => {
    toast({preset: 'done', title: t('TAXES.TAX-EXEMPTION.NEW.SUCCESS')});
    if (router.canGoBack()) {
      router.back();
    } else {
      router.replace('/profile/taxes');
    }
  }, [router, t]);

  const onSuccessMFA = useCallback(async () => {
    if (!user?.id) return;
    const payload: TaxExemptionOrderChangeRequest & {userID: string} = {
      amount: getValues('amount') ?? 0,
      custodian: getValues('custodian') as custodian,
      startYear: getValues('validFrom'),
      endYear: getValues('validUntil') === 'infinite' ? undefined : getValues('validUntil'),
      userID: user?.id.toString(),
    };

    if (withSpouse) {
      payload.spouse = {
        title: getValues('spouseTitle') ?? '',
        dateOfBirth: getValues('spouseDateBirth') ?? '',
        firstName: getValues('spouseFirstName') ?? '',
        lastName: getValues('spouseLastName') ?? '',
        germanTin: getValues('spouseTaxID') ?? '',
      };
    }
    try {
      await createTaxExemptionOrderChange(payload).unwrap();
      onSuccessTaxExemptionOrderChange();
    } catch (error) {
      toast({
        preset: 'error',
        title: t('SNACKBAR.ERROR_TITLE'),
      });
      captureException(error);
    }
  }, [
    createTaxExemptionOrderChange,
    getValues,
    onSuccessTaxExemptionOrderChange,
    t,
    user?.id,
    withSpouse,
  ]);

  const sendNewTaxExemtion = useCallback(() => {
    setAction({
      name: 'one-time-code',
      onSuccess: onSuccessMFA,
    });
  }, [onSuccessMFA]);

  const cancel = useCallback(() => {
    setWithSpouse(false);
    reset();
    router.back();
  }, [reset, router]);

  return (
    <YStack gap="$5">
      <Stack.Screen
        options={{
          headerShown: true,
          header: () => (
            <Header title={t('TAXES.TAX-EXEMPTION.NEW')} backButton={Platform.OS !== 'web'} />
          ),
        }}
      />

      <Card padding="$5" gap="$6">
        {custodianItems.length > 1 && (
          <YStack gap="$3">
            <Label3 color="$neutral400">{t('TAXES.TAX-EXEMPTION.CUSTODIAN')}</Label3>
            <SelectInput
              items={custodianItems}
              placeholder={custodianItems[0].name}
              onSelect={val => setCustodian(val)}
              selectedItem={custodian}
              wrapperProps={{bordered: true}}
            />
          </YStack>
        )}
        <Label3>{t('TAXES.TAX-EXEMPTION.SPOUSE.START')}</Label3>
        <YStack gap="$3">
          <XStack gap="$3" onPress={() => setChecked(0)} cursor="pointer">
            <RadioButton
              checked={checked === 0}
              onCheckedChange={() => {
                setChecked(0);
              }}
            />
            <Label2 flexShrink={1}>{t('TAXES.TAX-EXEMPTION.FULL_AMOUNT', {maxAmount})}</Label2>
          </XStack>
          <View gap="$2" flexDirection="column">
            <XStack gap="$3" onPress={() => setChecked(1)} cursor="pointer">
              <RadioButton
                checked={checked === 1}
                onCheckedChange={() => {
                  setChecked(1);
                }}
              />
              <Label2 alignSelf="center">
                {t('TAXES.TAX-EXEMPTION.CUSTOM_AMOUNT', {maxAmount})}
              </Label2>
            </XStack>
            {checked === 1 && (
              <View alignSelf="flex-start" minWidth={300} $sm={{alignSelf: 'stretch'}}>
                <FormAmountInput control={control} name="amount" label="" bordered />
              </View>
            )}
          </View>
        </YStack>
        <View gap="$3" flexDirection={media.sm ? 'column' : 'row'}>
          <FormSelectInput
            items={validStartItems}
            control={control}
            name="validFrom"
            label={t('TAXES.TAX-EXEMPTION.VALID-FROM')}
            bordered
          />
          <FormSelectInput
            items={validUntilItems}
            control={control}
            name="validUntil"
            label={t('TAXES.TAX-EXEMPTION.VALID-UNTIL')}
            bordered
          />
        </View>
        <View gap="$3" flexDirection={media.sm ? 'column' : 'row'}>
          <Button
            onPress={handleSubmit(sendNewTaxExemtion, data => {
              posthog?.capture('form_submit_failed', data);
            })}
          >
            {t('NAVIGATION.PROFILE.TAXES.BUTTON.SAVE-CHANGES')}
          </Button>
          <Button secondary onPress={cancel}>
            {t('NAVIGATION.PROFILE.TAXES.BUTTON.CANCEL')}
          </Button>
        </View>
      </Card>

      <Card padding="$5" gap="$6">
        <Label2>
          {withSpouse
            ? t('TAXES.TAX-EXEMPTION.SPOUSE.DETAILS')
            : t('TAXES.TAX-EXEMPTION.SPOUSE.ASK')}
        </Label2>
        {!withSpouse && (
          <XStack
            gap="$2"
            backgroundColor="$neutralBG"
            borderRadius="$3"
            padding="$4"
            alignSelf="flex-start"
            maxWidth={600}
          >
            <YStack>
              <SvgFile name="info" color="$primary" size={20} />
            </YStack>
            <YStack gap="$3" flexShrink={1}>
              <Label3sm2 color="$primary">{t('TAXES.TAX-EXEMPTION.SPOUSE.NOTE.TITLE')}</Label3sm2>
              <Paragraph3>{t('TAXES.TAX-EXEMPTION.SPOUSE.NOTE.DESC_1')}</Paragraph3>
            </YStack>
          </XStack>
        )}
        {withSpouse && (
          <YStack gap="$6">
            <View gap="$3" flexDirection={media.sm ? 'column' : 'row'}>
              <FormTitleSelect
                label={t('INTERVIEW.SALUTATION')}
                control={control}
                name="spouseTitle"
                bordered
              />
              <FormTextInput
                control={control}
                label={t('TAXES.TAX-EXEMPTION.SPOUSE.FIRST-NAME')}
                name="spouseFirstName"
                bordered
              />
              <FormTextInput
                control={control}
                label={t('TAXES.TAX-EXEMPTION.SPOUSE.LAST-NAME')}
                name="spouseLastName"
                bordered
              />
            </View>
            <FormDateInput
              label={t('TAXES.TAX-EXEMPTION.SPOUSE.BIRTH-DATE')}
              control={control}
              name="spouseDateBirth"
              bordered
            />
            <FormTextInput
              control={control}
              label={t('TAXES.TAX-EXEMPTION.SPOUSE.TAX-ID')}
              name="spouseTaxID"
              bordered
            />
          </YStack>
        )}
        <Button
          secondary
          onPress={() => {
            setWithSpouse(spouseToggle => !spouseToggle);
          }}
          disabled={
            (custodian === 'UPVEST' && t('TAXES.TAX-EXEMPTION.SPOUSE.UPVEST-DISABLED')) ||
            (singleAlreadyCreated && t('TAXES.TAX-EXEMPTION.SPOUSE.SINGLE-ALREADY-CREATED'))
          }
        >
          {withSpouse
            ? t('TAXES.TAX-EXEMPTION.SPOUSE.REMOVE')
            : t('TAXES.TAX-EXEMPTION.SPOUSE.ADD')}
        </Button>
      </Card>
      <MFAModal action={action} />
    </YStack>
  );
}
