import React, {PropsWithChildren, useEffect} from 'react';
import Animated, {
  ReduceMotion,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withRepeat,
  withSequence,
  withTiming,
} from 'react-native-reanimated';

export function AnimatedBounceView({children}: PropsWithChildren) {
  const sharedValue = useSharedValue(0);

  useEffect(() => {
    sharedValue.value = withRepeat(
      withSequence(
        withDelay(1000, withTiming(15, {duration: 750, reduceMotion: ReduceMotion.Never})),
        withTiming(0, {duration: 750, reduceMotion: ReduceMotion.Never})
      ),
      0,
      false
    );
  }, []);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      bottom: sharedValue.value,
    };
  });
  return <Animated.View style={animatedStyle}>{children}</Animated.View>;
}
