import {useTranslate} from '@tolgee/react';
import {Stack} from 'expo-router';
import {useRef, useState} from 'react';
import {Platform} from 'react-native';
import {YStack} from 'tamagui';

import {Button} from '@/components/buttons/Button';
import {ResponsiveScrollView} from '@/components/views/ResponsiveScrollView';
import {useCustomerIdParamOrRedirect} from '@/hooks/useCustomerIdParamOrRedirect';
import {WebFooter} from '@/modules/navigation/components/footer/WebFooter';
import {Header} from '@/modules/navigation/components/header/Header';
import {OrderSummaryModal} from '@/modules/orders/components/OrderSummaryModal';
import {ThemeWithdrawalCard} from '@/modules/orders/components/Theme/ThemeWithdrawalCard';
import {WarningBox} from '@/modules/orders/components/WarningBox';
import {useGetSpecificOrderConstraint} from '@/modules/orders/hooks/useGetSpecificOrderConstraint';
import {useGetThemesFromAllocation} from '@/modules/orders/hooks/useGetThemesFromAllocation';
import {useOrderContext} from '@/modules/orders/hooks/useOrderContext';
import {ModalRef} from '@/types/modal';

export default function WithdrawalMotivePage() {
  const {t} = useTranslate();

  const [value, setValue] = useState<number>(0);
  const [error, setError] = useState(false);
  const {themeWithdrawalDistribution} = useOrderContext();
  const customerId = useCustomerIdParamOrRedirect();
  const themes = useGetThemesFromAllocation(customerId);
  const {constraint: {canBeCreated, firstViolationLabel} = {}, loading} =
    useGetSpecificOrderConstraint(customerId, 'THEME_WITHDRAWAL');
  const modalRef = useRef<ModalRef>(null);
  const goToSummary = () => {
    modalRef.current?.open(true);
  };

  return (
    <ResponsiveScrollView>
      <YStack gap="$5">
        <Stack.Screen
          options={{
            headerShown: true,
            header: () => (
              <Header
                title={t('ORDER_SUMMARY.WITHDRAWAL.TITLE')}
                backButton={Platform.OS !== 'web'}
              />
            ),
          }}
        />
        <ThemeWithdrawalCard
          customerId={customerId}
          onValueChange={sum => setValue(sum)}
          onError={setError}
          themes={themes}
        />
        <WarningBox text={firstViolationLabel} show={!loading && !canBeCreated} />
        {themes && themes?.length > 0 && (
          <Button onPress={goToSummary} disabled={!canBeCreated || error || value === 0}>
            {t('CONTINUE')}
          </Button>
        )}
      </YStack>
      <OrderSummaryModal
        ref={modalRef}
        customerId={customerId}
        orderType="THEME_WITHDRAWAL"
        value={value}
        themeWithdrawalDistribution={themeWithdrawalDistribution}
      />
      <WebFooter />
    </ResponsiveScrollView>
  );
}
