import {SquarePlusIcon} from '@/components/icons/SquarePlusIcon';
import {THEME_ICON_SIZE} from '@/components/icons/ThemeIcon';
import {forwardRef, PropsWithChildren} from 'react';
import {GetProps, View, XStack} from 'tamagui';

export const ThemeItemDepositAddButton = forwardRef<
  any,
  PropsWithChildren<GetProps<typeof XStack>>
>(({children, ...props}, ref) => {
  return (
    <XStack
      ref={ref}
      animation="slow"
      enterStyle={{
        opacity: 0,
        translateY: -50,
      }}
      exitStyle={{
        opacity: 0,
        translateY: -50,
        height: 0,
      }}
      hoverStyle={{
        opacity: 0.85,
      }}
      pressStyle={{
        scale: 0.97,
      }}
      userSelect="none"
      cursor="pointer"
      alignItems="center"
      gap="$2"
      padding="$2"
      {...props}
    >
      <SquarePlusIcon onSurface />
      <View
        flex={1}
        height={THEME_ICON_SIZE}
        backgroundColor="$neutral200"
        borderRadius="$1"
        overflow="hidden"
        justifyContent="center"
        paddingHorizontal="$2"
      >
        {children}
      </View>
    </XStack>
  );
});
