import {Stack} from 'expo-router';
import {useMemo} from 'react';
import {GetProps, useTheme} from 'tamagui';

export const useScreenOptions = (opt?: GetProps<typeof Stack>['screenOptions']) => {
  const theme = useTheme();

  return useMemo<GetProps<typeof Stack>['screenOptions']>(
    () => ({
      headerShown: true,
      fullScreenGestureEnabled: true,
      headerShadowVisible: false,
      headerStyle: {backgroundColor: theme.neutralBG.val},
      ...opt,
    }),
    [opt, theme]
  );
};
