import libphonenumber from 'google-libphonenumber';

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

export function convertToE164PhoneNumber(phoneNumber: string): string | null {
  try {
    const phone = phoneUtil.parse(phoneNumber.replace(/\s/, ''));
    if (!phoneUtil.isValidNumber(phone)) throw new Error(`Invalid phone number: ${phoneNumber}`);
    return phoneUtil.format(phone, libphonenumber.PhoneNumberFormat.E164);
  } catch (e) {
    return null;
  }
}
