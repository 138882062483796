import {Heading5} from '@/components/texts/Heading';
import {InputErrorMessage} from '@/components/texts/InputErrorMessage';
import {isUpvestCustomer} from '@/helpers/isUpvestCustomer';
import {DepositMethodAccordionRow} from '@/modules/orders/components/DepositMethod/DepositMethodAccordionRow';
import {DepositMethodType} from '@/modules/orders/types/DepositMethod';
import {useGetCustomerAccountQuery} from '@/store/queries/customersApi';
import {useTranslate} from '@tolgee/react';
import React from 'react';
import {Control, FieldValues, Path, useController} from 'react-hook-form';
import {Accordion, YStack} from 'tamagui';

type DepositMethodAccordionProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  customerId: string;
};

export function DepositMethodAccordion<T extends FieldValues>({
  control,
  name,
  customerId,
}: DepositMethodAccordionProps<T>) {
  const {t} = useTranslate();

  const {
    field,
    fieldState: {error},
  } = useController({
    name,
    control,
  });

  const {data: customer} = useGetCustomerAccountQuery(customerId);
  const isUpvest = isUpvestCustomer(customer);

  return (
    <YStack gap="$4">
      <Heading5>{t('ORDERS.DEPOSIT_METHOD.SELECT_TITLE')}</Heading5>
      <Accordion
        overflow="hidden"
        type="single"
        onValueChange={(value: DepositMethodType) => {
          field.onChange(value);
        }}
        defaultValue="direct"
        gap="$4"
      >
        <DepositMethodAccordionRow
          depositMethod="direct"
          customerId={customerId}
          selected={field.value === 'direct'}
          variant="radio"
          control={control as Control}
        />

        <DepositMethodAccordionRow
          depositMethod="wire"
          customerId={customerId}
          selected={field.value === 'wire'}
          variant="radio"
          control={control as Control}
        />

        {!isUpvest && (
          <DepositMethodAccordionRow
            depositMethod="transfer"
            customerId={customerId}
            selected={field.value === 'transfer'}
            variant="radio"
            control={control as Control}
          />
        )}
      </Accordion>
      <InputErrorMessage error={error?.message} />
    </YStack>
  );
}
