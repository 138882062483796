import {PropsWithChildren} from 'react';
import {Provider as ReduxProvider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';

import {persistor, store} from '@/store';

export const StoreProvider = ({children}: PropsWithChildren) => (
  <ReduxProvider store={store}>
    <PersistGate persistor={persistor}>{children}</PersistGate>
  </ReduxProvider>
);
