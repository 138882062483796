import {FormCheckboxGroupInput} from '@/components/inputs/form/FormCheckboxGroupInput';
import {Heading4} from '@/components/texts/Heading';
import {InputErrorMessage} from '@/components/texts/InputErrorMessage';
import {Label2} from '@/components/texts/Label';
import {Skeleton} from '@/components/views/Skeleton';
import {useValidations} from '@/hooks/useValidations';
import {InterviewContinueButton} from '@/modules/interview/components/InterviewContinueButton';
import useExistingStoreAnswers from '@/modules/interview/hooks/useExistingStoreAnwers';
import {useGetQuestionWithAnswers} from '@/modules/interview/hooks/useGetQuestionWithAnswers';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useAppDispatch} from '@/store';
import {updateInterview} from '@/store/reducers/interview';
import {yupResolver} from '@hookform/resolvers/yup';
import {Href, useRouter} from 'expo-router';
import React, {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {YStack} from 'tamagui';

type SingleAnswerProps = {
  questionCode: string;
  href?: Href;
  onSubmit?: (value: string) => void;
};

export function SingleAnswer({questionCode, href, onSubmit: propOnSubmit}: SingleAnswerProps) {
  const defaultValues = useExistingStoreAnswers(questionCode);
  const {question, answers} = useGetQuestionWithAnswers(questionCode);

  const {interviewSingleAnswerSchema} = useValidations();

  const dispatch = useAppDispatch();
  const posthog = usePostHog();

  const router = useRouter();

  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(interviewSingleAnswerSchema(questionCode)),
    defaultValues,
  });

  const onSubmit = useCallback(
    (value: {[x: string]: any[] | undefined}) => {
      const res = value[questionCode]?.[0];

      if (!res) return;

      dispatch(updateInterview({[questionCode]: res}));

      if (propOnSubmit) {
        propOnSubmit(res);
      } else if (href) {
        router.navigate(href);
      }
    },
    [dispatch, href, questionCode, router, propOnSubmit]
  );

  if (!answers || !question) {
    return (
      <YStack gap="$4">
        <Skeleton width="100%" height={70} />
        <Skeleton width="75%" height={20} />
        <Skeleton width="75%" height={20} />
        <Skeleton width="100%" height={75} />
        <Skeleton width="100%" height={75} />
        <Skeleton width="100%" height={75} />
        <Skeleton width="100%" height={75} />
        <Skeleton width="100%" height={50} />
      </YStack>
    );
  }

  return (
    <YStack gap="$4">
      <YStack gap="$2">
        <Heading4 variant="medium">{question.label}</Heading4>
        {question.description && <Label2 color="$neutral500">{question.description}</Label2>}
      </YStack>
      <FormCheckboxGroupInput
        variant="radio"
        control={control}
        name={questionCode}
        items={answers}
      />
      <InputErrorMessage error={errors[questionCode]?.message} />
      <InterviewContinueButton
        onPress={handleSubmit(onSubmit, data => {
          posthog?.capture('form_submit_failed', data);
        })}
      />
    </YStack>
  );
}
