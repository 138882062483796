import {useTranslate} from '@tolgee/react';
import {forwardRef, useCallback, useMemo} from 'react';
import {ScrollView, XStack, YStack} from 'tamagui';

import {Modal} from '@/components/modal/Modal';
import {Heading5} from '@/components/texts/Heading';
import {Paragraph2, Paragraph3} from '@/components/texts/Paragraph';
import {ModalRef} from '@/types/modal';

export const PoliticallyExposedPersonModal = forwardRef<ModalRef>((_, ref) => {
  const {t} = useTranslate();

  const points = useMemo<string[]>(
    () => [
      t('PERSONAL_DETAILS.HEADS_OF_STATE'),
      t('PERSONAL_DETAILS.MEMBERS_OF_PARLIAMENT'),
      t('PERSONAL_DETAILS.MEMBERS_OF_GOVERNING'),
      t('PERSONAL_DETAILS.MEMBERS_OF_SUPREME_COURTS'),
      t('PERSONAL_DETAILS.MEMBERS_OF_GOVERNING_BODIES_COURTS_AUDIT'),
      t('PERSONAL_DETAILS.MEMBERS_OF_GOVERNING_BODIES_CENTRAL_BANKS'),
      t('PERSONAL_DETAILS.AMBASSADORS'),
      t('PERSONAL_DETAILS.MEMBERS_OF_ADMINISTRATIVE'),
      t('PERSONAL_DETAILS.DIRECTORS_AND_MEMBERS_OF_GOVERNING'),
    ],
    [t]
  );

  const getNumberText = useCallback(
    (value: number) => (value < 10 ? `0${value}.` : `${value}.`),
    []
  );

  return (
    <Modal ref={ref}>
      <Modal.Content typeContainer="SCROLL" side>
        <ScrollView>
          <YStack marginBottom="$3">
            <Modal.Title asChild="except-style">
              <Heading5 variant="medium">
                {t('PERSONAL_DETAILS.TITLE_POLITICALLY_EXPOSED_PERSON')}
              </Heading5>
            </Modal.Title>
          </YStack>
          <YStack marginBottom="$4">
            <Paragraph2 color="$neutral400">
              {t('PERSONAL_DETAILS.POLITICALLY_EXPOSED_PERSON_PART_1')}
            </Paragraph2>
            <Paragraph2 color="$neutral400">
              {t('PERSONAL_DETAILS.POLITICALLY_EXPOSED_PERSON_PART_2')}
            </Paragraph2>
          </YStack>
          <YStack marginBottom="$2">
            <Paragraph2 variant="medium">{t('PERSONAL_DETAILS.POSITIONS_AND_OFFICES')}</Paragraph2>
          </YStack>
          <YStack gap="$4">
            {points.map((key, index) => (
              <XStack key={index} gap="$2">
                <Paragraph3 variant="medium" color="$primary">
                  {getNumberText(index + 1)}
                </Paragraph3>
                <Paragraph3 color="$neutral400">{key}</Paragraph3>
              </XStack>
            ))}
          </YStack>
        </ScrollView>
      </Modal.Content>
    </Modal>
  );
});
