import {useTranslate} from '@tolgee/react';
import {Stack} from 'expo-router';
import React from 'react';
import {Platform} from 'react-native';
import {YStack} from 'tamagui';

import {ResponsiveScrollView} from '@/components/views/ResponsiveScrollView';
import {WebFooter} from '@/modules/navigation/components/footer/WebFooter';
import {Header} from '@/modules/navigation/components/header/Header';
import {AddressDataCard} from '@/modules/profile/components/AddressDataCard';
import {PersonalDataCard} from '@/modules/profile/components/PersonalDataCard';
import {PhoneNumberDataCard} from '@/modules/profile/components/PhoneNumberDataCard';

export default function TabPersonalData() {
  const {t} = useTranslate();

  return (
    <ResponsiveScrollView useKeyboardAwareScrollView>
      <YStack gap="$5">
        <Stack.Screen
          options={{
            headerShown: true,
            header: () => (
              <Header title={t('NAVIGATION.PROFILE.TITLE')} backButton={Platform.OS !== 'web'} />
            ),
          }}
        />
        <PersonalDataCard />
        <PhoneNumberDataCard />
        <AddressDataCard />
      </YStack>
      <WebFooter withCalendly />
    </ResponsiveScrollView>
  );
}
