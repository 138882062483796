import SvgFile from '@/components/icons/SvgFile';
import {FormCustomCheckbox} from '@/components/inputs/form/FormCustomCheckbox';
import {Modal} from '@/components/modal/Modal';
import {Label2} from '@/components/texts/Label';
import {AgreementsSchemaType} from '@/hooks/useValidations';
import {GinmonSepaContent} from '@/modules/onboarding/components/forms/agreements/GinmonSepaContent';
import {ShortenedCustomerInformationResponse} from '@/types/api/customers.v2';
import {useTranslate} from '@tolgee/react';
import {openBrowserAsync} from 'expo-web-browser';
import {Control} from 'react-hook-form';
import {XStack, YStack} from 'tamagui';

export function GinmonAgreement({
  control,
  product,
  isUpvest,
}: {
  control: Control<AgreementsSchemaType>;
  product: ShortenedCustomerInformationResponse['product'];
  isUpvest: boolean;
}) {
  const {t} = useTranslate();
  return (
    <FormCustomCheckbox
      control={control}
      name="ginmonTerms"
      label={<Label2 variant="medium">{t('ACCOUNT_SETUP.FROM_GMBH')}</Label2>}
    >
      <YStack paddingLeft={36} gap="$4">
        <XStack
          gap="$2"
          cursor="pointer"
          onPress={() => openBrowserAsync(process.env.EXPO_PUBLIC_AGREEMENT_POWER_OF_ATTORNEY)}
        >
          <SvgFile name="document" size={20} color="$neutral700" />
          <Label2 variant="medium">
            <Label2 variant="medium" color="$primary">
              {t('ACCOUNT_SETUP.AGREEMENTS.GINMON.CUSTOMER_DOC')}
            </Label2>
            {` ${t('ACCOUNT_SETUP.AGREEMENTS.GINMON.CUSTOMER_DOC_SUFFIX')}`}
          </Label2>
        </XStack>

        <YStack paddingLeft={36} gap="$2">
          <Label2 color="$neutral700">{`• ${t('ACCOUNT_SETUP.AGREEMENTS.GINMON.NOTE_1')}`}</Label2>
          <Label2 color="$neutral700">{`• ${t('ACCOUNT_SETUP.AGREEMENT.GINMON.NOTE_2')}`}</Label2>
          <Label2 color="$neutral700">{`• ${t('ACCOUNT_SETUP.AGREEMENT.GINMON.NOTE_3')}`}</Label2>
          <Label2 color="$neutral700">{`• ${t('ACCOUNT_SETUP.AGREEMENT.GINMON.NOTE_4')}`}</Label2>
          <Label2 color="$neutral700">{`• ${t('ACCOUNT_SETUP.AGREEMENT.GINMON.NOTE_5')}`}</Label2>
          <Label2 color="$neutral700">{`• ${t('ACCOUNT_SETUP.AGREEMENT.GINMON.NOTE_6')}`}</Label2>
          <Label2 color="$neutral700">{`• ${t('ACCOUNT_SETUP.AGREEMENT.GINMON.NOTE_7')}`}</Label2>
        </YStack>

        {!isUpvest && (
          <Modal>
            <Modal.Trigger asChild>
              <XStack gap="$2">
                <SvgFile name="document" size={20} color="$neutral700" />
                <Label2 variant="medium" color="$primary">
                  {t('ACCOUNT_SETUP.AGREEMENTS.GINMON.SEPA')}
                </Label2>
              </XStack>
            </Modal.Trigger>
            <Modal.Content side>
              <GinmonSepaContent />
            </Modal.Content>
          </Modal>
        )}

        {product === 'VL_ACCOUNT' && (
          <XStack
            gap="$2"
            cursor="pointer"
            onPress={() =>
              openBrowserAsync(process.env.EXPO_PUBLIC_AGREEMENT_ADDITIONAL_VL_INFORMATION)
            }
          >
            <SvgFile name="document" size={20} color="$neutral700" />
            <Label2 variant="medium" color="$primary" flexShrink={1}>
              {t('ACCOUNT_SETUP.AGREEMENTS.GINMON.SUP')}
              <Label2 color="$neutral700">{` ${t('ACCOUNT_SETUP.AGREEMENTS.GINMON.SUP_SUFFIX')}`}</Label2>
            </Label2>
          </XStack>
        )}
      </YStack>
    </FormCustomCheckbox>
  );
}
