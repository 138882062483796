import {Image} from 'expo-image';
import React, {useEffect} from 'react';
import Animated, {SharedValue, useAnimatedStyle, useSharedValue} from 'react-native-reanimated';
import {useTheme, useThemeName, useWindowDimensions} from 'tamagui';

type AnimatedSplashLoaderProps = {
  logoPosition?: SharedValue<number>;
  viewKey?: string;
};

export function AnimatedSplashLoader({logoPosition, viewKey}: AnimatedSplashLoaderProps) {
  const themeName = useThemeName();
  const theme = useTheme();
  const {width, height} = useWindowDimensions();
  const splashOpacity = useSharedValue(1);
  const measuredHeight = useSharedValue(height);

  const animatedStyle = useAnimatedStyle(() => {
    if (logoPosition && logoPosition.value !== 0) {
      return {
        top: logoPosition.value,
      };
    } else {
      return {
        top: measuredHeight.value / 2 - 50,
      };
    }
  }, [measuredHeight, logoPosition]);

  useEffect(() => {
    measuredHeight.value = height;
  }, [height, measuredHeight]);

  return (
    <Animated.View
      key={viewKey}
      style={[
        {
          opacity: splashOpacity,
          top: 0,
          left: 0,
          position: 'absolute',
          height,
          width,
          backgroundColor: theme.neutralBG.val,
        },
      ]}
    >
      <Animated.View style={[{alignSelf: 'center'}, animatedStyle]}>
        <Image
          source={
            themeName === 'light'
              ? require('@/assets/images/splash.png')
              : require('@/assets/images/splash-dark.png')
          }
          contentFit="contain"
          style={{width: 100, height: 100, alignSelf: 'center'}}
        />
      </Animated.View>
    </Animated.View>
  );
}
