import {useTranslate} from '@tolgee/react';
import {YStack} from 'tamagui';

import AnlageChart from '@/assets/svgs/anlage-chart.svg';
import {Heading6} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';

export const InvestTipModalContent = () => {
  const {t} = useTranslate();

  return (
    <YStack gap="$5" marginVertical="$5">
      <Heading6 variant="medium">{t('INVESTMENT_PLANNER.HOW_MUCH_I_SHALL_INVEST.HEADER')}</Heading6>
      <Paragraph2 color="$neutral400">
        {t('INVESTMENT_PLANNER.HOW_MUCH_I_SHALL_INVEST.TEXT_1')}
      </Paragraph2>
      <AnlageChart width="100%" />
      <Paragraph2 color="$neutral400">
        {t('INVESTMENT_PLANNER.HOW_MUCH_I_SHALL_INVEST.TEXT_2')}
      </Paragraph2>
      <Paragraph2 color="$neutral400">
        {t('INVESTMENT_PLANNER.HOW_MUCH_I_SHALL_INVEST.TEXT_3')}
      </Paragraph2>
    </YStack>
  );
};
