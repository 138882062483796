import SvgFile from '@/components/icons/SvgFile';
import {SideNavigation} from '@/modules/navigation/components/SideNavigation';
import {useTranslate} from '@tolgee/react';
import {Tabs} from 'expo-router';
import {isTouchable, useMedia} from 'tamagui';

export default function TabLayout() {
  const {t} = useTranslate();
  const media = useMedia();
  return (
    <SideNavigation>
      <Tabs
        screenOptions={{
          headerShown: false,
          tabBarStyle:
            media.sm || isTouchable
              ? {}
              : {
                  display: 'none',
                },
        }}
      >
        <Tabs.Screen
          name="overview"
          options={{
            title: t('NAVIGATION.OVERVIEW'),
            tabBarIcon: ({color}) => <SvgFile size={28} name="overview" color={color} />,
          }}
        />
        <Tabs.Screen
          name="recommendations"
          options={{
            title: t('NAVIGATION.RECOMMENDATIONS'),
            tabBarIcon: ({color}) => <SvgFile size={28} name="recommendations" color={color} />,
          }}
        />
        <Tabs.Screen
          name="profile"
          options={{
            title: t('NAVIGATION.PROFILE'),
            tabBarIcon: ({color}) => <SvgFile size={28} name="profile" color={color} />,
          }}
        />
      </Tabs>
    </SideNavigation>
  );
}
