import {Button} from '@/components/buttons/Button';
import {ReferenceAccountInformation} from '@/components/cards/ReferenceAccountInformation';
import {Label3} from '@/components/texts/Label';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {WithLabel} from '@/components/texts/WithLabel';
import {Skeleton} from '@/components/views/Skeleton';
import {useGetPerformanceSummaryQuery} from '@/store/queries/apeironApi';
import {
  useGetCustomerAccountQuery,
  usePostCustomerOrderMutation,
} from '@/store/queries/customersApi';
import {useTranslate} from '@tolgee/react';
import {toast} from 'burnt';
import {router} from 'expo-router';
import React, {useCallback} from 'react';
import {View, YStack} from 'tamagui';

type WithdrawalOrderSummaryCardProps = {
  customerId: string;
  value?: number;
  reason: string;
  onFinished?: () => void;
};

export function WithdrawalOrderSummaryCard({
  customerId,
  value,
  reason,
  onFinished,
}: WithdrawalOrderSummaryCardProps) {
  const {t} = useTranslate();
  const {data: customer} = useGetCustomerAccountQuery(customerId);
  const {data: performanceSummary} = useGetPerformanceSummaryQuery({customerId});
  const [postOrder] = usePostCustomerOrderMutation();

  const submitOrder = useCallback(async () => {
    if (value && reason && customer) {
      const res = await postOrder({
        orderType: 'WITHDRAWAL',
        customerId,
        amount: value,
        reason,
      });
      if (res.error) {
        toast({
          preset: 'error',
          title: t('SNACKBAR.ORDER-NOT-CREATED'),
        });
      } else {
        router.dismissTo({pathname: '/overview/[customerId]', params: {customerId}});
        toast({
          preset: 'done',
          title: t('SNACKBAR.WITHDRAWAL-CREATED'),
        });
        onFinished?.();
      }
    }
  }, [value, reason, customer, postOrder, customerId, t, onFinished]);
  // TODO: summary FRONTEND-335
  return (
    <YStack gap="$4">
      <WithLabel header={t('ORDER_SUMMARY.ACCOUNT-BALANCE')} horizontal>
        <LocalizedNumbers value={performanceSummary?.balance} textComponent={Label3} />
      </WithLabel>
      <WithLabel header={t('ORDER_SUMMARY.WITHDRAWAL.AMOUNT-TITLE')} horizontal>
        <Label3>-{value && <LocalizedNumbers value={value} textComponent={Label3} />}</Label3>
      </WithLabel>
      <WithLabel header={t('ORDER_SUMMARY.NEW-ACCOUNT-BALANCE')} horizontal>
        <View flex={1} alignItems="flex-end">
          <Skeleton width="100%" height={20}>
            {performanceSummary?.balance !== undefined && value !== undefined && (
              <LocalizedNumbers
                value={performanceSummary?.balance - value}
                textComponent={Label3}
              />
            )}
          </Skeleton>
        </View>
      </WithLabel>
      <ReferenceAccountInformation customerId={customerId} variant="withdrawal" column />
      <Button alignSelf="stretch" onPress={submitOrder}>
        {t('ORDER_SUMMARY.CONFIRM')}
      </Button>
    </YStack>
  );
}
