import {Button} from '@/components/buttons/Button';
import {IconButton} from '@/components/buttons/IconButton';
import {Label3, Label3sm2} from '@/components/texts/Label';
import {NotValidMfaMethodDesc} from '@/modules/mfa/components/NotValidMfaMethodDecsription';
import {RemainingTimeChip} from '@/modules/mfa/components/RemainingTimeChip';
import {useMfaAvailability} from '@/modules/mfa/hooks/useMfaAvailability';
import {MfaMethodSmsResponse} from '@/types/api/auth';
import {useTranslate} from '@tolgee/react';
import {YStack} from 'tamagui';

type Props = {
  methods: MfaMethodSmsResponse[];
  onAdd: (id?: string) => void;
  onRemove: (id: string) => void;
  loading?: boolean;
};

const MAX = 10;

export const PhoneNumbersCard = ({methods, onAdd, onRemove, loading}: Props) => {
  const {t} = useTranslate();
  const {invalidMethod} = useMfaAvailability();

  const handleRemoveNumber = (id: string) => onRemove(id);

  const handleAddNumber = (id?: string) => onAdd(id);

  return (
    <YStack gap="$2">
      <Label3sm2 color="$neutral500">{t('MFA.PHONE_NUMBER')}</Label3sm2>
      {methods.map((method, index, self) => (
        <YStack key={method.id}>
          <Label3>{method.phoneNumber}</Label3>
          {invalidMethod === undefined ? (
            <>
              {self.length === 1 ? (
                <Button secondary marginTop="$6" onPress={() => handleAddNumber()}>
                  {t('MFA.ADD_NUMBER')}
                </Button>
              ) : (
                <IconButton
                  name="trash"
                  size={18}
                  color="$primary"
                  onPress={() => handleRemoveNumber(method.id)}
                />
              )}
            </>
          ) : (
            <RemainingTimeChip validDate={invalidMethod.time} />
          )}
        </YStack>
      ))}
      {invalidMethod !== undefined && <NotValidMfaMethodDesc />}
      {methods.length > 1 && methods.length < MAX && invalidMethod === undefined && (
        <Button secondary marginTop="$6" onPress={() => handleAddNumber()}>
          {t('MFA.ADD_NUMBER')}
        </Button>
      )}
    </YStack>
  );
};
