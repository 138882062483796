import {Button} from '@/components/buttons/Button';
import SvgFile from '@/components/icons/SvgFile';
import {Label3} from '@/components/texts/Label';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {DEFAULT_COUNTRY} from '@/constants/countries';
import {useMfaAvailability} from '@/modules/mfa/hooks/useMfaAvailability';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';
import {TaxExemptionOrderResponse} from '@/types/api/users';
import {useTranslate} from '@tolgee/react';
import {useRouter} from 'expo-router';
import {useEffect, useMemo, useState} from 'react';
import {View, XStack, YStack, useMedia} from 'tamagui';

type TaxExemptionCardsProps = {newTaxExemptionDisabled: boolean};

export const TaxExemptionCards = ({newTaxExemptionDisabled}: TaxExemptionCardsProps) => {
  const {t} = useTranslate();

  const [currentDAB, setCurrentDAB] = useState<TaxExemptionOrderResponse>();
  const [currentUpvest, setCurrentUpvest] = useState<TaxExemptionOrderResponse>();

  const {data: user, isFetching: userFetching} = useGetSelfUserQuery();
  const router = useRouter();
  const {isMFAUsable, isFetching} = useMfaAvailability();
  const media = useMedia();

  useEffect(() => {
    if (user) {
      const dab = user.taxExemptionOrders?.find(item => item.custodian === 'DAB');
      const upvest = user.taxExemptionOrders?.find(item => item.custodian === 'UPVEST');
      setCurrentDAB(dab);
      setCurrentUpvest(upvest);
    }
  }, [user]);

  const newTaxExemption = () => {
    router.navigate('/(user)/profile/new-tax-exemption');
  };

  const germanTaxId = useMemo(() => {
    return user?.taxIdentification?.find(
      tax => tax.country.toLowerCase() === DEFAULT_COUNTRY.code.toLowerCase()
    )?.number;
  }, [user?.taxIdentification]);

  const disabledReason = useMemo(() => {
    if (germanTaxId === undefined) return t('SNACKBAR.TAX-ID.REQUIRED');
    if (!isMFAUsable) return t('SNACKBAR.MFA.REQUIRED');
    return undefined;
  }, [germanTaxId, isMFAUsable, t]);

  return (
    <YStack gap="$6" marginTop="$5" flex={1}>
      <XStack gap="$6" flexDirection={media.sm ? 'column' : 'row'}>
        <YStack
          gap="$3"
          flex={media.sm ? 'unset' : 1}
          flexBasis={media.sm ? 'unset' : 1 / 2}
          paddingRight="$7"
        >
          <XStack gap="$3" alignItems="center">
            <View
              width={40}
              height={40}
              backgroundColor="$neutralBG"
              borderRadius="$full"
              alignItems="center"
              justifyContent="center"
            >
              <SvgFile size={24} name="bank" color="$primary" />
            </View>
            <Label3 variant="medium">{t('TAXES.TAX-EXEMPTION.BLOCK_1.TITLE')}</Label3>
          </XStack>
          <XStack justifyContent="space-between">
            <YStack gap="$4" justifyContent="center">
              <Label3 variant="medium" color="$neutral500">
                {t('TAXES.TAX-EXEMPTION.BLOCK_1.TEXT_1')}
              </Label3>
              <Label3 variant="medium" color="$neutral500">
                {t('TAXES.TAX-EXEMPTION.BLOCK_1.TEXT_2')}
              </Label3>
              <Label3 variant="medium" color="$neutral500">
                {t('TAXES.TAX-EXEMPTION.BLOCK_1.TEXT_3')}
              </Label3>
            </YStack>
            <YStack gap="$4" justifyContent="center">
              <View alignItems="center" minWidth={100}>
                <Label3 variant="medium">
                  {currentDAB?.category === 'SINGLE'
                    ? t('TAXES.TAX-EXEMPTION.PREVIOUS.SINGLE')
                    : currentDAB?.category === 'JOINT'
                      ? t('TAXES.TAX-EXEMPTION.PREVIOUS.JOINT')
                      : '-'}
                </Label3>
              </View>
              <View alignItems="center" minWidth={100}>
                {currentDAB?.totalAmount ? (
                  <LocalizedNumbers
                    value={currentDAB?.totalAmount}
                    numberStyle={{variant: 'medium'}}
                    textComponent={Label3}
                  />
                ) : (
                  <Label3 variant="medium">-</Label3>
                )}
              </View>
              <View alignItems="center" minWidth={100}>
                {currentDAB?.usedAmount ? (
                  <LocalizedNumbers
                    value={currentDAB?.usedAmount}
                    numberStyle={{variant: 'medium'}}
                    textComponent={Label3}
                  />
                ) : (
                  <Label3 variant="medium">-</Label3>
                )}
              </View>
            </YStack>
          </XStack>
        </YStack>

        <YStack
          gap="$3"
          flex={media.sm ? 'unset' : 1}
          flexBasis={media.sm ? 'unset' : 1 / 2}
          paddingRight="$7"
        >
          <XStack gap="$3" alignItems="center">
            <View
              width={40}
              height={40}
              backgroundColor="$neutralBG"
              borderRadius="$full"
              alignItems="center"
              justifyContent="center"
            >
              <SvgFile size={24} name="bank" color="green" />
            </View>
            <Label3 variant="medium">{t('TAXES.TAX-EXEMPTION.BLOCK_2.TITLE')}</Label3>
          </XStack>
          <XStack justifyContent="space-between">
            <YStack gap="$4" justifyContent="center">
              <Label3 variant="medium" color="$neutral500">
                {t('TAXES.TAX-EXEMPTION.BLOCK_1.TEXT_1')}
              </Label3>
              <Label3 variant="medium" color="$neutral500">
                {t('TAXES.TAX-EXEMPTION.BLOCK_1.TEXT_2')}
              </Label3>
              <Label3 variant="medium" color="$neutral500">
                {t('TAXES.TAX-EXEMPTION.BLOCK_1.TEXT_3')}
              </Label3>
            </YStack>
            <YStack gap="$4" justifyContent="center">
              <View alignItems="center" minWidth={100}>
                <Label3 variant="medium">
                  {currentUpvest?.category === 'SINGLE'
                    ? t('TAXES.TAX-EXEMPTION.PREVIOUS.SINGLE')
                    : currentUpvest?.category === 'JOINT'
                      ? t('TAXES.TAX-EXEMPTION.PREVIOUS.JOINT')
                      : '-'}
                </Label3>
              </View>
              <View alignItems="center" minWidth={100}>
                {currentUpvest?.totalAmount ? (
                  <LocalizedNumbers
                    value={currentUpvest?.totalAmount}
                    numberStyle={{variant: 'medium'}}
                    textComponent={Label3}
                  />
                ) : (
                  <Label3 variant="medium">-</Label3>
                )}
              </View>
              <View alignItems="center" minWidth={100}>
                {currentUpvest?.usedAmount ? (
                  <LocalizedNumbers
                    value={currentUpvest?.usedAmount}
                    numberStyle={{variant: 'medium'}}
                    textComponent={Label3}
                  />
                ) : (
                  <Label3 variant="medium">-</Label3>
                )}
              </View>
            </YStack>
          </XStack>
        </YStack>
      </XStack>
      <Button
        onPress={newTaxExemption}
        isLoading={isFetching || userFetching}
        disabled={disabledReason || newTaxExemptionDisabled}
      >
        {t('NAVIGATION.PROFILE.TAXES.BUTTON.NEW-TAXEXEMPTION')}
      </Button>
    </YStack>
  );
};
