import {CustomLink} from '@/components/buttons/CustomLink';
import {Card} from '@/components/cards/Card';
import {Label3sm2} from '@/components/texts/Label';
import {LocalizedDate} from '@/components/texts/LocalizedDate';
import {Href} from 'expo-router';
import {PropsWithChildren} from 'react';
import {GetProps, XStack, YStack} from 'tamagui';

type StringCardProps = {
  label: string;
  date?: never;
  labelLink?: {
    href: Href;
    label: string;
  };
  withoutCard?: boolean;
};

type DateCardProps = {
  date: GetProps<typeof LocalizedDate>['date'];
  label?: never;
  labelLink?: never;
  withoutCard?: boolean;
};

export function LabelCard({
  children,
  label,
  date,
  labelLink,
  withoutCard,
  ...rest
}: PropsWithChildren<(StringCardProps | DateCardProps) & GetProps<typeof Card>>) {
  return (
    <YStack gap="$2">
      <XStack justifyContent="space-between" alignItems="flex-end">
        {typeof label === 'string' ? (
          <Label3sm2 color="$neutral500">{label}</Label3sm2>
        ) : (
          <LocalizedDate date={date} dateFormat="month" />
        )}
        {labelLink && <CustomLink href={labelLink.href}>{labelLink.label}</CustomLink>}
      </XStack>
      {withoutCard ? children : <Card {...rest}>{children}</Card>}
    </YStack>
  );
}
