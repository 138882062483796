import {useReactNavigationDevTools} from '@dev-plugins/react-navigation';
import {useNavigationContainerRef} from 'expo-router';
import {PropsWithChildren} from 'react';

export function NavigationDevTools({children}: PropsWithChildren) {
  const navigationRef = useNavigationContainerRef();

  useReactNavigationDevTools(navigationRef);

  return children;
}
