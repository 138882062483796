import {Stack} from 'expo-router';

import {useScreenOptions} from '@/modules/navigation/hooks/useScreenOptions';

const InterviewQuestionsLayout = () => {
  const screenOptions = useScreenOptions({headerShown: false});

  return <Stack screenOptions={screenOptions} />;
};

export default InterviewQuestionsLayout;
