import {captureException} from '@sentry/react-native';
import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
import {useCallback, useEffect, useState} from 'react';

import {VALID_MIMES, VALID_MIMES_WITHOUT_PDF} from '@/constants/documents';
import {convertDateToFormat} from '@/helpers/convertDateToFormat';
import {uploadDocumentWithUrl} from '@/helpers/uploadDocumentWithUrl';
import {useUploadFileMutation} from '@/store/queries/mediaApi';
import {UploadUrlRequest} from '@/types/api/media';

export type UploadFileAction = 'library' | 'camera' | 'photos';

export type LoadingStatus = 'loading' | 'success' | 'failed';

export type UploadedDoc = {
  objectKey: string;
  docName?: string;
};
export type DocumentUploadErrorReason =
  | 'CANCELED'
  | 'CAMERA_PERMISSION'
  | 'PHOTOS_PERMISSION'
  | 'FILE_FORMAT'
  | 'FILE_SIZE';

type Args = {
  fileContextPrefix: string;
  userID?: number;
  onSuccess?: (doc?: UploadedDoc) => void;
  onError?: (reason: DocumentUploadErrorReason) => void;
  pdf?: boolean;
  maxFileSize?: number;
};

export const useUploadFile = ({
  fileContextPrefix,
  userID,
  onSuccess,
  onError,
  pdf = true,
  maxFileSize,
}: Args) => {
  const [status, setStatus] = useState<LoadingStatus>();

  const [uploadFile] = useUploadFileMutation();

  useEffect(() => {
    if (!status || status === 'loading') return;

    setTimeout(() => setStatus(undefined), 1000);
  }, [status]);

  const getFileName = useCallback((url: string) => url.replace(/^.*[/\\]/, ''), []);

  const uploadDocument = useCallback(
    async (asset: DocumentPicker.DocumentPickerAsset): Promise<UploadedDoc | undefined> => {
      if (userID === undefined) return undefined;
      setStatus('loading');
      const extension = asset?.mimeType?.split('/').pop()?.toUpperCase()?.replace('JPG', 'JPEG');

      if (!extension) return undefined;

      const today = convertDateToFormat(new Date());
      const prefix = `${fileContextPrefix}/${userID}/${today}`;

      const {objectKey, uploadUrl} = await uploadFile({
        prefix,
        extension: extension as UploadUrlRequest['extension'],
      }).unwrap();

      await uploadDocumentWithUrl(uploadUrl, asset as any); // TODO: need to type mimeType properly

      const name = asset.name ?? getFileName(objectKey);

      setStatus('success');

      return {objectKey, docName: name};
    },
    [fileContextPrefix, getFileName, uploadFile, userID]
  );

  const pickImageFromPhotos = useCallback(async () => {
    const photosPermission = await ImagePicker.requestMediaLibraryPermissionsAsync();

    if (photosPermission.status !== ImagePicker.PermissionStatus.GRANTED) {
      if (onError) {
        onError('PHOTOS_PERMISSION');
      }
      return undefined;
    }

    let result;
    try {
      result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: 'images',
        base64: true,
      });
    } catch (e) {
      console.warn('launchImageLibraryAsync failed');
      captureException(e);
    }
    if (result === undefined) return undefined;

    if (result.canceled) {
      if (onError) {
        onError('CANCELED');
      }

      return undefined;
    }

    const asset = result.assets.at(0);

    if (!asset) {
      if (onError) {
        captureException(onError);
        onError('FILE_FORMAT');
      }

      return undefined;
    }

    if (maxFileSize !== undefined && asset.fileSize && asset.fileSize > maxFileSize) {
      if (onError) {
        onError('FILE_SIZE');
      }
      return;
    }

    const name = getFileName(asset.uri);
    return uploadDocument({uri: asset.uri, name, mimeType: 'image/jpeg'});
  }, [maxFileSize, getFileName, uploadDocument, onError]);

  const pickImageFromCamera = useCallback(async () => {
    const cameraPermission = await ImagePicker.requestCameraPermissionsAsync();

    if (cameraPermission.status !== ImagePicker.PermissionStatus.GRANTED) {
      if (onError) {
        onError('CAMERA_PERMISSION');
      }
      return undefined;
    }

    let result;
    try {
      result = await ImagePicker.launchCameraAsync({
        mediaTypes: 'images',
        base64: true,
      });
    } catch (e) {
      console.warn('launchCameraAsync failed');
      captureException(e);
      return pickImageFromPhotos();
    }
    if (result === undefined) return undefined;

    if (result.canceled) {
      if (onError) {
        onError('CANCELED');
      }

      return undefined;
    }

    const asset = result.assets.at(0);

    if (!asset) {
      if (onError) {
        captureException(onError);
        onError('FILE_FORMAT');
      }

      return undefined;
    }

    if (maxFileSize !== undefined && asset.fileSize && asset.fileSize > maxFileSize) {
      if (onError) {
        onError('FILE_SIZE');
      }
      return;
    }

    const name = getFileName(asset.uri);
    return uploadDocument({uri: asset.uri, name, mimeType: 'image/jpeg'});
  }, [maxFileSize, getFileName, uploadDocument, onError, pickImageFromPhotos]);

  const pickDocument = useCallback(async () => {
    const validMimes = pdf ? VALID_MIMES : VALID_MIMES_WITHOUT_PDF;

    const documents = await DocumentPicker.getDocumentAsync({type: [...validMimes]});

    if (documents.canceled) {
      if (onError) {
        onError('CANCELED');
      }

      return;
    }

    const asset = documents.assets.at(0);

    if (!asset) {
      if (onError) {
        captureException(onError);
        onError('FILE_FORMAT');
      }

      return undefined;
    }

    if (!asset.mimeType || !validMimes.has(asset.mimeType)) {
      if (onError) {
        captureException(onError);
        onError('FILE_FORMAT');
      }

      return undefined;
    }

    if (maxFileSize !== undefined && asset.size && asset.size > maxFileSize) {
      if (onError) {
        onError('FILE_SIZE');
      }
      return;
    }

    return uploadDocument(asset);
  }, [pdf, maxFileSize, uploadDocument, onError]);

  const uploadSelectedDoc = useCallback(
    async (action: UploadFileAction) => {
      let doc: UploadedDoc | undefined;

      try {
        switch (action) {
          case 'library': {
            doc = await pickDocument();

            break;
          }
          case 'camera': {
            doc = await pickImageFromCamera();

            break;
          }
          case 'photos': {
            doc = await pickImageFromPhotos();

            break;
          }
        }
      } catch (error) {
        console.error(error);
        captureException(error, {tags: {action}});
      }

      return doc;
    },
    [pickDocument, pickImageFromCamera, pickImageFromPhotos]
  );

  const handleUploadSelectedDoc = useCallback(
    async (action: UploadFileAction) => {
      if (!onSuccess) return;

      const doc = await uploadSelectedDoc(action);

      onSuccess(doc);
    },
    [uploadSelectedDoc, onSuccess]
  );

  return {
    pickImageFromCamera,
    pickImageFromPhotos,
    pickDocument,
    handleUploadSelectedDoc,
    uploadSelectedDoc,
    status,
  };
};
