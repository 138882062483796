import SvgFile from '@/components/icons/SvgFile';
import {Label3} from '@/components/texts/Label';
import {useTranslate} from '@tolgee/react';
import {XStack, YStack} from 'tamagui';

export function SecurityTransferInfoBlock() {
  const {t} = useTranslate();

  return (
    <YStack gap="$4" backgroundColor="$neutral50" padding="$2" borderRadius="$1">
      <XStack gap="$2" alignItems="flex-start" justifyContent="flex-start">
        <SvgFile size={18} name="document-ripped" color="$primary" withCircle="onNeutral" />
        <Label3 variant="medium" color="$primary" flex={1}>
          {t('ORDERS.DEPOSIT_METHOD.TRANSFER.INFO_TITLE')}
        </Label3>
      </XStack>
      <XStack gap="$2" alignItems="flex-start" justifyContent="flex-start">
        <SvgFile size={18} name="lightning" color="$primary" withCircle="onNeutral" />
        <YStack gap="$2" flex={1}>
          <Label3>{t('ORDERS.DEPOSIT_METHOD.TRANSFER.INFO_DESCRIPTION_1')}</Label3>
          <Label3>{t('ORDERS.DEPOSIT_METHOD.TRANSFER.INFO_DESCRIPTION_2')}</Label3>
        </YStack>
      </XStack>
    </YStack>
  );
}
