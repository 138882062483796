import {ResponsiveScrollView} from '@/components/views/ResponsiveScrollView';
import {WebFooter} from '@/modules/navigation/components/footer/WebFooter';
import NewTaxExemption from '@/modules/profile/components/taxes/NewTaxExemption';
import React from 'react';

export default function TabNewTaxExemption() {
  return (
    <ResponsiveScrollView useKeyboardAwareScrollView>
      <NewTaxExemption />
      <WebFooter withCalendly />
    </ResponsiveScrollView>
  );
}
