import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {AuthState} from './state';

const initialState: AuthState = {};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string | undefined>) => {
      state.accessToken = action.payload;
    },
    setCodeVerifier: (state, action: PayloadAction<AuthState['codeVerifier']>) => {
      state.codeVerifier = action.payload;
    },
    setTokenEmail: (state, action: PayloadAction<AuthState['tokenEmail']>) => {
      state.tokenEmail = action.payload;
    },
  },
});

export const {setAccessToken, setCodeVerifier, setTokenEmail} = authSlice.actions;

export default authSlice.reducer;
