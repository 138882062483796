import {FormRefdataSelect} from '@/components/inputs/form/FormRefdataSelect';
import {useTitlesQuery} from '@/store/queries/referenceApi';
import {SelectFormInputProps} from '@/types/FormInputProps';
import {FieldValues, Path, PathValue} from 'react-hook-form';

export function FormTitleSelect<T extends FieldValues>({
  control,
  name,
  label,
  bordered,
}: SelectFormInputProps<T>) {
  const {data: titles} = useTitlesQuery();
  return (
    <FormRefdataSelect
      control={control}
      name={name}
      label={label}
      type={{
        defaultValue: 'MR' as PathValue<T, Path<T>>,
        data: titles,
      }}
      bordered={bordered}
    />
  );
}
