import {useGetSelfUserQuery, useGetSpecificUserQuery} from '@/store/queries/usersApi';
import {skipToken} from '@reduxjs/toolkit/query';

export function useGetParentOrChildUser(isChildScreen: boolean, childID: string | undefined) {
  const {data: selfUser} = useGetSelfUserQuery();
  const {data: childUser} = useGetSpecificUserQuery(
    isChildScreen && childID ? {userID: childID} : skipToken
  );
  return isChildScreen ? childUser : selfUser;
}
