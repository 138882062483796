import {ProductBadge} from '@/components/buttons/ProductBadge';
import {StrategyBadge} from '@/components/buttons/StrategyBadge';
import SvgFile from '@/components/icons/SvgFile';
import {Heading4} from '@/components/texts/Heading';
import {Paragraph1} from '@/components/texts/Paragraph';
import {PerformanceBadge} from '@/components/texts/PerformanceBadge';
import {ComponentAnimator} from '@/modules/overview/components/ComponentAnimator';
import {AccSummaryResponse} from '@/types/api/apeiron';
import {ShortenedCustomerInformationResponse} from '@/types/api/customers.v2';
import {useRouter} from 'expo-router';
import {useMedia, View, XStack, YStack} from 'tamagui';
import {Card} from '../../shared/components/cards/Card';
import LocalizedNumbers from '../../shared/components/texts/LocalizedNumbers';

type AccountCardProps = {
  customer: ShortenedCustomerInformationResponse;
  apeiron: AccSummaryResponse | undefined;
};

export function AccountCard({customer, apeiron}: AccountCardProps) {
  const router = useRouter();
  const media = useMedia();
  return (
    <Card
      $gtSm={{justifyContent: 'space-between'}}
      $sm={{alignItems: 'flex-start'}}
      gap="$3"
      flexDirection={media.sm ? 'column' : 'row'}
      onPress={() => {
        router.navigate({
          pathname: '/overview/[customerId]',
          params: {customerId: customer.id},
        });
      }}
    >
      <YStack gap="$1" $gtSm={{alignSelf: 'center'}}>
        <Paragraph1 numberOfLines={1} maxWidth={media.sm ? 200 : 500}>
          {customer.displayName}
        </Paragraph1>
        <XStack gap="$2">
          <ProductBadge product={customer.product} />

          {customer.currentInvestmentStrategy && (
            <StrategyBadge strategy={customer.currentInvestmentStrategy} />
          )}
        </XStack>
      </YStack>

      <XStack gap="$5" $sm={{width: '100%'}}>
        <View
          flexDirection={media.sm ? 'row' : 'column'}
          $sm={{justifyContent: 'space-between', alignItems: 'flex-start', flex: 1}}
          $gtSm={{alignItems: 'flex-end'}}
        >
          <LocalizedNumbers
            variant="currency"
            precision="decimals"
            value={apeiron?.balance}
            numberStyle={{
              variant: 'medium',
            }}
            textComponent={Heading4}
            showZero
          />
          <XStack width={120}>
            {apeiron?.rateOfReturn?.timeWeighted.overall !== undefined &&
              apeiron?.performance.amount !== undefined && (
                <ComponentAnimator>
                  <PerformanceBadge
                    percentage
                    value={apeiron?.rateOfReturn?.timeWeighted.overall}
                    backgroundColor={media.sm ? '$greenTransparent' : undefined}
                  />
                  <PerformanceBadge
                    value={apeiron?.performance.amount}
                    backgroundColor={media.sm ? '$greenTransparent' : undefined}
                  />
                </ComponentAnimator>
              )}
          </XStack>
        </View>
        {!media.sm && <SvgFile name="chevron-right" color="$primary" />}
      </XStack>
    </Card>
  );
}
