import {Storage} from 'redux-persist';

import {createPersistedMMKVStorage} from './createPersistedMMKVStorage';

const noopStorage: Storage = {
  getItem: key => {
    return Promise.resolve(null);
  },
  setItem: (key, value) => {
    return Promise.resolve(value);
  },
  removeItem: key => {
    return Promise.resolve();
  },
};

export const persistedStorage =
  typeof window === 'undefined' ? noopStorage : createPersistedMMKVStorage();
