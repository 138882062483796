import * as Sentry from '@sentry/react-native';
import {PostHogProvider} from 'posthog-js/react';
import React from 'react';
import {PostHogScreenTracker} from './PostHogScreenTracker';

export function CustomPostHogProvider({children}: {children: React.ReactNode}) {
  if (__DEV__) return children;
  return (
    <PostHogProvider
      apiKey={process.env.EXPO_PUBLIC_POSTHOG_KEY}
      options={{
        api_host: process.env.EXPO_PUBLIC_POSTHOG_HOST,
        disable_session_recording: false,
        ui_host: 'https://eu.posthog.com',
        loaded: posthog_instance => {
          try {
            Sentry.setTag('posthog_session_id', posthog_instance.get_session_id());
            Sentry.setTag('posthog_distinct_id', posthog_instance.get_distinct_id());
            Sentry.setExtra(
              'posthog_session_url',
              `https://eu.posthog.com/project/39224/person/${posthog_instance.get_distinct_id()}`
            );
            Sentry.setExtra(
              'posthog_replay_url',
              posthog_instance.get_session_replay_url({withTimestamp: true})
            );
          } catch (e) {}
        },
      }}
    >
      <PostHogScreenTracker />
      {children}
    </PostHogProvider>
  );
}
