import {useTranslate} from '@tolgee/react';
import {Href, useRouter} from 'expo-router';
import {useCallback, useMemo, useRef, useState} from 'react';
import {YStack} from 'tamagui';

import {InitialDepositForm, ZERO_DEPOSIT} from '@/components/forms/InitialDepositForm';
import {Heading4} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {InitialDepositSchemaType} from '@/hooks/useValidations';
import {SubmitFormRef} from '@/modules/interview/components/FinancialSituationForm';
import {InterviewContinueButton} from '@/modules/interview/components/InterviewContinueButton';
import {useAppDispatch, useAppSelector} from '@/store';
import {saveInitialDeposit, savePlanner} from '@/store/reducers/onboarding';
import {initialDepositMethod} from '@/types/api/orders';
import {Product} from '@/types/fixedTypes/customers.v2/Product';

type Props = {
  href: Href;
  product: Product;
};

export const UserDepositScreen = ({href, product}: Props) => {
  const {t} = useTranslate();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const initialDepositAmount = useAppSelector(
    state => state.onboarding.planner?.initialDepositAmount
  );
  const initialDeposit = useAppSelector(state => state.onboarding.personalDetails.initialDeposit);
  const ref = useRef<SubmitFormRef>(null);

  const onPress = useCallback(async () => {
    ref.current?.submit();
  }, []);

  const [method, setMethod] = useState<initialDepositMethod | typeof ZERO_DEPOSIT>();

  const onValidSubmit = useCallback(
    async (data: InitialDepositSchemaType) => {
      if (data.amount === 0 || data.method === ZERO_DEPOSIT) {
        data.method = initialDepositMethod.DIRECT_DEBIT;
      }
      dispatch(saveInitialDeposit({method: data.method, amount: data.amount}));
      dispatch(savePlanner({initialDepositAmount: data.amount}));
      router.navigate(href);
    },
    [dispatch, href, router]
  );

  const defaultValue = useMemo(() => {
    if (initialDeposit !== undefined) {
      return {
        amount: initialDeposit.amount,
        method: initialDeposit.amount === 0 ? ZERO_DEPOSIT : initialDeposit.method,
      };
    }

    if (initialDepositAmount !== undefined) {
      return {
        amount: initialDepositAmount,
        method: initialDepositAmount === 0 ? ZERO_DEPOSIT : initialDepositMethod.DIRECT_DEBIT,
      };
    }

    return undefined;
  }, [initialDeposit, initialDepositAmount]);

  return (
    <YStack gap="$6" marginTop="$6">
      <Heading4 variant="medium">{t('ACCOUNT_SETUP.INITIAL-DEPOSIT-METHOD.TITLE')}</Heading4>
      <Paragraph2 color="$neutral400">
        {t('ACCOUNT_SETUP.INITIAL-DEPOSIT-METHOD.DESCRIPTION')}
      </Paragraph2>
      <InitialDepositForm
        ref={ref}
        onValid={onValidSubmit}
        defaultValues={defaultValue}
        product={product}
        setMethod={setMethod}
        setIsLoading={setIsLoading}
      />
      <InterviewContinueButton isLoading={isLoading} onPress={onPress}>
        {method === ZERO_DEPOSIT
          ? t('ACCOUNT_SETUP.INITIAL-DEPOSIT-METHOD.CONTINUE-WITH-ZERO-DEPOSIT')
          : t('CONTINUE')}
      </InterviewContinueButton>
    </YStack>
  );
};
