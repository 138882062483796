import {baseApi, MINUTE} from '@/store/queries/baseApi';
import {
  MfaChallengeRequest,
  MfaMethodSmsRequest,
  MfaMethodSmsResponse,
  MfaSessionRequest,
} from '@/types/api/auth';
import {Violation} from '@/types/api/refdata.v2';

const BASE_AUTH_PATH = 'auth';

const BASE_MFA_PATH = BASE_AUTH_PATH + '/mfa';

const extendedMfaApi = baseApi.injectEndpoints({
  endpoints: build => ({
    getMfaMethods: build.query<MfaMethodSmsResponse[], void>({
      query: () => ({url: `${BASE_MFA_PATH}/methods`}),
      providesTags: ['MFA_METHODS'],
      keepUnusedDataFor: 5 * MINUTE,
    }),
    createInitialMethod: build.mutation<MfaMethodSmsResponse, MfaMethodSmsRequest>({
      query: data => ({
        url: `${BASE_MFA_PATH}/initial-methods`,
        method: 'POST',
        body: data,
      }),
    }),
    createAdditionalMethod: build.mutation<MfaMethodSmsResponse, MfaMethodSmsRequest>({
      query: data => ({
        url: `${BASE_MFA_PATH}/methods`,
        method: 'POST',
        body: data,
      }),
    }),
    sendCode: build.mutation<void, MfaChallengeRequest>({
      query: data => ({
        url: `${BASE_MFA_PATH}/challenges`,
        method: 'POST',
        body: data,
      }),
    }),
    verifyCode: build.mutation<void, MfaSessionRequest>({
      query: data => ({
        url: `${BASE_MFA_PATH}/sms-verifications`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['MFA_METHODS'],
    }),
    createSession: build.mutation<void, MfaSessionRequest>({
      query: data => ({
        url: `${BASE_MFA_PATH}/sessions`,
        method: 'POST',
        body: data,
      }),
    }),
    deleteMethod: build.mutation<void, {methodId: string}>({
      query: ({methodId}) => ({
        url: `${BASE_MFA_PATH}/methods/${methodId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['MFA_METHODS'],
    }),
    changePassword: build.mutation<
      null | ResponseError,
      {oldPassword: string; newPassword: string}
    >({
      query: ({oldPassword, newPassword}) => ({
        url: `${BASE_AUTH_PATH}/password`,
        method: 'POST',
        body: {oldPassword, newPassword},
        headers: {'Content-Type': 'application/json'},
      }),
    }),
  }),
});

export type ResponseError = {
  status: number;
  title: string;
  violations: Violation[];
};

export const {
  useGetMfaMethodsQuery,
  useCreateInitialMethodMutation,
  useCreateAdditionalMethodMutation,
  useCreateSessionMutation,
  useSendCodeMutation,
  useVerifyCodeMutation,
  useDeleteMethodMutation,
  useChangePasswordMutation,
} = extendedMfaApi;
