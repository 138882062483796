import {getColorFromThemeCode, ThemeIcon} from '@/components/icons/ThemeIcon';
import {DraggableBar} from '@/components/inputs/DraggableBar';
import {Label2, Label3} from '@/components/texts/Label';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {ThemeCode} from '@/modules/portfolio/types/ThemeCode';
import {useState} from 'react';
import {View, XStack} from 'tamagui';

type Props = {
  code: ThemeCode;
  name: string;
  amount: number;
  percentage: number;
  onThemeChanged: (code: string, value: number) => void;
  sum: number;
  onThemeRemoved?: (code: string) => void;
};

export const ThemeItemDeposit = ({
  code,
  name,
  amount,
  percentage,
  onThemeChanged,
  sum,
  onThemeRemoved,
}: Props) => {
  const color = getColorFromThemeCode(code);
  const [itemPercentage, setItemPercentage] = useState(percentage);

  const handleChangedTheme = (percentage: number) => {
    onThemeChanged(code, percentage);
  };

  return (
    <View
      animation="slow"
      key={`animated-${code}`}
      enterStyle={{
        opacity: 0,
        y: 20,
      }}
      exitStyle={{
        opacity: 0,
        y: 20,
        height: 0,
      }}
      height={40}
    >
      <View padding="$2" borderRadius="$1">
        <XStack alignItems="center" gap="$2">
          <ThemeIcon code={code} onPress={onThemeRemoved} />
          <DraggableBar
            percentage={percentage}
            updateAmount={setItemPercentage}
            handleChangedTheme={handleChangedTheme}
            color={color}
            sum={sum}
          >
            <Label2>{name}</Label2>
            <View>
              <LocalizedNumbers
                variant="percentage"
                value={itemPercentage / 100}
                textComponent={Label3}
              />
              <LocalizedNumbers
                numberStyle={{alignSelf: 'flex-end', color: '$neutral500'}}
                variant="currency"
                value={amount}
                precision="decimals"
                textComponent={Label3}
              />
            </View>
          </DraggableBar>
        </XStack>
      </View>
    </View>
  );
};
