import {Button} from '@/components/buttons/Button';
import {Card} from '@/components/cards/Card';
import SvgFile from '@/components/icons/SvgFile';
import {Heading5} from '@/components/texts/Heading';
import {Label3} from '@/components/texts/Label';
import {Paragraph2} from '@/components/texts/Paragraph';
import {DEFAULT_COUNTRY} from '@/constants/countries';
import {ChangeGermanTaxID} from '@/modules/profile/components/taxes/ChangeGermanTaxID';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';
import {useTranslate} from '@tolgee/react';
import {useMemo, useState} from 'react';
import {XStack, YStack} from 'tamagui';

export function TaxIdDataCard() {
  const {t} = useTranslate();
  const {data: user} = useGetSelfUserQuery();
  const [showChange, setShowChange] = useState(false);

  const defaultCountryTin = useMemo(
    () =>
      user?.taxIdentification?.find(
        tax => tax.country.toLowerCase() === DEFAULT_COUNTRY.code.toLowerCase()
      )?.number,
    [user?.taxIdentification]
  );

  const taxIdUndefined = useMemo(() => defaultCountryTin === undefined, [defaultCountryTin]);

  const onPress = () => {
    setShowChange(true);
  };

  return (
    <Card gap="$6">
      <XStack justifyContent="space-between">
        <Heading5 variant="medium">{t('NAVIGATION.PROFILE.YOUR-TAX')}</Heading5>
        {!showChange && !taxIdUndefined && (
          <XStack gap="$1.5" alignItems="center" onPress={onPress} cursor="pointer">
            <SvgFile size={14} name="pen" color="$primary" />
            <Paragraph2 color="$primary">{t('PERSONAL_DETAILS.ADDRESS_DATA.CHANGE')}</Paragraph2>
          </XStack>
        )}
      </XStack>

      {!showChange ? (
        <YStack justifyContent="space-between" gap="$4">
          {taxIdUndefined ? (
            <Button onPress={onPress}>{t('NAVIGATION.PROFILE.TAXES.BUTTON.NEW-TAX-ID')}</Button>
          ) : (
            <YStack gap="$3">
              <Label3 color="$neutral500">{t('TAXES.TAX-SETTINGS')}</Label3>
              <Label3 variant="medium">{defaultCountryTin}</Label3>
            </YStack>
          )}
        </YStack>
      ) : (
        <ChangeGermanTaxID onClose={() => setShowChange(false)} />
      )}
    </Card>
  );
}
