import SvgFile from '@/components/icons/SvgFile';
import {Label3} from '@/components/texts/Label';
import {LocalizedDate} from '@/components/texts/LocalizedDate';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {TaxExemptionOrderChangeResponse} from '@/types/api/users';
import {useTranslate} from '@tolgee/react';
import React, {useMemo} from 'react';
import {View, XStack} from 'tamagui';

export function PreviousTaxItemRow({
  open,
  order,
}: {
  open?: boolean;
  order: TaxExemptionOrderChangeResponse;
}) {
  const {t} = useTranslate();

  const singleOrSpouse = useMemo(() => {
    if (order.spouse) return t('TAXES.TAX-EXEMPTION.PREVIOUS.JOINT');
    else return t('TAXES.TAX-EXEMPTION.PREVIOUS.SINGLE');
  }, [order.spouse, t]);

  return (
    <XStack>
      <Label3 flex={1} flexBasis={0} textAlign="left">
        {CustodianCodeToBank[order.custodian]}
      </Label3>
      <LocalizedNumbers
        variant="currency"
        value={order.amount}
        textComponent={Label3}
        numberStyle={{textAlign: 'left', flex: 1, flexBasis: 0}}
      />
      <Label3 flex={1} flexBasis={0} textAlign="left">
        {singleOrSpouse}
      </Label3>
      <Label3 flex={1} flexBasis={0} textAlign="left">
        {order.startYear}
      </Label3>
      <Label3 flex={1.5} flexBasis={0} textAlign="left">
        <LocalizedDate date={order.createdAt} textComponent={Label3} />
      </Label3>
      <View>
        {open !== undefined && (
          <SvgFile
            alignSelf="flex-end"
            color="$primary"
            name="chevron-down"
            animation="fast"
            rotate={open ? '180deg' : '0deg'}
          />
        )}
      </View>
    </XStack>
  );
}

export const CustodianCodeToBank = {
  DAB: 'DAB BNP Paribas',
  UPVEST: 'Upvest',
};
