import {Label2} from '@/components/texts/Label';
import {useTranslate} from '@tolgee/react';
import {useEffect, useMemo, useState} from 'react';
import {GetProps, View, XStack} from 'tamagui';

export type TimePeriodProjectionSelectProps = {
  initialIndex?: number;
  onIndexChange?: (index: number) => void;
  containerStyle?: GetProps<typeof XStack>;
};

export function TimePeriodProjectionSelect({
  initialIndex,
  onIndexChange,
  containerStyle,
}: TimePeriodProjectionSelectProps) {
  const [index, setIndex] = useState(initialIndex || 1);
  const {t} = useTranslate();
  const elements = useMemo(() => {
    return [
      {key: 5, label: t('OVERVIEW.CHART-PERIODS.5Y')},
      {key: 10, label: t('OVERVIEW.CHART-PERIODS.10Y')},
      {key: 15, label: t('OVERVIEW.CHART-PERIODS.15Y')},
      {key: 20, label: t('OVERVIEW.CHART-PERIODS.20Y')},
      {key: 25, label: t('OVERVIEW.CHART-PERIODS.25Y')},
    ];
  }, [t]);

  useEffect(() => {
    onIndexChange?.(elements[index].key);
  }, [elements, index, onIndexChange]);

  return (
    <XStack
      borderRadius="$1"
      justifyContent="flex-end"
      flex={1}
      flexWrap="wrap"
      gap="$2"
      {...containerStyle}
    >
      <View backgroundColor="$white" opacity={0.1} borderRadius="$1" height={24} animation="fast" />
      {elements.map(({key, label}, i) => {
        return (
          <View
            key={key}
            minWidth={70}
            paddingVertical="$1"
            paddingHorizontal="$2"
            alignItems="center"
            hoverStyle={{
              opacity: 0.8,
            }}
            pressStyle={{
              opacity: 0.8,
              scale: 0.95,
            }}
            onPress={() => {
              setIndex(i);
            }}
            cursor="pointer"
            backgroundColor={i === index ? '$primary' : '$neutralBG'}
            borderRadius="$0.5"
          >
            <Label2
              userSelect="none"
              variant="medium"
              color={i === index ? '$white' : '$neutral500'}
            >
              {label}
            </Label2>
          </View>
        );
      })}
    </XStack>
  );
}
