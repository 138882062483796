import {useStorageState} from '@/hooks/useStorageState';
import {useSession} from '@/providers/session-provider/SessionProvider';
import {STORAGE_KEYS} from '@/providers/session-provider/constants';
import {Image} from 'expo-image';
import React, {PropsWithChildren, useEffect, useState} from 'react';
import Animated, {ReduceMotion, runOnJS, useSharedValue, withTiming} from 'react-native-reanimated';
import {View, useTheme, useThemeName, useWindowDimensions} from 'tamagui';

export function SplashScreenProvider({children}: PropsWithChildren) {
  const themeName = useThemeName();
  const theme = useTheme();
  const {width, height} = useWindowDimensions();
  const splashOpacity = useSharedValue(1);
  const [showSplashLoader, setShowSplashLoader] = useState(true);
  const {isLoading} = useSession();
  const [[idLoading]] = useStorageState(STORAGE_KEYS.ID_TOKEN);

  useEffect(() => {
    if (!isLoading && !idLoading) {
      splashOpacity.value = withTiming(
        0,
        {
          duration: 500,
          reduceMotion: ReduceMotion.Never,
        },
        finished => {
          if (finished) {
            runOnJS(setShowSplashLoader)(false);
          }
        }
      );
    }
  }, [idLoading, isLoading, splashOpacity]);

  return (
    <View flex={1}>
      {children}
      {showSplashLoader && (
        <Animated.View
          style={{
            opacity: splashOpacity,
            position: 'absolute',
            height,
            width,
            backgroundColor: theme.neutralBG.val,
            justifyContent: 'center',
          }}
        >
          <Image
            onLoad={() => {
              const domSplash = document?.getElementById?.('splash-logo');
              if (domSplash != null) domSplash.outerHTML = '';
            }}
            source={
              themeName === 'light'
                ? require('@/assets/images/splash.png')
                : require('@/assets/images/splash-dark.png')
            }
            contentFit="contain"
            style={{width: 100, height: 100, alignSelf: 'center'}}
          />
        </Animated.View>
      )}
    </View>
  );
}
