import svgMap from '@/lib/svgMap';
import {captureException} from '@sentry/react-native';
import React, {forwardRef} from 'react';
import {SvgProps} from 'react-native-svg';
import {GetProps, SizeTokens, View, getTokens, styled} from 'tamagui';

export type SvgName = keyof typeof svgMap;

type SvgFileProps = {
  name: SvgName;
  color?: string;
  size?: SizeTokens | string;
  height?: number | string;
  width?: number | string;
  containerStyle?: GetProps<typeof SvgWrapper>;
  withCircle?: 'onSurface' | 'onNeutral';
} & SvgProps;

const SvgFile = forwardRef(({name, color, size, height, width, ...rest}: SvgFileProps, _) => {
  let defaultSvgSize;
  if (!size && !width) defaultSvgSize = getTokens().size.$6.val;
  const Icon = svgMap[name];
  if (Icon === undefined) {
    console.error(`Icon not found ${name}`);
    captureException(`Icon not found ${name}`);
    return null;
  }
  return (
    <Icon
      width={size ?? width ?? defaultSvgSize ?? '100%'}
      height={size ?? height ?? defaultSvgSize ?? '100%'}
      preserveAspectRatio="xMinYMin"
      color={color}
      {...rest}
    />
  );
});

const StyledSVG = styled(
  SvgFile,
  {},
  {
    accept: {
      color: 'color',
      width: 'size',
      height: 'size',
    } as const,
  }
);

const SvgWrapper = styled(View, {
  alignItems: 'center',
  justifyContent: 'center',
  variants: {
    withCircle: {
      onSurface: {
        borderRadius: '$full',
        backgroundColor: '$neutral50',
        padding: '$1',
      },
      onNeutral: {
        borderRadius: '$full',
        backgroundColor: '$surface',
        padding: '$1',
      },
    } as const,
  },
});

const PressableSVG = StyledSVG.styleable(
  ({onPress, animation, rotate, containerStyle, withCircle, color, ...rest}, ref) => {
    return (
      <SvgWrapper
        onPress={onPress}
        animation={animation}
        rotate={rotate}
        withCircle={withCircle}
        {...containerStyle}
      >
        <StyledSVG ref={ref} color={color ?? '$text'} {...rest} />
      </SvgWrapper>
    );
  }
);

export default PressableSVG;
