import {useTranslate} from '@tolgee/react';
import {Stack, useRouter} from 'expo-router';
import * as WebBrowser from 'expo-web-browser';
import {useCallback, useRef, useState} from 'react';
import {Keyboard, Platform} from 'react-native';
import {YStack} from 'tamagui';

import {Card} from '@/components/cards/Card';
import {ResponsiveScrollView} from '@/components/views/ResponsiveScrollView';
import {useCustomerIdParamOrRedirect} from '@/hooks/useCustomerIdParamOrRedirect';
import {DepositOrderSchemaType} from '@/hooks/useValidations';
import {WebFooter} from '@/modules/navigation/components/footer/WebFooter';
import {Header} from '@/modules/navigation/components/header/Header';
import {DepositForm} from '@/modules/orders/components/DepositForm';
import {OrderSummaryModal} from '@/modules/orders/components/OrderSummaryModal';
import {useGetCustomerAccountQuery} from '@/store/queries/customersApi';
import {ModalRef} from '@/types/modal';

export default function DepositPage() {
  const {t} = useTranslate();
  const router = useRouter();
  const customerId = useCustomerIdParamOrRedirect();
  const {data: customer, isFetching} = useGetCustomerAccountQuery(customerId);
  const [amount, setAmount] = useState<number>();
  const modalRef = useRef<ModalRef>(null);

  const onSubmit = useCallback(
    (data: DepositOrderSchemaType) => {
      if (isFetching) return;

      if (data.depositMethod === 'transfer') {
        WebBrowser.openBrowserAsync(process.env.EXPO_PUBLIC_DEPOTWECHSEL);

        return;
      }

      if (data.deposit === undefined) return;

      setAmount(data.deposit);

      if (customer?.product === 'THEME_ACCOUNT') {
        router.navigate({
          pathname: `/overview/[customerId]/order/deposit-motive`,
          params: {
            customerId,
            value: data.deposit,
            orderType: 'DEPOSIT',
          },
        });
      } else {
        Keyboard.dismiss();
        modalRef.current?.open(true);
      }
    },
    [customer?.product, customerId, isFetching, router]
  );

  return (
    <ResponsiveScrollView useKeyboardAwareScrollView>
      <YStack gap="$5">
        <Stack.Screen
          options={{
            headerShown: true,
            header: () => (
              <Header title={t('NAVIGATION.DEPOSIT.TITLE')} backButton={Platform.OS !== 'web'} />
            ),
          }}
        />
        <Card gap="$4">
          <DepositForm product={customer?.product} customerId={customerId} onSubmit={onSubmit} />
        </Card>
      </YStack>
      <OrderSummaryModal
        ref={modalRef}
        customerId={customerId}
        value={amount}
        orderType="DEPOSIT"
      />
      <WebFooter />
    </ResponsiveScrollView>
  );
}
