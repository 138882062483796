import {skipToken} from '@reduxjs/toolkit/query';
import {useCallback} from 'react';

import {isProductBasic} from '@/modules/interview/helpers/isProductBasic';
import {useSession} from '@/providers/session-provider/SessionProvider';
import {useGetLatestAcceptableInterviewsQuery} from '@/store/queries/interviewApi';
import {InterviewSummary} from '@/types/api/interviews';
import {Product} from '@/types/fixedTypes/customers.v2/Product';

export const useGetInterviewByProduct = () => {
  const {session} = useSession();

  const {
    data: latestAcceptableInterviews,
    isFetching,
    isLoading,
  } = useGetLatestAcceptableInterviewsQuery(!session ? skipToken : undefined);

  const getInterviewByProduct = useCallback(
    (product?: Product, isSecondGuardian?: boolean) => {
      if (!product) {
        throw new Error('No product');
      }

      if (!latestAcceptableInterviews) return undefined;

      const isBasicProduct = isProductBasic(product);

      const interviewType: Required<InterviewSummary['type']> =
        isBasicProduct || isSecondGuardian ? 'BASIC_V2' : 'RISK_PROFILE_EVALUATION_V2';

      return latestAcceptableInterviews?.find(interview => interview.type === interviewType);
    },
    [latestAcceptableInterviews]
  );

  return {getInterviewByProduct, isFetching, isLoading};
};
