import {useSession} from '@/providers/session-provider/SessionProvider';
import {captureException} from '@sentry/react-native';
import {router} from 'expo-router';
import * as WebBrowser from 'expo-web-browser';
import {useEffect, useRef} from 'react';
import {Platform} from 'react-native';

if (Platform.OS === 'web') {
  const result = WebBrowser.maybeCompleteAuthSession({skipRedirectCheck: true});
  if (result.type === 'failed') {
    captureException(result.message);
  }
}

export default function Auth() {
  const {isLoading} = useSession();
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (!isLoading && Platform.OS !== 'web') {
      timerRef.current = setTimeout(() => {
        router.replace('/login');
      }, 2500);
    }
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [isLoading]);

  return null;
}
