import {IdentityProof} from '@/types/api/refdata.v2';
import {Identity} from '@/types/api/users';

export function mapFormNationalitiesToUser(
  nationalities:
    | {
        number?: string | undefined;
        document?:
          | {
              docName?: string | undefined;
              objectKey: string;
            }[]
          | undefined;
        country: string;
      }[]
    | undefined,
  proofs: IdentityProof[] | undefined
) {
  const result: {[key: string]: Identity} = {};
  if (!nationalities || !proofs) return undefined;
  nationalities?.forEach(nat => {
    result[nat.country] = {
      proof: proofs?.find(p => p.country === nat.country)?.code || 'NONE',
      number: nat.number,
      uploadKey: nat?.document?.[0]?.objectKey,
    };
  });
  return result;
}
