import {useTranslate} from '@tolgee/react';
import {toast} from 'burnt';
import {Redirect, useRouter} from 'expo-router';
import React, {useCallback, useMemo} from 'react';
import {YStack} from 'tamagui';

import {Button} from '@/components/buttons/Button';
import {Label3} from '@/components/texts/Label';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {WithLabel} from '@/components/texts/WithLabel';
import {InfoBox} from '@/modules/orders/components/InfoBox';
import {ThemeWithdrawalSummaryRow} from '@/modules/orders/components/Theme/ThemeWithdrawalSummaryRow';
import {CustomThemeDistribution} from '@/modules/orders/types/CustomThemeDistribution';
import {ThemeCode} from '@/modules/portfolio/types/ThemeCode';
import {usePostCustomerOrderMutation} from '@/store/queries/customersApi';
import {OrderRequest, ThemeWithdrawalDistribution} from '@/types/api/customers.v2';

type ThemeDepositSummaryCardProps = {
  orderType: Required<OrderRequest>['orderType'];
  customerId: string;
  value?: number;
  onFinished?: () => void;
  themeWithdrawalDistribution?: CustomThemeDistribution<ThemeWithdrawalDistribution>;
};

export function ThemeWithdrawalSummaryCard({
  orderType,
  customerId,
  value,
  onFinished,
  themeWithdrawalDistribution,
}: ThemeDepositSummaryCardProps) {
  const {t} = useTranslate();
  const [postOrder] = usePostCustomerOrderMutation();
  const router = useRouter();

  const themeDistribution = useMemo(() => {
    if (!themeWithdrawalDistribution) return {};

    const distribution = Object.entries(themeWithdrawalDistribution).filter(
      distributionEntry => distributionEntry[1] !== undefined
    );

    return Object.fromEntries(distribution);
  }, [themeWithdrawalDistribution]);

  const submitOrder = useCallback(async () => {
    const res = await postOrder({
      orderType: 'THEME_WITHDRAWAL',
      customerId,
      themeDistribution,
    });

    if (res.error) {
      toast({
        preset: 'error',
        title: t('SNACKBAR.ORDER-NOT-CREATED'),
      });
    } else {
      router.dismissTo({pathname: '/overview/[customerId]', params: {customerId}});
      toast({
        preset: 'done',
        title: t('SNACKBAR.WITHDRAWAL-CREATED'),
      });
      onFinished?.();
    }
  }, [postOrder, customerId, themeDistribution, t, router, onFinished]);

  const distributionGuard = useMemo(() => {
    if (themeDistribution && Object.keys(themeDistribution).length === 0) {
      return (
        <Redirect
          href={{
            pathname: '/overview/[customerId]/order/withdrawal-motive',
            params: {customerId, orderType, value},
          }}
        />
      );
    }
  }, [customerId, orderType, themeDistribution, value]);

  return (
    <YStack gap="$4">
      {distributionGuard}

      <WithLabel header={t('THEME_WITHDRAWAL_ORDER.WITHDRAWAL_AMOUNT')} horizontal>
        {value !== undefined && <LocalizedNumbers value={value} textComponent={Label3} />}
      </WithLabel>

      <InfoBox withBackground>
        <Label3 color="$primary">{t('THEME_WITHDRAWAL_ORDER.SUMMARY.CONFIRM_MESSAGE')}</Label3>
      </InfoBox>

      <YStack gap="$4">
        {Object.entries(themeDistribution).map(([code, distribution]) => (
          <ThemeWithdrawalSummaryRow
            customerId={customerId}
            key={code}
            code={code as ThemeCode}
            distribution={distribution}
          />
        ))}
      </YStack>
      <Button alignSelf="stretch" onPress={submitOrder}>
        {t('ORDER_SUMMARY.CONFIRM')}
      </Button>
    </YStack>
  );
}
