import {Badge} from '@/components/buttons/Badge';
import SvgFile, {SvgName} from '@/components/icons/SvgFile';
import {Label3} from '@/components/texts/Label';
import React, {useMemo} from 'react';
import {XStack, useTheme} from 'tamagui';

type StatusBadgeProps = React.ComponentPropsWithoutRef<typeof XStack> & {
  colorVariant: string;
  status: string;
  icon?: SvgName;
};

export const StatusBadge: React.FC<StatusBadgeProps> = ({
  colorVariant,
  status,
  children,
  icon,
  ...props
}) => {
  const theme = useTheme();

  const colors = useMemo(() => {
    switch (colorVariant) {
      case 'warning':
        return {front: theme.orangeDark, back: theme.orangeTransparent};
      case 'destructive':
        return {front: theme.red, back: theme.redTransparent};
      case 'positive':
      default:
        return {front: theme.green, back: theme.greenTransparent};
    }
  }, [colorVariant, theme]);

  return (
    <Badge
      backgroundColor={colors.back}
      borderColor="transparent"
      gap="$1"
      cursor="auto"
      {...props}
    >
      {icon && <SvgFile name={icon} color={colors.front} size={15} />}
      <Label3 variant="medium" color={colors.front}>
        {status}
      </Label3>
    </Badge>
  );
};
