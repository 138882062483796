import {calculateTimeBetweenDates} from '@/helpers/calculateTimeBetweenDates';

export const calculateNextSavingsRateCollection = () => {
  const currentDate = new Date();
  const firstDayNextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
  const secondDayNextMonth = new Date(firstDayNextMonth);
  secondDayNextMonth.setDate(secondDayNextMonth.getDate() + 1);

  const dayOfWeek = secondDayNextMonth.getDay();
  // In JavaScript, 0 is Sunday and 6 is Saturday
  const isWeekday = dayOfWeek !== 0 && dayOfWeek !== 6;

  // Either take weekday or if its weekend calculate next weekday
  const targetDayOfNextMonth = secondDayNextMonth;
  if (!isWeekday)
    targetDayOfNextMonth.setDate(targetDayOfNextMonth.getDate() + (dayOfWeek ? 1 : 2));

  const totalDays = calculateTimeBetweenDates({
    startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
    endDate: targetDayOfNextMonth,
  });
  const daysToCollection = calculateTimeBetweenDates({endDate: targetDayOfNextMonth});
  return {totalDays, daysToCollection};
};
