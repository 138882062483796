import {DashPathEffect, Group, Path, Skia} from '@shopify/react-native-skia';
import {useCallback, useMemo} from 'react';
import {clamp, useDerivedValue} from 'react-native-reanimated';

import {REMOVE_SELECT_POS} from '@/constants/skia';
import {useChartContext} from './context';
import {useGetChartRange} from './hooks';

type Props = {
  color: string;
  width?: number;
  dashIntervals?: number[];
  opacity?: number;
};

export const ActivePointLine = ({color, dashIntervals, width = 2, opacity}: Props) => {
  const {x, domain, minMaxX, touchEnabled} = useChartContext();

  const range = useGetChartRange();

  const calcPosition = useCallback(
    (value: number) => {
      'worklet';

      const {
        x: [domainMin, domainMax],
      } = domain;
      const {
        x: [rangeMin, rangeMax],
      } = range;

      return ((value - domainMin) / (domainMax - domainMin)) * (rangeMax - rangeMin);
    },
    [domain, range]
  );

  const getBounds = useCallback((): [number, number] => {
    'worklet';

    const leftBound = calcPosition(minMaxX[0]);
    const rightBound = calcPosition(minMaxX[1]);

    return [leftBound, rightBound];
  }, [calcPosition, minMaxX]);

  const path = useDerivedValue(() => {
    if (!x || x.value === REMOVE_SELECT_POS) return Skia.Path.Make().close();

    const {y: rangeY} = range;
    const [leftBound, rightBound] = getBounds();

    const clampedX = clamp(x.value, leftBound, rightBound);

    return Skia.Path.Make().moveTo(clampedX, rangeY[0]).lineTo(clampedX, rangeY[1]);
  }, [x, range, domain.x]);

  const translateX = useMemo(() => range.x[0], [range.x]);

  if (!x || !touchEnabled) return null;

  return (
    <Group transform={[{translateX}]}>
      <Path
        path={path}
        style="stroke"
        strokeWidth={width}
        strokeJoin="round"
        strokeCap="round"
        opacity={opacity}
        color={color}
      >
        {!!dashIntervals && <DashPathEffect intervals={dashIntervals} />}
      </Path>
    </Group>
  );
};
