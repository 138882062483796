import {Label2} from '@/components/texts/Label';
import {useState} from 'react';
import {AnimatePresence, View, useEvent} from 'tamagui';

type InputErrorMessageProps = {
  error: string | undefined;
};

export function InputErrorMessage({error}: InputErrorMessageProps) {
  const [height, setHeight] = useState(0);
  const onLayout = useEvent(({nativeEvent}) => {
    if (nativeEvent.layout.height) {
      setHeight(nativeEvent.layout.height);
    }
  });

  return (
    <AnimatePresence>
      {error && (
        <View
          height={height}
          enterStyle={{height: 0, opacity: 0, y: -10}}
          exitStyle={{height: 0, opacity: 0, y: -10}}
          animation="fast"
        >
          <Label2 position="absolute" onLayout={onLayout} color="$red">
            {error}
          </Label2>
        </View>
      )}
    </AnimatePresence>
  );
}
