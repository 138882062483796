import {SelectInput} from '@/components/inputs/select-input/SelectInput';
import {InputErrorMessage} from '@/components/texts/InputErrorMessage';
import {Label3} from '@/components/texts/Label';
import {IMPORTANT_COUNTRIES} from '@/constants/countries';
import {useGetCountryOptions} from '@/hooks/useGetCountryOptions';
import {FormInputProps} from '@/types/FormInputProps';
import {useMemo} from 'react';
import {FieldValues, useController} from 'react-hook-form';
import {YStack} from 'tamagui';

type FormCountrySelectProps<T extends FieldValues> = FormInputProps<T> & {
  disabled?: boolean;
};

export function FormAllCountrySelect<T extends FieldValues>({
  control,
  name,
  bordered,
  label,
  disabled,
}: FormCountrySelectProps<T>) {
  const countryOptions = useGetCountryOptions();
  const {
    field,
    fieldState: {error},
  } = useController({
    name,
    control,
  });

  const translatedCountries = useMemo(() => {
    const tmp = countryOptions;
    if (!tmp) return undefined;
    const res = tmp.map(_ => ({
      name: _.name,
      key: _.key,
    }));
    const reorderList = [
      ...res.filter(_ => IMPORTANT_COUNTRIES.includes(_.key)),
      ...res.filter(_ => !IMPORTANT_COUNTRIES.includes(_.key)),
    ];
    return reorderList;
  }, [countryOptions]);

  return (
    <YStack gap="$2">
      <Label3 color="$neutral500">{label}</Label3>
      {translatedCountries && (
        <SelectInput
          items={translatedCountries}
          selectedItem={field.value}
          onSelect={field.onChange}
          placeholder="--"
          disabled={disabled}
          wrapperProps={{
            bordered,
          }}
        />
      )}
      <InputErrorMessage error={error?.message} />
    </YStack>
  );
}
