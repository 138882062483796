import {Label3} from '@/components/texts/Label';
import {useAppSelector} from '@/store';
import {useTranslate} from '@tolgee/react';
import {Linking} from 'react-native';
import {styled, useMedia, XStack} from 'tamagui';

/**
 * Web only
 * @returns
 */
export function WebFooter({withCalendly = false}: {withCalendly?: boolean}) {
  const {t} = useTranslate();
  const media = useMedia();
  const language = useAppSelector(state => state.app.language);

  return (
    <XStack
      width="100%"
      paddingBottom="$4"
      marginTop="$8"
      $sm={{
        marginTop: '$4',
      }}
      bottom={0}
      justifyContent={media.gtSm && withCalendly ? 'space-between' : 'center'}
      paddingHorizontal="$2"
      flexWrap="wrap"
      gap="$4"
    >
      {media.gtSm && withCalendly && (
        <XStack gap="$1">
          <FooterText>
            {t('FOOTER.NEED_HELP')}
            <FooterLabel
              color="$primary"
              onPress={() => {
                Linking.openURL(process.env.EXPO_PUBLIC_MAILTO_SERVICE);
              }}
            >
              {t('FOOTER.EMAIL')}
            </FooterLabel>
          </FooterText>
          <FooterText>|</FooterText>
          <FooterLabel
            color="$primary"
            onPress={() => {
              Linking.openURL(
                language === 'en'
                  ? process.env.EXPO_PUBLIC_MAILTO_CALENDLY_EN
                  : process.env.EXPO_PUBLIC_MAILTO_CALENDLY_DE
              );
            }}
          >
            {t('FOOTER.BOOK_A_CALL_BACK')}
          </FooterLabel>
        </XStack>
      )}
      <XStack gap="$2">
        <FooterLabel
          onPress={() => {
            Linking.openURL(process.env.EXPO_PUBLIC_FOOTER_LEGAL_NOTICE);
          }}
        >
          {t('FOOTER.LEGAL_NOTICE')}
        </FooterLabel>
        <FooterText>|</FooterText>
        <FooterLabel
          onPress={() => {
            Linking.openURL(process.env.EXPO_PUBLIC_FOOTER_LEGAL_INFORMATION);
          }}
        >
          {t('FOOTER.LEGAL_INFORMATION')}
        </FooterLabel>
        <FooterText>|</FooterText>
        <FooterLabel
          onPress={() => {
            Linking.openURL(process.env.EXPO_PUBLIC_FOOTER_PRIVACY_POLICY);
          }}
        >
          {t('FOOTER.PRIVACY_POLICY')}
        </FooterLabel>
      </XStack>
    </XStack>
  );
}

const FooterText = styled(Label3, {
  fontSize: 12,
  lineHeight: 16,
  color: '$neutral400',
  variant: 'medium',
  userSelect: 'none',
});

const FooterLabel = styled(FooterText, {
  cursor: 'pointer',
  hoverStyle: {
    textDecorationLine: 'underline',
  },
});
