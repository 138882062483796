import {DefaultTheme, Theme, ThemeProvider} from '@react-navigation/native';
import {PropsWithChildren} from 'react';
import {useTheme} from 'tamagui';

const useNavigationTheme = (): Theme => {
  const colorScheme = 'dark';
  const theme = useTheme();

  return {
    dark: colorScheme === 'dark',
    colors: {
      background: theme.neutralBG.val,
      border: theme.neutral200.val,
      card: theme.surface.val,
      notification: theme.red.val,
      primary: theme.primary.val,
      text: theme.text.val,
    },
    fonts: DefaultTheme.fonts,
  };
};

export function NavigationThemeProvider({children}: PropsWithChildren) {
  const navigationTheme = useNavigationTheme();
  return <ThemeProvider value={navigationTheme}>{children}</ThemeProvider>;
}
