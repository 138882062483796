import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslate} from '@tolgee/react';
import {useRouter} from 'expo-router';
import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {YStack} from 'tamagui';

import {FormAmountInput} from '@/components/inputs/form/FormAmountInput';
import {Heading4} from '@/components/texts/Heading';
import {Label2} from '@/components/texts/Label';
import {InvestmentProposalDepositSchemaType, useValidations} from '@/hooks/useValidations';
import {InterviewContinueButton} from '@/modules/interview/components/InterviewContinueButton';
import useExistingInitialDepositAnswers from '@/modules/interview/hooks/useExistingInitialDepositAnswers';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {Product} from '@/types/fixedTypes/customers.v2/Product';

type Props = {
  product: Product;
  riskProfile?: string;
};

export const InvestmentProposalDepositForm = ({product, riskProfile}: Props) => {
  const defaultValues = useExistingInitialDepositAnswers();
  const {t} = useTranslate();
  const {investmentProposalDepositSchema} = useValidations();
  const posthog = usePostHog();

  const {handleSubmit, control} = useForm({
    resolver: yupResolver(investmentProposalDepositSchema),
    mode: 'onChange',
    context: {
      product,
    },
    defaultValues,
  });
  const router = useRouter();

  const onPress = useCallback(
    async (data: InvestmentProposalDepositSchemaType) => {
      router.navigate({
        pathname: '/interview/planner',
        params: {
          riskProfile,
          initialDeposit: data.INITIAL_DEPOSIT || 0,
          initialMonthlySavings: data.INITIAL_MONTHLY_SAVINGS,
          product,
        },
      });
    },
    [product, riskProfile, router]
  );

  return (
    <YStack gap="$4">
      <YStack gap="$2">
        <Heading4 variant="medium">{t('INTERVIEW.INITIAL_DEPOSIT.TITLE')}</Heading4>
        <Label2 color="$neutral500">
          {product === 'VL_ACCOUNT'
            ? t('INTERVIEW.INITIAL_DEPOSIT.DESCRIPTION_VL')
            : t('INTERVIEW.INITIAL_DEPOSIT.DESCRIPTION')}
        </Label2>
      </YStack>

      {product !== 'VL_ACCOUNT' && (
        <FormAmountInput
          control={control}
          name="INITIAL_DEPOSIT"
          label={t('INTERVIEW.INITIAL_DEPOSIT.ONE_TIME')}
          decimal
        />
      )}
      <FormAmountInput
        control={control}
        name="INITIAL_MONTHLY_SAVINGS"
        label={t('INTERVIEW.INITIAL_DEPOSIT.MONTHLY')}
        decimal
      />

      <InterviewContinueButton
        onPress={handleSubmit(onPress, data => {
          posthog?.capture('form_submit_failed', data);
        })}
      />
    </YStack>
  );
};
