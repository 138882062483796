import {useWebGlAvailability} from '@/components/skia/hooks/useWebGlAvailability';
import {Canvas, Group, Path} from '@shopify/react-native-skia';
import React, {useEffect, useMemo} from 'react';
import {
  ReduceMotion,
  useDerivedValue,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';
import {View, styled, useMedia} from 'tamagui';

type LoadingSpinnerProps = {
  color: string;
  size?: number;
};

const STROKE_WIDTH = 2;

const LoadingSpinnerRaw = React.forwardRef(function LoadingSpinnerRaw(
  {color, size = 32}: LoadingSpinnerProps,
  ref: React.Ref<any>
) {
  const media = useMedia();
  const progress = useSharedValue(0);
  const transform = useDerivedValue(() => {
    return [{rotateZ: progress.value}];
  });

  const responsiveSize = useMemo(() => {
    return media.sm ? size * 0.75 : size;
  }, [media.sm, size]);

  useEffect(() => {
    progress.value = withRepeat(
      withTiming(Math.PI * 2, {duration: 1000, reduceMotion: ReduceMotion.Never}),
      -1
    );
  }, [progress]);

  const path = useMemo(() => {
    const radius = responsiveSize / 2 - STROKE_WIDTH;
    return `
    M ${responsiveSize / 2} ${responsiveSize / 2}
    m ${radius}, 0
    a ${radius},${radius} 0 1,0 ${-radius * 2},0
    a ${radius},${radius} 0 1,0 ${radius * 2},0
    `;
  }, [responsiveSize]);

  const webGlAvailable = useWebGlAvailability();

  return (
    <View backgroundColor="transparent" ref={ref} testID="loading-spinner">
      {webGlAvailable && (
        <Canvas style={{width: responsiveSize, height: responsiveSize}}>
          <Group origin={{x: responsiveSize / 2, y: responsiveSize / 2}} transform={transform}>
            <Path
              path={path}
              color={color}
              opacity={0.35}
              style="stroke"
              strokeJoin="round"
              strokeWidth={STROKE_WIDTH}
            />
            <Path
              path={path}
              color={color}
              style="stroke"
              strokeJoin="round"
              strokeWidth={STROKE_WIDTH}
              start={0.8}
              end={1}
            />
          </Group>
        </Canvas>
      )}
    </View>
  );
});

export const LoadingSpinner = styled(
  LoadingSpinnerRaw,
  {},
  {
    accept: {
      color: 'color',
    } as const,
  }
);
