import {yupResolver} from '@hookform/resolvers/yup';
import {captureException} from '@sentry/react-native';
import {useTranslate} from '@tolgee/react';
import {Href, useRouter} from 'expo-router';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {AnimatePresence, YStack} from 'tamagui';

import {LabelCheckBox} from '@/components/inputs/LabelCheckbox';
import {FormTextInput} from '@/components/inputs/form/FormTextInput';
import {SelectInput} from '@/components/inputs/select-input/SelectInput';
import {Heading4} from '@/components/texts/Heading';
import {Label3} from '@/components/texts/Label';
import {AnimatedHeightView} from '@/components/views/AnimatedHeightView';
import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {useValidations} from '@/hooks/useValidations';
import {InterviewContinueButton} from '@/modules/interview/components/InterviewContinueButton';
import {PoliticallyExposedPersonModal} from '@/modules/onboarding/components/PoliticallyExposedPersonModal';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {
  useCountriesQuery,
  useIndustriesQuery,
  useProfessionsQuery,
} from '@/store/queries/referenceApi';
import {useGetSelfUserQuery, useUpdateUserMutation} from '@/store/queries/usersApi';
import {SelectInputItem} from '@/types/FormInputProps';
import {Profession} from '@/types/api/refdata.v2';
import {ModalRef} from '@/types/modal';

type RefData = Profession;

type JobFormValues = {
  profession: string;
  industry: string;
  employmentCountry?: string;
  politicallyExposedPerson?: boolean;
  beneficialOwner?: boolean;
  beneficialOwnerOfCompany?: string;
};

export const JobScreen = ({href}: {href: Href}) => {
  const {t} = useTranslate();

  const [professionsItems, setProfessionItems] = useState<SelectInputItem[]>([]);
  const [industriesItems, setIndustriesItems] = useState<SelectInputItem[]>([]);
  const [countriesItems, setCountriesItems] = useState<SelectInputItem[]>([]);
  const [showCountry, setShowCountry] = useState(false);
  const [loading, setLoading] = useState(false);
  const posthog = usePostHog();

  const politicallyExposedPersonModalRef = useRef<ModalRef>(null);

  const {careerDetailsFormSchema} = useValidations();

  const {data: currentUser} = useGetSelfUserQuery();

  const [updateUser] = useUpdateUserMutation();

  const router = useRouter();

  const {
    control,
    setValue,
    watch,
    formState: {errors},
    handleSubmit,
  } = useForm<JobFormValues>({
    values: {
      profession: currentUser?.profession ?? '',
      industry: currentUser?.industry ?? '',
      employmentCountry: currentUser?.employmentCountry,
      politicallyExposedPerson: currentUser?.politicallyExposedPerson,
      beneficialOwner: currentUser?.beneficialOwner ?? false,
      beneficialOwnerOfCompany: currentUser?.beneficialOwnerOfCompany,
    },
    resolver: yupResolver(careerDetailsFormSchema),
  });
  const {profession, industry, employmentCountry, politicallyExposedPerson, beneficialOwner} =
    watch();

  const {data: professions} = useProfessionsQuery();
  const {data: industries} = useIndustriesQuery({
    profession,
  });
  const {data: countries} = useCountriesQuery();

  const {getAll} = useRefdataLocalizable();

  const countryCheck = useCallback(
    (profession: string) => {
      const showCountry = profession === 'CEO' || profession === 'CHM';

      setShowCountry(showCountry);

      if (showCountry) {
        setValue('employmentCountry', 'DE', {shouldValidate: true});
      } else {
        setValue('employmentCountry', undefined, {shouldValidate: true});
      }
    },
    [setValue]
  );

  useEffect(() => {
    if (!profession) return;

    countryCheck(profession);
  }, [profession, countryCheck]);

  const createSelectInputData = useCallback(
    (data?: RefData[]) => {
      const localizebleData = getAll(data);

      if (!localizebleData) {
        return [];
      }

      return localizebleData.map<SelectInputItem>(prof => ({
        key: prof.code,
        name: prof.label,
      }));
    },
    [getAll]
  );

  useEffect(() => {
    const professionItems = createSelectInputData(professions);

    setProfessionItems(professionItems);
  }, [createSelectInputData, professions]);

  useEffect(() => {
    const industriesItems = createSelectInputData(industries);

    setIndustriesItems(industriesItems);
  }, [createSelectInputData, industries]);

  useEffect(() => {
    const countriesItems = createSelectInputData(countries);

    setCountriesItems(countriesItems);
  }, [createSelectInputData, countries]);

  const handleItemSelect = useCallback(
    (select: keyof JobFormValues) => (value: string) => {
      switch (select) {
        case 'profession': {
          setValue('profession', value, {shouldValidate: true});

          setValue('industry', '', {shouldValidate: true});
          countryCheck(value);

          return;
        }
        case 'industry': {
          setValue('industry', value, {shouldValidate: true});

          return;
        }
        case 'employmentCountry': {
          setValue('employmentCountry', value, {shouldValidate: true});
        }
      }
    },
    [setValue, countryCheck]
  );

  const handlePoliticallyExposedChanged = useCallback(
    (checked: boolean) => {
      setValue('politicallyExposedPerson', checked);

      if (checked && politicallyExposedPersonModalRef.current) {
        politicallyExposedPersonModalRef.current.open(true);
      }
    },
    [setValue]
  );

  const handleBeneficialOwnerChanged = useCallback(
    (checked: boolean) => {
      setValue('beneficialOwner', checked);

      if (!checked) {
        setValue('beneficialOwnerOfCompany', undefined);
      }
    },
    [setValue]
  );

  const handleValidSubmit = useCallback(
    async (values: JobFormValues) => {
      if (!currentUser) return;

      const {
        profession,
        industry,
        employmentCountry,
        politicallyExposedPerson,
        beneficialOwner,
        beneficialOwnerOfCompany,
      } = values;

      if (politicallyExposedPerson) {
        router.navigate('/onboarding/personal-details/politically-person');

        return;
      }

      const changes: JobFormValues = {
        profession,
        industry,
        beneficialOwner: beneficialOwner ?? false,
        politicallyExposedPerson: politicallyExposedPerson ?? false,
      };

      if (employmentCountry) {
        changes.employmentCountry = employmentCountry;
      } else {
        changes.employmentCountry = undefined;
      }

      if (beneficialOwner && beneficialOwnerOfCompany) {
        changes.beneficialOwnerOfCompany = beneficialOwnerOfCompany;
      } else {
        changes.beneficialOwnerOfCompany = undefined;
      }

      try {
        setLoading(true);

        await updateUser({currentUser, changes}).unwrap();

        router.navigate(href);
      } catch (error) {
        captureException(error);
      } finally {
        setLoading(false);
      }
    },
    [currentUser, router, updateUser, href]
  );

  return (
    <YStack gap="$6">
      <Heading4 variant="medium">{t('PERSONAL_DETAILS.TITLE_WHAT_DO_YOU_DO_FOR_LIVING')}</Heading4>
      <YStack gap="$4">
        <YStack gap="$2">
          <Label3 color="$neutral500">{t('PERSONAL_DETAILS.PROFESSION')}</Label3>
          <SelectInput
            items={professionsItems}
            placeholder={t('PERSONAL_DETAILS.PROFESSION')}
            selectedItem={profession}
            onSelect={handleItemSelect('profession')}
            error={errors.profession?.message}
            testID="job-profession"
          />
        </YStack>
        <YStack gap="$2">
          <Label3 color="$neutral500">{t('PERSONAL_DETAILS.INDUSTRY')}</Label3>
          <SelectInput
            items={industriesItems}
            placeholder={t('PERSONAL_DETAILS.INDUSTRY')}
            selectedItem={industry}
            onSelect={handleItemSelect('industry')}
            error={errors.industry?.message}
            testID="job-industry"
          />
        </YStack>
        <AnimatedHeightView>
          <AnimatePresence>
            {showCountry && (
              <YStack gap="$2">
                <Label3 color="$neutral500">{t('PERSONAL_DETAILS.EMPLOYMENT_COUNTRY')}</Label3>
                <SelectInput
                  items={countriesItems}
                  placeholder={t('PERSONAL_DETAILS.IN_WHICH_COUNTRY_YOU_PRACTICE_YOUR_PROFESSION')}
                  onSelect={handleItemSelect('employmentCountry')}
                  selectedItem={employmentCountry}
                  error={errors.employmentCountry?.message}
                />
              </YStack>
            )}
          </AnimatePresence>
        </AnimatedHeightView>
        <LabelCheckBox
          label={t('PERSONAL_DETAILS.ARE_YOU_BENEFICIAL_OWNER')}
          checked={beneficialOwner ?? false}
          onCheckedChange={handleBeneficialOwnerChanged}
        />
        <LabelCheckBox
          label={t('PERSONAL_DETAILS.ARE_YOU_POLITICALLY_EXPOSED_PERSON')}
          checked={politicallyExposedPerson ?? false}
          onCheckedChange={handlePoliticallyExposedChanged}
        />
        <AnimatedHeightView>
          <AnimatePresence>
            {!!beneficialOwner && (
              <FormTextInput
                control={control}
                name="beneficialOwnerOfCompany"
                label={t('PERSONAL_DETAILS.NAME_OF_THE_COMPANY')}
              />
            )}
          </AnimatePresence>
        </AnimatedHeightView>
      </YStack>
      <InterviewContinueButton
        onPress={handleSubmit(handleValidSubmit, data => {
          posthog?.capture('form_submit_failed', data);
        })}
        isLoading={loading}
      >
        {t('CONTINUE')}
      </InterviewContinueButton>
      <PoliticallyExposedPersonModal ref={politicallyExposedPersonModalRef} />
    </YStack>
  );
};
