import {FormSelectInput} from '@/components/inputs/form/FormSelectInput';
import {useLocalizedMonths} from '@/hooks/useLocalizedMonths';
import {EmployerStartDateSchemaType, useValidations} from '@/hooks/useValidations';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useAppSelector} from '@/store';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslate} from '@tolgee/react';
import React, {forwardRef, useEffect, useImperativeHandle, useMemo} from 'react';
import {useForm} from 'react-hook-form';

import {YStack} from 'tamagui';

type EmployerStartDateFormProps = {
  onValid: (data: EmployerStartDateSchemaType) => void;
};

export type SubmitFormRef = {submit: () => Promise<void>};

export const EmployerStartDateForm = forwardRef<any, EmployerStartDateFormProps>(
  function EmployerStartDateForm({onValid}, ref) {
    const {t} = useTranslate();
    const {employerStartDateSchema} = useValidations();
    const employer = useAppSelector(state => state.onboarding.personalDetails.employer);
    const months = useLocalizedMonths();
    const posthog = usePostHog();

    const years = useMemo(() => {
      const currentYear = new Date().getFullYear();
      return Array.from({length: 5}, (_, i) => {
        const key = (i + currentYear).toString();
        return {name: key, key};
      });
    }, []);

    useImperativeHandle(ref, () => ({
      submit: handleSubmit(onValid, data => {
        posthog?.capture('form_submit_failed', data);
      }),
    }));

    const {handleSubmit, control, setValue} = useForm({
      resolver: yupResolver(employerStartDateSchema),
      mode: 'onChange',
    });

    useEffect(() => {
      if (!employer?.savingsStartDate) return;

      const date = new Date(employer?.savingsStartDate);
      const year = date.getFullYear().toString();
      const month = (date.getMonth() + 1).toString();
      setValue('startDateMonth', month);
      setValue('startDateYear', year);
    }, [setValue, employer]);

    return (
      <YStack gap="$4">
        <FormSelectInput
          items={months}
          control={control}
          name="startDateMonth"
          label={t('PERSONAL_DETAILS.MONTH')}
          placeholder={t('PERSONAL_DETAILS.MONTH.SELECT')}
        />
        <FormSelectInput
          items={years}
          control={control}
          name="startDateYear"
          label={t('PERSONAL_DETAILS.YEAR')}
          placeholder={t('PERSONAL_DETAILS.YEAR.SELECT')}
        />
      </YStack>
    );
  }
);
