import {Button} from '@/components/buttons/Button';
import {SquarePlusIcon} from '@/components/icons/SquarePlusIcon';
import {Button1} from '@/components/texts/Button';
import {Skeleton} from '@/components/views/Skeleton';
import {useGetCustomerAccountsQuery} from '@/store/queries/customersApi';
import {useGetValidOrdersQuery} from '@/store/queries/ordersApi';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';
import {useTranslate} from '@tolgee/react';
import {Link} from 'expo-router';
import {XStack} from 'tamagui';

const CreateNewAccountButton = () => {
  const {t} = useTranslate();
  const {data: user, isFetching: isUserFetching} = useGetSelfUserQuery();
  const {data: validOrders, isFetching: isOrdersFetching} = useGetValidOrdersQuery();
  const {data: customers, isFetching: isCustomersFetching} = useGetCustomerAccountsQuery();

  if (
    user?.id === undefined ||
    isUserFetching ||
    (!validOrders && isOrdersFetching) ||
    isCustomersFetching
  )
    return <Skeleton width="30%" height={55} />;

  const customerCount = customers?.totalCount ?? 0;
  const ordersCount =
    validOrders?.items?.filter((el: any) => el.status !== 'REJECTED')?.length ?? 0;
  const actionRequired =
    (validOrders?.items?.filter((el: any) => el.requiredAction !== undefined)?.length ?? 0) > 0;

  // TODO: remove any after https://ginmon.atlassian.net/browse/ORDERS-450
  const total = customerCount + ordersCount;

  const MAX_ACCOUNTS = parseInt(process.env.EXPO_PUBLIC_MAX_ACCOUNTS, 10);

  if (total >= MAX_ACCOUNTS) return null;

  return (
    <Link
      asChild
      href={{
        pathname: '/interview/product-choice',
        params: {userId: String(user?.id)},
      }}
    >
      {actionRequired ? (
        <Button
          paddingVertical="$3"
          paddingHorizontal="$7"
          borderRadius="$0.5"
          marginTop="$3"
          secondary
        >
          {t('HOME.OPEN_NEW_ACCOUNT')}
        </Button>
      ) : (
        <Button
          paddingHorizontal="$3"
          paddingRight="$8"
          borderRadius="$0.75"
          height={60}
          $sm={{borderRadius: '$1', height: 64, paddingHorizontal: '$3'}}
          marginTop="$3"
        >
          <XStack alignItems="center" gap="$4" $sm={{alignSelf: 'flex-start'}}>
            <SquarePlusIcon
              height={36}
              width={36}
              $sm={{height: 40, width: 40}}
              justifyContent="center"
              alignContent="center"
            />
            <Button1 variant="medium" color="$white">
              {t('HOME.OPEN_NEW_ACCOUNT')}
            </Button1>
          </XStack>
        </Button>
      )}
    </Link>
  );
};

export default CreateNewAccountButton;
