import {Badge} from '@/components/buttons/Badge';
import {Label2, Label3} from '@/components/texts/Label';
import {useTranslate} from '@tolgee/react';
import React, {useMemo} from 'react';
import {GetProps} from 'tamagui';

type BadgeProps = Omit<GetProps<typeof Badge>, 'children' | 'ref'> & {
  selectedStrategy: string | undefined;
  recommendedStrategy: string | undefined;
};

export const SuggestedStrategyBadge = ({selectedStrategy, recommendedStrategy}: BadgeProps) => {
  const {t} = useTranslate();
  const estimation: number = useMemo(() => {
    if (recommendedStrategy === undefined || selectedStrategy === undefined) return 0;
    if (getNumberFromStrategy(selectedStrategy) === getNumberFromStrategy(recommendedStrategy)) {
      return 0;
    } else if (
      getNumberFromStrategy(selectedStrategy) > getNumberFromStrategy(recommendedStrategy)
    ) {
      return 1;
    } else {
      return -1;
    }
  }, [selectedStrategy, recommendedStrategy]);

  return (
    <>
      {estimation === 0 && (
        <Badge
          alignSelf="flex-start"
          backgroundColor="$primary"
          borderColor="transparent"
          marginBottom="$4"
          paddingHorizontal="$4"
          paddingVertical="$1.5"
        >
          <Label3 color="$white" variant="medium">
            {t('PLANNER.STRATEGY-INFO.SUGGESTED')}
          </Label3>
        </Badge>
      )}
      {estimation === 1 && (
        <Badge
          alignSelf="flex-start"
          backgroundColor="$redDark"
          borderColor="transparent"
          marginBottom="$4"
          paddingHorizontal="$4"
          paddingVertical="$1.5"
        >
          <Label2 color="$white">{t('PLANNER.STRATEGY-INFO.RISKIER')}</Label2>
        </Badge>
      )}
    </>
  );
};

const getNumberFromStrategy = (strategy: string): number => {
  if (!strategy) {
    return 0;
  }
  let strategyNumber = parseInt(strategy.slice(2, 4), 10);
  if (strategyNumber > 10) {
    strategyNumber -= 10;
  }
  return strategyNumber;
};
