import {Canvas, Group} from '@shopify/react-native-skia';
import {PropsWithChildren, ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
import {GestureDetector} from 'react-native-gesture-handler';
import {runOnJS, useDerivedValue, useSharedValue} from 'react-native-reanimated';

import {useWebGlAvailability} from '@/components/skia/hooks/useWebGlAvailability';
import {
  INITIAL_DOMAIN,
  INITIAL_MIN_MAX,
  INITIAL_OFFSET_DATA,
  INITIAL_SIZE,
  REMOVE_SELECT_POS,
} from '@/constants/skia';
import {hapticFeedback} from '@/helpers/hapticFeedback';
import {useAppSelector} from '@/store';
import {
  Axis,
  AxisPosition,
  ChartContext,
  Domain,
  InnerSelectedPoint,
  NumberTuple,
  OffsetData,
  SelectedPoint,
  Size,
  XAxis,
  XYTuple,
  YAxis,
} from '@/types/skia';
import {useTheme} from 'tamagui';
import {ChartContextProvider} from './context';
import {useChartTouchHandler} from './hooks';
import {getMinMaxXYTuple} from './utils';

type Props = {
  height?: number;
  width?: number;
  domain?: Domain;
  onSelect?: (selectedPoints: SelectedPoint[]) => void;
  removeHorizontalOffset?: boolean;
  overlapYAxes?: boolean;
  hapticEffect?: boolean;
  removeSelectActionOnEnd?: boolean;
  webGlFallback?: ReactNode;
  theme: ReturnType<typeof useTheme>;
};

export const Chart = ({
  children,
  height,
  width,
  domain,
  onSelect,
  removeHorizontalOffset,
  overlapYAxes,
  hapticEffect,
  removeSelectActionOnEnd,
  webGlFallback,
  theme,
}: PropsWithChildren<Props>) => {
  const [size, setSize] = useState<Size>(INITIAL_SIZE);
  const [innerDomain, setInnerDomain] = useState<Domain>(INITIAL_DOMAIN);
  const [linesDomain, setLinesDomain] = useState<Domain>(INITIAL_DOMAIN);
  const [offsetData, setOffsetData] = useState<OffsetData>(INITIAL_OFFSET_DATA);
  const [minMaxX, setMinMaxX] = useState<NumberTuple>(INITIAL_MIN_MAX);
  const [linesDomainsMap, setLinesDomainsMap] = useState<Map<string, Domain>>(new Map());
  const [offsetMap, setOffsetMap] = useState<Map<string, OffsetData>>(new Map());
  const [xAxis, setXAxis] = useState<XAxis>({top: undefined, bottom: undefined});
  const [yAxis, setYAxis] = useState<YAxis>({left: undefined, right: undefined});

  const selectedPoints = useSharedValue<SelectedPoint[]>([]);

  const discreteMode = useAppSelector(state => state.app.discreteMode);
  const language = useAppSelector(state => state.app.language);
  const x = useSharedValue<number>(REMOVE_SELECT_POS);
  const currInterpolatedXIndex = useSharedValue(-1);

  const offset = useMemo(() => {
    const leftYAxisOffset = overlapYAxes ? 0 : yAxis?.left?.size?.width ?? 0;
    let offset = leftYAxisOffset + offsetData.width / 2;

    if (!removeHorizontalOffset) {
      offset += offsetData.pointSize / 2;
    }

    return offset;
  }, [removeHorizontalOffset, offsetData, yAxis?.left, overlapYAxes]);

  const gesture = useChartTouchHandler(x, offset, !!onSelect, removeSelectActionOnEnd);

  useEffect(() => {
    if (!domain) return;

    setInnerDomain(domain);
  }, [domain]);

  const handleCanvasMounted = useCallback((size: Size) => {
    setSize(size);
  }, []);

  const updateAxis = useCallback((position: AxisPosition, axis?: Partial<Axis>) => {
    if (position === 'top' || position === 'bottom') {
      setXAxis(prev => ({
        ...prev,
        [position]: axis === undefined ? undefined : {...prev[position], ...axis},
      }));

      return;
    }

    setYAxis(prev => ({
      ...prev,
      [position]: axis === undefined ? undefined : {...prev[position], ...axis},
    }));
  }, []);

  const updateXYTuple = useCallback(
    (key: string, tuple?: XYTuple) => (prevMap: Map<string, XYTuple>) => {
      const newMap = new Map(prevMap);

      if (newMap.has(key)) {
        if (tuple) {
          newMap.set(key, {...tuple});
        } else {
          newMap.delete(key);
        }
      } else {
        if (tuple) {
          newMap.set(key, {...tuple});
        }
      }

      return newMap;
    },
    []
  );

  const updateLineDomain = useCallback(
    (lineKey: string, lineDomain?: Domain) => {
      setLinesDomainsMap(updateXYTuple(lineKey, lineDomain));
    },
    [updateXYTuple]
  );

  const updateOffsetData = useCallback((lineKey: string, offsetData?: OffsetData) => {
    setOffsetMap(prev => {
      const newMap = new Map(prev);

      if (newMap.has(lineKey)) {
        if (offsetData === undefined) {
          newMap.delete(lineKey);
        } else {
          newMap.set(lineKey, {pointSize: offsetData.pointSize, width: offsetData.width});
        }
      } else {
        if (offsetData !== undefined) {
          newMap.set(lineKey, {pointSize: offsetData.pointSize, width: offsetData.width});
        }
      }

      return newMap;
    });
  }, []);

  const updateSelectedPoint = useCallback((lineKey: string, selectedPoint?: InnerSelectedPoint) => {
    'worklet';

    if (selectedPoint) {
      currInterpolatedXIndex.value = selectedPoint.xIndex;
    } else {
      currInterpolatedXIndex.value = -1;
    }

    selectedPoints.modify(value => {
      'worklet';

      const selectedPointIndex = value.findIndex(point => point.key === lineKey);

      if (selectedPointIndex !== -1) {
        if (selectedPoint) {
          const {xIndex, ...rest} = selectedPoint;

          value[selectedPointIndex] = rest;
        } else {
          value.splice(selectedPointIndex, 1);
        }
      } else {
        if (selectedPoint) {
          const {xIndex, ...rest} = selectedPoint;

          value.push(rest);
        }
      }

      return value;
    });
  }, []);

  useDerivedValue(() => {
    if (!hapticEffect) return;

    runOnJS(hapticFeedback)();
  }, [currInterpolatedXIndex, hapticEffect]);

  useDerivedValue(() => {
    if (!onSelect) return;

    onSelect(selectedPoints.value);
  }, [selectedPoints, onSelect]);

  const checkDomain = useCallback(
    (newDomain: Domain) => (prevDomain: Domain) => {
      const {x: prevX, y: prevY} = prevDomain;
      const {x: newX, y: newY} = newDomain;

      if (
        prevX[0] === newX[0] &&
        prevX[1] === newX[1] &&
        prevY[0] === newY[0] &&
        prevY[1] === newY[1]
      ) {
        return prevDomain;
      }

      return newDomain;
    },
    []
  );

  useEffect(() => {
    if (linesDomainsMap.size === 0) {
      setLinesDomain(INITIAL_DOMAIN);

      return;
    }

    const {xyTuple: newDomain, minMaxX} = getMinMaxXYTuple([...linesDomainsMap.values()]);

    setLinesDomain(checkDomain(newDomain));
    setMinMaxX(minMaxX);
  }, [linesDomainsMap, checkDomain]);

  useEffect(() => {
    const {top: {axisDomain: topAxisDomain} = {}, bottom: {axisDomain: bottomAxisDomain} = {}} =
      xAxis;
    const {left: {axisDomain: lefAxisDomain} = {}, right: {axisDomain: rightAxisDomain} = {}} =
      yAxis;

    const sortedXAxis = [...(topAxisDomain ?? []), ...(bottomAxisDomain ?? [])].sort(
      (a, b) => a - b
    );
    const sortedYAxis = [...(lefAxisDomain ?? []), ...(rightAxisDomain ?? [])].sort(
      (a, b) => a - b
    );

    const firstX = sortedXAxis.at(0);
    const lastX = sortedXAxis.at(-1);
    const firstY = sortedYAxis.at(0);
    const lastY = sortedYAxis.at(-1);

    let xAxisDomain = linesDomain.x;
    let yAxisDomain = linesDomain.y;

    if (firstX !== undefined && lastX !== undefined) {
      xAxisDomain = [firstX, lastX];
    }

    if (firstY !== undefined && lastY !== undefined) {
      yAxisDomain = [firstY, lastY];
    }

    const axisDomain: Domain = {
      x: xAxisDomain,
      y: yAxisDomain,
    };

    const {xyTuple: domain} = getMinMaxXYTuple([linesDomain, axisDomain]);

    setInnerDomain(checkDomain(domain));
  }, [checkDomain, linesDomain, xAxis, yAxis]);

  useEffect(() => {
    if (offsetMap.size === 0) {
      setOffsetData(INITIAL_OFFSET_DATA);

      return;
    }

    let maxPointSize = Number.NEGATIVE_INFINITY;
    let maxLineWidth = Number.NEGATIVE_INFINITY;

    offsetMap.forEach(({pointSize, width}) => {
      if (pointSize > maxPointSize) {
        maxPointSize = pointSize;
      }

      if (width > maxLineWidth) {
        maxLineWidth = width;
      }
    });

    setOffsetData({pointSize: maxPointSize, width: maxLineWidth});
  }, [offsetMap]);

  const contextValue = useMemo<ChartContext>(
    () => ({
      size,
      domain: innerDomain,
      linesDomain,
      offsetData,
      minMaxX,
      xAxis,
      yAxis,
      discreteMode,
      updateLineDomain,
      updateOffsetData,
      updateAxis,
      lang: language,
      x,
      updateSelectedPoint,
      removeHorizontalOffset,
      overlapYAxes,
      chartHeight: height,
      touchEnabled: !!onSelect,
      theme,
    }),
    [
      size,
      innerDomain,
      linesDomain,
      offsetData,
      xAxis,
      yAxis,
      discreteMode,
      updateLineDomain,
      updateOffsetData,
      updateAxis,
      language,
      x,
      updateSelectedPoint,
      removeHorizontalOffset,
      overlapYAxes,
      height,
      minMaxX,
      onSelect,
      theme,
    ]
  );

  const webGlAvailable = useWebGlAvailability();

  if (webGlAvailable) {
    return (
      <GestureDetector gesture={gesture}>
        <Canvas
          style={{flex: 1, width}}
          onLayout={event =>
            handleCanvasMounted({
              width: event.nativeEvent.layout.width,
              height: event.nativeEvent.layout.height,
            })
          }
        >
          <ChartContextProvider value={contextValue}>
            <Group>{children}</Group>
          </ChartContextProvider>
        </Canvas>
      </GestureDetector>
    );
  }

  if (webGlFallback) {
    return webGlFallback;
  }

  return null;
};
