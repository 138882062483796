import {Button} from '@/components/buttons/Button';
import SvgFile from '@/components/icons/SvgFile';
import {Modal} from '@/components/modal/Modal';
import {Heading6} from '@/components/texts/Heading';
import {Label1, Label2} from '@/components/texts/Label';
import useRefdataLocalizable, {LocalizableRefData} from '@/hooks/useRefdataLocalizable';
import {AddThemeModalItem} from '@/modules/orders/components/Theme/AddThemeModalItem';
import {ThemeItemDepositAddButton} from '@/modules/orders/components/Theme/ThemeItemDepositAddButton';
import {CustomerAllocationTheme} from '@/modules/orders/types/CustomerAllocationTheme';
import {useInvestmentThemesQuery} from '@/store/queries/referenceApi';
import {InvestmentTheme} from '@/types/api/refdata.v2';
import {ModalRef} from '@/types/modal';
import {BottomSheetScrollView, BottomSheetView} from '@gorhom/bottom-sheet';
import {useTranslate} from '@tolgee/react';
import {forwardRef, useImperativeHandle, useMemo, useRef, useState} from 'react';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {ScrollView, View, XStack, useMedia} from 'tamagui';

type Props = {
  onThemesSelected: (selectedThemes: CustomerAllocationTheme[]) => void;
  existingThemes: CustomerAllocationTheme[];
  withoutTitle?: boolean;
};

export const AddThemeModal = forwardRef<any, Props>(
  ({onThemesSelected, existingThemes, withoutTitle = false}, ref) => {
    const modalRef = useRef<ModalRef>(null);
    useImperativeHandle(ref, () => ({
      open: modalRef.current?.open,
    }));
    const {getAll} = useRefdataLocalizable();
    const media = useMedia();

    const {data: investmentThemes} = useInvestmentThemesQuery();
    const translatedInvestmentThemes = useMemo(
      () => getAll(investmentThemes),
      [investmentThemes, getAll]
    );
    const {t} = useTranslate();
    const [selectedThemes, setSelectedThemes] = useState<CustomerAllocationTheme[]>([]);

    const filteredThemes = useMemo(() => {
      if (!translatedInvestmentThemes) return [];
      return translatedInvestmentThemes.filter(_ => !existingThemes.some(t => t.code === _.code));
    }, [translatedInvestmentThemes, existingThemes]);

    // TODO: check top choice UI design
    const moneyMarketTheme = useMemo(
      () => filteredThemes.find(_ => _.code === 'MONEY_MARKET'),
      [filteredThemes]
    );
    const regularThemes = useMemo(
      () => filteredThemes.filter(_ => _.code !== 'MONEY_MARKET'),
      [filteredThemes]
    );

    return (
      <Modal ref={modalRef}>
        <Modal.Trigger asChild>
          <ThemeItemDepositAddButton>
            <Label2>{t('ORDER_SUMMARY.THEME_DEPOSIT.ADD-NEW-THEME')}</Label2>
          </ThemeItemDepositAddButton>
        </Modal.Trigger>
        {media.sm ? (
          <Modal.Content typeContainer="CUSTOM">
            <BottomSheetView>
              <AddThemeModalTitle
                existingThemes={existingThemes}
                selectedThemes={selectedThemes}
                setSelectedThemes={setSelectedThemes}
                onThemesSelected={onThemesSelected}
                modalRef={modalRef}
              />
            </BottomSheetView>
            <BottomSheetScrollView>
              <AddThemeModalItems
                moneyMarketTheme={moneyMarketTheme}
                regularThemes={regularThemes}
                selectedThemes={selectedThemes}
                setSelectedThemes={setSelectedThemes}
              />
            </BottomSheetScrollView>
          </Modal.Content>
        ) : (
          <Modal.Content width="100%">
            <AddThemeModalTitle
              existingThemes={existingThemes}
              selectedThemes={selectedThemes}
              setSelectedThemes={setSelectedThemes}
              onThemesSelected={onThemesSelected}
              modalRef={modalRef}
            />
            <ScrollView>
              <AddThemeModalItems
                moneyMarketTheme={moneyMarketTheme}
                regularThemes={regularThemes}
                selectedThemes={selectedThemes}
                setSelectedThemes={setSelectedThemes}
              />
            </ScrollView>
          </Modal.Content>
        )}
      </Modal>
    );
  }
);

const AddThemeModalTitle = ({
  selectedThemes,
  modalRef,
  onThemesSelected,
  existingThemes,
  setSelectedThemes,
}: {
  selectedThemes: CustomerAllocationTheme[];
  modalRef: React.RefObject<ModalRef>;
  onThemesSelected: (selectedThemes: CustomerAllocationTheme[]) => void;
  existingThemes: CustomerAllocationTheme[];
  setSelectedThemes: (selectedThemes: CustomerAllocationTheme[]) => void;
}) => {
  const {t} = useTranslate();
  return (
    <XStack height="$7" justifyContent="center">
      {selectedThemes.length > 0 ? (
        <Button
          paddingVertical="$1"
          height="$7"
          onPress={() => {
            onThemesSelected(selectedThemes);
            setSelectedThemes([]);
            modalRef.current?.open(false);
          }}
        >
          <XStack alignItems="center">
            <SvgFile name="plus" color="$neutral0" />
            <Label2 color="$neutral0">{t('ORDER_SUMMARY.THEME_DEPOSIT.ADD')}</Label2>
          </XStack>
        </Button>
      ) : (
        <View key="moti-add-description">
          <Heading6 variant="medium" style={{textAlign: 'center'}}>
            {existingThemes.length === 0
              ? t('ORDER_SUMMARY.THEME_DEPOSIT.ADD-NEW-THEME-FIRST')
              : t('ORDER_SUMMARY.THEME_DEPOSIT.ADD-NEW-THEME')}
          </Heading6>
        </View>
      )}
    </XStack>
  );
};

const AddThemeModalItems = ({
  moneyMarketTheme,
  selectedThemes,
  setSelectedThemes,
  regularThemes,
}: {
  moneyMarketTheme?: LocalizableRefData<InvestmentTheme>;
  selectedThemes: CustomerAllocationTheme[];
  setSelectedThemes: React.Dispatch<React.SetStateAction<CustomerAllocationTheme[]>>;
  regularThemes: LocalizableRefData<InvestmentTheme>[];
}) => {
  const {bottom: safeBottomArea} = useSafeAreaInsets();
  const {t} = useTranslate();
  return (
    <View height="100%" gap="$3" paddingHorizontal="$2" paddingBottom={safeBottomArea || '$3'}>
      {moneyMarketTheme && (
        <>
          <Label1 color="$neutral500">
            {t('ORDER_SUMMARY.THEME_DEPOSIT.ADD-NEW-SECTION-TOP')}
          </Label1>
          <View gap="$3" flexDirection="column" $gtSm={{flexDirection: 'row'}} flexWrap="wrap">
            <AddThemeModalItem
              key={moneyMarketTheme.code}
              thm={moneyMarketTheme}
              selectedThemes={selectedThemes}
              setSelectedThemes={setSelectedThemes}
            />
          </View>
        </>
      )}

      {regularThemes.length > 0 && (
        <Label1 color="$neutral500">{t('ORDER_SUMMARY.THEME_DEPOSIT.ADD-NEW-SECTION-ALL')}</Label1>
      )}
      <View gap="$3" flexDirection="column" $gtSm={{flexDirection: 'row'}} flexWrap="wrap">
        {regularThemes.map(thm => {
          return (
            <AddThemeModalItem
              key={thm.code}
              thm={thm}
              selectedThemes={selectedThemes}
              setSelectedThemes={setSelectedThemes}
            />
          );
        })}
      </View>
    </View>
  );
};
