import {Heading4} from '@/components/texts/Heading';
import {EmployerAddressDetailsSchemaType} from '@/hooks/useValidations';
import {SubmitFormRef} from '@/modules/interview/components/FinancialSituationForm';
import {InterviewContinueButton} from '@/modules/interview/components/InterviewContinueButton';
import {EmployerAddressForm} from '@/modules/onboarding/components/forms/EmployerAddressForm';
import {InfoBox} from '@/modules/orders/components/InfoBox';
import {useAppDispatch} from '@/store';
import {saveEmployer} from '@/store/reducers/onboarding';
import {useTranslate} from '@tolgee/react';
import {Href, useRouter} from 'expo-router';
import {useCallback, useRef} from 'react';
import {YStack} from 'tamagui';

export function EmployerAddress({href}: {href: Href}) {
  const ref = useRef<SubmitFormRef>(null);
  const {t} = useTranslate();
  const router = useRouter();
  const dispatch = useAppDispatch();

  const onPress = useCallback(() => {
    ref.current?.submit();
  }, []);

  const onValidSubmit = useCallback(
    async (data: EmployerAddressDetailsSchemaType) => {
      const {street, streetNumber, postCode, city, employer} = data;
      const address = {
        street,
        streetNumber,
        postCode,
        city,
        country: 'DE' as 'DE',
      };
      dispatch(saveEmployer({address, name: employer}));
      router.navigate(href);
    },
    [dispatch, href, router]
  );

  return (
    <YStack gap="$6" marginTop="$6">
      <Heading4 variant="medium">{t('PERSONAL_DETAILS.EMPLOYER_DETAILS')}</Heading4>

      <EmployerAddressForm ref={ref} onValid={onValidSubmit} />

      <InfoBox text={t('PERSONAL_DETAILS.PLEASE_NOTE_EMPLOYER_IN_GERMANY')} />

      <InterviewContinueButton onPress={onPress} />
    </YStack>
  );
}
