import {Button} from '@/components/buttons/Button';
import {Paragraph1} from '@/components/texts/Paragraph';
import {captureMessage} from '@sentry/react-native';
import {useTranslate} from '@tolgee/react';
import {Stack, usePathname, useRouter} from 'expo-router';
import {useEffect} from 'react';
import {YStack} from 'tamagui';

export default function NotFoundScreen() {
  const {t} = useTranslate();
  const router = useRouter();
  const pathname = usePathname();

  useEffect(() => {
    console.log(pathname);
    captureMessage(`screen not found: ${pathname}`);
  }, [pathname]);

  return (
    <>
      <Stack.Screen options={{title: 'Oops!'}} />
      <YStack gap="$2" justifyContent="center" alignItems="center">
        <Paragraph1>{t('NOT_FOUND.TITLE')}</Paragraph1>

        <Button
          onPress={() => {
            router.replace('/');
          }}
        >
          {t('NOT_FOUND.CTA')}
        </Button>
      </YStack>
    </>
  );
}
