// eslint-disable-next-line no-restricted-imports
import {GetProps, Text, styled, useMedia} from 'tamagui';

const Label = styled(Text, {
  tag: 'span',
  name: 'Label',
  accessibilityRole: 'text',
  color: '$text',
  fontFamily: '$body',
  fontWeight: 'normal',
  whiteSpace: 'normal',
  style: {
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  variants: {
    variant: {
      medium: {
        fontFamily: '$bodyMedium',
      },
      underline: {
        textDecorationLine: 'underline',
      },
    },
  } as const,
});

export type ParagraphProps = GetProps<typeof Label>;

export const Label1 = styled(Label, {
  name: 'L1',
  fontSize: 18,
  lineHeight: 26,
  $sm: {
    fontSize: 16,
    lineHeight: 22,
  },
});

export const Label2 = styled(Label, {
  name: 'L2',
  fontSize: 16,
  lineHeight: 22,
  $sm: {
    fontSize: 14,
    lineHeight: 18,
  },
});

export const Label3 = styled(Label, {
  name: 'L3',
  fontSize: 14,
  lineHeight: 18,
  $sm: {
    fontSize: 12,
    lineHeight: 16,
  },
});

export const Label4 = styled(Label, {
  name: 'L4',
  fontSize: 12,
  lineHeight: 16,
  $sm: {
    fontSize: 12,
    lineHeight: 18,
  },
});

export const Label3sm2 = (props: GetProps<typeof Label3>) => {
  const media = useMedia();
  if (media.sm) {
    return <Label2 {...props} />;
  }
  return <Label3 {...props} />;
};
