import {BaseInput} from '@/components/inputs/form/BaseInput';
import {Label3} from '@/components/texts/Label';
import React, {PropsWithChildren} from 'react';
import {YStack} from 'tamagui';

type NonEditableInputProps = {
  label: string;
  text: string;
  bordered?: boolean;
};

export function NonEditableInput({
  label,
  text,
  bordered,
}: PropsWithChildren<NonEditableInputProps>) {
  return (
    <YStack gap="$2">
      <Label3 color="$neutral500">{label}</Label3>
      <BaseInput value={text} disabled bordered={bordered} />
    </YStack>
  );
}
