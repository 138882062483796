import SvgFile from '@/components/icons/SvgFile';
import {InvestmentAccountStrategyType} from '@/modules/onboarding/types/investment-account-strategy-type';
import {View, XStack} from 'tamagui';

type StrategyTypeToggleProps = {
  isGreen: boolean;
  onChangeType: (type: InvestmentAccountStrategyType) => void;
};

export function StrategyTypeToggle({isGreen, onChangeType}: StrategyTypeToggleProps) {
  return (
    <XStack
      cursor="pointer"
      onPress={() => onChangeType(isGreen ? 'global' : 'green')}
      alignItems="center"
      gap="$2"
    >
      <View>
        <View animation="slow" opacity={isGreen ? 1 : 0}>
          <SvgFile name="stocks" size={24} color="$neutral200" />
        </View>
        <View animation="slow" opacity={isGreen ? 0 : 1}>
          <SvgFile position="absolute" bottom={0} name="stocks" size={24} color="$primary" />
        </View>
      </View>

      <View
        animation="slow"
        backgroundColor={isGreen ? '$emerald' : '$primary'}
        borderRadius="$full"
        height={28}
        width={48}
        justifyContent="center"
      >
        <View
          animation="slow"
          x={isGreen ? 23 : 3}
          height={22}
          width={22}
          borderRadius="$full"
          backgroundColor="$white"
        />
      </View>

      <View paddingTop={3}>
        <View animation="slow" opacity={isGreen ? 0 : 1}>
          <SvgFile name="eco-leaf" size={24} color="$neutral200" />
        </View>
        <View animation="slow" opacity={isGreen ? 1 : 0}>
          <SvgFile position="absolute" bottom={0} name="eco-leaf" size={24} color="$emerald" />
        </View>
      </View>
    </XStack>
  );
}
