import {
  PostidentStart,
  PostidentStartResult,
  PostidentWebStart,
} from '@/modules/overview/helpers/postident';
import {openBrowserAsync} from 'expo-web-browser';

export function start(props: PostidentStart): Promise<PostidentStartResult> {
  const {url} = props as PostidentWebStart;
  return openBrowserAsync(url);
}
