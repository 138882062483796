import {useId} from 'react';
import {GetProps, Label, XStack, XStackProps} from 'tamagui';

import {CheckBox} from '@/components/inputs/Checkbox';
import {Label3} from '@/components/texts/Label';

export type LabelCheckBoxProps = {
  label: string;
  checked: GetProps<typeof CheckBox>['checked'];
  onCheckedChange: GetProps<typeof CheckBox>['onCheckedChange'];
  containerProps?: XStackProps;
  checkboxProps?: Omit<GetProps<typeof CheckBox>, 'checked' | 'onCheckedChange' | 'id'>;
};

export const LabelCheckBox: React.FC<LabelCheckBoxProps> = ({
  label,
  checked,
  onCheckedChange,
  containerProps,
  checkboxProps,
}) => {
  const id = useId();

  return (
    <XStack alignItems="center" gap="$4" cursor="pointer" {...containerProps}>
      <CheckBox checked={checked} onCheckedChange={onCheckedChange} id={id} {...checkboxProps} />
      <Label htmlFor={id} cursor="inherit">
        <Label3 color="$neutral500">{label}</Label3>
      </Label>
    </XStack>
  );
};
