import {Card} from '@/components/cards/Card';
import SvgFile from '@/components/icons/SvgFile';
import {Heading5} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {GradientCard} from '@/modules/recommendations/components/GradientCard';
import {RecommendationsProps} from '@/modules/recommendations/components/Recommendations';
import {Image, ImageSource} from 'expo-image';
import {forwardRef, useMemo} from 'react';
import {GetProps, View, XStack, YStack} from 'tamagui';

type BaseRecommendationCardProps = {
  title: string;
  description: string;
  onPress?: () => void;
  onDismiss: () => void;
  iconPath: ImageSource;
  highlighted?: boolean;
  containerStyle?: GetProps<typeof Card>;
} & RecommendationsProps;

export const BaseRecommendationCard = forwardRef<any, BaseRecommendationCardProps>(
  function BaseRecommendationCard(
    {title, description, onPress, onDismiss, iconPath, highlighted, containerStyle, type},
    ref
  ) {
    const content = useMemo(() => {
      return type === 'smallHorizontal' || type === 'smallVertical' ? (
        <XStack ref={ref} justifyContent="space-between" $platform-web={{height: '100%'}}>
          <YStack
            width="70%"
            cursor="pointer"
            hoverStyle={{opacity: 0.8}}
            onPress={onPress}
            justifyContent="space-between"
          >
            <Paragraph2
              variant="medium"
              color={highlighted ? '$white' : '$neutral900'}
              maxWidth={175}
            >
              {title}
            </Paragraph2>
            <View
              backgroundColor={highlighted ? '$white10' : '$neutralBG'}
              padding="$0.5"
              borderRadius="$0.25"
              height={24}
              width={24}
              alignContent="center"
              justifyContent="center"
            >
              <SvgFile name="chevron-right" color={highlighted ? '$white' : '$primary'} />
            </View>
          </YStack>
          <YStack alignItems="flex-end" justifyContent="space-between" flex={1}>
            <View
              backgroundColor="$neutralBG"
              borderRadius="$full"
              height={16}
              width={16}
              alignContent="center"
              justifyContent="center"
              cursor="pointer"
              hoverStyle={{opacity: 0.8}}
              onPress={onDismiss}
            >
              <SvgFile
                height={16}
                width={16}
                name="close"
                color={highlighted ? '$white' : '$primary'}
              />
            </View>
            <View cursor="pointer" hoverStyle={{opacity: 0.8}} onPress={onPress}>
              <Image source={iconPath} style={{height: 45, width: 45}} />
            </View>
          </YStack>
        </XStack>
      ) : (
        <YStack
          ref={ref}
          cursor="pointer"
          hoverStyle={{opacity: 0.8}}
          onPress={onPress}
          justifyContent="space-between"
          $platform-web={{height: '100%'}}
          gap="$2"
        >
          <YStack gap="$3">
            <Heading5
              variant="medium"
              color={highlighted ? '$white' : '$neutral900'}
              maxWidth={175}
            >
              {title}
            </Heading5>
            <Paragraph2 color={highlighted ? '$white' : '$neutral400'}>{description}</Paragraph2>
          </YStack>
          <XStack alignItems="flex-end" justifyContent="space-between" userSelect="none">
            <View
              backgroundColor={highlighted ? '$white10' : '$neutralBG'}
              padding="$2"
              borderRadius="$0.25"
            >
              <SvgFile name="chevron-right" color={highlighted ? '$white' : '$primary'} />
            </View>
            <Image source={iconPath} style={{height: 54, width: 54}} contentFit="contain" />
          </XStack>
        </YStack>
      );
    }, [description, highlighted, iconPath, onDismiss, onPress, ref, title, type]);

    const res = useMemo(() => {
      if (highlighted)
        return (
          <GradientCard
            containerStyle={{
              padding: '$6',
              $sm: {
                padding: '$4',
              },
              borderRadius: '$1',
              ...containerStyle,
            }}
          >
            {content}
          </GradientCard>
        );
      else if (type === 'smallHorizontal')
        return (
          <Card borderRadius="$1" width={230} height={100} padding="$2">
            {content}
          </Card>
        );
      else if (type === 'smallVertical')
        return (
          <Card borderRadius="$1" width={245} height={140} padding="$4">
            {content}
          </Card>
        );
      else {
        return (
          <Card borderRadius="$1" $sm={{width: '100%'}} {...containerStyle} padding="$6">
            {content}
          </Card>
        );
      }
    }, [containerStyle, content, highlighted, type]);

    return (
      <View
        animation="slow"
        enterStyle={{
          opacity: 0,
          x: 10,
        }}
        exitStyle={{
          opacity: 0,
          x: 10,
        }}
      >
        {res}
      </View>
    );
  }
);
