import {Domain, NumberTuple, OffsetData, Size, XYTuple} from '@/types/skia';

export const REMOVE_SELECT_POS = 999999999;

export const INITIAL_SIZE: Size = {
  width: 0,
  height: 0,
} as const;

const INITIAL_XY_TUPLE: XYTuple = {
  x: [0, 0],
  y: [0, 0],
};

export const INITIAL_DOMAIN: Readonly<Domain> = INITIAL_XY_TUPLE;

export const INITIAL_RANGE: Readonly<Domain> = INITIAL_XY_TUPLE;

export const INITIAL_MIN_MAX: NumberTuple = [0, 0] as const;

export const INITIAL_OFFSET_DATA: OffsetData = {
  width: 0,
  pointSize: 0,
} as const;
