import {useGlobalSearchParams, useSegments} from 'expo-router';
import {useEffect} from 'react';
import {Platform} from 'react-native';
import {usePostHog} from './usePostHog';

export function PostHogScreenTracker() {
  const posthog = usePostHog();
  const segments = useSegments();
  const {params, screen, ...rest} = useGlobalSearchParams();
  useEffect(() => {
    const newUrl = segments.join('/');
    posthog?.register({
      $current_url: newUrl,
    });
    if (Platform.OS === 'web') {
      posthog?.capture('$pageview', {
        ...rest,
        $current_url: newUrl,
      });
    } else posthog?.screen(newUrl, rest);
  }, [params, posthog, rest, segments]);

  return null;
}
