import {Group, Line, Skia, useFonts, vec} from '@shopify/react-native-skia';
import {SkTextStyle} from '@shopify/react-native-skia/src/skia/types/Paragraph/TextStyle';
import {useEffect, useId, useMemo, useState} from 'react';
import {getTokens} from 'tamagui';

import {CommonAxisProps, NumberTuple, Size, XAxisPosition} from '@/types/skia';
import {Text} from './Text';
import {useChartContext} from './context';
import {useAxisUtils} from './hooks';
import {createParagraph, isSameDomain} from './utils';

type Props = {
  position: XAxisPosition;
} & CommonAxisProps;

export const XAxis = ({
  position,
  countTicks = 5,
  getTexLabel,
  fontSize = 14,
  grid,
  color,
  nice = false,
  blur,
  time = true,
}: Props) => {
  const {
    size,
    offsetData,
    xAxis,
    yAxis,
    updateAxis,
    discreteMode,
    removeHorizontalOffset,
    overlapYAxes,
    chartHeight,
    theme,
  } = useChartContext();

  const [maxTextHeight, setMaxTextHeight] = useState(0);

  const tokens = getTokens();

  const commonMargin = tokens.size['1']?.val;

  const xAxisKey = useId();

  const fonts = useFonts({
    // @ts-ignore
    'Inter-Regular': [require('@tamagui/font-inter/otf/Inter-Regular.otf')],
  });

  const {axisTicks, createTickText} = useAxisUtils(true, time, countTicks, nice);

  const textColor = useMemo(() => color ?? theme.text?.val ?? 'black', [theme, color]);

  const commonTextStyles = useMemo<SkTextStyle>(
    () => ({
      fontSize,
      color: Skia.Color(textColor),
      fontFamilies: ['Inter-Regular'],
    }),
    [fontSize, textColor]
  );

  useEffect(() => {
    return () => {
      updateAxis(position);
    };
  }, [position]);

  useEffect(() => {
    const size: Size = {
      width: 0,
      height: maxTextHeight + 2 * commonMargin,
    };

    updateAxis(position, {size});
  }, [position, maxTextHeight, commonMargin, updateAxis]);

  useEffect(() => {
    if (axisTicks.length === 0) return;

    const first = axisTicks.at(0);
    const last = axisTicks.at(-1);

    if (!first || !last || first.tick === last.tick) return;

    const xAxisTuple: NumberTuple = [first.tick, last.tick];

    const currAxis = xAxis[position];

    if (!!currAxis?.axisDomain && isSameDomain(xAxisTuple, currAxis.axisDomain)) return;

    updateAxis(position, {axisDomain: xAxisTuple});
  }, [position, xAxis, axisTicks, updateAxis]);

  useEffect(() => {
    if (axisTicks.length === 0 || !fonts) {
      setMaxTextHeight(0);

      return;
    }

    const text = axisTicks
      .map((tick, index, self) => createTickText(tick.tick, index, self, getTexLabel) ?? '')
      .join(' ');

    const paragraph = createParagraph(fonts, commonTextStyles, text, size.width);
    paragraph.layout(size.width);

    const allHeights = paragraph.getLineMetrics().map(skRect => skRect.height);

    if (allHeights.length === 0) {
      setMaxTextHeight(0);

      return;
    }

    const maxHeight = Math.max(...allHeights);

    setMaxTextHeight(maxHeight);
  }, [axisTicks, fonts, createTickText, getTexLabel, size.width, commonTextStyles]);

  const translateX = useMemo(() => {
    const leftYAxisOffset = overlapYAxes ? 0 : yAxis?.left?.size?.width ?? 0;
    let commonOffset = offsetData.width / 2;

    if (!removeHorizontalOffset) {
      commonOffset += offsetData.pointSize / 2;
    }

    return leftYAxisOffset + commonOffset;
  }, [yAxis?.left, offsetData, removeHorizontalOffset, overlapYAxes]);

  if (!fonts) return null;

  return (
    <Group transform={[{translateX}]}>
      {axisTicks.map((tick, index, self) => {
        const text = createTickText(tick.tick, index, self, getTexLabel);

        if (!text) return null;

        const {top, bottom} = xAxis ?? {};

        const paragraph = createParagraph(fonts, commonTextStyles, text, size.width);
        const width = paragraph.getLongestLine();
        const height = paragraph.getHeight();

        let yText: number;

        if (position === 'top') {
          yText = commonMargin;
        } else {
          yText =
            chartHeight === undefined
              ? size.height - height - commonMargin
              : chartHeight + height + commonMargin;
        }

        const gridSize = grid?.size ?? 0;
        let lineX = tick.position;
        let xText = tick.position - width / 2;

        if (index === 0) {
          const startLine = tick.position - gridSize / 2 + translateX;

          xText = xText < 0 ? 0 : xText;
          lineX = startLine < 0 ? gridSize : lineX;
        }

        if (index === self.length - 1) {
          const endText = tick.position + width / 2 + translateX;
          const endLine = tick.position + gridSize / 2 + translateX;

          xText = endText > size.width ? size.width - width - translateX : xText;
          lineX = endLine > size.width ? size.width - gridSize - translateX : lineX;
        }

        const lineP1Y =
          position === 'bottom' ? yText - commonMargin : commonMargin + height + commonMargin;

        let lineP2Y = top?.size?.height ?? 0;

        if (position === 'top') {
          lineP2Y =
            chartHeight === undefined ? size.height - (bottom?.size?.height ?? 0) : chartHeight;
        }

        return (
          <Group key={`${xAxisKey}-X-AXIS-TICK-${position}-${index}`}>
            {grid && (
              <Line
                p1={vec(lineX, lineP1Y)}
                p2={vec(lineX, lineP2Y)}
                color={grid.color}
                style="stroke"
                strokeWidth={grid.size}
              />
            )}
            <Text paragraph={paragraph} x={xText} y={yText} blur={blur && discreteMode} />
          </Group>
        );
      })}
    </Group>
  );
};
