import SvgFile, {SvgName} from '@/components/icons/SvgFile';
import {Paragraph2} from '@/components/texts/Paragraph';
import React, {forwardRef, useMemo} from 'react';
import {GetProps, TamaguiElement, YStack, styled, useMedia} from 'tamagui';

const ActionButtonWrapper = styled(YStack, {
  role: 'button',
  cursor: 'pointer',
  alignItems: 'center',
  pressStyle: {
    opacity: 0.8,
    scale: 0.95,
  },
  alignSelf: 'center',
  minWidth: 75,
  width: '100%',
  borderRadius: '$1',
  padding: '$2',
  backgroundColor: '$surface',
  justifyContent: 'center',
  hoverStyle: {opacity: 0.75},
  $sm: {
    width: 'auto',
    flex: 0,
    padding: '$3',
    borderRadius: '$full',
    minWidth: 0,
  },
  variants: {
    primary: {
      true: {
        backgroundColor: '$primary',
      },
      false: {
        $sm: {
          backgroundColor: '$neutralBG',
        },
      },
    },
  } as const,
});

type ActionButtonProps = GetProps<typeof ActionButtonWrapper> & {
  iconName: SvgName;
  label?: string;
  primary?: boolean;
  wrapperProps?: GetProps<typeof YStack>;
};

export const ActionButton = forwardRef<TamaguiElement, ActionButtonProps>(
  ({label, iconName, primary = false, wrapperProps, ...props}, ref) => {
    const media = useMedia();

    const labelComp = useMemo(
      () => (
        <Paragraph2
          textAlign="center"
          color={primary ? '$white' : '$neutral900'}
          marginTop="$1"
          numberOfLines={1}
          $sm={{color: '$neutral900'}}
        >
          {label}
        </Paragraph2>
      ),
      [label, primary]
    );
    return (
      <YStack flex={1} {...wrapperProps}>
        <ActionButtonWrapper ref={ref} primary={primary} {...props}>
          <SvgFile
            name={iconName}
            size={!media.sm ? 32 : 20}
            color={primary ? '$white' : '$primary'}
          />
          {label && !media.sm && labelComp}
        </ActionButtonWrapper>
        {label && media.sm && labelComp}
      </YStack>
    );
  }
);
