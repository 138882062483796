import {BaseInput} from '@/components/inputs/form/BaseInput';
import {SelectInput} from '@/components/inputs/select-input/SelectInput';
import {InputErrorMessage} from '@/components/texts/InputErrorMessage';
import {Label3} from '@/components/texts/Label';
import {useLocalizedMonths} from '@/hooks/useLocalizedMonths';
import {FormInputProps} from '@/types/FormInputProps';
import {useTranslate} from '@tolgee/react';
import React, {useEffect, useState} from 'react';
import {FieldValues, useController} from 'react-hook-form';
import {View, XStack, YStack} from 'tamagui';

export function FormDateInput<T extends FieldValues>({
  control,
  name,
  label,
  bordered,
}: FormInputProps<T>) {
  const {t} = useTranslate();
  const [date, setDate] = useState<{
    day: string;
    month: string;
    year: string;
  }>({
    day: '',
    month: '',
    year: '',
  });

  const {
    field,
    fieldState: {error, isDirty},
  } = useController({
    name,
    control,
  });

  const months = useLocalizedMonths();

  useEffect(() => {
    if (field?.value && !date.day && !date.month && !date.year) {
      const date = new Date(field.value);

      if (isNaN(date.getTime())) return;

      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      setDate({
        day: day.toString(),
        month: month.toString(),
        year: year.toString(),
      });
    }
  }, [date.day, date.month, date.year, field?.value]);

  const handleDayChanged = (day: string) => {
    const updatedDay = day.replace(/\D/g, '');
    const parsedDay = Number.parseInt(updatedDay, 10);

    if (!Number.isNaN(parsedDay) && (parsedDay < 0 || parsedDay > 31)) return;

    setDate(_ => ({..._, day: updatedDay}));
  };

  const handleMonthChanged = (month: string) => {
    const updatedMonth = month.replace(/\D/g, '');
    const parsedMonth = Number.parseInt(updatedMonth, 10);

    if (!Number.isNaN(parsedMonth) && (parsedMonth < 0 || parsedMonth > 12)) return;

    setDate(_ => ({..._, month: updatedMonth}));
  };

  const handleYearChanged = (year: string) => {
    const updatedYear = year.replace(/\D/g, '');

    if (updatedYear.length > 4) return;

    setDate(_ => ({..._, year: updatedYear}));
  };

  useEffect(() => {
    const {day, month, year} = date;

    if ((!day || !month || !year) && !isDirty) return;

    const parsedMonth = Number.parseInt(month, 10);

    let updateMonth: string;

    if (isNaN(parsedMonth)) {
      updateMonth = '';
    } else {
      updateMonth = parsedMonth.toString();
    }

    field.onChange(`${year}-${updateMonth.padStart(2, '0')}-${day.padStart(2, '0')}`);
  }, [date, field, isDirty]);

  return (
    <YStack gap="$2" maxWidth="100%">
      <Label3 color="$neutral500">{label}</Label3>
      <XStack gap="$2">
        <BaseInput
          onChangeText={handleDayChanged}
          value={date.day}
          placeholder={t('PERSONAL_DETAILS.DAY')}
          surfaceProps={{width: '100%', maxWidth: 80}}
          bordered={bordered}
          testID="date-day"
          keyboardType="numeric"
        />
        <View flex={1} maxWidth={275}>
          <SelectInput
            items={months}
            selectedItem={date.month}
            onSelect={handleMonthChanged}
            placeholder={t('PERSONAL_DETAILS.MONTH')}
            wrapperProps={{
              bordered,
            }}
            testID="date-month"
          />
        </View>
        <BaseInput
          onChangeText={handleYearChanged}
          value={date.year}
          placeholder={t('PERSONAL_DETAILS.YEAR')}
          surfaceProps={{width: '100%', maxWidth: 100}}
          bordered={bordered}
          testID="date-year"
          keyboardType="numeric"
        />
      </XStack>
      <InputErrorMessage error={error?.message} />
    </YStack>
  );
}
