import {useTranslate} from '@tolgee/react';
import {XStack, YStack} from 'tamagui';

import SvgFile from '@/components/icons/SvgFile';
import {Label3, Label3sm2} from '@/components/texts/Label';
import {useMfaAvailability} from '@/modules/mfa/hooks/useMfaAvailability';
import {Linking} from 'react-native';

export const TaxExemptionNote = () => {
  const {t} = useTranslate();
  const {isMFAUsable, isLoading} = useMfaAvailability();
  if (isLoading || !isMFAUsable) return null;

  return (
    <XStack
      gap="$2"
      backgroundColor="$neutralBG"
      borderRadius="$3"
      padding="$4"
      alignSelf="flex-start"
      width="100%"
    >
      <YStack>
        <SvgFile name="info" color="$primary" size={20} />
      </YStack>
      <YStack gap="$3" flex={1}>
        <Label3sm2 color="$primary">{t('TAXES.TAX-EXEMPTION.NOTE.TITLE')}</Label3sm2>
        <Label3>
          {t('TAXES.TAX-EXEMPTION.NOTE.DESC_1')}
          <Label3
            color="$primary"
            onPress={() => {
              Linking.openURL(process.env.EXPO_PUBLIC_TAX_CHILD_NOTE);
            }}
            cursor="pointer"
          >
            {t('TAXES.TAX-EXEMPTION.NOTE.DESC_2')}
          </Label3>
          <Label3>.</Label3>
        </Label3>
        <Label3>
          {t('TAXES.TAX-EXEMPTION.NOTE.DESC_3')}
          <Label3
            color="$primary"
            onPress={() => {
              Linking.openURL(process.env.EXPO_PUBLIC_MAILTO_SERVICE);
            }}
            cursor="pointer"
          >
            {t('TAXES.TAX-EXEMPTION.NOTE.DESC_4')}
          </Label3>
          <Label3>.</Label3>
        </Label3>
      </YStack>
    </XStack>
  );
};
