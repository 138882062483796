export enum ClassColorsEnum {
  'COMMODITIES' = '#FCC400',
  'EQUITY' = '#3356D2',
  'FIXED_INCOME' = '#12BE57',
  'REAL_ESTATE' = '#D233A5',
  'DEFAULT' = '#B4A0FF',
}

export enum IndustryColorsEnum {
  'COMMODITIES' = '#009ADC',
  'COMMUNICATION_SERVICES' = '#3356D2',
  'CONSUMER_DISCRETIONARY' = '#D233A5',
  'CONSUMER_STAPLES' = '#12BE57',
  'ENERGY' = '#FF4F4F',
  'FINANCIAL' = '#00B2B2',
  'HEALTH_CARE' = '#FBE16E',
  'INDUSTRY' = '#FCC400',
  'INFORMATION_TECHNOLOGY' = '#DC66FF',
  'MATERIALS' = '#FFB366',
  'REAL_ESTATE' = '#FFB266',
  'SOVEREIGN_DEBT' = '#8566FF',
  'UTILITIES' = '#E2B4BD',
  'DEFAULT' = '#B4A0FF',
}

export enum RegionColorsEnum {
  'AFRICA_MIDDLE_EAST' = '#D233A5',
  'EUROPE' = '#12BE57',
  'LATIN_AMERICA' = '#FCC400',
  'NORTH_AMERICA' = '#3356D2',
  'OCEANIA' = '#00B2B2',
  'CENTRAL_ASIA' = '#8566FF',
  'SOUTH_EAST_ASIA' = '#DC66FF',
  'SUPRANATIONAL_ORGANIZATIONS' = 'transparent',
  'DEFAULT' = '#B4A0FF',
}

export enum ThemeColorsEnum {
  'BLOCKCHAIN' = '#AD58E5',
  'BLUE_ECONOMY' = '#45C7F0',
  'CLEAN_ENERGY' = '#12C95B',
  'COMMODITIES' = '#FCAC6D',
  'DIVIDEND_STOCKS_EUROPE' = '#F09E23',
  'DIVIDENDS_EURO' = '#F09E23',
  'DIVIDENDS_USA' = '#F09E23',
  'ELECTRIC_MOBILITY' = '#08CCC5',
  'ENERGY_INDUSTRY' = '#FFBB01',
  'FINANCIAL_INDUSTRY' = '#EDCE28',
  'GLOBAL_WATER' = '#008FCC',
  'HEALTHCARE' = '#FF6F77',
  'INFORMATION_TECH' = '#D233A5',
  'STOCKS_CHINA' = '#9D83E1',
  'STOCKS_EM' = '#9D83E1',
  'STOCKS_EUROPE' = '#9D83E1',
  'STOCKS_GERMANY' = '#9D83E1',
  'STOCKS_USA' = '#9D83E1',
  'WATER' = '#008FCC',
  'MONEY_MARKET' = '#008FCC',
  'DEFAULT' = '#B4A0FF',
}
