import SvgFile from '@/components/icons/SvgFile';
import {Label2, Label3} from '@/components/texts/Label';
import {LocalizedDate} from '@/components/texts/LocalizedDate';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {TRANSACTION_ICONS} from '@/modules/overview/constants/transaction-icons';
import {useTransactionCategoriesQuery} from '@/store/queries/referenceApi';
import {TransactionsDTO} from '@/types/api/apeiron';
import {View, XStack, YStack} from 'tamagui';

export function TransactionItem({
  item: {amount, category, createdAt, reference},
}: {
  item: TransactionsDTO;
}) {
  const {data: transactionCategories} = useTransactionCategoriesQuery();
  const {getByCode} = useRefdataLocalizable();

  return (
    <XStack gap="$2" alignItems="center" paddingRight="$2">
      <View padding="$2" backgroundColor="$greenTransparent" borderRadius="$full">
        <SvgFile
          size={24}
          name={TRANSACTION_ICONS[category] || 'unknown-transactions'}
          color="$green"
        />
      </View>
      <YStack flex={1} gap="$1">
        <XStack justifyContent="space-between" alignItems="center">
          <Label2 variant="medium">{getByCode(transactionCategories, category)?.label}</Label2>
          {amount && (
            <LocalizedNumbers
              value={amount}
              numberStyle={{variant: 'medium'}}
              textComponent={Label2}
            />
          )}
        </XStack>
        <YStack gap="$1">
          {createdAt && <LocalizedDate date={createdAt} textComponent={Label3} />}
          <Label3 color="$neutral500">{reference}</Label3>
        </YStack>
      </YStack>
    </XStack>
  );
}
