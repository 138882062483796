import {Card} from '@/components/cards/Card';
import {CheckBox} from '@/components/inputs/Checkbox';
import {RadioButton} from '@/components/inputs/RadioButton';
import {Label2, Label3} from '@/components/texts/Label';
import React, {PropsWithChildren, useMemo} from 'react';
import {GetProps, XStack, YStack} from 'tamagui';

type CardCheckboxProps = {
  label: string;
  caption?: string;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  variant?: 'radio' | 'checkbox' | 'radioCompact';
  wrapperProps?: GetProps<typeof Card>;
  borderColor?: GetProps<typeof Card>['borderColor'];
};

export function CardCheckbox({
  children,
  label,
  caption,
  checked,
  onCheckedChange,
  variant = 'checkbox',
  wrapperProps,
  borderColor = 'transparent',
}: PropsWithChildren<CardCheckboxProps>) {
  const CheckComponent = useMemo(
    () => (variant === 'checkbox' ? CheckBox : RadioButton),
    [variant]
  );
  return (
    <Card
      role="checkbox"
      tabIndex={0}
      // @ts-ignore
      onKeyDown={(e: KeyboardEvent) => {
        if (e.code === 'Space') {
          onCheckedChange(!checked);
        }
      }}
      onPress={() => onCheckedChange(!checked)}
      padding={variant === 'radioCompact' ? '$4' : '$6'}
      borderColor={checked ? '$primary' : borderColor}
      borderWidth={1}
      outlineWidth={0}
      focusVisibleStyle={{
        opacity: 0.85,
        scale: 0.95,
      }}
      flexGrow={1}
      flexBasis="auto"
      width={variant === 'radioCompact' ? 172 : 'auto'}
      height={variant === 'radioCompact' ? 102 : 'auto'}
      {...wrapperProps}
    >
      <YStack gap="$2">
        {variant === 'radioCompact' ? (
          <YStack gap="$1">
            <CheckComponent
              checked={checked}
              onCheckedChange={check => onCheckedChange(check as boolean)}
              tabIndex={-1}
              alignSelf="flex-start"
            />
            <Label3 flexShrink={1} tabIndex={-1}>
              {label}
            </Label3>
          </YStack>
        ) : (
          <XStack justifyContent="space-between" gap="$1">
            <Label2 flexShrink={1} variant="medium" tabIndex={-1}>
              {label}
            </Label2>
            <CheckComponent
              checked={checked}
              onCheckedChange={check => onCheckedChange(check as boolean)}
              tabIndex={-1}
            />
          </XStack>
        )}
        {caption && <Label3 color="$neutral400">{caption}</Label3>}
      </YStack>
      {children}
    </Card>
  );
}
