import {Card} from '@/components/cards/Card';
import {SquarePlusIcon} from '@/components/icons/SquarePlusIcon';
import SvgFile from '@/components/icons/SvgFile';
import {useWebGlAvailability} from '@/components/skia/hooks/useWebGlAvailability';
import {Label2, Label3} from '@/components/texts/Label';
import {calculateNextSavingsRateCollection} from '@/helpers/calculateNextSavingsRateCollection';
import {NumberBadge} from '@/modules/overview/components/NumberBadge';
import {SavingsRateModal} from '@/modules/overview/modals/SavingsRateModal';
import {useGetCustomerAccountQuery} from '@/store/queries/customersApi';
import {GetCustomerResponse, LegacyThemeAccountResponse} from '@/types/api/customers.v2';
import {isThemeCustomer} from '@/types/isThemeCustomer';
import {Canvas, Group, Path, Skia} from '@shopify/react-native-skia';
import {useTranslate} from '@tolgee/react';
import {forwardRef, useEffect, useMemo} from 'react';
import {Easing, ReduceMotion, useSharedValue, withTiming} from 'react-native-reanimated';
import {View, XStack, YStack, useTheme} from 'tamagui';

type SavingsRateCardProps = {
  size?: number;
  customerId: string;
};

const PADDING = 16;

const makeCircle = (width: number, height: number) => {
  const path = Skia.Path.Make();

  path.addArc(
    {
      x: 0,
      y: 0,
      width: width - PADDING,
      height: height - PADDING,
    },
    270,
    355
  );
  return path;
};

export function SavingsRateCard({customerId, size = 74}: SavingsRateCardProps) {
  const {data: customer} = useGetCustomerAccountQuery(customerId);

  if (isThemeCustomer(customer)) {
    // TODO: add some element for motive accounts
    return null;
  } else if (customer?.currentRecurringDeposit.amount === 0) {
    return (
      <SavingsRateModal customerId={customerId}>
        <EmptySavingsRateCard />
      </SavingsRateModal>
    );
  } else {
    return (
      <SavingsRateModal customerId={customerId}>
        <ChangeSavingsRateCard customer={customer} size={size} />
      </SavingsRateModal>
    );
  }
}

const ChangeSavingsRateCard = forwardRef<
  any,
  {
    size?: number;
    customer: Exclude<GetCustomerResponse, LegacyThemeAccountResponse> | undefined;
  }
>(({customer, size = 64, ...props}, ref) => {
  const theme = useTheme();
  const end = useSharedValue(0);
  const {t} = useTranslate();

  const nextCalculation = useMemo(() => {
    return calculateNextSavingsRateCollection();
  }, []);

  useEffect(() => {
    end.value = withTiming(1 - nextCalculation.daysToCollection / nextCalculation.totalDays, {
      duration: 1000,
      easing: Easing.inOut(Easing.exp),
      reduceMotion: ReduceMotion.Never,
    });
  }, [end, nextCalculation, customer]);

  const path = useMemo(() => {
    return makeCircle(size, size);
  }, [size]);

  const webGlAvailable = useWebGlAvailability();

  return (
    <Card flex={1} minHeight={100} ref={ref} cursor="pointer" padding="$4" {...props}>
      <XStack gap="$2" alignItems="center">
        {webGlAvailable ? (
          <Canvas style={{width: size, height: size}}>
            <Group transform={[{translateX: 8}, {translateY: 8}]}>
              <Path path={path} color={theme.neutral200.val} style="stroke" strokeWidth={5} />
              <Path
                path={path}
                color={theme.primary.val}
                style="stroke"
                strokeWidth={6}
                end={end}
                strokeCap="round"
              />
            </Group>
          </Canvas>
        ) : (
          <View width={size} height={size} />
        )}
        <YStack fullscreen alignItems="center" justifyContent="center" width={size} height={size}>
          <Label3 variant="medium">{nextCalculation.daysToCollection}</Label3>
          <Label3 variant="medium">{t('DAYS')}</Label3>
        </YStack>
        <YStack alignSelf="center" gap="$1" flex={1}>
          <XStack justifyContent="space-between" alignItems="center" gap="$4">
            <Label2 variant="medium">{t('PERSONAL_DETAILS.SAVINGS_RATE')}</Label2>
            <SvgFile name="edit" size={18} color="$primary" />
          </XStack>
          <NumberBadge value={customer?.currentRecurringDeposit.amount} />
        </YStack>
      </XStack>
    </Card>
  );
});

const EmptySavingsRateCard = forwardRef(({...props}, ref) => {
  const {t} = useTranslate();
  return (
    <Card cursor="pointer" padding="$6" justifyContent="center" {...props}>
      <XStack gap="$2">
        <View backgroundColor="$primary" borderRadius="$full" padding="$2">
          <SvgFile name="wallet" color="$white" marginLeft={2} size={24} />
        </View>
        <XStack flex={1} gap="$2" justifyContent="space-between" alignItems="center">
          <Label2 variant="medium" color="$primary">
            {t('ACCOUNT_SETUP.SAVINGS_RATE')}
          </Label2>
          <SquarePlusIcon onSurface />
        </XStack>
      </XStack>
    </Card>
  );
});
