import {Badge} from '@/components/buttons/Badge';
import {Label3} from '@/components/texts/Label';
import {calculateTimeBetweenDates} from '@/helpers/calculateTimeBetweenDates';
import {useTranslate} from '@tolgee/react';

type Props = {
  validDate: number;
};
export const RemainingTimeChip = ({validDate}: Props) => {
  const {t} = useTranslate();

  const getRemainingTime = () => {
    const time = Math.round(calculateTimeBetweenDates({endDate: validDate, type: 'days'}));

    if (time === 0) return t('MFA.ACTIVE_TODAY');
    return t('MFA.ACTIVE_IN', {days: time});
  };

  return (
    <Badge backgroundColor="$yellowTransparent" borderColor="transparent">
      <Label3 color="$yellowDark" variant="medium">
        {getRemainingTime()}
      </Label3>
    </Badge>
  );
};
