// eslint-disable-next-line no-restricted-imports
import {GetProps, Text, styled} from 'tamagui';

const Button = styled(Text, {
  tag: 'span',
  name: 'Button',
  accessibilityRole: 'button',
  fontFamily: '$body',
  fontWeight: 'normal',
  color: '$text',
  whiteSpace: 'normal',
  style: {
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  variants: {
    variant: {
      medium: {
        fontFamily: '$bodyMedium',
      },
      underline: {
        textDecorationLine: 'underline',
      },
    },
  } as const,
});

export type ParagraphProps = GetProps<typeof Button>;

export const Button1 = styled(Button, {
  name: 'B1',
  fontSize: 16,
  lineHeight: 24,
  $sm: {
    fontSize: 14,
    lineHeight: 20,
  },
});

export const Button2 = styled(Button, {
  name: 'B2',
  fontSize: 16,
  lineHeight: 24,
  $sm: {
    fontSize: 14,
    lineHeight: 16,
  },
});
