import {Label2} from '@/components/texts/Label';
import {Href, usePathname, useRouter} from 'expo-router';
import {PropsWithChildren, useMemo} from 'react';
import {XStack} from 'tamagui';

export type SideNavigationSubItemProps = {
  title: string;
  href: Href;
};

export function SideNavigationSubItem({
  title,
  href,
}: PropsWithChildren<SideNavigationSubItemProps>) {
  const path = usePathname();
  const router = useRouter();

  const isActive = useMemo(() => {
    if (typeof href === 'object') {
      const param = href.params?.customerId?.toString() ?? '';
      return path.includes(param);
    } else {
      return path === href;
    }
  }, [href, path]);

  return (
    <>
      <XStack
        gap="$2"
        alignItems="center"
        onPress={() => router.navigate(href)}
        cursor="pointer"
        overflow="hidden"
        hoverStyle={{
          opacity: 0.8,
        }}
        paddingVertical="$2"
        paddingRight="$4"
        paddingLeft="$3"
        backgroundColor={isActive ? '$surfaceRaised' : 'transparent'}
        borderRadius="$0.25"
      >
        <Label2
          userSelect="none"
          color={isActive ? '$primary' : '$text'}
          numberOfLines={1}
          variant="medium"
        >
          {title}
        </Label2>
      </XStack>
    </>
  );
}
