import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {PoliticallyExposedPersonScreen} from '@/modules/onboarding/components/PoliticallyExposedPersonScreen';

const PoliticallyPerson = () => {
  return (
    <FullscreenScrollView variant="large">
      <PoliticallyExposedPersonScreen />
    </FullscreenScrollView>
  );
};

export default PoliticallyPerson;
