import SvgFile from '@/components/icons/SvgFile';
import {BookCallButton} from '@/modules/navigation/components/header/BookCallButton';
import {HeaderLanguageSelector} from '@/modules/navigation/components/header/HeaderLanguageSelector';
import {UserEmailButton} from '@/modules/navigation/components/header/UserEmailButton';
import {router} from 'expo-router';
import {useTheme, View, XStack} from 'tamagui';

export function OnboardingHeader() {
  const theme = useTheme();
  return (
    <XStack
      maxWidth={1200}
      alignSelf="center"
      width="100%"
      justifyContent="space-between"
      paddingHorizontal="$4"
      gap="$2"
    >
      <View
        onPress={() => {
          router.navigate('/');
        }}
        hoverStyle={{
          opacity: 0.8,
        }}
        cursor="pointer"
      >
        <SvgFile
          color={theme.primary.val}
          name="ginmon"
          width={117}
          paddingVertical="$4"
          alignSelf="flex-start"
        />
      </View>
      <XStack alignItems="center" gap="$6">
        <BookCallButton />
        <HeaderLanguageSelector />
        <UserEmailButton />
      </XStack>
    </XStack>
  );
}
