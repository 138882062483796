import {SelectInput} from '@/components/inputs/select-input/SelectInput';
import {InputErrorMessage} from '@/components/texts/InputErrorMessage';
import {Label3} from '@/components/texts/Label';
import {Skeleton} from '@/components/views/Skeleton';
import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {LocalizableValue} from '@/types/api/refdata.v2';
import {SelectFormInputProps} from '@/types/FormInputProps';
import {useMemo} from 'react';
import {FieldValues, Path, PathValue, useController} from 'react-hook-form';
import {YStack} from 'tamagui';

type RefdataSelectProps<T extends FieldValues> = SelectFormInputProps<T> & {
  type: {
    defaultValue?: PathValue<T, Path<T>>;
    data?: {
      code: string;
      label: LocalizableValue;
    }[];
  };
};

export function FormRefdataSelect<T extends FieldValues>({
  control,
  name,
  label,
  bordered,
  type,
}: RefdataSelectProps<T>) {
  const {getAll} = useRefdataLocalizable();
  const {
    field,
    fieldState: {error},
  } = useController({
    name,
    control,
    defaultValue: type.defaultValue,
  });

  const items = useMemo(() => {
    const tmp = getAll(type.data);
    if (!tmp) return undefined;
    return tmp.map(_ => ({
      name: _.label,
      key: _.code,
    }));
  }, [type.data, getAll]);

  return (
    <YStack gap="$2">
      <Label3 color="$neutral500">{label}</Label3>
      <Skeleton width="100%" height={bordered ? 50 : 70}>
        {items && (
          <SelectInput
            items={items}
            selectedItem={field.value}
            onSelect={field.onChange}
            placeholder="--"
            wrapperProps={{
              borderWidth: 0,
              bordered,
            }}
          />
        )}
      </Skeleton>
      <InputErrorMessage error={error?.message} />
    </YStack>
  );
}
