import {Button} from '@/components/buttons/Button';
import {Label3} from '@/components/texts/Label';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {WithLabel} from '@/components/texts/WithLabel';
import {Skeleton} from '@/components/views/Skeleton';
import {ThemeDepositSummaryRow} from '@/modules/orders/components/Theme/ThemeDepositSummaryRow';
import {CustomThemeDistribution} from '@/modules/orders/types/CustomThemeDistribution';
import {ThemeCode} from '@/modules/portfolio/types/ThemeCode';
import {usePostCustomerOrderMutation} from '@/store/queries/customersApi';
import {OrderRequest, ThemeDepositDistribution} from '@/types/api/customers.v2';
import {useTranslate} from '@tolgee/react';
import {toast} from 'burnt';
import {Redirect, useRouter} from 'expo-router';
import React, {useCallback, useMemo} from 'react';

type ThemeDepositSummaryCardProps = {
  orderType: Required<OrderRequest>['orderType'];
  customerId: string;
  value?: number;
  onFinished?: () => void;
  themeDepositDistribution?: CustomThemeDistribution<ThemeDepositDistribution>;
};

export function ThemeDepositSummaryCard({
  orderType,
  customerId,
  value,
  onFinished,
  themeDepositDistribution,
}: ThemeDepositSummaryCardProps) {
  const {t} = useTranslate();
  const [postOrder] = usePostCustomerOrderMutation();
  const router = useRouter();

  const submitOrder = useCallback(async () => {
    const res = themeDepositDistribution
      ? await postOrder({
          orderType: 'THEME_DEPOSIT',
          customerId,
          themeDistribution: themeDepositDistribution,
        })
      : {error: true};
    if (res.error) {
      toast({
        preset: 'error',
        title: t('SNACKBAR.ORDER-NOT-CREATED'),
      });
    } else {
      router.dismissTo({pathname: '/overview/[customerId]', params: {customerId}});
      toast({
        preset: 'done',
        title: t('SNACKBAR.DEPOSIT-CREATED'),
      });
      onFinished?.();
    }
  }, [postOrder, customerId, themeDepositDistribution, t, router, onFinished]);

  const depositChange = useMemo(
    () =>
      orderType !== 'RECURRING_DEPOSIT_CHANGE' && (
        <WithLabel header={t('ORDER_SUMMARY.DEPOSIT.AMOUNT-TITLE')} horizontal>
          {value && <LocalizedNumbers value={value} textComponent={Label3} />}
        </WithLabel>
      ),
    [orderType, t, value]
  );

  const distributionGuard = useMemo(() => {
    if (themeDepositDistribution && Object.keys(themeDepositDistribution).length === 0) {
      return (
        <Redirect
          href={{
            pathname: '/overview/[customerId]/order/deposit-motive',
            params: {customerId, orderType, value},
          }}
        />
      );
    }
  }, [customerId, orderType, themeDepositDistribution, value]);

  return (
    <>
      {distributionGuard}
      <Skeleton width="100%" height={140}>
        {depositChange}
      </Skeleton>

      {value !== undefined &&
        themeDepositDistribution !== undefined &&
        Object.entries(themeDepositDistribution).map(([code, distribution]) => (
          <ThemeDepositSummaryRow
            key={code}
            code={code as ThemeCode}
            distribution={distribution}
            value={value}
          />
        ))}
      <Button alignSelf="stretch" onPress={submitOrder}>
        {t('ORDER_SUMMARY.CONFIRM')}
      </Button>
    </>
  );
}
