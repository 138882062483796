import React, {useEffect, useMemo, useState} from 'react';

import {Label3} from '@/components/texts/Label';
import {PreviousTaxItem} from '@/modules/profile/components/taxes/PreviousTaxItem';
import {useGetSelfUserQuery, useGetTaxExemptionsQuery} from '@/store/queries/usersApi';
import {TaxExemptionOrderChangeResponse} from '@/types/api/users';
import {skipToken} from '@reduxjs/toolkit/query';
import {useTranslate} from '@tolgee/react';
import {Accordion, XStack, YStack} from 'tamagui';

export const PreviousTaxes = () => {
  const {t} = useTranslate();
  const {data: user} = useGetSelfUserQuery();
  const userID = useMemo(() => user?.id, [user]);
  const {data, isFetching, isLoading} = useGetTaxExemptionsQuery(
    userID === undefined ? skipToken : {userID}
  );

  const [historic, setHistoric] = useState<TaxExemptionOrderChangeResponse[]>();

  useEffect(() => {
    if (data && data?.items?.length > 0 && !isFetching && !isLoading) {
      setHistoric(data.items);
    }
  }, [data, isFetching, isLoading]);

  if ((!data || !data.items || data.items.length === 0) && !isFetching && !isLoading) return null;

  return (
    <YStack gap="$4" flex={1}>
      <XStack paddingHorizontal="$2">
        <Label3 flex={1} flexBasis={0} color="$neutral500" alignContent="flex-start">
          {t('TAXES.TAX-EXEMPTION.PREVIOUS.BANK')}
        </Label3>
        <Label3 flex={1} flexBasis={0} color="$neutral500" alignContent="flex-start">
          {t('TAXES.TAX-EXEMPTION.PREVIOUS.SUM')}
        </Label3>
        <Label3 flex={1} flexBasis={0} color="$neutral500" alignContent="flex-start">
          {t('TAXES.TAX-EXEMPTION.PREVIOUS.TYPE')}
        </Label3>
        <Label3 flex={1} flexBasis={0} color="$neutral500" alignContent="flex-start">
          {t('TAXES.TAX-EXEMPTION.PREVIOUS.START')}
        </Label3>
        <Label3
          flex={1.5}
          flexBasis={0}
          color="$neutral500"
          alignContent="flex-start"
          paddingRight="$6"
        >
          {t('TAXES.TAX-EXEMPTION.PREVIOUS.SUBMITTED-ON')}
        </Label3>
      </XStack>
      <Accordion overflow="hidden" type="multiple">
        {historic?.map((order, index) => (
          <PreviousTaxItem order={order} key={`${index}-${order.createdAt}`} index={index} />
        ))}
      </Accordion>
    </YStack>
  );
};
