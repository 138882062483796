import {FormBicInput} from '@/components/inputs/form/FormBicInput';
import {FormIbanInput} from '@/components/inputs/form/FormIbanInput';
import {FormTextInput} from '@/components/inputs/form/FormTextInput';
import useDebounce from '@/hooks/useDebounce';
import {BankInfoSchemaType, useValidations} from '@/hooks/useValidations';
import {WarningBox} from '@/modules/orders/components/WarningBox';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useLazyGetIbanStatusQuery} from '@/store/queries/validationApi';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslate} from '@tolgee/react';
import {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {useForm} from 'react-hook-form';
import {YStack, useMedia} from 'tamagui';

type BankInfoFormProps = {
  onValid: (data: BankInfoSchemaType) => void;
  defaultValues?: {
    iban?: string;
    bic?: string;
    bank?: string;
  };
  bordered?: boolean;
};
export const BankInfoForm = forwardRef<any, BankInfoFormProps>(function BankInfoForm(
  {onValid, defaultValues, bordered},
  ref
) {
  const {t} = useTranslate();
  const media = useMedia();
  const {bankInfoSchema} = useValidations();
  const [getIbanStatus] = useLazyGetIbanStatusQuery();
  const [ibanValue, setIbanValue] = useState('');
  const [ibanValidationByServiceFinished, setIbanValidationByServiceFinished] = useState(false);
  const [bicRequired, setBicRequired] = useState(true);
  const [bankNameRequired, setBankNameRequired] = useState(true);
  const [isIngExtra, setIsIngExtra] = useState(false);
  const posthog = usePostHog();
  const {handleSubmit, control, watch, setValue, trigger} = useForm({
    resolver: yupResolver(bankInfoSchema),
    mode: 'onChange',
    context: {
      bicRequired,
      bankNameRequired,
    },
    defaultValues: {
      iban: '',
      bic: '',
      bank: '',
    },
  });

  useImperativeHandle(ref, () => ({
    submit: handleSubmit(onValid, data => {
      posthog?.capture('form_submit_failed', data);
    }),
  }));

  useEffect(() => {
    if (defaultValues) {
      if (defaultValues.iban) {
        setValue('iban', defaultValues.iban.replaceAll(' ', ''), {shouldDirty: true});
        setIbanValue(defaultValues.iban.replaceAll(' ', ''));
      }
      defaultValues.bic && setValue('bic', defaultValues.bic);
      defaultValues.bank && setValue('bank', defaultValues.bank);
    }
  }, [defaultValues, setValue]);

  useEffect(() => {
    const subscription = watch(async (value, {name, type}) => {
      if (name === 'iban' && value.iban) {
        setIbanValue(value.iban);
      }
    });
    return () => subscription.unsubscribe();
  }, [getIbanStatus, setValue, trigger, watch]);

  useDebounce(
    async () => {
      if (!ibanValue) return;
      const response = await getIbanStatus(ibanValue);

      if (response.data) {
        const valid = !!response.data.valid;
        setValue('openiban', valid);
        if (response.data.bankData.bic) setValue('bic', response.data.bankData.bic);
        if (response.data.bankData.name) setValue('bank', response.data.bankData.name);
        setBicRequired(valid && !response.data.bankData.bic);
        setBankNameRequired(valid && !response.data.bankData.name);
        setIsIngExtra(response.data.bankData.bic === 'INGDDEFFXXX');
        trigger('iban');
      } else {
        setBicRequired(true);
        setBankNameRequired(true);
        setIsIngExtra(false);
      }
      setIbanValidationByServiceFinished(true);
    },
    500,
    [ibanValue, getIbanStatus, setValue, trigger]
  );

  return (
    <YStack gap="$4" maxWidth={media.sm ? 'unset' : 400}>
      <FormIbanInput
        bordered={bordered}
        control={control}
        name="iban"
        label={t('ACCOUNT_SETUP.IBAN')}
      />
      {bicRequired && ibanValidationByServiceFinished && (
        <FormBicInput
          bordered={bordered}
          control={control}
          name="bic"
          label={t('ACCOUNT_SETUP.BIC')}
        />
      )}
      {bankNameRequired && ibanValidationByServiceFinished && (
        <FormTextInput
          bordered={bordered}
          control={control}
          name="bank"
          label={t('ACCOUNT_SETUP.BANK_NAME')}
        />
      )}
      <WarningBox text={t('ACCOUNT_SETUP.BIC_NOTE')} show={isIngExtra} />
    </YStack>
  );
});
