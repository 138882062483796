import {Skeleton} from '@/components/views/Skeleton';
import {AccountCard} from '@/modules/overview/components/AccountCard';
import {useGetPerformanceSummariesQuery} from '@/store/queries/apeironApi';
import {useGetCustomerAccountsQuery} from '@/store/queries/customersApi';
import {skipToken} from '@reduxjs/toolkit/query';
import React from 'react';
import {YStack} from 'tamagui';

export function AccountList() {
  const {data: customers, isFetching: isCustomersFetching} = useGetCustomerAccountsQuery();
  const {data: apeironSummary, isFetching: isAccSummaryFetching} = useGetPerformanceSummariesQuery(
    customers && customers.totalCount > 0
      ? {customerIds: customers.items.map(_ => _.id)}
      : skipToken
  );
  return (
    <Skeleton width="100%" height={100}>
      {!isCustomersFetching && !isAccSummaryFetching && (
        <YStack gap="$4">
          {!!customers &&
            customers.items.length > 0 &&
            customers.items.map(customer => (
              <AccountCard
                customer={customer}
                key={customer.id}
                apeiron={apeironSummary?.[0]?.items.find(_ => _.customerId === customer.id)}
              />
            ))}
        </YStack>
      )}
    </Skeleton>
  );
}
