import {Stack} from 'expo-router';
import {YStack} from 'tamagui';

import {Header} from '@/modules/navigation/components/header/Header';
import {OnboardingHeader} from '@/modules/navigation/components/header/OnboardingHeader';
import {useScreenOptions} from '@/modules/navigation/hooks/useScreenOptions';

export default function OnboardingLayout() {
  const screenOptions = useScreenOptions({headerShown: false});

  return (
    <YStack flex={1}>
      <OnboardingHeader />
      <Stack screenOptions={screenOptions}>
        <Stack.Screen
          name="personal-details"
          options={{
            headerShown: true,
            header: () => (
              <Header
                withSidePanel={false}
                style={{
                  '$platform-web': {
                    alignSelf: 'center',
                    maxWidth: 1000,
                  },
                }}
              />
            ),
          }}
        />
      </Stack>
    </YStack>
  );
}
