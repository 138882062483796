import {baseApi} from '@/store/queries/baseApi';
import {
  AnonymousBasicV2Request,
  AnonymousRiskProfileEvaluationV2Request,
  BasicV2Request,
  BasicV2Response,
  InterviewSummary,
  RiskProfileEvaluationV2Request,
  RiskProfileEvaluationV2Response,
} from '@/types/api/interviews';

export type GetLatestAcceptableInterviewsPayload = {
  type: InterviewSummary['type'];
};

type GetLatestAcceptableInterviewsParams = {
  verbosity: 'LATEST_ACCEPTABLE';
} & Partial<GetLatestAcceptableInterviewsPayload>;

export type CreateInterviewPayload = BasicV2Request | RiskProfileEvaluationV2Request;

export type CreateInterviewBasicV2Response = Omit<BasicV2Response, 'type'> & {
  type: 'BASIC_V2';
};

export type CreateInterviewRiskProfileEvaluationV2Response = Omit<
  RiskProfileEvaluationV2Response,
  'type'
> & {
  type: 'RISK_PROFILE_EVALUATION_V2';
};

export type CreateInterviewResponse =
  | CreateInterviewBasicV2Response
  | CreateInterviewRiskProfileEvaluationV2Response;

const BASE_PATH = 'interviews/v3';

const extendedInterviewApi = baseApi.injectEndpoints({
  endpoints: build => ({
    getLatestAcceptableInterviews: build.query<
      InterviewSummary[],
      GetLatestAcceptableInterviewsPayload | void
    >({
      query: arg => {
        const params: GetLatestAcceptableInterviewsParams = {
          verbosity: 'LATEST_ACCEPTABLE',
        };

        if (arg) {
          params.type = arg.type;
        }

        return {
          url: BASE_PATH,
          params,
        };
      },
      providesTags: ['INTERVIEWS'],
    }),
    getInterview: build.query<BasicV2Response | RiskProfileEvaluationV2Response, {id: string}>({
      query: ({id}) => ({
        url: `${BASE_PATH}/${id}`,
      }),
    }),
    createInterview: build.mutation<CreateInterviewResponse, CreateInterviewPayload>({
      query: payload => ({
        url: BASE_PATH,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['INTERVIEWS'],
    }),
    createPublicInterview: build.mutation<
      CreateInterviewResponse,
      AnonymousRiskProfileEvaluationV2Request | AnonymousBasicV2Request
    >({
      query: payload => ({
        url: `${BASE_PATH}/public`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetLatestAcceptableInterviewsQuery,
  useGetInterviewQuery,
  useCreateInterviewMutation,
  useCreatePublicInterviewMutation,
} = extendedInterviewApi;
