import {UseQuery} from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {useCallback, useEffect, useRef, useState} from 'react';

export interface ListQueryResponse<T> {
  items: T[];
  totalCount: number;
}

const DEFAULT_LIMIT = 40;

// TODO: optimize this
const useInfiniteScroll = <T = undefined>(
  useGetDataListQuery: UseQuery<any>,
  {...queryParameters}
): {
  combinedData: {[customerId: string]: ListQueryResponse<T>};
  data: ListQueryResponse<T>;
  loadMore: () => void;
  refetch: () => Promise<void>;
  allDataFetched: boolean;
  isLoading: boolean;
  isFetching: boolean;
} => {
  const [offset, setOffset] = useState(0);
  const [combinedData, setCombinedData] = useState<any>({});
  const {customerId, limit, category, sourceFilter} = queryParameters;
  const previousCustomerId = useRef(customerId);

  const LIMIT: number = limit || DEFAULT_LIMIT;

  const queryResponse = useGetDataListQuery({
    ...queryParameters,
    customerId: queryParameters.customerId ?? customerId,
    offset: customerId === previousCustomerId.current ? offset : 0,
    limit: LIMIT,
  });

  const res = (queryResponse?.data as ListQueryResponse<T>) || {};

  useEffect(() => {
    setCombinedData({
      ...combinedData,
      [customerId]: undefined,
    });
    setOffset(0);
    setTimeout(() => {
      previousCustomerId.current = customerId;
    }, 0);
  }, [customerId, category, sourceFilter]);

  useEffect(() => {
    if (res?.items) {
      if (offset === 0) {
        setCombinedData({...combinedData, [customerId]: res});
      } else {
        const items = [...combinedData[customerId].items, ...res.items];
        setCombinedData({
          ...combinedData,
          [customerId]: {items, totalCount: combinedData[customerId].totalCount},
        });
      }
    }
  }, [res]);

  const refetch = useCallback(async () => {
    await queryResponse.refetch();
  }, [res]);

  const loadMore = () => {
    if (
      combinedData[customerId]?.items &&
      combinedData[customerId].items.length < combinedData[customerId].totalCount &&
      !queryResponse?.isFetching &&
      !queryResponse?.isLoading
    ) {
      setOffset(prev => prev + LIMIT);
    }
  };

  const data = combinedData[customerId];

  return {
    combinedData,
    data,
    loadMore,
    refetch,
    allDataFetched: combinedData[customerId]?.items.length === combinedData[customerId]?.totalCount,
    isLoading: !!queryResponse?.isLoading,
    isFetching: !!queryResponse?.isFetching,
  };
};

export default useInfiniteScroll;
