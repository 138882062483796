import SvgFile from '@/components/icons/SvgFile';
import {SelectInput} from '@/components/inputs/select-input/SelectInput';
import {useGetCustomerAccountsQuery} from '@/store/queries/customersApi';
import {SelectInputItem} from '@/types/FormInputProps';
import {PropsWithChildren, useEffect, useMemo, useState} from 'react';
import {View, useMedia} from 'tamagui';

export type FilterProps = {
  onFilterSelected: (option: string) => void;
  preselected?: string;
};

export function FilterSelectCustomerId({
  onFilterSelected,
  preselected,
}: PropsWithChildren<FilterProps>) {
  const {data: customers} = useGetCustomerAccountsQuery();
  const media = useMedia();
  const [selectedItem, setSelectedItem] = useState<string | undefined>();

  useEffect(() => {
    setSelectedItem(preselected);
  }, [preselected]);

  const onSelect = (option: string) => {
    setSelectedItem(option);
    onFilterSelected(option);
  };

  const items = useMemo(() => {
    const tempItems: SelectInputItem[] = [];
    if (customers) {
      customers.items.forEach(({id, displayName}) => {
        tempItems.push({name: displayName, key: id});
      });
    }
    return tempItems;
  }, [customers]);

  return (
    <View alignSelf="flex-end">
      <SelectInput
        items={items}
        placeholder={preselected ?? ''}
        onSelect={onSelect}
        wrapperProps={{unstyled: true, alignItems: 'flex-end'}}
        selectedItem={selectedItem}
      >
        {media.sm && <SvgFile name="filter" />}
      </SelectInput>
    </View>
  );
}
