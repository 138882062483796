import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslate} from '@tolgee/react';
import {Href, useRouter} from 'expo-router';
import {openBrowserAsync} from 'expo-web-browser';
import {useForm} from 'react-hook-form';
import {XStack, YStack} from 'tamagui';

import SvgFile from '@/components/icons/SvgFile';
import {FormLabelCheckBox} from '@/components/inputs/form/FormLabelCheckBox';
import {Heading4} from '@/components/texts/Heading';
import {Paragraph2, Paragraph3} from '@/components/texts/Paragraph';
import {useValidations} from '@/hooks/useValidations';
import {InterviewBackButton} from '@/modules/interview/components/InterviewBackButton';
import {InterviewContinueButton} from '@/modules/interview/components/InterviewContinueButton';
import {usePostHog} from '@/providers/posthog/usePostHog';

type ConfirmNoExperienceAnswerProps = {
  href: Href;
};

export function ConfirmNoExperienceAnswer({href}: ConfirmNoExperienceAnswerProps) {
  const {interviewNoExpConfirmSchema} = useValidations();
  const {t} = useTranslate();
  const controlName = 'confirm';
  const router = useRouter();
  const posthog = usePostHog();

  const {control, handleSubmit} = useForm({
    mode: 'onChange',
    resolver: yupResolver(interviewNoExpConfirmSchema),
    defaultValues: {
      confirm: false,
    },
  });

  const handleValidSubmit = () => {
    router.navigate(href);
  };

  const onSubmitBack = () => {
    router.back();
  };

  const openDocument = () => {
    openBrowserAsync(process.env.EXPO_PUBLIC_GINMON_RISK_OF_INVESTMENT);
  };

  return (
    <YStack gap="$6">
      <Heading4 variant="medium">{t('ONBOARDING.NO-EXPERIENCE')}</Heading4>
      <Paragraph2 color="$neutral500">{t('ONBOARDING.NO-EXPERIENCE.DESCRIPTION')}</Paragraph2>
      <Paragraph2>{t('ONBOARDING.NO-EXPERIENCE.TEXT')}</Paragraph2>
      <XStack gap="$2" onPress={openDocument} cursor="pointer">
        <SvgFile name="download" size={20} color="$primary" />
        <Paragraph3 color="$primary">{t('ONBOARDING.NO-EXPERIENCE.FILE')}</Paragraph3>
      </XStack>
      <FormLabelCheckBox
        control={control}
        name={controlName}
        label={t('ONBOARDING.NO-EXPERIENCE.CHECKBOX')}
      />
      <InterviewContinueButton
        onPress={handleSubmit(handleValidSubmit, data => {
          posthog?.capture('form_submit_failed', data);
        })}
      />
      <InterviewBackButton onPress={onSubmitBack} />
    </YStack>
  );
}
