import {useId} from 'react';
import Svg, {Defs, Path, RadialGradient, Stop} from 'react-native-svg';
import {View} from 'tamagui';

export function PrimaryGradientSvg() {
  const id = useId();

  return (
    <View top={0} right={0} bottom={0} left={0} position="absolute">
      <Svg preserveAspectRatio="none" width="100%" height="100%" fill="none" viewBox="0 0 375 305">
        <Path fill={`url(#home-gradient_svg__a_${id})`} d="M0 0h375v305H0z" />
        <Defs>
          <RadialGradient
            id={`home-gradient_svg__a_${id}`}
            cx="0"
            cy="0"
            r="1"
            gradientTransform="rotate(129.474 159.421 131.79)scale(275.273 299.304)"
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#2663EB" />
            <Stop offset="1" stopColor="#5388FF" />
          </RadialGradient>
        </Defs>
      </Svg>
    </View>
  );
}
