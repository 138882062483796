import {ZERO_DEPOSIT} from '@/components/forms/InitialDepositForm';
import {POR_DOCUMENT_TYPES} from '@/constants/documents';
import {PartnerBank} from '@/constants/partner-bank';
import {COUNTRY_ID_MATCH, DEFAULT_ID_MATCH, TIN_MATCH, UMLAUTS_REGEXP} from '@/constants/regexp';
import {calculateTimeBetweenDates} from '@/helpers/calculateTimeBetweenDates';
import {convertToE164PhoneNumber} from '@/helpers/convertToE164PhoneNumber';
import {getPorDocTypes} from '@/helpers/getPorDocTypes';
import {isDateInPast} from '@/helpers/isDateInPast';
import {germanTaxIdChecksum, removeStringSpaces} from '@/helpers/stringProcessors';
import {substractFromDate} from '@/helpers/substractDates';
import {validateIBAN} from '@/helpers/validateIBAN';
import {useAppSelector} from '@/store';
import {TransactionsDTO} from '@/types/api/apeiron';
import {IdentityProof} from '@/types/api/refdata.v2';
import {useTranslate} from '@tolgee/react';
import {useMemo} from 'react';
import * as Yup from 'yup';

export function useValidations() {
  const {t} = useTranslate();
  const language = useAppSelector(state => state.app.language);

  return useMemo(() => {
    const commonRules = {
      string: Yup.string().required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
      boolean: Yup.boolean(),
      nullableString: Yup.string(),
      stringArray: Yup.array()
        .of(Yup.string().required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')))
        .min(1, t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET'))
        .required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
      name: Yup.string()
        .required(t('NAME_CHARACTER_VALIDATION'))
        .min(2, t('NAME_CHARACTER_VALIDATION'))
        .max(50, t('NAME_CHARACTER_VALIDATION'))
        .matches(
          // eslint-disable-next-line no-useless-escape
          /^[ .A-Za-zÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÑÒÓÔÕÖØÙÚÛÜßàáâãäåæçèéêëìíîïñòóôõöøùúûüÿẞ\-]+$/,
          t('PERSONAL_DETAILS.INCORRECT_DATA')
        ),
      dateOfBirth: Yup.string()
        .test('valid-date', t('PERSONAL_DETAILS.ERROR_INVALID_DATE'), val => {
          if (!val) return false;

          const [year, month, day] = val.split('-');

          if (!year || !month || !day) return false;

          const date = new Date(`${year}-${month}-${day}`);

          if (isNaN(date.getTime())) return false;

          const parsedYear = Number(year);
          const parsedMonth = Number(month);
          const parsedDay = Number(day);

          if (isNaN(parsedYear) || isNaN(parsedMonth) || isNaN(parsedDay) || year.length !== 4) {
            return false;
          }

          return date.getMonth() === parsedMonth - 1;
        })
        .test('min-date', t('PERSONAL_DETAILS.ERROR_MIN_DATE'), val => {
          if (!val) return false;
          const minDate = new Date('1900-01-01').getTime();
          const date = Date.parse(val);
          return date >= minDate;
        })
        .test('max-date', t('PERSONAL_DETAILS.ERROR_INVALID_DATE'), val => {
          if (!val) return false;
          const maxDate = Date.now();
          const date = Date.parse(val);
          return date <= maxDate;
        }),
      germanTaxID: Yup.string()
        .trim()
        .test('german-number', (value, {createError}) => {
          if (!value) return true;

          if (value?.trim().length !== 11) {
            return createError({message: t('PERSONAL_DETAILS.ERROR_LENGTH_TAX_ID')});
          }

          if (value?.trim()?.charAt(0) === '0') {
            return createError({
              message: t('PERSONAL_DETAILS.ERROR_LEADING_ZERO_TAX_ID'),
            });
          }

          const removed = removeStringSpaces(value);
          const pattern = /^[1-9]\d{10}$/;

          if (!(pattern.test(removed) && germanTaxIdChecksum(removed))) {
            return createError({message: t('PERSONAL_DETAILS.ERROR_INVALID_TAX_ID')});
          }

          return true;
        }),
      phoneNumberE164: (schema: Yup.StringSchema<string | undefined, Yup.AnyObject, unknown, ''>) =>
        schema.test(
          'phone',
          t('PERSONAL_DETAILS.INCORRECT_PHONE_NUMBER'),
          (val: string | undefined) => {
            try {
              // If number is masked from backend we treat it as valid
              if (val?.includes('XXX')) return true;
              return (val && convertToE164PhoneNumber(val)) !== null;
            } catch {
              return false;
            }
          }
        ),
      spouseReqValue: Yup.string().test(
        'spouse value string test',
        t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET'),
        (value, {options: {context}}) => {
          const withSpouse = context?.withSpouse as boolean;

          if (!withSpouse) return true;

          return !!value;
        }
      ),
    };

    const rules = {
      postalCode: Yup.string()
        .required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET'))
        .matches(/^[a-zA-ZäöüßÄÖÜ0-9 .'-_=+,:;\\/\\\\]+$/, t('PERSONAL_DETAILS.INCORRECT_DATA')),
      cityStreet: Yup.string()
        .required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET'))
        .test(
          'len',
          t('NAME_CHARACTER_VALIDATION'),

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (val: any) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return val && val.length >= 2 && val.length <= 50;
          }
        )
        .matches(
          // eslint-disable-next-line no-useless-escape
          /^[\d '(),./A-Za-zÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÑÒÓÔÕÖØÙÚÛÜßàáâãäåæçèéêëìíîïñòóôõöøùúûüÿẞ\-]+$/,
          t('PERSONAL_DETAILS.INCORRECT_DATA')
        ),
      phoneNumber: Yup.string().when('$child', {
        is: true,
        then: (schema: Yup.StringSchema<string | undefined, Yup.AnyObject, unknown, ''>) => schema,
        otherwise: commonRules.phoneNumberE164,
      }),
      userEmail: Yup.string()
        .test('not allow umlauts', t('LOGIN.INVALID_EMAIL'), v => !v?.match(UMLAUTS_REGEXP))
        .test(
          'currentEmail',
          t('LOGIN.INVALID_EMAIL.SAME-EMAIL'),
          (email, {options: {context}}) => email !== context?.currentEmail
        )
        .email(t('LOGIN.INVALID_EMAIL'))
        .matches(
          /^[-a-zA-Z0-9~!$%^&*_=+}{\'?]+(\.[-a-zA-Z0-9~!$%^&*_=+}{\'?]+)*@([a-zA-Z0-9_][-a-zA-Z0-9_]*(\.[-a-zA-Z0-9_]+)+)$/,
          t('LOGIN.INVALID_EMAIL')
        )
        .required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
      verificationCode: Yup.string()
        .required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET'))
        .trim()
        .matches(/^[0-9]\d{4}$/, t('PERSONAL_DETAILS.SHOULD_BE_5_DIGITS')),
      streetNumber: Yup.string()
        .required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET'))
        .matches(/^[\d (),-./A-Za-z]+$/, t('PERSONAL_DETAILS.INCORRECT_DATA')),
      dateOfBirth: commonRules.dateOfBirth.when('$child', {
        is: true,
        then: schema =>
          schema.test('child-age', t('PERSONAL_DETAILS.ALREADY_ADULT'), val => {
            if (!val) return false;
            const today = new Date();
            today.setUTCHours(0, 0, 0, 0);
            const years = calculateTimeBetweenDates({
              startDate: Date.parse(val),
              endDate: today,
              type: 'years',
              noRounding: true,
            });
            return years < 18;
          }),
        otherwise: schema =>
          schema.test('is-of-age', t('PERSONAL_DETAILS.AGE_LIMIT'), val => {
            if (!val) return false;
            const today = new Date();
            today.setUTCHours(0, 0, 0, 0);
            const years = calculateTimeBetweenDates({
              startDate: Date.parse(val),
              endDate: today,
              type: 'years',
              noRounding: true,
            });
            return years >= 18;
          }),
      }),
      taxes: Yup.array(
        Yup.object().shape({
          country: Yup.string().required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
          number: Yup.string()
            .when('country', {
              is: (country: string) => country === 'DE',
              then: schema => schema.notRequired(),
              otherwise: schema => schema.required(t('PERSONAL_DETAILS.ERROR_INVALID_TAX_ID')),
            })
            .test('valid-tin', t('PERSONAL_DETAILS.ERROR_INVALID_TAX_ID'), (val, context) => {
              if (context.parent.country === undefined || !val) return true;

              const pattern = TIN_MATCH[context.parent.country];
              return pattern.test(val);
            }),
        })
      ).test('Unique', t('PERSONAL_DETAILS.ERROR_DUPLICATE_TAX_ID'), values => {
        return !values?.some(_ => values.filter(v => v.country === _.country).length > 1);
      }),
      startDate: Yup.mixed()
        .test('valid-date', t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET'), val => {
          if (!val) return false;
          if (typeof val === 'string' || typeof val === 'number')
            return !isNaN(new Date(val).getTime());
          return false;
        })
        .test('valid-length', t('PERSONAL_DETAILS.ERROR_INVALID_DATE'), val =>
          typeof val === 'string' ? val.replaceAll(/[/_]/g, '').length === 10 : false
        )
        .test('min-date', t('PERSONAL_DETAILS.ERROR_MIN_DATE'), val => {
          if (!val || !['string', 'number'].includes(typeof val)) return false;
          const beginningOfYear = new Date(`${new Date().getFullYear()}-01-01`);
          return (
            calculateTimeBetweenDates({
              startDate: beginningOfYear,
              endDate: val as string | number,
            }) > 0
          );
        }),
      company: Yup.string()
        .required(t('NAME_CHARACTER_VALIDATION'))
        .test(
          'len',
          t('NAME_CHARACTER_VALIDATION'),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          (val: any) => val && val.length >= 2 && val.length <= 50
        )
        .matches(
          /^[\w +,.:;=ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝßàáâãäåæçèéêëìíîïñòóôõöøùúûüýÿĆćČčĐđŠšŽžẞ-]*$/g,
          t('PERSONAL_DETAILS.INCORRECT_DATA')
        ),
      childField: <T extends Yup.AnySchema>(
        schema: T,
        key: string,
        requiredError: string,
        isFunc?: (value: any) => boolean
      ) =>
        schema.when(key, {
          is: isFunc ?? true,
          then: schema => schema.required(requiredError),
          otherwise: schema => schema.notRequired(),
        }),
      employmentCountry: Yup.string().when('profession', {
        is: (profession?: string) => !!profession && (profession === 'CEO' || profession === 'CHM'),
        then: schema => schema.required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
        otherwise: schema => schema,
      }),
      beneficialOwnerOfCompany: Yup.string().when('beneficialOwner', {
        is: true,
        then: schema => schema.required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
        otherwise: schema => schema,
      }),
      secondGuardianDateOfBirth: Yup.string().when('guardianType', {
        is: (value: string) => value === 'shared',
        then: () =>
          commonRules.dateOfBirth.test('is-of-age', t('PERSONAL_DETAILS.AGE_LIMIT'), val => {
            if (!val) return false;
            const today = new Date();
            today.setUTCHours(0, 0, 0, 0);
            const years = calculateTimeBetweenDates({
              startDate: Date.parse(val),
              endDate: today,
              type: 'years',
              noRounding: true,
            });
            return years >= 18;
          }),
        otherwise: schema => schema,
      }),
      secondGuardianEmail: Yup.string().when('guardianType', {
        is: (value: string) => value === 'shared',
        then: schema =>
          schema
            .required(t('LOGIN.PLEASE_PROVIDE_EMAIL'))
            .test('not allow umlauts', t('LOGIN.INVALID_EMAIL'), v => !v?.match(UMLAUTS_REGEXP))
            .test(
              'same-email',
              t('PERSONAL_DETAILS.ANOTHER_GUARDIAN.INVALID_EMAIL_ITS_YOUR'),
              (email, {parent}) => email !== parent.selfEmail
            )
            .email(t('LOGIN.INVALID_EMAIL'))
            .matches(
              /^[-a-zA-Z0-9~!$%^&*_=+}{\'?]+(\.[-a-zA-Z0-9~!$%^&*_=+}{\'?]+)*@([a-zA-Z0-9_][-a-zA-Z0-9_]*(\.[-a-zA-Z0-9_]+)+)$/,
              t('LOGIN.INVALID_EMAIL')
            ),
        otherwise: schema => schema,
      }),
      spouseAmount: Yup.number().test(
        'spouse-amount',
        t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET'),
        (value, context) => {
          if (!value) return false;
          const withSpouse = context?.options?.context?.withSpouse as boolean;
          const usedAmount = context?.options?.context?.usedAmount as number;

          if (withSpouse) {
            if (value > 2000) {
              return context.createError({
                message: t('NEW_TAX_EXEMPTION_ORDER.ERRORS.TAX_EXEMPTION_AMOUNT_JOINT_LIMIT'),
              });
            }
          } else {
            if (value > 1000) {
              return context.createError({
                message: t('NEW_TAX_EXEMPTION_ORDER.ERRORS.TAX_EXEMPTION_AMOUNT_SINGLE_LIMIT'),
              });
            }
          }

          if (usedAmount > value) {
            return context.createError({
              message: t('NEW_TAX_EXEMPTION_ORDER.ERRORS.SHOULD_BE_GREATER_THAN_USED'),
            });
          }

          return true;
        }
      ),
      validUntil: Yup.string().test(
        'until > from',
        t('NEW_TAX_EXEMPTION_ORDER.ERRORS.END_MORE_START'),
        (value, context) => {
          if (!value) return true;

          const validFrom = Number(context.parent?.validFrom);

          if (Number.isNaN(validFrom)) return true;

          const validUntil = Number(value.split(' ').at(-1));

          if (Number.isNaN(validUntil)) return true;

          return validUntil >= validFrom;
        }
      ),
      spouseTaxID: Yup.string().when('$withSpouse', {
        is: true,
        then: () => commonRules.germanTaxID,
        otherwise: schema => schema,
      }),
      spouseDateBirth: Yup.string().when('$withSpouse', {
        is: true,
        then: () => commonRules.dateOfBirth,
        otherwise: schema => schema,
      }),
      newPassword: Yup.string()
        .trim()
        .required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET'))
        .min(8, t('LOGIN.PASS_LENGTH'))
        .matches(/(\d*[A-Za-z]+\d*)+/, t('LOGIN.ONE_LOWER_CASE_REQUIRED'))
        .matches(/(\d*[a-z]+\d*)+/, t('LOGIN.ONE_LOWER_CASE_REQUIRED'))
        .matches(/([A-Za-z]*\d+[A-Za-z]*)+/, t('LOGIN.ONE_DIGIT_REQUIRED'))
        .test(
          'same-password',
          t('LOGIN.INVALID_PASSWORD.SAME-PASSWORD'),
          (newPassword, context) => newPassword !== context.parent?.oldPassword
        ),
      uploadFiles: Yup.array()
        .of(
          Yup.object()
            .shape({objectKey: commonRules.string, docName: commonRules.nullableString})
            .required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET'))
        )
        .required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
    };

    const UserDetailsSchema = {
      main: Yup.boolean().default(true),
      child: Yup.boolean().default(false),
      title: rules.childField(
        commonRules.nullableString,
        'main',
        t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')
      ),
      firstName: rules.childField(
        commonRules.name,
        'main',
        t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')
      ),
      lastName: rules.childField(
        commonRules.name,
        'main',
        t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')
      ),
      academicTitle: Yup.string().oneOf(['DR', 'PRF', 'NONE']),
      childTitle: rules.childField(
        commonRules.nullableString,
        'child',
        t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')
      ),
      childFirstName: rules.childField(commonRules.name, 'child', t('NAME_CHARACTER_VALIDATION')),
      childLastName: rules.childField(commonRules.name, 'child', t('NAME_CHARACTER_VALIDATION')),
    };

    const BirthDetailsSchema = {
      placeOfBirth: rules.cityStreet,
      countryOfBirthCode: commonRules.string,
      dateOfBirth: rules.dateOfBirth,
      nationalities: Yup.array(
        Yup.object().shape({
          country: Yup.string().required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
          number: Yup.string().when(['$proofs', 'country'], {
            is: (proofs: IdentityProof[] | undefined, country: string) =>
              proofs?.find(p => country === p.country) !== undefined,
            then: schema =>
              schema.test(
                'not allow umlauts',
                t('PERSONAL_DETAILS.NATIONALITY.WRONG-NUMBER'),
                (v, context) => {
                  const regex = COUNTRY_ID_MATCH[context.parent.country];
                  return v?.match(regex || DEFAULT_ID_MATCH) != null;
                }
              ),
            otherwise: schema => schema.notRequired(),
          }),
          document: rules.uploadFiles.when(['$proofs', 'country'], {
            is: (proofs: IdentityProof[] | undefined, country: string) =>
              proofs?.find(p => country === p.country)?.uploadRequired,
            then: schema =>
              schema
                .min(1, t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET'))
                .required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
            otherwise: schema => schema.notRequired(),
          }),
        })
      ),
    };

    const ChangeEmailSchema = {
      newEmail: rules.userEmail,
    };

    const ChangeEmailConfirmSchema = {
      verificationCode: rules.verificationCode,
      currentPassword: Yup.string().required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
    };

    const ChangePasswordSchema = {
      oldPassword: commonRules.string,
      newPassword: rules.newPassword,
    };

    const AddressDetailsSchema = {
      street: rules.cityStreet,
      streetNumber: rules.streetNumber,
      city: rules.cityStreet,
      countryCode: commonRules.string,
      postCode: rules.postalCode,
      phoneNumber: rules.phoneNumber,
    };

    const EmployerAddressDetailsSchema = {
      employer: commonRules.string,
      street: rules.cityStreet,
      streetNumber: rules.streetNumber,
      city: rules.cityStreet,
      postCode: rules.postalCode,
    };

    const ContactDetailsSchema = {
      ...AddressDetailsSchema,
      phoneNumber: rules.phoneNumber,
    };

    const CareerDetailsSchema = {
      profession: commonRules.string,
      industry: commonRules.string,
      employmentCountry: rules.employmentCountry,
      politicallyExposedPerson: commonRules.boolean,
      beneficialOwner: commonRules.boolean,
      beneficialOwnerOfCompany: rules.beneficialOwnerOfCompany,
    };

    const IncomeOrWealthSchema = {
      sources: commonRules.stringArray,
    };

    const TaxesDetailsSchema = {
      taxIdentification: rules.taxes,
      unitedStatesTax: Yup.boolean()
        .oneOf([true], t('PERSONAL_DETAILS.USA_TAX_HINT'))
        .required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
    };

    const WorkPlaceDetailsSchema = {
      street: rules.cityStreet,
      streetNumber: rules.streetNumber,
      city: rules.cityStreet,
      postalCode: rules.postalCode,
      company: rules.company,
    };

    const VLStartDateSchema = {
      startDate: rules.startDate,
    };

    const AnotherGuardianSchema = {
      selfEmail: commonRules.string,
      guardianType: commonRules.string,
      title: rules.childField(
        commonRules.nullableString,
        'guardianType',
        t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET'),
        value => value === 'shared'
      ),
      firstName: rules.childField(
        commonRules.name,
        'guardianType',
        t('NAME_CHARACTER_VALIDATION'),
        value => value === 'shared'
      ),
      lastName: rules.childField(
        commonRules.name,
        'guardianType',
        t('NAME_CHARACTER_VALIDATION'),
        value => value === 'shared'
      ),
      dateOfBirth: rules.secondGuardianDateOfBirth,
      email: rules.secondGuardianEmail,
    };

    const FinancialSituationSchema = {
      MONTHLY_NET_INCOME: Yup.number()
        .required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET'))
        .min(0, t('INPUT_ERRORS.MIN_VALUE'))
        .max(1_000_000_000, t('INPUT_ERRORS.MAX_VALUE')),
      MONTHLY_SAVING: Yup.number()
        .required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET'))
        .min(0, t('INPUT_ERRORS.MIN_VALUE'))
        .max(1_000_000_000, t('INPUT_ERRORS.MAX_VALUE'))
        .when('MONTHLY_NET_INCOME', ([monthlyNetIncome], schema) => {
          return schema.max(
            monthlyNetIncome,
            t('ONBOARDING.FORM-ERRORS.INCOME-HIGHER-THAN-EXPENSES')
          );
        }),
      monthlyCalculated: Yup.number()
        .min(0, t('INPUT_ERRORS.MIN_VALUE'))
        .max(1_000_000_000, t('INPUT_ERRORS.MAX_VALUE')),
      DISPOSABLE_SAVING: Yup.number()
        .required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET'))
        .min(0, t('INPUT_ERRORS.MIN_VALUE'))
        .max(1_000_000_000, t('INPUT_ERRORS.MAX_VALUE')),
      OTHER_ASSETS: Yup.number()
        .optional()
        .min(0, t('INPUT_ERRORS.MIN_VALUE'))
        .max(1_000_000_000, t('INPUT_ERRORS.MAX_VALUE')),
      CREDITS: Yup.number()
        .optional()
        .min(0, t('INPUT_ERRORS.MIN_VALUE'))
        .max(1_000_000_000, t('INPUT_ERRORS.MAX_VALUE')),
      sumAssets: Yup.number().max(1_000_000_000, t('INPUT_ERRORS.MAX_VALUE')),
    };

    const UpdateGermanTaxIdentificationSchema = {
      newTin: commonRules.germanTaxID,
    };

    const TaxExemptionCreationSchema = {
      custodian: Yup.string().required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
      amount: rules.spouseAmount,
      validFrom: Yup.string().required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
      validUntil: rules.validUntil,
      spouseTitle: commonRules.spouseReqValue,
      spouseDateBirth: rules.spouseDateBirth,
      spouseFirstName: commonRules.spouseReqValue,
      spouseLastName: commonRules.spouseReqValue,
      spouseTaxID: rules.spouseTaxID,
    };

    const PhoneNumberSchema = {
      phoneNumber: commonRules.phoneNumberE164(
        Yup.string().required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET'))
      ),
    };

    const dynamicInterviewSchema = (code: string, multi: boolean = false) => {
      if (multi) {
        return {
          [code]: Yup.array().min(1, t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
        };
      }
      return {
        [code]: Yup.array().length(1, t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
      };
    };

    const InterviewTradesSchema = {
      ASSET_TRADES_BONDS: Yup.string()
        .oneOf(['TRADES_0', 'TRADES_1', 'TRADES_2', 'TRADES_3', 'TRADES_4'])
        .when('$assetClasses', {
          is: (assetClasses: string[]) => assetClasses.some(_ => _.includes('BONDS')),
          then: schema => schema.required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
          otherwise: schema => schema,
        }),
      ASSET_TRADES_ETF: Yup.string()
        .oneOf(['TRADES_0', 'TRADES_1', 'TRADES_2', 'TRADES_3', 'TRADES_4'])
        .when('$assetClasses', {
          is: (assetClasses: string[]) => assetClasses.some(_ => _.includes('ETF')),
          then: schema => schema.required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
          otherwise: schema => schema,
        }),
      ASSET_TRADES_DERIVATIVES: Yup.string()
        .oneOf(['TRADES_0', 'TRADES_1', 'TRADES_2', 'TRADES_3', 'TRADES_4'])
        .when('$assetClasses', {
          is: (assetClasses: string[]) => assetClasses.some(_ => _.includes('DERIVATIVES')),
          then: schema => schema.required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
          otherwise: schema => schema,
        }),
      ASSET_TRADES_STOCKS: Yup.string()
        .oneOf(['TRADES_0', 'TRADES_1', 'TRADES_2', 'TRADES_3', 'TRADES_4'])
        .when('$assetClasses', {
          is: (assetClasses: string[]) => assetClasses.some(_ => _.includes('STOCKS')),
          then: schema => schema.required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
          otherwise: schema => schema,
        }),
      ASSET_VOLUME_BONDS: Yup.string()
        .oneOf(['VOLUME_0', 'VOLUME_1', 'VOLUME_2', 'VOLUME_3', 'VOLUME_4'])
        .when('$assetClasses', {
          is: (assetClasses: string[]) => assetClasses.some(_ => _.includes('BONDS')),
          then: schema => schema.required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
          otherwise: schema => schema,
        }),
      ASSET_VOLUME_ETF: Yup.string()
        .oneOf(['VOLUME_0', 'VOLUME_1', 'VOLUME_2', 'VOLUME_3', 'VOLUME_4'])
        .when('$assetClasses', {
          is: (assetClasses: string[]) => assetClasses.some(_ => _.includes('ETF')),
          then: schema => schema.required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
          otherwise: schema => schema,
        }),
      ASSET_VOLUME_DERIVATIVES: Yup.string()
        .oneOf(['VOLUME_0', 'VOLUME_1', 'VOLUME_2', 'VOLUME_3', 'VOLUME_4'])
        .when('$assetClasses', {
          is: (assetClasses: string[]) => assetClasses.some(_ => _.includes('DERIVATIVES')),
          then: schema => schema.required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
          otherwise: schema => schema,
        }),
      ASSET_VOLUME_STOCKS: Yup.string()
        .oneOf(['VOLUME_0', 'VOLUME_1', 'VOLUME_2', 'VOLUME_3', 'VOLUME_4'])
        .when('$assetClasses', {
          is: (assetClasses: string[]) => assetClasses.some(_ => _.includes('STOCKS')),
          then: schema => schema.required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
          otherwise: schema => schema,
        }),
    };

    const InvestmentProposalDepositSchema = {
      INITIAL_DEPOSIT: Yup.number().when('$product', {
        is: 'VL_ACCOUNT',
        then: schema => schema,
        otherwise: schema =>
          schema
            .required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET'))
            .test('valid-amount-range', t('ACCOUNT_SETUP.ERROR_INVEST_AMOUNT'), val => {
              return val === 0 || (val >= 50 && val <= 999_999);
            }),
      }),
      INITIAL_MONTHLY_SAVINGS: Yup.number()
        .required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET'))
        .when('$product', {
          is: 'VL_ACCOUNT',
          then: schema =>
            schema
              .min(0, t('INPUT_ERRORS.VL_MONTHLY_CONTRIBUTION_RANGE'))
              .max(40, t('INPUT_ERRORS.VL_MONTHLY_CONTRIBUTION_RANGE')),
          otherwise: schema =>
            schema.test('valid-amount-range', t('INPUT_ERRORS.monthlyContributionRange'), val => {
              return val === 0 || (val >= 50 && val <= 50000);
            }),
        }),
    };

    const DepositOrderSchema = {
      deposit: Yup.number().when('depositMethod', {
        is: (method: string) => method === 'direct',
        then: schema =>
          schema
            .test(
              'valid-amount-range',
              t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET'),
              (val, context) => {
                if (val === undefined) return false;

                if (val < 50)
                  return context.createError({
                    message: t('INPUT_ERRORS.DEPOSIT_TOO_SMALL'),
                  });

                if (context?.options?.context?.custodian === PartnerBank.DAB) {
                  const previousDepositsAmount =
                    context?.options?.context?.previousDebitOrders?.reduce(function (
                      acc: any,
                      obj: TransactionsDTO
                    ) {
                      return acc + obj.amount;
                    }, 0);
                  if (val + previousDepositsAmount > 100_000)
                    return context.createError({
                      message: t('INPUT_ERRORS.DEPOSIT_DAB_MAX_PER_DIRECT', {
                        maxValue: Intl.NumberFormat(language, {
                          currency: 'EUR',
                          style: 'currency',
                        }).format(100_000 - previousDepositsAmount),
                      }),
                    });
                }

                const maxValue =
                  context?.options?.context?.custodian === PartnerBank.UPVEST ? 1_000_000 : 100_000;

                if (val > maxValue && context?.options?.context?.custodian === PartnerBank.DAB)
                  return context.createError({
                    message: t('INPUT_ERRORS.DEPOSIT_DAB_MAX', {
                      maxValue: Intl.NumberFormat(language, {
                        currency: 'EUR',
                        style: 'currency',
                      }).format(maxValue),
                    }),
                  });

                if (val > maxValue && context?.options?.context?.custodian === PartnerBank.UPVEST)
                  return context.createError({
                    message: t('INPUT_ERRORS.DEPOSIT_UPVEST_MAX', {
                      maxValue: Intl.NumberFormat(language, {
                        currency: 'EUR',
                        style: 'currency',
                      }).format(maxValue),
                    }),
                  });

                return true;
              }
            )
            .required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
        otherwise: schema => schema,
      }),
      depositMethod: Yup.string()
        .oneOf(['direct', 'wire', 'transfer'])
        .required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
    };

    const SavingsRateOrderSchema = {
      amount: Yup.number()
        .test(
          'not-same',
          t('INPUT_ERRORS.monthlyContributionValidateNotChanged'),
          (amount, {options: {context}}) => {
            return amount !== context?.recurringDeposit;
          }
        )
        .test(
          'regular-threshold',
          t('INPUT_ERRORS.monthlyContributionRange'),
          (amount, context) => {
            return !(amount !== undefined && amount !== 0 && (amount < 50 || amount > 50_000));
          }
        )
        .required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
    };

    const ProofOfResidenceSchema = {
      category: Yup.string().required().oneOf(POR_DOCUMENT_TYPES),
      issuanceDate: Yup.string()
        .required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET'))
        .test({
          name: 'min-doc-date',
          message: t('PROOF_OF_RESIDENCE.INVALID_DATE_ERROR'),
          test: (val, context) => {
            if (val) {
              if (isNaN(Date.parse(val))) return false;
              const docType = getPorDocTypes(t).find(_ => _.value === context.parent.category);
              if (!docType || !context.options.context?.dateOfBirth) return false;
              // User age at the time of document issuance
              const diff = calculateTimeBetweenDates({
                startDate: context.options.context.dateOfBirth,
                endDate: val,
                type: 'years',
                noRounding: true,
              });
              // Period of validity of the document can change depending on the user age at the time of document issuance
              const period = docType.getValityPeriod(diff);
              const minDocDate = substractFromDate(new Date(), period, docType.periodType);
              if (calculateTimeBetweenDates({startDate: val, endDate: minDocDate}) >= 0) {
                return context.createError({
                  message: t('PROOF_OF_RESIDENCE.DOCUMENT_DATE_ERROR', {
                    x: period,
                    term:
                      docType.periodType === 'years'
                        ? t('PROOF_OF_RESIDENCE.YEARS')
                        : t('PROOF_OF_RESIDENCE.MONTHS'),
                  }),
                });
              }
              if (!isDateInPast(val))
                return context.createError({
                  message: t('PROOF_OF_RESIDENCE.DOCUMENT_DATE_IN_FUTURE'),
                });

              return true;
            }
            return false;
          },
        }),
      uploadKeys: rules.uploadFiles,
    };

    const BankInfoSchema = {
      iban: Yup.string()
        .required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET'))
        .test('valid-openiban', t('ACCOUNT_SETUP.INVALID_IBAN'), (value, context) => {
          if (context.parent.openiban !== undefined) return context.parent.openiban;
          else {
            return validateIBAN(value);
          }
        }),
      bic: Yup.string().when('$bicRequired', {
        is: true,
        then: schema =>
          schema
            .matches(/^[A-Z]{6}[\dA-Z]{2}([\dA-Z]{3})?$/, t('ACCOUNT_SETUP.INVALID_BIC'))
            .required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
        otherwise: schema => schema.notRequired(),
      }),
      bank: Yup.string().when('$bankNameRequired', {
        is: true,
        then: schema => schema.required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
        otherwise: schema => schema.notRequired(),
      }),
      openiban: Yup.boolean(),
    };

    const InterviewNoExpConfirmSchema = {
      confirm: Yup.boolean().oneOf([true], t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
    };

    const InitialDepositSchema = {
      amount: Yup.number()
        .required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET'))
        .when('method', {
          is: (method: string) => method !== ZERO_DEPOSIT,
          then: schema =>
            schema.test(
              'valid-amount-range',
              t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET'),
              (val, context) => {
                if (val === undefined) return false;
                const maxValue =
                  context?.options?.context?.custodian === PartnerBank.UPVEST ||
                  (context?.options as any)?.parent?.method === 'WIRE_DEBIT'
                    ? 999_999
                    : 99_999;

                if (val < 50 || val > maxValue || val === 0)
                  return context.createError({
                    message: t('INPUT_ERRORS.DEPOSIT_RANGE', {
                      maxValue: Intl.NumberFormat(language, {
                        currency: 'EUR',
                        style: 'currency',
                      }).format(maxValue),
                    }),
                  });

                return true;
              }
            ),
          otherwise: schema =>
            schema.test(
              'valid-amount-range',
              t('PERSONAL_DETAILS.INITIAL-DEPOSIT.ERROR_VALUE_SHOULD_BE_ZERO'),
              val => {
                if (val === undefined || val !== 0) return false;
                return true;
              }
            ),
        }),
      method: Yup.string()
        .required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET'))
        .oneOf(['DIRECT_DEBIT', 'WIRE_DEBIT', 'SECURITIES_TRANSFER', ZERO_DEPOSIT]),
    };

    const EmployerStartDateSchema = {
      startDateMonth: Yup.string().required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
      startDateYear: Yup.string().required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
    };

    const AgreementsSchema = {
      guardianTerms: Yup.boolean().when('$child', {
        is: true,
        then: schema => schema.oneOf([true], t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
        otherwise: schema => schema,
      }),
      generalTerms: Yup.boolean().oneOf([true], t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
      ginmonTerms: Yup.boolean().oneOf([true], t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
      dabTerms: Yup.boolean().when('$custodian', {
        is: (custodian: string) => custodian === PartnerBank.DAB,
        then: schema => schema.oneOf([true], t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
        otherwise: schema => schema,
      }),
      upvestTerms: Yup.boolean().when('$custodian', {
        is: (custodian: string) => custodian === PartnerBank.UPVEST,
        then: schema => schema.oneOf([true], t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET')),
        otherwise: schema => schema,
      }),
    };

    const GuardianDocsSchema = {
      birthCertificate: rules.uploadFiles,
      soloOrSharedGuardianCertificate: rules.uploadFiles,
    };

    const ChangeDisplayNameSchema = {
      displayName: commonRules.string.max(50, t('PERSONAL_DETAILS.DISPLAYNAME.MAX-LENGTH')).matches(
        // eslint-disable-next-line no-useless-escape
        /^[\w !"$%'()*+,./:;=?@\\|ÄÖÜßäöü-]*$/,
        t('PERSONAL_DETAILS.INCORRECT_DATA')
      ),
    };

    const InternalTransferOrderSchema = {
      amount: Yup.number()
        .required(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET'))
        .test(
          'amount-min',
          t('ORDER.INTERNAL_ASSETS_TRANSFER.MIN-AMOUNT'),
          (amount, {options: {context}}) => amount > 0
        )
        .min(0.01, t('ORDER.INTERNAL_ASSETS_TRANSFER.MIN-AMOUNT'))
        .test(
          'amount-max',
          t('ORDER.INTERNAL_ASSETS_TRANSFER.MAX-AMOUNT'),
          (amount, {options: {context}}) => amount <= context?.maxAmount
        ),
      targetCustomerId: Yup.string().required(
        t('ORDER.INTERNAL_ASSETS_TRANSFER.TO.VALUE_SHOULD_BE_SET')
      ),
    };

    // TODO: measure performance of this and maybe return callbacks instead of it's problematic
    return {
      userDetailsFormSchema: Yup.object(UserDetailsSchema),
      birthDetailsFormSchema: Yup.object().shape(BirthDetailsSchema),
      contactDetailsFormSchema: Yup.object().shape(ContactDetailsSchema),
      addressDetailsSchema: Yup.object().shape(AddressDetailsSchema),
      employerAddressDetailsSchema: Yup.object().shape(EmployerAddressDetailsSchema),
      careerDetailsFormSchema: Yup.object().shape(CareerDetailsSchema),
      incomeOrWealthFormSchema: Yup.object().shape(IncomeOrWealthSchema),
      taxesDetailsFormSchema: Yup.object().shape(TaxesDetailsSchema),
      workPlaceDetailsFormSchema: Yup.object().shape(WorkPlaceDetailsSchema),
      VLStartDateFormSchema: Yup.object().shape(VLStartDateSchema),
      anotherGuardianFormSchema: Yup.object().shape(AnotherGuardianSchema),
      changeEmailFormSchema: Yup.object().shape(ChangeEmailSchema),
      changeEmailConfirmSchema: Yup.object().shape(ChangeEmailConfirmSchema),
      changePasswordFormSchema: Yup.object().shape(ChangePasswordSchema),
      financialSituationFormSchema: Yup.object().shape(FinancialSituationSchema),
      updateGermanTaxIdentificationSchema: Yup.object().shape(UpdateGermanTaxIdentificationSchema),
      phoneNumberSchema: Yup.object().shape(PhoneNumberSchema),
      taxExemptionCreationSchema: Yup.object().shape(TaxExemptionCreationSchema),
      interviewSingleAnswerSchema: (code: string) =>
        Yup.object().shape(dynamicInterviewSchema(code)),
      interviewMultiAnswerSchema: (code: string) =>
        Yup.object().shape(dynamicInterviewSchema(code, true)),
      interviewTradesSchema: Yup.object().shape(InterviewTradesSchema),
      investmentProposalDepositSchema: Yup.object().shape(InvestmentProposalDepositSchema),
      depositOrderSchema: Yup.object().shape(DepositOrderSchema),
      savingsRateOrderSchema: Yup.object().shape(SavingsRateOrderSchema),
      changeDisplayNameSchema: Yup.object().shape(ChangeDisplayNameSchema),
      proofOfResidenceSchema: Yup.object().shape(ProofOfResidenceSchema),
      bankInfoSchema: Yup.object().shape(BankInfoSchema),
      interviewNoExpConfirmSchema: Yup.object().shape(InterviewNoExpConfirmSchema),
      initialDepositSchema: Yup.object().shape(InitialDepositSchema),
      employerStartDateSchema: Yup.object().shape(EmployerStartDateSchema),
      agreementsSchema: Yup.object().shape(AgreementsSchema),
      guardianDocumentsSchema: Yup.object().shape(GuardianDocsSchema),
      internalTransferOrderSchema: Yup.object().shape(InternalTransferOrderSchema),
    };
  }, [t]);
}

export type UserDetailsSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['userDetailsFormSchema']
>;
export type BirthDetailsSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['birthDetailsFormSchema']
>;
export type ContactDetailsSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['contactDetailsFormSchema']
>;
export type AddressDetailsSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['addressDetailsSchema']
>;
export type TaxesDetailsSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['taxesDetailsFormSchema']
>;
export type VLStartDateSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['VLStartDateFormSchema']
>;
export type ChangeEmailSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['changeEmailFormSchema']
>;
export type ChangeEmailConfirmSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['changeEmailConfirmSchema']
>;
export type ChangePasswordSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['changePasswordFormSchema']
>;
export type CareerDetailsSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['careerDetailsFormSchema']
>;
export type WorkPlaceDetailsSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['workPlaceDetailsFormSchema']
>;
export type AnotherGuardianSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['anotherGuardianFormSchema']
>;
export type FinancialSituationSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['financialSituationFormSchema']
>;
export type UpdateGermanTaxIdentificationSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['updateGermanTaxIdentificationSchema']
>;
export type PhoneNumberSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['phoneNumberSchema']
>;
export type TaxExemptionCreationSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['taxExemptionCreationSchema']
>;
export type InterviewTradesSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['interviewTradesSchema']
>;
export type InvestmentProposalDepositSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['investmentProposalDepositSchema']
>;
export type DepositOrderSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['depositOrderSchema']
>;
export type SavingsRateOrderSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['savingsRateOrderSchema']
>;
export type ChangeDisplayNameSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['changeDisplayNameSchema']
>;
export type ProofOfResidenceSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['proofOfResidenceSchema']
>;
export type BankInfoSchemaType = Yup.InferType<ReturnType<typeof useValidations>['bankInfoSchema']>;
export type InterviewNoExpConfirmSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['interviewNoExpConfirmSchema']
>;
export type InitialDepositSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['initialDepositSchema']
>;
export type EmployerAddressDetailsSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['employerAddressDetailsSchema']
>;
export type EmployerStartDateSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['employerStartDateSchema']
>;
export type AgreementsSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['agreementsSchema']
>;
export type GuardianDocumentsSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['guardianDocumentsSchema']
>;
export type InternalTransferOrderSchemaType = Yup.InferType<
  ReturnType<typeof useValidations>['internalTransferOrderSchema']
>;
