import {Button} from '@/components/buttons/Button';
import {Heading4} from '@/components/texts/Heading';
import {Label2} from '@/components/texts/Label';
import {AnimatedBounceView} from '@/components/views/animated/AnimatedBounceView';
import {FullScreenCenterView} from '@/components/views/FullScreenCenterView';
import {isTokenStillValid} from '@/providers/session-provider/utils';
import {useAppSelector} from '@/store';
import {useTranslate} from '@tolgee/react';
import {Image} from 'expo-image';
import {YStack} from 'tamagui';

type EmailVerificationFailureProps = {
  isResending: boolean;
  onEmailResend: () => void;
  onLogin: () => void;
};

export function EmailVerificationFailure({
  isResending,
  onEmailResend,
  onLogin,
}: EmailVerificationFailureProps) {
  const {t} = useTranslate();
  const accessToken = useAppSelector(state => state.auth.accessToken);

  return (
    <FullScreenCenterView>
      <AnimatedBounceView>
        <Image
          source={require('@/assets/images/mail-failure.png')}
          style={{width: 117, height: 120}}
        />
      </AnimatedBounceView>
      <Heading4 variant="medium">{t('EMAIL_VERIFICATION.FAILURE.TITLE')}</Heading4>
      <Label2 color="$neutral500">{t('EMAIL_VERIFICATION.FAILURE.DESCRIPTION')}</Label2>
      <YStack width="100%">
        {accessToken && isTokenStillValid(accessToken) ? (
          <Button size="large" alignSelf="stretch" onPress={onEmailResend} isLoading={isResending}>
            {t('EMAIL_VERIFICATION.FAILURE.RESEND')}
          </Button>
        ) : (
          <Button size="large" alignSelf="stretch" onPress={onLogin} isLoading={isResending}>
            {t('EMAIL_VERIFICATION.FAILURE.LOGIN')}
          </Button>
        )}
      </YStack>
    </FullScreenCenterView>
  );
}
