import {InterviewState} from '@/store/reducers/interview/state';

/**
 * API contract requires sending some arbitrary values if asset was not selected by user
 */
export function fillEmptyTradesAndVolumes(interviewAnswersState: InterviewState) {
  const {
    ASSET_TRADES_BONDS,
    ASSET_TRADES_STOCKS,
    ASSET_TRADES_ETF,
    ASSET_TRADES_DERIVATIVES,
    ASSET_VOLUME_BONDS,
    ASSET_VOLUME_DERIVATIVES,
    ASSET_VOLUME_ETF,
    ASSET_VOLUME_STOCKS,
  } = interviewAnswersState;

  return {
    ...interviewAnswersState,
    ASSET_TRADES_BONDS: ASSET_TRADES_BONDS || 'TRADES_0',
    ASSET_TRADES_STOCKS: ASSET_TRADES_STOCKS || 'TRADES_0',
    ASSET_TRADES_ETF: ASSET_TRADES_ETF || 'TRADES_0',
    ASSET_TRADES_DERIVATIVES: ASSET_TRADES_DERIVATIVES || 'TRADES_0',
    ASSET_VOLUME_BONDS: ASSET_VOLUME_BONDS || 'VOLUME_0',
    ASSET_VOLUME_DERIVATIVES: ASSET_VOLUME_DERIVATIVES || 'VOLUME_0',
    ASSET_VOLUME_ETF: ASSET_VOLUME_ETF || 'VOLUME_0',
    ASSET_VOLUME_STOCKS: ASSET_VOLUME_STOCKS || 'VOLUME_0',
  };
}
