export const DISCOVERY = {
  authorizationEndpoint:
    process.env.EXPO_PUBLIC_AUTH_API_BASE_PATH + '/protocol/openid-connect/auth',
  tokenEndpoint: process.env.EXPO_PUBLIC_AUTH_API_BASE_PATH + '/protocol/openid-connect/token',
  revocationEndpoint:
    process.env.EXPO_PUBLIC_AUTH_API_BASE_PATH + '/protocol/openid-connect/revoke',
  userInfoEndpoint:
    process.env.EXPO_PUBLIC_AUTH_API_BASE_PATH + '/protocol/openid-connect/userinfo',
  endSessionEndpoint:
    process.env.EXPO_PUBLIC_AUTH_API_BASE_PATH + '/protocol/openid-connect/logout',
} as const;

export const SIGN_UP_DISCOVERY = {
  ...DISCOVERY,
  authorizationEndpoint:
    process.env.EXPO_PUBLIC_AUTH_API_BASE_PATH + '/protocol/openid-connect/registrations',
} as const;

export const STORAGE_KEYS = {
  REFRESH_TOKEN: 'refresh_token',
  ID_TOKEN: 'idToken',
} as const;
