import {Card} from '@/components/cards/Card';
import {Label3} from '@/components/texts/Label';
import {LocalizedDate} from '@/components/texts/LocalizedDate';
import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {useAcademicTitlesQuery} from '@/store/queries/referenceApi';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';
import {useTranslate} from '@tolgee/react';
import {View, YStack, useMedia} from 'tamagui';

export function PersonalDataCard() {
  const {t} = useTranslate();
  const {data: user} = useGetSelfUserQuery();
  const {data: academicTitles} = useAcademicTitlesQuery();
  const {getByCode} = useRefdataLocalizable();
  const media = useMedia();

  return (
    <Card headerText={t('NAVIGATION.PROFILE.PERSONAL_DATA')} gap="$6" padding="$6">
      <View flexDirection={media.sm ? 'column' : 'row'} gap="$4">
        <YStack gap="$3" minWidth={300}>
          <Label3 color="$neutral500">{t('PERSONAL_DETAILS.FULL_NAME')}</Label3>
          <Label3 variant="medium">
            {user?.academicTitle ? getByCode(academicTitles, user?.academicTitle)?.label : ''}
            {user?.firstName} {user?.lastName}
          </Label3>
        </YStack>
        <YStack gap="$3">
          <Label3 color="$neutral500">{t('PERSONAL_DETAILS.DATE_OF_BIRTH')}</Label3>
          {user?.dateOfBirth && (
            <LocalizedDate
              date={user?.dateOfBirth}
              textComponent={Label3}
              variant="medium"
              color="$text"
            />
          )}
        </YStack>
      </View>
    </Card>
  );
}
