import {Stack} from 'expo-router';
import {Platform} from 'react-native';
import {YStack} from 'tamagui';

import {ResponsiveScrollView} from '@/components/views/ResponsiveScrollView';
import {useCustomerIdParamOrRedirect} from '@/hooks/useCustomerIdParamOrRedirect';
import {InternalTransferOrderSchemaType} from '@/hooks/useValidations';
import {WebFooter} from '@/modules/navigation/components/footer/WebFooter';
import {Header} from '@/modules/navigation/components/header/Header';
import {InternalAssetsTransferForm} from '@/modules/orders/components/InternalTransfer/InternalAssetsTransferForm';
import {OrderSummaryModal} from '@/modules/orders/components/OrderSummaryModal';
import {ModalRef} from '@/types/modal';
import {useTranslate} from '@tolgee/react';
import {useCallback, useRef, useState} from 'react';

export default function InternalAssetsTransfer() {
  const customerId = useCustomerIdParamOrRedirect();
  const {t} = useTranslate();
  const modalRef = useRef<ModalRef>(null);
  const [orderData, setOrderData] = useState<InternalTransferOrderSchemaType>();

  const goToSummary = useCallback(
    (data: InternalTransferOrderSchemaType) => {
      setOrderData({...data});
      modalRef.current?.open(true);
    },
    [setOrderData]
  );

  return (
    <ResponsiveScrollView>
      <YStack gap="$5">
        <Stack.Screen
          options={{
            headerShown: true,
            header: () => (
              <Header
                title={t('ORDER.INTERNAL_ASSETS_TRANSFER.TITLE')}
                backButton={Platform.OS !== 'web'}
              />
            ),
          }}
        />
        <InternalAssetsTransferForm customerId={customerId} onSubmit={goToSummary} />
      </YStack>
      <OrderSummaryModal
        ref={modalRef}
        customerId={customerId}
        orderType="INTERNAL_TRANSFER"
        value={orderData?.amount}
        targetCustomerId={orderData?.targetCustomerId}
      />
      <WebFooter />
    </ResponsiveScrollView>
  );
}
