import {Button} from '@/components/buttons/Button';
import {Card} from '@/components/cards/Card';
import {ReferenceAccountInformation} from '@/components/cards/ReferenceAccountInformation';
import {BankInfoForm} from '@/components/forms/BankInfoForm';
import {NonEditableInput} from '@/components/inputs/NonEditableInput';
import {SelectInput} from '@/components/inputs/select-input/SelectInput';
import {Heading6} from '@/components/texts/Heading';
import {Label3} from '@/components/texts/Label';
import {Skeleton} from '@/components/views/Skeleton';
import {BankInfoSchemaType} from '@/hooks/useValidations';
import {SubmitFormRef} from '@/modules/interview/components/FinancialSituationForm';
import {MFARequiredNote} from '@/modules/mfa/components/MFARequiredNote';
import {useMfaAvailability} from '@/modules/mfa/hooks/useMfaAvailability';
import {OrderSummaryModal} from '@/modules/orders/components/OrderSummaryModal';
import {WarningBox} from '@/modules/orders/components/WarningBox';
import {useGetSpecificOrderConstraint} from '@/modules/orders/hooks/useGetSpecificOrderConstraint';
import {useGetCustomerAccountQuery} from '@/store/queries/customersApi';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';
import {ModalRef} from '@/types/modal';
import {useTranslate} from '@tolgee/react';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {XStack, YStack} from 'tamagui';

type ReferenceAccountChangeFormProps = {
  customerId: string;
};

export function ReferenceAccountChangeForm({customerId}: ReferenceAccountChangeFormProps) {
  const {t} = useTranslate();
  const {data: customer, isFetching} = useGetCustomerAccountQuery(customerId);
  const {data: user} = useGetSelfUserQuery();
  const ref = useRef<SubmitFormRef>(null);
  const [name, setName] = useState<string | undefined>(undefined);
  const {isMFAUsable} = useMfaAvailability();
  const [summaryData, setSummaryData] = useState<{
    iban: string;
    bic?: string;
    bank?: string;
    holderFullName: string;
  }>();
  const modalRef = useRef<ModalRef>(null);

  const onPress = useCallback(() => {
    ref.current?.submit();
  }, []);

  const accountHoldersNames = useMemo(() => {
    const result: any[] = [];
    result.push({
      name: `${customer?.firstName} ${customer?.lastName}`,
      key: customer?.bankAccount.holderFullName,
    });
    if (customer?.product === 'CHILD_ACCOUNT') {
      result.push({
        name: user?.firstName + ' ' + user?.lastName,
        key: user?.firstName + ' ' + user?.lastName,
      });
    }
    return result;
  }, [
    customer?.bankAccount.holderFullName,
    customer?.firstName,
    customer?.lastName,
    customer?.product,
    user?.firstName,
    user?.lastName,
  ]);

  const onValidSubmit = useCallback(
    async (data: BankInfoSchemaType) => {
      setSummaryData({
        iban: data.iban,
        bic: data.bic,
        bank: data.bank,
        holderFullName: name ?? accountHoldersNames[0].name,
      });
    },
    [accountHoldersNames, name]
  );

  const {constraint: {canBeCreated, firstViolationLabel} = {}, loading} =
    useGetSpecificOrderConstraint(customerId, 'REFERENCE_ACCOUNT_CHANGE');

  useEffect(() => {
    if (summaryData !== undefined) {
      modalRef.current?.open(true);
    }
  }, [summaryData]);

  useEffect(() => {
    if (accountHoldersNames && accountHoldersNames.length > 1) setName(accountHoldersNames[0].key);
  }, [accountHoldersNames]);

  return (
    <Card gap="$4" padding="$4">
      <YStack gap="$6">
        <MFARequiredNote />
        <ReferenceAccountInformation customerId={customerId} variant="common" />
        <Heading6 variant="medium">{t('ORDER.REFERENCE-ACCOUNT-CHANGE.NEW')}</Heading6>
        <XStack>
          {accountHoldersNames.length === 1 && (
            <Skeleton width={250}>
              {!isFetching && (
                <NonEditableInput
                  label={t('ORDER.REFERENCE-ACCOUNT-CHANGE.ACCOUNT-HOLDER')}
                  text={accountHoldersNames[0].name}
                  bordered
                />
              )}
            </Skeleton>
          )}
          {accountHoldersNames.length > 1 && (
            <YStack gap="$2" flex={1}>
              <Label3 color="$neutral500">
                {t('ORDER.REFERENCE-ACCOUNT-CHANGE.ACCOUNT-HOLDER-LEGAL-OR-CHILD')}
              </Label3>
              <SelectInput
                items={accountHoldersNames}
                placeholder=""
                selectedItem={name ?? ''}
                onSelect={setName}
                wrapperProps={{
                  bordered: true,
                }}
              />
            </YStack>
          )}
        </XStack>
        <BankInfoForm bordered ref={ref} onValid={onValidSubmit} />
        <WarningBox show={!loading && !canBeCreated} text={firstViolationLabel} />
        <Button
          onPress={onPress}
          disabled={!canBeCreated || (!isMFAUsable && t('SNACKBAR.MFA.REQUIRED'))}
        >
          {t('CONTINUE')}
        </Button>
      </YStack>
      {summaryData && (
        <OrderSummaryModal
          ref={modalRef}
          customerId={customerId}
          orderType="REFERENCE_ACCOUNT_CHANGE"
          iban={summaryData.iban}
          bic={summaryData.bic}
          bank={summaryData.bank}
          holderFullName={summaryData.holderFullName}
          value={0} // FIXME: we can remove this later if we fix type in OrderSummaryModal
        />
      )}
    </Card>
  );
}
