import {useTranslate} from '@tolgee/react';
import {Redirect, Stack, useRouter} from 'expo-router';
import {useMemo, useRef, useState} from 'react';
import {Platform} from 'react-native';
import {YStack} from 'tamagui';

import {Button} from '@/components/buttons/Button';
import {Card} from '@/components/cards/Card';
import {ResponsiveScrollView} from '@/components/views/ResponsiveScrollView';
import {useCustomerIdParamOrRedirect} from '@/hooks/useCustomerIdParamOrRedirect';
import {WebFooter} from '@/modules/navigation/components/footer/WebFooter';
import {Header} from '@/modules/navigation/components/header/Header';
import {WarningBox} from '@/modules/orders/components/WarningBox';
import {WithdrawOrderInput} from '@/modules/orders/components/Withdrawal/WithdrawOrderInput';
import WithdrawalWarningModal from '@/modules/orders/components/Withdrawal/WithdrawalWarningModal';
import {useGetSpecificOrderConstraint} from '@/modules/orders/hooks/useGetSpecificOrderConstraint';
import {
  GetValueFromInputType,
  WithDrawalOrderCase,
} from '@/modules/orders/hooks/useGetValueFromInput';
import {useGetCustomerAccountQuery} from '@/store/queries/customersApi';
import {isThemeCustomer} from '@/types/isThemeCustomer';

export default function WithdrawalPage() {
  const {t} = useTranslate();

  const router = useRouter();
  const [error, setError] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [withdrawalCase, setWithdrawalCase] = useState<WithDrawalOrderCase>('NORMAL');

  const customerId = useCustomerIdParamOrRedirect();

  const {data: customer} = useGetCustomerAccountQuery(customerId);

  const ref = useRef<GetValueFromInputType>(null);

  const {constraint: {canBeCreated, firstViolationLabel} = {}, loading} =
    useGetSpecificOrderConstraint(
      customerId,
      withdrawalCase === 'FULL' ? 'FULL_WITHDRAWAL' : 'WITHDRAWAL'
    );
  const withdrawalGuard = useMemo(() => {
    if (!customer || !isThemeCustomer(customer)) return null;
    return (
      <Redirect
        href={{
          pathname: '/overview/[customerId]/order/withdrawal-motive',
          params: {customerId: customer.id},
        }}
      />
    );
  }, [customer]);

  function proceedFurther() {
    router.navigate({
      pathname: `/overview/[customerId]/order/withdrawal-reason`,
      params: {
        customerId,
        value: ref.current?.getValue(),
        orderCase: withdrawalCase,
      },
    });
  }

  function handleAgree(): void {
    proceedFurther();
  }

  function handleCancel(): void {
    //TODO implement if needed
  }

  function onConfirm() {
    if (withdrawalCase === '50to90' || withdrawalCase === 'FULL') {
      setOpenModal(true);
    } else {
      proceedFurther();
    }
  }

  return (
    <ResponsiveScrollView>
      <YStack gap="$5">
        <Stack.Screen
          options={{
            headerShown: true,
            header: () => (
              <Header
                title={t('ORDER_SUMMARY.WITHDRAWAL.TITLE')}
                backButton={Platform.OS !== 'web'}
              />
            ),
          }}
        />
        <Card gap="$3">
          {withdrawalGuard}
          <WithdrawOrderInput
            customerId={customerId}
            onError={setError}
            setCase={setWithdrawalCase}
            ref={ref}
          />
          <WarningBox text={firstViolationLabel} show={!loading && !canBeCreated} />
          <Button disabled={error || !canBeCreated} onPress={onConfirm}>
            {t('ORDER_SUMMARY.SUBMIT-ORDER')}
          </Button>
        </Card>
        <WithdrawalWarningModal
          open={openModal}
          handleAgree={handleAgree}
          handleCancel={handleCancel}
          withdrawalCase={withdrawalCase}
          onClose={() => setOpenModal(false)}
        />
      </YStack>

      <WebFooter />
    </ResponsiveScrollView>
  );
}
