import {IconButton} from '@/components/buttons/IconButton';
import {useTranslate} from '@tolgee/react';
import {toast} from 'burnt';
import {setStringAsync} from 'expo-clipboard';
import {PropsWithChildren} from 'react';
import {XStack} from 'tamagui';

export function CopyButton({text, children}: PropsWithChildren<{text: string | undefined}>) {
  const {t} = useTranslate();
  if (text === undefined) return null;
  return (
    <XStack alignSelf="flex-end" justifyContent="flex-end" gap="$1" flex={1}>
      {children}
      <IconButton
        name="copy"
        size={16}
        color="$primary"
        onPress={async () => {
          await setStringAsync(text).catch();
          toast({
            preset: 'done',
            title: t('TEXT_COPIED'),
          });
        }}
        containerStyle={{bottom: 3}}
      />
    </XStack>
  );
}
