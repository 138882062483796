import {CopyButton} from '@/components/buttons/CopyButton';
import {Label3} from '@/components/texts/Label';
import {spaceIban} from '@/helpers/stringProcessors';
import {useGetCustomerAccountQuery} from '@/store/queries/customersApi';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';
import {useTranslate} from '@tolgee/react';
import {XStack, YStack} from 'tamagui';

export function SecurityTransferTaxBlock({customerId}: {customerId: string}) {
  const {t} = useTranslate();
  const {data: user} = useGetSelfUserQuery();
  const {data: customer} = useGetCustomerAccountQuery(customerId);
  return (
    <YStack gap="$4">
      <XStack gap="$4" justifyContent="space-between">
        <Label3 color="$neutral500">{t('ORDERS.DEPOSIT_METHOD.TRANSFER.TAX_ID')}</Label3>
        <CopyButton text={user?.taxIdentification?.[0]?.number}>
          <Label3 variant="medium">{user?.taxIdentification?.[0]?.number}</Label3>
        </CopyButton>
      </XStack>
      <XStack gap="$4" justifyContent="space-between">
        <Label3 color="$neutral500">{t('ORDERS.DEPOSIT_METHOD.TRANSFER.SECURITY_NUMBER')}</Label3>
        <CopyButton text={customer?.securitiesAccountNumber}>
          <Label3 variant="medium">{customer?.securitiesAccountNumber}</Label3>
        </CopyButton>
      </XStack>
      <XStack gap="$4" justifyContent="space-between">
        <Label3 color="$neutral500">{t('ACCOUNT_DETAILS.IBAN')}</Label3>
        <CopyButton text={customer?.clearingAccount?.iban}>
          <Label3 variant="medium">
            {customer?.clearingAccount?.iban && spaceIban(customer?.clearingAccount?.iban)}
          </Label3>
        </CopyButton>
      </XStack>
    </YStack>
  );
}
