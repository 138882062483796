import {skipToken} from '@reduxjs/toolkit/query';
import {ComponentProps, useMemo, useState} from 'react';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {useMedia} from 'tamagui';

import {Card} from '@/components/cards/Card';
import {PrimaryHeader} from '@/components/views/PrimaryHeader';
import {HomeChart} from '@/modules/overview/components/home-chart/HomeChart';
import {useGetPerformanceSummariesQuery} from '@/store/queries/apeironApi';
import {useGetCustomerAccountsQuery} from '@/store/queries/customersApi';
import {Point} from '@/types/skia';

export function HomeHeader() {
  const media = useMedia();
  const [filteredPoints, setFilteredPoints] = useState<Point[]>();
  const {top} = useSafeAreaInsets();

  const {data: customers, isFetching: customersFetching} = useGetCustomerAccountsQuery();

  const customerIds = useMemo(() => {
    if (!customers) return;

    return customers.items.map(customer => customer.id);
  }, [customers]);

  const {data: performanceSummary, isFetching: isPerformanceSummaryFetching} =
    useGetPerformanceSummariesQuery(
      customerIds && customerIds.length > 0 ? {customerIds, attachHistory: true} : skipToken
    );

  const HeaderComponent = media.sm ? PrimaryHeader : Card;
  const headerComponentProps: ComponentProps<typeof HeaderComponent> = media.sm
    ? {containerStyle: {paddingTop: top}, roundEdges: true}
    : {paddingHorizontal: 0, marginBottom: '$3'};

  return (
    <HeaderComponent {...headerComponentProps}>
      <HomeChart
        filteredPoints={filteredPoints}
        balance={performanceSummary?.[0]?.balance || 0}
        performance={performanceSummary?.[0]?.performance?.amount || 0}
        performanceSummary={performanceSummary}
        setFilteredPoints={setFilteredPoints}
        isLoading={customersFetching || isPerformanceSummaryFetching}
      />
    </HeaderComponent>
  );
}
