import {Heading4, Heading5} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {EmployerStartDateSchemaType} from '@/hooks/useValidations';
import {SubmitFormRef} from '@/modules/interview/components/FinancialSituationForm';
import {InterviewContinueButton} from '@/modules/interview/components/InterviewContinueButton';
import {EmployerStartDateForm} from '@/modules/onboarding/components/forms/EmployerStartDateForm';
import {OnboardingHintModal} from '@/modules/onboarding/components/OnboardingHintModal';
import {useAppDispatch} from '@/store';
import {saveEmployer} from '@/store/reducers/onboarding';
import {useTranslate} from '@tolgee/react';
import {Href, useRouter} from 'expo-router';
import {useCallback, useRef} from 'react';
import {YStack} from 'tamagui';

export function EmployerStartDate({href}: {href: Href}) {
  const ref = useRef<SubmitFormRef>(null);
  const {t} = useTranslate();
  const router = useRouter();
  const dispatch = useAppDispatch();

  const onPress = useCallback(() => {
    ref.current?.submit();
  }, []);

  const onValidSubmit = useCallback(
    async (data: EmployerStartDateSchemaType) => {
      dispatch(
        saveEmployer({
          savingsStartDate: `${data.startDateYear}-${data.startDateMonth.padStart(2, '0')}-01`,
        })
      );
      router.navigate(href);
    },
    [dispatch, href, router]
  );

  return (
    <YStack gap="$6" marginTop="$6">
      <Heading4 variant="medium">{t('PERSONAL_DETAILS.WHEN_DO_YOU_WANT_TO_START')}</Heading4>

      <EmployerStartDateForm ref={ref} onValid={onValidSubmit} />

      <OnboardingHintModal label={t('PERSONAL_DETAILS.VL_START_DATE.HINT')}>
        <YStack gap="$4">
          <Heading5 variant="medium">{t('PERSONAL_DETAILS.VL_START_DATE.TITLE')}</Heading5>
          <Paragraph2 color="$neutral400">{t('PERSONAL_DETAILS.VL_START_DATE.LINE_1')}</Paragraph2>
          <Paragraph2 color="$neutral400">{t('PERSONAL_DETAILS.VL_START_DATE.LINE_2')}</Paragraph2>
          <Paragraph2 color="$neutral400">{t('PERSONAL_DETAILS.VL_START_DATE.LINE_3')}</Paragraph2>
        </YStack>
      </OnboardingHintModal>

      <InterviewContinueButton onPress={onPress} />
    </YStack>
  );
}
