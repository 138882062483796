import {Toaster} from 'burnt/web';
import {XStack} from 'tamagui';

import {Slot} from 'expo-router';

export const MainStack = () => {
  return (
    <XStack justifyContent="center" flex={1} backgroundColor="$neutralBG">
      <Slot />
      <Toaster position="bottom-right" />
    </XStack>
  );
};
