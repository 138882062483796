import {Card} from '@/components/cards/Card';
import {Label3} from '@/components/texts/Label';
import {AnimatedHeightView} from '@/components/views/AnimatedHeightView';
import {AddThemeModal} from '@/modules/orders/components/Theme/AddThemeModal';
import {ThemeItemDeposit} from '@/modules/orders/components/Theme/ThemeItemDeposit';
import {CustomerAllocationTheme} from '@/modules/orders/types/CustomerAllocationTheme';
import {ModalRef} from '@/types/modal';
import {useTranslate} from '@tolgee/react';
import React, {useCallback, useEffect, useRef} from 'react';
import {AnimatePresence, XStack, YStack} from 'tamagui';

type Props = {
  themes: CustomerAllocationTheme[];
  initialThemes?: CustomerAllocationTheme[];
  loading?: boolean;
  onThemesSelected: (selectedThemes: CustomerAllocationTheme[]) => void;
  onThemeRemoved: (code: string) => void;
  onEqualize: (themes?: CustomerAllocationTheme[]) => void;
  onThemeChanged: (code: string, ratio: number) => void;
  sum: number;
};

export const ThemesDepositCard = ({
  themes,
  initialThemes,
  loading = false,
  onThemesSelected,
  onThemeRemoved,
  onEqualize,
  sum,
  onThemeChanged,
}: Props) => {
  const {t} = useTranslate();
  const modalRef = useRef<ModalRef>(null);

  useEffect(() => {
    if (initialThemes?.length === 0) {
      modalRef.current?.open(true);
    }
  }, [initialThemes]);

  const onThemesSelectedIntercepted = useCallback(
    (themes: CustomerAllocationTheme[]) => {
      modalRef?.current?.open(false);
      onThemesSelected(themes);
    },
    [onThemesSelected]
  );

  return (
    <>
      <Card gap="$4">
        <XStack justifyContent="space-between">
          <Label3
            paddingHorizontal="$2"
            color={sum === 100 ? '$neutral500' : '$red'}
            variant={sum !== 100 ? 'medium' : undefined}
          >
            {t('ORDER_SUMMARY.THEME_DEPOSIT.WEIGHTING', '', {value: Math.round(sum)})}
          </Label3>
          <Label3
            onPress={() => {
              onEqualize();
            }}
            color="$primary"
            textAlign="right"
            variant="medium"
            cursor="pointer"
          >
            {t('ORDER_SUMMARY.THEME_DEPOSIT.EQUALIZE')}
          </Label3>
        </XStack>
        <AnimatePresence>
          <AnimatedHeightView>
            <YStack gap="$5">
              {themes.map(({code, name, amount, ratio}) => {
                return (
                  <ThemeItemDeposit
                    onThemeChanged={onThemeChanged}
                    key={code}
                    code={code}
                    name={name}
                    amount={amount}
                    percentage={ratio}
                    sum={sum}
                    onThemeRemoved={
                      initialThemes?.some(_ => {
                        return _.code === code;
                      })
                        ? undefined
                        : onThemeRemoved
                    }
                  />
                );
              })}
              <AddThemeModal
                ref={modalRef}
                onThemesSelected={onThemesSelectedIntercepted}
                existingThemes={themes}
                withoutTitle
              />
            </YStack>
          </AnimatedHeightView>
        </AnimatePresence>
      </Card>
    </>
  );
};
