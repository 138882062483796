import {Button} from '@/components/buttons/Button';
import {ReferenceAccountInformation} from '@/components/cards/ReferenceAccountInformation';
import {Label3} from '@/components/texts/Label';
import {MFAModal} from '@/modules/mfa/components/MFAModal';
import {useMfaAvailability} from '@/modules/mfa/hooks/useMfaAvailability';
import {
  useGetCustomerAccountQuery,
  usePostCustomerOrderMutation,
} from '@/store/queries/customersApi';
import {MFAActionType} from '@/types/mfaAction';
import {useTranslate} from '@tolgee/react';
import {toast} from 'burnt';
import {Redirect, router} from 'expo-router';
import {useCallback, useState} from 'react';
import {YStack} from 'tamagui';

type ReferenceAccountChangeSummaryCardProps = {
  customerId: string;
  iban: string;
  bic: string;
  bank: string;
  holderFullName: string;
  onFinished?: () => void;
};

export function ReferenceAccountChangeSummaryCard({
  customerId,
  iban,
  bic,
  bank,
  holderFullName,
  onFinished,
}: ReferenceAccountChangeSummaryCardProps) {
  const {t} = useTranslate();
  const {data: customer} = useGetCustomerAccountQuery(customerId);
  const [postOrder] = usePostCustomerOrderMutation();
  const {isMFAUsable, isFetching} = useMfaAvailability();
  const [action, setAction] = useState<MFAActionType | null>(null);

  const submitOrder = useCallback(async () => {
    const callback = async () => {
      if (customer && iban !== '' && bic !== '') {
        const res = await postOrder({
          orderType: 'REFERENCE_ACCOUNT_CHANGE',
          customerId,
          iban: iban.replaceAll(' ', '').toUpperCase(),
          bic,
          bank,
          holderFullName,
        });
        if (res.error) {
          toast({
            preset: 'error',
            title: t('SNACKBAR.ORDER-NOT-CREATED'),
          });
        } else {
          router.dismissTo({pathname: '/overview/[customerId]', params: {customerId}});
          toast({
            preset: 'done',
            title: t('SNACKBAR.REFERENCE-ACCOUNT-CHANGE-CREATED'),
          });
          onFinished?.();
        }
      }
    };
    setAction({
      name: 'one-time-code',
      onSuccess: callback,
    });
    // TODO: handle case where MFA is not set up
  }, [customer, iban, bic, postOrder, customerId, bank, holderFullName, t, onFinished]);

  // TODO: summary FRONTEND-335
  if (!isFetching && !isMFAUsable) return <Redirect href={{pathname: '/profile/security'}} />;

  return (
    <YStack gap="$4">
      <ReferenceAccountInformation customerId={customerId} variant="current" column />
      <ReferenceAccountInformation customerId={customerId} variant="new" column>
        <Label3 textAlign="right">{holderFullName}</Label3>
        <Label3 textAlign="right">{iban}</Label3>
        <Label3 textAlign="right">{bic}</Label3>
        <Label3 textAlign="right">{bank}</Label3>
      </ReferenceAccountInformation>
      <Button alignSelf="stretch" onPress={submitOrder}>
        {t('ORDER_SUMMARY.CONFIRM')}
      </Button>
      <MFAModal action={action} />
    </YStack>
  );
}
