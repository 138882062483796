import {useInterval} from '@/hooks/useInterval';
import React, {PropsWithChildren, useState} from 'react';
import {AnimatePresence, View} from 'tamagui';

type ComponentAnimatorProps = {
  duration?: number;
  leftAlign?: boolean;
};

export function ComponentAnimator({
  children,
  duration = 2000,
  leftAlign,
}: PropsWithChildren<ComponentAnimatorProps>) {
  const [curr, setCurr] = useState(0);

  const styleProps = leftAlign ? {left: 0} : {right: 0};

  useInterval(
    () => {
      setCurr(curr => (curr + 1) % React.Children.count(children));
    },
    {delay: duration}
  );

  const mappedChildren = React.Children.map(children, (child, index) => {
    if (index === curr)
      return (
        <View
          key={`animator-child-${index}`}
          animation="fast"
          enterStyle={{opacity: 0, x: 20}}
          exitStyle={{opacity: 0, x: -20}}
          position="absolute"
          {...styleProps}
        >
          {child}
        </View>
      );
  });

  return <AnimatePresence>{mappedChildren}</AnimatePresence>;
}
