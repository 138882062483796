import {Card} from '@/components/cards/Card';
import {LabelCard} from '@/components/cards/LabelCard';
import {Tabbar} from '@/modules/navigation/components/tabbar/Tabbar';
import {AllocationBarChart} from '@/modules/portfolio/components/AllocationBarChart';
import {useGetPerformanceSummaryQuery} from '@/store/queries/apeironApi';
import {useGetCustomerAccountQuery} from '@/store/queries/customersApi';
import {useTranslate} from '@tolgee/react';
import React, {useMemo} from 'react';
import {useMedia} from 'tamagui';

type AllocationPreviewCardProps = {
  customerId: string;
};

export function AllocationPreviewCard({customerId}: AllocationPreviewCardProps) {
  const {data: customer} = useGetCustomerAccountQuery(customerId);
  const {data: performanceSummary, isFetching} = useGetPerformanceSummaryQuery({customerId});
  const {t} = useTranslate();
  const media = useMedia();
  const elements = useMemo(() => {
    const tmp = [
      {
        label: t('PORTFOLIO.ASSET-CLASSES'),
      },
      {
        label: t('PORTFOLIO.INDUSTRIES-2'),
      },
      {
        label: t('PORTFOLIO.REGIONS'),
      },
    ];

    if (customer?.product === 'THEME_ACCOUNT') {
      tmp.push({
        label: t('PORTFOLIO.THEMES'),
      });
    }
    return tmp;
  }, [customer?.product, t]);

  if (
    !isFetching &&
    (performanceSummary === undefined || performanceSummary.allocation?.length === 0)
  )
    return null; // TODO: add empty numbers for fresh accounts

  if (media.sm) {
    return (
      <LabelCard
        gap="$5"
        label={t('OVERVIEW.PORTFOLIO-ALLOCATION')}
        labelLink={{
          href: {
            pathname: '/overview/[customerId]/allocation',
            params: {customerId},
          },
          label: t('SHOW_MORE'),
        }}
      >
        <AllocationBarChart
          groupType={customer?.product === 'THEME_ACCOUNT' ? 'trends' : 'assets'}
          preview
          customerId={customerId}
        />
      </LabelCard>
    );
  } else {
    return (
      <Card>
        <Tabbar elements={elements}>
          <AllocationBarChart groupType="assets" customerId={customerId} />
          <AllocationBarChart groupType="industry" customerId={customerId} />
          <AllocationBarChart groupType="region" customerId={customerId} />
          <AllocationBarChart groupType="trends" customerId={customerId} />
        </Tabbar>
      </Card>
    );
  }
}
