import SvgFile, {SvgName} from '@/components/icons/SvgFile';
import {Paragraph1, Paragraph2} from '@/components/texts/Paragraph';
import React from 'react';
import {XStack, YStack} from 'tamagui';

type RecommendationModalBulletPointProps = {
  iconName: SvgName;
  title: string;
  description: string;
};

export function RecommendationModalBulletPoint({
  iconName,
  title,
  description,
}: RecommendationModalBulletPointProps) {
  return (
    <XStack gap="$4" alignItems="flex-start">
      <SvgFile marginTop="$2" name={iconName} size={18} />
      <YStack flex={1}>
        <Paragraph1 variant="medium">{title}</Paragraph1>
        <Paragraph2 color="$neutral400">{description}</Paragraph2>
      </YStack>
    </XStack>
  );
}
