import {useTranslate} from '@tolgee/react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {XStack, YStack} from 'tamagui';

import {Button} from '@/components/buttons/Button';
import {Card} from '@/components/cards/Card';
import SvgFile from '@/components/icons/SvgFile';
import {Label2, Label3} from '@/components/texts/Label';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {Paragraph3} from '@/components/texts/Paragraph';
import {Skeleton} from '@/components/views/Skeleton';
import {ThemeItemWithdrawal} from '@/modules/orders/components/Theme/ThemeItemWithdrawal';
import {useOrderContext} from '@/modules/orders/hooks/useOrderContext';
import {CustomerAllocationTheme} from '@/modules/orders/types/CustomerAllocationTheme';
import {ThemeCode} from '@/modules/portfolio/types/ThemeCode';
import {useRouter} from 'expo-router';

type ThemeWithdrawalCardProps = {
  customerId: string;
  onValueChange?: (sum: number) => void;
  onError?: (error: boolean) => void;
  themes: CustomerAllocationTheme[] | undefined;
};

export const ThemeWithdrawalCard = ({
  customerId,
  onValueChange,
  onError,
  themes,
}: ThemeWithdrawalCardProps) => {
  const {t} = useTranslate();

  const [errors, setErrors] = useState<ThemeCode[]>([]);
  const router = useRouter();

  const {themeWithdrawalDistribution} = useOrderContext();

  const sum = useMemo(() => {
    if (themes !== undefined) {
      return Object.keys(themeWithdrawalDistribution).reduce((curr, prev) => {
        const themeKey = prev as keyof typeof themeWithdrawalDistribution;
        const val = themeWithdrawalDistribution[themeKey];

        if (val === undefined) return curr;

        return (
          curr +
          (val.amount !== undefined
            ? val.amount
            : themes.find(_ => _.code === themeKey)?.amount ?? 0)
        );
      }, 0);
    }
  }, [themeWithdrawalDistribution, themes]);

  useEffect(() => {
    if (sum !== undefined && onValueChange) onValueChange(sum);
  }, [onValueChange, sum]);

  const handleThemeError = useCallback((code: ThemeCode, error?: string) => {
    setErrors(prev => {
      const newErrors = [...prev];

      if (!error) {
        const index = newErrors.indexOf(code);

        if (index !== -1) {
          newErrors.splice(index, 1);
        }
      } else {
        newErrors.push(code);
      }

      return newErrors;
    });
  }, []);

  useEffect(() => {
    if (!onError) return;

    onError(errors.length > 0);
  }, [onError, errors]);

  const goToMotiveDeposit = () => {
    router.navigate({
      pathname: `/overview/[customerId]/order/deposit`,
      params: {
        customerId,
      },
    });
  };

  if (!themes) return null;

  return (
    <Skeleton width="100%" height={100}>
      <Card gap="$7">
        {themes.map((theme, index) => {
          return (
            <ThemeItemWithdrawal
              key={theme.code}
              code={theme.code}
              amount={theme.amount}
              onError={handleThemeError}
            />
          );
        })}

        <XStack justifyContent="space-between" gap="$2">
          <Label2 variant="medium">{t('THEME_WITHDRAWAL_ORDER.TOTAL')}</Label2>
          <LocalizedNumbers value={sum} numberStyle={{variant: 'medium'}} textComponent={Label2} />
        </XStack>

        {themes.length === 0 && (
          <YStack gap="$6">
            <XStack
              borderRadius="$1"
              padding="$5"
              gap="$2"
              backgroundColor="$neutralBG"
              alignItems="flex-start"
            >
              <SvgFile name="info" size={16} color="$primary" />
              <YStack gap="$2" flexShrink={1}>
                <Label3 variant="medium" color="$primary">
                  {t('THEME_WITHDRAWAL_ORDER.ZERO_ACCOUNT.FULL_TITLE')}
                </Label3>
                <Paragraph3>{t('THEME_WITHDRAWAL_ORDER.ZERO_ACCOUNT.FULL')}</Paragraph3>
              </YStack>
            </XStack>
            <Button onPress={goToMotiveDeposit}>
              {t('THEME_WITHDRAWAL_ORDER.ZERO_ACCOUNT.DEPOSIT')}
            </Button>
          </YStack>
        )}
      </Card>
    </Skeleton>
  );
};
