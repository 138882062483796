import SvgFile from '@/components/icons/SvgFile';
import {Label2, Label3} from '@/components/texts/Label';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {Paragraph1} from '@/components/texts/Paragraph';
import {RatioItem} from '@/modules/portfolio/hooks/useGetAssetAllocation';
import React from 'react';
import {GetProps, View, XStack, useMedia, useWindowDimensions} from 'tamagui';

export function RatioItemRow({
  title,
  color,
  ratio,
  open,
  amount,
  numberStyle,
  textComponent,
  bottomRow,
  wrapperProps,
}: Pick<RatioItem, 'title' | 'color' | 'amount'> & {
  open?: boolean;
  ratio?: number;
  numberStyle?: GetProps<typeof Paragraph1>;
  textComponent?: typeof Label2;
  bottomRow?: boolean;
  wrapperProps?: GetProps<typeof XStack>;
}) {
  const media = useMedia();
  const {width} = useWindowDimensions();
  const Component = textComponent || Label3;

  return (
    <XStack justifyContent="space-between" paddingVertical="$3" {...wrapperProps}>
      <XStack flex={1} alignItems="center" gap="$2">
        {!bottomRow && <SvgFile name="circle" size={16} color={color} />}
        <Component variant="medium" textAlign="left" {...numberStyle}>
          {title}
        </Component>
      </XStack>
      <XStack
        justifyContent={amount !== undefined ? 'space-between' : 'flex-end'}
        alignItems="center"
        flex={1}
      >
        {ratio === undefined ? (
          <View width={75} />
        ) : (
          <LocalizedNumbers
            numberStyle={{width: 75, textAlign: 'right'}}
            variant="percentage"
            value={ratio}
            textComponent={Label3}
          />
        )}
        <XStack alignItems="center" gap="$1" justifyContent="flex-end" flexShrink={1}>
          {amount !== undefined && (
            <LocalizedNumbers
              variant="currency"
              value={amount}
              textComponent={Component}
              numberStyle={{
                width: Math.max(width * 0.2, 75) + (bottomRow ? (media.sm ? 24 : 16) : 0),
                textAlign: 'right',
                ...numberStyle,
              }}
            />
          )}
          {open !== undefined && (
            <SvgFile
              color="$primary"
              name="chevron-down"
              animation="fast"
              rotate={open ? '180deg' : '0deg'}
            />
          )}
        </XStack>
      </XStack>
    </XStack>
  );
}
