import {Badge} from '@/components/buttons/Badge';
import {Label4} from '@/components/texts/Label';
import {Skeleton} from '@/components/views/Skeleton';
import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {useInvestmentStrategiesQuery} from '@/store/queries/referenceApi';
import React, {useMemo} from 'react';
import {GetProps} from 'tamagui';

type BadgeProps = Omit<GetProps<typeof Badge>, 'children' | 'ref'> & {
  strategy: string | undefined;
  onPrimary?: boolean;
};

export const StrategyBadge = ({strategy, ...props}: BadgeProps) => {
  const {data} = useInvestmentStrategiesQuery();
  const {getByCode} = useRefdataLocalizable();
  const green = useMemo(() => {
    if (strategy && strategy.includes('AG')) {
      return true;
    } else {
      return false;
    }
  }, [strategy]);

  return (
    <Skeleton
      width={60}
      radius="round"
      height={22}
      color={props.onPrimary ? 'onPrimary' : undefined}
    >
      {!!strategy && data && (
        <Badge alignSelf="flex-start" bordered {...props}>
          <Label4 color={green ? '$green' : props.onPrimary ? '$white' : '$primary'}>
            {getByCode(data, strategy)?.label}
          </Label4>
        </Badge>
      )}
    </Skeleton>
  );
};
