import {BaseInput} from '@/components/inputs/form/BaseInput';
import {InputErrorMessage} from '@/components/texts/InputErrorMessage';
import {Label3} from '@/components/texts/Label';
import {FormInputProps} from '@/types/FormInputProps';
import {FieldValues, useController} from 'react-hook-form';
import {Label, YStack} from 'tamagui';

type Props<T extends FieldValues> = {
  modal?: boolean;
} & FormInputProps<T>;

export function FormBicInput<T extends FieldValues>({
  control,
  name,
  label,
  bordered,
  textInputProps,
  modal,
}: Props<T>) {
  const {
    field,
    fieldState: {error},
  } = useController({
    name,
    control,
  });

  return (
    <YStack gap="$2" maxWidth={500}>
      <Label htmlFor={name}>
        <Label3 color="$neutral500">{label}</Label3>
      </Label>
      <BaseInput
        ref={field.ref}
        onBlur={field.onBlur}
        value={field.value || ''}
        onChangeText={text => field.onChange(text.replaceAll(' ', '').toUpperCase())}
        id={name}
        bordered={bordered}
        modal={modal}
        testID={name}
        autoCapitalize="characters"
        {...textInputProps}
      />
      <InputErrorMessage error={error?.message} />
    </YStack>
  );
}
