export const calculateRecommendedStrategy = (
  acceptableInvestmentStrategies: string[]
): [string | undefined, string | undefined] => {
  if (acceptableInvestmentStrategies === undefined) return [undefined, undefined];

  const sortedStrategies = [...acceptableInvestmentStrategies].sort(
    (a, b) => Number.parseInt(b.slice(-2), 10) - Number.parseInt(a.slice(-2), 10)
  );

  let global: string | undefined;
  let green: string | undefined;

  for (const strategy of sortedStrategies) {
    if (global && green) break;

    if (strategy.includes('AP') && !global) {
      global = strategy;

      continue;
    }

    if (strategy.includes('AG') && !green) {
      green = strategy;
    }
  }

  return [global, green];
};
