import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {CustomerAllocationTheme} from '@/modules/orders/types/CustomerAllocationTheme';
import {ThemeCode} from '@/modules/portfolio/types/ThemeCode';
import {useGetPerformanceSummaryQuery} from '@/store/queries/apeironApi';
import {useInvestmentThemesQuery} from '@/store/queries/referenceApi';
import {useEffect, useMemo, useState} from 'react';

export function useGetThemesFromAllocation(customerId: string) {
  const {data: performanceSummary} = useGetPerformanceSummaryQuery({customerId});
  const {data: investmentThemes} = useInvestmentThemesQuery();
  const [initialThemes, setInitialThemes] = useState<CustomerAllocationTheme[] | undefined>();
  const {getAll} = useRefdataLocalizable();

  const translatedInvestmentThemes = useMemo(
    () => getAll(investmentThemes),
    [investmentThemes, getAll]
  );

  useEffect(() => {
    if (!translatedInvestmentThemes || !performanceSummary?.allocation) {
      return;
    }

    const themes: CustomerAllocationTheme[] = [];

    for (const allocation of performanceSummary?.allocation) {
      const investmentTheme = translatedInvestmentThemes.find(
        investmentTheme => investmentTheme.security === allocation.security
      );

      if (!investmentTheme) continue;
      themes.push({
        code: investmentTheme.code as ThemeCode,
        name: investmentTheme.label,
        amount: allocation.amount,
        ratio: allocation.ratio,
      });
    }

    setInitialThemes(themes);
  }, [performanceSummary, translatedInvestmentThemes]);
  return initialThemes;
}
