import SvgFile from '@/components/icons/SvgFile';
import {Label3} from '@/components/texts/Label';
import {Paragraph1} from '@/components/texts/Paragraph';
import {THEME_ICON_MAP} from '@/constants/theme-icon-map';
import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {ThemeColorsEnum} from '@/modules/portfolio/constants/enums';
import {ThemeCode} from '@/modules/portfolio/types/ThemeCode';
import {useInvestmentThemesQuery} from '@/store/queries/referenceApi';
import {useMemo} from 'react';
import {GetProps, View, ViewProps, XStack} from 'tamagui';

export const THEME_ICON_SIZE = 38;

export const getColorFromThemeCode = (code: ThemeCode) =>
  ThemeColorsEnum[code] ?? ThemeColorsEnum.DEFAULT;

type Props = {
  code: ThemeCode;
  onPress?: (code: string) => void;
  size?: number;
  containerProps?: ViewProps;
  showLabel?: boolean;
  textProps?: GetProps<typeof Paragraph1>;
};

export function ThemeIcon({code, onPress, size, containerProps, showLabel, textProps}: Props) {
  const color = getColorFromThemeCode(code);
  const {data: investmentThemes} = useInvestmentThemesQuery();
  const {getByCode} = useRefdataLocalizable();

  const Icon = useMemo(() => {
    return (
      <View
        height={size || THEME_ICON_SIZE}
        width={size || THEME_ICON_SIZE}
        backgroundColor={`${color}1A`}
        borderRadius="$0.5"
        justifyContent="center"
        alignItems="center"
        {...containerProps}
      >
        <SvgFile
          name={THEME_ICON_MAP[code]}
          size={(size || THEME_ICON_SIZE) * 0.65}
          color={color}
        />
        {onPress && (
          <View
            position="absolute"
            top={-7}
            right={-7}
            margin={0}
            onPress={() => {
              onPress(code);
            }}
          >
            <View
              backgroundColor="$red"
              padding="$0.5"
              borderRadius="$1"
              cursor="pointer"
              hoverStyle={{opacity: 0.8}}
            >
              <SvgFile name="trash" color="white" size={16} />
            </View>
          </View>
        )}
      </View>
    );
  }, [code, color, containerProps, onPress, size]);

  if (showLabel) {
    return (
      <XStack gap="$2" alignItems="center" flex={1}>
        {Icon}
        <Label3 variant="medium" {...textProps}>
          {getByCode(investmentThemes, code)?.label}
        </Label3>
      </XStack>
    );
  }

  return Icon;
}
