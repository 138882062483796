import {Stack} from 'expo-router';
import {useCallback} from 'react';

import {useScreenOptions} from '@/modules/navigation/hooks/useScreenOptions';

const PersonalDetailsLayout = () => {
  const screenOptions = useScreenOptions({headerShown: false});

  const createId = useCallback(
    (name: string) =>
      ({params}: {params?: Record<string, any>}) => {
        let typeKey = '';

        if (params?.onboardingType === 'second_guardian') {
          typeKey = '-second_guardian';
        }

        return `${name}${typeKey}-${params?.screenType ?? 'common'}`;
      },
    []
  );

  return (
    <Stack screenOptions={screenOptions}>
      <Stack.Screen name="birth" getId={createId('birth')} />
      <Stack.Screen name="address" getId={createId('address')} />
      <Stack.Screen name="tax" getId={createId('tax')} />
      <Stack.Screen name="summary" getId={createId('summary')} />
    </Stack>
  );
};

export default PersonalDetailsLayout;
