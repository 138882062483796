import {Label3} from '@/components/texts/Label';
import React, {forwardRef, MutableRefObject, useEffect, useState} from 'react';

const TIMER_SECONDS = 60;

type Props = {
  timerText: (time?: number) => string;
  initialTime?: number;
  onStartTimer?: () => void;
  onEndTimer?: () => void;
};

export const Timer = forwardRef<NodeJS.Timeout | undefined, Props>(
  ({initialTime, timerText, onStartTimer, onEndTimer}, ref) => {
    const [timer, setTimer] = useState<number>(initialTime ?? TIMER_SECONDS);

    const internalRef = ref as MutableRefObject<NodeJS.Timeout>;

    useEffect(() => {
      if (initialTime === undefined) return;

      setTimer(initialTime);
    }, [initialTime]);

    useEffect(() => {
      if (timer === undefined) return undefined;

      if ((timer === initialTime || timer === TIMER_SECONDS) && onStartTimer) {
        onStartTimer();
      }

      if (timer === 0) {
        if (onEndTimer) {
          onEndTimer();
        }

        return () => {
          clearTimeout(internalRef.current);
        };
      }

      internalRef.current = setTimeout(() => {
        setTimer(prev => prev - 1);
      }, 1000);

      return () => {
        clearTimeout(internalRef.current);
      };
    }, [timer]);

    return <Label3 color="$neutral500">{timerText(timer)}</Label3>;
  }
);
