import {ThemeIcon} from '@/components/icons/ThemeIcon';
import {Modal} from '@/components/modal/Modal';
import {Heading5} from '@/components/texts/Heading';
import {Label3} from '@/components/texts/Label';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {Paragraph1, Paragraph2} from '@/components/texts/Paragraph';
import {WithLabel} from '@/components/texts/WithLabel';
import {LocalizableRefData} from '@/hooks/useRefdataLocalizable';
import {ThemeCode} from '@/modules/portfolio/types/ThemeCode';
import {useAppSelector} from '@/store';
import {InvestmentTheme, Security} from '@/types/api/refdata.v2';
import {useTranslate} from '@tolgee/react';
import React, {PropsWithChildren} from 'react';
import {GetProps, ScrollView, View, XStack, YStack} from 'tamagui';

type ThemeInfoModalProps = {
  theme: LocalizableRefData<InvestmentTheme>;
  security: LocalizableRefData<Security>;
  triggerProps?: GetProps<typeof View>;
};

export function ThemeInfoModal({
  theme,
  security,
  children,
  triggerProps,
}: PropsWithChildren<ThemeInfoModalProps>) {
  const {t} = useTranslate();
  const language = useAppSelector(state => state.app.language);
  return (
    <Modal>
      <Modal.Trigger asChild {...triggerProps}>
        {children}
      </Modal.Trigger>
      <Modal.Content side typeContainer="SCROLL">
        <ScrollView>
          <YStack gap="$5" paddingRight="$2">
            <XStack gap="$3" alignItems="center">
              <ThemeIcon code={theme.code as ThemeCode} />
              <Heading5 variant="medium">{theme.label}</Heading5>
            </XStack>
            <Paragraph2 color="$neutral400">{theme.description[language]}</Paragraph2>
            <Paragraph1 variant="medium">{t('PLANNER.MOTIVE.MODAL.SELECTION_CRITERIA')}</Paragraph1>
            <Paragraph2 color="$neutral400">{theme.selectionCriteria[language]}</Paragraph2>
            <Paragraph1 variant="medium">{t('PLANNER.MOTIVE.MODAL.ADDITIONAL_INFO')}</Paragraph1>
            <WithLabel header={t('PLANNER.MOTIVE.MODAL.NAME_OF_ETF')} horizontal>
              {security.name}
            </WithLabel>
            <WithLabel header={t('PLANNER.MOTIVE.MODAL.ISIN')} horizontal>
              {theme.security}
            </WithLabel>
            <WithLabel header={t('PLANNER.MOTIVE.MODAL.TYPE')} horizontal>
              {theme.type[language]}
            </WithLabel>
            <WithLabel header={t('PLANNER.MOTIVE.MODAL.REPLICATION')} horizontal>
              {theme.replication[language]}
            </WithLabel>
            <WithLabel header={t('PLANNER.MOTIVE.MODAL.APPROPRIATION')} horizontal>
              {theme.appropriationDefinition[language]}
            </WithLabel>
            <WithLabel header={t('PLANNER.MOTIVE.MODAL.TER')} horizontal>
              <LocalizedNumbers
                variant="percentage"
                value={theme.fee}
                textComponent={Label3}
                numberStyle={{variant: 'medium'}}
              />
            </WithLabel>
          </YStack>
        </ScrollView>
      </Modal.Content>
    </Modal>
  );
}
