import {Stack} from 'expo-router';
import {YStack} from 'tamagui';

import {Header} from '@/modules/navigation/components/header/Header';
import {NativeLogoutButton} from '@/modules/navigation/components/header/NativeLogoutButton';
import {OnboardingHeader} from '@/modules/navigation/components/header/OnboardingHeader';
import {useScreenOptions} from '@/modules/navigation/hooks/useScreenOptions';

const InterviewStackLayout = () => {
  const screenOptions = useScreenOptions();

  return (
    <YStack flex={1}>
      <OnboardingHeader />
      <Stack screenOptions={screenOptions} initialRouteName="product-choice">
        <Stack.Screen
          name="question"
          options={{
            header: () => (
              <Header
                style={{
                  '$platform-web': {
                    alignSelf: 'center',
                    maxWidth: 1000,
                  },
                }}
                withSidePanel={false}
              />
            ),
          }}
        />
      </Stack>
      <NativeLogoutButton />
    </YStack>
  );
};

export default InterviewStackLayout;
