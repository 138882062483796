import {Button} from '@/components/buttons/Button';
import SvgFile from '@/components/icons/SvgFile';
import {Modal} from '@/components/modal/Modal';
import {Heading5} from '@/components/texts/Heading';
import {Label1} from '@/components/texts/Label';
import {Paragraph2} from '@/components/texts/Paragraph';
import {start} from '@/modules/overview/helpers/postident';
import {useGetPostidentQuery} from '@/store/queries/identityApi';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';
import {ModalRef} from '@/types/modal';
import {skipToken} from '@reduxjs/toolkit/query';
import {useTranslate} from '@tolgee/react';
import {useRouter} from 'expo-router';
import React, {PropsWithChildren, useCallback, useRef, useState} from 'react';
import {Platform} from 'react-native';
import {View, XStack, YStack} from 'tamagui';

export function IdentityVerificationModal({children}: PropsWithChildren) {
  const {t} = useTranslate();
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const modalRef = useRef<ModalRef>(null);
  const {data: user} = useGetSelfUserQuery();
  const {data: postident, isLoading} = useGetPostidentQuery(
    user?.id && open ? user.id : skipToken,
    {
      pollingInterval: 3000,
    }
  );

  const onStartIdentification = useCallback(() => {
    if (!postident) return;
    if (Platform.OS === 'web') {
      start({url: postident.identificationUrl});
      modalRef.current?.open(false);
      router.navigate({pathname: '/overview', params: {action: 'refreshPostident'}});
    } else {
      start({caseId: postident.caseId});
      modalRef.current?.open(false);
      router.navigate({pathname: '/overview', params: {action: 'refreshPostident'}});
    }
  }, [postident, router]);

  return (
    <Modal ref={modalRef} onOpenChange={o => setOpen(o)}>
      <Modal.Trigger asChild>{children}</Modal.Trigger>
      <Modal.Content>
        <YStack gap="$4" $platform-web={{maxWidth: 450}}>
          <Heading5 variant="medium">
            {t('IDENTITY_VERIFICATION.MODAL.TITLE', {firstName: user?.firstName || ''})}
          </Heading5>
          <Label1 variant="medium">{t('IDENTITY_VERIFICATION.MODAL.SUBTITLE')}</Label1>
          <XStack gap="$4" alignItems="flex-start">
            <View borderRadius="$full" padding="$2" backgroundColor="$neutralBG" marginTop={4}>
              <SvgFile color="$primary" name="verification-post-office" size={20} />
            </View>
            <YStack flex={1}>
              <Label1 color="$primary" variant="medium">
                {t('IDENTITY_VERIFICATION.MODAL.POST_OFFICE.TITLE')}
              </Label1>
              <Paragraph2 color="$neutral400">
                {t('IDENTITY_VERIFICATION.MODAL.POST_OFFICE.DESCRIPTION')}
              </Paragraph2>
            </YStack>
          </XStack>
          <XStack gap="$4" alignItems="flex-start">
            <View borderRadius="$full" padding="$2" backgroundColor="$neutralBG" marginTop={4}>
              <SvgFile color="$primary" name="verification-mobile" size={20} />
            </View>
            <YStack flex={1}>
              <Label1 color="$primary" variant="medium">
                {t('IDENTITY_VERIFICATION.MODAL.POST_VIDEO.TITLE')}
              </Label1>
              <Paragraph2 color="$neutral400">
                {t('IDENTITY_VERIFICATION.MODAL.POST_VIDEO.DESCRIPTION')}
              </Paragraph2>
            </YStack>
          </XStack>
          <Button
            testID="start-identification"
            isLoading={isLoading}
            onPress={onStartIdentification}
            alignSelf="stretch"
          >
            {t('IDENTITY_VERIFICATION.MODAL.BUTTTON')}
          </Button>
        </YStack>
      </Modal.Content>
    </Modal>
  );
}
