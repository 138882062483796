import {FlashList} from '@shopify/flash-list';
import React, {forwardRef, PropsWithChildren, useCallback, useMemo, useRef, useState} from 'react';
import {Dialog, useMedia, useWindowDimensions, View} from 'tamagui';

import {Input} from '@/components/inputs/select-input/Input';
import {Modal} from '@/components/modal/Modal';
import {useSelectInput} from '@/hooks/useSelectInput';
import {SelectInputItem} from '@/types/FormInputProps';
import {ModalRef} from '@/types/modal';
import {SelectInputProps} from '@/types/SelectInput';

const CONTEXT_MENU_WINDOW_PADDING = 16;

export const SelectInput = forwardRef<ModalRef, PropsWithChildren<SelectInputProps>>(
  (
    {
      children,
      items,
      onSelect,
      selectedItem,
      renderItem: renderItemProps,
      additionalContent,
      wrapperProps,
      containerProps,
      cardProps,
      placeholder,
      disabled,
      error,
      minWidth = 250,
    },
    ref
  ) => {
    const [position, setPosition] = useState({left: 0, top: 0, width: 0});
    const modalRef = useRef<ModalRef>(null);

    const handleInputRendered = useCallback((e: any) => {
      setPosition({
        left: e.nativeEvent.layout.left,
        top: e.nativeEvent.layout.top,
        width: e.nativeEvent.layout.width,
      });
    }, []);

    const handleInputPress = useCallback((e: any) => {
      if (modalRef.current) {
        modalRef.current.open(true);
      }
    }, []);

    const inputText = useMemo(
      () => (selectedItem ? items.find(_ => _.key === selectedItem)?.name : placeholder || ''),
      [items, placeholder, selectedItem]
    );

    return (
      <>
        <Input
          onPress={handleInputPress}
          selectedItem={selectedItem}
          text={inputText}
          wrapper={{onLayout: handleInputRendered, ...wrapperProps}}
          disabled={disabled}
          error={error}
          containerProps={containerProps}
        >
          {children}
        </Input>
        <ResponsiveWebSelectInput
          ref={modalRef}
          position={position}
          renderItem={renderItemProps}
          additionalContent={additionalContent}
          selectedItem={selectedItem}
          onSelect={onSelect}
          cardProps={cardProps}
          items={items}
          minWidth={minWidth}
        />
      </>
    );
  }
);

const ResponsiveWebSelectInput = forwardRef(
  (
    {
      position,
      items,
      renderItem: renderItemProps,
      additionalContent,
      selectedItem,
      onSelect,
      cardProps,
      minWidth,
    }: PropsWithChildren<SelectInputProps & {position: {left: number; top: number; width: number}}>,
    ref: any
  ) => {
    const media = useMedia();

    const [listSize, setListSize] = useState({width: 0, height: 0});
    const {width, height} = useWindowDimensions();
    const flashListRef = useRef<FlashList<SelectInputItem>>(null);

    const menuOffset = useMemo(() => {
      const maxLeft = width - listSize.width - 2 * CONTEXT_MENU_WINDOW_PADDING;

      return {
        top:
          position.top +
          Math.min(0, height - position.top - listSize.height - 2 * CONTEXT_MENU_WINDOW_PADDING),
        left: Math.min(maxLeft, position.left),
      };
    }, [position, listSize, width, height]);

    const onCardLayout = useCallback((e: any) => {
      setListSize({
        width: e.nativeEvent.layout.width,
        height: Math.min(e.nativeEvent.layout.height, 500),
      });
    }, []);

    const handleFlashListOnLayout = useCallback(() => {
      if (!flashListRef.current) return;

      const selectedIndex = items.findIndex(item => item.key === selectedItem);

      if (selectedIndex === -1) return;

      flashListRef.current.scrollToIndex({index: selectedIndex});
    }, [items, selectedItem]);

    const handleSelect = useCallback(
      (item: SelectInputItem) => {
        onSelect(item.key);

        if (ref.current) {
          ref.current.open(false);
        }
      },
      [onSelect, ref]
    );

    const {renderItem} = useSelectInput(selectedItem, renderItemProps, handleSelect);

    const content = useMemo(() => {
      return (
        <View width="100%" height={listSize.height} overflowY="auto">
          <View onLayout={onCardLayout} {...cardProps}>
            {additionalContent}
            <FlashList
              ref={flashListRef}
              renderItem={renderItem}
              data={items}
              estimatedItemSize={20}
              keyExtractor={({key}) => key}
              scrollEventThrottle={4}
              onLayout={handleFlashListOnLayout}
              contentContainerStyle={{paddingRight: 16}}
            />
          </View>
        </View>
      );
    }, [
      additionalContent,
      cardProps,
      handleFlashListOnLayout,
      items,
      listSize.height,
      onCardLayout,
      renderItem,
    ]);

    if (media.sm) {
      return (
        <Modal ref={ref}>
          <Modal.Content $gtSm={{minWidth: 500}}>{content}</Modal.Content>
        </Modal>
      );
    } else {
      return (
        <Dialog ref={ref} modal>
          <Dialog.Portal>
            <Dialog.Content
              key="modal-content"
              backgroundColor="$surface"
              borderRadius="$0.5"
              borderWidth={0}
              shadowColor="$shadow"
              shadowOffset={{width: 0, height: 4}}
              shadowOpacity={0.4}
              shadowRadius="$2"
              animateOnly={['opacity']}
              animation="slow"
              enterStyle={{opacity: 0}}
              exitStyle={{opacity: 0}}
              maxWidth={900}
              position="absolute"
              paddingLeft="$4"
              paddingRight={0}
              paddingVertical="$2"
              margin={0}
              left={menuOffset.left}
              top={menuOffset.top}
              width={position.width}
              minWidth={minWidth}
              overflow="hidden"
            >
              {content}
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog>
      );
    }
  }
);
