import {useFonts} from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';

import {MainStack} from '@/modules/app/components/MainStack';
import {CustomThemeProvider} from '@/providers/CustomThemeProvider';
import {ExpoDevMenuProvider} from '@/providers/ExpoDevMenuProvider';
import {GestureHandlerProvider} from '@/providers/GestureHandlerProvider';
import {ModalProvider} from '@/providers/ModalProvider';
import {NavigationDevTools} from '@/providers/NavigationDevTools';
import {NavigationThemeProvider} from '@/providers/NavigationThemeProvider';
import SentryProvider from '@/providers/SentryProvider';
import {StoreProvider} from '@/providers/StoreProvider';
import {NotificationProvider} from '@/providers/notification-provider/NotificationProvider';
import {CustomPostHogProvider} from '@/providers/posthog/CustomPostHogProvider';
import {SessionProvider} from '@/providers/session-provider/SessionProvider';
import {SplashScreenProvider} from '@/providers/splash-screen-provider/SplashScreenProvider';
import {TolgeeTranslationProvider} from '@/providers/tolgee-translation-provider/TolgeeTranslationProvider';
import {KeyboardProvider} from 'react-native-keyboard-controller';

export {
  // Catch any errors thrown by the Layout component.
  ErrorBoundary,
} from 'expo-router';

// export const unstable_settings = {
//   // Ensure that reloading on `/modal` keeps a back button present.
//   initialRouteName: '(visitor)/login',
// };

// Prevent the splash screen from auto-hiding before asset loading is complete.
SplashScreen.preventAutoHideAsync();

export default function RootLayout() {
  // TODO: move to a provider
  const [loaded] = useFonts({
    InterRegular: require('@tamagui/font-inter/otf/Inter-Regular.otf'),
    InterMedium: require('@tamagui/font-inter/otf/Inter-Medium.otf'),
    InterBold: require('@tamagui/font-inter/otf/Inter-Bold.otf'),
  });

  if (!loaded) {
    return null;
  }

  return (
    <SentryProvider>
      <NavigationDevTools>
        <StoreProvider>
          <TolgeeTranslationProvider>
            <SessionProvider>
              <NotificationProvider>
                <GestureHandlerProvider>
                  <CustomThemeProvider>
                    <CustomPostHogProvider>
                      <ModalProvider>
                        <NavigationThemeProvider>
                          <KeyboardProvider>
                            <SplashScreenProvider>
                              <MainStack />
                              <ExpoDevMenuProvider />
                            </SplashScreenProvider>
                          </KeyboardProvider>
                        </NavigationThemeProvider>
                      </ModalProvider>
                    </CustomPostHogProvider>
                  </CustomThemeProvider>
                </GestureHandlerProvider>
              </NotificationProvider>
            </SessionProvider>
          </TolgeeTranslationProvider>
        </StoreProvider>
      </NavigationDevTools>
    </SentryProvider>
  );
}
