import {forwardRef, PropsWithChildren} from 'react';
import {LayoutChangeEvent} from 'react-native';
import {ScrollView} from 'tamagui';

import {Variant} from '@/modules/navigation/types/Tabbar';

type Props = {
  variant: Variant;
  onLayout: (event: LayoutChangeEvent) => void;
};

export const ScrolledTabbar = forwardRef<ScrollView, PropsWithChildren<Props>>(
  ({children, variant, onLayout}, ref) => {
    return (
      <ScrollView
        ref={ref}
        onLayout={onLayout}
        backgroundColor={variant === 'onSurface' ? '$surfaceRaised' : '$surface'}
        borderRadius="$0.5"
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{padding: '$2', gap: '$2'}}
      >
        {children}
      </ScrollView>
    );
  }
);
