import {FormCheckboxGroupInput} from '@/components/inputs/form/FormCheckboxGroupInput';
import {Heading4} from '@/components/texts/Heading';
import {InputErrorMessage} from '@/components/texts/InputErrorMessage';
import {Label2} from '@/components/texts/Label';
import {Skeleton} from '@/components/views/Skeleton';
import {useValidations} from '@/hooks/useValidations';
import {InterviewContinueButton} from '@/modules/interview/components/InterviewContinueButton';
import useExistingStoreAnswers from '@/modules/interview/hooks/useExistingStoreAnwers';
import {useGetQuestionWithAnswers} from '@/modules/interview/hooks/useGetQuestionWithAnswers';
import {useHandleNoExperienceOption} from '@/modules/interview/hooks/useHandleNoExperienceOption';
import useProductSearchParam from '@/modules/interview/hooks/useProductSearchParam';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useAppDispatch} from '@/store';
import {updateInterview} from '@/store/reducers/interview';
import {yupResolver} from '@hookform/resolvers/yup';
import {Href, useRouter} from 'expo-router';
import React from 'react';
import {useForm} from 'react-hook-form';
import {YStack} from 'tamagui';

type MultiAnswerProps = {
  questionCode: string;
  href: Href;
  params?: Record<string, any>;
};

export function MultiAnswer({questionCode, href, params}: MultiAnswerProps) {
  const defaultValues = useExistingStoreAnswers(questionCode);
  const {question, answers} = useGetQuestionWithAnswers(questionCode);
  const {interviewMultiAnswerSchema} = useValidations();
  const dispatch = useAppDispatch();
  const posthog = usePostHog();
  const product = useProductSearchParam();
  const {
    control,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(interviewMultiAnswerSchema(questionCode)),
    defaultValues,
  });
  const router = useRouter();
  useHandleNoExperienceOption(questionCode, setValue, control);
  const hrefNoExperience: Href = {
    pathname: '/interview/question/no-exp',
    params: params ? {...params, product} : {product},
  };

  const onSubmit = handleSubmit(
    value => {
      const res = value[questionCode];
      if (!res) return;
      dispatch(updateInterview({[questionCode]: res}));
      if (res.length === 1 && res.findIndex((_: string) => _ === 'ASSET_CLASS_NONE') === 0) {
        router.navigate(hrefNoExperience);
      } else {
        router.navigate(href);
      }
    },
    data => {
      posthog?.capture('form_submit_failed', data);
    }
  );

  return (
    <YStack gap="$4">
      <YStack gap="$2">
        {question ? (
          <>
            <Heading4 variant="medium">{question.label}</Heading4>
            {question.description && <Label2 color="$neutral500">{question.description}</Label2>}
          </>
        ) : (
          <>
            <Skeleton width="100%" height={50} />
            <Skeleton width="75%" height={20} />
          </>
        )}
      </YStack>
      <FormCheckboxGroupInput
        variant="checkbox"
        control={control}
        name={questionCode}
        items={answers}
      />
      <InputErrorMessage error={errors[questionCode]?.message} />
      <InterviewContinueButton onPress={onSubmit} />
    </YStack>
  );
}
