import {WebFooter} from '@/modules/navigation/components/footer/WebFooter';
import React, {PropsWithChildren} from 'react';
import {Platform} from 'react-native';
import {KeyboardAwareScrollView} from 'react-native-keyboard-controller';
import {GetProps, ScrollView, YStack, styled} from 'tamagui';

type FullscreenScrollViewProps = {
  /**
   * variant defines the maxWidth of the inner view
   * e.g. interview uses small = 500, investment planne ruses large = 1000
   */
  variant?: GetProps<typeof InnerView>['variant'];
  innerViewProps?: GetProps<typeof InnerView>;
  useKeyboardAwareScrollView?: boolean;
};

export function FullscreenScrollView({
  variant,
  children,
  innerViewProps,
  useKeyboardAwareScrollView,
}: PropsWithChildren<FullscreenScrollViewProps>) {
  const content = (
    <>
      <InnerView variant={variant} {...innerViewProps}>
        {children}
      </InnerView>
      {Platform.OS === 'web' && <WebFooter />}
    </>
  );
  if (Platform.OS !== 'web' && useKeyboardAwareScrollView) {
    return (
      <KeyboardAwareScrollView bottomOffset={80} keyboardShouldPersistTaps="handled">
        {content}
      </KeyboardAwareScrollView>
    );
  }
  return (
    <ScrollView flex={1} contentContainerStyle={{justifyContent: 'space-between'}}>
      {content}
    </ScrollView>
  );
}

const InnerView = styled(YStack, {
  width: '100%',
  alignSelf: 'center',
  paddingHorizontal: '$4',
  paddingVertical: '$4',
  gap: '$4',
  $sm: {
    paddingBottom: '$8',
  },
  variants: {
    variant: {
      small: {
        maxWidth: 500,
      },
      large: {
        maxWidth: 1000,
      },
    },
  } as const,
  defaultVariants: {
    variant: 'small',
  },
});
