import {useTranslate} from '@tolgee/react';
import {View, XStack, YStack} from 'tamagui';

import SvgFile from '@/components/icons/SvgFile';
import {Heading6} from '@/components/texts/Heading';
import {Label3sm2} from '@/components/texts/Label';
import {Paragraph2, Paragraph3} from '@/components/texts/Paragraph';

export const InternalTransferTipModalContent = () => {
  const {t} = useTranslate();

  return (
    <YStack gap="$5" marginVertical="$5">
      <Heading6 variant="medium">{t('ORDER.INTERNAL_ASSETS_TRANSFER.TIP.CONTENT.TITLE')}</Heading6>
      <View alignItems="flex-start">
        <YStack backgroundColor="$neutralBG" maxWidth="100%" borderRadius="$1" padding="$4">
          <XStack gap="$4" justifyContent="flex-start">
            <YStack alignItems="center">
              <SvgFile name="info" color="$primary" size={20} />
            </YStack>
            <YStack flexShrink={1} gap="$2">
              <Label3sm2 color="$primary">
                {t('ORDER.INTERNAL_ASSETS_TRANSFER.TIP.CONTENT.TEXT_1_1')}
              </Label3sm2>
              <Paragraph3>{t('ORDER.INTERNAL_ASSETS_TRANSFER.TIP.CONTENT.TEXT_1_2')}</Paragraph3>
            </YStack>
          </XStack>
        </YStack>
      </View>
      <Paragraph2 color="$neutral400">
        {t('ORDER.INTERNAL_ASSETS_TRANSFER.TIP.CONTENT.TEXT_2')}
      </Paragraph2>
      <Paragraph2 color="$neutral400">
        {t('ORDER.INTERNAL_ASSETS_TRANSFER.TIP.CONTENT.TEXT_3')}
      </Paragraph2>
    </YStack>
  );
};
