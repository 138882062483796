import {useTranslate} from '@tolgee/react';
import {XStack, YStack} from 'tamagui';

import SvgFile from '@/components/icons/SvgFile';
import {Label3sm2} from '@/components/texts/Label';
import {Paragraph3} from '@/components/texts/Paragraph';

type Props = {
  isUpvest?: boolean;
};

export const DepositWireNote = ({isUpvest}: Props) => {
  const {t} = useTranslate();

  return (
    <XStack
      gap="$2"
      backgroundColor="$neutralBG"
      borderRadius="$3"
      padding="$4"
      alignSelf="flex-start"
      maxWidth={600}
    >
      <YStack>
        <SvgFile name="info" color="$primary" size={20} />
      </YStack>
      <YStack gap="$3" flexShrink={1}>
        <Label3sm2 color="$primary">{t('ORDERS.DEPOSIT_METHOD.WIRE.NOTE.TITLE')}</Label3sm2>
        {!!isUpvest && <Paragraph3>{t('ORDERS.DEPOSIT_METHOD.WIRE.NOTE.DESC_1')}</Paragraph3>}
        <Paragraph3>{t('ORDERS.DEPOSIT_METHOD.WIRE.NOTE.DESC_2')}</Paragraph3>
      </YStack>
    </XStack>
  );
};
