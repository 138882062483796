import SvgFile from '@/components/icons/SvgFile';
import {Modal} from '@/components/modal/Modal';
import {Label2} from '@/components/texts/Label';
import {PropsWithChildren} from 'react';
import {XStack} from 'tamagui';

export type OnboardingHintModalProps = {
  label: string;
};

export function OnboardingHintModal({
  label,
  children,
}: PropsWithChildren<OnboardingHintModalProps>) {
  return (
    <Modal>
      <Modal.Trigger>
        <XStack alignItems="center" gap="$2" alignSelf="center">
          <SvgFile name="info" size={24} color="$primary" />
          <Label2 color="$primary">{label}</Label2>
        </XStack>
      </Modal.Trigger>
      <Modal.Content side>
        {children}
        <Modal.IconClose />
      </Modal.Content>
    </Modal>
  );
}
