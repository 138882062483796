type WithStartDateProps = {
  /**
   * Date to start calculation from
   */
  startDate: Date | string | number;
  /**
   * Date to calculate difference to: default is Date.now()
   */
  endDate?: Date | string | number;
};

type WithEndDateProps = {
  /**
   * Date to start calculation from: default is Date.now()
   */
  startDate?: Date | string | number;
  /**
   * Date to calculate difference to
   */
  endDate: Date | string | number;
};

type Props = {
  /**
   * default is days
   */
  type?: 'days' | 'hours' | 'years';
  noRounding?: boolean;
} & (WithStartDateProps | WithEndDateProps);

export const calculateTimeBetweenDates = ({
  startDate = new Date(),
  endDate = new Date(),
  type = 'days',
  noRounding = false,
}: Props) => {
  if (typeof endDate === 'string' || typeof endDate === 'number') {
    endDate = new Date(endDate);
  }
  if (typeof startDate === 'string' || typeof startDate === 'number') {
    startDate = new Date(startDate);
  }

  const oneHour = 1000 * 60 * 60; // milliseconds in one day
  const differenceInTime = endDate.getTime() - startDate.getTime();
  // const differenceInDays = Math.round(differenceInTime / oneDay);
  switch (type) {
    case 'hours':
      return Math.round(differenceInTime / oneHour);
    case 'years': {
      const years = differenceInTime / (oneHour * 24 * 365.25);
      return noRounding ? years : Math.round(years);
    }
    case 'days':
    default:
      return Math.round(differenceInTime / (oneHour * 24));
  }
};
