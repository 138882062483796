import {Stack} from 'expo-router';

import {useScreenOptions} from '@/modules/navigation/hooks/useScreenOptions';
import {OrderContextProvider} from '@/modules/orders/providers/OrderContextProvider';

export default function OrderLayout() {
  const screenOptions = useScreenOptions({headerShown: false});

  return (
    <OrderContextProvider>
      <Stack screenOptions={screenOptions} />
    </OrderContextProvider>
  );
}
