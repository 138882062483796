import {Image} from 'expo-image';
import {useCallback, useRef, useState} from 'react';
import {FieldValues, useController} from 'react-hook-form';
import {Input, Label, View, XStack, YStack, composeRefs} from 'tamagui';

import SvgFile from '@/components/icons/SvgFile';
import {BaseInput} from '@/components/inputs/form/BaseInput';
import {SelectInput} from '@/components/inputs/select-input/SelectInput';
import {InputErrorMessage} from '@/components/texts/InputErrorMessage';
import {Label2, Label3} from '@/components/texts/Label';
import {CALLING_CODES, FLAGS} from '@/constants/countries';
import {convertToE164PhoneNumber} from '@/helpers/convertToE164PhoneNumber';
import {FormInputProps} from '@/types/FormInputProps';
import {SelectInputRenderItemType} from '@/types/SelectInput';

type Props<T extends FieldValues> = {
  modal?: boolean;
  caption?: string;
} & FormInputProps<T>;

export function FormPhonenumberInput<T extends FieldValues>({
  control,
  name,
  label,
  bordered,
  textInputProps,
  modal,
  caption,
}: Props<T>) {
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>('DE');
  const ref = useRef<Input>(null);
  const {
    field,
    fieldState: {error},
  } = useController({
    name,
    control,
  });
  const composedRefs = composeRefs(ref, field.ref);

  const onChangeText = useCallback(
    (value: string) => {
      let updatedValue = value;

      const foundCode = CALLING_CODES.find(_ => {
        return updatedValue.startsWith(_.callingCode);
      });

      if (foundCode !== undefined) {
        setSelectedCountryCode(foundCode.countryCode);
        updatedValue = convertToE164PhoneNumber(updatedValue) || updatedValue;
        const [_, suffix] = updatedValue.split(foundCode.callingCode);
        if (field.value === `${updatedValue} `) {
          field.onChange(updatedValue);
          return;
        }
        if (suffix === '' || suffix.charAt(0) !== ' ') {
          updatedValue = `${foundCode.callingCode} ${suffix}`;
        }
        field.onChange(updatedValue);
        return;
      }

      field.onChange(value);
    },
    [field]
  );

  const renderItem = useCallback((item: SelectInputRenderItemType) => {
    return (
      <XStack key={item.key} justifyContent="space-between" alignItems="center" {...item.props}>
        <XStack flex={1} alignItems="center" gap="$2">
          <Image style={{height: 32, width: 32}} source={FLAGS[item.key]} contentFit="cover" />
          <Label2 variant="medium">{item.key}</Label2>
        </XStack>
        {item.active && <SvgFile name="check" size={18} color="$primary" />}
      </XStack>
    );
  }, []);

  const onSelect = useCallback(
    (value: string) => {
      const foundCode = CALLING_CODES.find(_ => {
        return _.countryCode === value;
      });
      if (!foundCode) return;
      field.onChange(`${foundCode.callingCode} `);
      setSelectedCountryCode(foundCode.countryCode);
      ref.current?.focus();
    },
    [field]
  );

  return (
    <YStack gap="$2">
      <Label htmlFor={name}>
        <Label3 color="$neutral500">{label}</Label3>
      </Label>
      {caption && <Label3 color="$neutral500">{caption}</Label3>}
      <View>
        <BaseInput
          ref={composedRefs}
          onChangeText={onChangeText}
          onBlur={field.onBlur}
          value={field.value || ''}
          id={name}
          bordered={bordered}
          iconLeft
          modal={modal}
          testID={name}
          keyboardType="phone-pad"
          {...textInputProps}
        />
        <SelectInput
          items={CALLING_CODES.map(_ => ({name: _.countryCode, key: _.countryCode}))}
          onSelect={onSelect}
          placeholder="-"
          wrapperProps={{
            backgroundColor: 'transparent',
            shadowOpacity: 0,
            position: 'absolute',
            height: bordered ? 32 : 40,
            padding: 0,
            width: bordered ? 32 : 48,
            alignItems: 'center',
            justifyContent: 'center',
            left: bordered ? '$1' : '$2',
            bottom: bordered ? 6 : '$4',
            $sm: {
              bottom: bordered ? 6 : '$1',
              left: '$2',
            },
            alignSelf: 'center',
            paddingHorizontal: bordered ? '$5' : '$6',
            borderWidth: 1,
            borderColor: 'transparent',
            hoverStyle: {
              backgroundColor: '$neutral100',
              borderColor: bordered ? '$neutral200' : 'transparent',
            },
          }}
          minWidth={100}
          renderItem={renderItem}
          selectedItem={selectedCountryCode}
          modalProps={{
            enableDynamicSizing: false,
          }}
        >
          <Image
            style={{height: bordered ? 24 : 32, width: bordered ? 24 : 32}}
            source={FLAGS[selectedCountryCode]}
            contentFit="cover"
          />
        </SelectInput>
      </View>
      <InputErrorMessage error={error?.message} />
    </YStack>
  );
}
