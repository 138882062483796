import {AmountDecimalInput} from '@/components/inputs/AmountDecimalInput';
import {InputErrorMessage} from '@/components/texts/InputErrorMessage';
import {Label3} from '@/components/texts/Label';
import {FormInputProps} from '@/types/FormInputProps';
import {FieldValues, useController} from 'react-hook-form';
import {Label, YStack} from 'tamagui';

type FormAmountInputProps<T extends FieldValues> = FormInputProps<T> & {
  disabled?: boolean;
  decimal?: boolean;
  modal?: boolean;
};

export function FormAmountInput<T extends FieldValues>({
  control,
  name,
  label,
  bordered = false,
  disabled = false,
  decimal = false,
  modal,
}: FormAmountInputProps<T>) {
  const {
    field,
    fieldState: {error},
  } = useController({
    name,
    control,
  });
  return (
    <YStack gap="$2">
      <Label htmlFor={name}>
        <Label3 color="$neutral500">{label}</Label3>
      </Label>
      <AmountDecimalInput
        bordered={bordered}
        ref={field.ref}
        onValueChange={field.onChange}
        onBlur={field.onBlur}
        value={field.value}
        disabled={disabled}
        decimal={decimal}
        id={name}
        modal={modal}
        testID={name}
      />
      <InputErrorMessage error={error?.message} />
    </YStack>
  );
}
