import SvgFile from '@/components/icons/SvgFile';
import {ThemeIcon} from '@/components/icons/ThemeIcon';
import {Heading2} from '@/components/texts/Heading';
import {Label1} from '@/components/texts/Label';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {Paragraph2} from '@/components/texts/Paragraph';
import {AnimatedHeightView} from '@/components/views/AnimatedHeightView';
import {LocalizableRefData} from '@/hooks/useRefdataLocalizable';
import {CustomerAllocationTheme} from '@/modules/orders/types/CustomerAllocationTheme';
import {ThemeCode} from '@/modules/portfolio/types/ThemeCode';
import {useAppSelector} from '@/store';
import {InvestmentTheme} from '@/types/api/refdata.v2';
import {useMemo} from 'react';
import {View, XStack, YStack, useMedia} from 'tamagui';

export function AddThemeModalItem({
  selectedThemes,
  thm,
  setSelectedThemes,
}: {
  thm: LocalizableRefData<InvestmentTheme>;
  selectedThemes: CustomerAllocationTheme[];
  setSelectedThemes: React.Dispatch<React.SetStateAction<CustomerAllocationTheme[]>>;
}) {
  const isMoneyMarket = useMemo(() => {
    return thm.code === 'MONEY_MARKET';
  }, [thm.code]);

  const language = useAppSelector(state => state.app.language);
  const isSelected = selectedThemes.some(_ => _.code === thm.code);
  const media = useMedia();
  return (
    <AnimatedHeightView
      borderRadius="$1"
      backgroundColor={isSelected ? '$primaryTransparent' : '$neutral0'}
      borderWidth={1}
      borderColor={isSelected ? '$primary' : 'transparent'}
      onPress={() => {
        if (selectedThemes.some(_ => _.code === thm.code))
          setSelectedThemes(_ => {
            return _.filter(_ => _.code !== thm.code);
          });
        else
          setSelectedThemes(_ => {
            return [
              ..._,
              {
                code: thm.code as ThemeCode,
                name: thm.label,
                amount: 0,
                ratio: 0,
              },
            ];
          });
      }}
      cursor="pointer"
      hoverStyle={{opacity: 0.7}}
      width="100%"
    >
      <XStack alignItems="center" gap="$2" padding="$2">
        {isMoneyMarket ? (
          <SvgFile name="topzins-us-vector" />
        ) : (
          <ThemeIcon code={thm.code as ThemeCode} />
        )}
        <XStack justifyContent="space-between" flex={1} alignItems="center">
          <Label1 variant="medium">{thm.label}</Label1>
          {media.sm && (
            <View rotate={isSelected ? '270deg' : '90deg'} animation="slow">
              <SvgFile name="chevron-right" color="$neutral500" />
            </View>
          )}
        </XStack>
      </XStack>
      <XStack padding="$2">
        <Paragraph2 color="$neutral500" numberOfLines={isMoneyMarket || isSelected ? 100 : 2}>
          {thm.description[language]}
        </Paragraph2>
        {media.gtSm && isMoneyMarket && thm.yearlyReturn && (
          <YStack alignItems="center" gap="$2">
            <LocalizedNumbers
              value={thm.yearlyReturn / 100}
              precision="decimals"
              textComponent={Heading2}
              // @ts-ignore
              numberStyle={{variant: 'bold', color: '#C42126'}}
              variant="percentage"
            />
            <SvgFile name="topzins-us-vector" width={96} />
          </YStack>
        )}
      </XStack>
    </AnimatedHeightView>
  );
}
