// Helper function: letters become (ASCII - 55), digits remain themselves.
function converter(ch: string): string {
  // If it's A-Z or a-z, convert using 'A' → 10, 'B' → 11, ...
  if (/[A-Z]/i.test(ch)) {
    return (ch.toUpperCase().charCodeAt(0) - 55).toString();
  }
  // Otherwise, assume it is a digit
  return ch;
}

/**
 * Validate IBAN based on the standard mod 97 check.
 * @param iban - The IBAN string to validate
 * @returns true if valid IBAN, false otherwise
 */
export function validateIBAN(iban: string): boolean {
  // 1. Rearrange IBAN: move first 4 characters to the end
  const rearranged = iban.slice(4) + iban.slice(0, 4);

  // 2. Convert each character and concatenate
  let converted = '';
  for (const c of rearranged) {
    converted += converter(c);
  }

  // 3. Convert to BigInt and check mod 97
  const bigNumber = BigInt(converted);

  // 4. If bigNumber % 97 == 1, the IBAN is valid
  return bigNumber % 97n === 1n;
}
