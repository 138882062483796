import {Href} from 'expo-router';
import {useMemo} from 'react';
import {YStack} from 'tamagui';

import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {ConfirmNoExperienceAnswer} from '@/modules/interview/components/ConfirmNoExperienceAnswer';
import {isProductBasic} from '@/modules/interview/helpers/isProductBasic';
import {useOnboardingSearchParams} from '@/modules/onboarding/hooks/useOnboardingSearchParams';

export default function NoExpPage() {
  const params = useOnboardingSearchParams();

  const href: Href = useMemo(() => {
    const {product} = params;

    const isBasic = isProductBasic(product) || params.onboardingType === 'second_guardian';

    return isBasic
      ? {pathname: '/interview/question/inputs', params}
      : {pathname: '/interview/question/risk', params};
  }, [params]);

  return (
    <FullscreenScrollView variant="small">
      <YStack>
        <ConfirmNoExperienceAnswer href={href} />
      </YStack>
    </FullscreenScrollView>
  );
}
