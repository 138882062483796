import {useTranslate} from '@tolgee/react';
import {XStack, YStack} from 'tamagui';

import {Label3} from '@/components/texts/Label';
import {ComparisonNumber} from '@/modules/orders/components/ChangeStrategy/ComparisonNumber';

export type EquityQuote = {
  curr?: number;
  new?: number;
};

type Props = {
  currentStrategyRiskProfileLabel?: string;
  newStrategyRiskProfileLabel?: string;
  equityQuote?: EquityQuote;
};

export const ComparisonStrategyProfile = ({
  currentStrategyRiskProfileLabel,
  newStrategyRiskProfileLabel,
  equityQuote,
}: Props) => {
  const {t} = useTranslate();

  return (
    <YStack gap="$4">
      <XStack justifyContent="space-between">
        <Label3>{t('CHANGE_STRATEGY.COMPARISON.STRATEGY_PROFILE')}</Label3>
        <XStack minWidth="25%" maxWidth="45%" justifyContent="space-between">
          <XStack flexGrow={1}>
            <Label3>{currentStrategyRiskProfileLabel ?? ''}</Label3>
          </XStack>
          <XStack flexGrow={1} justifyContent="flex-end">
            <Label3>{newStrategyRiskProfileLabel ?? ''}</Label3>
          </XStack>
        </XStack>
      </XStack>
      <XStack justifyContent="space-between">
        <Label3>{t('CHANGE_STRATEGY.COMPARISON.EQUITY_QUOTA')}</Label3>
        <XStack minWidth="25%" maxWidth="45%" justifyContent="space-between">
          <XStack flexGrow={1}>
            <ComparisonNumber value={equityQuote?.curr} />
          </XStack>
          <XStack flexGrow={1} justifyContent="flex-end">
            <ComparisonNumber value={equityQuote?.new} />
          </XStack>
        </XStack>
      </XStack>
    </YStack>
  );
};
