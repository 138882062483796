import {
  AcademicTitle,
  AssetClass,
  AssetDetailsResponse,
  AssetIndustry,
  AssetLabel,
  AssetRegion,
  Country,
  GetPerformanceProjectionsData,
  IdentityProof,
  InboxDocumentCategory,
  InboxDocumentSource,
  IncomeSource,
  Industry,
  InterviewAnswer,
  InterviewQuestion,
  InvestmentStrategy,
  InvestmentTheme,
  OrderType,
  PerformanceProjection,
  ProductResponse,
  Profession,
  RiskProfile,
  Security,
  StaticDocument,
  Title,
  TransactionCategory,
  Violation,
  WealthSource,
  WithdrawalReason,
} from '@/types/api/refdata.v2';

import {HOUR, baseApi} from './baseApi';

const BASE_PATH = '/refdata/v2';

export const extendedReferenceApi = baseApi.injectEndpoints({
  endpoints: build => ({
    investmentStrategies: build.query<InvestmentStrategy[], void>({
      query: () => ({url: `${BASE_PATH}/investment-strategies`}),
      keepUnusedDataFor: 24 * HOUR,
    }),
    assetClasses: build.query<AssetClass[], void>({
      query: () => ({url: `${BASE_PATH}/asset-classes`}),
      keepUnusedDataFor: 24 * HOUR,
    }),
    assetLabels: build.query<AssetLabel[], void>({
      query: () => ({url: `${BASE_PATH}/asset-labels`}),
      keepUnusedDataFor: 24 * HOUR,
    }),
    assetIndustries: build.query<AssetIndustry[], void>({
      query: () => ({url: `${BASE_PATH}/asset-industries`}),
      keepUnusedDataFor: 24 * HOUR,
    }),
    assetRegions: build.query<AssetRegion[], void>({
      query: () => ({url: `${BASE_PATH}/asset-regions`}),
      keepUnusedDataFor: 24 * HOUR,
    }),
    assetDetails: build.query<AssetDetailsResponse[], void>({
      query: () => ({url: `${BASE_PATH}/asset-details`}),
      keepUnusedDataFor: 24 * HOUR,
    }),
    securities: build.query<Security[], void>({
      query: () => ({url: `${BASE_PATH}/securities`}),
      keepUnusedDataFor: 24 * HOUR,
    }),
    countries: build.query<Country[], void>({
      query: () => ({url: `${BASE_PATH}/countries`}),
      keepUnusedDataFor: 24 * HOUR,
    }),
    transactionCategories: build.query<TransactionCategory[], void>({
      query: () => ({url: `${BASE_PATH}/transaction-categories`}),
      keepUnusedDataFor: 24 * HOUR,
    }),
    inboxDocumentCategories: build.query<InboxDocumentCategory[], void>({
      query: () => ({url: `${BASE_PATH}/inbox-document-categories`}),
      keepUnusedDataFor: 24 * HOUR,
    }),
    inboxDocumentSources: build.query<InboxDocumentSource[], void>({
      query: () => ({url: `${BASE_PATH}/inbox-document-sources`}),
      keepUnusedDataFor: 24 * HOUR,
    }),
    withdrawalReasons: build.query<WithdrawalReason[], void>({
      query: () => ({url: `${BASE_PATH}/withdrawal-reasons`}),
      keepUnusedDataFor: 24 * HOUR,
    }),
    violations: build.query<Violation[], void>({
      query: () => ({url: `${BASE_PATH}/violations`}),
      keepUnusedDataFor: 24 * HOUR,
    }),
    academicTitles: build.query<AcademicTitle[], void>({
      query: () => ({url: `${BASE_PATH}/academic-titles`}),
      keepUnusedDataFor: 24 * HOUR,
    }),
    investmentThemes: build.query<InvestmentTheme[], void>({
      query: () => ({url: `${BASE_PATH}/investment-themes`}),
      keepUnusedDataFor: 24 * HOUR,
    }),
    products: build.query<ProductResponse[], void>({
      query: () => ({url: `${BASE_PATH}/products`}),
      keepUnusedDataFor: 24 * HOUR,
    }),
    titles: build.query<Title[], void>({
      query: () => ({url: `${BASE_PATH}/titles`}),
      keepUnusedDataFor: 24 * HOUR,
    }),
    orderTypes: build.query<OrderType[], void>({
      query: () => ({url: `${BASE_PATH}/order-types`}),
      keepUnusedDataFor: 24 * HOUR,
    }),
    interviewQuestions: build.query<InterviewQuestion[], void>({
      query: () => ({url: `${BASE_PATH}/interview-questions`}),
      keepUnusedDataFor: 24 * HOUR,
      transformResponse: (response: InterviewQuestion[]) =>
        response.map(item => ({
          ...item,
          possibleAnswers:
            item.code === 'EXP_V2'
              ? [...item.possibleAnswers, 'ASSET_CLASS_NONE']
              : item.possibleAnswers,
        })),
    }),
    interviewAnswers: build.query<InterviewAnswer[], void>({
      query: () => ({url: `${BASE_PATH}/interview-answers`}),
      keepUnusedDataFor: 24 * HOUR,
    }),
    professions: build.query<Profession[], void>({
      query: () => ({url: `${BASE_PATH}/professions`}),
      keepUnusedDataFor: 24 * HOUR,
    }),
    industries: build.query<Industry[], void | {profession?: string}>({
      query: args => ({
        url: `${BASE_PATH}/industries`,
        params: {profession: args && args.profession ? args.profession : undefined},
      }),
      keepUnusedDataFor: 24 * HOUR,
    }),
    incomeSources: build.query<IncomeSource[], void>({
      query: () => ({url: `${BASE_PATH}/income-sources`}),
      keepUnusedDataFor: 24 * HOUR,
    }),
    wealthSources: build.query<WealthSource[], void>({
      query: () => ({url: `${BASE_PATH}/wealth-sources`}),
      keepUnusedDataFor: 24 * HOUR,
    }),
    riskProfiles: build.query<RiskProfile[], void>({
      query: () => ({url: `${BASE_PATH}/risk-profiles`}),
      keepUnusedDataFor: 24 * HOUR,
    }),
    identityProofs: build.query<IdentityProof[], void>({
      query: () => ({url: `${BASE_PATH}/identity-proofs`}),
      keepUnusedDataFor: 24 * HOUR,
    }),
    performanceProjections: build.query<PerformanceProjection[], GetPerformanceProjectionsData>({
      query: ({
        initialDepositAmount,
        investmentPeriodMonths,
        investmentStrategy,
        recurringDepositAmount,
        recurringDepositType,
        startDate,
      }) => {
        const params = new URLSearchParams();
        if (initialDepositAmount !== undefined)
          params.append('initialDepositAmount', String(initialDepositAmount));
        params.append('investmentPeriodMonths', String(investmentPeriodMonths || 240));
        if (investmentStrategy !== undefined)
          params.append('investmentStrategy', investmentStrategy);
        if (recurringDepositAmount !== undefined)
          params.append('recurringDepositAmount', String(recurringDepositAmount));
        if (recurringDepositType !== undefined)
          params.append('recurringDepositType', recurringDepositType);
        if (startDate !== undefined) params.append('startDate', startDate);
        return {url: `${BASE_PATH}/performance-projections/?${params.toString()}`};
      },
      keepUnusedDataFor: 24 * HOUR,
    }),
    getStaticDocuments: build.query<StaticDocument[], void>({
      query: () => ({url: `${BASE_PATH}/static-documents`}),
      keepUnusedDataFor: 24 * HOUR,
    }),
  }),
  overrideExisting: false,
});

export const {
  useAssetClassesQuery,
  useAssetIndustriesQuery,
  useAssetRegionsQuery,
  useAssetLabelsQuery,
  useAcademicTitlesQuery,
  useAssetDetailsQuery,
  useCountriesQuery,
  useInboxDocumentCategoriesQuery,
  useInboxDocumentSourcesQuery,
  useInvestmentStrategiesQuery,
  useInvestmentThemesQuery,
  useWithdrawalReasonsQuery,
  useViolationsQuery,
  useTransactionCategoriesQuery,
  useTitlesQuery,
  useSecuritiesQuery,
  useProductsQuery,
  useOrderTypesQuery,
  useInterviewQuestionsQuery,
  useInterviewAnswersQuery,
  useProfessionsQuery,
  useIndustriesQuery,
  useIncomeSourcesQuery,
  useWealthSourcesQuery,
  useRiskProfilesQuery,
  usePerformanceProjectionsQuery,
  useGetStaticDocumentsQuery,
  useIdentityProofsQuery,
} = extendedReferenceApi;
