import {Heading4} from '@/components/texts/Heading';
import {BirthDetailsSchemaType} from '@/hooks/useValidations';
import {SubmitFormRef} from '@/modules/interview/components/FinancialSituationForm';
import {InterviewContinueButton} from '@/modules/interview/components/InterviewContinueButton';
import {UserBirthForm} from '@/modules/onboarding/components/forms/UserBirthForm';
import {mapFormNationalitiesToUser} from '@/modules/onboarding/components/helpers/mapFormNationalitiesToUser';
import {useGetParentOrChildUser} from '@/modules/onboarding/hooks/useGetParentOrChildUser';
import {useIdentityProofsQuery} from '@/store/queries/referenceApi';
import {useUpdateUserMutation} from '@/store/queries/usersApi';
import {useTranslate} from '@tolgee/react';
import {Href, router} from 'expo-router';
import {useCallback, useMemo, useRef, useState} from 'react';
import {YStack} from 'tamagui';

export function UserBirthScreen({
  href,
  childID,
  isChildScreen,
}: {
  href: Href;
  childID?: string;
  isChildScreen: boolean;
}) {
  const ref = useRef<SubmitFormRef>(null);
  const {t} = useTranslate();
  const [updateUser] = useUpdateUserMutation();
  const user = useGetParentOrChildUser(isChildScreen, childID);
  const [isLoading, setIsLoading] = useState(false);
  const {data: proofs} = useIdentityProofsQuery();

  const onPress = useCallback(async () => {
    await ref.current?.submit();
  }, []);

  const onValidSubmit = useCallback(
    async (data: BirthDetailsSchemaType) => {
      const {dateOfBirth, placeOfBirth, countryOfBirthCode, nationalities} = data;
      if (user) {
        await updateUser({
          currentUser: user,
          changes: {
            dateOfBirth,
            placeOfBirth,
            countryOfBirth: countryOfBirthCode,
            nationalities: mapFormNationalitiesToUser(nationalities, proofs),
          },
        });
      }
      router.push(href);
    },
    [href, proofs, updateUser, user]
  );

  const title = useMemo(() => {
    if (!user?.firstName) return;
    if (isChildScreen) return t('ONBOARDING.CHILD.WHATS_YOUR_BIRTH', {firstName: user?.firstName});
    else return t('ONBOARDING.WHATS_YOUR_BIRTH', {firstName: user?.firstName});
  }, [isChildScreen, t, user?.firstName]);

  return (
    <YStack gap="$6" marginTop="$6">
      <Heading4 variant="medium">{title}</Heading4>

      <UserBirthForm
        ref={ref}
        onValid={onValidSubmit}
        user={user}
        child={isChildScreen}
        setIsLoading={setIsLoading}
      />

      <InterviewContinueButton isLoading={isLoading} onPress={onPress} />
    </YStack>
  );
}
