import {InterviewState} from '@/store/reducers/interview/state';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';

const initialState: InterviewState = {};

export const interviewSlice = createSlice({
  name: 'interview',
  initialState,
  reducers: {
    updateInterview: (state, action: PayloadAction<InterviewState>) => {
      // Reset state when switching between interview types
      if (action.payload.type !== undefined && action.payload.type !== state.type) {
        return action.payload;
      }
      return {...state, ...action.payload};
    },
    resetInterviewState: () => {
      return initialState;
    },
  },
});

export const {updateInterview, resetInterviewState} = interviewSlice.actions;

export default interviewSlice.reducer;
