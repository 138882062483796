import {SelectItem} from '@/components/inputs/select-input/SelectItem';
import {SelectInputItem} from '@/types/FormInputProps';
import {SelectInputRenderItemType} from '@/types/SelectInput';
import {ListRenderItem} from '@shopify/flash-list/src/FlashListProps';
import {ReactElement, useCallback} from 'react';
import {GestureResponderEvent} from 'react-native';
import {GetProps, XStack} from 'tamagui';

export const useSelectInput = (
  selectedItem?: string,
  renderItem?: (item: SelectInputRenderItemType) => ReactElement,
  onSelect?: (item: SelectInputItem) => void
) => {
  const handleSelectItem = useCallback(
    (item: SelectInputItem) => (event: GestureResponderEvent) => {
      event.preventDefault();
      event.stopPropagation();

      if (onSelect) {
        onSelect(item);
      }
    },
    [onSelect]
  );

  const handleRenderItem = useCallback<ListRenderItem<SelectInputItem>>(
    ({item}) => {
      const active = selectedItem === item.key;

      const props = {
        userSelect: 'none',
        alignSelf: 'flex-start',
        onPress: handleSelectItem(item),
        cursor: 'pointer',
        paddingVertical: '$2',
        paddingHorizontal: '$3',
        hoverStyle: {backgroundColor: '$neutral50'},
        width: '100%',
        borderWidth: 1,
        borderColor: 'transparent',
      } as GetProps<typeof XStack>;

      if (renderItem) {
        return renderItem({...item, active, props});
      }

      return (
        <SelectItem
          wrapperProps={props}
          item={item}
          selected={active}
          icon={item.icon}
          disabled={item.disabled}
        />
      );
    },
    [handleSelectItem, renderItem, selectedItem]
  );

  return {
    renderItem: handleRenderItem,
  };
};
