// All of the requested modules are loaded behind enumerable getters.
const map = Object.defineProperties({}, {
  "./(user)/(tabs)/_layout.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/(tabs)/_layout.tsx"); } },
  "./(user)/(tabs)/overview/[customerId]/_layout.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/(tabs)/overview/[customerId]/_layout.tsx"); } },
  "./(user)/(tabs)/overview/[customerId]/allocation.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/(tabs)/overview/[customerId]/allocation.tsx"); } },
  "./(user)/(tabs)/overview/[customerId]/index.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/(tabs)/overview/[customerId]/index.tsx"); } },
  "./(user)/(tabs)/overview/[customerId]/information.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/(tabs)/overview/[customerId]/information.tsx"); } },
  "./(user)/(tabs)/overview/[customerId]/order/_layout.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/(tabs)/overview/[customerId]/order/_layout.tsx"); } },
  "./(user)/(tabs)/overview/[customerId]/order/change-reference-account.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/(tabs)/overview/[customerId]/order/change-reference-account.tsx"); } },
  "./(user)/(tabs)/overview/[customerId]/order/change-strategy.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/(tabs)/overview/[customerId]/order/change-strategy.tsx"); } },
  "./(user)/(tabs)/overview/[customerId]/order/deposit-motive.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/(tabs)/overview/[customerId]/order/deposit-motive.tsx"); } },
  "./(user)/(tabs)/overview/[customerId]/order/deposit.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/(tabs)/overview/[customerId]/order/deposit.tsx"); } },
  "./(user)/(tabs)/overview/[customerId]/order/internal-assets-transfer.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/(tabs)/overview/[customerId]/order/internal-assets-transfer.tsx"); } },
  "./(user)/(tabs)/overview/[customerId]/order/withdrawal-motive.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/(tabs)/overview/[customerId]/order/withdrawal-motive.tsx"); } },
  "./(user)/(tabs)/overview/[customerId]/order/withdrawal-reason.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/(tabs)/overview/[customerId]/order/withdrawal-reason.tsx"); } },
  "./(user)/(tabs)/overview/[customerId]/order/withdrawal.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/(tabs)/overview/[customerId]/order/withdrawal.tsx"); } },
  "./(user)/(tabs)/overview/[customerId]/orders.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/(tabs)/overview/[customerId]/orders.tsx"); } },
  "./(user)/(tabs)/overview/[customerId]/performance.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/(tabs)/overview/[customerId]/performance.tsx"); } },
  "./(user)/(tabs)/overview/[customerId]/transactions.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/(tabs)/overview/[customerId]/transactions.tsx"); } },
  "./(user)/(tabs)/overview/_layout.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/(tabs)/overview/_layout.tsx"); } },
  "./(user)/(tabs)/overview/index.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/(tabs)/overview/index.tsx"); } },
  "./(user)/(tabs)/profile.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/(tabs)/profile.tsx"); } },
  "./(user)/(tabs)/recommendations.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/(tabs)/recommendations.tsx"); } },
  "./(user)/_layout.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/_layout.tsx"); } },
  "./(user)/friends/_layout.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/friends/_layout.tsx"); } },
  "./(user)/friends/index.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/friends/index.tsx"); } },
  "./(user)/index.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/index.tsx"); } },
  "./(user)/onboarding/_layout.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/onboarding/_layout.tsx"); } },
  "./(user)/onboarding/personal-details/_layout.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/onboarding/personal-details/_layout.tsx"); } },
  "./(user)/onboarding/personal-details/address.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/onboarding/personal-details/address.tsx"); } },
  "./(user)/onboarding/personal-details/agreements.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/onboarding/personal-details/agreements.tsx"); } },
  "./(user)/onboarding/personal-details/another-guardian-info.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/onboarding/personal-details/another-guardian-info.tsx"); } },
  "./(user)/onboarding/personal-details/another-guardian.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/onboarding/personal-details/another-guardian.tsx"); } },
  "./(user)/onboarding/personal-details/bank.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/onboarding/personal-details/bank.tsx"); } },
  "./(user)/onboarding/personal-details/birth.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/onboarding/personal-details/birth.tsx"); } },
  "./(user)/onboarding/personal-details/child-docs.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/onboarding/personal-details/child-docs.tsx"); } },
  "./(user)/onboarding/personal-details/deposits.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/onboarding/personal-details/deposits.tsx"); } },
  "./(user)/onboarding/personal-details/employer-instructions.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/onboarding/personal-details/employer-instructions.tsx"); } },
  "./(user)/onboarding/personal-details/income.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/onboarding/personal-details/income.tsx"); } },
  "./(user)/onboarding/personal-details/index.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/onboarding/personal-details/index.tsx"); } },
  "./(user)/onboarding/personal-details/job.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/onboarding/personal-details/job.tsx"); } },
  "./(user)/onboarding/personal-details/name.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/onboarding/personal-details/name.tsx"); } },
  "./(user)/onboarding/personal-details/politically-person.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/onboarding/personal-details/politically-person.tsx"); } },
  "./(user)/onboarding/personal-details/summary.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/onboarding/personal-details/summary.tsx"); } },
  "./(user)/onboarding/personal-details/tax.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/onboarding/personal-details/tax.tsx"); } },
  "./(user)/onboarding/personal-details/vl-address.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/onboarding/personal-details/vl-address.tsx"); } },
  "./(user)/onboarding/personal-details/vl-start.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/onboarding/personal-details/vl-start.tsx"); } },
  "./(user)/onboarding/personal-details/wealth.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/onboarding/personal-details/wealth.tsx"); } },
  "./(user)/onboarding/proposal.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/onboarding/proposal.tsx"); } },
  "./(user)/profile/_layout.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/profile/_layout.tsx"); } },
  "./(user)/profile/change-address-proof.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/profile/change-address-proof.tsx"); } },
  "./(user)/profile/delete-account.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/profile/delete-account.tsx"); } },
  "./(user)/profile/display-and-language.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/profile/display-and-language.tsx"); } },
  "./(user)/profile/documents.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/profile/documents.tsx"); } },
  "./(user)/profile/email-change.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/profile/email-change.tsx"); } },
  "./(user)/profile/mfa.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/profile/mfa.tsx"); } },
  "./(user)/profile/new-tax-exemption.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/profile/new-tax-exemption.tsx"); } },
  "./(user)/profile/password-change.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/profile/password-change.tsx"); } },
  "./(user)/profile/personal-data.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/profile/personal-data.tsx"); } },
  "./(user)/profile/security.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/profile/security.tsx"); } },
  "./(user)/profile/taxes.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/profile/taxes.tsx"); } },
  "./(user)/proof-of-residence.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/proof-of-residence.tsx"); } },
  "./(user)/transactions/index.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(user)/transactions/index.tsx"); } },
  "./(visitor)/confirm.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(visitor)/confirm.tsx"); } },
  "./(visitor)/emailverify/failure.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(visitor)/emailverify/failure.tsx"); } },
  "./(visitor)/emailverify/start.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(visitor)/emailverify/start.tsx"); } },
  "./(visitor)/emailverify/success.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(visitor)/emailverify/success.tsx"); } },
  "./(visitor)/interview/_layout.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(visitor)/interview/_layout.tsx"); } },
  "./(visitor)/interview/planner.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(visitor)/interview/planner.tsx"); } },
  "./(visitor)/interview/product-choice.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(visitor)/interview/product-choice.tsx"); } },
  "./(visitor)/interview/question/_layout.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(visitor)/interview/question/_layout.tsx"); } },
  "./(visitor)/interview/question/deposits.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(visitor)/interview/question/deposits.tsx"); } },
  "./(visitor)/interview/question/exp-v2.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(visitor)/interview/question/exp-v2.tsx"); } },
  "./(visitor)/interview/question/index.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(visitor)/interview/question/index.tsx"); } },
  "./(visitor)/interview/question/inputs.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(visitor)/interview/question/inputs.tsx"); } },
  "./(visitor)/interview/question/invest-years.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(visitor)/interview/question/invest-years.tsx"); } },
  "./(visitor)/interview/question/loss-limit.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(visitor)/interview/question/loss-limit.tsx"); } },
  "./(visitor)/interview/question/no-exp.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(visitor)/interview/question/no-exp.tsx"); } },
  "./(visitor)/interview/question/risk.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(visitor)/interview/question/risk.tsx"); } },
  "./(visitor)/interview/question/sustain-level-news.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(visitor)/interview/question/sustain-level-news.tsx"); } },
  "./(visitor)/interview/question/sustain-level.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(visitor)/interview/question/sustain-level.tsx"); } },
  "./(visitor)/interview/question/sustain-pref.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(visitor)/interview/question/sustain-pref.tsx"); } },
  "./(visitor)/interview/question/trades.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(visitor)/interview/question/trades.tsx"); } },
  "./(visitor)/login.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(visitor)/login.tsx"); } },
  "./(visitor)/redirect/[identity].tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(visitor)/redirect/[identity].tsx"); } },
  "./(visitor)/redirect/auth.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(visitor)/redirect/auth.tsx"); } },
  "./(visitor)/redirect/logout.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/(visitor)/redirect/logout.tsx"); } },
  "./+not-found.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/+not-found.tsx"); } },
  "./_layout.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/_layout.tsx"); } },
  "./index.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/index.tsx"); } },
  "./storybook/_layout.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/storybook/_layout.tsx"); } },
  "./storybook/index.tsx": { enumerable: true, get() { return require("/codebuild/output/src2244814865/src/frontend/src/app/storybook/index.tsx"); } },
});

function metroContext(request) {
    return map[request];
}

// Return the keys that can be resolved.
metroContext.keys = function metroContextKeys() {
  return Object.keys(map);
};

// Return the module identifier for a user request.
metroContext.resolve = function metroContextResolve(request) {
  throw new Error('Unimplemented Metro module context functionality');
}

module.exports = metroContext;