import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {
  ClassColorsEnum,
  IndustryColorsEnum,
  RegionColorsEnum,
  ThemeColorsEnum,
} from '@/modules/portfolio/constants/enums';
import {ThemeCode} from '@/modules/portfolio/types/ThemeCode';
import {
  useAssetClassesQuery,
  useAssetIndustriesQuery,
  useAssetLabelsQuery,
  useAssetRegionsQuery,
  useInvestmentThemesQuery,
  useSecuritiesQuery,
} from '@/store/queries/referenceApi';
import {Allocation, Security} from '@/types/api/refdata.v2';
import {useTranslate} from '@tolgee/react';
import {useCallback, useMemo} from 'react';
import {GetProps, View} from 'tamagui';
import {GroupType} from '../types/GroupType';

type UseGetAllocationsRatiosArgs = {
  groupType: GroupType;
  allocations?: (Allocation & {amount?: number})[];
};

type SecurityWithRatioAndAmount = Omit<Security, 'label'> & {
  ratio: number;
  label: string;
  amount?: number;
};

export type RatioItem = {
  key: string;
  title: string;
  ratio: number;
  amount?: number;
  color: GetProps<typeof View>['backgroundColor'];
  securities?: SecurityWithRatioAndAmount[];
};

const UNKNOWN_SECURITY = 'UNKNOWN';

export const useGetAllocationsRatios = ({groupType, allocations}: UseGetAllocationsRatiosArgs) => {
  const {t} = useTranslate();

  const {data: securities, isFetching: fetchingSecurities} = useSecuritiesQuery();
  const {data: assetClasses, isFetching: fetchingAssetClasses} = useAssetClassesQuery();
  const {data: assetLabels, isFetching: fetchingAssetLabels} = useAssetLabelsQuery();
  const {data: assetIndustries, isFetching: fetchingAssetIndustries} = useAssetIndustriesQuery();
  const {data: assetRegions, isFetching: fetchingAssetRegions} = useAssetRegionsQuery();
  const {data: investmentThemes, isFetching: fetchingInvestmentThemes} = useInvestmentThemesQuery();
  const {getByCode} = useRefdataLocalizable();

  const getColor = useCallback(
    (key: string) => {
      switch (groupType) {
        case 'assets': {
          return ClassColorsEnum[key as keyof typeof ClassColorsEnum];
        }
        case 'industry': {
          return IndustryColorsEnum[key as keyof typeof IndustryColorsEnum];
        }
        case 'region': {
          return RegionColorsEnum[key as keyof typeof RegionColorsEnum];
        }
        case 'trends': {
          return ThemeColorsEnum[key as ThemeCode];
        }
        default: {
          return ClassColorsEnum.DEFAULT;
        }
      }
    },
    [groupType]
  );

  return useMemo(() => {
    if (
      allocations === undefined ||
      fetchingSecurities ||
      fetchingAssetClasses ||
      fetchingAssetLabels ||
      fetchingAssetIndustries ||
      fetchingAssetRegions ||
      fetchingInvestmentThemes
    )
      return;
    if (allocations?.length === 0) return [];

    const overallAmount =
      allocations[0].amount && allocations?.reduce((acc, curr) => acc + (curr.amount ?? 0), 0);

    const res: Record<string, RatioItem> = {};

    for (const allocation of allocations) {
      const security = getByCode(securities, allocation.security);

      if (!security) {
        if (res[UNKNOWN_SECURITY]) {
          res[UNKNOWN_SECURITY].ratio += allocation.ratio;
        } else {
          res[UNKNOWN_SECURITY] = {
            key: UNKNOWN_SECURITY,
            title: t('PORTFOLIO.UNKNOWN'),
            ratio: allocation.ratio,
            color: ClassColorsEnum.DEFAULT,
            amount: allocation?.amount,
          };
        }

        continue;
      }

      if (groupType === 'assets') {
        const assetClass = getByCode(assetClasses, security.assetClass);
        const assetLabel = getByCode(assetLabels, security.assetLabel)?.label;

        if (!assetClass || !assetLabel) continue;

        const item = res[security.assetClass];

        if (item) {
          item.ratio += allocation.ratio;
          if (item.securities)
            item.securities.push({
              ...security,
              assetLabel,
              ratio: allocation.ratio,
              amount: allocation.amount,
            });
        } else {
          res[security.assetClass] = {
            key: security.assetClass,
            title: assetClass.label,
            ratio: allocation.ratio,
            color: getColor(security.assetClass) ?? ClassColorsEnum.DEFAULT,
            amount: allocation?.amount,
            securities: [
              {
                ...security,
                assetLabel,
                ratio: allocation.ratio,
                amount: allocation.amount,
              },
            ],
          };
        }
      } else if (groupType === 'trends') {
        const theme = getByCode(investmentThemes, security.code, 'security');
        if (!theme) continue;

        res[theme.code] = {
          key: theme.code,
          title: theme.label,
          ratio: allocation.ratio,
          color: getColor(theme.code) ?? ClassColorsEnum.DEFAULT,
          amount: allocation?.amount,
        };
      } else {
        const assetsRatios =
          groupType === 'industry' ? security.assetIndustries : security.assetRegions;

        if (!assetsRatios) continue;

        for (const [code, ratio] of Object.entries(assetsRatios)) {
          const asset =
            groupType === 'industry'
              ? getByCode(assetIndustries, code)
              : getByCode(assetRegions, code);

          if (!asset) continue;

          const assetRatio = allocation.ratio * ratio;
          const item = res[code];

          if (item) {
            item.ratio += assetRatio;
            if (item.amount) item.amount = overallAmount && item.ratio * overallAmount;
          } else {
            res[code] = {
              key: code,
              title: asset.label,
              ratio: assetRatio,
              color: getColor(code) ?? ClassColorsEnum.DEFAULT,
              amount: overallAmount && assetRatio * overallAmount,
            };
          }
        }
      }
    }
    return Object.values(res)
      .sort((a, b) => b.ratio - a.ratio)
      .filter(_ => Boolean(_.key));
  }, [
    allocations,
    assetClasses,
    assetIndustries,
    assetLabels,
    assetRegions,
    fetchingAssetClasses,
    fetchingAssetIndustries,
    fetchingAssetLabels,
    fetchingAssetRegions,
    fetchingInvestmentThemes,
    fetchingSecurities,
    getByCode,
    getColor,
    groupType,
    investmentThemes,
    securities,
    t,
  ]);
};
