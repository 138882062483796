import SvgFile from '@/components/icons/SvgFile';
import {Label3} from '@/components/texts/Label';
import {useTranslate} from '@tolgee/react';
import React, {PropsWithChildren, forwardRef} from 'react';
import {GetProps, GetThemeValueForKey, View, XStack, YStack} from 'tamagui';

type WarningBoxProps = PropsWithChildren<{
  text?: string;
  wrapperProps?: GetProps<typeof XStack>;
  withBackground?: boolean;
  tipColor?: GetThemeValueForKey<'color'>;
}>;

export const InfoBox = forwardRef<any, WarningBoxProps>(
  ({text, wrapperProps, children, tipColor, withBackground, ...rest}, ref) => {
    const {t} = useTranslate();
    return (
      <XStack
        ref={ref}
        borderRadius="$1"
        padding="$3"
        flexDirection="row"
        gap="$1.5"
        alignItems="center"
        backgroundColor={withBackground ? '$primaryTransparent' : 'unset'}
        {...wrapperProps}
        {...rest}
      >
        <SvgFile size={24} name="info" color={tipColor ? tipColor : '$primary'} />
        {children ? (
          children
        ) : (
          <View flex={1}>
            <YStack gap="$1">
              <Label3 color="$primary" variant="medium">
                {t('HOME.VERIFICATION.PLEASE_NOTE')}
              </Label3>
              <Label3>{text}</Label3>
            </YStack>
          </View>
        )}
      </XStack>
    );
  }
);
