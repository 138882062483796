import {Heading4} from '@/components/texts/Heading';
import {AddressDetailsSchemaType} from '@/hooks/useValidations';
import {SubmitFormRef} from '@/modules/interview/components/FinancialSituationForm';
import {InterviewContinueButton} from '@/modules/interview/components/InterviewContinueButton';
import {UserAddressSameAddressModal} from '@/modules/onboarding/components/address/UserAddressSameAddressModal';
import {UserAddressForm} from '@/modules/onboarding/components/forms/UserAddressForm';
import {
  useGetSelfUserQuery,
  useGetSpecificUserQuery,
  useUpdateUserMutation,
} from '@/store/queries/usersApi';
import {UserResponseV3} from '@/types/api/users';
import {ModalRef} from '@/types/modal';
import {skipToken} from '@reduxjs/toolkit/query';
import {useTranslate} from '@tolgee/react';
import {Href, router} from 'expo-router';
import React, {useCallback, useRef, useState} from 'react';
import {YStack} from 'tamagui';

export function UserAddressScreen({
  href,
  childScreenHref,
  childID,
  isChildScreen,
}: {
  href: Href;
  childScreenHref: Href;
  childID?: string;
  isChildScreen: boolean;
}) {
  const ref = useRef<SubmitFormRef>(null);
  const modalRef = useRef<ModalRef>(null);
  const {t} = useTranslate();
  const [updateUser] = useUpdateUserMutation();
  const {data: selfUser} = useGetSelfUserQuery();
  const {data: childUser} = useGetSpecificUserQuery(childID ? {userID: childID} : skipToken);
  const user = isChildScreen ? childUser : selfUser;
  const [isLoading, setIsLoading] = useState(false);

  const onPress = useCallback(async () => {
    await ref.current?.submit();
  }, []);

  const patchUser = useCallback(
    async (data: AddressDetailsSchemaType) => {
      const {street, streetNumber, postCode, city, countryCode, phoneNumber} = data;
      const address = {street, streetNumber, postCode, city, countryCode};
      let changes: Partial<UserResponseV3> = {address};
      if (phoneNumber && !phoneNumber.includes('X')) {
        changes = {...changes, phoneNumber: phoneNumber.replace(/\s/g, '')};
      }
      if (user) {
        await updateUser({
          currentUser: user,
          changes,
        });
      }
      if (!isChildScreen && childID) {
        modalRef.current?.open(true);
      } else {
        router.push(href);
      }
    },
    [childID, href, isChildScreen, updateUser, user]
  );

  const onNo = useCallback(async () => {
    router.push(childScreenHref);
  }, [childScreenHref]);

  const onYes = useCallback(async () => {
    if (!childUser) return; // TODO: we should maybe show loading indicator on button in this case?

    const changes: Partial<UserResponseV3> = {address: user?.address};
    await updateUser({
      currentUser: childUser,
      changes,
    });

    router.push(href);
  }, [childUser, user?.address, updateUser, href]);

  const onValidSubmit = useCallback(
    async (data: AddressDetailsSchemaType) => {
      if (isChildScreen) {
        const {phoneNumber, ...rest} = data;

        await patchUser(rest);

        if (isChildScreen) {
          router.push(href);
        }
      } else {
        await patchUser(data);
      }
    },
    [href, isChildScreen, patchUser]
  );

  return (
    <YStack gap="$6" marginTop="$6">
      <Heading4 variant="medium">
        {isChildScreen
          ? t('ONBOARDING.CHILD.WHATS_YOUR_ADDRESS', {firstName: childUser?.firstName || ''})
          : t('ONBOARDING.WHATS_YOUR_ADDRESS')}
      </Heading4>

      <UserAddressForm
        ref={ref}
        onValid={onValidSubmit}
        user={user}
        child={isChildScreen}
        setIsLoading={setIsLoading}
      />

      <InterviewContinueButton isLoading={isLoading} onPress={onPress} />

      {childUser && (
        <UserAddressSameAddressModal
          ref={modalRef}
          childUser={childUser}
          onNo={onNo}
          onYes={onYes}
        />
      )}
    </YStack>
  );
}
