import {FriendsRecommendationCard} from '@/modules/recommendations/components/FriendsRecommendationCard';
import {MfaRecommendationCard} from '@/modules/recommendations/components/MfaRecommendationCard';
import {SavingsrateRecommendationCard} from '@/modules/recommendations/components/SavingsrateRecommendationCard';
import {TopzinsRecommendationCard} from '@/modules/recommendations/components/TopzinsRecommendationCard';
import {useRecommendations} from '@/modules/recommendations/hooks/useRecommendations';
import {AnimatePresence, View} from 'tamagui';

export type RecommendationsProps = {
  type: 'smallVertical' | 'smallHorizontal' | 'regular';
};

export function Recommendations({type}: RecommendationsProps) {
  const {mfa, topzins, savingrate, friends} = useRecommendations();

  return (
    <View
      gap={type === 'smallVertical' ? '$3' : '$4'}
      flexDirection={type === 'smallVertical' ? 'column' : 'row'}
      flexWrap={type !== 'regular' ? 'unset' : 'wrap'}
    >
      <AnimatePresence>
        {((mfa.condition && !mfa.dismissed && !mfa.loading) || type === 'regular') && (
          <MfaRecommendationCard key="mfa-recommendation" type={type} />
        )}
        {((topzins.condition && !topzins.dismissed && !topzins.loading) || type === 'regular') && (
          <TopzinsRecommendationCard key="topzins-recommendation" type={type} />
        )}
        {((savingrate.condition && !savingrate.dismissed && !savingrate.loading) ||
          type === 'regular') && (
          <SavingsrateRecommendationCard key="savings-recommendation" type={type} />
        )}
        {((friends.condition && !friends.dismissed && !friends.loading) || type === 'regular') && (
          <FriendsRecommendationCard key="friends-recommendation" type={type} />
        )}
      </AnimatePresence>
    </View>
  );
}
