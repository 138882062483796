import React, {PropsWithChildren} from 'react';
import {YStack} from 'tamagui';

export function FullScreenCenterView({children}: PropsWithChildren) {
  return (
    <YStack
      alignItems="center"
      justifyContent="center"
      flex={1}
      maxWidth={800}
      alignSelf="center"
      gap="$6"
      paddingHorizontal="$4"
      width="100%"
    >
      {children}
    </YStack>
  );
}
