import {MONTHS} from '@/constants/date-time';
import {useTranslate} from '@tolgee/react';
import {useMemo} from 'react';

export function useLocalizedMonths() {
  const {t} = useTranslate();
  return useMemo(() => {
    return MONTHS.map(({key, translationKey}) => ({name: t(translationKey), key}));
  }, [t]);
}
