import {useAppSelector} from '@/store';

export default function useExistingInitialDepositAnswers() {
  const answers = useAppSelector(state => state.interview);
  if (!answers) return {};
  return JSON.parse(
    JSON.stringify(
      (({INITIAL_DEPOSIT, INITIAL_MONTHLY_SAVINGS}) => ({
        INITIAL_DEPOSIT,
        INITIAL_MONTHLY_SAVINGS,
      }))(answers)
    )
  );
}
