import {Group, Line, Skia, useFonts, vec} from '@shopify/react-native-skia';
import {SkTextStyle} from '@shopify/react-native-skia/src/skia/types/Paragraph/TextStyle';
import {useEffect, useId, useMemo, useState} from 'react';
import {getTokens} from 'tamagui';

import {CommonAxisProps, NumberTuple, Size, YAxisPosition} from '@/types/skia';
import {Text} from './Text';
import {useChartContext} from './context';
import {useAxisUtils} from './hooks';
import {createParagraph, isSameDomain} from './utils';

type Props = {
  position: YAxisPosition;
} & CommonAxisProps;

export const YAxis = ({
  position,
  fontSize = 14,
  grid,
  color,
  getTexLabel,
  countTicks = 4,
  nice = true,
  blur,
  time = false,
}: Props) => {
  const {size, xAxis, yAxis, updateAxis, offsetData, discreteMode, theme} = useChartContext();

  const [maxTextWidth, setMaxTextWidth] = useState(0);

  const tokens = getTokens();

  const commonMargin = tokens.size['1']?.val;

  const {axisTicks, createTickText} = useAxisUtils(false, time, countTicks, nice);

  const fonts = useFonts({
    // @ts-ignore
    'Inter-Regular': [require('@tamagui/font-inter/otf/Inter-Regular.otf')],
  });

  const textColor = useMemo(() => color ?? theme.text?.val ?? 'black', [theme, color]);
  const shouldBlur = useMemo(() => !!discreteMode && !!blur, [discreteMode, blur]);

  const commonTextStyles = useMemo<SkTextStyle>(
    () => ({
      fontSize,
      color: Skia.Color(textColor),
      fontFamilies: ['Inter-Regular'],
    }),
    [fontSize, textColor]
  );

  const yAxisKey = useId();

  useEffect(() => {
    return () => {
      updateAxis(position);
    };
  }, [position]);

  useEffect(() => {
    const size: Size = {
      width: maxTextWidth + 2 * commonMargin,
      height: 0,
    };

    updateAxis(position, {size});
  }, [maxTextWidth, commonMargin, position, updateAxis]);

  useEffect(() => {
    if (axisTicks.length === 0) return;

    const first = axisTicks.at(0);
    const last = axisTicks.at(-1);

    if (!first || !last || first.tick === last.tick) return;

    const yAxisTuple: NumberTuple = [first.tick, last.tick];
    const currAxis = yAxis[position];

    if (!!currAxis?.axisDomain && isSameDomain(yAxisTuple, currAxis.axisDomain)) return;

    updateAxis(position, {axisDomain: yAxisTuple});
  }, [axisTicks, yAxis, updateAxis, position]);

  useEffect(() => {
    if (!fonts || axisTicks.length === 0) {
      setMaxTextWidth(0);

      return;
    }

    const text = axisTicks
      .map((tick, index, self) => createTickText(tick.tick, index, self, getTexLabel) ?? '')
      .filter(text => !!text)
      .join('\n');

    const paragraph = createParagraph(fonts, commonTextStyles, text, size.width);
    const maxTextWidth = paragraph.getLongestLine();

    setMaxTextWidth(maxTextWidth + 1);
  }, [fonts, axisTicks, createTickText, getTexLabel, size.width, commonTextStyles, commonMargin]);

  const translateY = useMemo(() => {
    const topXAxisOffset = xAxis?.top?.size?.height ?? 0;
    const commonOffset = offsetData.pointSize / 2 + offsetData.width / 2;

    return topXAxisOffset + commonOffset;
  }, [xAxis?.top, offsetData]);

  if (!fonts) return null;

  return (
    <Group transform={[{translateY}]}>
      {axisTicks.map((tick, index, self) => {
        const text = createTickText(tick.tick, index, self, getTexLabel);

        if (!text) return null;

        const {left, right} = yAxis ?? {};

        const leftYAxisWidth = left?.size?.width ?? 0;
        const rightYAxisWidth = right?.size?.width ?? 0;

        const paragraph = createParagraph(fonts, commonTextStyles, text, maxTextWidth);
        const width = paragraph.getLongestLine();
        const height = paragraph.getHeight();

        let xText: number;

        if (position === 'left') {
          xText = maxTextWidth - width;
        } else {
          xText = size.width - maxTextWidth;
        }

        const gridSize = grid?.size ?? 0;
        let yText = tick.position - height / 2;
        let lineY = tick.position;

        if (index === 0) {
          const endLine = tick.position + gridSize / 2 + translateY;
          const endText = tick.position + height / 2 + translateY;

          yText = endText > size.height ? size.height - height - translateY : yText;
          lineY = endLine > size.height ? size.height - gridSize - translateY : lineY;
        }

        if (index === self.length - 1) {
          const startLine = tick.position - gridSize / 2 - translateY;

          yText = yText < 0 ? 0 : yText;
          lineY = startLine < 0 ? (translateY > 0 ? tick.position : gridSize) : lineY;
        }

        const lineP1x =
          position === 'left'
            ? maxTextWidth + 2 * commonMargin
            : size.width - maxTextWidth - 2 * commonMargin;
        const lineP2x = position === 'left' ? size.width - rightYAxisWidth : leftYAxisWidth;

        return (
          <Group key={`${yAxisKey}-Y-AXIS-${position}-${index}`}>
            {grid && (
              <Line
                p1={vec(lineP1x, lineY)}
                p2={vec(lineP2x, lineY)}
                color={grid.color}
                style="stroke"
                strokeWidth={grid.size}
              />
            )}
            <Text paragraph={paragraph} x={xText} y={yText} blur={shouldBlur} />
          </Group>
        );
      })}
    </Group>
  );
};
