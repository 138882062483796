import {Label1} from '@/components/texts/Label';
import {Skeleton} from '@/components/views/Skeleton';
import {RatioItemRow} from '@/modules/portfolio/components/RatioItemRow';
import {RatioItemRows} from '@/modules/portfolio/components/RatioItemRows';
import {useGetAllocationsRatios} from '@/modules/portfolio/hooks/useGetAssetAllocation';
import {GroupType} from '@/modules/portfolio/types/GroupType';
import {useGetPerformanceSummaryQuery} from '@/store/queries/apeironApi';
import {skipToken} from '@reduxjs/toolkit/query';
import {useTranslate} from '@tolgee/react';
import {useMemo} from 'react';
import {View, XStack, YStack} from 'tamagui';
import {BarLineSegments} from './BarLineSegments';

type CustomerAllocation = {
  allocations?: never;
  customerId: string;
};

type OnboardingAllocation = {
  allocations: Required<Parameters<typeof useGetAllocationsRatios>['0']>['allocations'];
  customerId?: never;
};

type AllocationBarChartProps = {
  preview?: boolean;
  groupType: GroupType;
} & (OnboardingAllocation | CustomerAllocation);

export function AllocationBarChart({
  groupType,
  customerId,
  allocations,
  preview = false,
}: AllocationBarChartProps) {
  const {t} = useTranslate();
  const {data: performanceSummary, isFetching} = useGetPerformanceSummaryQuery(
    customerId ? {customerId} : skipToken
  );
  const resolvedAllocations = performanceSummary?.allocation || allocations;
  const ratios = useGetAllocationsRatios({
    groupType,
    allocations: resolvedAllocations,
  });
  const hasAmount = useMemo(() => Boolean(resolvedAllocations?.[0]?.amount), [resolvedAllocations]);

  if (ratios === undefined || isFetching) {
    return (
      <>
        <XStack gap="$2">
          <View flex={5}>
            <Skeleton width="100%" />
          </View>
          <View flex={3}>
            <Skeleton width="100%" />
          </View>
          <View flex={2}>
            <Skeleton width="100%" />
          </View>
          <View flex={1}>
            <Skeleton width="100%" />
          </View>
          <View flex={1}>
            <Skeleton width="100%" />
          </View>
        </XStack>

        <XStack gap="$4">
          <View flex={1}>
            <Skeleton width="100%" />
          </View>
          <View flex={1}>
            <Skeleton width="100%" />
          </View>
        </XStack>
        <XStack gap="$4">
          <View flex={1}>
            <Skeleton width="100%" />
          </View>
          <View flex={1}>
            <Skeleton width="100%" />
          </View>
        </XStack>
      </>
    );
  }

  return (
    <YStack gap="$4" $sm={{gap: '$2'}} flex={1} flexBasis="auto">
      <BarLineSegments items={ratios} />
      <View gap="$4" $sm={{gap: '$0'}}>
        <RatioItemRows ratios={ratios} preview={preview} />
      </View>
      {!preview && hasAmount && performanceSummary && (
        <YStack>
          <RatioItemRow
            ratio={1}
            amount={performanceSummary.balance - performanceSummary.liquidity}
            title={t('PORTFOLIO.INVESTMENT_AMOUNT')}
            color="transparent"
            bottomRow
          />
          <RatioItemRow
            amount={performanceSummary.liquidity}
            title={t('PORTFOLIO.LIQUIDITY')}
            color="transparent"
            bottomRow
          />
          <RatioItemRow
            amount={performanceSummary.balance}
            title={t('PORTFOLIO.AUM')}
            color="transparent"
            textComponent={Label1}
            numberStyle={{variant: 'medium'}}
            bottomRow
            wrapperProps={{paddingBottom: 0}}
          />
        </YStack>
      )}
    </YStack>
  );
}
