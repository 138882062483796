import {PropsWithChildren, createContext, useContext} from 'react';

export type ContextValue = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onDismiss?: () => void;
  controllable: boolean;
};

const ModalBottomSheetContext = createContext<ContextValue | undefined>(undefined);

export const useBottomSheetContext = () => {
  const context = useContext(ModalBottomSheetContext);

  if (!context) {
    throw new Error('ModalSheetContext must be defined');
  }

  return context;
};

type ContextProviderProps = {
  value: ContextValue;
};

export const ModalBottomSheetProvider = ({
  children,
  value,
}: PropsWithChildren<ContextProviderProps>) => (
  <ModalBottomSheetContext.Provider value={value}>{children}</ModalBottomSheetContext.Provider>
);
