import SvgFile from '@/components/icons/SvgFile';
import {FormCustomCheckbox} from '@/components/inputs/form/FormCustomCheckbox';
import {Modal} from '@/components/modal/Modal';
import {Label2} from '@/components/texts/Label';
import {AgreementsSchemaType} from '@/hooks/useValidations';
import {UpvestSepaContent} from '@/modules/onboarding/components/forms/agreements/UpvestSepaContent';
import {useTranslate} from '@tolgee/react';
import {openBrowserAsync} from 'expo-web-browser';
import {Control} from 'react-hook-form';
import {XStack, YStack} from 'tamagui';

export function UpvestAgreement({control}: {control: Control<AgreementsSchemaType>}) {
  const {t} = useTranslate();

  return (
    <FormCustomCheckbox
      control={control}
      name="upvestTerms"
      label={
        <Label2 variant="medium">
          {t('ACCOUNT_SETUP.AGREEMENTS.UPVEST.TITLE_PREFIX')}
          <Label2
            variant="medium"
            color="$primary"
            cursor="pointer"
            onPress={e => {
              e.stopPropagation();
              e.preventDefault();
              openBrowserAsync(process.env.EXPO_PUBLIC_UPVEST_DOC5);
            }}
          >
            {` ${t('ACCOUNT_SETUP.AGREEMENTS.UPVEST.TITLE_DOCUMENT')} `}
          </Label2>
          {t('ACCOUNT_SETUP.AGREEMENTS.UPVEST.TITLE_SUFFIX')}
        </Label2>
      }
    >
      <YStack paddingLeft={36} gap="$4">
        <XStack
          gap="$2"
          cursor="pointer"
          onPress={() => openBrowserAsync(process.env.EXPO_PUBLIC_UPVEST_DOC1)}
        >
          <SvgFile name="document" size={20} color="$neutral700" />
          <Label2 variant="medium" color="$primary">
            {t('ACCOUNT_SETUP.AGREEMENTS.UPVEST.DOC_1')}
          </Label2>
        </XStack>
        <XStack
          gap="$2"
          cursor="pointer"
          onPress={() => openBrowserAsync(process.env.EXPO_PUBLIC_UPVEST_DOC2)}
        >
          <SvgFile name="document" size={20} color="$neutral700" />
          <Label2 variant="medium" color="$primary">
            {t('ACCOUNT_SETUP.AGREEMENTS.UPVEST.DOC_2')}
          </Label2>
        </XStack>
        <XStack
          gap="$2"
          cursor="pointer"
          onPress={() => openBrowserAsync(process.env.EXPO_PUBLIC_UPVEST_DOC3)}
        >
          <SvgFile name="document" size={20} color="$neutral700" />
          <Label2 variant="medium" color="$primary">
            {t('ACCOUNT_SETUP.AGREEMENTS.UPVEST.DOC_3')}
          </Label2>
        </XStack>
        <Modal>
          <Modal.Trigger asChild>
            <XStack gap="$2">
              <SvgFile name="document" size={20} color="$neutral700" />
              <Label2 variant="medium" color="$primary">
                {t('ACCOUNT_SETUP.AGREEMENTS.GINMON.SEPA')}
              </Label2>
            </XStack>
          </Modal.Trigger>
          <Modal.Content side>
            <UpvestSepaContent />
          </Modal.Content>
        </Modal>

        <XStack
          gap="$2"
          cursor="pointer"
          onPress={() => openBrowserAsync(process.env.EXPO_PUBLIC_UPVEST_DOC4)}
        >
          <SvgFile name="document" size={20} color="$neutral700" />
          <Label2 variant="medium" color="$primary">
            {t('ACCOUNT_SETUP.AGREEMENTS.UPVEST.DOC_4')}
          </Label2>
        </XStack>
        <XStack
          gap="$2"
          cursor="pointer"
          onPress={() => openBrowserAsync(process.env.EXPO_PUBLIC_UPVEST_DOC6)}
        >
          <SvgFile name="document" size={20} color="$neutral700" />
          <Label2 variant="medium" color="$primary">
            {t('ACCOUNT_SETUP.AGREEMENTS.UPVEST.DOC_6')}
          </Label2>
        </XStack>
      </YStack>
    </FormCustomCheckbox>
  );
}
