._ovs-contain {overscroll-behavior:contain;}
  .is_Text .is_Text {display:inline-flex;}
  ._dsp_contents {display:contents;}
  :root {--t-size-0:0px;--t-size-1:4px;--t-size-2:8px;--t-size-3:12px;--t-size-4:16px;--t-size-5:20px;--t-size-6:24px;--t-size-7:32px;--t-size-8:48px;--t-size-9:64px;--t-size-10:80px;--t-size-0--25:1px;--t-size-0--5:2px;--t-size-1--5:6px;--t-size-true:8px;--t-space-0:0px;--t-space-1:4px;--t-space-2:8px;--t-space-3:12px;--t-space-4:16px;--t-space-5:20px;--t-space-6:24px;--t-space-7:32px;--t-space-8:48px;--t-space-9:64px;--t-space-10:80px;--t-space-0--25:1px;--t-space-0--5:2px;--t-space-1--5:6px;--t-space-true:8px;--t-radius-0:0px;--t-radius-1:16px;--t-radius-2:18px;--t-radius-3:20px;--t-radius-0--25:6px;--t-radius-0--5:8px;--t-radius-0--75:12px;--t-radius-full:100000000px;--t-zIndex-0:0;--t-zIndex-1:100;--t-zIndex-2:200}
:root .font_heading, :root .t_lang-heading-default .font_heading {--f-weight-1:normal;--f-weight-2:normal;--f-weight-3:normal;--f-weight-4:normal;--f-weight-5:normal;--f-weight-6:normal;--f-weight-7:normal;--f-weight-8:normal;--f-weight-9:normal;--f-weight-10:normal;--f-weight-11:normal;--f-weight-12:normal;--f-weight-13:normal;--f-weight-14:normal;--f-weight-15:normal;--f-weight-16:normal;--f-weight-true:normal;--f-weight-normal:normal;--f-lineHeight-1:22px;--f-lineHeight-2:23px;--f-lineHeight-3:24px;--f-lineHeight-4:25px;--f-lineHeight-5:24px;--f-lineHeight-6:27px;--f-lineHeight-7:32px;--f-lineHeight-8:35px;--f-lineHeight-9:40px;--f-lineHeight-10:53px;--f-lineHeight-11:66px;--f-lineHeight-12:73px;--f-lineHeight-13:84px;--f-lineHeight-14:106px;--f-lineHeight-15:130px;--f-lineHeight-16:152px;--f-lineHeight-true:25px;--f-letterSpacing-1:2px;--f-letterSpacing-2:2px;--f-letterSpacing-3:2px;--f-letterSpacing-4:2px;--f-letterSpacing-5:2px;--f-letterSpacing-6:1px;--f-letterSpacing-7:0px;--f-letterSpacing-8:0px;--f-letterSpacing-9:-1px;--f-letterSpacing-10:-1.5px;--f-letterSpacing-11:-1.5px;--f-letterSpacing-12:-2px;--f-letterSpacing-13:-2px;--f-letterSpacing-14:-3px;--f-letterSpacing-15:-4px;--f-letterSpacing-16:-4px;--f-letterSpacing-true:-4px;--f-size-1:11px;--f-size-2:12px;--f-size-3:13px;--f-size-4:14px;--f-size-5:13px;--f-size-6:15px;--f-size-7:20px;--f-size-8:23px;--f-size-9:32px;--f-size-10:44px;--f-size-11:55px;--f-size-12:62px;--f-size-13:72px;--f-size-14:92px;--f-size-15:114px;--f-size-16:134px;--f-size-true:14px;--f-family:InterRegular}
:root .font_headingMedium, :root .t_lang-headingMedium-default .font_headingMedium {--f-weight-1:normal;--f-weight-2:normal;--f-weight-3:normal;--f-weight-4:normal;--f-weight-5:normal;--f-weight-6:normal;--f-weight-7:normal;--f-weight-8:normal;--f-weight-9:normal;--f-weight-10:normal;--f-weight-11:normal;--f-weight-12:normal;--f-weight-13:normal;--f-weight-14:normal;--f-weight-15:normal;--f-weight-16:normal;--f-weight-true:normal;--f-weight-normal:normal;--f-lineHeight-1:22px;--f-lineHeight-2:23px;--f-lineHeight-3:24px;--f-lineHeight-4:25px;--f-lineHeight-5:24px;--f-lineHeight-6:27px;--f-lineHeight-7:32px;--f-lineHeight-8:35px;--f-lineHeight-9:40px;--f-lineHeight-10:53px;--f-lineHeight-11:66px;--f-lineHeight-12:73px;--f-lineHeight-13:84px;--f-lineHeight-14:106px;--f-lineHeight-15:130px;--f-lineHeight-16:152px;--f-lineHeight-true:25px;--f-letterSpacing-1:2px;--f-letterSpacing-2:2px;--f-letterSpacing-3:2px;--f-letterSpacing-4:2px;--f-letterSpacing-5:2px;--f-letterSpacing-6:1px;--f-letterSpacing-7:0px;--f-letterSpacing-8:0px;--f-letterSpacing-9:-1px;--f-letterSpacing-10:-1.5px;--f-letterSpacing-11:-1.5px;--f-letterSpacing-12:-2px;--f-letterSpacing-13:-2px;--f-letterSpacing-14:-3px;--f-letterSpacing-15:-4px;--f-letterSpacing-16:-4px;--f-letterSpacing-true:-4px;--f-size-1:11px;--f-size-2:12px;--f-size-3:13px;--f-size-4:14px;--f-size-5:13px;--f-size-6:15px;--f-size-7:20px;--f-size-8:23px;--f-size-9:32px;--f-size-10:44px;--f-size-11:55px;--f-size-12:62px;--f-size-13:72px;--f-size-14:92px;--f-size-15:114px;--f-size-16:134px;--f-size-true:14px;--f-family:InterMedium}
:root .font_headingBold, :root .t_lang-headingBold-default .font_headingBold {--f-weight-1:normal;--f-weight-2:normal;--f-weight-3:normal;--f-weight-4:normal;--f-weight-5:normal;--f-weight-6:normal;--f-weight-7:normal;--f-weight-8:normal;--f-weight-9:normal;--f-weight-10:normal;--f-weight-11:normal;--f-weight-12:normal;--f-weight-13:normal;--f-weight-14:normal;--f-weight-15:normal;--f-weight-16:normal;--f-weight-true:normal;--f-weight-normal:normal;--f-lineHeight-1:22px;--f-lineHeight-2:23px;--f-lineHeight-3:24px;--f-lineHeight-4:25px;--f-lineHeight-5:24px;--f-lineHeight-6:27px;--f-lineHeight-7:32px;--f-lineHeight-8:35px;--f-lineHeight-9:40px;--f-lineHeight-10:53px;--f-lineHeight-11:66px;--f-lineHeight-12:73px;--f-lineHeight-13:84px;--f-lineHeight-14:106px;--f-lineHeight-15:130px;--f-lineHeight-16:152px;--f-lineHeight-true:25px;--f-letterSpacing-1:2px;--f-letterSpacing-2:2px;--f-letterSpacing-3:2px;--f-letterSpacing-4:2px;--f-letterSpacing-5:2px;--f-letterSpacing-6:1px;--f-letterSpacing-7:0px;--f-letterSpacing-8:0px;--f-letterSpacing-9:-1px;--f-letterSpacing-10:-1.5px;--f-letterSpacing-11:-1.5px;--f-letterSpacing-12:-2px;--f-letterSpacing-13:-2px;--f-letterSpacing-14:-3px;--f-letterSpacing-15:-4px;--f-letterSpacing-16:-4px;--f-letterSpacing-true:-4px;--f-size-1:11px;--f-size-2:12px;--f-size-3:13px;--f-size-4:14px;--f-size-5:13px;--f-size-6:15px;--f-size-7:20px;--f-size-8:23px;--f-size-9:32px;--f-size-10:44px;--f-size-11:55px;--f-size-12:62px;--f-size-13:72px;--f-size-14:92px;--f-size-15:114px;--f-size-16:134px;--f-size-true:14px;--f-family:InterBold}
:root .font_body, :root .t_lang-body-default .font_body {--f-weight-1:normal;--f-weight-2:normal;--f-weight-3:normal;--f-weight-4:normal;--f-weight-5:normal;--f-weight-6:normal;--f-weight-7:normal;--f-weight-8:normal;--f-weight-9:normal;--f-weight-10:normal;--f-weight-11:normal;--f-weight-12:normal;--f-weight-13:normal;--f-weight-14:normal;--f-weight-15:normal;--f-weight-16:normal;--f-weight-true:normal;--f-weight-normal:normal;--f-lineHeight-1:16px;--f-lineHeight-2:21px;--f-lineHeight-3:22px;--f-lineHeight-4:23px;--f-lineHeight-5:26px;--f-lineHeight-6:28px;--f-lineHeight-7:30px;--f-lineHeight-8:33px;--f-lineHeight-9:41px;--f-lineHeight-10:59px;--f-lineHeight-11:69px;--f-lineHeight-12:76px;--f-lineHeight-13:87px;--f-lineHeight-14:109px;--f-lineHeight-15:133px;--f-lineHeight-16:155px;--f-lineHeight-true:23px;--f-letterSpacing-1:0px;--f-letterSpacing-2:0px;--f-letterSpacing-3:0px;--f-letterSpacing-4:0px;--f-letterSpacing-5:0px;--f-letterSpacing-6:0px;--f-letterSpacing-7:0px;--f-letterSpacing-8:0px;--f-letterSpacing-9:0px;--f-letterSpacing-10:0px;--f-letterSpacing-11:0px;--f-letterSpacing-12:0px;--f-letterSpacing-13:0px;--f-letterSpacing-14:0px;--f-letterSpacing-15:0px;--f-letterSpacing-16:0px;--f-letterSpacing-true:0px;--f-size-1:11px;--f-size-2:12px;--f-size-3:13px;--f-size-4:14px;--f-size-5:16px;--f-size-6:18px;--f-size-7:20px;--f-size-8:23px;--f-size-9:30px;--f-size-10:46px;--f-size-11:55px;--f-size-12:62px;--f-size-13:72px;--f-size-14:92px;--f-size-15:114px;--f-size-16:134px;--f-size-true:14px;--f-family:InterRegular}
:root .font_bodyMedium, :root .t_lang-bodyMedium-default .font_bodyMedium {--f-weight-1:normal;--f-weight-2:normal;--f-weight-3:normal;--f-weight-4:normal;--f-weight-5:normal;--f-weight-6:normal;--f-weight-7:normal;--f-weight-8:normal;--f-weight-9:normal;--f-weight-10:normal;--f-weight-11:normal;--f-weight-12:normal;--f-weight-13:normal;--f-weight-14:normal;--f-weight-15:normal;--f-weight-16:normal;--f-weight-true:normal;--f-weight-normal:normal;--f-lineHeight-1:16px;--f-lineHeight-2:21px;--f-lineHeight-3:22px;--f-lineHeight-4:23px;--f-lineHeight-5:26px;--f-lineHeight-6:28px;--f-lineHeight-7:30px;--f-lineHeight-8:33px;--f-lineHeight-9:41px;--f-lineHeight-10:59px;--f-lineHeight-11:69px;--f-lineHeight-12:76px;--f-lineHeight-13:87px;--f-lineHeight-14:109px;--f-lineHeight-15:133px;--f-lineHeight-16:155px;--f-lineHeight-true:23px;--f-letterSpacing-1:0px;--f-letterSpacing-2:0px;--f-letterSpacing-3:0px;--f-letterSpacing-4:0px;--f-letterSpacing-5:0px;--f-letterSpacing-6:0px;--f-letterSpacing-7:0px;--f-letterSpacing-8:0px;--f-letterSpacing-9:0px;--f-letterSpacing-10:0px;--f-letterSpacing-11:0px;--f-letterSpacing-12:0px;--f-letterSpacing-13:0px;--f-letterSpacing-14:0px;--f-letterSpacing-15:0px;--f-letterSpacing-16:0px;--f-letterSpacing-true:0px;--f-size-1:11px;--f-size-2:12px;--f-size-3:13px;--f-size-4:14px;--f-size-5:16px;--f-size-6:18px;--f-size-7:20px;--f-size-8:23px;--f-size-9:30px;--f-size-10:46px;--f-size-11:55px;--f-size-12:62px;--f-size-13:72px;--f-size-14:92px;--f-size-15:114px;--f-size-16:134px;--f-size-true:14px;--f-family:InterMedium}
:root .font_bodyBold, :root .t_lang-bodyBold-default .font_bodyBold {--f-weight-1:normal;--f-weight-2:normal;--f-weight-3:normal;--f-weight-4:normal;--f-weight-5:normal;--f-weight-6:normal;--f-weight-7:normal;--f-weight-8:normal;--f-weight-9:normal;--f-weight-10:normal;--f-weight-11:normal;--f-weight-12:normal;--f-weight-13:normal;--f-weight-14:normal;--f-weight-15:normal;--f-weight-16:normal;--f-weight-true:normal;--f-weight-normal:normal;--f-lineHeight-1:16px;--f-lineHeight-2:21px;--f-lineHeight-3:22px;--f-lineHeight-4:23px;--f-lineHeight-5:26px;--f-lineHeight-6:28px;--f-lineHeight-7:30px;--f-lineHeight-8:33px;--f-lineHeight-9:41px;--f-lineHeight-10:59px;--f-lineHeight-11:69px;--f-lineHeight-12:76px;--f-lineHeight-13:87px;--f-lineHeight-14:109px;--f-lineHeight-15:133px;--f-lineHeight-16:155px;--f-lineHeight-true:23px;--f-letterSpacing-1:0px;--f-letterSpacing-2:0px;--f-letterSpacing-3:0px;--f-letterSpacing-4:0px;--f-letterSpacing-5:0px;--f-letterSpacing-6:0px;--f-letterSpacing-7:0px;--f-letterSpacing-8:0px;--f-letterSpacing-9:0px;--f-letterSpacing-10:0px;--f-letterSpacing-11:0px;--f-letterSpacing-12:0px;--f-letterSpacing-13:0px;--f-letterSpacing-14:0px;--f-letterSpacing-15:0px;--f-letterSpacing-16:0px;--f-letterSpacing-true:0px;--f-size-1:11px;--f-size-2:12px;--f-size-3:13px;--f-size-4:14px;--f-size-5:16px;--f-size-6:18px;--f-size-7:20px;--f-size-8:23px;--f-size-9:30px;--f-size-10:46px;--f-size-11:55px;--f-size-12:62px;--f-size-13:72px;--f-size-14:92px;--f-size-15:114px;--f-size-16:134px;--f-size-true:14px;--f-family:InterBold}
  :root .t_dark .t_light , :root .t_dark .t_light .t_dark .t_light , :root .t_light, :root .t_light , :root .t_light .t_dark .t_light , .tm_xxt {--primaryGradientEnd:#5388FF;--primary:#2663EB;--primaryHover:#5085f9;--primaryPressed:#114ac7;--primaryTransparent:#f5f8ff;--primarySkeleton:#1557e5;--neutral0:#ffffff;--neutral50:#f6f8fc;--neutral100:#eff1f6;--neutral200:#dcdee4;--neutral300:#b6b9c2;--neutral400:#999ca2;--neutral500:#80848a;--neutral600:#5a5c63;--neutral700:#3d3f47;--neutral800:#2b2e36;--neutral900:#22252e;--neutral950:#161a23;--neutralBG:#fafafa;--text:#22252e;--surface:#ffffff;--surfaceRaised:#fafafa;--surfaceHover:#fcfcfc;--shadow:#e0e8eb;--white:#ffffff;--white50:#ffffff32;--white10:#ffffff1A;--green:#39bf6f;--greenDark:#1e9a50;--greenLight:#93dfb2;--greenTransparent:#f3fcf7;--emerald:#3ecea1;--emeraldDark:#2db58b;--emeraldLight:#9fe7d1;--emeraldTransparent:#f5fffc;--red:#ff4d4d;--redDark:#ef3c3a;--redLight:#ffbcbc;--redTransparent:#fef7f6;--orange:#ffa64d;--orangeDark:#ff8a15;--orangeLight:#ffe1c2;--orangeTransparent:#fff9f2;--yellow:#ffd74d;--yellowDark:#f5c125;--yellowLight:#fff3c7;--yellowTransparent:#FFFDF5;--violet:#6f4bf9;--violetDark:#461cea;--violetLight:#d1c5fd;--violetTransparent:#f6f4fe;--pink:#d64dff;--pinkDark:#c914ff;--pinkLight:#f1c2ff;--pinkTransparent:#fdf5ff;--cerulean:#4b72fc;--ceruleanDark:#1543e6;--ceruleanLight:#c2d0fe;--ceruleanTransparent:#f5f7ff;}
:root .t_dark, :root .t_dark , :root .t_dark .t_light .t_dark , :root .t_light .t_dark , :root .t_light .t_dark .t_light .t_dark , .tm_xxt {--primaryGradientEnd:#5388FF;--primary:#4779eb;--primaryHover:#6f9cff;--primaryPressed:#2a4073;--primaryTransparent:#262d41;--primarySkeleton:#3068e8;--neutral0:#22252e;--neutral50:#2b2e36;--neutral100:#3d3f47;--neutral200:#5a5c63;--neutral300:#80848a;--neutral400:#999ca2;--neutral500:#b6b9c2;--neutral600:#dcdee4;--neutral700:#eff1f6;--neutral800:#f6f8fc;--neutral900:#ffffff;--neutral950:#ffffff;--neutralBG:#161a23;--text:#f6f8fc;--surface:#22252e;--surfaceHover:#2b2f3b;--surfaceRaised:#2b2e36;--shadow:#161a23;--white:#ffffff;--white50:#ffffff32;--white10:#ffffff1A;--green:#54bf7f;--greenDark:#74d69b;--greenLight:#2f5c48;--greenTransparent:#273436;--emerald:#5bceaa;--emeraldDark:#7fdfc0;--emeraldLight:#316259;--emeraldTransparent:#28363a;--red:#ff7070;--redDark:#ff9f9f;--redLight:#733c42;--redTransparent:#382d35;--orange:#ffb870;--orangeDark:#ffcf9f;--orangeLight:#735942;--orangeTransparent:#383435;--yellow:#ffdf70;--yellowDark:#ffe99f;--yellowLight:#736942;--yellowTransparent:#383835;--violet:#8a6ef9;--violetDark:#ae9afb;--violetLight:#453b79;--violetTransparent:#2c2c42;--pink:#de70ff;--pinkDark:#e99fff;--pinkLight:#663c7b;--pinkTransparent:#352d43;--cerulean:#6f8efc;--ceruleanDark:#9bb1fd;--ceruleanLight:#3a487a;--ceruleanTransparent:#2a3043;}
  