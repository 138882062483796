import {skipToken} from '@reduxjs/toolkit/query';
import {useTranslate} from '@tolgee/react';
import {Stack, useLocalSearchParams, useRouter} from 'expo-router';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {View, XStack} from 'tamagui';

import {Heading4} from '@/components/texts/Heading';
import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {Skeleton} from '@/components/views/Skeleton';
import {useInterval} from '@/hooks/useInterval';
import {InterviewContinueButton} from '@/modules/interview/components/InterviewContinueButton';
import {PlannerLoading} from '@/modules/interview/components/PlannerLoading';
import {MoneyMarketUsCard} from '@/modules/interview/components/planner/MoneyMarketUsCard';
import ProjectDevelopment from '@/modules/interview/components/planner/ProjectedDevelopment';
import {ProjectDevelopmentTabHandle} from '@/modules/interview/components/planner/ProjectedDevelopmentTab';
import {RecommendedStrategyCard} from '@/modules/interview/components/planner/RecommendedStrategyCard';
import {ThemeInfoCardList} from '@/modules/interview/components/planner/ThemeInfoCardList';
import StrategyInfo from '@/modules/interview/components/planner/strategy-info/StrategyInfo';
import StrategyInfoMotive from '@/modules/interview/components/planner/strategy-info/StrategyInfoMotive';
import {Header} from '@/modules/navigation/components/header/Header';
import {SelectedStrategy} from '@/modules/onboarding/types/selected-strategy';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useSession} from '@/providers/session-provider/SessionProvider';
import {useAppDispatch, useAppSelector} from '@/store';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';
import {saveProposal} from '@/store/reducers/onboarding';
import {ShortenedCustomerInformationResponse} from '@/types/api/customers.v2';
import {initialInvestmentStrategy} from '@/types/api/orders';
import {captureException} from '@sentry/react-native';

export default function PlannerPage() {
  const {riskProfile, initialDeposit, initialMonthlySavings, product, selectedStrategy} =
    useLocalSearchParams<{
      riskProfile: string;
      initialDeposit: string;
      initialMonthlySavings: string;
      product: ShortenedCustomerInformationResponse['product'];
      selectedStrategy?: string;
    }>();
  const {t} = useTranslate();
  const router = useRouter();
  const [strategy, setStrategy] = useState(selectedStrategy);
  const [recommended, setRecommended] = useState<string>();
  const {session, signUp} = useSession();
  const dispatch = useAppDispatch();
  const planner = useAppSelector(state => state.onboarding.planner);
  const publicInterview = useAppSelector(state => state.onboarding.publicInterview);
  const {data: user, refetch: refetchUser} = useGetSelfUserQuery(!session ? skipToken : undefined);
  const userId = useMemo(() => user?.id, [user]);
  const [showLoader, setShowLoader] = useState(true);
  const projectDevelopmentRef = useRef<ProjectDevelopmentTabHandle>({
    submit: () => Promise.resolve(),
  });
  const [strategyInitialized, setStrategyInitialized] = useState(false);
  const posthog = usePostHog();
  const prePlannerLoadingScreenVariant = posthog?.getFeatureFlag('pre-planner-loading-screen');
  useInterval(
    () => {
      if (session && !userId) refetchUser();
    },
    {delay: 1000}
  );

  const onContinue = useCallback(async () => {
    if (session) {
      await projectDevelopmentRef.current?.submit();
      router.push({
        pathname: '/onboarding/personal-details',
        params: {product},
      });
    } else {
      if (
        publicInterview?.id === undefined ||
        planner?.initialDepositAmount === undefined ||
        planner?.initialRecurringDepositAmount === undefined ||
        strategy === undefined
      ) {
        captureException('Failed to save proposal', {
          data: {
            'publicInterview?.id': publicInterview?.id,
            'planner?.initialDepositAmount': planner?.initialDepositAmount,
            'planner?.initialRecurringDepositAmount': planner?.initialRecurringDepositAmount,
            strategy,
          },
        });
        console.error('Failed to save proposal');
      } else {
        dispatch(
          saveProposal({
            product,
            initialDepositAmount: planner.initialDepositAmount,
            initialRecurringDepositAmount: planner.initialRecurringDepositAmount,
            initialInvestmentStrategy: strategy as initialInvestmentStrategy,
            interviewId: publicInterview.id,
          })
        );
      }
      await signUp();
    }
  }, [
    dispatch,
    planner?.initialDepositAmount,
    planner?.initialRecurringDepositAmount,
    product,
    publicInterview?.id,
    router,
    session,
    signUp,
    strategy,
  ]);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, 7000);
  }, []);

  useEffect(() => {
    if (prePlannerLoadingScreenVariant === 'planner-without-loader') setShowLoader(false);
  }, [prePlannerLoadingScreenVariant]);

  if (showLoader) {
    return (
      <View flexGrow={1} alignItems="center" justifyContent="center">
        <Stack.Screen
          options={{
            headerShown: false,
          }}
        />
        <PlannerLoading />
      </View>
    );
  }

  if (product === 'THEME_ACCOUNT') {
    return (
      <FullscreenScrollView variant="large" innerViewProps={{gap: '$6'}}>
        <Stack.Screen
          options={{
            headerShown: true,
            header: () => (
              <Header
                withSidePanel={false}
                style={{
                  '$platform-web': {
                    alignSelf: 'center',
                    maxWidth: 1000,
                  },
                }}
              />
            ),
          }}
        />
        <Heading4 variant="medium">{t('PLANNER.MOTIVE.TITLE')}</Heading4>
        <StrategyInfoMotive />
        <MoneyMarketUsCard />
        <ThemeInfoCardList />
        <InterviewContinueButton
          maxWidth="100%"
          width={400}
          onPress={onContinue}
          alignSelf="center"
        >
          {t('CONTINUE')}
        </InterviewContinueButton>
      </FullscreenScrollView>
    );
  }

  return (
    <FullscreenScrollView variant="large" innerViewProps={{gap: '$6'}}>
      <Stack.Screen
        options={{
          headerShown: true,
          header: () => (
            <Header
              withSidePanel={false}
              style={{
                '$platform-web': {
                  alignSelf: 'center',
                  maxWidth: 1000,
                },
              }}
            />
          ),
        }}
      />
      <XStack>
        <Heading4 variant="medium">
          {product === 'SAVINGS_ACCOUNT'
            ? t('INVESTMENT_PLANNER.TOPZINS_TITLE')
            : t('INVESTMENT_PLANNER.TITLE')}
        </Heading4>
      </XStack>
      <RecommendedStrategyCard
        isSV={product === 'SAVINGS_ACCOUNT'}
        isVL={product === 'VL_ACCOUNT'}
        onStrategyChange={(strategy: SelectedStrategy, recommended?: SelectedStrategy) => {
          setStrategy(strategy.code);
          setRecommended(recommended?.code);
          if (!strategyInitialized) {
            setTimeout(() => {
              setStrategyInitialized(true);
            }, 250);
          }
        }}
        defaultStrategy={selectedStrategy}
        defaultType={
          selectedStrategy ? (selectedStrategy.includes('AP') ? 'global' : 'green') : undefined
        }
        riskProfileCode={riskProfile}
      />
      {strategy === undefined ||
        (!strategyInitialized && (
          <>
            <Skeleton width="100%" height={375} />
            <XStack gap="$3" width="100%">
              <View flex={1}>
                <Skeleton width="100%" height={115} />
              </View>

              <View flex={1}>
                <Skeleton width="100%" height={115} />
              </View>
              <View flex={1}>
                <Skeleton width="100%" height={115} />
              </View>
              <View flex={1}>
                <Skeleton width="100%" height={115} />
              </View>
            </XStack>
            <Skeleton width="50%" height={35} />
            <Skeleton width="100%" height={400} />
          </>
        ))}

      {strategy !== undefined && strategyInitialized && (
        <>
          <StrategyInfo
            isSV={product === 'SAVINGS_ACCOUNT'}
            selectedStrategy={strategy}
            recommendedStrategy={recommended ?? strategy}
          />
          <ProjectDevelopment
            ref={projectDevelopmentRef}
            initialDeposit={Number(initialDeposit)}
            initialMonthlySavings={Number(initialMonthlySavings)}
            product={product}
            selectedStrategy={strategy}
          />
        </>
      )}
      <InterviewContinueButton
        isLoading={strategy === undefined || !planner}
        width={400}
        maxWidth="100%"
        onPress={onContinue}
        alignSelf="center"
        $sm={{
          // override internal mobile style
          alignSelf: 'center',
        }}
      >
        {t('CONTINUE')}
      </InterviewContinueButton>
    </FullscreenScrollView>
  );
}
