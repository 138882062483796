import React from 'react';
import {XStack, styled} from 'tamagui';

const StyledPressable = styled(XStack, {
  name: 'Badge',
  role: 'button',
  paddingHorizontal: '$2',
  paddingVertical: '$0.5',
  borderRadius: '$full',
  userSelect: 'none',
  alignItems: 'center',
  variants: {
    bordered: {
      true: {
        borderWidth: '$0.25',
        borderColor: '$neutral100',
      },
    },
    onPrimary: {
      true: {
        borderColor: '#CCC',
      },
    },
  } as const,
});

export type BadgeProps = React.ComponentPropsWithoutRef<typeof StyledPressable> & {
  children: React.ReactNode;
};

export const Badge: React.FC<BadgeProps> = ({children, ...props}) => {
  return <StyledPressable {...props}>{children}</StyledPressable>;
};
