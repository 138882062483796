// eslint-disable-next-line no-restricted-imports
import {Skeleton as MotiSkeleton} from 'moti/skeleton';
import {ReactElement} from 'react';
import {GetProps, useTheme} from 'tamagui';

type SkeletonProps = {
  children?: React.ReactNode | null | undefined | false;
  color?: 'onSurface' | 'onBackground' | 'onPrimary';
} & Omit<GetProps<typeof MotiSkeleton>, 'colorMode' | 'colors' | 'children'>;

export function Skeleton({children, color, width, height, ...props}: SkeletonProps) {
  const theme = useTheme();
  let colors = [theme.neutral100.val, theme.neutral200.val];

  switch (color) {
    case 'onSurface':
      colors = [theme.neutral100.val, theme.neutral300.val];
      break;
    case 'onPrimary':
      colors = [theme.primaryHover.val, theme.primarySkeleton.val];
      break;
  }

  return (
    <MotiSkeleton
      colors={colors}
      width={children ? 0 : width}
      height={children ? 0 : height}
      {...props}
    >
      {children ? (children as ReactElement) : null}
    </MotiSkeleton>
  );
}

Skeleton.Group = MotiSkeleton.Group;
