import {CopyButton} from '@/components/buttons/CopyButton';
import {Label3} from '@/components/texts/Label';
import {UPVEST_BIC, UPVEST_HOLDER, UPVEST_IBAN} from '@/constants/upvest';
import {isUpvestCustomer} from '@/helpers/isUpvestCustomer';
import {spaceIban, spacePaymentReference} from '@/helpers/stringProcessors';
import {useGetCustomerAccountQuery} from '@/store/queries/customersApi';
import {useTranslate} from '@tolgee/react';
import {useMemo} from 'react';
import {View, XStack, YStack, useMedia} from 'tamagui';

export function ClearingAccountBlock({
  customerId,
  withOutCopyButtons = false,
}: {
  customerId: string;
  withOutCopyButtons?: boolean;
}) {
  const {t} = useTranslate();
  const {data: customer} = useGetCustomerAccountQuery(customerId);
  const isUpvest = isUpvestCustomer(customer);
  const media = useMedia();
  const info = useMemo(() => {
    if (isUpvest === undefined) return undefined;
    if (isUpvest) {
      return {
        holder: {
          title: t('ACCOUNT_DETAILS.ACCOUNT_HOLDER'),
          value: UPVEST_HOLDER,
        },
        iban: {
          title: t('ACCOUNT_DETAILS.IBAN'),
          value: UPVEST_IBAN,
        },
        bic: {
          title: t('ACCOUNT_DETAILS.BIC'),
          value: UPVEST_BIC,
        },
        referenceOrBank: {
          title: t('ACCOUNT_DETAILS.PAYMENT_REFERENCE'),
          value: customer?.paymentReference,
        },
      };
    } else {
      return {
        holder: {
          title: t('ACCOUNT_DETAILS.ACCOUNT_HOLDER'),
          value: customer?.clearingAccount?.holderFullName,
        },
        iban: {
          title: t('ACCOUNT_DETAILS.IBAN'),
          value: customer?.clearingAccount?.iban,
        },
        bic: {
          title: t('ACCOUNT_DETAILS.BIC'),
          value: customer?.clearingAccount?.bic,
        },
        referenceOrBank: {
          title: t('ACCOUNT_DETAILS.BANK_NAME'),
          value: customer?.clearingAccount?.bank || undefined,
        },
      };
    }
  }, [
    customer?.clearingAccount?.bank,
    customer?.clearingAccount?.bic,
    customer?.clearingAccount?.holderFullName,
    customer?.clearingAccount?.iban,
    customer?.paymentReference,
    isUpvest,
    t,
  ]);

  return (
    <>
      {!withOutCopyButtons ? (
        <YStack gap="$4">
          <XStack gap="$4" justifyContent="space-between">
            <Label3 color="$neutral500">{info?.holder.title}</Label3>
            <CopyButton text={info?.holder.value}>
              <Label3 variant="medium" textAlign="right">
                {info?.holder.value}
              </Label3>
            </CopyButton>
          </XStack>
          <XStack gap="$4" justifyContent="space-between">
            <Label3 color="$neutral500">{info?.iban.title}</Label3>
            <CopyButton text={info?.iban.value && spaceIban(info?.iban.value)}>
              <Label3 variant="medium" textAlign="right">
                {info?.iban.value && spaceIban(info?.iban.value)}
              </Label3>
            </CopyButton>
          </XStack>
          <XStack gap="$4" justifyContent="space-between">
            <Label3 color="$neutral500">{info?.bic.title}</Label3>
            <CopyButton text={info?.bic.value}>
              <Label3 variant="medium" textAlign="right">
                {info?.bic.value}
              </Label3>
            </CopyButton>
          </XStack>
          <XStack gap="$4" justifyContent="space-between">
            <Label3 color="$neutral500">{info?.referenceOrBank.title}</Label3>
            <CopyButton text={info?.referenceOrBank.value}>
              <Label3 variant="medium" textAlign="right" flex={1}>
                {info?.referenceOrBank.value && spacePaymentReference(info?.referenceOrBank.value)}
              </Label3>
            </CopyButton>
          </XStack>
        </YStack>
      ) : (
        <>
          <View
            flexDirection={media.sm ? 'column' : 'row'}
            justifyContent="space-between"
            flexWrap="wrap"
            gap="$4"
            width="100%"
          >
            <XStack justifyContent="space-between" minWidth="45%" gap="$2">
              <YStack gap="$4">
                <Label3 color="$neutral500">{t('ACCOUNT_DETAILS.ACCOUNT_HOLDER')}</Label3>
                <Label3 color="$neutral500">{t('ACCOUNT_DETAILS.IBAN')}</Label3>
                <Label3 color="$neutral500">{t('ACCOUNT_DETAILS.BIC')}</Label3>
              </YStack>
              <YStack gap="$4" maxWidth={250} flexShrink={1}>
                <Label3 variant="medium" textAlign="right">
                  {info?.holder.value}
                </Label3>
                <Label3 variant="medium" textAlign="right">
                  {info?.iban.value}
                </Label3>
                <Label3 variant="medium" textAlign="right">
                  {info?.bic.value}
                </Label3>
              </YStack>
            </XStack>
            <XStack justifyContent="space-between" minWidth="45%" gap="$2">
              <YStack gap="$4">
                <Label3 color="$neutral500">{t('ACCOUNT_DETAILS.BANK_NAME')}</Label3>
              </YStack>
              <YStack gap="$4" maxWidth={250} flexShrink={1}>
                <Label3 variant="medium" textAlign="right">
                  {info?.referenceOrBank.value}
                </Label3>
              </YStack>
            </XStack>
          </View>
        </>
      )}
    </>
  );
}
