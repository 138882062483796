import {Canvas, Paragraph, SkParagraph, Skia, useFonts} from '@shopify/react-native-skia';
import {SkTextStyle} from '@shopify/react-native-skia/src/skia/types/Paragraph/TextStyle';
import {useTranslate} from '@tolgee/react';
import {useCallback, useMemo, useState} from 'react';
import {LayoutChangeEvent} from 'react-native';
import {SharedValue, runOnJS, useDerivedValue, useSharedValue} from 'react-native-reanimated';
import {View, useTheme} from 'tamagui';

import {useWebGlAvailability} from '@/components/skia/hooks/useWebGlAvailability';
import {createParagraph} from '@/components/skia/utils';
import {INITIAL_SIZE} from '@/constants/skia';
import {InvestArrowIcon} from '@/modules/overview/components/InvestArrowIcon';
import {useAppSelector} from '@/store';
import {Size} from '@/types/skia';

export type BalanceAndPerformance = {
  time: number;
  balance: number;
  performance: number;
};

const LABEL_FONT_SIZE = 12;
const VALUE_FONT_SIZE = 18;
const DATE_FONT_SIZE = 12;

const DEFAULT_HEIGHT = LABEL_FONT_SIZE + 4 + VALUE_FONT_SIZE + 8 + DATE_FONT_SIZE;

type Props = {
  legendValue: Readonly<SharedValue<BalanceAndPerformance | undefined>>;
};

export const PerformanceChartLegend = ({legendValue}: Props) => {
  const {t} = useTranslate();
  const theme = useTheme();

  const language = useAppSelector(state => state.app.language);

  const [height, setHeight] = useState(DEFAULT_HEIGHT);

  const legendWidth = useSharedValue(0);

  const fonts = useFonts({
    // @ts-ignore
    Inter: [require('@tamagui/font-inter/otf/Inter-Regular.otf')],
  });

  const handleLegendContentRendered = useCallback((event: LayoutChangeEvent) => {
    legendWidth.value = event.nativeEvent.layout.width;
  }, []);

  const legendDateParagraph = useDerivedValue(() => {
    if (!legendValue.value || !fonts || legendWidth.value === 0) return null;

    const text = Intl.DateTimeFormat(language, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }).format(legendValue.value.time);

    const style: SkTextStyle = {
      fontSize: DATE_FONT_SIZE,
      color: Skia.Color(theme.neutral500.val),
      fontFamilies: ['Inter'],
    };

    return createParagraph(fonts, style, text, legendWidth.value);
  });

  const legendBalanceParagraph = useDerivedValue(() => {
    if (!legendValue.value || !fonts || legendWidth.value === 0) return null;

    const text = Intl.NumberFormat(language, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'EUR',
    }).format(legendValue.value.balance);

    const style: SkTextStyle = {
      fontSize: VALUE_FONT_SIZE,
      fontStyle: {
        weight: 500,
      },
      fontFamilies: ['Inter'],
      color: Skia.Color(theme.text.val),
    };

    return createParagraph(fonts, style, text, legendWidth.value);
  });

  const legendDateParagraphWidth = useDerivedValue(() => {
    if (!legendDateParagraph.value) return 0;

    return Math.ceil(legendDateParagraph.value.getLongestLine());
  });

  const getParagraphMetrics = (paragraph: SkParagraph | null): Size => {
    'worklet';

    if (!paragraph) return INITIAL_SIZE;

    return {
      width: Math.ceil(paragraph.getLongestLine()),
      height: Math.ceil(paragraph.getHeight()),
    };
  };

  const legendBalanceParagraphMetrics = useDerivedValue(() => {
    return getParagraphMetrics(legendBalanceParagraph.value);
  });

  const legendBalanceParagraphWidth = useDerivedValue(() => {
    return legendBalanceParagraphMetrics.value.width;
  });

  const currentBalanceText = useMemo(() => t('OVERVIEW.CURRENT-BALANCE'), [t]);
  const performanceText = useMemo(
    () => t('PERFORMANCE_CHART.BOTTOM_SHEET.TIME_WEIGHTED_RETURN'),
    [t]
  );

  const createLabelParagraph = (width: number, balance?: boolean) => {
    'worklet';

    if (!fonts || width === 0) return null;

    const text = balance ? currentBalanceText : performanceText;

    const style: SkTextStyle = {
      fontSize: LABEL_FONT_SIZE,
      color: Skia.Color(theme.neutral500.val),
      fontFamilies: ['Inter'],
    };

    return createParagraph(fonts, style, text, width);
  };

  const legendBalanceLabelParagraph = useDerivedValue(() => {
    return createLabelParagraph(legendWidth.value, true);
  });

  const legendBalanceLabelParagraphMetrics = useDerivedValue(() => {
    return getParagraphMetrics(legendBalanceLabelParagraph.value);
  });

  const legendBalanceLabelParagraphWidth = useDerivedValue(() => {
    return legendBalanceLabelParagraphMetrics.value.width;
  });

  const legendBalanceParagraphY = useDerivedValue(() => {
    return legendBalanceLabelParagraphMetrics.value.height + 4;
  });

  const legendDateParagraphY = useDerivedValue(() => {
    return legendBalanceParagraphY.value + legendBalanceParagraphMetrics.value.height + 8;
  });

  const legendPerformanceLabelParagraph = useDerivedValue(() => {
    return createLabelParagraph(legendWidth.value);
  });

  const legendPerformanceLabelParagraphMetrics = useDerivedValue(() => {
    return getParagraphMetrics(legendPerformanceLabelParagraph.value);
  });

  const legendPerformanceLabelParagraphX = useDerivedValue(() => {
    if (legendWidth.value === 0) return 100;

    return legendWidth.value - legendPerformanceLabelParagraphMetrics.value.width;
  });

  const legendPerformanceLabelParagraphWidth = useDerivedValue(() => {
    return legendPerformanceLabelParagraphMetrics.value.width;
  });

  const legendPerformanceParagraph = useDerivedValue(() => {
    if (!legendValue.value || !fonts || legendWidth.value === 0) return null;

    const positive = legendValue.value.performance > 0;

    let text = Intl.NumberFormat(language, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: 'percent',
    }).format(legendValue.value.performance);

    if (positive) {
      text = `+${text}`;
    }

    const style: SkTextStyle = {
      fontSize: VALUE_FONT_SIZE,
      fontStyle: {
        weight: 500,
      },
      fontFamilies: ['Inter'],
      color: Skia.Color(positive ? theme.green.val : theme.text.val),
    };

    return createParagraph(fonts, style, text, legendWidth.value);
  });

  const legendPerformanceParagraphMetrics = useDerivedValue(() => {
    return getParagraphMetrics(legendPerformanceParagraph.value);
  });

  const legendPerformanceParagraphX = useDerivedValue(() => {
    if (legendWidth.value === 0) return 100;

    return legendWidth.value - legendPerformanceParagraphMetrics.value.width;
  });

  const legendPerformanceParagraphY = useDerivedValue(() => {
    return legendPerformanceLabelParagraphMetrics.value.height + 4;
  });

  const legendPerformanceParagraphWidth = useDerivedValue(() => {
    return legendPerformanceParagraphMetrics.value.width;
  });

  useDerivedValue(() => {
    const dateParagraphMetrics = getParagraphMetrics(legendDateParagraph.value);

    if (dateParagraphMetrics.height === 0 || legendDateParagraphY.value === 0) {
      runOnJS(setHeight)(DEFAULT_HEIGHT);

      return;
    }

    runOnJS(setHeight)(legendDateParagraphY.value + dateParagraphMetrics.height);
  });

  const webGlAvailable = useWebGlAvailability();

  return (
    <View width="100%" height={height} onLayout={handleLegendContentRendered}>
      {webGlAvailable && (
        <Canvas style={{flex: 1}}>
          <Paragraph
            paragraph={legendBalanceLabelParagraph}
            x={0}
            y={0}
            width={legendBalanceLabelParagraphWidth}
          />
          <Paragraph
            paragraph={legendBalanceParagraph}
            x={0}
            y={legendBalanceParagraphY}
            width={legendBalanceParagraphWidth}
          />
          <Paragraph
            paragraph={legendPerformanceLabelParagraph}
            x={legendPerformanceLabelParagraphX}
            y={0}
            width={legendPerformanceLabelParagraphWidth}
          />
          <InvestArrowIcon
            legendValue={legendValue}
            legendPerformanceParagraphX={legendPerformanceParagraphX}
            legendPerformanceParagraphY={legendPerformanceParagraphY}
            theme={theme}
          />
          <Paragraph
            paragraph={legendPerformanceParagraph}
            x={legendPerformanceParagraphX}
            y={legendPerformanceParagraphY}
            width={legendPerformanceParagraphWidth}
          />
          <Paragraph
            paragraph={legendDateParagraph}
            x={0}
            y={legendDateParagraphY}
            width={legendDateParagraphWidth}
          />
        </Canvas>
      )}
    </View>
  );
};
