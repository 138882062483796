import {forwardRef, useCallback} from 'react';
import {Control, useController} from 'react-hook-form';
import {ScrollView, View, XStack, YStack, useWindowDimensions} from 'tamagui';

import {CheckBox} from '@/components/inputs/Checkbox';
import {Modal} from '@/components/modal/Modal';
import {Heading5} from '@/components/texts/Heading';
import {Label2} from '@/components/texts/Label';
import {useGetQuestionWithAnswers} from '@/modules/interview/hooks/useGetQuestionWithAnswers';
import {SustainLevelFormValues} from '@/modules/interview/types/sustainLevel';
import {ModalRef} from '@/types/modal';

type Props = {
  control: Control<SustainLevelFormValues>;
};

export const SustainLevelFactors = forwardRef<ModalRef, Props>(({control}, ref) => {
  const {height} = useWindowDimensions();

  const {
    field: {value, onChange},
  } = useController<SustainLevelFormValues>({name: 'SUSTAIN_FACTOR', control});
  const {question, answers = []} = useGetQuestionWithAnswers('SUSTAIN_FACTOR');

  const handleFactorPressed = useCallback(
    (code: string) => {
      let newFactors = [...(value ?? [])];

      if (newFactors.includes(code)) {
        newFactors = newFactors.filter(factor => factor !== code);
      } else {
        newFactors.push(code);
      }

      onChange(newFactors);
    },
    [value, onChange]
  );

  return (
    <Modal ref={ref}>
      <Modal.Content typeContainer="SCROLL" side>
        <ScrollView>
          <Modal.Title asChild="except-style">
            <Heading5 variant="medium">{question?.label}</Heading5>
          </Modal.Title>
          <YStack paddingTop="$4" gap="$2">
            {answers.map(answer => (
              <View
                key={answer.code}
                cursor="pointer"
                onPress={() => handleFactorPressed(answer.code)}
              >
                <XStack gap="$3">
                  <YStack alignItems="center" justifyContent="center">
                    <CheckBox checked={value?.includes(answer.code) ?? false} />
                  </YStack>
                  <XStack flexShrink={1}>
                    <Label2>{answer.label}</Label2>
                  </XStack>
                </XStack>
              </View>
            ))}
          </YStack>
          <Modal.IconClose />
        </ScrollView>
      </Modal.Content>
    </Modal>
  );
});
