import {Skeleton} from '@/components/views/Skeleton';
import {isAccountOrderExpired} from '@/helpers/isAccountOrderExpired';
import {useInterval} from '@/hooks/useInterval';
import {AccountOrderCard} from '@/modules/accounts/components/AccountOrderCard';
import {start} from '@/modules/overview/helpers/postident';
import {useGetPostidentQuery} from '@/store/queries/identityApi';
import {useGetValidOrdersQuery} from '@/store/queries/ordersApi';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';
import {skipToken} from '@reduxjs/toolkit/query';
import {useLocalSearchParams, useRouter} from 'expo-router';
import {useEffect} from 'react';
import {YStack} from 'tamagui';

export function AccountOrderList() {
  const {data: user, isFetching: isUserFetching, refetch: refetchUser} = useGetSelfUserQuery();
  const {data: orders, refetch: refetchOrders} = useGetValidOrdersQuery();
  const {data: postident} = useGetPostidentQuery(user?.id ? user.id : skipToken);
  const {action, ...restParams} = useLocalSearchParams();
  const router = useRouter();

  useInterval(
    () => {
      if (action === 'refreshPoR' || action === 'refreshPostident') {
        refetchOrders();
      }
    },
    {delay: 3000}
  );

  useEffect(() => {
    if (action === 'refreshPoR') {
      if (orders && !orders.items.some(_ => _.requiredAction === 'PROOF_OF_RESIDENCE_REQUIRED')) {
        router.setParams({...restParams, action: undefined});
      }
    }
    if (action === 'refreshPostident') {
      if (orders && !orders.items.some(_ => _.requiredAction === 'IDENTIFICATION_REQUIRED')) {
        router.setParams({...restParams, action: undefined});
        refetchUser();
      }
    }
  }, [action, orders, refetchUser, restParams, router]);

  useEffect(() => {
    if (action === 'startPostident' && postident?.caseId) {
      start({caseId: postident.caseId});
      router.setParams({action: undefined});
    }
  }, [action, postident?.caseId, router]);

  if (!isUserFetching && !(!!orders && orders.items.length > 0 && user)) return null;
  return (
    <Skeleton width="100%" height={100}>
      {!isUserFetching && (
        <YStack gap="$4">
          {!!orders &&
            user &&
            orders.items
              .filter(item => !isAccountOrderExpired(item))
              .map(order => (
                <AccountOrderCard
                  order={
                    order.requiredAction === 'PROOF_OF_RESIDENCE_REQUIRED' &&
                    action === 'refreshPoR'
                      ? {...order, requiredAction: undefined}
                      : order
                  }
                  key={order.id}
                  userId={user.id}
                />
              ))}
        </YStack>
      )}
    </Skeleton>
  );
}
