import {Card} from '@/components/cards/Card';
import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {Tabbar} from '@/modules/navigation/components/tabbar/Tabbar';
import {AllocationBarChart} from '@/modules/portfolio/components/AllocationBarChart';
import {useInvestmentStrategiesQuery} from '@/store/queries/referenceApi';
import {ShortenedCustomerInformationResponse} from '@/types/api/customers.v2';
import {useTranslate} from '@tolgee/react';
import {PropsWithChildren, useMemo} from 'react';
import {YStack} from 'tamagui';

export type AssetsAllocationTabProps = {
  product: ShortenedCustomerInformationResponse['product'];
  selectedStrategy: string;
};

export default function AssetsAllocationTab({
  selectedStrategy,
}: PropsWithChildren<AssetsAllocationTabProps>) {
  const {t} = useTranslate();
  const {data: investmentStrategies} = useInvestmentStrategiesQuery();
  const {getByCode} = useRefdataLocalizable();
  const allocations = getByCode(investmentStrategies, selectedStrategy)?.allocations;

  const elements = useMemo(() => {
    return [
      {
        label: t('PORTFOLIO.ASSET-CLASSES'),
      },
      {
        label: t('PORTFOLIO.INDUSTRIES-2'),
      },
      {
        label: t('PORTFOLIO.REGIONS'),
      },
    ];
  }, [t]);

  if (!allocations) return null;

  return (
    <YStack gap="$3" flex={1}>
      <Card headerText={t('PROJECT-DEVELOPMENT.ASSETS-ALLOCATION')} gap="$5">
        <Tabbar elements={elements}>
          <AllocationBarChart allocations={allocations} groupType="assets" />
          <AllocationBarChart allocations={allocations} groupType="industry" />
          <AllocationBarChart allocations={allocations} groupType="region" />
        </Tabbar>
      </Card>
    </YStack>
  );
}
