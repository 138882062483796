export enum PlaceType2 {
  /**
   * indicates a first-order civil entity below the country level. Within the United States, these administrative levels are states.
   * Not all nations exhibit these administrative levels. In most cases, `administrative_area_level_1` short names will closely match
   * ISO 3166-2 subdivisions and other widely circulated lists; however this is not guaranteed as our geocoding results are based
   * on a variety of signals and location data.
   */
  administrative_area_level_1 = 'administrative_area_level_1',
  /**
   * indicates a second-order civil entity below the country level. Within the United States, these administrative levels are counties.
   * Not all nations exhibit these administrative levels.
   */
  administrative_area_level_2 = 'administrative_area_level_2',
  /**
   * indicates a third-order civil entity below the country level. This type indicates a minor civil division.
   * Not all nations exhibit these administrative levels.
   */
  administrative_area_level_3 = 'administrative_area_level_3',
  /**
   * indicates a fourth-order civil entity below the country level. This type indicates a minor civil division.
   * Not all nations exhibit these administrative levels.
   */
  administrative_area_level_4 = 'administrative_area_level_4',
  /**
   * indicates a fifth-order civil entity below the country level. This type indicates a minor civil division.
   * Not all nations exhibit these administrative levels.
   */
  administrative_area_level_5 = 'administrative_area_level_5',
  archipelago = 'archipelago',
  /** indicates a commonly-used alternative name for the entity. */
  colloquial_area = 'colloquial_area',
  continent = 'continent',
  /** indicates the national political entity, and is typically the highest order type returned by the Geocoder. */
  country = 'country',
  establishment = 'establishment',
  finance = 'finance',
  floor = 'floor',
  food = 'food',
  general_contractor = 'general_contractor',
  geocode = 'geocode',
  health = 'health',
  /** indicates a major intersection, usually of two major roads. */
  intersection = 'intersection',
  landmark = 'landmark',
  /** indicates an incorporated city or town political entity. */
  locality = 'locality',
  /** indicates a prominent natural feature. */
  natural_feature = 'natural_feature',
  /** indicates a named neighborhood */
  neighborhood = 'neighborhood',
  place_of_worship = 'place_of_worship',
  plus_code = 'plus_code',
  point_of_interest = 'point_of_interest',
  /** indicates a political entity. Usually, this type indicates a polygon of some civil administration. */
  political = 'political',
  post_box = 'post_box',
  /** indicates a postal code as used to address postal mail within the country. */
  postal_code = 'postal_code',
  postal_code_prefix = 'postal_code_prefix',
  postal_code_suffix = 'postal_code_suffix',
  postal_town = 'postal_town',
  /** indicates a named location, usually a building or collection of buildings with a common name */
  premise = 'premise',
  room = 'room',
  /** indicates a named route (such as "US 101"). */
  route = 'route',
  street_address = 'street_address',
  street_number = 'street_number',
  /**
   * indicates a first-order civil entity below a locality. For some locations may receive one of the additional types:
   * `sublocality_level_1` to `sublocality_level_5`. Each sublocality level is a civil entity. Larger numbers indicate a smaller
   * geographic area.
   */
  sublocality = 'sublocality',
  sublocality_level_1 = 'sublocality_level_1',
  sublocality_level_2 = 'sublocality_level_2',
  sublocality_level_3 = 'sublocality_level_3',
  sublocality_level_4 = 'sublocality_level_4',
  sublocality_level_5 = 'sublocality_level_5',
  /**
   * indicates a first-order entity below a named location, usually a singular building within a collection of buildings with a
   * common name.
   */
  subpremise = 'subpremise',
  town_square = 'town_square',
}
