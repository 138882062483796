import {Modal} from '@/components/modal/Modal';
import {Label2} from '@/components/texts/Label';
import {InfoBox} from '@/modules/orders/components/InfoBox';
import {ReactNode} from 'react';
import {GetProps, GetThemeValueForKey, ScrollView, View} from 'tamagui';

type TipWithSideModalProps = {
  tipTitle: string | ReactNode;
  tipContent: React.JSX.Element;
  contentContainerProps?: GetProps<typeof View>;
  color?: string | undefined;
};

export function TipWithSideModal({tipTitle, tipContent, color}: TipWithSideModalProps) {
  const tipColor = (color ? color : '$primary') as GetThemeValueForKey<'color'>;

  return (
    <Modal>
      <Modal.Trigger>
        {typeof tipTitle === 'string' ? (
          <View alignSelf="center" maxWidth="100%">
            <InfoBox wrapperProps={{padding: 0, cursor: 'pointer'}} tipColor={tipColor}>
              <Label2 color={tipColor} maxWidth="100%" flexShrink={1}>
                {tipTitle}
              </Label2>
            </InfoBox>
          </View>
        ) : (
          tipTitle
        )}
      </Modal.Trigger>
      <Modal.Content typeContainer="SCROLL" side>
        <ScrollView>{tipContent}</ScrollView>
      </Modal.Content>
    </Modal>
  );
}
