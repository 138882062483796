import {baseApi} from '@/store/queries/baseApi';
import {PlaceType2} from '@/types/address';

const BASE_PATH = 'gmaps';

export const gmapsApi = baseApi.injectEndpoints({
  endpoints: build => ({
    getGmapsSuggestions: build.query<any, {query: string; token: string; language: string}>({
      query: ({query, token, language}) => {
        const params = new URLSearchParams();
        params.append('key', process.env.EXPO_PUBLIC_GOOGLE_MAPS_KEY);
        params.append('input', query);
        params.append('types', 'address');
        params.append('url', 'https://maps.googleapis.com/maps/api/place/autocomplete/json?');
        params.append('sessiontoken', token);
        params.append('locationbias', 'ipbias');
        params.append('language', language === 'de' ? 'de' : 'en');
        return {url: `${BASE_PATH}?${params.toString()}`};
      },
      transformResponse: (response: GmapsSuggestionsResponse) => {
        if (response === null || response.status !== 'OK') {
          return [];
        }
        return response.predictions.map(prediction => {
          return {
            key: prediction.place_id,
            name: prediction.description,
          };
        });
      },
    }),
    getGmapsPlace: build.query<any, {query: string; token: string}>({
      query: ({query, token}) => {
        const params = new URLSearchParams();
        params.append('key', process.env.EXPO_PUBLIC_GOOGLE_MAPS_KEY);
        params.append('place_id', query);
        params.append('url', 'https://maps.googleapis.com/maps/api/place/details/json?');
        params.append('fields', 'address_component');
        params.append('sessiontoken', token);
        return {url: `${BASE_PATH}?${params.toString()}`};
      },
      transformResponse: (response: GmapsPlaceResponse) => {
        if (response === null || response.status !== 'OK') {
          return {};
        }
        const addressComponents = response.result?.address_components;
        const streetName = addressComponents.find((address: any) =>
          address.types.includes(PlaceType2.route)
        );
        const city = addressComponents.find((address: any) =>
          address.types.includes(PlaceType2.locality)
        );
        const country = addressComponents.find((address: any) =>
          address.types.includes(PlaceType2.country)
        );
        const streetNumber = addressComponents.find((address: any) =>
          address.types.includes(PlaceType2.street_number)
        );
        const postalCode = addressComponents.find((address: any) =>
          address.types.includes(PlaceType2.postal_code)
        );
        return {streetName, city, country, streetNumber, postalCode};
      },
    }),
  }),
});

export const {useLazyGetGmapsSuggestionsQuery, useLazyGetGmapsPlaceQuery} = gmapsApi;

export type GmapsSuggestionsResponse = {
  predictions: {
    description: string;
    matched_substrings: {
      length: number;
      offset: number;
    }[];
    place_id: string;
    reference: string;
    structured_formatting: {
      main_text: string;
      main_text_matched_substrings: {
        length: number;
        offset: number;
      }[];
      secondary_text: string;
    };
    terms: {
      offset: number;
      value: string;
    }[];
    types: string[];
  }[];
  status: string;
};

export type GmapsPlaceResponse = {
  result: {
    address_components: {
      long_name: string;
      short_name: string;
      types: string[];
    }[];
  };
  status: string;
};
