import SvgFile from '@/components/icons/SvgFile';
import React, {PropsWithChildren} from 'react';
import {GetProps, View, XStack} from 'tamagui';

type BulletPointProps = {
  color?: GetProps<typeof View>['backgroundColor'];
};

export function BulletPoint({children, color}: PropsWithChildren<BulletPointProps>) {
  return (
    <XStack gap="$2" alignItems="flex-start">
      <View
        borderRadius="$full"
        padding="$0.5"
        backgroundColor={color ? color : '$neutralBG'}
        marginTop={4}
      >
        <SvgFile color="$primary" name="check" size={16} />
      </View>
      <View display="inline" flex={1}>
        {children}
      </View>
    </XStack>
  );
}
