import {SvgName} from '@/components/icons/SvgFile';

export const TRANSACTION_ICONS = {
  DIVIDEND: 'dividends',
  DIRECT_DEPOSIT: 'deposit',
  WIRE_DEPOSIT: 'deposit',
  WIRE_WITHDRAWAL: 'withdrawal',
  DIRECT_WITHDRAWAL: 'withdrawal',
  BOUNCED_DEPOSIT: 'deposit-bounced',
  BOUNCED_WITHDRAWAL: 'withdrawal-bounced',
  BONUS: 'bonus',
  TAX_OPTIMIZATION: 'credit',
  TAX: 'credit',
  MANAGEMENT_FEE: 'fee',
  OTHER_FEE: 'fee',
} as Record<string, SvgName>;
