import {DocType} from '@/types/documents';
import {TFnType} from '@tolgee/react';

export function getPorDocTypes(t: TFnType): DocType[] {
  return [
    {
      value: 'ID_CARD',
      text: t('PROOF_OF_RESIDENCE.DOCUMENT_TYPES.ID_CARD'),
      getValityPeriod: age => (age < 24 ? 6 : 10),
      periodType: 'years',
    },
    {
      value: 'UTILITY_BILL',
      text: t('PROOF_OF_RESIDENCE.DOCUMENT_TYPES.UTILITY_BILL'),
      getValityPeriod: () => 12,
      periodType: 'months',
    },
    {
      value: 'TELEPHONE_BILL',
      text: t('PROOF_OF_RESIDENCE.DOCUMENT_TYPES.TELEPHONE_BILL'),
      getValityPeriod: () => 12,
      periodType: 'months',
    },
    {
      value: 'INTERNET_BILL',
      text: t('PROOF_OF_RESIDENCE.DOCUMENT_TYPES.INTERNET_BILL'),
      getValityPeriod: () => 12,
      periodType: 'months',
    },
    {
      value: 'REGISTRATION_CERT',
      text: t('PROOF_OF_RESIDENCE.DOCUMENT_TYPES.REGISTRATION_CERT'),
      getValityPeriod: () => 12,
      periodType: 'months',
    },
    {
      value: 'RESIDENCE_PERMIT',
      text: t('PROOF_OF_RESIDENCE.DOCUMENT_TYPES.RESIDENCE_PERMIT'),
      getValityPeriod: () => 12,
      periodType: 'months',
    },
  ];
}
