import {Stack, useLocalSearchParams} from 'expo-router';
import {useState} from 'react';
import {Platform} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {View, XStack, YStack, isTouchable, useMedia} from 'tamagui';

import {OverlappedStack} from '@/components/views/OverlappedStack';
import {ResponsiveScrollView} from '@/components/views/ResponsiveScrollView';
import {WebFooter} from '@/modules/navigation/components/footer/WebFooter';
import {Header} from '@/modules/navigation/components/header/Header';
import ActionBar from '@/modules/overview/components/ActionBar';
import {AllocationPreviewCard} from '@/modules/overview/components/AllocationPreviewCard';
import {CustomerCard} from '@/modules/overview/components/CustomerCard';
import {OrdersPreviewCard} from '@/modules/overview/components/OrdersPreviewCard';
import {PerformanceCharts} from '@/modules/overview/components/PerformanceCharts';
import {PreviewChartScrollview} from '@/modules/overview/components/PreviewChartScrollview';
import {SavingsRateCard} from '@/modules/overview/components/SavingsRateCard';
import {TransactionsPreviewCard} from '@/modules/overview/components/TransactionsPreviewCard';
import {useTranslate} from '@tolgee/react';

export default function OverviewPage() {
  const media = useMedia();
  const {t} = useTranslate();

  const {customerId} = useLocalSearchParams<{customerId: string}>();

  const {top} = useSafeAreaInsets();

  const [overlapPadding, setOverlapPadding] = useState(47);
  return (
    <ResponsiveScrollView
      contentContainerStyle={{
        padding: 0,
        paddingHorizontal: media.gtSm && isTouchable ? '$4' : '$0',
        paddingBottom: '$4',
        paddingTop: media.gtSm ? '$7' : 0,
      }}
    >
      {media.gtSm && Platform.OS !== 'web' && (
        <Stack.Screen
          options={{
            headerShown: true,
            header: () => <Header title={t('OVERVIEW.TITLE.WEB')} backButton />,
          }}
        />
      )}
      <XStack
        flexWrap="wrap"
        $gtSm={{
          gap: '$3',
        }}
      >
        <CustomerCard
          customerId={customerId}
          containerStyle={{
            flex: 1,
            flexBasis: 0,
            minWidth: 300,
            $sm: {minWidth: '100%'},
            paddingBottom: overlapPadding,
            paddingTop: top,
          }}
        />
        <YStack
          flex={1}
          flexBasis={0}
          minWidth={350}
          gap="$3"
          justifyContent="space-between"
          $sm={{minWidth: 0, marginHorizontal: '$4'}}
        >
          <OverlappedStack setOverlapPadding={setOverlapPadding}>
            <ActionBar />
          </OverlappedStack>
          {media.gtSm && <SavingsRateCard customerId={customerId} />}
        </YStack>
      </XStack>
      {media.sm && (
        <View marginHorizontal="$4">
          <SavingsRateCard customerId={customerId} />
        </View>
      )}
      <YStack $sm={{paddingHorizontal: '$4'}} gap="$6">
        {media.sm ? (
          <PreviewChartScrollview customerId={customerId} />
        ) : (
          <PerformanceCharts customerId={customerId} tab="performance" />
        )}
        <AllocationPreviewCard customerId={customerId} />
        <OrdersPreviewCard customerId={customerId} />
        <TransactionsPreviewCard customerId={customerId} />
      </YStack>

      <WebFooter withCalendly />
    </ResponsiveScrollView>
  );
}
