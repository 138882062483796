import SvgFile, {SvgName} from '@/components/icons/SvgFile';
import {Label2} from '@/components/texts/Label';
import {SelectInputItem} from '@/types/FormInputProps';
import {GetProps, XStack} from 'tamagui';

type SelectItemProps = {
  /**
   * Display an icon on the left of the item
   * Forces more complex design even on web
   */
  icon?: SvgName;
  item: SelectInputItem;
  selected?: boolean;
  disabled?: boolean;
  wrapperProps?: GetProps<typeof XStack>;
};

export function SelectItem({
  icon,
  item,
  selected,
  wrapperProps,
  disabled = false,
}: SelectItemProps) {
  return (
    <XStack
      {...wrapperProps}
      cursor="pointer"
      alignItems="center"
      justifyContent="flex-start"
      backgroundColor="$neutralBG"
      marginVertical="$2"
      padding="$4"
      borderRadius="$0.5"
      hoverStyle={{
        opacity: 0.8,
      }}
      borderWidth={1}
      borderColor={selected ? '$primary' : 'transparent'}
      testID={item.key}
    >
      {icon && <SvgFile size="18" marginRight="$2" name={icon} color="$primary" />}
      <Label2
        variant="medium"
        flexShrink={1}
        width="100%"
        color={disabled ? '$neutral300' : selected ? '$primary' : '$text'}
      >
        {item.name}
      </Label2>
    </XStack>
  );
}
