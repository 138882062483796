import {Card} from '@/components/cards/Card';
import SvgFile from '@/components/icons/SvgFile';
import {Label3} from '@/components/texts/Label';
import React from 'react';
import {AnimatePresence, GetThemeValueForKey} from 'tamagui';

type WarningBoxProps = {
  text: string | undefined;
  show: boolean;
  backgroundColor?: GetThemeValueForKey<'backgroundColor'>;
  color?: GetThemeValueForKey<'color'>;
  iconColor?: GetThemeValueForKey<'color'>;
};

export function WarningBox({
  text,
  show,
  backgroundColor = '$orangeTransparent',
  color = '$orange',
  iconColor,
}: WarningBoxProps) {
  return (
    <AnimatePresence>
      {show && text && (
        <Card flexDirection="row" gap="$2" backgroundColor={backgroundColor}>
          <SvgFile name="warning" color={color} />
          <Label3 color={iconColor || color} alignSelf="center" flex={1}>
            {text}
          </Label3>
        </Card>
      )}
    </AnimatePresence>
  );
}
