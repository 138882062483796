import {Label2} from '@/components/texts/Label';
import {Paragraph1, Paragraph2} from '@/components/texts/Paragraph';
import {useAppSelector} from '@/store';
import {captureException} from '@sentry/react-native';
import {useMemo} from 'react';
import {GetProps} from 'tamagui';

type LocalizedDateProps = {
  date: Date | string;
  dateFormat?: 'month' | 'full';
  textComponent?: typeof Paragraph1 | typeof Label2;
};

const OPTIONS: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
};

export function LocalizedDate({
  date,
  dateFormat = 'full',
  textComponent,
  ...rest
}: GetProps<typeof Paragraph2> & LocalizedDateProps) {
  const currentLanguage = useAppSelector(state => state.app.language);
  const Component = textComponent || Label2;
  const value = useMemo(() => {
    if (typeof date !== 'string') return date;
    try {
      const [year, month, day] = date.split('-');
      if (month && parseInt(month) > 12) {
        console.error('Invalid string date format used');
        captureException('Invalid string date format used');
      }
    } catch (e) {
      captureException(e);
    }
    return new Date(date);
  }, [date]);

  const dateTimeFormat = useMemo(() => {
    return new Intl.DateTimeFormat(
      currentLanguage,
      dateFormat === 'month' ? {month: 'long'} : OPTIONS
    );
  }, [currentLanguage, dateFormat]);

  const formattedValue = useMemo(() => dateTimeFormat.format(value), [dateTimeFormat, value]);

  return (
    <Component color="$neutral500" {...rest}>
      {formattedValue}
    </Component>
  );
}
