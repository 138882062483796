import {useTranslate} from '@tolgee/react';
import {YStack} from 'tamagui';

import {Label3} from '@/components/texts/Label';
import {isUpvestCustomer} from '@/helpers/isUpvestCustomer';
import {ClearingAccountBlock} from '@/modules/orders/components/DepositMethod/ClearingAccountBlock';
import {DepositWireNote} from '@/modules/orders/components/DepositMethod/DepositWireNote';
import {WireQRCode} from '@/modules/orders/components/DepositMethod/WireQRCode';
import {useGetCustomerAccountQuery} from '@/store/queries/customersApi';

type Props = {
  customerId: string;
  amount?: string;
};

export const DepositWireMethodContent = ({customerId, amount}: Props) => {
  const {t} = useTranslate();

  const {data: customer} = useGetCustomerAccountQuery(customerId);

  const isUpvest = isUpvestCustomer(customer);

  return (
    <YStack gap="$4">
      <Label3>
        {t('ORDERS.DEPOSIT_METHOD.WIRE')}
        {isUpvest && <Label3 variant="medium">{t('ORDERS.DEPOSIT_METHOD.WIRE_UPVEST')}</Label3>}
      </Label3>
      <DepositWireNote isUpvest={isUpvest} />
      <Label3 color="$neutral500">{t('ORDERS.DEPOSIT_METHOD.WIRE.GIRO')}</Label3>
      <WireQRCode customerId={customerId} amount={amount ?? ''} />
      <ClearingAccountBlock customerId={customerId} />
    </YStack>
  );
};
