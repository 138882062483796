import {PropsWithChildren, forwardRef} from 'react';
import {DialogHandle, isWeb, useMedia, withStaticProperties} from 'tamagui';

import {Button} from '@/components/buttons/Button';
import {DialogDescriptionFrame, DialogTitleFrame} from '@/components/dialog/Text';
import {
  DialogBottomSheet,
  Root as DialogBottomSheetRoot,
} from '@/components/dialog/dialog-bottom-sheet/DialogBottomSheet';
import {Dialog, Root as DialogRoot} from '@/components/dialog/dialog-tamagui/Dialog';
import {
  DialogCloseProps,
  DialogContentProps,
  DialogIconCloseProps,
  DialogRootProps,
  DialogTriggerProps,
} from '@/types/dialog';
import {ViewRef} from '@/types/refs';

const ModalTrigger = forwardRef<ViewRef, DialogTriggerProps>((props, ref) => {
  const media = useMedia();

  if (!isWeb || media.sm) {
    return <DialogBottomSheet.Trigger ref={ref} {...props} />;
  }

  return <Dialog.Trigger ref={ref} {...props} />;
});

const ModalButtonTrigger = Button.styleable((props, forwardRef) => {
  const media = useMedia();

  if (!isWeb || media.sm) {
    return <DialogBottomSheet.ButtonTrigger ref={forwardRef} {...props} />;
  }

  return <Dialog.ButtonTrigger ref={forwardRef} {...props} />;
});

const ModalClose = forwardRef<ViewRef, DialogCloseProps>((props, ref) => {
  const media = useMedia();

  if (!isWeb || media.sm) {
    return <DialogBottomSheet.Close ref={ref} {...props} />;
  }

  return <Dialog.Close ref={ref} {...props} />;
});

const ModalButtonClose = Button.styleable((props, forwardRef) => {
  const media = useMedia();

  if (!isWeb || media.sm) {
    return <DialogBottomSheet.ButtonClose ref={forwardRef} {...props} />;
  }

  return <Dialog.ButtonClose ref={forwardRef} {...props} />;
});

const ModalIconButtonClose = forwardRef<ViewRef, DialogIconCloseProps>((props, ref) => {
  const media = useMedia();

  if (!isWeb || media.sm) return null;

  return <Dialog.IconClose ref={ref} {...props} />;
});

const ModalTitle = DialogTitleFrame;

const ModalDescription = DialogDescriptionFrame;

const ModalContent = ({children, ...rest}: PropsWithChildren<DialogContentProps>) => {
  const media = useMedia();

  if (!isWeb || media.sm) {
    return (
      <DialogBottomSheet.Content {...rest} keyboardBehavior="fillParent">
        {children}
      </DialogBottomSheet.Content>
    );
  }

  return <Dialog.Content {...rest}>{children}</Dialog.Content>;
};

const ModalRoot = forwardRef<DialogHandle, PropsWithChildren<DialogRootProps>>((props, ref) => {
  const media = useMedia();

  if (!isWeb || media.sm) {
    return <DialogBottomSheetRoot ref={ref} {...props} />;
  }

  return <DialogRoot ref={ref} {...props} />;
});

export const Modal = withStaticProperties(ModalRoot, {
  Content: ModalContent,
  Trigger: ModalTrigger,
  ButtonTrigger: ModalButtonTrigger,
  Close: ModalClose,
  ButtonClose: ModalButtonClose,
  IconClose: ModalIconButtonClose,
  Title: ModalTitle,
  Description: ModalDescription,
});
