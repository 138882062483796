import {IconButton} from '@/components/buttons/IconButton';
import {Label3} from '@/components/texts/Label';
import {useTranslate} from '@tolgee/react';
import {toast} from 'burnt';
import {setStringAsync} from 'expo-clipboard';
import React, {PropsWithChildren} from 'react';
import {ScrollView, View} from 'tamagui';

type LabelWithTextAndCopyButtonProps = {
  label: string;
  textColor?: any;
  copyLabel: string;
  copyText: string;
  backgroundColor?: any;
};

export function LabelWithTextAndCopyButton({
  label,
  copyLabel,
  textColor,
  copyText,
  backgroundColor,
}: PropsWithChildren<LabelWithTextAndCopyButtonProps>) {
  const {t} = useTranslate();

  return (
    <>
      <View gap="$3">
        <Label3 color="$white">{label}</Label3>
        <View
          flexDirection="row"
          padding="$2"
          alignContent="space-between"
          borderWidth={1}
          borderColor="$neutral300"
          borderRadius="$0.25"
          alignItems="center"
          backgroundColor={backgroundColor !== undefined ? backgroundColor : '$surface'}
          height={40}
        >
          <ScrollView horizontal height={20} scrollbarWidth="none">
            <Label3 numberOfLines={1} color={textColor}>
              {copyLabel}
            </Label3>
          </ScrollView>
          <IconButton
            name="copy"
            size={18}
            color={textColor !== undefined ? textColor : '$primary'}
            hoverColor="$primaryPressed"
            onPress={async () => {
              await setStringAsync(copyText).catch();
              toast({
                preset: 'done',
                title: t('LINK_COPIED'),
              });
            }}
          />
        </View>
      </View>
    </>
  );
}
