import React, {forwardRef} from 'react';
import {GetProps, View} from 'tamagui';

import SvgFile from '@/components/icons/SvgFile';
import {ViewRef} from '@/types/refs';
import {OpaqueColorValue} from 'react-native';

export type IconButtonProps = {
  onPress?: () => void;
  containerStyle?: GetProps<typeof View>;
  hoverColor?: string;
} & GetProps<typeof SvgFile>;

export const IconButton = forwardRef<ViewRef, IconButtonProps>(
  ({onPress, hoverColor, containerStyle, ...props}, ref) => (
    <View
      ref={ref}
      onPress={onPress}
      cursor="pointer"
      $group-hover={{
        backgroundColor: hoverColor ? (hoverColor as any as OpaqueColorValue) : '$neutral100',
      }}
      hoverStyle={{
        backgroundColor: hoverColor ? (hoverColor as any as OpaqueColorValue) : '$neutral100',
      }}
      pressStyle={{
        backgroundColor: hoverColor ? (hoverColor as any as OpaqueColorValue) : '$neutral100',
      }}
      borderRadius="$full"
      padding="$1"
      {...containerStyle}
    >
      <SvgFile color="$neutral900" {...props} />
    </View>
  )
);
