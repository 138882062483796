import {Button} from '@/components/buttons/Button';
import SvgFile from '@/components/icons/SvgFile';
import {Heading5} from '@/components/texts/Heading';
import {Label2} from '@/components/texts/Label';
import {Paragraph2} from '@/components/texts/Paragraph';
import {RemainingTimeChip} from '@/modules/mfa/components/RemainingTimeChip';
import {useMfaAvailability} from '@/modules/mfa/hooks/useMfaAvailability';
import {useGetMfaMethodsQuery, useSendCodeMutation} from '@/store/queries/authApi';
import {MfaMethodSmsResponse} from '@/types/api/auth';
import {MFAActionType} from '@/types/mfaAction';
import {ModalRef} from '@/types/modal';
import {captureException} from '@sentry/react-native';
import {useTranslate} from '@tolgee/react';
import {forwardRef, useEffect, useMemo, useState} from 'react';
import {ScrollView, Spinner, View, XStack, YStack} from 'tamagui';

type Props = {
  loading?: boolean;
  action: MFAActionType;
  onSuccess: (method: MfaMethodSmsResponse) => void;
};

export const MFAActionSelectNumber = forwardRef<ModalRef, Props>(
  ({loading, action, onSuccess}, ref) => {
    const {t} = useTranslate();
    const [selectedMethod, setSelectedMethod] = useState<MfaMethodSmsResponse>();
    const {
      data: methods,
      isFetching: isGetMfaMethodsFetching,
      isLoading: isGetMfaMethodsLoading,
    } = useGetMfaMethodsQuery();
    const {invalidMethod} = useMfaAvailability();

    useEffect(() => {
      if (methods && methods.length > 0 && selectedMethod === undefined)
        setSelectedMethod(methods[0]);
    }, [methods, selectedMethod]);

    const actionTranslation = useMemo(() => {
      switch (action.name) {
        case 'additional': {
          return (
            <Paragraph2 color="$neutral400">
              {t(`MFA.ACTION_BOTTOM_SHEET.ADD_DESCRIPTION`)}
            </Paragraph2>
          );
        }
        case 'deletion': {
          return (
            <Paragraph2 color="$neutral400">
              {t(`MFA.ACTION_BOTTOM_SHEET.DELETE_DESCRIPTION`)}
            </Paragraph2>
          );
        }
        default: {
          return (
            <Paragraph2 color="$neutral400">
              {t(`MFA.ACTION_BOTTOM_SHEET.ONE_TIME_CODE`)}
            </Paragraph2>
          );
        }
      }
    }, [t, action]);

    const [sendCodeTrigger, {isLoading: isSendCodeLoading}] = useSendCodeMutation();

    const handlePress = async () => {
      if (!selectedMethod || !selectedMethod.phoneNumber) return;

      try {
        await sendCodeTrigger({method: selectedMethod.id}).unwrap();

        onSuccess(selectedMethod);
      } catch (error) {
        captureException(error);
      }
    };

    const handleSelectPhone = (method: MfaMethodSmsResponse) => {
      if (selectedMethod?.id === method.id) return;

      setSelectedMethod(method);
    };

    if (!action) return <Spinner />;

    return (
      <YStack gap="$4">
        <ScrollView contentContainerStyle={{gap: '$4'}}>
          <Heading5 variant="medium">{t('MFA.ACTION_BOTTOM_SHEET.TITLE')}</Heading5>
          {actionTranslation}
          {methods &&
            methods.map((item, index) => {
              const selected = invalidMethod === undefined && item.id === selectedMethod?.id;
              return (
                <View
                  key={item.id}
                  cursor="pointer"
                  hoverStyle={{backgroundColor: '$neutral50'}}
                  padding="$2"
                  borderRadius="$1"
                  userSelect="none"
                  onPress={invalidMethod === undefined ? () => handleSelectPhone(item) : undefined}
                  borderWidth={1}
                  borderColor={selected ? '$primary' : '$neutral200'}
                >
                  <XStack gap="$4" justifyContent="space-between" alignItems="center">
                    <Label2 variant="medium">{item.phoneNumber}</Label2>
                    {selected && <SvgFile marginTop={4} name="check" size={18} color="$primary" />}
                    {invalidMethod !== undefined && (
                      <RemainingTimeChip validDate={invalidMethod.time} />
                    )}
                  </XStack>
                </View>
              );
            })}
        </ScrollView>
        <Button
          disabled={selectedMethod ? false : t('MFA.SELECTED-NUMBER-REQUIRED')}
          onPress={handlePress}
          isLoading={
            loading || isGetMfaMethodsFetching || isGetMfaMethodsLoading || isSendCodeLoading
          }
        >
          {t('MFA.ACTION_BOTTOM_SHEET.GET_SMS_CODE')}
        </Button>
      </YStack>
    );
  }
);
