import {useTranslate} from '@tolgee/react';
import {useCallback, useMemo, useRef, useState} from 'react';
import {XStack, YStack} from 'tamagui';

import {BankInfoForm} from '@/components/forms/BankInfoForm';
import {SelectInput} from '@/components/inputs/select-input/SelectInput';
import {BulletPoint} from '@/components/texts/BulletPoint';
import {Heading4} from '@/components/texts/Heading';
import {Label3} from '@/components/texts/Label';
import {Paragraph2} from '@/components/texts/Paragraph';
import {BankInfoSchemaType} from '@/hooks/useValidations';
import {SubmitFormRef} from '@/modules/interview/components/FinancialSituationForm';
import {InterviewContinueButton} from '@/modules/interview/components/InterviewContinueButton';
import useProductSearchParam from '@/modules/interview/hooks/useProductSearchParam';
import {useOnboardingSearchParams} from '@/modules/onboarding/hooks/useOnboardingSearchParams';
import {useAppDispatch, useAppSelector} from '@/store';
import {useGetSelfUserQuery, useGetSpecificUserQuery} from '@/store/queries/usersApi';
import {saveAccountSettings} from '@/store/reducers/onboarding';
import {skipToken} from '@reduxjs/toolkit/query';

type Props = {
  onSuccess: () => void;
  buttonLoading?: boolean;
};

export function UserBankScreen({onSuccess, buttonLoading}: Props) {
  const {t} = useTranslate();
  const {data: user} = useGetSelfUserQuery();
  const {childID} = useOnboardingSearchParams();
  const {data: childUser} = useGetSpecificUserQuery(childID ? {userID: childID} : skipToken);
  const product = useProductSearchParam();

  const dispatch = useAppDispatch();

  const accountSettings = useAppSelector(state => state.onboarding.personalDetails.account);
  const anotherGuardian = useAppSelector(state => state.onboarding.personalDetails.anotherGuardian);

  const ref = useRef<SubmitFormRef>(null);

  const onPress = useCallback(() => {
    ref.current?.submit();
  }, []);

  const accountHoldersNames = useMemo(() => {
    const result: any[] = [];
    if (product === 'CHILD_ACCOUNT') {
      if (user?.firstName && user?.lastName) {
        result.push({
          name: user?.firstName + ' ' + user?.lastName,
          key: user?.firstName + ' ' + user?.lastName,
        });
      }
      if (anotherGuardian?.firstName && anotherGuardian?.lastName) {
        result.push({
          name: anotherGuardian?.firstName + ' ' + anotherGuardian?.lastName,
          key: anotherGuardian?.firstName + ' ' + anotherGuardian?.lastName,
        });
      }
      result.push({
        name: childUser?.firstName + ' ' + childUser?.lastName,
        key: childUser?.firstName + ' ' + childUser?.lastName,
      });
    }
    return result;
  }, [
    anotherGuardian?.firstName,
    anotherGuardian?.lastName,
    childUser?.firstName,
    childUser?.lastName,
    product,
    user?.firstName,
    user?.lastName,
  ]);

  const [name, setName] = useState<string | undefined>(undefined);

  const onValidSubmit = useCallback(
    async (data: BankInfoSchemaType) => {
      dispatch(
        saveAccountSettings({iban: data.iban, bic: data.bic, bank: data.bank, holderFullName: name})
      );
      onSuccess();
    },
    [dispatch, name, onSuccess]
  );

  const bulletPoints = useMemo(() => {
    const items =
      product === 'VL_ACCOUNT'
        ? [t('ACCOUNT_SETUP.VL_IBAN.ROW1'), t('ACCOUNT_SETUP.VL_IBAN.ROW2')]
        : [
            t('ACCOUNT_SETUP.BANK_DESC_1'),
            t('ACCOUNT_SETUP.BANK_DESC_2'),
            t('ACCOUNT_SETUP.BANK_DESC_3'),
          ];

    return items.map(i => (
      <BulletPoint key={`iban_text_block_item_${i}`} color="$surface">
        <Paragraph2 color="$neutral400">{i}</Paragraph2>
      </BulletPoint>
    ));
  }, [product, t]);

  const headerText = useMemo(() => {
    if (product === 'VL_ACCOUNT') {
      return t('ACCOUNT_SETUP.TITLE_YOUR_BANK_VL_DETAILS');
    }
    if (product === 'CHILD_ACCOUNT') {
      return t('ACCOUNT_SETUP.TITLE_CHILD_BANK_DETAILS', {value: childUser?.firstName});
    }
    return t('ACCOUNT_SETUP.TITLE_YOUR_BANK_DETAILS');
  }, [childUser?.firstName, product, t]);

  return (
    <YStack gap="$6" marginTop="$6">
      <Heading4 variant="medium">{headerText}</Heading4>
      <BankInfoForm ref={ref} onValid={onValidSubmit} defaultValues={accountSettings} />
      <XStack>
        {accountHoldersNames.length > 1 && (
          <YStack gap="$2" flex={1}>
            <Label3 color="$neutral500">
              {t('ORDER.REFERENCE-ACCOUNT-CHANGE.ACCOUNT-HOLDER-LEGAL-OR-CHILD')}
            </Label3>
            <SelectInput
              items={accountHoldersNames}
              placeholder=""
              selectedItem={name ?? accountHoldersNames[0].key}
              onSelect={setName}
            />
          </YStack>
        )}
      </XStack>
      <YStack gap="$2">{bulletPoints}</YStack>
      <InterviewContinueButton isLoading={buttonLoading} onPress={onPress} />
    </YStack>
  );
}
