import {preparingHeader} from '@/store/queries/baseApi';
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const validationApi = createApi({
  reducerPath: 'validationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.EXPO_PUBLIC_API_VALIDATE_IBAN_BASE_PATH}`,
    prepareHeaders: preparingHeader,
  }),
  endpoints: build => ({
    getIbanStatus: build.query<IBan, string>({
      query: (iban: string) => ({
        url: `validate/${iban}`,
        params: {getBIC: true, validateBankCode: false},
      }),
    }),
  }),
});

export interface IBan {
  valid: boolean;
  messages: string[];
  iban: string;
  checkResults: any;
  bankData: IBank;
}

export interface IBank {
  bankCode: string;
  name: string;
  zip?: string;
  city?: string;
  bic?: string;
}

export const {useLazyGetIbanStatusQuery} = validationApi;
