import {useEffect, useState} from 'react';
import {AppState, AppStateStatus} from 'react-native';

export type AppStateHookSettings = {
  onChange?: (status: AppStateStatus) => void;
  onForeground?: () => void;
  onBackground?: () => void;
};

export default function useAppState(settings?: AppStateHookSettings) {
  const {onChange, onForeground, onBackground} = settings || {};
  const [appState, setAppState] = useState(AppState.currentState);

  useEffect(() => {
    function handleAppStateChange(nextAppState: AppStateStatus) {
      if (
        nextAppState === 'active' &&
        appState !== 'active' &&
        onForeground &&
        typeof onForeground === 'function'
      ) {
        onForeground();
      } else if (
        // TODO: this means we can only have either foreground or background, probably this is not wanted
        appState === 'active' &&
        /inactive|background/.test(nextAppState) &&
        onBackground &&
        typeof onBackground === 'function'
      ) {
        onBackground();
      }

      setAppState(nextAppState);

      if (onChange && typeof onChange === 'function') {
        onChange(nextAppState);
      }
    }

    const appStateSub = AppState.addEventListener('change', handleAppStateChange);

    return () => appStateSub.remove();
  }, [onChange, onForeground, onBackground, appState]);

  return {appState};
}
