import {useTranslate} from '@tolgee/react';
import {Redirect, Stack, useLocalSearchParams} from 'expo-router';
import {useMemo} from 'react';
import {Platform} from 'react-native';

import {ResponsiveScrollView} from '@/components/views/ResponsiveScrollView';
import {Header} from '@/modules/navigation/components/header/Header';
import {Tabbar} from '@/modules/navigation/components/tabbar/Tabbar';
import {AllocationBarChart} from '@/modules/portfolio/components/AllocationBarChart';
import {useGetCustomerAccountQuery} from '@/store/queries/customersApi';
import {useMedia} from 'tamagui';

export default function AllocationPage() {
  const media = useMedia();
  const {customerId} = useLocalSearchParams<{customerId: string}>();
  const {data: customer} = useGetCustomerAccountQuery(customerId);
  const {t} = useTranslate();
  const elements = useMemo(() => {
    const elements = [
      {
        label: t('PORTFOLIO.ASSET-CLASSES'),
      },
      {
        label: t('PORTFOLIO.INDUSTRIES-2'),
      },
      {
        label: t('PORTFOLIO.REGIONS'),
      },
    ];
    if (customer?.product === 'THEME_ACCOUNT') {
      elements.push({
        label: t('PORTFOLIO.THEMES'),
      });
    }
    return elements;
  }, [customer?.product, t]);

  if (!media.sm)
    return <Redirect href={{pathname: '/overview/[customerId]', params: {customerId}}} />;

  return (
    <ResponsiveScrollView backgroundColor="$surface">
      <Stack.Screen
        options={{
          headerShown: true,
          header: () => (
            <Header title={t('OVERVIEW.PORTFOLIO-ALLOCATION')} backButton={Platform.OS !== 'web'} />
          ),
        }}
      />
      <Tabbar elements={elements}>
        <AllocationBarChart groupType="assets" customerId={customerId} />
        <AllocationBarChart groupType="industry" customerId={customerId} />
        <AllocationBarChart groupType="region" customerId={customerId} />
        {customer?.product === 'THEME_ACCOUNT' && (
          <AllocationBarChart groupType="trends" customerId={customerId} />
        )}
      </Tabbar>
    </ResponsiveScrollView>
  );
}
