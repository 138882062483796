/**
 * Converts any string date to format YYYY-MM-DD
 */
export const convertDateToFormat = (date: string | Date) => {
  const parsedDate = date instanceof Date ? date : new Date(date);
  const formatedYear = parsedDate.getFullYear();
  const formatedMonth = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
  const formatedDay = parsedDate.getDate().toString().padStart(2, '0');
  return `${formatedYear}-${formatedMonth}-${formatedDay}`;
};
