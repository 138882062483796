import {AccordionContent} from '@/components/inputs/AccordionContent';
import {AccordionTrigger} from '@/components/inputs/AccordionTrigger';
import {Label2} from '@/components/texts/Label';
import {Skeleton} from '@/components/views/Skeleton';
import {SecurityDetails} from '@/modules/portfolio/components/SecurityDetails';
import {RatioItem} from '@/modules/portfolio/hooks/useGetAssetAllocation';
import React from 'react';
import {Accordion, XStack, YStack} from 'tamagui';
import {RatioItemRow} from './RatioItemRow';

type Props = {
  ratios: RatioItem[];
  preview?: boolean;
};

export const RatioItemRows = ({ratios, preview}: Props) => {
  if (ratios.length === 0) {
    return (
      <YStack gap="$5">
        <XStack justifyContent="space-between">
          <Skeleton width={100} height={32} />
          <Skeleton width={75} height={32} />
        </XStack>
        <XStack justifyContent="space-between">
          <Skeleton width={100} height={32} />
          <Skeleton width={75} height={32} />
        </XStack>
        <XStack justifyContent="space-between">
          <Skeleton width={100} height={32} />
          <Skeleton width={75} height={32} />
        </XStack>
        <XStack justifyContent="space-between">
          <Skeleton width={100} height={32} />
          <Skeleton width={75} height={32} />
        </XStack>
      </YStack>
    );
  }

  if (preview) {
    return ratios.slice(-5).map(item => {
      const {key, ...itemWithoutKey} = item;
      return (
        <RatioItemRow key={key} {...itemWithoutKey} amount={undefined} textComponent={Label2} />
      );
    });
  }

  return (
    <Accordion overflow="hidden" type="multiple">
      {ratios.map(({key, title, color, ratio, securities, amount}) => {
        if (securities) {
          const sortedSecurities = Object.values(securities).sort((a, b) => b.ratio - a.ratio);
          return sortedSecurities.map(sec => {
            return (
              <Accordion.Item
                key={`${sec.code}-${key}-accordion-item`}
                value={`${sec.code}-${key}-accordion-item`}
              >
                <AccordionTrigger padding={0}>
                  {({open}: {open: boolean}) => (
                    <>
                      <RatioItemRow
                        title={sec.assetLabel}
                        color={color}
                        ratio={sec.ratio}
                        amount={sec.amount}
                        key={`${sec.code}-${key}-accordion-item-row`}
                        open={open}
                      />
                    </>
                  )}
                </AccordionTrigger>
                <AccordionContent>
                  <SecurityDetails security={sec} />
                </AccordionContent>
              </Accordion.Item>
            );
          });
        } else {
          return (
            <RatioItemRow title={title} color={color} key={key} ratio={ratio} amount={amount} />
          );
        }
      })}
    </Accordion>
  );
};
