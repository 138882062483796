import {baseApi} from '@/store/queries/baseApi';
import {PostidentResponse} from '@/types/api/identity';

const BASE_PATH = 'identity';
// Todo: Add handler api for this

const extendedIdentityApi = baseApi.injectEndpoints({
  endpoints: build => ({
    getPostident: build.query<Required<PostidentResponse>, number>({
      query: (userId: number) => ({
        url: `${BASE_PATH}/postident/`,
        params: {userId},
      }),
      transformResponse: (response: any[]) => {
        return response[0] as Required<PostidentResponse>;
      },
    }),
  }),
});

export const {useGetPostidentQuery, useLazyGetPostidentQuery} = extendedIdentityApi;
