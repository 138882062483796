import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslate} from '@tolgee/react';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {View, XStack, useMedia} from 'tamagui';

import {Button} from '@/components/buttons/Button';
import {NonEditableInput} from '@/components/inputs/NonEditableInput';
import {FormTextInput} from '@/components/inputs/form/FormTextInput';
import {Label3} from '@/components/texts/Label';
import {useShowViolationToast} from '@/hooks/useShowViolationToast';
import {useValidations} from '@/hooks/useValidations';
import {ConfirmEmailChangeModal} from '@/modules/profile/components/ConfirmEmailChangeModal';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useChangeEmailMutation} from '@/store/queries/emailVerifyApi';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';
import {ModalRef} from '@/types/modal';

type ChangeEmailProps = {
  onClose?: () => void;
};

export const ChangeEmail = ({onClose}: ChangeEmailProps) => {
  const {t} = useTranslate();
  const media = useMedia();
  const ref = useRef<ModalRef>(null);
  const newEmail = useRef<string | null>(null);
  const [pollEmails, setPollEmails] = useState(false);
  const {data: user} = useGetSelfUserQuery();
  useGetSelfUserQuery(undefined, {
    pollingInterval: 2000,
    skipPollingIfUnfocused: true,
    skip: !pollEmails,
  });
  const {showViolationToast} = useShowViolationToast();
  const currentEmail = useMemo(() => user?.email, [user]);
  const {changeEmailFormSchema} = useValidations();
  const {handleSubmit, control, reset, getValues} = useForm({
    resolver: yupResolver(changeEmailFormSchema),
    context: {currentEmail},
    mode: 'onChange',
  });
  const posthog = usePostHog();
  const [changeEmailTrigger] = useChangeEmailMutation();

  useEffect(() => {
    reset();
  }, [currentEmail, reset]);

  const cancelEmailChange = useCallback(() => {
    reset();
    onClose?.();
  }, [onClose, reset]);

  const onSubmit = useCallback(async () => {
    try {
      newEmail.current = getValues('newEmail');
      await changeEmailTrigger({newEmail: newEmail.current}).unwrap();
      ref.current?.open(true);
    } catch (error) {
      showViolationToast(error);
    }
  }, [changeEmailTrigger, getValues, showViolationToast]);

  useEffect(() => {
    if (user && user.email === newEmail.current) {
      setPollEmails(false);
    }
  }, [currentEmail, user]);

  const onSuccess = useCallback(() => {
    setPollEmails(true);
    onClose?.();
  }, [onClose]);

  return (
    <View gap="$6">
      <View gap="$4">
        <View flexDirection={media.sm ? 'column' : 'row'} gap="$4">
          <NonEditableInput
            label={t('PROFILE.SECURITY.CHANGE-EMAIL.CURRENT')}
            text={currentEmail || ''}
            bordered
          />
          <FormTextInput
            control={control}
            label={t('PROFILE.SECURITY.CHANGE-EMAIL.NEW')}
            name="newEmail"
            bordered
          />
        </View>
        <Label3 color="$neutral400">{t('PROFILE.SECURITY.CHANGE-EMAIL.NOTE')}</Label3>
      </View>
      <XStack gap="$7">
        <Button
          onPress={handleSubmit(onSubmit, data => {
            posthog?.capture('form_submit_failed', data);
          })}
        >
          {t('NAVIGATION.PROFILE.TAXES.BUTTON.SAVE-CHANGES')}
        </Button>
        <Button tertiary onPress={cancelEmailChange}>
          {t('NAVIGATION.PROFILE.TAXES.BUTTON.CANCEL')}
        </Button>
      </XStack>
      <ConfirmEmailChangeModal
        ref={ref}
        onSuccess={onSuccess}
        newEmail={(newEmail.current = getValues('newEmail'))}
      />
    </View>
  );
};
