import {PropsWithChildren} from 'react';

import {TamaguiProvider} from 'tamagui';

import {useAppSelector} from '@/store';
import {useColorScheme} from 'react-native';
import tamaguiConfig from '../../tamagui.config';

export function CustomThemeProvider({children}: PropsWithChildren) {
  const colorScheme = useColorScheme();
  const theme = useAppSelector(state => state.app.theme);

  return (
    <TamaguiProvider
      config={tamaguiConfig}
      defaultTheme={theme === 'system' ? (colorScheme as string) : theme}
    >
      {children}
    </TamaguiProvider>
  );
}
