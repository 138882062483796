import {Accordion} from 'tamagui';

export function AccordionContent({children}: {children: React.ReactNode}) {
  return (
    <Accordion.HeightAnimator animation="slow">
      <Accordion.Content animation="slow" exitStyle={{opacity: 0}}>
        {children}
      </Accordion.Content>
    </Accordion.HeightAnimator>
  );
}
