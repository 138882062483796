import {IconButton} from '@/components/buttons/IconButton';
import {Card} from '@/components/cards/Card';
import {Heading5} from '@/components/texts/Heading';

import {WithLabel} from '@/components/texts/WithLabel';
import {Href, useRouter} from 'expo-router';
import React, {ReactElement} from 'react';
import {XStack} from 'tamagui';

type SummaryCardProps = {
  title: string;
  href?: Href;
  row: Record<string, ReactElement | null>;
};

export function SummaryCard({title, href, row}: SummaryCardProps) {
  const router = useRouter();

  return (
    <Card gap="$3" minWidth={300} width="100%">
      <XStack justifyContent="space-between" alignItems="flex-start">
        <Heading5 variant="medium" paddingVertical="$2">
          {title}
        </Heading5>
        {href && <IconButton name="edit" color="$primary" onPress={() => router.push(href)} />}
      </XStack>
      {Object.entries(row)
        .filter(_ => _[1] != null)
        .map(([key, element]) => (
          <WithLabel key={key} header={key} horizontal>
            {element}
          </WithLabel>
        ))}
    </Card>
  );
}
