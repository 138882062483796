import {useTranslate} from '@tolgee/react';
import {useLocalSearchParams} from 'expo-router';

import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {InvestmentProposalDepositForm} from '@/modules/interview/components/InvestmentProposalDepositForm';
import {TipWithSideModal} from '@/modules/interview/components/TipWithSideModal';
import {InvestTipModalContent} from '@/modules/interview/components/planner/InvestTipModalContent';
import {Product} from '@/types/fixedTypes/customers.v2/Product';

type Params = {
  product: Product;
  riskProfile?: string;
};

export default function DepositsPage() {
  const {t} = useTranslate();

  const {product, riskProfile} = useLocalSearchParams<Params>();

  return (
    <FullscreenScrollView variant="small" useKeyboardAwareScrollView>
      <InvestmentProposalDepositForm product={product} riskProfile={riskProfile} />
      <TipWithSideModal
        tipTitle={t('INVESTMENT_PLANNER.HOW_MUCH_I_SHALL_INVEST.TEXT')}
        tipContent={<InvestTipModalContent />}
      />
    </FullscreenScrollView>
  );
}
