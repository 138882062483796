import {Button} from '@/components/buttons/Button';
import {IconButton} from '@/components/buttons/IconButton';
import {useAppSelector} from '@/store';
import {useTranslate} from '@tolgee/react';
import React, {useCallback} from 'react';
import {Linking} from 'react-native';
import {useMedia} from 'tamagui';

export function BookCallButton() {
  const {t} = useTranslate();
  const locale = useAppSelector(state => state.app.language);
  const media = useMedia();

  const onPress = useCallback(() => {
    Linking.openURL(
      locale === 'de'
        ? process.env.EXPO_PUBLIC_MAILTO_CALENDLY_DE
        : process.env.EXPO_PUBLIC_MAILTO_CALENDLY_EN
    );
  }, [locale]);

  if (media.sm) {
    return <IconButton name="call" onPress={onPress} color="$primary" />;
  } else {
    return (
      <Button
        secondary
        onPress={onPress}
        hoverStyle={{
          opacity: 0.8,
        }}
      >
        {t('BOOK_RECALL')}
      </Button>
    );
  }
}
