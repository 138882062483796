import {styled, YStack} from 'tamagui';

export const Surface = styled(YStack, {
  backgroundColor: '$surface',
  borderRadius: '$1',
  shadowColor: '$shadow',
  shadowOffset: {width: 0, height: 4},
  shadowOpacity: 0.4,
  shadowRadius: '$2',
  elevationAndroid: 1,
});
