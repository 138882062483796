import {useIsFocused} from '@react-navigation/native';
import {useCallback, useLayoutEffect, useRef} from 'react';
import {useIsomorphicLayoutEffect} from 'tamagui';

type Options = {
  // delay in ms to use for the interval method
  delay?: number;
  // should interval be cleared and started on route focus?
  focus?: boolean;
  // method to call when interval is cleared
  onClear?: () => void;
};

export const useInterval = (callback: () => void, {delay, focus = true, onClear}: Options) => {
  const savedCallback = useRef(callback);
  const isFocused = useIsFocused();

  // Remember the latest callback if it changes.
  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const intervalFunction = useCallback(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (delay === undefined) return undefined;

    return setInterval(() => {
      savedCallback.current();
    }, delay);
  }, [delay]);

  const handleClearInterval = useCallback(
    (id?: NodeJS.Timeout) => {
      clearInterval(id);

      if (onClear) {
        onClear();
      }
    },
    [onClear]
  );

  useLayoutEffect(() => {
    if (focus) return () => {};

    const id = intervalFunction();

    return () => {
      handleClearInterval(id);
    };
  }, [focus, intervalFunction, handleClearInterval]);

  useLayoutEffect(() => {
    let id: NodeJS.Timeout | undefined;
    if (isFocused) {
      if (!focus) return () => {};

      id = intervalFunction();
    }
    return () => {
      if (id) handleClearInterval(id);
    };
  }, [focus, handleClearInterval, intervalFunction, isFocused]);
};
