import SvgFile from '@/components/icons/SvgFile';
import {SelectInput} from '@/components/inputs/select-input/SelectInput';
import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {useGetTransactionsSummaryQuery} from '@/store/queries/apeironApi';
import {
  useGetCustomerInboxSummariesQuery,
  useGetCustomerOrdersSummaryQuery,
} from '@/store/queries/customersApi';
import {
  useInboxDocumentCategoriesQuery,
  useInboxDocumentSourcesQuery,
  useOrderTypesQuery,
  useTransactionCategoriesQuery,
} from '@/store/queries/referenceApi';
import {
  InboxDocumentCategory,
  InboxDocumentSource,
  OrderType,
  TransactionCategory,
} from '@/types/api/refdata.v2';
import {SelectInputItem} from '@/types/FormInputProps';
import {skipToken} from '@reduxjs/toolkit/query';
import {useTranslate} from '@tolgee/react';
import {PropsWithChildren, useCallback, useEffect, useState} from 'react';
import {useMedia, View} from 'tamagui';

type FilterVariantProps = Pick<FilterProps, 'onFilterSelected' | 'customerId' | 'selectedItem'>;

export type FilterProps = {
  onFilterSelected: (option: string) => void;
  customerId: string | undefined;
  data:
    | (TransactionCategory | OrderType | InboxDocumentCategory | InboxDocumentSource)[]
    | undefined;
  filterCodes?: {
    code: string;
  }[];
  selectedItem?: string;
};

function FilterSelectBase({
  onFilterSelected,
  data,
  filterCodes,
  selectedItem,
}: PropsWithChildren<FilterProps>) {
  const {getByCode, getAll} = useRefdataLocalizable();
  const {t} = useTranslate();
  const media = useMedia();
  const [items, setItems] = useState<SelectInputItem[]>([]);

  useEffect(() => {
    if (!data) return;

    const tmpItems: SelectInputItem[] = [];
    if (filterCodes) {
      filterCodes.forEach(t => {
        const category = getByCode(data, t.code);
        if (!category) return;
        tmpItems.push({name: category.label, key: category.code});
      });
    } else {
      const allOptions = getAll(data);

      if (allOptions) {
        allOptions.forEach(({code, label}) => {
          tmpItems.push({name: label, key: code});
        });
      }
    }

    tmpItems?.push({name: t('OVERVIEW.CHART-PERIODS.ALL'), key: 'ALL'});
    setItems(tmpItems);
  }, [data, filterCodes, getAll, getByCode, t]);

  const onSelect = useCallback(
    (option: string) => {
      onFilterSelected(option);
    },
    [onFilterSelected]
  );

  useEffect(() => {
    if (items.filter(_ => _.key === selectedItem).length === 0) {
      onSelect('ALL');
    }
  }, [items, onSelect, selectedItem]);

  return (
    <View alignSelf="flex-end">
      <SelectInput
        items={items}
        placeholder={t('OVERVIEW.CHART-PERIODS.ALL')}
        onSelect={onSelect}
        wrapperProps={{unstyled: true, alignItems: 'flex-end'}}
        selectedItem={selectedItem}
      >
        {media.sm && <SvgFile name="filter" color="$primary" />}
      </SelectInput>
    </View>
  );
}

function FilterSelectTransactions({
  onFilterSelected,
  customerId,
  category,
}: FilterVariantProps & {category: string}) {
  const {data: usedTransactions} = useGetTransactionsSummaryQuery(customerId || skipToken);
  const {data: transactionCategories} = useTransactionCategoriesQuery();
  if (!usedTransactions) return <View />;

  return (
    <FilterSelectBase
      customerId={customerId}
      onFilterSelected={onFilterSelected}
      data={transactionCategories}
      filterCodes={usedTransactions?.map(_ => ({code: _.transactionCategory}))}
      selectedItem={category}
    />
  );
}

function FilterSelectOrders({
  onFilterSelected,
  customerId,
  category,
}: FilterVariantProps & {category: string}) {
  const {data: orderTypes} = useOrderTypesQuery();
  const {data: orderTypesSummary} = useGetCustomerOrdersSummaryQuery(
    customerId ? {customerId} : skipToken
  );

  const tmp = orderTypes?.filter(_ =>
    orderTypesSummary?.items.some(ots => ots.orderType === _.code)
  );
  return (
    <FilterSelectBase
      customerId={customerId}
      onFilterSelected={onFilterSelected}
      data={tmp}
      selectedItem={category}
    />
  );
}

function FilterSelectDocumentCategories({
  onFilterSelected,
  customerId,
  sourceFilter,
  documentCategory,
}: FilterVariantProps & {
  sourceFilter: string;
  documentCategory: string;
}) {
  const {data: categories} = useInboxDocumentCategoriesQuery();
  const {data: inboxDocumentCategories} = useGetCustomerInboxSummariesQuery(
    customerId || skipToken
  );
  if (!inboxDocumentCategories) return <View />;

  const filteredCategories = categories?.filter(_ =>
    inboxDocumentCategories.items.some(idc => {
      if (sourceFilter === 'ALL') {
        return idc.category === _.code;
      } else {
        return idc.category === _.code && idc.source === sourceFilter;
      }
    })
  );
  return (
    <FilterSelectBase
      customerId={customerId}
      onFilterSelected={onFilterSelected}
      data={filteredCategories}
      selectedItem={documentCategory}
    />
  );
}

function FilterSelectDocumentSources({
  onFilterSelected,
  customerId,
  documentSource,
}: FilterVariantProps & {documentSource: string}) {
  const {data: inboxDocumentSources} = useInboxDocumentSourcesQuery();
  const {data: inboxDocumentCategories} = useGetCustomerInboxSummariesQuery(
    customerId || skipToken
  );
  if (!inboxDocumentCategories) return null;

  return (
    <FilterSelectBase
      customerId={customerId}
      onFilterSelected={onFilterSelected}
      data={inboxDocumentSources?.filter(_ =>
        inboxDocumentCategories.items.some(idc => idc.source === _.code)
      )}
      selectedItem={documentSource}
    />
  );
}

export const FilterSelect = {
  Base: FilterSelectBase,
  Transactions: FilterSelectTransactions,
  Orders: FilterSelectOrders,
  DocumentCategories: FilterSelectDocumentCategories,
  DocumentSources: FilterSelectDocumentSources,
};
