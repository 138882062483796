import {Href} from 'expo-router';
import {useMemo} from 'react';

import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {Skeleton} from '@/components/views/Skeleton';
import {UserSummaryScreen} from '@/modules/onboarding/components/summary/UserSummaryScreen';
import {UserSummaryScreenChild} from '@/modules/onboarding/components/summary/UserSummaryScreenChild';
import {useOnboardingSearchParams} from '@/modules/onboarding/hooks/useOnboardingSearchParams';

const SummaryScreen = () => {
  const params = useOnboardingSearchParams();

  if (params.onboardingType === 'second_guardian' && !params.orderId) {
    throw new Error('Missing params');
  }

  const href = useMemo<Href>(() => {
    const newParams = {
      ...params,
      screenType:
        params.onboardingType === 'second_guardian' && !params.screenType ? 'child' : undefined,
    };

    if (params.product === 'VL_ACCOUNT') {
      return {
        pathname: '/onboarding/personal-details/employer-instructions',
        params: newParams,
      };
    }

    if (params.product === 'CHILD_ACCOUNT' && params.onboardingType !== 'second_guardian') {
      return {
        pathname: '/onboarding/personal-details/another-guardian',
        params: newParams,
      };
    }

    if (params.onboardingType === 'second_guardian' && !params.screenType) {
      return {
        pathname: '/onboarding/personal-details/summary',
        params: newParams,
      };
    }

    return {
      pathname: '/onboarding/personal-details/agreements',
      params: newParams,
    };
  }, [params]);

  return (
    <FullscreenScrollView variant="large">
      <Skeleton width="100%" height={200}>
        {params.onboardingType === 'second_guardian' && params.screenType === 'child' ? (
          <UserSummaryScreenChild
            href={{
              pathname: '/onboarding/personal-details/agreements',
              params,
            }}
            orderId={params.orderId!}
          />
        ) : (
          <UserSummaryScreen href={href} params={params} />
        )}
      </Skeleton>
    </FullscreenScrollView>
  );
};

export default SummaryScreen;
