import {Heading4} from '@/components/texts/Heading';
import {Label2} from '@/components/texts/Label';
import {AnimatedBounceView} from '@/components/views/animated/AnimatedBounceView';
import {FullScreenCenterView} from '@/components/views/FullScreenCenterView';
import {LoadingSpinner} from '@/components/views/LoadingSpinner';
import {useTokenUserInfo} from '@/hooks/useTokenUserInfo';
import {useTranslate} from '@tolgee/react';
import {Image} from 'expo-image';
import React from 'react';
import {View, XStack} from 'tamagui';

type EmailVerificationStartProps = {
  isResending: boolean;
  onEmailResend: () => void;
};

export function EmailVerificationStart({isResending, onEmailResend}: EmailVerificationStartProps) {
  const {t} = useTranslate();
  const userInfo = useTokenUserInfo();

  return (
    <FullScreenCenterView>
      <AnimatedBounceView>
        <Image
          source={require('@/assets/images/mail-start.png')}
          style={{width: 121, height: 120}}
        />
      </AnimatedBounceView>
      <Heading4 variant="medium" textAlign="center">
        {t('EMAIL_VERIFICATION.TITLE')}
      </Heading4>
      {userInfo?.email && (
        <Label2 color="$neutral500" textAlign="center">
          {t('EMAIL_VERIFICATION.DESCRIPTION', '', {emailvar: userInfo?.email})}
        </Label2>
      )}
      <XStack alignItems="center">
        <Label2 color="$neutral500">{t('EMAIL_VERIFICATION.RESEND_EMAIL_DESCRIPTION')}</Label2>
        {isResending ? (
          <View marginHorizontal="$1">
            <LoadingSpinner color="$primary" size={16} />
          </View>
        ) : (
          <Label2
            variant="medium"
            color="$primary"
            marginLeft="$2"
            cursor="pointer"
            hoverStyle={{opacity: 0.8}}
            onPress={onEmailResend}
          >
            {t('EMAIL_VERIFICATION.RESEND_EMAIL')}
          </Label2>
        )}
      </XStack>
    </FullScreenCenterView>
  );
}
