import SvgFile from '@/components/icons/SvgFile';
import {Label2} from '@/components/texts/Label';
import {useTranslate} from '@tolgee/react';
import {XStack} from 'tamagui';

type Props = {
  onPress: () => void;
  label: string;
};

export const OnboardingAddItem = ({onPress, label}: Props) => {
  const {t} = useTranslate();

  return (
    <XStack
      alignItems="center"
      gap="$2"
      alignSelf="flex-start"
      marginTop="$2"
      onPress={onPress}
      cursor="pointer"
      hoverStyle={{opacity: 0.8}}
      pressStyle={{opacity: 0.8}}
    >
      <SvgFile name="plus-filled" size={20} color="$primary" />
      <Label2 color="$primary">{label}</Label2>
    </XStack>
  );
};
