import {useTranslate} from '@tolgee/react';
import {useMemo} from 'react';
import {YStack} from 'tamagui';

import {Heading5} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {TipWithSideModal} from '@/modules/interview/components/TipWithSideModal';
import {UserAddressScreen} from '@/modules/onboarding/components/address/UserAddressScreen';
import {useChildScreen} from '@/modules/onboarding/hooks/useChildScreen';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';

const AddressScreen = () => {
  const {
    params: {screenType, childID, onboardingType},
    nextParams,
  } = useChildScreen();
  const {data: selfUser} = useGetSelfUserQuery();
  const {t} = useTranslate();
  const tipContent = useMemo(
    () => (
      <YStack gap="$5" marginVertical="$5">
        <Heading5 variant="medium">{t('PERSONAL_DETAILS.ADDRESS_FAQ.HEADER')}</Heading5>
        <Paragraph2 color="$neutral400">{t('PERSONAL_DETAILS.ADDRESS_FAQ.LINE_1')}</Paragraph2>
        <Paragraph2 color="$neutral400">{t('PERSONAL_DETAILS.ADDRESS_FAQ.LINE_2')}</Paragraph2>
        <Paragraph2 color="$neutral400">{t('PERSONAL_DETAILS.ADDRESS_FAQ.LINE_3')}</Paragraph2>
        <Paragraph2 color="$neutral400">{t('PERSONAL_DETAILS.ADDRESS_FAQ.LINE_4')}</Paragraph2>
      </YStack>
    ),
    [t]
  );

  return (
    <FullscreenScrollView variant="small" useKeyboardAwareScrollView>
      <UserAddressScreen
        href={
          selfUser?.complete && childID !== undefined
            ? {
                pathname: '/onboarding/personal-details/tax',
                params: nextParams,
              }
            : {
                pathname: '/onboarding/personal-details/job',
                params: {...nextParams, screenType: undefined},
              }
        }
        childScreenHref={{pathname: '/onboarding/personal-details/address', params: nextParams}}
        childID={childID}
        isChildScreen={screenType === 'child' && !onboardingType}
      />
      <TipWithSideModal tipTitle={t('ONBOARDING.CAN-I-OPEN-NOT')} tipContent={tipContent} />
    </FullscreenScrollView>
  );
};

export default AddressScreen;
