import {ResponsiveScrollView} from '@/components/views/ResponsiveScrollView';
import {Header} from '@/modules/navigation/components/header/Header';
import {MFAStatusOverview} from '@/modules/profile/components/MFAStatusOverview';
import {useTranslate} from '@tolgee/react';
import {Stack} from 'expo-router';
import {Platform} from 'react-native';
import {YStack} from 'tamagui';

export default function MFAPage() {
  const {t} = useTranslate();
  return (
    <ResponsiveScrollView>
      <YStack gap="$5">
        <Stack.Screen
          options={{
            headerShown: true,
            header: () => (
              <Header title={t('PROFILE.SECURITY.MFA')} backButton={Platform.OS !== 'web'} />
            ),
          }}
        />
        <MFAStatusOverview />
      </YStack>
    </ResponsiveScrollView>
  );
}
