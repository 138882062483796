import {Label3sm2} from '@/components/texts/Label';
import {Href, Link} from 'expo-router';
import {PropsWithChildren} from 'react';

type CustomLinkProps = {
  href: Href;
};

export function CustomLink({href, children}: PropsWithChildren<CustomLinkProps>) {
  return (
    <Link href={href}>
      {typeof children === 'string' ? (
        <Label3sm2
          color="$primary"
          hoverStyle={{
            color: '$primaryHover',
          }}
        >
          {children}
        </Label3sm2>
      ) : (
        children
      )}
    </Link>
  );
}
