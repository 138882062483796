import {useTranslate} from '@tolgee/react';
import {Redirect, Stack, useLocalSearchParams} from 'expo-router';
import {Platform} from 'react-native';
import {XStack, useMedia} from 'tamagui';

import SvgFile from '@/components/icons/SvgFile';
import {Label2} from '@/components/texts/Label';
import {ResponsiveScrollView} from '@/components/views/ResponsiveScrollView';
import {Header} from '@/modules/navigation/components/header/Header';
import {PerformanceCharts} from '@/modules/overview/components/PerformanceCharts';
import {PerformanceDetailsModal} from '@/modules/overview/components/PerformanceDetailsModal';

export default function PerformancePage() {
  const {t} = useTranslate();

  const media = useMedia();
  const {customerId, tab} = useLocalSearchParams<{
    customerId: string;
    tab: 'balance' | 'performance';
  }>();

  // return to overview when switching from mboile to desktop view
  if (media.gtSm)
    return <Redirect href={{pathname: '/overview/[customerId]', params: {customerId}}} />;

  return (
    <ResponsiveScrollView backgroundColor="$surface">
      <Stack.Screen
        options={{
          headerShown: true,
          header: () => (
            <Header title={t('PORTFOLIO.PERFORMANCE')} backButton={Platform.OS !== 'web'} />
          ),
        }}
      />
      <PerformanceCharts customerId={customerId} tab={tab} />
      <PerformanceDetailsModal customerId={customerId}>
        <XStack
          marginHorizontal="$2"
          marginBottom="$4"
          padding="$5"
          backgroundColor="$neutralBG"
          borderRadius="$1"
          alignItems="center"
          justifyContent="space-between"
        >
          <Label2 color="$primary">{t('OVERVIEW.PERFORMANCE-DETAILS')}</Label2>
          <SvgFile name="chevron-right" size={16} color="$primary" />
        </XStack>
      </PerformanceDetailsModal>
    </ResponsiveScrollView>
  );
}
