import {LanguageSvg} from '@/components/icons/LanguageSvg';
import SvgFile from '@/components/icons/SvgFile';
import {SelectInput} from '@/components/inputs/select-input/SelectInput';
import {useAppDispatch, useAppSelector} from '@/store';
import {setLanguage} from '@/store/reducers/app';
import {SelectInputItem} from '@/types/FormInputProps';
import {LanguagesType} from '@/types/languages';
import {useTranslate} from '@tolgee/react';
import React, {useMemo} from 'react';
import {GetProps, XStack} from 'tamagui';

export function HeaderLanguageSelector({
  wrapperProps,
}: {
  wrapperProps?: GetProps<typeof SelectInput>['wrapperProps'];
}) {
  const language = useAppSelector(state => state.app.language);
  const dispatch = useAppDispatch();
  const {t} = useTranslate();
  const items = useMemo(
    () =>
      [
        {
          key: 'de',
          name: t('SETTINGS.LANGUAGE.DE'),
          icon: 'flag-de',
        },
        {
          key: 'en',
          name: t('SETTINGS.LANGUAGE.EN'),
          icon: 'flag-gb',
        },
      ] satisfies SelectInputItem[],
    [t]
  );

  return (
    <SelectInput
      items={items}
      placeholder={t('SETTINGS.SELECT_APP_LANGUAGE')}
      onSelect={(item: string) => {
        dispatch(setLanguage(item as LanguagesType));
      }}
      selectedItem={language}
      cardProps={{
        minWidth: 150,
      }}
      align="center"
      wrapperProps={{
        unstyled: true,
        ...wrapperProps,
      }}
    >
      <XStack
        hoverStyle={{
          opacity: 0.8,
        }}
        gap="$1"
      >
        <LanguageSvg language={language} />
        {/* <SvgFile size={24} name={language === 'de' ? 'flag-de' : 'flag-gb'} /> */}
        <SvgFile size={18} name="chevron-down" />
      </XStack>
    </SelectInput>
  );
}
