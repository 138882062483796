import {useTranslate} from '@tolgee/react';
import {Stack, useLocalSearchParams} from 'expo-router';
import {Platform} from 'react-native';

import {ResponsiveScrollView} from '@/components/views/ResponsiveScrollView';
import {Header} from '@/modules/navigation/components/header/Header';
import {AccountDetails} from '@/modules/profile/components/AccountDetails';

export default function Profile() {
  const {t} = useTranslate();
  const {customerId} = useLocalSearchParams<{customerId?: string}>();

  return (
    <ResponsiveScrollView>
      <Stack.Screen
        options={{
          headerShown: true,
          header: () => (
            <Header title={t('ACCOUNT.INFORMATION')} backButton={Platform.OS !== 'web'} />
          ),
        }}
      />
      <AccountDetails customerId={customerId} />
    </ResponsiveScrollView>
  );
}
