export function useGroupByMonth<T>(list: T[], key: string): T[][] {
  const tmp = list.reduce((result: Record<string, T[]>, item: T) => {
    const date = new Date((item as any)[key]);
    const prefix = `${date.getFullYear()}-${date.getMonth() + 1}`;
    if (!result[prefix]) {
      result[prefix] = [];
    }
    result[prefix].push(item);
    return result;
  }, {});

  const groupedTransactions: T[][] = Object.values(tmp);
  return groupedTransactions;
}
