import {useTranslate} from '@tolgee/react';
import {toast} from 'burnt';
import {Redirect, Stack, router, useLocalSearchParams} from 'expo-router';
import React, {useCallback, useState} from 'react';
import {Platform} from 'react-native';
import {YStack} from 'tamagui';

import {Card} from '@/components/cards/Card';
import {ProofOfResidenceForm} from '@/components/forms/ProofOfResidenceForm';
import {ResponsiveScrollView} from '@/components/views/ResponsiveScrollView';
import {ProofOfResidenceSchemaType} from '@/hooks/useValidations';
import {MFAModal} from '@/modules/mfa/components/MFAModal';
import {useMfaAvailability} from '@/modules/mfa/hooks/useMfaAvailability';
import {WebFooter} from '@/modules/navigation/components/footer/WebFooter';
import {Header} from '@/modules/navigation/components/header/Header';
import {useChangeAddressMutation, useGetSelfUserQuery} from '@/store/queries/usersApi';
import {Address} from '@/types/api/users';
import {MFAActionType} from '@/types/mfaAction';

export default function ChangeAddressProofPage() {
  const {t} = useTranslate();
  const newAddress = useLocalSearchParams<Address>();

  const [action, setAction] = useState<MFAActionType | null>(null);
  const {isMFAUsable, isFetching} = useMfaAvailability();
  const [updateAddress] = useChangeAddressMutation();
  const {data: user} = useGetSelfUserQuery();

  const onSubmit = useCallback(
    async (data: ProofOfResidenceSchemaType) => {
      const callback = async () => {
        if (!user) return;
        const res = await updateAddress({
          userID: user.id,
          address: newAddress,
          proofOfResidence: {
            category: data.category,
            issuanceDate: data.issuanceDate,
            uploadKeys: data.uploadKeys.map(({objectKey}) => objectKey),
          },
        });
        if (!res.error) {
          router.navigate('/(user)/(tabs)/profile');
          toast({
            preset: 'done',
            title: t('PERSONAL_DETAILS.ADDRESS_CHANGE.SUCCESS'),
          });
        } else {
          toast({
            preset: 'error',
            title: t('SNACKBAR.ERROR_TITLE'),
          });
        }
      };
      setAction({
        name: 'one-time-code',
        onSuccess: callback,
      });
      // TODO: handle case where MFA is not set up
    },
    [newAddress, t, updateAddress, user]
  );

  if (!isFetching && !isMFAUsable) return <Redirect href="/profile/personal-data" />;

  return (
    <ResponsiveScrollView>
      <YStack gap="$5">
        <Stack.Screen
          options={{
            headerShown: true,
            header: () => (
              <Header
                title={t('NAVIGATION.PROOF-OF-RESIDENCE')}
                backButton={Platform.OS !== 'web'}
              />
            ),
          }}
        />
        <Card headerText={t('PROOF_OF_RESIDENCE.TITLE')} gap="$6">
          <ProofOfResidenceForm onSubmit={onSubmit} newAddress={newAddress} />
          <MFAModal action={action} />
        </Card>
      </YStack>
      <WebFooter withCalendly />
    </ResponsiveScrollView>
  );
}
