import {
  BottomSheetBackdrop,
  BottomSheetBackdropProps,
  BottomSheetModal,
  BottomSheetScrollView,
  BottomSheetView,
  type BottomSheetModalProps,
} from '@gorhom/bottom-sheet';
import {
  PropsWithChildren,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {BackHandler, Platform, ScrollViewProps, ViewProps} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {ViewProps as TamaguiViewProps, View, getTokens, useTheme} from 'tamagui';

import {HandlerComponent} from '@/components/modal-bottom-sheet/HandlerComponent';
import useAppState from '@/hooks/useAppState';
import {useKeyboard} from '@/hooks/useKeyboard';

type ExcludedBottomSheetModalProps =
  | 'snapPoints'
  | 'enableDynamicSizing'
  | 'onChange'
  | 'onDismiss'
  | 'children';

export type TypeContainer = 'VIEW' | 'SCROLL' | 'CUSTOM';

type ViewContainerModalProps = {
  typeContainer?: 'VIEW' | 'CUSTOM';
  container?: Omit<ViewProps, 'children'>;
};

type ScrollViewContainerModalProps = {
  typeContainer?: 'SCROLL';
  container?: Omit<ScrollViewProps, 'children'>;
};

type CommonProps = {
  closable?: boolean;
  closeOnBackground?: boolean;
  onChangeIndex?: (index: number) => void;
  snapPoints?: BottomSheetModalProps['snapPoints'];
  enableDynamicSizing?: boolean;
  onDismiss?: () => void;
  wrapper?: TamaguiViewProps;
} & Omit<BottomSheetModalProps, ExcludedBottomSheetModalProps>;

type ContainerModalProps = ViewContainerModalProps | ScrollViewContainerModalProps;

type Props = CommonProps & ContainerModalProps;

export const ModalBottomSheet = forwardRef<BottomSheetModal, PropsWithChildren<Props>>(
  (
    {
      children,
      typeContainer = 'VIEW',
      closable = true,
      onChangeIndex,
      closeOnBackground = false,
      snapPoints,
      enableDynamicSizing = true,
      onDismiss,
      backdropComponent: BackdropComponent,
      container,
      wrapper,
      enableDismissOnClose: propEnableDismissOnClose,
      style,
      ...rest
    },
    ref
  ) => {
    const bottomSheetRef = useRef<BottomSheetModal>(null);

    const theme = useTheme();
    const tokens = getTokens();

    const [open, setOpen] = useState(false);
    const [enableDismissOnClose, setEnableDismissOnClose] = useState(true);

    const shouldCloseBottomSheet = useRef(false);

    const {bottom} = useSafeAreaInsets();

    const {keyboardShown, dismissKeyboard} = useKeyboard();

    useImperativeHandle(ref, () => bottomSheetRef.current!, []);

    const closeBottomSheet = useCallback(() => {
      if (keyboardShown) {
        dismissKeyboard();

        shouldCloseBottomSheet.current = true;

        return;
      }

      if (!bottomSheetRef.current) return;

      bottomSheetRef.current.dismiss();
    }, [keyboardShown, dismissKeyboard]);

    const handleBackPressed = useCallback(() => {
      if (open) {
        closeBottomSheet();

        return true;
      }

      return false;
    }, [open, closeBottomSheet, onDismiss]);

    useEffect(() => {
      const subscription = BackHandler.addEventListener('hardwareBackPress', handleBackPressed);

      return () => {
        subscription.remove();
      };
    }, [handleBackPressed]);

    useEffect(() => {
      if (Platform.OS === 'android' && keyboardShown) {
        setEnableDismissOnClose(false);
      }

      if (!keyboardShown && shouldCloseBottomSheet.current) {
        const timeout = Platform.OS === 'ios' ? 300 : 0;

        setTimeout(() => {
          if (!bottomSheetRef.current) return;

          bottomSheetRef.current.dismiss();
        }, timeout);

        shouldCloseBottomSheet.current = false;
      }
    }, [keyboardShown]);

    useEffect(() => {
      if (Platform.OS !== 'android') {
        setEnableDismissOnClose(true);

        return;
      }

      if (!enableDismissOnClose && keyboardShown) {
        setTimeout(() => {
          setEnableDismissOnClose(true);
        }, 10);
      }
    }, [keyboardShown, enableDismissOnClose]);

    useAppState({
      onBackground: () => {
        if (!closeOnBackground) return;

        closeBottomSheet();
      },
    });

    const innerSnapPoints = useMemo(() => {
      if (snapPoints) return snapPoints;

      return enableDynamicSizing ? undefined : ['80%'];
    }, [enableDynamicSizing, snapPoints]);

    const handleIndexChanged = useCallback(
      (index: number) => {
        const open = index !== -1;

        setOpen(open);

        if (onChangeIndex) {
          onChangeIndex(index);
        }
      },
      [onChangeIndex]
    );

    const renderBackdropComponent = useCallback(
      (props: BottomSheetBackdropProps) => {
        if (BackdropComponent) return <BackdropComponent {...props} />;

        return (
          <BottomSheetBackdrop
            {...props}
            appearsOnIndex={0}
            disappearsOnIndex={-1}
            pressBehavior={closable ? 'close' : 'none'}
          />
        );
      },
      [closable, BackdropComponent]
    );

    return (
      <BottomSheetModal
        ref={bottomSheetRef}
        snapPoints={innerSnapPoints}
        enableDynamicSizing={enableDynamicSizing}
        onDismiss={onDismiss}
        keyboardBlurBehavior="restore"
        android_keyboardInputMode="adjustResize"
        keyboardBehavior="interactive"
        enableOverDrag={false}
        enablePanDownToClose={closable ?? true}
        style={[
          {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
          },
          style,
        ]}
        backgroundStyle={{
          backgroundColor: theme.surface.val,
        }}
        onChange={handleIndexChanged}
        handleComponent={() => <HandlerComponent />}
        {...rest}
        backdropComponent={renderBackdropComponent}
        enableDismissOnClose={propEnableDismissOnClose ?? enableDismissOnClose}
        accessible={Platform.select({
          // setting it to false on Android seems to cause issues with TalkBack instead
          ios: false,
        })}
      >
        {typeContainer === 'CUSTOM' ? (
          children
        ) : typeContainer === 'SCROLL' ? (
          <BottomSheetScrollView
            contentContainerStyle={{paddingBottom: bottom || tokens.size.$4.val}}
            {...container}
          >
            <View paddingHorizontal="$4" backgroundColor="$surface" {...wrapper}>
              {children}
            </View>
          </BottomSheetScrollView>
        ) : (
          <BottomSheetView {...container}>
            <View
              paddingHorizontal="$4"
              backgroundColor="$surface"
              paddingBottom={bottom || tokens.size.$4.val}
              {...wrapper}
            >
              {children}
            </View>
          </BottomSheetView>
        )}
      </BottomSheetModal>
    );
  }
);
