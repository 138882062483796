import AsyncStorage from '@react-native-async-storage/async-storage';
import {useLocalSearchParams, useRouter} from 'expo-router';
import {useEffect, useMemo, useState} from 'react';

type StorybookViewType = {
  getStorybookUI: (options: {
    storage: {
      getItem: typeof AsyncStorage.getItem;
      setItem: (key: string, value: string) => Promise<void>;
    };
    initialSelection?: {kind: string; name: string};
  }) => React.ComponentType;
};

const StorybookUIRoot: React.FC = () => {
  const {story} = useLocalSearchParams<{story?: string}>();
  const router = useRouter();
  const [initialSelection, setInitialSelection] = useState<
    {kind: string; name: string} | undefined
  >();
  const [StorybookView, setStorybookView] = useState<StorybookViewType | null>(null);

  useEffect(() => {
    // Dynamically import the `view` only in development mode
    if (process.env.NODE_ENV === 'development') {
      import('./storybook.requires')
        .then(module => {
          setStorybookView(module.view as StorybookViewType);
        })
        .catch(error => {
          console.error('Failed to load storybook view:', error);
        });
    }
  }, []);

  useEffect(() => {
    if (initialSelection === undefined && story != null) {
      const [kind, name] = story.split('--');
      setInitialSelection({kind, name});
    }
  }, [initialSelection, story]);

  const Root = useMemo(() => {
    if (StorybookView) {
      return StorybookView.getStorybookUI({
        storage: {
          getItem: AsyncStorage.getItem,
          setItem: async (key, value) => {
            if (key === 'lastOpenedStory') {
              router.setParams({story: value});
            }
            await AsyncStorage.setItem(key, value);
          },
        },
        initialSelection,
      });
    }
    return null; // Placeholder while StorybookView is loading
  }, [StorybookView, initialSelection]);

  if (!StorybookView || !Root) {
    return null; // Render nothing until StorybookView is loaded
  }

  return <Root />;
};

export default StorybookUIRoot;
