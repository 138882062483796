import {Heading1} from '@/components/texts/Heading';
import {Label2, Label3sm2} from '@/components/texts/Label';
import {Paragraph1, Paragraph2} from '@/components/texts/Paragraph';
import {useAppSelector} from '@/store';
import {useTranslate} from '@tolgee/react';
import React from 'react';
import {GetProps} from 'tamagui';

export interface LocalizedNumbersProps {
  variant?: 'percentage' | 'currency';
  precision?: 'decimals' | 'integer';
  value: number | undefined;
  showZero?: boolean;
  numberStyle?: GetProps<typeof Paragraph1>;
  textComponent?: typeof Paragraph2 | typeof Heading1 | typeof Label2 | typeof Label3sm2;
  plus?: boolean;
}

const LocalizedNumbers: React.FC<LocalizedNumbersProps> = ({
  variant = 'currency',
  precision = 'decimals',
  value,
  showZero = true,
  textComponent,
  numberStyle,
  plus,
}) => {
  const currentLanguage = useAppSelector(state => state.app.language);
  const {t} = useTranslate();
  const Component = textComponent || Label2;
  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: precision === 'decimals' ? 2 : 0,
    maximumFractionDigits: precision === 'decimals' ? 2 : 0,
  };

  if (value === undefined) {
    options.style = 'currency';
    options.currency = 'EUR';
    return (
      <Component {...numberStyle}>
        {showZero
          ? new Intl.NumberFormat(currentLanguage, options).format(0)
          : t('OVERVIEW.NOT_AVAILABLE')}
      </Component>
    );
  }

  if (variant === 'percentage') {
    options.style = 'percent';
  } else if (variant === 'currency') {
    options.style = 'currency';
    options.currency = 'EUR';
  }

  let formattedValue = new Intl.NumberFormat(currentLanguage, options).format(value);

  if (plus && value >= 0) {
    formattedValue = `+${formattedValue}`;
  }
  return <Component {...numberStyle}>{formattedValue}</Component>;
};

export default LocalizedNumbers;
