import {useTranslate} from '@tolgee/react';
import {Stack} from 'expo-router';
import {useEffect, useMemo, useState} from 'react';
import {Platform} from 'react-native';
import {View} from 'tamagui';

import {Card} from '@/components/cards/Card';
import SvgFile from '@/components/icons/SvgFile';
import {Heading5} from '@/components/texts/Heading';
import {Label2} from '@/components/texts/Label';
import {ResponsiveScrollView} from '@/components/views/ResponsiveScrollView';
import {useTokenUserInfo} from '@/hooks/useTokenUserInfo';
import {FriendsPrimaryHeader} from '@/modules/friends/components/FriendsPrimaryHeader';
import {FriendsProgressBlock} from '@/modules/friends/components/FriendsProgressBlock';
import {FriendsProgressText} from '@/modules/friends/components/FriendsProgressText';
import {FriendsVolumeCard} from '@/modules/friends/components/FriendsVolumeCard';
import {WebFooter} from '@/modules/navigation/components/footer/WebFooter';
import {Header} from '@/modules/navigation/components/header/Header';
import {useFetchReferrerQuery} from '@/store/queries/referralApi';
import {skipToken} from '@reduxjs/toolkit/query';

export default function FriendsPage() {
  const {t} = useTranslate();
  const [progress, setProgress] = useState(0);
  const userInfo = useTokenUserInfo();

  const {data: referrer} = useFetchReferrerQuery(
    userInfo?.user_id ? {id: userInfo?.user_id} : skipToken
  );

  const friendsGoal = useMemo(() => {
    if (referrer === undefined || referrer?.confirmedReferrals < 5) return 5;
    if (referrer?.confirmedReferrals < 10) return 10;
    return 25;
  }, [referrer]);

  useEffect(() => {
    setProgress(
      ((referrer?.confirmedReferrals === undefined ? 0 : referrer?.confirmedReferrals) /
        friendsGoal) *
        100
    );
  }, [friendsGoal, referrer]);

  return (
    <ResponsiveScrollView>
      <View gap="$5">
        <Stack.Screen
          options={{
            headerShown: true,
            header: () => (
              <Header title={t('NAVIGATION.INVITE_FRIENDS')} backButton={Platform.OS !== 'web'} />
            ),
          }}
        />

        <FriendsPrimaryHeader />

        <FriendsVolumeCard friendsGoal={friendsGoal} />

        <Card padding="$5" gap="$5">
          <View gap="$3" flexDirection="row">
            <View
              width={40}
              height={40}
              backgroundColor="$neutralBG"
              borderRadius="$full"
              alignItems="center"
              justifyContent="center"
            >
              <SvgFile size={20} name="prize" color="$primary" />
            </View>

            <Heading5>{t('NAVIGATION.INVITE_FRIENDS.BLOCK_3.TEXT_1')}</Heading5>
          </View>
          <View
            height={40}
            backgroundColor="$neutralBG"
            borderRadius="$1"
            overflow="hidden"
            justifyContent="center"
          >
            <View
              animation="bouncy"
              position="absolute"
              height="100%"
              width={`${progress}%`}
              backgroundColor="$primary"
            />
            <Label2
              color={progress < 50 ? '$primary' : 'white'}
              variant="medium"
              alignSelf="center"
              zIndex="$1"
            >
              {referrer?.confirmedReferrals ?? 0}
            </Label2>
          </View>

          {(referrer?.confirmedReferrals ?? 0) >= friendsGoal && (
            <Heading5 variant="medium">{t('NAVIGATION.INVITE_FRIENDS.DONE_TITLE')}</Heading5>
          )}
          <FriendsProgressText friendsGoal={friendsGoal} />

          <View
            gap="$5"
            flexWrap="wrap"
            flexDirection="row"
            minHeight={175}
            alignItems={Platform.OS === 'web' ? 'stretch' : 'flex-start'}
          >
            <FriendsProgressBlock
              title={t('NAVIGATION.INVITE_FRIENDS.BLOCK_4.TEXT_1_1')}
              description={t('NAVIGATION.INVITE_FRIENDS.BLOCK_4.TEXT_1_2')}
              subtitle={t('NAVIGATION.INVITE_FRIENDS.BLOCK_4.TEXT_1_3')}
              reached={(referrer?.confirmedReferrals ?? 0) >= 1}
            />
            <FriendsProgressBlock
              title={t('NAVIGATION.INVITE_FRIENDS.BLOCK_4.TEXT_2_1')}
              description={t('NAVIGATION.INVITE_FRIENDS.BLOCK_4.TEXT_1_2')}
              subtitle={t('NAVIGATION.INVITE_FRIENDS.BLOCK_4.TEXT_2_3')}
              reached={(referrer?.confirmedReferrals ?? 0) >= 5}
            />
            <FriendsProgressBlock
              title={t('NAVIGATION.INVITE_FRIENDS.BLOCK_4.TEXT_3_1')}
              description={t('NAVIGATION.INVITE_FRIENDS.BLOCK_4.TEXT_1_2')}
              subtitle={t('NAVIGATION.INVITE_FRIENDS.BLOCK_4.TEXT_3_3')}
              reached={(referrer?.confirmedReferrals ?? 0) >= 10}
            />
            <FriendsProgressBlock
              title={t('NAVIGATION.INVITE_FRIENDS.BLOCK_4.TEXT_4_1')}
              subtitle={t('NAVIGATION.INVITE_FRIENDS.BLOCK_4.TEXT_4_2')}
              reached={(referrer?.confirmedReferrals ?? 0) >= 25}
              variant="primary"
            />
          </View>
        </Card>
      </View>
      <WebFooter withCalendly />
    </ResponsiveScrollView>
  );
}
