import {useCallback} from 'react';

import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {ResponseError} from '@/store/queries/authApi';
import {useViolationsQuery} from '@/store/queries/referenceApi';

export const useGetViolationError = () => {
  const {data: violations, isFetching} = useViolationsQuery();

  const {getByCode} = useRefdataLocalizable();

  const getViolationError = useCallback(
    (error: unknown | undefined) => {
      if (!error || !violations) return undefined;

      const typedError = error as {data?: ResponseError};

      const errorViolations = typedError?.data?.violations;

      if (!errorViolations) return undefined;

      const errorViolationCode = errorViolations.at(0)?.code;

      if (!errorViolationCode) return undefined;

      const violationError = getByCode(violations, errorViolationCode);

      return violationError?.label;
    },
    [getByCode, violations]
  );

  return {getViolationError, isFetching};
};
