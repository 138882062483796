import {captureException} from '@sentry/react-native';
import {useTranslate} from '@tolgee/react';
import * as Linking from 'expo-linking';
import {useCallback} from 'react';
import {YStack} from 'tamagui';

import {Button} from '@/components/buttons/Button';
import {Heading5} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {useSession} from '@/providers/session-provider/SessionProvider';

export const PoliticallyExposedPersonScreen = () => {
  const {t} = useTranslate();

  const {signOut} = useSession();

  const handleLinkPress = useCallback(() => {
    if (!process.env.EXPO_PUBLIC_MAILTO_SERVICE) return;

    Linking.openURL(process.env.EXPO_PUBLIC_MAILTO_SERVICE).catch(captureException);
  }, []);

  return (
    <YStack alignItems="center" justifyContent="center">
      <Heading5 textAlign="center" variant="medium" marginBottom="$2">
        {t('PERSONAL_DETAILS.ERROR_POLITICALLY_PERSON')}
      </Heading5>
      <Paragraph2 textAlign="center" marginBottom="$5">
        {t('PERSONAL_DETAILS.ERROR_POLITICALLY_PERSON_CONTACT')}
        <Paragraph2
          onPress={handleLinkPress}
          color="$primary"
          cursor="pointer"
          hoverStyle={{opacity: 0.8}}
        >
          service@ginmon.de
        </Paragraph2>
      </Paragraph2>
      <Button onPress={signOut}>{t('LOGIN.LOGOUT')}</Button>
    </YStack>
  );
};
