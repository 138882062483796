import {Heading5} from '@/components/texts/Heading';
import {Label1, Label3} from '@/components/texts/Label';
import {media} from '@tamagui/config/v3';
import React from 'react';
import {GetProps, ViewProps, XStack} from 'tamagui';
import LocalizedNumbers from './LocalizedNumbers';

type BadgeProps = GetProps<typeof XStack> & {
  value?: number;
  percentage?: boolean;
  backgroundColor?: ViewProps['backgroundColor'];
  color?: ViewProps['backgroundColor'];
  textComponent?: typeof Label3 | typeof Heading5;
  showZero?: boolean;
};

export const PerformanceBadge: React.FC<BadgeProps> = ({
  percentage,
  value,
  backgroundColor,
  color,
  textComponent,
  showZero = true,
  ...props
}) => {
  const isPositive = !value || value >= 0;
  const textColor = isPositive ? '$green' : '$neutral500';
  const Label = textComponent || media.sm ? Label3 : Label1;
  return (
    <XStack
      paddingVertical="$1"
      borderRadius="$full"
      backgroundColor={backgroundColor}
      alignSelf="center"
      $sm={{
        paddingHorizontal: '$2',
      }}
      {...props}
    >
      <Label color={color || textColor}>
        {isPositive && '+'}
        <LocalizedNumbers
          variant={percentage ? 'percentage' : 'currency'}
          precision="decimals"
          value={percentage && value ? value : value}
          numberStyle={{color: color || textColor, variant: 'medium'}}
          textComponent={Label}
          showZero={showZero}
        />
      </Label>
    </XStack>
  );
};
