import {Button} from '@/components/buttons/Button';
import {FormDateInput} from '@/components/inputs/form/FormDateInput';
import {FormDocumentUpload} from '@/components/inputs/form/FormDocumentUpload';
import {FormSelectInput} from '@/components/inputs/form/FormSelectInput';
import {Heading6} from '@/components/texts/Heading';
import {Label3} from '@/components/texts/Label';
import {usePorDocTypes} from '@/hooks/usePorDocTypes';
import {ProofOfResidenceSchemaType, useValidations} from '@/hooks/useValidations';
import {InfoBox} from '@/modules/orders/components/InfoBox';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';
import {Address} from '@/types/api/users';
import {ProofDocumentType} from '@/types/documents';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslate} from '@tolgee/react';
import {toast} from 'burnt';
import {useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {XStack, YStack} from 'tamagui';

type ProofOfResidenceFormProps = {
  onSubmit: (data: ProofOfResidenceSchemaType) => void;
  newAddress?: Address;
};

export function ProofOfResidenceForm({onSubmit, newAddress}: ProofOfResidenceFormProps) {
  const {t} = useTranslate();
  const {proofOfResidenceSchema} = useValidations();
  const {data: user} = useGetSelfUserQuery();
  const possibleDocTypes = usePorDocTypes();
  const posthog = usePostHog();
  const {handleSubmit, control, watch} = useForm({
    resolver: yupResolver(proofOfResidenceSchema),
    mode: 'onChange',
    defaultValues: {
      category: 'ID_CARD' as ProofDocumentType,
    },
    context: {
      dateOfBirth: user?.dateOfBirth,
    },
  });

  const docTypeItems = useMemo(() => {
    return possibleDocTypes.map(_ => ({key: _.value, name: _.text}));
  }, [possibleDocTypes]);

  const category = watch('category');

  return (
    <XStack flexWrap="wrap" justifyContent="space-between" gap="$6">
      <YStack gap="$4" $gtSm={{maxWidth: 500}} flexShrink={1}>
        <Heading6 variant="medium">{t('PROOF_OF_RESIDENCE.DOCUMENT_MATCH_ADDRESS_TITLE')}</Heading6>
        <YStack>
          <Label3>
            {newAddress?.street} {newAddress?.streetNumber}
          </Label3>
          <Label3>
            {newAddress?.city} {newAddress?.postCode}
          </Label3>
        </YStack>
        <Heading6 variant="medium">{t('PROOF_OF_RESIDENCE.PLEASE_SPECIFY_INFORMATION')}</Heading6>
        <FormDateInput
          control={control}
          name="issuanceDate"
          label={t('PROOF_OF_RESIDENCE.ISSUANCE_DATE')}
          bordered
        />
        <FormSelectInput
          control={control}
          name="category"
          label={t('PROOF_OF_RESIDENCE.DOCUMENT_TYPE')}
          items={docTypeItems}
          bordered
        />
      </YStack>
      <YStack
        gap="$4"
        justifyContent="flex-end"
        $gtSm={{maxWidth: 400}}
        flexShrink={1}
        width="100%"
      >
        {user?.id && (
          <FormDocumentUpload
            uploadAreaStyle={{minHeight: 150}}
            control={control}
            name="uploadKeys"
            userID={user?.id}
            fileContextPrefix="proof-of-residence"
            maxFileSize={1000 * 1000 * 20} // 20 MB
            onError={reason => {
              switch (reason) {
                case 'FILE_SIZE':
                  toast({
                    preset: 'error',
                    title: t('PROOF_OF_RESIDENCE.DOCUMENT.FILE_TO_BIG'),
                  });
                  break;
                case 'FILE_FORMAT':
                  toast({
                    preset: 'error',
                    title: t('PROOF_OF_RESIDENCE.DOCUMENT.FILE_WRONG_FORMAT'),
                  });
              }
            }}
          />
        )}
        {category === 'ID_CARD' && <InfoBox text={t('PROOF_OF_RESIDENCE.ID_CARD_NOTE')} />}
        <Button
          alignSelf="flex-end"
          onPress={handleSubmit(onSubmit, data => {
            posthog?.capture('form_submit_failed', data);
          })}
        >
          {t('PROOF_OF_RESIDENCE.SEND')}
        </Button>
      </YStack>
    </XStack>
  );
}
