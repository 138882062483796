import {Label3} from '@/components/texts/Label';
import {InfoBox} from '@/modules/orders/components/InfoBox';
import {useTranslate} from '@tolgee/react';
import {YStack} from 'tamagui';

export function DeleteAccountNote() {
  const {t} = useTranslate();
  return (
    <InfoBox wrapperProps={{alignItems: 'flex-start', backgroundColor: '$neutralBG'}}>
      <YStack gap="$2" flex={1}>
        <Label3 color="$primary" variant="medium">
          {t('NAVIGATION.PROFILE.ACCOUNT.DELETE.DETAILS.PLEASE_NOTE')}
        </Label3>
        <Label3>{t('NAVIGATION.PROFILE.ACCOUNT.DELETE.DETAILS.NOTE')}</Label3>
      </YStack>
    </InfoBox>
  );
}
