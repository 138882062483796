import {BaseInput} from '@/components/inputs/form/BaseInput';
import {OnboardingAddItem} from '@/components/inputs/form/OnboardingAddItem';
import {OnboardingRemoveItem} from '@/components/inputs/form/OnboardingRemoveItem';
import {SelectInput} from '@/components/inputs/select-input/SelectInput';
import {InputErrorMessage} from '@/components/texts/InputErrorMessage';
import {Label3} from '@/components/texts/Label';
import {Skeleton} from '@/components/views/Skeleton';
import {IMPORTANT_COUNTRIES, TAX_RESIDENCIES} from '@/constants/countries';
import {useAppSelector} from '@/store';
import {useCountriesQuery} from '@/store/queries/referenceApi';
import {FieldArrayFormInputProps} from '@/types/FormInputProps';
import {useTranslate} from '@tolgee/react';
import {useMemo} from 'react';
import {ArrayPath, Controller, FieldArray, FieldValues, Path, useFieldArray} from 'react-hook-form';
import {XStack, YStack} from 'tamagui';

const LIMIT_RESIDENCIES = 3;

export function TaxResidenceSelect<T extends FieldValues>({
  control,
  name,
  bordered,
}: Omit<FieldArrayFormInputProps<T>, 'label'>) {
  const {t} = useTranslate();
  const language = useAppSelector(state => state.app.language);
  const {data: countries, isFetching: isCountriesFetching} = useCountriesQuery();
  const {fields, append, remove} = useFieldArray({
    control,
    name,
  });

  const reorderedCountries = useMemo(() => {
    if (!countries) return undefined;
    const res = countries
      .filter(_ => TAX_RESIDENCIES.includes(_.code))
      .map(_ => ({
        name: _.label[language],
        key: _.code,
      }))
      .sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        } else {
          return 1;
        }
      });
    return [
      ...res.filter(_ => IMPORTANT_COUNTRIES.includes(_.key)),
      ...res.filter(_ => !IMPORTANT_COUNTRIES.includes(_.key)),
    ];
  }, [countries, language]);

  return (
    <YStack gap="$4">
      <YStack gap="$9">
        {countries &&
          reorderedCountries &&
          fields.map((fieldItem, index) => {
            return (
              <YStack key={fieldItem.id} gap="$2">
                <XStack justifyContent="space-between">
                  <Label3 color="$neutral500">{t('PERSONAL_DETAILS.TAX_RESIDENCE_COUNTRY')}</Label3>
                  {index !== 0 && (
                    <OnboardingRemoveItem
                      label={t('PERSONAL_DETAILS.TAX_IDENTIFICATION_NUMBER.REMOVE')}
                      onPress={() => remove(index)}
                    />
                  )}
                </XStack>
                <Controller
                  name={`${name}.${index}.country` as Path<T>}
                  control={control}
                  render={({field, fieldState: {error}}) => {
                    return (
                      <YStack gap="$6">
                        <YStack gap="$2">
                          <SelectInput
                            key={fieldItem.id}
                            items={reorderedCountries}
                            selectedItem={field.value}
                            onSelect={field.onChange}
                            placeholder="--"
                            wrapperProps={{
                              borderWidth: 0,
                              bordered,
                            }}
                            disabled={index === 0}
                          />
                          <InputErrorMessage error={error?.message} />
                        </YStack>
                        <YStack gap="$2" maxWidth={500}>
                          <Controller
                            name={`${name}.${index}.number` as Path<T>}
                            control={control}
                            render={({field: numberField, fieldState: {error}}) => (
                              <YStack gap="$2">
                                <Label3 color="$neutral500">
                                  {field.value === 'DE'
                                    ? t('PERSONAL_DETAILS.TAX_IDENTIFICATION_NUMBER_OPTIONAL')
                                    : t('PERSONAL_DETAILS.TAX_IDENTIFICATION_NUMBER')}
                                </Label3>
                                <BaseInput
                                  key={fieldItem.id}
                                  keyboardType="numbers-and-punctuation"
                                  onChangeText={numberField.onChange}
                                  {...numberField}
                                />
                                <InputErrorMessage error={error?.message} />
                              </YStack>
                            )}
                          />
                        </YStack>
                      </YStack>
                    );
                  }}
                />
              </YStack>
            );
          })}
      </YStack>
      <Controller
        name={`${name}.root` as Path<T>}
        control={control}
        render={({field, fieldState: {error}}) => {
          return <InputErrorMessage error={error?.message} />;
        }}
      />
      {isCountriesFetching && <Skeleton width="100%" />}
      {fields.length < LIMIT_RESIDENCIES && (
        <OnboardingAddItem
          label={t('PERSONAL_DETAILS.TAX_IDENTIFICATION_NUMBER.ADD')}
          onPress={() => append({} as FieldArray<T, ArrayPath<T>>)}
        />
      )}
    </YStack>
  );
}
