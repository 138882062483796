import {SkTypefaceFontProvider, Skia} from '@shopify/react-native-skia';
import type {SkTextStyle} from '@shopify/react-native-skia/src/skia/types/Paragraph/TextStyle';

import {Domain, NumberTuple, Point, XYTuple} from '@/types/skia';

type Res = {
  xyTuple: XYTuple;
  minMaxX: NumberTuple;
};

export const getMinMaxXYTuple = (xyTuples: XYTuple[]): Res => {
  let x0 = Number.POSITIVE_INFINITY;
  let x1 = Number.NEGATIVE_INFINITY;
  let y0 = Number.POSITIVE_INFINITY;
  let y1 = Number.NEGATIVE_INFINITY;

  let minX = Number.POSITIVE_INFINITY;
  let maxX = Number.NEGATIVE_INFINITY;

  for (const {
    x: [newX0, newX1],
    y: [newY0, newY1],
  } of xyTuples) {
    minX = newX0 < minX ? newX0 : minX;
    maxX = newX1 > maxX ? newX1 : maxX;

    if (newX0 < x0) {
      x0 = newX0;
    }

    if (newX1 > x1) {
      x1 = newX1;
    }

    if (newY0 < y0) {
      y0 = newY0;
    }

    if (newY1 > y1) {
      y1 = newY1;
    }
  }

  if (
    !Number.isFinite(x0) ||
    !Number.isFinite(x1) ||
    !Number.isFinite(y0) ||
    !Number.isFinite(y1)
  ) {
    return {
      xyTuple: {
        x: [0, 0],
        y: [0, 0],
      },
      minMaxX: [0, 0],
    };
  }

  return {
    xyTuple: {
      x: [x0, x1],
      y: [y0, y1],
    },
    minMaxX: [minX, maxX],
  };
};

export const getDomainFromPoints = (points: Point[]): Domain | undefined => {
  if (points.length < 2) return undefined;

  let x0 = Number.POSITIVE_INFINITY;
  let x1 = Number.NEGATIVE_INFINITY;
  let y0 = Number.POSITIVE_INFINITY;
  let y1 = Number.NEGATIVE_INFINITY;

  for (const point of points) {
    if (point.x < x0) {
      x0 = point.x;
    }

    if (point.x > x1) {
      x1 = point.x;
    }

    if (point.y < y0) {
      y0 = point.y;
    }

    if (point.y > y1) {
      y1 = point.y;
    }
  }

  return {
    x: [x0, x1],
    y: [y0, y1],
  };
};

export const isSameDomain = (newAxisDomain: NumberTuple, currAxisDomain: NumberTuple) =>
  newAxisDomain[0] === currAxisDomain[0] && newAxisDomain[1] === currAxisDomain[1];

export const degreesToRadians = (degrees: number) => {
  'worklet';

  return degrees * (Math.PI / 180);
};

export const createParagraph = (
  fonts: SkTypefaceFontProvider,
  textStyle: SkTextStyle,
  text: string,
  width?: number
) => {
  'worklet';

  const builder = Skia.ParagraphBuilder.Make({}, fonts).pushStyle(textStyle).addText(text).build();

  if (width !== undefined) {
    builder.layout(width);
  }

  return builder;
};
