import {ThemeIcon} from '@/components/icons/ThemeIcon';
import {Label3} from '@/components/texts/Label';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {ThemeCode} from '@/modules/portfolio/types/ThemeCode';
import {ThemeDepositDistribution} from '@/types/api/customers.v2';
import {XStack} from 'tamagui';

type ThemeDepositSummaryRowProps = {
  code: ThemeCode;
  distribution: ThemeDepositDistribution;
  value: number;
};

export function ThemeDepositSummaryRow({code, distribution, value}: ThemeDepositSummaryRowProps) {
  return (
    <XStack alignItems="center" justifyContent="space-between" gap="$4">
      <ThemeIcon code={code} showLabel />
      <XStack gap="$2">
        <LocalizedNumbers
          variant="percentage"
          value={distribution.amount / value}
          numberStyle={{variant: 'medium'}}
          textComponent={Label3}
          precision="integer"
        />
        <Label3 variant="medium">
          (
          <LocalizedNumbers
            value={distribution.amount}
            numberStyle={{variant: 'medium'}}
            textComponent={Label3}
          />
          )
        </Label3>
      </XStack>
    </XStack>
  );
}
