import {captureException} from '@sentry/react-native';
import {useLocalSearchParams, useRouter} from 'expo-router';

export function useCustomerIdParamOrRedirect(): string {
  const {customerId} = useLocalSearchParams<{customerId: string}>();
  const router = useRouter();
  if (!customerId) {
    captureException(`DepositPage: Missing customerId param`);
    router.navigate('/');
    return undefined as unknown as string;
  } else {
    return customerId;
  }
}
