import {FormCountrySelect} from '@/components/inputs/form/FormCountrySelect';
import {FormPhonenumberInput} from '@/components/inputs/form/FormPhonenumberInput';
import {FormTextInput} from '@/components/inputs/form/FormTextInput';
import {SelectInputAddress} from '@/components/inputs/select-input/SelectInputAddress';
import {LoadingSpinner} from '@/components/views/LoadingSpinner';
import useDebounce from '@/hooks/useDebounce';
import {AddressDetailsSchemaType, useValidations} from '@/hooks/useValidations';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useAppSelector} from '@/store';
import {useLazyGetGmapsPlaceQuery, useLazyGetGmapsSuggestionsQuery} from '@/store/queries/gmapsApi';
import {SelectInputItem} from '@/types/FormInputProps';
import {UserResponseV3} from '@/types/api/users';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslate} from '@tolgee/react';
import * as Crypto from 'expo-crypto';
import {useFocusEffect} from 'expo-router';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {useForm} from 'react-hook-form';
import {View, XStack, YStack} from 'tamagui';

type UserAddressFormProps = {
  onValid: (data: AddressDetailsSchemaType) => void;
  user: UserResponseV3 | undefined;
  child?: boolean;
  setIsLoading?: (loading: boolean) => void;
};

export type SubmitFormRef = {submit: () => Promise<void>};

export const UserAddressForm = forwardRef<any, UserAddressFormProps>(function UserAddressForm(
  {onValid, user, child = false, setIsLoading},
  ref
) {
  const {t} = useTranslate();
  const {addressDetailsSchema} = useValidations();
  const [addresses, setAddresses] = useState<SelectInputItem[]>([]);
  const token = useMemo(() => Crypto.randomUUID(), []);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [lastSearchQuery, setLastSearchQuery] = useState<string>('');
  const posthog = usePostHog();
  const [isAutoCompleting, setIsAutoCompleting] = useState(false);
  const [getPlaces] = useLazyGetGmapsSuggestionsQuery();
  const [getPlace] = useLazyGetGmapsPlaceQuery();
  const language = useAppSelector(state => state.app.language);

  useImperativeHandle(ref, () => ({
    submit: handleSubmit(onValid, data => {
      posthog?.capture('form_submit_failed', data);
    }),
  }));

  const {
    handleSubmit,
    control,
    formState: {isSubmitting},
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(addressDetailsSchema),
    mode: 'onChange',
    context: {
      child,
    },
  });

  useFocusEffect(
    useCallback(() => {
      setValue('street', user?.address?.street ?? '');
      setValue('streetNumber', user?.address?.streetNumber ?? '');
      setValue('postCode', user?.address?.postCode ?? '');
      setValue('city', user?.address?.city ?? '');
      setValue('countryCode', user?.address?.countryCode ?? 'DE');
      setValue('phoneNumber', user?.phoneNumber ?? '+49 ');
    }, [setValue, user])
  );

  useEffect(() => {
    setIsLoading?.(isSubmitting);
  }, [isSubmitting, setIsLoading]);

  const getPlacesByQuery = useCallback(
    async (query: string) => {
      if (!query || query === lastSearchQuery) return;
      const response = await getPlaces({token, query, language});
      const items = response.data ? (response.data as SelectInputItem[]) : [];
      setAddresses(items);
    },
    [getPlaces, lastSearchQuery, token, language]
  );

  useFocusEffect(
    useCallback(() => {
      const subscription = watch(async (data, {name}) => {
        if (name === 'street') {
          setSearchQuery(data.street ?? '');
          setIsAutoCompleting(true);
        } else {
          setIsAutoCompleting(false);
        }
      });
      return () => subscription.unsubscribe();
    }, [watch])
  );

  useDebounce(
    async () => {
      await getPlacesByQuery(searchQuery);
      setIsAutoCompleting(false);
    },
    500,
    [searchQuery, getPlacesByQuery]
  );

  const getPlaceByQuery = useCallback(
    async (query: string) => {
      if (!query) return;
      const response = await getPlace({token, query});
      setAddresses([]);
      setLastSearchQuery(response.data.streetName.long_name ?? '');
      setValue('street', response.data.streetName.long_name ?? '');
      setValue('city', response.data.city.long_name ?? '', {shouldValidate: true});
      setValue('countryCode', response.data.country?.short_name ?? '');
      setValue('streetNumber', response.data.streetNumber?.long_name ?? '', {shouldValidate: true});
      setValue('postCode', response.data.postalCode?.long_name ?? '', {shouldValidate: true});
    },
    [getPlace, setValue, token]
  );

  return (
    <YStack gap="$6">
      <XStack gap="$4">
        <View flex={3} flexBasis={3 / 4} flexDirection="column">
          <XStack alignItems="center" gap="$2">
            <FormTextInput
              wrapperProps={{flexGrow: 1}}
              control={control}
              name="street"
              label={t('FORM.LABELS.STREET')}
            />
            {isAutoCompleting && (
              <View paddingTop={22}>
                <LoadingSpinner color="$primary" />
              </View>
            )}
          </XStack>
          <SelectInputAddress items={addresses} onSelect={item => getPlaceByQuery(item)} />
        </View>
        <View flex={1} flexBasis={1 / 4}>
          <FormTextInput
            control={control}
            name="streetNumber"
            label={t('FORM.LABELS.STREET_NUMBER')}
          />
        </View>
      </XStack>
      <XStack gap="$4">
        <View minWidth={100} maxWidth={125}>
          <FormTextInput
            control={control}
            name="postCode"
            label={t('FORM.LABELS.POST_CODE')}
            textInputProps={{keyboardType: 'numeric'}}
          />
        </View>
        <View flex={1}>
          <FormTextInput control={control} name="city" label={t('FORM.LABELS.CITY')} />
        </View>
      </XStack>
      <XStack>
        <View flex={1}>
          <FormCountrySelect
            control={control}
            name="countryCode"
            label={t('FORM.LABELS.LAND_OF_LIVING')}
            caption={t('FORM.LABELS.LAND_OF_LIVING_CAPTION')}
          />
        </View>
      </XStack>
      {!child && (
        <FormPhonenumberInput
          control={control}
          name="phoneNumber"
          label={t('FORM.LABELS.PHONE_NUMBER')}
          caption={t('FORM.LABELS.PHONE_NUMBER_CAPTION')}
        />
      )}
    </YStack>
  );
});

export type AddressListItem = {
  id: string;
  street: string;
  full: string;
};
