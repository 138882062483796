import {Skia} from '@shopify/react-native-skia';
import {useCallback} from 'react';

import {CurveStrategy, Point} from '@/types/skia';
import {useChartContext} from '../context';
import {curveLines} from '../math';
import {useGetChartRange} from './useGetChartRange';

export const useBuildPath = () => {
  const {domain} = useChartContext();

  const range = useGetChartRange();

  return useCallback(
    (points: Point[], strategy: CurveStrategy = 'complex', smoothing = 0.1) => {
      'worklet';

      if (points.length === 0) {
        return Skia.Path.Make().close();
      }

      const {
        x: [x0Domain, x1Domain],
        y: [y0Domain, y1Domain],
      } = domain;

      if (x0Domain === x1Domain) {
        return Skia.Path.Make().close();
      }

      const {
        x: [x0Range, x1Range],
        y: [y0Range, y1Range],
      } = range;

      const adjustedWidth = Math.abs(x1Range - x0Range);
      const adjustedHeight = Math.abs(y1Range - y0Range);

      const xDomainRange = x1Domain - x0Domain;
      const yDomainRange = y1Domain - y0Domain;

      const chartPoints = [...points].map<Point>(({x, y}) => ({
        x: ((x - x0Domain) / xDomainRange) * adjustedWidth,
        y:
          yDomainRange === 0
            ? adjustedHeight / 2
            : ((y - y0Domain) / yDomainRange) * adjustedHeight,
      }));

      return curveLines(chartPoints, smoothing, strategy, 0, adjustedHeight);
    },
    [domain, range]
  );
};
