import {Stack} from 'expo-router';

import {SideNavigation} from '@/modules/navigation/components/SideNavigation';
import {useScreenOptions} from '@/modules/navigation/hooks/useScreenOptions';
import {useSession} from '@/providers/session-provider/SessionProvider';

export default function AppLayout() {
  const {session} = useSession();
  const screenOptions = useScreenOptions({headerShown: false});
  return (
    <SideNavigation>
      <Stack screenOptions={screenOptions} />
    </SideNavigation>
  );
}
