import {SelectInput} from '@/components/inputs/select-input/SelectInput';
import {InputErrorMessage} from '@/components/texts/InputErrorMessage';
import {Label3} from '@/components/texts/Label';
import {Skeleton} from '@/components/views/Skeleton';
import {IMPORTANT_COUNTRIES, TAX_RESIDENCIES} from '@/constants/countries';
import {useAppSelector} from '@/store';
import {useCountriesQuery} from '@/store/queries/referenceApi';
import {FormInputProps} from '@/types/FormInputProps';
import {useMemo} from 'react';
import {FieldValues, useController} from 'react-hook-form';
import {YStack} from 'tamagui';

type FormCountrySelectProps<T extends FieldValues> = FormInputProps<T> & {
  disabled?: boolean;
  smFullWidth?: boolean;
  caption?: string;
  placeholder?: string;
};

export function FormCountrySelect<T extends FieldValues>({
  control,
  name,
  bordered,
  label,
  disabled,
  smFullWidth = false,
  caption,
  placeholder,
}: FormCountrySelectProps<T>) {
  const {data: countries, isFetching: isCountriesFetching} = useCountriesQuery();
  const language = useAppSelector(state => state.app.language);
  const {
    field,
    fieldState: {error},
  } = useController({
    name,
    control,
  });

  const translatedCountries = useMemo(() => {
    if (!countries) return undefined;
    const res = countries
      .filter(_ => TAX_RESIDENCIES.includes(_.code))
      .sort((a, b) => {
        if (a?.label?.[language] && b?.label?.[language]) {
          return a.label[language].localeCompare(b.label[language]);
        } else {
          return 1;
        }
      })
      .map(_ => ({
        name: _.label[language],
        key: _.code,
      }));
    const reorderList = [
      ...res.filter(_ => IMPORTANT_COUNTRIES.includes(_.key)),
      ...res.filter(_ => !IMPORTANT_COUNTRIES.includes(_.key)),
    ];
    return reorderList;
  }, [countries, language]);

  return (
    <YStack gap="$2" $sm={{width: smFullWidth ? '100%' : 'auto'}} minWidth={225}>
      <Label3 color="$neutral500">{label}</Label3>
      {caption && <Label3 color="$neutral500">{caption}</Label3>}
      {translatedCountries && (
        <SelectInput
          items={translatedCountries}
          selectedItem={field.value}
          onSelect={field.onChange}
          placeholder={placeholder ?? '--'}
          disabled={disabled}
          wrapperProps={{
            bordered,
          }}
        />
      )}
      {isCountriesFetching && <Skeleton width="100%" />}
      <InputErrorMessage error={error?.message} />
    </YStack>
  );
}
