import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslate} from '@tolgee/react';
import {useRouter} from 'expo-router';
import {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {YStack} from 'tamagui';

import {Heading4} from '@/components/texts/Heading';
import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {GuardianDocumentsSchemaType, useValidations} from '@/hooks/useValidations';
import {InterviewContinueButton} from '@/modules/interview/components/InterviewContinueButton';
import {GuardianDocumentsCard} from '@/modules/onboarding/components/GuardianDocumentsCard';
import {useOnboardingSearchParams} from '@/modules/onboarding/hooks/useOnboardingSearchParams';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useAppDispatch, useAppSelector} from '@/store';
import {useGetAvailableUsersQuery} from '@/store/queries/usersApi';
import {setGuardianDocuments} from '@/store/reducers/onboarding';
import {WithGuardianType} from '@/types/routeParams';

const ChildDocs = () => {
  const {t} = useTranslate();

  const [userId, setUserId] = useState<number>();
  const [childName, setChildName] = useState('');
  const posthog = usePostHog();

  const router = useRouter();

  const guardianDocuments = useAppSelector(
    state => state.onboarding.personalDetails.guardianDocuments
  );

  const params = useOnboardingSearchParams<WithGuardianType>();

  const {guardianDocumentsSchema} = useValidations();

  const {control, handleSubmit} = useForm({
    resolver: yupResolver(guardianDocumentsSchema),
    mode: 'onChange',
    defaultValues: {
      birthCertificate: guardianDocuments ? [guardianDocuments.birthCertificate] : [],
      soloOrSharedGuardianCertificate: guardianDocuments
        ? [guardianDocuments.soloOrSharedGuardianCertificate]
        : [],
    },
  });

  const {data} = useGetAvailableUsersQuery();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!data) return;

    const self = data.find(user => user.type === 'SELF');

    if (!self) return;

    setUserId(self.id);

    const child = data.find(user => user.id === Number(params.childID));

    setChildName(child?.firstName ?? '');
  }, [data, params.childID]);

  const getGuardianshipTitle = useCallback(
    () =>
      params.guardianType === 'solo'
        ? t('PERSONAL_DETAILS.GUARDIAN_DOCUMENTS.SOLE_GUARDIANSHIP_TITLE')
        : t('PERSONAL_DETAILS.GUARDIAN_DOCUMENTS.SHARED_GUARDIANSHIP_TITLE'),
    [params.guardianType, t]
  );

  const getGuardianshipNote = useCallback(
    () =>
      params.guardianType === 'solo'
        ? t('PERSONAL_DETAILS.GUARDIAN_DOCUMENTS.SOLE_GUARDIANSHIP_NOTE')
        : t('PERSONAL_DETAILS.GUARDIAN_DOCUMENTS.SHARED_GUARDIANSHIP_NOTE'),
    [params.guardianType, t]
  );

  const handleValidSubmit = useCallback(
    (data: GuardianDocumentsSchemaType) => {
      const {birthCertificate, soloOrSharedGuardianCertificate} = data;

      if (birthCertificate.length === 0 || soloOrSharedGuardianCertificate.length === 0) return;

      const birthCertificateDoc = birthCertificate.at(0);
      const soloOrSharedGuardianCertificateDoc = soloOrSharedGuardianCertificate.at(0);

      if (!birthCertificateDoc || !soloOrSharedGuardianCertificateDoc) return;

      dispatch(
        setGuardianDocuments({
          birthCertificate: birthCertificateDoc,
          soloOrSharedGuardianCertificate: soloOrSharedGuardianCertificateDoc,
        })
      );

      const {guardianType, ...rest} = params;

      router.navigate({
        pathname: '/onboarding/personal-details/deposits',
        params: rest,
      });
    },
    [dispatch, params, router]
  );

  return (
    <FullscreenScrollView variant="large">
      <YStack gap="$8">
        <YStack gap="$4">
          <Heading4 variant="medium">{t('PERSONAL_DETAILS.GUARDIAN_DOCUMENTS.TITLE')}</Heading4>
          <GuardianDocumentsCard
            title={t('PERSONAL_DETAILS.GUARDIAN_DOCUMENTS.BIRTH_CERTIFICATE_TITLE')}
            description={t('PERSONAL_DETAILS.GUARDIAN_DOCUMENTS.BIRTH_CERTIFICATE_NOTE', {
              userName: childName,
            })}
            control={control}
            name="birthCertificate"
            userID={userId}
          />
          <GuardianDocumentsCard
            title={getGuardianshipTitle()}
            description={getGuardianshipNote()}
            control={control}
            name="soloOrSharedGuardianCertificate"
            userID={userId}
          />
        </YStack>
        <InterviewContinueButton
          maxWidth="100%"
          width={400}
          alignSelf="center"
          onPress={handleSubmit(handleValidSubmit, data => {
            posthog?.capture('form_submit_failed', data);
          })}
        >
          {t('CONTINUE')}
        </InterviewContinueButton>
      </YStack>
    </FullscreenScrollView>
  );
};

export default ChildDocs;
