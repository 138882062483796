import {XStack} from 'tamagui';

import {Label2, Label3} from '@/components/texts/Label';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';

type Props = {
  title: string;
  heading?: boolean;
  value?: number;
  percentage?: boolean;
};

export const PerformanceDetailsRow = ({title, heading, value, percentage = true}: Props) => (
  <XStack>
    <XStack flexGrow={1} flexShrink={1}>
      {heading ? (
        <Label2 variant="medium">{title}</Label2>
      ) : (
        <Label3 variant="medium" color="$neutral400">
          {title}
        </Label3>
      )}
    </XStack>
    <LocalizedNumbers
      value={value}
      variant={percentage ? 'percentage' : 'currency'}
      showZero={false}
      textComponent={heading ? Label2 : Label3}
      numberStyle={{
        variant: 'medium',
        color: percentage ? (value !== undefined && value > 0 ? '$green' : '$neutral400') : '$text',
      }}
      plus={percentage}
    />
  </XStack>
);
