import {Label3} from '@/components/texts/Label';
import {PropsWithChildren} from 'react';
import {YStack} from 'tamagui';

export function InputLabel({children, label}: PropsWithChildren<{label: string}>) {
  return (
    <YStack gap="$2">
      <Label3 color="$neutral400">{label}</Label3>
      {children}
    </YStack>
  );
}
