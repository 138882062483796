import {Button} from '@/components/buttons/Button';
import SvgFile from '@/components/icons/SvgFile';
import {Modal} from '@/components/modal/Modal';
import {Heading5} from '@/components/texts/Heading';
import {Label3} from '@/components/texts/Label';
import {Divider} from '@/components/views/Divider';
import {ModalRef} from '@/types/modal';
import {useTranslate} from '@tolgee/react';
import React, {PropsWithChildren, useRef} from 'react';
import {View, XStack, YStack} from 'tamagui';

type RejectChildAccountModalProps = {
  childFirstName: string;
  onContinue: () => void;
  onReject: () => void;
};

export function RejectChildAccountModal({
  children,
  childFirstName,
  onContinue,
  onReject,
}: PropsWithChildren<RejectChildAccountModalProps>) {
  const {t} = useTranslate();
  const internalRef = useRef<ModalRef>(null);

  return (
    <Modal ref={internalRef}>
      <Modal.Trigger asChild>{children}</Modal.Trigger>
      <Modal.Content>
        <YStack gap="$4">
          <View
            backgroundColor="$yellowTransparent"
            borderRadius="$full"
            padding="$4"
            alignSelf="center"
          >
            <SvgFile name="warning" size={36} color="$yellowDark" />
          </View>
          <Heading5 variant="medium">
            {t('PERSONAL_DETAILS.CHILD.USER_SUMMARY.REJECT-MODAL.TITLE', {
              firstName: childFirstName,
            })}
          </Heading5>
          <Label3 color="$neutral400">
            {t('PERSONAL_DETAILS.CHILD.USER_SUMMARY.REJECT-MODAL.DESCRIPTION_1')}
          </Label3>
          <XStack alignItems="center" gap="$3">
            <SvgFile name="circle" size={10} color="$primary" />
            <Label3 variant="medium">
              {t('PERSONAL_DETAILS.CHILD.USER_SUMMARY.REJECT-MODAL.POINT_1')}
            </Label3>
          </XStack>
          <XStack alignItems="center" gap="$3">
            <SvgFile name="circle" size={10} color="$primary" />
            <Label3 variant="medium">
              {t('PERSONAL_DETAILS.CHILD.USER_SUMMARY.REJECT-MODAL.POINT_2')}
            </Label3>
          </XStack>
          <XStack alignItems="center" gap="$3">
            <SvgFile name="circle" size={10} color="$primary" />
            <Label3 variant="medium">
              {t('PERSONAL_DETAILS.CHILD.USER_SUMMARY.REJECT-MODAL.POINT_3')}
            </Label3>
          </XStack>
          <XStack alignItems="center" gap="$3">
            <SvgFile name="circle" size={10} color="$primary" />
            <Label3 variant="medium">
              {t('PERSONAL_DETAILS.CHILD.USER_SUMMARY.REJECT-MODAL.POINT_4')}
            </Label3>
          </XStack>
          <Divider />
          <Label3 color="$neutral400">
            {t('PERSONAL_DETAILS.CHILD.USER_SUMMARY.REJECT-MODAL.DESCRIPTION_2')}
          </Label3>
          <XStack alignItems="center" gap="$3">
            <SvgFile name="circle" size={10} color="$primary" />
            <Label3 variant="medium">
              {t('PERSONAL_DETAILS.CHILD.USER_SUMMARY.REJECT-MODAL.POINT_5')}
            </Label3>
          </XStack>
          <XStack alignItems="center" gap="$3">
            <SvgFile name="circle" size={10} color="$primary" />
            <Label3 variant="medium">
              {t('PERSONAL_DETAILS.CHILD.USER_SUMMARY.REJECT-MODAL.POINT_6')}
            </Label3>
          </XStack>
          <Button
            onPress={() => {
              onContinue();
              internalRef.current?.open(false);
            }}
            alignSelf="stretch"
            $gtSm={{maxWidth: 500, width: '100%', alignSelf: 'center'}}
          >
            {t('PERSONAL_DETAILS.CHILD.USER_SUMMARY.REJECT-MODAL.CONTINUE')}
          </Button>
          <Button
            secondary
            onPress={() => {
              onReject();
              internalRef.current?.open(false);
            }}
            alignSelf="stretch"
            $gtSm={{maxWidth: 500, width: '100%', alignSelf: 'center'}}
          >
            {t('PERSONAL_DETAILS.CHILD.USER_SUMMARY.REJECT-MODAL.REJECT')}
          </Button>
        </YStack>
      </Modal.Content>
    </Modal>
  );
}
