import {useTranslate} from '@tolgee/react';
import {useRouter} from 'expo-router';
import {useCallback} from 'react';
import {YStack} from 'tamagui';

import {Card} from '@/components/cards/Card';
import {Heading4, Heading5} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {Note} from '@/components/views/Note';
import {InterviewContinueButton} from '@/modules/interview/components/InterviewContinueButton';
import {useOnboardingSearchParams} from '@/modules/onboarding/hooks/useOnboardingSearchParams';

const EmployerInstructions = () => {
  const {t} = useTranslate();

  const params = useOnboardingSearchParams();

  const router = useRouter();

  const handleContinuePressed = useCallback(() => {
    router.navigate({
      pathname: '/onboarding/personal-details/agreements',
      params,
    });
  }, [router, params]);

  return (
    <FullscreenScrollView variant="small">
      <YStack gap="$8" flex={1}>
        <Heading4 variant="medium">{t('PERSONAL_DETAILS.EMPLOYER_INSTRUCTIONS.TITLE')}</Heading4>
        <YStack gap="$6" flex={1}>
          <Card>
            <YStack gap="$6">
              <Heading5 variant="medium">
                {t('PERSONAL_DETAILS.EMPLOYER_INSTRUCTIONS.INSTRUCTIONS_TITLE')}
              </Heading5>
              <YStack gap="$4">
                <Note
                  icon={{name: 'circle', color: '$primary', size: 12}}
                  iconWrapper={{marginTop: 6}}
                  title={
                    <Paragraph2 color="$neutral500">
                      {t('PERSONAL_DETAILS.EMPLOYER_INSTRUCTIONS.INSTRUCTIONS_1')}
                    </Paragraph2>
                  }
                  asChild="except-style"
                />
                <Note
                  icon={{name: 'circle', color: '$primary', size: 12}}
                  iconWrapper={{marginTop: 6}}
                  title={
                    <Paragraph2 color="$neutral500">
                      {t('PERSONAL_DETAILS.EMPLOYER_INSTRUCTIONS.INSTRUCTIONS_2')}
                    </Paragraph2>
                  }
                  asChild="except-style"
                />
                <Note
                  icon={{name: 'circle', color: '$primary', size: 12}}
                  iconWrapper={{marginTop: 6}}
                  title={
                    <Paragraph2 color="$neutral500">
                      {t('PERSONAL_DETAILS.EMPLOYER_INSTRUCTIONS.INSTRUCTIONS_3')}
                    </Paragraph2>
                  }
                  asChild="except-style"
                />
              </YStack>
            </YStack>
          </Card>
          <InterviewContinueButton onPress={handleContinuePressed} />
        </YStack>
      </YStack>
    </FullscreenScrollView>
  );
};

export default EmployerInstructions;
