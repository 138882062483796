import {useTranslate} from '@tolgee/react';
import {useRouter} from 'expo-router';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {YStack} from 'tamagui';

import {Button} from '@/components/buttons/Button';
import {Heading4, Heading6} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {SustainLevelCheckboxControl} from '@/modules/interview/components/SustainLevelCheckboxControl';
import {SustainLevelEcoModal} from '@/modules/interview/components/SustainLevelEcoModal';
import {SustainLevelFactors} from '@/modules/interview/components/SustainLevelFactors';
import {SustainLevelModalTrigger} from '@/modules/interview/components/SustainLevelModalTrigger';
import {SustainLevelPercentageModal} from '@/modules/interview/components/SustainLevelPercentageModal';
import {TipWithSideModal} from '@/modules/interview/components/TipWithSideModal';
import {useGetQuestionWithAnswers} from '@/modules/interview/hooks/useGetQuestionWithAnswers';
import {SustainLevelFormValues} from '@/modules/interview/types/sustainLevel';
import {useOnboardingSearchParams} from '@/modules/onboarding/hooks/useOnboardingSearchParams';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useAppDispatch, useAppSelector} from '@/store';
import {updateInterview} from '@/store/reducers/interview';
import {InterviewState} from '@/store/reducers/interview/state';
import {ModalRef} from '@/types/modal';

export default function SustainLevelPage() {
  const {t} = useTranslate();

  const router = useRouter();
  const params = useOnboardingSearchParams();

  const posthog = usePostHog();
  const [percentageText, setPercentageText] = useState('');
  const [ecoText, setEcoText] = useState('');

  const {SUSTAIN_LEVEL, SUSTAIN_PERCENTAGE, SUSTAIN_FACTOR} = useAppSelector(
    state => state.interview
  );

  const modalPercentageRef = useRef<ModalRef>(null);
  const modalFactorsRef = useRef<ModalRef>(null);
  const modalEcoRef = useRef<ModalRef>(null);

  const {question, answers} = useGetQuestionWithAnswers('SUSTAIN_LEVEL');
  const {control, handleSubmit} = useForm<SustainLevelFormValues>({
    defaultValues: {
      SUSTAIN_LEVEL,
      SUSTAIN_PERCENTAGE,
      SUSTAIN_FACTOR,
    },
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!answers) return;

    const answer = answers.find(answer => answer.code === 'SUSTAIN_LEVEL_ECOLOGICAL');

    if (!answer) return;

    setEcoText(answer.label);
  }, [answers]);

  const handleSubmitValid = useCallback(
    (values: SustainLevelFormValues) => {
      const data: SustainLevelFormValues = {
        SUSTAIN_LEVEL:
          values.SUSTAIN_LEVEL && values.SUSTAIN_LEVEL.length > 0
            ? values.SUSTAIN_LEVEL
            : undefined,
        SUSTAIN_FACTOR:
          values.SUSTAIN_FACTOR && values.SUSTAIN_FACTOR.length > 0
            ? values.SUSTAIN_FACTOR
            : undefined,
        SUSTAIN_PERCENTAGE: values.SUSTAIN_PERCENTAGE,
      };

      dispatch(updateInterview(data as InterviewState));

      const {SUSTAIN_LEVEL, SUSTAIN_PERCENTAGE} = values;

      const newsPage =
        SUSTAIN_LEVEL?.includes('SUSTAIN_LEVEL_ECOLOGICAL') ||
        SUSTAIN_PERCENTAGE === 'SUSTAIN_PERCENTAGE_100';

      router.navigate({
        pathname: newsPage
          ? '/interview/question/sustain-level-news'
          : '/interview/question/inputs',
        params,
      });
    },
    [dispatch, params, router]
  );

  const openPercentageModal = useCallback(() => {
    if (!modalPercentageRef.current) return;

    modalPercentageRef.current.open(true);
  }, []);

  const openFactorsModal = useCallback(() => {
    if (!modalFactorsRef.current) return;

    modalFactorsRef.current.open(true);
  }, []);

  const openEcoModal = useCallback(() => {
    if (!modalEcoRef.current) return;

    modalEcoRef.current.open(true);
  }, []);

  const tipContent = (
    <YStack gap="$5" marginVertical="$5">
      <Heading6 variant="medium">{t('INTERVIEW.FAQ.SUSTAIN_LEVEL.TITLE')}</Heading6>
      <Paragraph2 variant="medium">
        {t('INTERVIEW.FAQ.SUSTAIN_LEVEL.TITLE_DESCRIPTION_1')}
      </Paragraph2>
      <Paragraph2 color="$neutral400">{t('INTERVIEW.FAQ.SUSTAIN_LEVEL.DESCRIPTION_1')}</Paragraph2>
      <Paragraph2 variant="medium">
        {t('INTERVIEW.FAQ.SUSTAIN_LEVEL.TITLE_DESCRIPTION_2')}
      </Paragraph2>
      <Paragraph2 color="$neutral400">{t('INTERVIEW.FAQ.SUSTAIN_LEVEL.DESCRIPTION_2')}</Paragraph2>
      <Paragraph2 variant="medium">
        {t('INTERVIEW.FAQ.SUSTAIN_LEVEL.TITLE_DESCRIPTION_3')}
      </Paragraph2>
      <Paragraph2 color="$neutral400">{t('INTERVIEW.FAQ.SUSTAIN_LEVEL.DESCRIPTION_3')}</Paragraph2>
    </YStack>
  );

  return (
    <FullscreenScrollView variant="small">
      <YStack gap="$4">
        <YStack gap="$2">
          <Heading4 variant="medium">{question?.label}</Heading4>
        </YStack>
        <YStack gap="$3">
          {answers?.map(answer => (
            <SustainLevelCheckboxControl key={answer.code} control={control} answer={answer}>
              {answer.code === 'SUSTAIN_LEVEL_SIMPLE' && (
                <SustainLevelModalTrigger text={percentageText} onPress={openPercentageModal} />
              )}
              {answer.code === 'SUSTAIN_LEVEL_PAI' && (
                <SustainLevelModalTrigger
                  text={t(
                    'INTERVIEW.WHAT_LEVEL_OF_SUSTAINABILITY.FACTORS.CHOOSE_IMPORTANT_FACTORS'
                  )}
                  onPress={openFactorsModal}
                />
              )}
              {answer.code === 'SUSTAIN_LEVEL_ECOLOGICAL' && (
                <SustainLevelModalTrigger
                  text={t('SUB_ACCOUNTS.READ_MORE')}
                  onPress={openEcoModal}
                />
              )}
            </SustainLevelCheckboxControl>
          ))}
        </YStack>
        <Button
          alignSelf="stretch"
          onPress={handleSubmit(handleSubmitValid, data => {
            posthog?.capture('form_submit_failed', data);
          })}
        >
          {t('CONTINUE')}
        </Button>
        <TipWithSideModal
          tipTitle={t('INTERVIEW.FAQ.SUSTAIN_LEVEL.TITLE')}
          tipContent={tipContent}
        />
      </YStack>
      <SustainLevelPercentageModal
        ref={modalPercentageRef}
        control={control}
        onQuestionLabel={setPercentageText}
      />
      <SustainLevelFactors ref={modalFactorsRef} control={control} />
      <SustainLevelEcoModal ref={modalEcoRef} title={ecoText} />
    </FullscreenScrollView>
  );
}
