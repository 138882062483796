import {Stack, useRouter} from 'expo-router';

export default function StorybookLayout() {
  const router = useRouter();
  return (
    <Stack
      screenOptions={{
        title: 'Storybook',
        headerShown: false,
      }}
    />
  );
}
