import React from 'react';
import {Checkbox, GetProps, styled, View} from 'tamagui';

const StyledPressableRadioButton = styled(Checkbox, {
  name: 'RadioButton',
  role: 'radio',
  cursor: 'pointer',
  backgroundColor: '$primary',
  height: 20,
  width: 20,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  borderWidth: 1,
  borderRadius: '$full',
  userSelect: 'none',
  pressStyle: {
    opacity: 0.8,
    scale: 0.95,
  },
  variants: {
    checked: {
      true: {
        backgroundColor: '$surface',
        borderColor: '$primaryHover',
        hoverStyle: {
          backgroundColor: '$neutral100',
          borderColor: '$primaryHover',
        },
      },
      false: {
        backgroundColor: 'transparent',
        borderColor: '$neutral200',
        hoverStyle: {
          borderColor: '$neutral300',
        },
      },
    },
  } as const,
});

export const RadioButton: React.FC<GetProps<typeof StyledPressableRadioButton>> = ({
  checked,
  onCheckedChange,
  ...props
}) => {
  return (
    <StyledPressableRadioButton checked={checked} onCheckedChange={onCheckedChange} {...props}>
      <View
        animation="fast"
        backgroundColor="$primary"
        hoverStyle={{backgroundColor: '$primaryHover'}}
        height={10}
        width={10}
        borderRadius="$full"
        scale={checked ? 1 : 0}
      />
    </StyledPressableRadioButton>
  );
};
