import {View, XStack} from 'tamagui';

import {Card} from '@/components/cards/Card';
import SvgFile from '@/components/icons/SvgFile';
import {Heading1, Heading5} from '@/components/texts/Heading';
import {Label2, Label3} from '@/components/texts/Label';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {Skeleton} from '@/components/views/Skeleton';
import {useTokenUserInfo} from '@/hooks/useTokenUserInfo';
import {useFetchReferrerQuery} from '@/store/queries/referralApi';
import {skipToken} from '@reduxjs/toolkit/query';
import {useTranslate} from '@tolgee/react';

type FriendsVolumeCardProps = {
  friendsGoal: number;
};

export function FriendsVolumeCard({friendsGoal}: FriendsVolumeCardProps) {
  const {t} = useTranslate();
  const userInfo = useTokenUserInfo();
  const {data: referrer, isFetching} = useFetchReferrerQuery(
    userInfo?.user_id ? {id: userInfo?.user_id} : skipToken
  );

  return (
    <Card padding="$5" gap="$5" flexDirection="row" flexWrap="wrap">
      <View gap="$5" flex={1} minWidth={200}>
        <XStack gap="$3">
          <View
            width={40}
            height={40}
            backgroundColor="$neutralBG"
            borderRadius="$full"
            alignItems="center"
            justifyContent="center"
          >
            <SvgFile size={24} name="friends" color="$primary" />
          </View>
          <Heading5>{t('NAVIGATION.INVITE_FRIENDS.BLOCK_2.TEXT_1')}</Heading5>
        </XStack>
        <Label3 color="$neutral400">{t('NAVIGATION.INVITE_FRIENDS.BLOCK_2.TEXT_2')}</Label3>
      </View>
      <View gap="$5" alignItems="center" flex={1} minWidth={200}>
        <View>
          <Skeleton>
            {!isFetching &&
              referrer?.unbilledAmount !== undefined &&
              ((referrer?.confirmedReferrals ?? 0) < friendsGoal ? (
                <LocalizedNumbers
                  value={referrer.unbilledAmount}
                  textComponent={Heading1}
                  numberStyle={{variant: 'medium', color: '$primary'}}
                  precision="integer"
                />
              ) : (
                <Heading1 color="$primary" variant="medium">
                  {t('INVITE_FRIENDS.INF_AMOUNT')}
                </Heading1>
              ))}
          </Skeleton>
          <Label2 color="$neutral500" variant="medium">
            {t('NAVIGATION.INVITE_FRIENDS.BLOCK_2.TEXT_4')}
          </Label2>
        </View>
      </View>
    </Card>
  );
}
