import {Label4} from '@/components/texts/Label';
import {calculateTimeBetweenDates} from '@/helpers/calculateTimeBetweenDates';
import {Point} from '@/types/skia';
import {useTranslate} from '@tolgee/react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {LayoutChangeEvent} from 'react-native';
import {GetProps, View, XStack} from 'tamagui';

type TimePeriodSelectProps = {
  points: Point[];
  onPointsFiltered: (points: Point[]) => void;
  onPrimary?: boolean;
  initialIndex?: number;
  onIndexChange?: (index: number) => void;
  containerStyle?: GetProps<typeof XStack>;
};

export function TimePeriodSelect({
  points,
  onPointsFiltered,
  onPrimary,
  initialIndex,
  onIndexChange,
  containerStyle,
}: TimePeriodSelectProps) {
  const [index, setIndex] = useState(initialIndex || 2);
  const {t} = useTranslate();
  const [containerSize, setContainerSize] = useState({width: 0, height: 0});
  const onLayout = useCallback((e: LayoutChangeEvent) => {
    setContainerSize({width: e.nativeEvent.layout.width, height: e.nativeEvent.layout.height});
  }, []);

  useEffect(() => {
    onIndexChange?.(index);
  }, [index, onIndexChange]);

  const elements = useMemo(() => {
    return [
      {key: '1W', label: t('OVERVIEW.CHART-PERIODS.1W')},
      {key: '1M', label: t('OVERVIEW.CHART-PERIODS.1M')},
      {key: '3M', label: t('OVERVIEW.CHART-PERIODS.3M')},
      {key: '1Y', label: t('OVERVIEW.CHART-PERIODS.1Y')},
      {key: 'ALL', label: t('OVERVIEW.CHART-PERIODS.ALL')},
    ];
  }, [t]);

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const day = today.getDate();
    let days;
    switch (index) {
      case 0:
        days = calculateTimeBetweenDates({startDate: new Date(year, month, day - 7)});
        break;
      case 1:
        days = calculateTimeBetweenDates({startDate: new Date(year, month - 1, day)});
        break;
      case 2:
        days = calculateTimeBetweenDates({startDate: new Date(year, month - 3, day)});
        break;
      case 3:
        days = calculateTimeBetweenDates({startDate: new Date(year - 1, month, day)});
        break;
      case 4:
        break;
    }
    if (!days) {
      onPointsFiltered(points);
      return;
    }
    onPointsFiltered(points.slice(-(days - 1)));
  }, [index, onPointsFiltered, points]);

  return (
    <XStack onLayout={onLayout} borderRadius="$1" minWidth={300} width="100%" {...containerStyle}>
      <View
        backgroundColor={onPrimary ? '$white' : '$neutral300'}
        opacity={0.1}
        borderRadius="$0.5"
        height={containerSize.height}
        width={containerSize.width / elements.length}
        position="absolute"
        left={index * (containerSize.width / elements.length)}
        animation="fast"
      />
      {elements.map(({key, label}, i) => {
        let color: GetProps<typeof Label4>['color'] = onPrimary ? '$white' : '$neutral500';
        if (!onPrimary && i === index) color = '$primary';
        return (
          <View
            key={key}
            width={containerSize.width / elements.length}
            paddingVertical="$2"
            alignItems="center"
            hoverStyle={{
              opacity: 0.8,
            }}
            pressStyle={{
              opacity: 0.8,
              scale: 0.95,
            }}
            onPress={() => {
              setIndex(i);
            }}
            cursor="pointer"
          >
            <Label4 userSelect="none" variant="medium" color={color}>
              {label}
            </Label4>
          </View>
        );
      })}
    </XStack>
  );
}
