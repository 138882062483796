import {skipToken} from '@reduxjs/toolkit/query';
import {captureException} from '@sentry/react-native';
import {useTranslate} from '@tolgee/react';
import {toast} from 'burnt';
import {Stack, useRouter} from 'expo-router';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Platform} from 'react-native';
import {View, YStack} from 'tamagui';

import {Button} from '@/components/buttons/Button';
import {LoadingSpinner} from '@/components/views/LoadingSpinner';
import {ResponsiveScrollView} from '@/components/views/ResponsiveScrollView';
import {useCustomerIdParamOrRedirect} from '@/hooks/useCustomerIdParamOrRedirect';
import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import ProjectDevelopment from '@/modules/interview/components/planner/ProjectedDevelopment';
import {RecommendedStrategyCard} from '@/modules/interview/components/planner/RecommendedStrategyCard';
import StrategyInfo from '@/modules/interview/components/planner/strategy-info/StrategyInfo';
import {WebFooter} from '@/modules/navigation/components/footer/WebFooter';
import {Header} from '@/modules/navigation/components/header/Header';
import {AllocationRatioCompare} from '@/modules/orders/components/ChangeStrategy/ComparisonStrategyAllocationsRow';
import {Strategy} from '@/modules/orders/components/ChangeStrategy/ComparisonStrategyName';
import {ComparisonTab} from '@/modules/orders/components/ChangeStrategy/ComparisonTab';
import {WarningBox} from '@/modules/orders/components/WarningBox';
import {useGetSpecificOrderConstraint} from '@/modules/orders/hooks/useGetSpecificOrderConstraint';
import {useGetAllocationsRatios} from '@/modules/portfolio/hooks/useGetAssetAllocation';
import {useGetPerformanceSummaryQuery} from '@/store/queries/apeironApi';
import {
  useGetCustomerAccountQuery,
  usePostCustomerOrderMutation,
} from '@/store/queries/customersApi';
import {useInvestmentStrategiesQuery, useRiskProfilesQuery} from '@/store/queries/referenceApi';
import {Product} from '@/types/fixedTypes/customers.v2/Product';

const PRODUCTS_WITH_VIOLATION_ERROR: Product[] = ['VL_ACCOUNT', 'THEME_ACCOUNT'];

const ChangeStrategyScreen = () => {
  const {t} = useTranslate();

  const router = useRouter();

  const [selectedStrategyCode, setSelectedStrategyCode] = useState<string>();
  const [deposit, setDeposit] = useState<number>();
  const [savings, setSavings] = useState<number>();
  const [currentStrategy, setCurrentStrategy] = useState<Strategy>();
  const [newStrategy, setNewStrategy] = useState<Strategy>();
  const [ratioCompareData, setRatioCompareData] = useState<AllocationRatioCompare[]>([]);
  const [riskStrategiesCodes, setRiskStrategiesCodes] = useState<
    {label: string; acceptableInvestmentStrategies: string[]}[]
  >([]);
  const [equityQuote, setEquityQuote] = useState<{curr?: number; new?: number}>();
  const [creating, setCreating] = useState(false);

  const customerId = useCustomerIdParamOrRedirect();

  const currAllocationsRatios = useGetAllocationsRatios({
    allocations: currentStrategy?.allocations,
    groupType: 'assets',
  });
  const newAllocationsRatios = useGetAllocationsRatios({
    allocations: newStrategy?.allocations,
    groupType: 'assets',
  });

  const {data: accSummary, isFetching: isAccSummaryFetching} = useGetPerformanceSummaryQuery(
    customerId ? {customerId} : skipToken
  );
  const {data: customerData, isFetching: isCustomerFetching} = useGetCustomerAccountQuery(
    customerId ?? skipToken
  );
  const {data: investmentStrategies, isFetching: isInvestmentStrategiesFetching} =
    useInvestmentStrategiesQuery();
  const {data: riskProfiles, isFetching: isRiskProfilesFetching} = useRiskProfilesQuery();

  const [postOrder] = usePostCustomerOrderMutation();

  const {constraint: {canBeCreated, firstViolationLabel} = {}, loading} =
    useGetSpecificOrderConstraint(customerId, 'STRATEGY_CHANGE');

  const {getStrategyByCode, getAll} = useRefdataLocalizable();

  useEffect(() => {
    const localizableRiskProfiles = getAll(riskProfiles);

    if (!localizableRiskProfiles) return;

    const riskProfilesStrategiesCodes = localizableRiskProfiles
      .map(riskProfile => ({
        label: riskProfile.label,
        acceptableInvestmentStrategies: riskProfile.acceptableInvestmentStrategies,
      }))
      .sort(
        (a, b) => a.acceptableInvestmentStrategies.length - b.acceptableInvestmentStrategies.length
      );

    setRiskStrategiesCodes(riskProfilesStrategiesCodes);
  }, [getAll, riskProfiles]);

  useEffect(() => {
    if (!customerData || customerData.product === 'THEME_ACCOUNT') return;

    const currInvestmentStrategy = getStrategyByCode(
      investmentStrategies,
      customerData.currentInvestmentStrategy
    );
    const riskProfileLabel = riskStrategiesCodes.find(profile =>
      profile.acceptableInvestmentStrategies.includes(customerData.currentInvestmentStrategy)
    );

    if (!riskProfileLabel || !currInvestmentStrategy) return;

    setCurrentStrategy({...currInvestmentStrategy, riskProfileLabel: riskProfileLabel.label});
  }, [customerData, getStrategyByCode, investmentStrategies, riskStrategiesCodes]);

  useEffect(() => {
    if (!selectedStrategyCode) return;

    const newStrategy = getStrategyByCode(investmentStrategies, selectedStrategyCode);
    const riskProfileLabel = riskStrategiesCodes.find(profile =>
      profile.acceptableInvestmentStrategies.includes(selectedStrategyCode)
    );

    if (!riskProfileLabel || !newStrategy) return;

    setNewStrategy({...newStrategy, riskProfileLabel: riskProfileLabel.label});
  }, [getStrategyByCode, investmentStrategies, riskStrategiesCodes, selectedStrategyCode]);

  useEffect(() => {
    if (!currAllocationsRatios || !newAllocationsRatios) return;

    const currCompareObj = currAllocationsRatios.reduce<Record<string, AllocationRatioCompare>>(
      (full, item) => {
        full[item.key] = {
          key: item.key,
          title: item.title,
          color: item.color,
          currRatio: item.ratio,
          newRatio: newAllocationsRatios.find(ratio => ratio.key === item.key)?.ratio,
        };

        return full;
      },
      {}
    );

    const fullCompare = newAllocationsRatios.reduce((full, item) => {
      if (!full[item.key]) {
        full[item.key] = {
          key: item.key,
          title: item.title,
          color: item.color,
          newRatio: item.ratio,
        };
      }

      return full;
    }, currCompareObj);

    const equity = fullCompare['EQUITY'];

    if (equity) {
      setEquityQuote({new: equity.newRatio, curr: equity.currRatio});
    }

    const data = Object.values(fullCompare).sort((a, b) => (b.currRatio ?? 0) - (a.currRatio ?? 0));

    setRatioCompareData(data);
  }, [currAllocationsRatios, newAllocationsRatios]);

  useEffect(() => {
    if (!customerData || customerData.product === 'THEME_ACCOUNT' || !accSummary) return;

    const deposit = accSummary.balance;
    const savings = customerData.currentRecurringDeposit.amount;

    setDeposit(deposit);
    setSavings(savings);
  }, [customerData, accSummary, router]);

  const changeStrategy = useCallback(async () => {
    if (
      !customerId ||
      !currentStrategy ||
      !newStrategy ||
      currentStrategy.code === newStrategy.code
    )
      return;

    try {
      setCreating(true);

      await postOrder({
        orderType: 'STRATEGY_CHANGE',
        strategy: newStrategy.code,
        customerId,
      }).unwrap();

      router.replace({pathname: '/overview/[customerId]/orders', params: {customerId}});

      toast({
        preset: 'done',
        title: t('SNACKBAR.CHANGE_STRATEGY_TITLE'),
        message: t('SNACKBAR.CHANGE_STRATEGY_MESSAGE'),
      });
    } catch (error) {
      captureException(error);
    } finally {
      setCreating(false);
    }
  }, [currentStrategy, customerId, newStrategy, postOrder, router, t]);

  const handleChangeStrategyButtonPressed = useCallback(() => {
    changeStrategy().catch(captureException);
  }, [changeStrategy]);

  const showViolationError = useMemo(
    () => !!customerData?.product && PRODUCTS_WITH_VIOLATION_ERROR.includes(customerData.product),
    [customerData?.product]
  );

  return (
    <View flex={1}>
      <ResponsiveScrollView>
        <YStack gap="$3">
          <Stack.Screen
            options={{
              headerShown: true,
              header: () => (
                <Header title={t('CHANGE_STRATEGY.TITLE')} backButton={Platform.OS !== 'web'} />
              ),
            }}
          />
          {customerData && currentStrategy && (
            <RecommendedStrategyCard
              isSV={customerData.product === 'SAVINGS_ACCOUNT'}
              isVL={customerData.product === 'VL_ACCOUNT'}
              onStrategyChange={strategy => {
                setSelectedStrategyCode(strategy.code);
              }}
              changeStrategyNote
              defaultType={currentStrategy.code.includes('AP') ? 'global' : 'green'}
              defaultStrategy={currentStrategy.code}
              planner={false}
            />
          )}
          {selectedStrategyCode && customerData && (
            <YStack gap="$3">
              <StrategyInfo
                selectedStrategy={selectedStrategyCode}
                isSV={customerData.product === 'SAVINGS_ACCOUNT'}
                hideBadge
              />
              {deposit !== undefined && savings !== undefined && (
                <ProjectDevelopment
                  initialDeposit={deposit}
                  initialMonthlySavings={savings}
                  product={customerData.product}
                  selectedStrategy={selectedStrategyCode}
                  showInputs={false}
                  additionalElements={[{label: t('CHANGE_STRATEGY.COMPARISON.TAB_TITLE')}]}
                >
                  <ComparisonTab
                    currentStrategy={currentStrategy}
                    newStrategy={newStrategy}
                    equityQuote={equityQuote}
                    ratioCompareData={ratioCompareData}
                  />
                </ProjectDevelopment>
              )}
            </YStack>
          )}
          <WarningBox text={firstViolationLabel} show={!loading && !canBeCreated} />
          <Button
            disabled={!canBeCreated}
            onPress={handleChangeStrategyButtonPressed}
            isLoading={creating}
          >
            {t('CHANGE_STRATEGY.CHANGE_STRATEGY')}
          </Button>
        </YStack>

        <WebFooter />
      </ResponsiveScrollView>
      {(loading ||
        isAccSummaryFetching ||
        isCustomerFetching ||
        isInvestmentStrategiesFetching ||
        isRiskProfilesFetching) && (
        <View
          flex={1}
          position="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          justifyContent="center"
          alignItems="center"
        >
          <LoadingSpinner color="$primary" size={64} />
        </View>
      )}
    </View>
  );
};

export default ChangeStrategyScreen;
