import {DISCOVERY, SIGN_UP_DISCOVERY} from '@/providers/session-provider/constants';
import {store, useAppDispatch, useAppSelector} from '@/store';
import {setCodeVerifier} from '@/store/reducers/auth';
import {CodeVerifierType} from '@/store/reducers/auth/state';
import {captureException} from '@sentry/react-native';
import {AuthRequest, TokenResponse, exchangeCodeAsync, makeRedirectUri} from 'expo-auth-session';
import {Href, useGlobalSearchParams, useRouter} from 'expo-router';
import {useCallback, useEffect, useState} from 'react';

export type CustomTokenResponse = TokenResponse & {url?: string};

const request = new AuthRequest({
  clientId: process.env.EXPO_PUBLIC_AUTH_CLIENT_ID,
  redirectUri: __DEV__ ? makeRedirectUri({path: '/'}) : process.env.EXPO_PUBLIC_REDIRECT_URL,
  scopes: ['openid', 'profile'],
});

export const useCustomAuthRequest = (type: CodeVerifierType) => {
  const dispatch = useAppDispatch();
  const codeVerifier = useAppSelector(state => state.auth.codeVerifier);
  const router = useRouter();
  const {code, error} = useGlobalSearchParams<{code?: string; error?: string}>();
  const [response, setResponse] = useState<CustomTokenResponse | null>(null);

  const discovery = type === 'signup' ? SIGN_UP_DISCOVERY : DISCOVERY;

  const promptAsync = useCallback(async () => {
    // Step 2: Build the authentication URL
    if (type === 'signup') {
      const storeState = store.getState();
      const proposal = storeState?.onboarding?.proposal;
      const referralCode = storeState?.onboarding?.referralCode;
      if (proposal) request.extraParams.ginmon_proposal = btoa(JSON.stringify(proposal));
      if (referralCode) request.extraParams.ginmon_referral = referralCode;
    }
    const authUrl = await request.makeAuthUrlAsync(discovery);

    // Step 3: Store the codeVerifier for later use (since the page will reload)
    dispatch(setCodeVerifier({code: request.codeVerifier!, type}));

    // Step 4: Redirect the user to the authentication URL in the same tab
    router.navigate(authUrl as Href);
  }, [discovery, dispatch, router, type]);

  useEffect(() => {
    const handleRedirect = async () => {
      if (code) {
        if (codeVerifier === undefined) {
          // if codeVerifier is undefined that usually means user came from keycloak directly
          // e.g. from 2nd guardian email registration
          router.replace('/');
        }
        // Retrieve the codeVerifier from localStorage
        if (codeVerifier?.type !== type) {
          return;
        }

        // Exchange the authorization code for tokens
        try {
          const tokenResponse = await exchangeCodeAsync(
            {
              clientId: process.env.EXPO_PUBLIC_AUTH_CLIENT_ID,
              redirectUri: request.redirectUri,
              code,
              extraParams: {
                code_verifier: codeVerifier.code,
              },
            },
            discovery
          );
          setResponse({...tokenResponse} as CustomTokenResponse);

          // Remove the codeVerifier from localStorage for security
          dispatch(setCodeVerifier());
        } catch (exchangeError) {
          router.replace('/');
        }
      } else if (error) {
        captureException(error);
        // Handle authentication error
        console.debug('Authentication error:', error);
        router.replace('/');
      }
    };

    // Call the function when the component mounts
    handleRedirect();
  }, [code, codeVerifier, discovery, dispatch, error, router, type]);

  const reset = useCallback(() => {
    setResponse(null);
  }, []);

  return {response, promptAsync, reset};
};
