import {useTranslate} from '@tolgee/react';
import {useState} from 'react';
import {Platform} from 'react-native';
import {useMedia, XStack} from 'tamagui';

import {FilterSelect} from '@/components/inputs/FilterSelect';
import {ResponsiveScrollViewContent} from '@/components/views/ResponsiveScrollView';
import {useCustomerIdParamOrRedirect} from '@/hooks/useCustomerIdParamOrRedirect';
import {AccountActivityList} from '@/modules/account-activity/components/AccountActivityList';
import useInfiniteScroll from '@/modules/account-activity/hooks/useInfiniteScroll';
import {Header} from '@/modules/navigation/components/header/Header';
import {useGetTransactionsQuery} from '@/store/queries/apeironApi';
import {TransactionsDTO} from '@/types/api/apeiron';

export default function TransactionsPage() {
  const media = useMedia();
  const {t} = useTranslate();
  const customerId = useCustomerIdParamOrRedirect();
  const [category, setCategory] = useState<string>('ALL');
  const {
    data: transactions,
    isFetching,
    isLoading,
    loadMore,
    refetch,
    allDataFetched,
  } = useInfiniteScroll<TransactionsDTO>(useGetTransactionsQuery, {
    customerId,
    limit: 40,
    category,
  });
  return (
    <ResponsiveScrollViewContent>
      <XStack justifyContent="space-between">
        <Header
          style={{flexShrink: 1}}
          title={t('ACCOUNT.TRANSACTIONS')}
          backButton={Platform.OS !== 'web'}
        />
        <XStack marginBottom="$2" paddingRight="$4">
          <FilterSelect.Transactions
            onFilterSelected={option => {
              setCategory(option);
            }}
            customerId={customerId}
            category={category}
          />
        </XStack>
      </XStack>
      <AccountActivityList
        transactionsResponse={transactions}
        isFetching={isFetching}
        onRefresh={refetch}
        onLoadMore={loadMore}
        customerId={customerId}
      />
    </ResponsiveScrollViewContent>
  );
}
