import {PrimaryGradientSvg} from '@/components/icons/PrimaryGradientSvg';
import {PropsWithChildren} from 'react';
import {GetProps, YStack} from 'tamagui';

export function GradientCard({
  containerStyle,
  children,
}: PropsWithChildren<{containerStyle?: GetProps<typeof YStack>}>) {
  return (
    <YStack overflow="hidden" {...containerStyle}>
      <PrimaryGradientSvg />
      {children}
    </YStack>
  );
}
