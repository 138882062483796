import {Heading5} from '@/components/texts/Heading';
import React from 'react';
import {XStack} from 'tamagui';

type Props = {
  value: string;
  isFocused: boolean;
  error?: boolean;
};

export const CodeFieldCell = ({value, isFocused, error}: Props) => {
  return (
    <XStack
      animation="fast"
      height={48}
      width={48}
      alignItems="center"
      justifyContent="center"
      borderWidth={1}
      borderColor={isFocused ? '$primary' : error ? '$red' : '$neutral500'}
      backgroundColor={isFocused ? '$primaryTransparent' : 'transparent'}
      borderRadius="$1"
    >
      {value !== undefined && (
        <Heading5 animation="slow" enterStyle={{scale: 0}} variant="bold" textAlign="center">
          {value}
        </Heading5>
      )}
    </XStack>
  );
};
