import {Button} from '@/components/buttons/Button';
import SvgFile from '@/components/icons/SvgFile';
import {Heading5} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {TipWithSideModal} from '@/modules/interview/components/TipWithSideModal';
import {useTranslate} from '@tolgee/react';
import React from 'react';
import {Platform} from 'react-native';
import {View, XStack, YStack} from 'tamagui';

type FriendsTipContentProps = {
  onShare: () => Promise<void>;
};

export function FriendsTipContent({onShare}: FriendsTipContentProps) {
  const {t} = useTranslate();
  return (
    <TipWithSideModal
      tipTitle={t('NAVIGATION.INVITE_FRIENDS.INFO')}
      tipContent={
        <YStack $gtSm={{paddingHorizontal: '$2'}} gap="$4" marginTop="$6">
          <Heading5 variant="medium" paddingBottom="$2">
            {t('NAVIGATION.INVITE_FRIENDS.INFO')}
          </Heading5>

          <XStack alignItems="flex-start" gap="$2">
            <View
              padding="$1"
              backgroundColor="$neutralBG"
              borderRadius="$full"
              alignItems="center"
              justifyContent="center"
            >
              <SvgFile name="people" size={16} color="$primary" />
            </View>
            <YStack gap="$1" flex={1} flexWrap="wrap">
              <Paragraph2 variant="medium">
                {t('NAVIGATION.INVITE_FRIENDS.NOTE.POINT_1_TITLE')}
              </Paragraph2>
              <Paragraph2 width="100%" color="$neutral400">
                {t('NAVIGATION.INVITE_FRIENDS.NOTE.POINT_1')}
              </Paragraph2>
            </YStack>
          </XStack>

          <XStack alignItems="flex-start" gap="$2">
            <View
              padding="$1"
              backgroundColor="$neutralBG"
              borderRadius="$full"
              alignItems="center"
              justifyContent="center"
            >
              <SvgFile name="checkbox-circle" size={16} color="$primary" />
            </View>
            <YStack gap="$1" flex={1} flexWrap="wrap">
              <Paragraph2 variant="medium">
                {t('NAVIGATION.INVITE_FRIENDS.NOTE.POINT_2_TITLE')}
              </Paragraph2>
              <Paragraph2 width="100%" color="$neutral400">
                {t('NAVIGATION.INVITE_FRIENDS.NOTE.POINT_2')}
              </Paragraph2>
            </YStack>
          </XStack>

          <XStack alignItems="flex-start" gap="$2" marginBottom="$3">
            <View
              padding="$1"
              backgroundColor="$neutralBG"
              borderRadius="$full"
              alignItems="center"
              justifyContent="center"
            >
              <SvgFile name="prize" size={16} color="$primary" />
            </View>
            <YStack gap="$1" flex={1} flexWrap="wrap">
              <Paragraph2 variant="medium">
                {t('NAVIGATION.INVITE_FRIENDS.NOTE.POINT_3_TITLE')}
              </Paragraph2>
              <Paragraph2 width="100%" color="$neutral400">
                {t('NAVIGATION.INVITE_FRIENDS.NOTE.POINT_3')}
              </Paragraph2>
            </YStack>
          </XStack>

          {Platform.OS !== 'web' && (
            <Button onPress={onShare} marginBottom="$5">
              {t('NAVIGATION.INVITE_FRIENDS.BLOCK_1.BUTTON_1')}
            </Button>
          )}
        </YStack>
      }
      color="white"
    />
  );
}
