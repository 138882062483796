import {isAccountExpired} from '@/helpers/isAccountExpired';
import {useIsUserComplete} from '@/hooks/useIsUserComplete';
import {useGetCustomerAccountsQuery} from '@/store/queries/customersApi';
import {useGetValidOrdersQuery} from '@/store/queries/ordersApi';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';
import {skipToken} from '@reduxjs/toolkit/query';
import {useMemo} from 'react';

export const useUserFinishedOnboardingBefore = () => {
  const {
    isUserComplete,
    isFetching: isUserCompleteFetching,
    isLoading: isUserCompleteLoading,
  } = useIsUserComplete();
  const {
    data: customers,
    isFetching: isCustomersFetching,
    isLoading: isCustomersLoading,
  } = useGetCustomerAccountsQuery(!isUserComplete ? skipToken : undefined);
  const {
    data: orders,
    isFetching: isOrdersFetching,
    isLoading: isOrdersLoading,
  } = useGetValidOrdersQuery(!isUserComplete ? skipToken : undefined);
  const {data: selfUser, isFetching: isSelfUserFetching} = useGetSelfUserQuery(); // TODO: FRONTEND-196 - for junior we might need to use different userid

  const finishedBefore = useMemo(
    () =>
      selfUser?.complete &&
      ((customers?.totalCount && customers?.totalCount >= 0) ||
        (orders?.items && orders.items.filter(acc => !isAccountExpired(acc))?.length > 0)),
    [customers?.totalCount, orders?.items, selfUser?.complete]
  );

  const isFetching = useMemo(
    () => isUserCompleteFetching || isCustomersFetching || isOrdersFetching || isSelfUserFetching,
    [isCustomersFetching, isOrdersFetching, isSelfUserFetching, isUserCompleteFetching]
  );

  return {
    finishedBefore,
    isFetching,
    isLoading: isUserCompleteLoading || isCustomersLoading || isOrdersLoading,
  };
};
