import SvgFile from '@/components/icons/SvgFile';
import {InputErrorMessage} from '@/components/texts/InputErrorMessage';
import {Label2, Label3} from '@/components/texts/Label';
import {PropsWithChildren} from 'react';
import {GestureResponderEvent} from 'react-native';
import {GetProps, YStack} from 'tamagui';
import {SelectInputWrapper} from './SelectInputWrapper';

type Props = {
  onPress: (event: GestureResponderEvent) => void;
  text?: string;
  selectedItem?: string;
  wrapper?: GetProps<typeof SelectInputWrapper>;
  containerProps?: GetProps<typeof YStack>;
  error?: string;
  disabled?: boolean;
  testID?: string;
};

export const Input = ({
  children,
  onPress,
  text,
  wrapper,
  containerProps,
  selectedItem,
  error,
  disabled,
  testID,
}: PropsWithChildren<Props>) => {
  const SelectInputTextComponent = wrapper?.bordered ? Label3 : Label2;
  return (
    <YStack gap="$2" {...containerProps}>
      <SelectInputWrapper
        onPress={disabled ? undefined : onPress}
        disabled={disabled}
        testID={testID}
        {...wrapper}
      >
        {children ? (
          children
        ) : (
          <>
            <SelectInputTextComponent
              variant="medium"
              color={selectedItem && !disabled ? '$text' : '$neutral400'}
            >
              {text}
            </SelectInputTextComponent>
            <SvgFile
              name="chevron-down"
              size={18}
              color="$primary"
              $platform-native={{rotate: '270deg'}}
            />
          </>
        )}
      </SelectInputWrapper>
      <InputErrorMessage error={error} />
    </YStack>
  );
};
