import {TimePeriodSelect} from '@/components/inputs/TimePeriodSelect';
import {useAppSelector} from '@/store';
import {CrossAccSummaryResponse} from '@/types/api/apeiron';
import {GetTextLabelFunc, Point} from '@/types/skia';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {XStack, useMedia} from 'tamagui';

type HomeChartPeriodSelectProps = {
  performanceSummary: CrossAccSummaryResponse[] | undefined;
  setFilteredPoints: React.Dispatch<React.SetStateAction<Point[] | undefined>>;
  variant: 'small' | 'large';
  updateAxisLabel?: React.Dispatch<React.SetStateAction<GetTextLabelFunc | undefined>>;
};

export function HomeChartPeriodSelect({
  performanceSummary,
  setFilteredPoints,
  variant,
  updateAxisLabel,
}: HomeChartPeriodSelectProps) {
  const [index, setIndex] = useState(0);
  const media = useMedia();
  const language = useAppSelector(state => state.app.language);
  const dataPoints: Point[] | undefined = useMemo(() => {
    return performanceSummary?.[0]?.history?.map(hist => ({
      x: Date.parse(hist.date),
      y: hist.balance,
      extra: hist.performance.amount,
    }));
  }, [performanceSummary]);

  const updatedAxisLabel = useCallback(
    (value: number, i: number) => {
      let show = true;

      if (index === 4) {
        show = i % 2 === 0;
      }

      const options: Intl.DateTimeFormatOptions = {
        month: 'short',
      };

      if (index < 3) {
        options.day = 'numeric';
      } else {
        options.year = 'numeric';
      }

      return show ? Intl.DateTimeFormat(language, options).format(value) : undefined;
    },
    [index, language]
  );

  useEffect(() => {
    if (updateAxisLabel !== undefined) {
      updateAxisLabel(() => {
        return updatedAxisLabel;
      });
    }
  }, [updateAxisLabel, updatedAxisLabel]);

  if (!dataPoints || (variant === 'small' && !media.sm) || (variant === 'large' && media.sm))
    return null;

  if (variant === 'small') {
    return (
      <XStack padding="$2">
        <TimePeriodSelect
          key="timeperiodselector"
          points={dataPoints}
          onPointsFiltered={setFilteredPoints}
          initialIndex={index}
          onIndexChange={setIndex}
          onPrimary
        />
      </XStack>
    );
  } else {
    return (
      <TimePeriodSelect
        key="timeperiodselector"
        points={dataPoints}
        onPointsFiltered={setFilteredPoints}
        initialIndex={index}
        onIndexChange={setIndex}
        containerStyle={{
          alignSelf: 'flex-start',
          flexBasis: 0,
        }}
      />
    );
  }
}
