import SvgFile from '@/components/icons/SvgFile';
import {Modal} from '@/components/modal/Modal';
import {Label2, Label3} from '@/components/texts/Label';
import {LocalizedDate} from '@/components/texts/LocalizedDate';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import DetailView from '@/modules/orders/components/DetailView';
import {isOrderWithAmount} from '@/modules/orders/types/isOrderWithAmount';
import {useOrderTypesQuery} from '@/store/queries/referenceApi';
import {OrderResponse} from '@/types/api/customers.v2';
import {useMemo} from 'react';
import {View, XStack, YStack} from 'tamagui';

type OrdersItemProps = {
  item: OrderResponse;
  customerId: string;
};

export function OrderItem({item, customerId}: OrdersItemProps) {
  const {data: orderTypes} = useOrderTypesQuery();
  const {getByCode} = useRefdataLocalizable();

  const statusIcon = useMemo(() => {
    const status = item.status;

    switch (status) {
      case 'CANCELED':
        return (
          <View padding="$2" backgroundColor="$neutral50" borderRadius="$full">
            <SvgFile size={24} name="type-close" color="$neutral500" />
          </View>
        );
      case 'REJECTED':
        return (
          <View padding="$2" backgroundColor="$redTransparent" borderRadius="$full">
            <SvgFile size={24} name="type-rejected" color="$red" />
          </View>
        );
      case 'COMPLETED':
        return (
          <View padding="$2" backgroundColor="$greenTransparent" borderRadius="$full">
            <SvgFile size={24} name="type-complete" color="$green" />
          </View>
        );
      case 'SUBMITTED':
      case 'CREATED':
        return (
          <View padding="$2" backgroundColor="$ceruleanTransparent" borderRadius="$full">
            <SvgFile size={24} name="type-inprogress" color="$cerulean" />
          </View>
        );
    }
  }, [item.status]);

  return (
    <>
      <Modal>
        <Modal.Trigger asChild>
          <XStack gap="$2" alignItems="center" group>
            {statusIcon}
            <YStack flex={1}>
              <XStack justifyContent="space-between" alignItems="center" gap="$2">
                <Label2 variant="medium" flex={1}>
                  {getByCode(orderTypes, item.orderType)?.label}
                </Label2>
                {isOrderWithAmount(item) && (
                  <LocalizedNumbers
                    numberStyle={{variant: 'medium'}}
                    textComponent={Label2}
                    value={item.amount}
                  />
                )}
                <SvgFile name="chevron-right" size={24} color="$primary" />
              </XStack>
              {item.createdAt && <LocalizedDate date={item.createdAt} textComponent={Label3} />}
            </YStack>
          </XStack>
        </Modal.Trigger>
        <Modal.Content>
          <DetailView order={item} customerId={customerId} />
        </Modal.Content>
      </Modal>
    </>
  );
}
