import {MINUTE, baseApi} from '@/store/queries/baseApi';
import {
  AddressChangeRequest,
  ProofOfResidenceDocument,
  TaxExemptionOrderChangeListResponse,
  TaxExemptionOrderChangeRequest,
  UserResponseV3,
} from '@/types/api/users';
import {compare} from 'fast-json-patch';

const BASE_PATH = 'users/v3';

const extendedUsersApi = baseApi.injectEndpoints({
  endpoints: build => ({
    getAvailableUsers: build.query<UserResponseV3[], GetAvailableUsersPayload | void>({
      query: () => ({url: BASE_PATH}),
      transformResponse: (response: UserResponseV3[], _meta, args) => {
        if (!args) return response;

        const user = response.find(user => {
          let condition = user.type === args.type;

          if (typeof args.complete !== 'undefined') {
            condition = condition && user.complete === args.complete;
          }

          return condition;
        });

        if (!user) return [];

        return [user];
      },
      keepUnusedDataFor: 5 * MINUTE,
      providesTags: ['USERS'],
    }),
    getSelfUser: build.query<UserResponseV3 | undefined, void>({
      query: () => ({url: BASE_PATH}),
      transformResponse: (response: UserResponseV3[], _meta, args) => {
        return response.find(_ => _.type === 'SELF');
      },
      providesTags: ['USERS'],
      keepUnusedDataFor: 5 * MINUTE,
    }),
    getSpecificUser: build.query<UserResponseV3 | undefined, {userID: string}>({
      query: ({userID}) => ({url: `${BASE_PATH}/${userID}`}),
      providesTags: ['USERS'],
      keepUnusedDataFor: 5 * MINUTE,
    }),
    getTaxExemptions: build.query<TaxExemptionOrderChangeListResponse, {userID: number}>({
      query: ({userID}) => ({url: `${BASE_PATH}/${userID}/tax-exemption-order-changes`}),
      providesTags: ['TAX_EXEMPTIONS'],
      keepUnusedDataFor: 5 * MINUTE,
    }),
    createTaxExemptionOrderChange: build.mutation<
      TaxExemptionOrderChangeListResponse,
      TaxExemptionOrderChangeRequest & {userID: string}
    >({
      query: ({userID, ...data}) => ({
        url: `${BASE_PATH}/${userID}/tax-exemption-order-changes`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['TAX_EXEMPTIONS'],
    }),
    changeAddress: build.mutation<void, AddressChangeRequest & {userID: number}>({
      query: ({userID, ...data}) => ({
        url: `${BASE_PATH}/${userID}/address-change`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['USERS'],
    }),
    createChild: build.mutation<UserResponseV3, void>({
      query: () => ({
        url: BASE_PATH,
        method: 'POST',
        body: {type: 'CHILD'},
      }),
      invalidatesTags: ['USERS'],
    }),
    updateUser: build.mutation<UserResponseV3, PatchUserRequest>({
      query: ({currentUser, changes}) => ({
        url: `${BASE_PATH}/${currentUser.id}`,
        method: 'PATCH',
        body: JSON.stringify(compare(currentUser, {...currentUser, ...changes})),
        headers: {
          'content-type': 'application/json-patch+json',
        },
      }),
      invalidatesTags: ['USERS'],
    }),
    savePoR: build.mutation<void, PostPoRRequest>({
      query: request => ({
        url: `${BASE_PATH}/${request.userID}/proof-of-residence`,
        method: 'POST',
        body: {documents: request.documents},
        headers: {'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['USERS'],
    }),
  }),
  overrideExisting: true,
});

type UserType = 'SELF' | 'CHILD';

type GetAvailableUsersPayload = {
  type: UserType;
  complete?: boolean;
};

export type PatchUserRequest = {
  currentUser: UserResponseV3;
  changes: Partial<UserResponseV3>;
};

export type PostPoRRequest = {
  userID: number;
  documents: ProofOfResidenceDocument[];
};

export type PatchUsersRequest = {
  id: number;
  data: string;
};

export const {
  useGetAvailableUsersQuery,
  useGetTaxExemptionsQuery,
  useCreateTaxExemptionOrderChangeMutation,
  useCreateChildMutation,
  useUpdateUserMutation,
  useGetSelfUserQuery,
  useChangeAddressMutation,
  useSavePoRMutation,
  useGetSpecificUserQuery,
} = extendedUsersApi;
