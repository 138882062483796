import {EmailVerificationSuccess} from '@/modules/orders/components/EmailVerification/EmailVerificationSuccess';
import useEmailVerifiedInterval from '@/providers/session-provider/useEmailVerificationState';
import {useAppDispatch} from '@/store';
import {baseApi} from '@/store/queries/baseApi';
import {useRouter} from 'expo-router';
import {useEffect} from 'react';

export default function EmailVerificationSuccessPage() {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const {emailVerified} = useEmailVerifiedInterval();

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (emailVerified) {
      timer = setTimeout(() => {
        dispatch(baseApi.util.invalidateTags(['USERS']));
        router.navigate({
          pathname: '/',
        });
      }, 3000);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [dispatch, emailVerified, router]);

  return <EmailVerificationSuccess />;
}
