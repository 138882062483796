import {useTranslate} from '@tolgee/react';
import React, {useMemo} from 'react';
import {useMedia, View, XStack, YStack} from 'tamagui';

import {StrategyBadge} from '@/components/buttons/StrategyBadge';
import {Heading4} from '@/components/texts/Heading';
import {Label2} from '@/components/texts/Label';
import {LocalizedDate} from '@/components/texts/LocalizedDate';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {Skeleton} from '@/components/views/Skeleton';
import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {SummaryCard} from '@/modules/onboarding/components/summary/SummaryCard';
import {useCountriesQuery, useTitlesQuery} from '@/store/queries/referenceApi';
import {Planner} from '@/store/reducers/onboarding/state';
import {BankAccount} from '@/types/api/orders';
import {UserResponseV3} from '@/types/api/users';

type SummaryCardsChildProps = {
  childUser: UserResponseV3 | undefined;
  planner?: Planner;
  bank?: BankAccount;
};

export function SummaryCardsChild({childUser, planner, bank}: SummaryCardsChildProps) {
  const {t} = useTranslate();
  const {getByCode} = useRefdataLocalizable();
  const {data: titles} = useTitlesQuery();
  const {data: countries} = useCountriesQuery();
  const media = useMedia();

  const leftColumnCards = useMemo(() => {
    if (childUser) {
      return (
        <>
          <SummaryCard
            title={t('PERSONAL_DETAILS.NAME')}
            row={{
              [t('PERSONAL_DETAILS.SALUTATION')]: (
                <Label2 variant="medium">
                  {childUser.title && getByCode(titles, childUser.title)?.label}
                </Label2>
              ),
              [t('PERSONAL_DETAILS.NAME')]: (
                <Label2 variant="medium">{`${childUser.firstName} ${childUser.lastName}`}</Label2>
              ),
            }}
          />
          <SummaryCard
            title={t('PERSONAL_DETAILS.DATE_AND_PLACE_OF_BIRTH')}
            row={{
              [t('PERSONAL_DETAILS.DATE_OF_BIRTH')]: (
                <Label2 variant="medium">
                  {childUser.dateOfBirth && (
                    <LocalizedDate
                      color="$text"
                      date={childUser.dateOfBirth}
                      textComponent={Label2}
                      variant="medium"
                    />
                  )}
                </Label2>
              ),
              [t('PERSONAL_DETAILS.PLACE_OF_BIRTH')]: (
                <Label2 variant="medium">
                  {childUser.placeOfBirth},{' '}
                  {childUser.countryOfBirth &&
                    getByCode(countries, childUser.countryOfBirth)?.label}
                </Label2>
              ),
              ...(childUser.nationalities
                ? {
                    [t('PERSONAL_DETAILS.NATIONALITY')]: (
                      <YStack alignItems="flex-end" gap="$2">
                        {childUser.nationalities &&
                          Object.keys(childUser.nationalities).map((nationality, index) => (
                            <Label2 key={index} variant="medium">
                              {nationality && getByCode(countries, nationality)?.label}
                            </Label2>
                          ))}
                      </YStack>
                    ),
                  }
                : {}),
            }}
          />
        </>
      );
    } else return null;
  }, [childUser, countries, getByCode, t, titles]);

  const rightColumnCards = useMemo(() => {
    if (childUser) {
      return (
        <>
          <SummaryCard
            title={t('PERSONAL_DETAILS.ADDRESS')}
            row={{
              [t('PERSONAL_DETAILS.ADDRESS')]: (
                <View alignItems="flex-end">
                  <Label2 variant="medium">
                    {childUser.address?.street} {childUser.address?.streetNumber}
                  </Label2>
                  <Label2 variant="medium">
                    {childUser.address?.postCode} {childUser.address?.city}
                  </Label2>
                  <Label2 variant="medium">
                    {childUser.address?.countryCode &&
                      getByCode(countries, childUser.address?.countryCode)?.label}
                  </Label2>
                </View>
              ),
            }}
          />
          <SummaryCard
            title={t('PERSONAL_DETAILS.TAX_RESIDENCE')}
            row={{
              [t('PERSONAL_DETAILS.COUNTRY')]: (
                <Label2 variant="medium">
                  {childUser.taxIdentification?.[0]?.country &&
                    getByCode(countries, childUser.taxIdentification?.[0].country)?.label}
                </Label2>
              ),
              ...(childUser.taxIdentification?.[0]?.number
                ? {
                    [t('PERSONAL_DETAILS.TAX_IDENTIFICATION_NUMBER')]: (
                      <Label2 variant="medium">
                        {/* Todo: Need to add the German only tax id */}
                        {childUser.taxIdentification?.[0] &&
                          childUser.taxIdentification?.[0].number}
                      </Label2>
                    ),
                  }
                : {}),
            }}
          />
        </>
      );
    } else return null;
  }, [childUser, countries, getByCode, t]);

  const leftColumnPlannerBankCards = useMemo(() => {
    if (childUser && planner && bank) {
      return (
        <>
          <SummaryCard
            title={t('PERSONAL_DETAILS.INVESTMENT_DETAILS')}
            row={{
              [t('PERSONAL_DETAILS.STARTING_INVESTMENT')]: (
                <LocalizedNumbers
                  value={planner.initialDepositAmount}
                  variant="currency"
                  precision="decimals"
                  textComponent={Label2}
                  numberStyle={{variant: 'medium'}}
                />
              ),
              [t('PERSONAL_DETAILS.USER_SUMMARY.MONTHLY_SAVINGS')]: (
                <LocalizedNumbers
                  value={planner.initialRecurringDepositAmount}
                  variant="currency"
                  precision="decimals"
                  textComponent={Label2}
                  numberStyle={{variant: 'medium'}}
                />
              ),
              [t('ORDER_SUMMARY.INVESTMENT-STRATEGY')]: (
                <StrategyBadge strategy={planner.selectedStrategy} />
              ),
            }}
          />
        </>
      );
    } else return null;
  }, [bank, childUser, planner, t]);

  const rightColumnPlannerBankCards = useMemo(() => {
    if (childUser && planner && bank) {
      return (
        <>
          <SummaryCard
            title={t('PERSONAL_DETAILS.BANK_DETAILS')}
            row={{
              [t('ACCOUNT_DETAILS.ACCOUNT_HOLDER')]: (
                <Label2 variant="medium">{bank.holderFullName}</Label2>
              ),
              [t('ACCOUNT_DETAILS.IBAN')]: <Label2 variant="medium">{bank.iban}</Label2>,
              ...(bank.bic
                ? {
                    [t('ACCOUNT_DETAILS.BIC')]: <Label2 variant="medium">{bank.bic}</Label2>,
                  }
                : {}),
            }}
          />
        </>
      );
    } else return null;
  }, [bank, childUser, planner, t]);

  return (
    <YStack gap="$4">
      <Skeleton width="100%" height={140}>
        {media.sm ? (
          <XStack gap="$4" padding="$2" $sm={{flexWrap: 'wrap'}}>
            {leftColumnCards}
            {rightColumnCards}
          </XStack>
        ) : (
          <XStack gap="$4" padding="$2">
            <YStack gap="$4" flex={1}>
              {leftColumnCards}
            </YStack>

            <YStack gap="$4" flex={1}>
              {rightColumnCards}
            </YStack>
          </XStack>
        )}
      </Skeleton>
      {planner && bank && (
        <Skeleton width="100%" height={140}>
          <YStack gap="$6">
            <Heading4 variant="medium">
              {t('PERSONAL_DETAILS.SECOND_GUARDIAN.INVESTMENT_DATA.TITLE')}
            </Heading4>
            {media.sm ? (
              <XStack gap="$4" padding="$2" $sm={{flexWrap: 'wrap'}}>
                {leftColumnPlannerBankCards}
                {rightColumnPlannerBankCards}
              </XStack>
            ) : (
              <XStack gap="$4" padding="$2">
                <YStack gap="$4" flex={1}>
                  {leftColumnPlannerBankCards}
                </YStack>

                <YStack gap="$4" flex={1}>
                  {rightColumnPlannerBankCards}
                </YStack>
              </XStack>
            )}
          </YStack>
        </Skeleton>
      )}
    </YStack>
  );
}
