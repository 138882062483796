import {useCallback} from 'react';
import {Control, FieldValues, Path, useController} from 'react-hook-form';
import {View, XStack, YStack} from 'tamagui';

import {Card} from '@/components/cards/Card';
import SvgFile from '@/components/icons/SvgFile';
import {CheckBox} from '@/components/inputs/Checkbox';
import {InputErrorMessage} from '@/components/texts/InputErrorMessage';
import {Label2} from '@/components/texts/Label';
import {Skeleton} from '@/components/views/Skeleton';
import {Base, LocalizableRefData} from '@/hooks/useRefdataLocalizable';
import {INCOME_WEALTH_ICONS} from '@/modules/onboarding/constants/income-wealth-icons';

type Props<Data extends Base, Values extends FieldValues> = {
  items: LocalizableRefData<Data>[];
  name: Path<Values>;
  control: Control<Values>;
};

export const IncomeOrWealthCards = <Data extends Base, Values extends FieldValues>({
  items,
  name,
  control,
}: Props<Data, Values>) => {
  const {
    field,
    fieldState: {error},
  } = useController({name, control});

  const handleSelectIncomeSource = useCallback(
    (code: string) => {
      let curr = [...field.value];

      if (curr.includes(code)) {
        curr = curr.filter(sourceCode => sourceCode !== code);
      } else {
        curr.push(code);
      }

      field.onChange(curr);
    },
    [field]
  );

  return (
    <>
      <XStack gap="$4" justifyContent="space-between" flexWrap="wrap">
        {items.length === 0 && (
          <>
            <View width="47%">
              <Skeleton width="100%" height={125} />
            </View>
            <View width="47%">
              <Skeleton width="100%" height={125} />
            </View>
            <View width="47%">
              <Skeleton width="100%" height={125} />
            </View>
            <View width="47%">
              <Skeleton width="100%" height={125} />
            </View>
            <View width="47%">
              <Skeleton width="100%" height={125} />
            </View>
            <View width="47%">
              <Skeleton width="100%" height={125} />
            </View>
            <View width="47%">
              <Skeleton width="100%" height={125} />
            </View>
          </>
        )}
        {items.map(income => {
          const icon = INCOME_WEALTH_ICONS[income.code];
          const checked = field.value.includes(income.code);
          return (
            <Card
              key={income.code}
              onPress={() => handleSelectIncomeSource(income.code)}
              width="47%"
              borderColor={checked ? '$primary' : 'transparent'}
              borderWidth={1}
              outlineWidth={0}
              focusVisibleStyle={{
                opacity: 0.85,
                scale: 0.95,
              }}
            >
              <YStack
                gap="$4"
                $platform-native={{flex: 1}}
                height="100%"
                justifyContent="space-between"
              >
                <XStack flexDirection="row-reverse" justifyContent="space-between">
                  <YStack>
                    <CheckBox checked={checked} />
                  </YStack>
                  {icon && (
                    <View backgroundColor="$neutralBG" borderRadius="$full" padding="$2">
                      <SvgFile name={icon} color="$neutral900" size={20} />
                    </View>
                  )}
                </XStack>
                <Label2 variant="medium">{income.label}</Label2>
              </YStack>
            </Card>
          );
        })}
      </XStack>
      <InputErrorMessage error={error?.message} />
    </>
  );
};
