import {Heading4, Heading5} from '@/components/texts/Heading';
import {useInterval} from '@/hooks/useInterval';
import {Canvas, Group, Path, Skia, usePathInterpolation} from '@shopify/react-native-skia';
import {useTranslate} from '@tolgee/react';
import React, {useEffect, useState} from 'react';
import {useDerivedValue, useSharedValue, withTiming} from 'react-native-reanimated';
import {AnimatePresence, Image, View, YStack, useThemeName} from 'tamagui';

const CIRCLE_OUTER_1 = Skia.Path.MakeFromSVGString(
  'M247 121.676C247 188.538 199.243 249 122.799 249C56.1135 249 0 198.292 0 131.431C0 64.5696 56.1135 0 122.799 0C189.485 0 241.865 37.8082 247 121.676Z'
)!;

const CIRCLE_OUTER_2 = Skia.Path.MakeFromSVGString(
  'M245 128.5C245 195.361 198.243 245 121.799 245C55.1135 245 0 214.361 0 147.5C0 80.6387 55.1135 0 121.799 0C188.485 0 239.865 44.6319 245 128.5Z'
)!;
const CIRCLE_OUTER_3 = Skia.Path.MakeFromSVGString(
  'M237 122.5C237 189.361 212.5 235.5 113.799 245C47.2172 251.409 0 224.861 0 158C0 91.1387 47.3143 0 114 0C180.686 0 231.865 38.6319 237 122.5Z'
)!;

const CIRCLE_INNER_1 = Skia.Path.MakeFromSVGString(
  'M226 121C226 181.199 150.199 225.5 90 225.5C29.801 225.5 0 165.199 0 105C0 44.801 53.5 0.5 119 0.5C179.199 0.5 226 60.801 226 121Z'
)!;

const CIRCLE_INNER_2 = Skia.Path.MakeFromSVGString(
  'M226 123C226 183.199 194.5 246 90 227.5C31.5 211 0 167.199 0 107C11.5 4.5 75.5 0.5 141 0.5C201.199 0.5 226 62.801 226 123Z'
)!;
const CIRCLE_INNER_3 = Skia.Path.MakeFromSVGString(
  'M228.5 114C228.5 174.199 175 239.5 70.5 221C12 204.5 0 161.199 0 101C11.5 -1.50011 60 0.499892 125.5 0.499892C185.699 0.499892 228.5 53.8009 228.5 114Z'
)!;

const MORPH_DURATION = 1000;
const ANIMATION_DELAY = 2000;

export function PlannerLoading() {
  const {t} = useTranslate();
  const progress = useSharedValue(0);
  const innerY = useSharedValue(10.5);
  const outerX = useSharedValue(0);
  const [counter, setCounter] = useState(0);
  const [isForward, setIsForward] = useState(true);
  const themeName = useThemeName();

  useInterval(
    () => {
      setCounter(_ => {
        const nxt = _ + 1;
        if (nxt > 2) {
          setIsForward(false);
          return 0;
        }
        setIsForward(true);
        return nxt;
      });
    },
    {delay: ANIMATION_DELAY}
  );

  useEffect(() => {
    if (counter === 0) {
      progress.value = withTiming(0, {duration: MORPH_DURATION});
      innerY.value = withTiming(10.5, {duration: MORPH_DURATION});
      outerX.value = withTiming(0, {duration: MORPH_DURATION});
    }
    if (counter === 1) {
      progress.value = withTiming(0.5, {duration: MORPH_DURATION});
      innerY.value = withTiming(8.5, {duration: MORPH_DURATION});
    }
    if (counter === 2) {
      progress.value = withTiming(1, {duration: MORPH_DURATION});
      innerY.value = withTiming(14.5, {duration: MORPH_DURATION});
      outerX.value = withTiming(9, {duration: MORPH_DURATION});
    }
  }, [counter]);

  const path = usePathInterpolation(
    progress,
    [0, 0.5, 1],
    [CIRCLE_OUTER_1, isForward ? CIRCLE_OUTER_2 : CIRCLE_OUTER_1, CIRCLE_OUTER_3]
  );
  const pathInner = usePathInterpolation(
    progress,
    [0, 0.5, 1],
    [CIRCLE_INNER_1, isForward ? CIRCLE_INNER_2 : CIRCLE_INNER_1, CIRCLE_INNER_3]
  );

  const outerTransform = useDerivedValue(() => {
    return [{translateX: outerX.value}];
  }, []);

  const innerTransform = useDerivedValue(() => {
    return [{translateX: 9}, {translateY: innerY.value}];
  }, []);

  return (
    <YStack
      minWidth={330}
      maxWidth={500}
      justifyContent="center"
      alignItems="center"
      gap="$4"
      paddingHorizontal="$4"
    >
      <Heading4 variant="medium" textAlign="center">
        {t('PLANNER.LOADING.TITLE')}
      </Heading4>
      <View height={30} width={330} justifyContent="center" alignItems="center" overflow="hidden">
        <AnimatePresence>
          {counter === 0 && (
            <View
              position="absolute"
              key="first-item"
              animation="verySlow"
              enterStyle={{y: 30}}
              exitStyle={{y: -30}}
            >
              <Heading5 color="$primary">{t('PLANNER.LOADING.TEXT_1')}</Heading5>
            </View>
          )}
          {counter === 1 && (
            <View
              position="absolute"
              key="second-item"
              animation="verySlow"
              enterStyle={{y: 30}}
              exitStyle={{y: -30}}
            >
              <Heading5 color="$primary">{t('PLANNER.LOADING.TEXT_2')}</Heading5>
            </View>
          )}
          {counter === 2 && (
            <View
              position="absolute"
              key="third-item"
              animation="verySlow"
              enterStyle={{y: 30}}
              exitStyle={{y: -30}}
            >
              <Heading5 color="$primary">{t('PLANNER.LOADING.TEXT_3')}</Heading5>
            </View>
          )}
        </AnimatePresence>
      </View>
      <View>
        <Canvas style={{width: 247, height: 249}}>
          <Group transform={outerTransform}>
            <Path path={path} style="fill" color={themeName === 'light' ? '#F3F7FF' : '#1F2739'} />
          </Group>
          <Group transform={innerTransform}>
            <Path
              path={pathInner}
              style="fill"
              color={themeName === 'light' ? '#E1EAFA' : '#475A86'}
            />
          </Group>
        </Canvas>
        <View
          position="absolute"
          top={17}
          left={18}
          backgroundColor={themeName === 'light' ? '$white' : '$neutralBG'}
          borderRadius={105}
          height={210}
          width={210}
          overflow="hidden"
        >
          <AnimatePresence>
            {counter === 0 && (
              <View
                position="absolute"
                key="first-image"
                animation="verySlow"
                enterStyle={{x: 210}}
                exitStyle={{x: -210}}
              >
                <Image
                  source={
                    themeName === 'light'
                      ? require('@/assets/images/planner-loading/1.png')
                      : require('@/assets/images/planner-loading/1-dark.png')
                  }
                  style={{width: 210, height: 210}}
                />
              </View>
            )}
            {counter === 1 && (
              <View
                position="absolute"
                key="second-image"
                animation="verySlow"
                enterStyle={{x: 210}}
                exitStyle={{x: -210}}
              >
                <Image
                  source={
                    themeName === 'light'
                      ? require('@/assets/images/planner-loading/2.png')
                      : require('@/assets/images/planner-loading/2-dark.png')
                  }
                  style={{width: 210, height: 210}}
                />
              </View>
            )}
            {counter === 2 && (
              <View
                position="absolute"
                key="third-image"
                animation="verySlow"
                enterStyle={{x: 210}}
                exitStyle={{x: -210}}
              >
                <Image
                  source={
                    themeName === 'light'
                      ? require('@/assets/images/planner-loading/3.png')
                      : require('@/assets/images/planner-loading/3-dark.png')
                  }
                  style={{width: 210, height: 210}}
                />
              </View>
            )}
          </AnimatePresence>
        </View>
      </View>
    </YStack>
  );
}
