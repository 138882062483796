/* eslint-disable no-restricted-imports */

import {GetProps, Text, styled} from 'tamagui';

const Heading = styled(Text, {
  tag: 'span',
  name: 'Heading',
  accessibilityRole: 'header',
  fontFamily: '$heading',
  color: '$text',
  fontWeight: 'normal',
  whiteSpace: 'normal',
  style: {
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  variants: {
    variant: {
      medium: {
        fontFamily: '$headingMedium',
      },
      bold: {
        fontFamily: '$headingBold',
      },
    },
  } as const,
});

export type HeadingProps = GetProps<typeof Heading>;

export const Heading1 = styled(Heading, {
  name: 'H1',
  tag: 'h1',
  fontSize: 40,
  lineHeight: 48,
  $sm: {
    fontSize: 36,
    lineHeight: 44,
  },
});

export const Heading2 = styled(Heading, {
  name: 'H2',
  tag: 'h2',
  fontSize: 36,
  lineHeight: 44,
  $sm: {
    fontSize: 32,
    lineHeight: 40,
  },
});

export const Heading3 = styled(Heading, {
  name: 'H3',
  tag: 'h3',
  fontSize: 32,
  lineHeight: 40,
  $sm: {
    fontSize: 28,
    lineHeight: 36,
  },
});

export const Heading4 = styled(Heading, {
  name: 'H4',
  tag: 'h4',
  fontSize: 26,
  lineHeight: 34,
  $sm: {
    fontSize: 22,
    lineHeight: 30,
  },
});

export const Heading5 = styled(Heading, {
  name: 'H5',
  tag: 'h5',
  fontSize: 20,
  lineHeight: 32,
  $sm: {
    fontSize: 18,
    lineHeight: 28,
  },
});

export const Heading6 = styled(Heading, {
  name: 'H6',
  tag: 'h6',
  fontSize: 18,
  lineHeight: 26,
  $sm: {
    fontSize: 16,
    lineHeight: 24,
  },
});
