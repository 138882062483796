// run following script to update this file when adding new svg files to the icons folder
// node bin/generate-svg-icons.js

import It from '@/assets/svgs/IT.svg';
import AccountDetails from '@/assets/svgs/account-details.svg';
import AccountOpened from '@/assets/svgs/account-opened.svg';
import AnlageChart from '@/assets/svgs/anlage-chart.svg';
import ArrowDown from '@/assets/svgs/arrow-down.svg';
import ArrowLeft from '@/assets/svgs/arrow-left.svg';
import ArrowRight from '@/assets/svgs/arrow-right.svg';
import ArrowUp from '@/assets/svgs/arrow-up.svg';
import AskMore from '@/assets/svgs/ask-more.svg';
import AttachmentDocument from '@/assets/svgs/attachment-document.svg';
import AttachmentPhoto from '@/assets/svgs/attachment-photo.svg';
import Bank from '@/assets/svgs/bank.svg';
import Blockchain from '@/assets/svgs/blockchain.svg';
import BlueEconomy from '@/assets/svgs/blue-economy.svg';
import Bonus from '@/assets/svgs/bonus.svg';
import Bookmark from '@/assets/svgs/bookmark.svg';
import Building from '@/assets/svgs/building.svg';
import Calendar from '@/assets/svgs/calendar.svg';
import Call from '@/assets/svgs/call.svg';
import Camera from '@/assets/svgs/camera.svg';
import ChartFill from '@/assets/svgs/chart-fill.svg';
import Chart from '@/assets/svgs/chart.svg';
import CheckMarkRound from '@/assets/svgs/check-mark-round.svg';
import Check from '@/assets/svgs/check.svg';
import CheckboxChecked from '@/assets/svgs/checkbox-checked.svg';
import CheckboxCirclePrimary from '@/assets/svgs/checkbox-circle-primary.svg';
import CheckboxCircleUnchecked from '@/assets/svgs/checkbox-circle-unchecked.svg';
import CheckboxCircle from '@/assets/svgs/checkbox-circle.svg';
import CheckboxGreen from '@/assets/svgs/checkbox-green.svg';
import CheckboxPrimaryUnchecked from '@/assets/svgs/checkbox-primary-unchecked.svg';
import CheckboxPrimary from '@/assets/svgs/checkbox-primary.svg';
import CheckboxUnchecked from '@/assets/svgs/checkbox-unchecked.svg';
import ChevronDown from '@/assets/svgs/chevron-down.svg';
import ChevronLeft from '@/assets/svgs/chevron-left.svg';
import ChevronRightLarge from '@/assets/svgs/chevron-right-large.svg';
import ChevronRight from '@/assets/svgs/chevron-right.svg';
import ChevronUp from '@/assets/svgs/chevron-up.svg';
import Circle from '@/assets/svgs/circle.svg';
import CleanEnergy from '@/assets/svgs/clean-energy.svg';
import Clock from '@/assets/svgs/clock.svg';
import Close from '@/assets/svgs/close.svg';
import Commodities from '@/assets/svgs/commodities.svg';
import ConversionExchangeCheckmark from '@/assets/svgs/conversion-exchange-checkmark.svg';
import Copy from '@/assets/svgs/copy.svg';
import CreditCard from '@/assets/svgs/credit-card.svg';
import Credit from '@/assets/svgs/credit.svg';
import CurrencyEuro from '@/assets/svgs/currency-euro.svg';
import DepositBounced from '@/assets/svgs/deposit-bounced.svg';
import DepositDirect from '@/assets/svgs/deposit-direct.svg';
import DepositTransfer from '@/assets/svgs/deposit-transfer.svg';
import DepositWire from '@/assets/svgs/deposit-wire.svg';
import Deposit from '@/assets/svgs/deposit.svg';
import DividendStocks from '@/assets/svgs/dividend-stocks.svg';
import Dividends from '@/assets/svgs/dividends.svg';
import DocumentRipped from '@/assets/svgs/document-ripped.svg';
import Document from '@/assets/svgs/document.svg';
import DocumentsFilled from '@/assets/svgs/documents-filled.svg';
import Documents from '@/assets/svgs/documents.svg';
import Download from '@/assets/svgs/download.svg';
import EcoLeaf from '@/assets/svgs/eco-leaf.svg';
import Edit from '@/assets/svgs/edit.svg';
import ElectricMobility from '@/assets/svgs/electric-mobility.svg';
import Email from '@/assets/svgs/email.svg';
import EmojiHappy from '@/assets/svgs/emoji-happy.svg';
import EndCall from '@/assets/svgs/end-call.svg';
import EnergyIndustry from '@/assets/svgs/energy-industry.svg';
import EuroCurrency from '@/assets/svgs/euro-currency.svg';
import EuroFill from '@/assets/svgs/euro-fill.svg';
import Euro from '@/assets/svgs/euro.svg';
import ExternalLink from '@/assets/svgs/external-link.svg';
import EyeCrossedOut from '@/assets/svgs/eye-crossed-out.svg';
import Eye from '@/assets/svgs/eye.svg';
import FaceId from '@/assets/svgs/face-id.svg';
import Fee from '@/assets/svgs/fee.svg';
import Fees from '@/assets/svgs/fees.svg';
import FileBlankList from '@/assets/svgs/file-blank-list.svg';
import FilterEnabled from '@/assets/svgs/filter-enabled.svg';
import Filter from '@/assets/svgs/filter.svg';
import FinLeap from '@/assets/svgs/fin-leap.svg';
import FinancialIndustry from '@/assets/svgs/financial-industry.svg';
import FlagDe from '@/assets/svgs/flag-de.svg';
import FlagEu from '@/assets/svgs/flag-eu.svg';
import FlagEuropean from '@/assets/svgs/flag-european.svg';
import FlagGb from '@/assets/svgs/flag-gb.svg';
import FlagUs from '@/assets/svgs/flag-us.svg';
import FlashDisabled from '@/assets/svgs/flash-disabled.svg';
import Flash from '@/assets/svgs/flash.svg';
import Flexibility from '@/assets/svgs/flexibility.svg';
import Friends from '@/assets/svgs/friends.svg';
import GinmonBenchmarkHorizontal from '@/assets/svgs/ginmon-benchmark-horizontal.svg';
import GinmonBenchmarkVertical from '@/assets/svgs/ginmon-benchmark-vertical.svg';
import GinmonIconOnly from '@/assets/svgs/ginmon-icon-only.svg';
import GinmonIcon from '@/assets/svgs/ginmon-icon.svg';
import GinmonInvest from '@/assets/svgs/ginmon-invest.svg';
import GinmonJunior from '@/assets/svgs/ginmon-junior.svg';
import GinmonMotive from '@/assets/svgs/ginmon-motive.svg';
import GinmonTopzins from '@/assets/svgs/ginmon-topzins.svg';
import GinmonVl from '@/assets/svgs/ginmon-vl.svg';
import Ginmon from '@/assets/svgs/ginmon.svg';
import GiveFeedback from '@/assets/svgs/give-feedback.svg';
import Goal from '@/assets/svgs/goal.svg';
import GreenLeafCircle from '@/assets/svgs/green-leaf-circle.svg';
import HandWithCoins from '@/assets/svgs/hand-with-coins.svg';
import Healthcare from '@/assets/svgs/healthcare.svg';
import HomeGradient from '@/assets/svgs/home-gradient.svg';
import HorizontalDots from '@/assets/svgs/horizontal-dots.svg';
import Houses from '@/assets/svgs/houses.svg';
import IdCard from '@/assets/svgs/id-card.svg';
import Identity from '@/assets/svgs/identity.svg';
import Info from '@/assets/svgs/info.svg';
import InsightsChild from '@/assets/svgs/insights-child.svg';
import InsightsReseach from '@/assets/svgs/insights-reseach.svg';
import Intercom from '@/assets/svgs/intercom.svg';
import InternalTransfer from '@/assets/svgs/internal-transfer.svg';
import InvestArrow from '@/assets/svgs/invest-arrow.svg';
import InviteFriendsIllustration from '@/assets/svgs/invite-friends-illustration.svg';
import Laptop from '@/assets/svgs/laptop.svg';
import LegalNotice from '@/assets/svgs/legal-notice.svg';
import LightbulbShineCircle from '@/assets/svgs/lightbulb-shine-circle.svg';
import Lightning from '@/assets/svgs/lightning.svg';
import Link from '@/assets/svgs/link.svg';
import LocationArrow from '@/assets/svgs/location-arrow.svg';
import LocationPoint from '@/assets/svgs/location-point.svg';
import LockLocked from '@/assets/svgs/lock-locked.svg';
import LockUnlocked from '@/assets/svgs/lock-unlocked.svg';
import Logout from '@/assets/svgs/logout.svg';
import MapsPin from '@/assets/svgs/maps-pin.svg';
import Menu from '@/assets/svgs/menu.svg';
import Minus from '@/assets/svgs/minus.svg';
import MobileMessage from '@/assets/svgs/mobile-message.svg';
import MoneyCoins from '@/assets/svgs/money-coins.svg';
import MoneyMarket from '@/assets/svgs/money-market.svg';
import Money from '@/assets/svgs/money.svg';
import NavigationDotSelected from '@/assets/svgs/navigation-dot-selected.svg';
import NavigationDotUnselected from '@/assets/svgs/navigation-dot-unselected.svg';
import Note from '@/assets/svgs/note.svg';
import Notification from '@/assets/svgs/notification.svg';
import NotificationsRead from '@/assets/svgs/notifications-read.svg';
import NotificationsUnread from '@/assets/svgs/notifications-unread.svg';
import OptimalInvestmentGraphDe from '@/assets/svgs/optimal-investment-graph-de.svg';
import OptimalInvestmentGraphEn from '@/assets/svgs/optimal-investment-graph-en.svg';
import OrdersFilled from '@/assets/svgs/orders-filled.svg';
import Orders from '@/assets/svgs/orders.svg';
import OverviewFilled from '@/assets/svgs/overview-filled.svg';
import Overview from '@/assets/svgs/overview.svg';
import Pen from '@/assets/svgs/pen.svg';
import People from '@/assets/svgs/people.svg';
import Performance from '@/assets/svgs/performance.svg';
import PlaceholderLinechart from '@/assets/svgs/placeholder-linechart.svg';
import PlusAcc from '@/assets/svgs/plus-acc.svg';
import PlusFilled from '@/assets/svgs/plus-filled.svg';
import PlusRounded from '@/assets/svgs/plus-rounded.svg';
import Plus from '@/assets/svgs/plus.svg';
import Portfolio from '@/assets/svgs/portfolio.svg';
import Prize from '@/assets/svgs/prize.svg';
import Profile from '@/assets/svgs/profile.svg';
import ReasonableReturn from '@/assets/svgs/reasonable-return.svg';
import Rebalancing from '@/assets/svgs/rebalancing.svg';
import Recommendations from '@/assets/svgs/recommendations.svg';
import RecommendedLabelDe from '@/assets/svgs/recommended-label-de.svg';
import RecommendedLabelEn from '@/assets/svgs/recommended-label-en.svg';
import RiskValue from '@/assets/svgs/risk-value.svg';
import Rocket from '@/assets/svgs/rocket.svg';
import Rotate from '@/assets/svgs/rotate.svg';
import SavingsRetirement from '@/assets/svgs/savings-retirement.svg';
import SavingsWealthbuilding from '@/assets/svgs/savings-wealthbuilding.svg';
import Search from '@/assets/svgs/search.svg';
import Send from '@/assets/svgs/send.svg';
import ServiceFee from '@/assets/svgs/service-fee.svg';
import Settings from '@/assets/svgs/settings.svg';
import Share from '@/assets/svgs/share.svg';
import SliderThumb from '@/assets/svgs/slider-thumb.svg';
import Sms from '@/assets/svgs/sms.svg';
import Sparkle from '@/assets/svgs/sparkle.svg';
import Stall from '@/assets/svgs/stall.svg';
import StarFilled from '@/assets/svgs/star-filled.svg';
import StarInSquare from '@/assets/svgs/star-in-square.svg';
import Star from '@/assets/svgs/star.svg';
import StartChat from '@/assets/svgs/start-chat.svg';
import Stocks from '@/assets/svgs/stocks.svg';
import Suitcase from '@/assets/svgs/suitcase.svg';
import SvgCircle from '@/assets/svgs/svg-circle.svg';
import TaxDetails from '@/assets/svgs/tax-details.svg';
import Tax from '@/assets/svgs/tax.svg';
import TopZinsUsOverview from '@/assets/svgs/top-zins-us-overview.svg';
import TopzinsEuVector from '@/assets/svgs/topzins-eu-vector.svg';
import TopzinsEu from '@/assets/svgs/topzins-eu.svg';
import TopzinsPayment from '@/assets/svgs/topzins-payment.svg';
import TopzinsProfit from '@/assets/svgs/topzins-profit.svg';
import TopzinsUsVector from '@/assets/svgs/topzins-us-vector.svg';
import TouchId from '@/assets/svgs/touch-id.svg';
import TransactionFee from '@/assets/svgs/transaction-fee.svg';
import TransactionsFilled from '@/assets/svgs/transactions-filled.svg';
import Transactions from '@/assets/svgs/transactions.svg';
import Trash from '@/assets/svgs/trash.svg';
import Tree from '@/assets/svgs/tree.svg';
import TrendingDown from '@/assets/svgs/trending-down.svg';
import TrendingUp from '@/assets/svgs/trending-up.svg';
import TypeClose from '@/assets/svgs/type-close.svg';
import TypeComplete from '@/assets/svgs/type-complete.svg';
import TypeInprogress from '@/assets/svgs/type-inprogress.svg';
import TypeRejected from '@/assets/svgs/type-rejected.svg';
import UnknownTransactions from '@/assets/svgs/unknown-transactions.svg';
import Upload from '@/assets/svgs/upload.svg';
import User from '@/assets/svgs/user.svg';
import ValidationSuccess from '@/assets/svgs/validation-success.svg';
import VectorDown from '@/assets/svgs/vector-down.svg';
import VectorUp from '@/assets/svgs/vector-up.svg';
import VerificationMobile from '@/assets/svgs/verification-mobile.svg';
import VerificationPostOffice from '@/assets/svgs/verification-post-office.svg';
import VideoCall from '@/assets/svgs/video-call.svg';
import Video from '@/assets/svgs/video.svg';
import WalletAdd from '@/assets/svgs/wallet-add.svg';
import WalletCoins from '@/assets/svgs/wallet-coins.svg';
import Wallet from '@/assets/svgs/wallet.svg';
import WarningYellow from '@/assets/svgs/warning-yellow.svg';
import Warning from '@/assets/svgs/warning.svg';
import Water from '@/assets/svgs/water.svg';
import Wifi from '@/assets/svgs/wifi.svg';
import WithdrawalBounced from '@/assets/svgs/withdrawal-bounced.svg';
import Withdrawal from '@/assets/svgs/withdrawal.svg';

const iconMap = {
  IT: It,
  'account-details': AccountDetails,
  'account-opened': AccountOpened,
  'anlage-chart': AnlageChart,
  'arrow-down': ArrowDown,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'arrow-up': ArrowUp,
  'ask-more': AskMore,
  'attachment-document': AttachmentDocument,
  'attachment-photo': AttachmentPhoto,
  bank: Bank,
  blockchain: Blockchain,
  'blue-economy': BlueEconomy,
  bonus: Bonus,
  bookmark: Bookmark,
  building: Building,
  calendar: Calendar,
  call: Call,
  camera: Camera,
  'chart-fill': ChartFill,
  chart: Chart,
  'check-mark-round': CheckMarkRound,
  check: Check,
  'checkbox-checked': CheckboxChecked,
  'checkbox-circle-primary': CheckboxCirclePrimary,
  'checkbox-circle-unchecked': CheckboxCircleUnchecked,
  'checkbox-circle': CheckboxCircle,
  'checkbox-green': CheckboxGreen,
  'checkbox-primary-unchecked': CheckboxPrimaryUnchecked,
  'checkbox-primary': CheckboxPrimary,
  'checkbox-unchecked': CheckboxUnchecked,
  'chevron-down': ChevronDown,
  'chevron-left': ChevronLeft,
  'chevron-right-large': ChevronRightLarge,
  'chevron-right': ChevronRight,
  'chevron-up': ChevronUp,
  circle: Circle,
  'clean-energy': CleanEnergy,
  clock: Clock,
  close: Close,
  commodities: Commodities,
  'conversion-exchange-checkmark': ConversionExchangeCheckmark,
  copy: Copy,
  'credit-card': CreditCard,
  credit: Credit,
  'currency-euro': CurrencyEuro,
  'deposit-bounced': DepositBounced,
  'deposit-direct': DepositDirect,
  'deposit-transfer': DepositTransfer,
  'deposit-wire': DepositWire,
  deposit: Deposit,
  'dividend-stocks': DividendStocks,
  dividends: Dividends,
  'document-ripped': DocumentRipped,
  document: Document,
  'documents-filled': DocumentsFilled,
  documents: Documents,
  download: Download,
  'eco-leaf': EcoLeaf,
  edit: Edit,
  'electric-mobility': ElectricMobility,
  email: Email,
  'emoji-happy': EmojiHappy,
  'end-call': EndCall,
  'energy-industry': EnergyIndustry,
  'euro-currency': EuroCurrency,
  'euro-fill': EuroFill,
  euro: Euro,
  'external-link': ExternalLink,
  'eye-crossed-out': EyeCrossedOut,
  eye: Eye,
  'face-id': FaceId,
  fee: Fee,
  fees: Fees,
  'file-blank-list': FileBlankList,
  'filter-enabled': FilterEnabled,
  filter: Filter,
  'fin-leap': FinLeap,
  'financial-industry': FinancialIndustry,
  'flag-de': FlagDe,
  'flag-eu': FlagEu,
  'flag-european': FlagEuropean,
  'flag-gb': FlagGb,
  'flag-us': FlagUs,
  'flash-disabled': FlashDisabled,
  flash: Flash,
  flexibility: Flexibility,
  friends: Friends,
  'ginmon-benchmark-horizontal': GinmonBenchmarkHorizontal,
  'ginmon-benchmark-vertical': GinmonBenchmarkVertical,
  'ginmon-icon-only': GinmonIconOnly,
  'ginmon-icon': GinmonIcon,
  'ginmon-invest': GinmonInvest,
  'ginmon-junior': GinmonJunior,
  'ginmon-motive': GinmonMotive,
  'ginmon-topzins': GinmonTopzins,
  'ginmon-vl': GinmonVl,
  ginmon: Ginmon,
  'give-feedback': GiveFeedback,
  goal: Goal,
  'green-leaf-circle': GreenLeafCircle,
  'hand-with-coins': HandWithCoins,
  healthcare: Healthcare,
  'home-gradient': HomeGradient,
  'horizontal-dots': HorizontalDots,
  houses: Houses,
  'id-card': IdCard,
  identity: Identity,
  info: Info,
  'insights-child': InsightsChild,
  'insights-reseach': InsightsReseach,
  intercom: Intercom,
  'internal-transfer': InternalTransfer,
  'invest-arrow': InvestArrow,
  'invite-friends-illustration': InviteFriendsIllustration,
  laptop: Laptop,
  'legal-notice': LegalNotice,
  'lightbulb-shine-circle': LightbulbShineCircle,
  lightning: Lightning,
  link: Link,
  'location-arrow': LocationArrow,
  'location-point': LocationPoint,
  'lock-locked': LockLocked,
  'lock-unlocked': LockUnlocked,
  logout: Logout,
  'maps-pin': MapsPin,
  menu: Menu,
  minus: Minus,
  'mobile-message': MobileMessage,
  'money-coins': MoneyCoins,
  'money-market': MoneyMarket,
  money: Money,
  'navigation-dot-selected': NavigationDotSelected,
  'navigation-dot-unselected': NavigationDotUnselected,
  note: Note,
  notification: Notification,
  'notifications-read': NotificationsRead,
  'notifications-unread': NotificationsUnread,
  'optimal-investment-graph-de': OptimalInvestmentGraphDe,
  'optimal-investment-graph-en': OptimalInvestmentGraphEn,
  'orders-filled': OrdersFilled,
  orders: Orders,
  'overview-filled': OverviewFilled,
  overview: Overview,
  pen: Pen,
  people: People,
  performance: Performance,
  'placeholder-linechart': PlaceholderLinechart,
  'plus-acc': PlusAcc,
  'plus-filled': PlusFilled,
  'plus-rounded': PlusRounded,
  plus: Plus,
  portfolio: Portfolio,
  prize: Prize,
  profile: Profile,
  'reasonable-return': ReasonableReturn,
  rebalancing: Rebalancing,
  recommendations: Recommendations,
  'recommended-label-de': RecommendedLabelDe,
  'recommended-label-en': RecommendedLabelEn,
  'risk-value': RiskValue,
  rocket: Rocket,
  rotate: Rotate,
  'savings-retirement': SavingsRetirement,
  'savings-wealthbuilding': SavingsWealthbuilding,
  search: Search,
  send: Send,
  'service-fee': ServiceFee,
  settings: Settings,
  share: Share,
  'slider-thumb': SliderThumb,
  sms: Sms,
  sparkle: Sparkle,
  stall: Stall,
  'star-filled': StarFilled,
  'star-in-square': StarInSquare,
  star: Star,
  'start-chat': StartChat,
  stocks: Stocks,
  suitcase: Suitcase,
  'svg-circle': SvgCircle,
  'tax-details': TaxDetails,
  tax: Tax,
  'top-zins-us-overview': TopZinsUsOverview,
  'topzins-eu-vector': TopzinsEuVector,
  'topzins-eu': TopzinsEu,
  'topzins-payment': TopzinsPayment,
  'topzins-profit': TopzinsProfit,
  'topzins-us-vector': TopzinsUsVector,
  'touch-id': TouchId,
  'transaction-fee': TransactionFee,
  'transactions-filled': TransactionsFilled,
  transactions: Transactions,
  trash: Trash,
  tree: Tree,
  'trending-down': TrendingDown,
  'trending-up': TrendingUp,
  'type-close': TypeClose,
  'type-complete': TypeComplete,
  'type-inprogress': TypeInprogress,
  'type-rejected': TypeRejected,
  'unknown-transactions': UnknownTransactions,
  upload: Upload,
  user: User,
  'validation-success': ValidationSuccess,
  'vector-down': VectorDown,
  'vector-up': VectorUp,
  'verification-mobile': VerificationMobile,
  'verification-post-office': VerificationPostOffice,
  'video-call': VideoCall,
  video: Video,
  'wallet-add': WalletAdd,
  'wallet-coins': WalletCoins,
  wallet: Wallet,
  'warning-yellow': WarningYellow,
  warning: Warning,
  water: Water,
  wifi: Wifi,
  'withdrawal-bounced': WithdrawalBounced,
  withdrawal: Withdrawal,
};

export default iconMap;
