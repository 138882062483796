import {useEffect, useMemo, useState} from 'react';

import {DEFAULT_COUNTRY} from '@/constants/countries';
import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {useSession} from '@/providers/session-provider/SessionProvider';
import {useAppSelector} from '@/store';
import {useCountriesQuery} from '@/store/queries/referenceApi';
import {SelectInputItem} from '@/types/FormInputProps';

export type CountryLimitation = 'upvestLimitation' | 'taxResidence' | undefined;

export const useGetCountryOptions = (limitation?: CountryLimitation) => {
  const [countryOptions, setCountryOptions] = useState<SelectInputItem[]>([]);
  const language = useAppSelector(state => state.app.language);
  const {isUpvestFeatureFlagEnabled} = useSession();

  const {data: countries} = useCountriesQuery();

  const {getAll} = useRefdataLocalizable();
  const translatedCountries = useMemo(() => getAll(countries), [getAll, countries]);

  const onlyGerman = isUpvestFeatureFlagEnabled;

  useEffect(() => {
    if (!translatedCountries) {
      setCountryOptions([]);

      return;
    }

    if (limitation === 'upvestLimitation' && onlyGerman) {
      setCountryOptions([{key: DEFAULT_COUNTRY.code, name: DEFAULT_COUNTRY.label[language]}]);

      return;
    }

    const options = translatedCountries
      .filter(country => country.taxResidenceUsageAllowed || limitation === undefined)
      .map<SelectInputItem>(country => ({key: country.code, name: country.label}));

    setCountryOptions(options);
  }, [limitation, countries, language, onlyGerman, translatedCountries]);

  return countryOptions;
};
