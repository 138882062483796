import {useTranslate} from '@tolgee/react';
import {Stack} from 'expo-router';
import React, {useCallback, useState} from 'react';
import {Platform} from 'react-native';

import {Heading4} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {ResponsiveScrollViewContent} from '@/components/views/ResponsiveScrollView';
import {
  DocumentFilterOnSelect,
  DocumentsFilter,
} from '@/modules/account-activity/components/DocumentsFilter';
import DocumentsList from '@/modules/account-activity/components/DocumentsList';
import {WebFooter} from '@/modules/navigation/components/footer/WebFooter';
import {Header} from '@/modules/navigation/components/header/Header';
import {Image} from 'expo-image';

import {FullScreenCenterView} from '@/components/views/FullScreenCenterView';
import {View, XStack, YStack} from 'tamagui';

export default function TabDocuments() {
  const {t} = useTranslate();
  const [documentFilter, setDocumentFilter] = useState<DocumentFilterOnSelect>();

  const onSelect = useCallback((filter: DocumentFilterOnSelect) => {
    setDocumentFilter(filter);
  }, []);

  return (
    <ResponsiveScrollViewContent>
      <Stack.Screen
        options={{
          headerShown: false,
        }}
      />
      <XStack justifyContent="space-between">
        <Header
          style={{flexShrink: 1}}
          title={t('NAVIGATION.TAX.DOCUMENTS')}
          backButton={Platform.OS !== 'web'}
        />
        <DocumentsFilter
          onSelect={onSelect}
          containerProps={{
            marginBottom: 15,
          }}
        />
      </XStack>
      {documentFilter ? (
        <DocumentsList
          customerId={documentFilter.customerId}
          source={documentFilter.source}
          category={documentFilter.category}
        />
      ) : (
        <FullScreenCenterView>
          <YStack gap="$4" maxWidth={400} alignItems="center">
            <View marginBottom="$4">
              <Image
                source={require('@/assets/images/empty-documents.png')}
                style={{width: 142, height: 120}}
              />
            </View>
            <Heading4 textAlign="center" variant="medium" flexShrink={1}>
              {t('NAVIGATION.TAX.DOCUMENTS.EMPTY')}
            </Heading4>
            <Paragraph2 flexShrink={1} color="$neutral400" textAlign="center">
              {t('NAVIGATION.TAX.DOCUMENTS.EMPTY_DESC')}
            </Paragraph2>
          </YStack>
        </FullScreenCenterView>
      )}
      <WebFooter withCalendly />
    </ResponsiveScrollViewContent>
  );
}
