import {PropsWithChildren, useCallback, useEffect, useState} from 'react';
import {LayoutChangeEvent} from 'react-native';
import {GetProps, View, useMedia} from 'tamagui';

type OverlappedStackProps = PropsWithChildren<
  {
    setOverlapPadding: (padding: number) => void;
  } & GetProps<typeof View>
>;
export function OverlappedStack({children, setOverlapPadding, ...rest}: OverlappedStackProps) {
  const [height, setHeight] = useState(47);
  const media = useMedia();

  const onLayout = useCallback((e: LayoutChangeEvent) => {
    setHeight(e.nativeEvent.layout.height / 2);
  }, []);

  useEffect(() => {
    setOverlapPadding(media.sm ? height : 0);
  }, [height, setOverlapPadding, media.sm]);

  if (media.gtSm) return children;

  return (
    <View $sm={{height, y: -height}}>
      <View $sm={{position: 'absolute'}} width="100%" onLayout={onLayout}>
        {children}
      </View>
    </View>
  );
}
