import {useCallback} from 'react';

export const useBackNavigation = (onBack?: () => void) => {
  return useCallback(() => {
    if (onBack) {
      onBack();

      return;
    }

    history.back();
  }, [onBack]);
};
