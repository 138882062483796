import {SelectInput} from '@/components/inputs/select-input/SelectInput';
import {Heading4} from '@/components/texts/Heading';
import {InputErrorMessage} from '@/components/texts/InputErrorMessage';
import {Label3} from '@/components/texts/Label';
import {InterviewTradesSchemaType, useValidations} from '@/hooks/useValidations';
import {InterviewContinueButton} from '@/modules/interview/components/InterviewContinueButton';
import useExistingTradesStoreAnswers from '@/modules/interview/hooks/useExistingTradesStoreAnswers';
import {useGetQuestionWithAnswers} from '@/modules/interview/hooks/useGetQuestionWithAnswers';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useAppDispatch, useAppSelector} from '@/store';
import {updateInterview} from '@/store/reducers/interview';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslate} from '@tolgee/react';
import {Href, router} from 'expo-router';
import {useCallback, useMemo} from 'react';
import {Control, FieldValues, Path, SubmitHandler, useController, useForm} from 'react-hook-form';
import {YStack} from 'tamagui';

type TradesOrVolumesQuestionsProps = {
  href: Href;
};

const ASSET_CLASS_PREFIX = 'ASSET_CLASS_';

export function TradesOrVolumesQuestions({href}: TradesOrVolumesQuestionsProps) {
  const defaultValues = useExistingTradesStoreAnswers();
  const {t} = useTranslate();
  const dispatch = useAppDispatch();
  const {answers: tradeAnswers} = useGetQuestionWithAnswers('TRADES');
  const assetClasses = useAppSelector(state => state.interview?.EXP_V2);
  const {interviewTradesSchema} = useValidations();
  const posthog = usePostHog();
  const {control, handleSubmit} = useForm({
    mode: 'onChange',
    resolver: yupResolver(interviewTradesSchema),
    context: {
      assetClasses,
    },
    defaultValues,
  });

  const assetClassesWithoutPrefix = useMemo(() => {
    if (!assetClasses || assetClasses.length === 0 || !tradeAnswers) return undefined;
    return assetClasses.map(assetClass => {
      const withoutPrefix = assetClass.replace(ASSET_CLASS_PREFIX, '') as
        | 'STOCKS'
        | 'BONDS'
        | 'ETF'
        | 'DERIVATIVES';
      return {
        code: withoutPrefix,
        label: tradeAnswers.find(_ => _.code.includes(withoutPrefix))?.label,
      };
    });
  }, [assetClasses, tradeAnswers]);

  const onSubmit: SubmitHandler<InterviewTradesSchemaType> = useCallback(
    data => {
      dispatch(updateInterview(data));
      router.navigate(href);
    },
    [dispatch, href]
  );

  return (
    <YStack gap="$4">
      <YStack gap="$2">
        <Heading4 variant="medium">{t('INTERVIEW.TRADES_AND_VOLUMES.TITLE')}</Heading4>
      </YStack>
      {assetClassesWithoutPrefix &&
        assetClassesWithoutPrefix.map(assetClass => {
          return (
            <YStack gap="$2" key={assetClass.code}>
              <Label3 color="$neutral500">{assetClass.label}</Label3>
              <YStack gap="$4">
                <TradesOrVolumeQuestion
                  control={control}
                  name={`ASSET_TRADES_${assetClass.code}`}
                  placeholder={t('INTERVIEW.TRADES')}
                />
                <TradesOrVolumeQuestion
                  control={control}
                  name={`ASSET_VOLUME_${assetClass.code}`}
                  placeholder={t('INTERVIEW.VOLUME')}
                />
              </YStack>
            </YStack>
          );
        })}
      <InterviewContinueButton
        onPress={handleSubmit(onSubmit, data => {
          posthog?.capture('form_submit_failed', data);
        })}
      />
    </YStack>
  );
}

type TradesOrVolumeQuestionProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  placeholder: string;
};

export function TradesOrVolumeQuestion<T extends FieldValues>({
  control,
  name,
  placeholder,
}: TradesOrVolumeQuestionProps<T>) {
  const {question, answers} = useGetQuestionWithAnswers(name);
  const {
    field,
    fieldState: {error},
  } = useController({
    name,
    control,
  });
  return (
    <>
      {answers && (
        <SelectInput
          items={answers?.map(_ => ({name: _.label, key: _.code}))}
          placeholder={placeholder}
          onSelect={value => {
            field.onChange(value);
          }}
          selectedItem={field.value}
          testID={name}
        />
      )}
      <InputErrorMessage error={error?.message} />
    </>
  );
}
