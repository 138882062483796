import {useTranslate} from '@tolgee/react';
import {toast} from 'burnt';
import {useCallback} from 'react';

import {useGetViolationError} from '@/hooks/useGetViolationError';

export const useShowViolationToast = () => {
  const {t} = useTranslate();

  const {getViolationError, isFetching} = useGetViolationError();

  const showViolationToast = useCallback(
    (error: unknown, defaultError?: string) => {
      const errorText = getViolationError(error);

      toast({
        preset: 'error',
        title: errorText ?? defaultError ?? t('SNACKBAR.SOMETHING_WENT_WRONG'),
      });
    },
    [getViolationError, t]
  );

  return {showViolationToast, isFetching};
};
