import SvgFile from '@/components/icons/SvgFile';
import React from 'react';
import {View} from 'tamagui';

type SquarePlusIconProps = {
  onSurface?: boolean;
};

export const SquarePlusIcon = View.styleable<SquarePlusIconProps>(({onSurface, ...props}, ref) => {
  return (
    <View
      ref={ref}
      backgroundColor={onSurface ? '$neutralBG' : '$primaryHover'}
      padding="$2"
      borderRadius="$0.5"
      {...props}
    >
      <SvgFile name="plus" color={onSurface ? '$primary' : '$white'} />
    </View>
  );
});
