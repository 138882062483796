import {jwtDecode} from 'jwt-decode';
import {useEffect, useState} from 'react';

import {useAppSelector} from '@/store';
import {JWTUserData} from '@/types/jwt';

export function useTokenUserInfo() {
  const [tokenUserInfo, setTokenUserInfo] = useState<JWTUserData>();

  const accessToken = useAppSelector(state => state.auth.accessToken);

  useEffect(() => {
    if (!accessToken) {
      setTokenUserInfo(undefined);

      return;
    }

    const info = jwtDecode<JWTUserData>(accessToken);

    setTokenUserInfo(info);
  }, [accessToken]);

  return tokenUserInfo;
}
