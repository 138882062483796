import {useTranslate} from '@tolgee/react';
import {Platform, Share} from 'react-native';
import {XStack, YStack, useMedia} from 'tamagui';

import {Button} from '@/components/buttons/Button';
import {Heading3} from '@/components/texts/Heading';
import {LabelWithTextAndCopyButton} from '@/components/texts/LabelWithTextAndCopyButton';
import {Skeleton} from '@/components/views/Skeleton';
import {useTokenUserInfo} from '@/hooks/useTokenUserInfo';
import {FriendsTipContent} from '@/modules/friends/components/FriendsTipContent';
import {GradientCard} from '@/modules/recommendations/components/GradientCard';
import {useAppSelector} from '@/store';
import {useFetchReferrerQuery} from '@/store/queries/referralApi';
import {skipToken} from '@reduxjs/toolkit/query';
import {captureException} from '@sentry/react-native';

const BRANCH_URL = process.env.EXPO_PUBLIC_BRANCH_URL;

export function FriendsPrimaryHeader() {
  const {t} = useTranslate();
  const locale = useAppSelector(state => state.app.language);
  const media = useMedia();
  const userInfo = useTokenUserInfo();
  const {data: referrer, isLoading} = useFetchReferrerQuery(
    userInfo?.user_id ? {id: userInfo?.user_id} : skipToken
  );
  const refLink = `${BRANCH_URL}/${locale === 'en' ? 'en/' : ''}ref/?r=${
    referrer?.referrerCode ?? ''
  }`;

  const onShare = async () => {
    try {
      await Share.share({
        message: `${t('INVITE_FRIENDS.SHARE_TEXT')} ${refLink}`,
      });
    } catch (error) {
      captureException(error);
    }
  };

  return (
    <GradientCard
      containerStyle={{
        borderRadius: '$1',
        minHeight: 220,
        padding: '$5',
      }}
    >
      <YStack
        gap="$4"
        width="100%"
        $gtSm={{
          width: '50%',
        }}
      >
        <Heading3 flexWrap="wrap" variant="medium" color="white">
          {t('NAVIGATION.INVITE_FRIENDS.BLOCK_1.TEXT_1')}
        </Heading3>
        {isLoading && <Skeleton color="onPrimary" height={24} width="25%" />}
        <Skeleton color="onPrimary" width="100%">
          {!isLoading && (
            <LabelWithTextAndCopyButton
              label={t('INVITE_FRIENDS.INVITATION_LINK')}
              copyLabel={refLink.replace('https://', '')}
              copyText={refLink}
              backgroundColor="$primaryHover"
              textColor="$white"
            />
          )}
        </Skeleton>
        {Platform.OS !== 'web' && (
          <Button tertiary onPress={onShare}>
            {t('NAVIGATION.INVITE_FRIENDS.BLOCK_1.BUTTON_1')}
          </Button>
        )}

        <XStack justifyContent={media.sm ? 'center' : 'flex-start'}>
          <FriendsTipContent onShare={onShare} />
        </XStack>
      </YStack>
    </GradientCard>
  );
}
