import {LabelCard} from '@/components/cards/LabelCard';
import {Skeleton} from '@/components/views/Skeleton';
import {OrderItem} from '@/modules/account-activity/components/OrderItem';
import {useGetCustomerOrdersQuery} from '@/store/queries/customersApi';
import {useTranslate} from '@tolgee/react';
import React from 'react';
import {YStack} from 'tamagui';

type OrdersPreviewCardProps = {
  customerId: string;
};

export function OrdersPreviewCard({customerId}: OrdersPreviewCardProps) {
  const {t} = useTranslate();
  const {data, isLoading} = useGetCustomerOrdersQuery({
    customerId,
    offset: 0,
    limit: 3,
    category: 'ALL',
  });

  return (
    <LabelCard
      gap="$5"
      label={t('OVERVIEW.ORDERS-OVERVIEW')}
      labelLink={{
        href: {
          pathname: '/overview/[customerId]/orders',
          params: {customerId},
        },
        label: t('OVERVIEW.ORDERS-OVERVIEW-MORE'),
      }}
    >
      <YStack gap="$3">
        {isLoading ? (
          <>
            <Skeleton width="100%" />
            <Skeleton width="100%" />
          </>
        ) : null}
        {data?.items.map((item, index) => (
          <OrderItem
            key={`transaction-${item.createdAt}-${index}`}
            item={item}
            customerId={customerId}
          />
        ))}
      </YStack>
    </LabelCard>
  );
}
