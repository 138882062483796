import {useOnboardingSearchParams} from '@/modules/onboarding/hooks/useOnboardingSearchParams';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';
import {OnboardingParams} from '@/types/routeParams';
import {useMemo} from 'react';

type Res = {
  params: OnboardingParams;
  nextParams: OnboardingParams;
  navigateToChildScreen: boolean;
};

export const useChildScreen = (): Res => {
  const {product, screenType, onboardingType, ...rest} = useOnboardingSearchParams();
  const {data: selfUser} = useGetSelfUserQuery();

  const navigateToChildScreen = useMemo(
    () =>
      product === 'CHILD_ACCOUNT' && !onboardingType && (!screenType || screenType === 'common'),
    [product, screenType, onboardingType]
  );

  const nextParams = useMemo(() => {
    const newParams: OnboardingParams = {
      product,
      screenType: navigateToChildScreen || selfUser?.complete ? 'child' : undefined,
      onboardingType,
      ...rest,
    };

    return newParams;
  }, [product, navigateToChildScreen, selfUser?.complete, rest, onboardingType]);

  return {
    params: {product, screenType, onboardingType, ...rest},
    nextParams,
    navigateToChildScreen,
  };
};
