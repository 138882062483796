import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {UserDepositScreen} from '@/modules/onboarding/components/UserDepositScreen';
import {useOnboardingSearchParams} from '@/modules/onboarding/hooks/useOnboardingSearchParams';
import React from 'react';

const DepositsScreen = () => {
  const params = useOnboardingSearchParams();

  return (
    <FullscreenScrollView variant="small" useKeyboardAwareScrollView>
      <UserDepositScreen
        href={{pathname: '/onboarding/personal-details/bank', params}}
        product={params.product}
      />
    </FullscreenScrollView>
  );
};

export default DepositsScreen;
