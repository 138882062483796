import {useTranslate} from '@tolgee/react';
import {Link} from 'expo-router';
import {Platform} from 'react-native';
import {YStack} from 'tamagui';

import {Button} from '@/components/buttons/Button';
import {Card} from '@/components/cards/Card';
import {Heading4} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {useOnboardingSearchParams} from '@/modules/onboarding/hooks/useOnboardingSearchParams';

const SustainLevelNews = () => {
  const {t} = useTranslate();

  const params = useOnboardingSearchParams();

  return (
    <FullscreenScrollView variant="small">
      <YStack gap="$4">
        <Heading4 variant="medium">
          {t('INTERVIEW.WHAT_LEVEL_OF_SUSTAINABILITY.GOOD_NEWS_BOTTOM_SHEET.HEADER')}
        </Heading4>
        <Card gap="$4">
          {Platform.OS === 'web' && (
            <Paragraph2 variant="medium" color="$primary">
              {t('INTERVIEW.WHAT_LEVEL_OF_SUSTAINABILITY.GOOD_NEWS_BOTTOM_SHEET.TITLE')}
            </Paragraph2>
          )}
          <Paragraph2 variant="medium">
            {t('INTERVIEW.WHAT_LEVEL_OF_SUSTAINABILITY.GOOD_NEWS_BOTTOM_SHEET.DESC_1')}
          </Paragraph2>
          <Paragraph2 variant="medium">
            {t('INTERVIEW.WHAT_LEVEL_OF_SUSTAINABILITY.GOOD_NEWS_BOTTOM_SHEET.DESC_2')}
          </Paragraph2>
          <Paragraph2 variant="medium">
            {t('INTERVIEW.WHAT_LEVEL_OF_SUSTAINABILITY.GOOD_NEWS_BOTTOM_SHEET.DESC_3')}
          </Paragraph2>
        </Card>
        <Link href={{pathname: '/interview/question/inputs', params}} asChild>
          <Button alignSelf="stretch">{t('CONTINUE')}</Button>
        </Link>
      </YStack>
    </FullscreenScrollView>
  );
};

export default SustainLevelNews;
