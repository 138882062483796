import {useTranslate} from '@tolgee/react';
import {toast} from 'burnt';
import {forwardRef, useEffect, useMemo, useState} from 'react';
import {Spinner} from 'tamagui';

import {Modal} from '@/components/modal/Modal';
import {MFAActionCodeEnter} from '@/modules/mfa/components/MFAActionCodeEnter';
import {MFAActionSelectNumber} from '@/modules/mfa/components/MFAActionSelectNumber';
import {MFAPhoneInput} from '@/modules/mfa/components/MFAPhoneInput';
import {MfaMethodSmsResponse} from '@/types/api/auth';
import {MFAActionType} from '@/types/mfaAction';
import {ModalRef} from '@/types/modal';

type MFAModalProps = {
  action: MFAActionType | null;
};

export const MFAModal = forwardRef<ModalRef, MFAModalProps>(({action}, ref) => {
  const {t} = useTranslate();
  const [method, setMethod] = useState<MfaMethodSmsResponse>();
  const [elevatedSession, setElevatedSession] = useState(false);
  const [internalOpen, setInternalOpen] = useState(false);
  useEffect(() => {
    setInternalOpen(action !== null);
    setMethod(undefined);
  }, [action]);

  const actionComponent = useMemo(() => {
    switch (action?.name) {
      case 'initial':
        if (method === undefined) {
          return (
            <MFAPhoneInput
              action={action}
              onSuccess={(method: MfaMethodSmsResponse) => {
                setMethod(method);
              }}
              modal
            />
          );
        } else {
          return (
            <MFAActionCodeEnter
              method={method}
              action={action}
              onSuccess={() => {
                toast({
                  preset: 'done',
                  title: t('MFA.ADDED_NUMBER'),
                });
                setInternalOpen(false);
              }}
              modal
            />
          );
        }
      case 'additional':
        if (!elevatedSession && method === undefined) {
          return (
            <MFAActionSelectNumber
              action={action}
              onSuccess={(method: MfaMethodSmsResponse) => {
                setMethod(method);
              }}
            />
          );
        } else if (!elevatedSession && method !== undefined) {
          return (
            <MFAActionCodeEnter
              method={method}
              action={action}
              onSuccess={() => {
                setElevatedSession(true);
                setMethod(undefined);
              }}
              modal
            />
          );
        } else if (elevatedSession && method) {
          return (
            <MFAActionCodeEnter
              method={method}
              action={{name: 'finish'}}
              onSuccess={() => {
                setInternalOpen(false);
                toast({
                  preset: 'done',
                  title: t('MFA.ADDED_NUMBER'),
                });
              }}
              modal
            />
          );
        } else {
          return (
            <MFAPhoneInput
              action={action}
              onSuccess={(method: MfaMethodSmsResponse) => {
                setMethod(method);
              }}
              modal
            />
          );
        }
      case 'one-time-code':
        if (method === undefined) {
          return (
            <MFAActionSelectNumber
              action={action}
              onSuccess={(method: MfaMethodSmsResponse) => {
                setMethod(method);
              }}
            />
          );
        } else {
          return (
            <MFAActionCodeEnter
              method={method}
              action={action}
              onSuccess={() => {
                setInternalOpen(false);
                toast({
                  preset: 'done',
                  title: t('MFA.AUTHENTICATION_SUCCESSFUL'),
                });
                action.onSuccess?.();
              }}
              modal
            />
          );
        }
      case 'deletion':
        if (method === undefined) {
          return (
            <MFAActionSelectNumber
              action={action}
              onSuccess={(method: MfaMethodSmsResponse) => {
                setMethod(method);
              }}
            />
          );
        } else {
          return (
            <MFAActionCodeEnter
              method={method}
              action={action}
              onSuccess={() => {
                setInternalOpen(false);
                toast({
                  preset: 'done',
                  title: t('MFA.REMOVED_NUMBER'),
                });
              }}
              modal
            />
          );
        }
      case undefined:
        return <Spinner />;
    }
  }, [action, method, elevatedSession, t]);

  return (
    <Modal open={internalOpen} onOpenChange={() => setInternalOpen(false)}>
      <Modal.Content typeContainer="SCROLL" closeOnBackground={false}>
        <Modal.Title />
        <Modal.Description />
        {actionComponent}
      </Modal.Content>
    </Modal>
  );
});
