import {isStoryBookEnabled} from '@/constants/storybook';
import {useEffect, useState} from 'react';
import StorybookUIRoot from '../../../../.storybook';

export function StorybookScreen() {
  const [loading, setLoading] = useState(isStoryBookEnabled);

  useEffect(() => {
    let server: any;

    if (process.env.NODE_ENV === 'development' && isStoryBookEnabled) {
      // Dynamically import the server only in development mode
      import('@/lib/mocks/server').then(({server: importedServer}) => {
        server = importedServer;
        setLoading(true);
        server.listen({
          onUnhandledRequest(request: any, print: any) {
            const url = new URL(request.url);

            if (
              url.pathname.match(/(\/openid-configuration)|tolgee|(refdata\/v2)|(symbolicate)/g)
            ) {
              return;
            }

            print.warning();
          },
        });
        setLoading(false);
        console.log('Mocking enabled');
      });
    }

    return () => {
      if (process.env.NODE_ENV === 'development' && isStoryBookEnabled && server) {
        setLoading(false);
        server.close();
        console.log('Mocking disabled');
      }
    };
  }, []);

  if (isStoryBookEnabled) {
    if (loading) {
      return null;
    } else {
      return <StorybookUIRoot />;
    }
  }
  return null;
}
