import {Card} from '@/components/cards/Card';
import {LabelCheckBox} from '@/components/inputs/LabelCheckbox';
import {InputErrorMessage} from '@/components/texts/InputErrorMessage';
import {FormInputProps} from '@/types/FormInputProps';
import {useCallback} from 'react';
import {FieldValues, useController} from 'react-hook-form';
import {YStack} from 'tamagui';

export function FormLabelCheckBox<T extends FieldValues>({
  control,
  name,
  label,
}: FormInputProps<T>) {
  const {
    field,
    fieldState: {error},
  } = useController({
    name,
    control,
    // @ts-ignore
    defaultValue: false,
  });

  const onCheckedChange = useCallback(() => {
    field.onChange(!field.value);
  }, [field]);

  return (
    <YStack gap="$2">
      <Card padding="$5" gap="$2">
        <LabelCheckBox label={label} checked={field.value} onCheckedChange={onCheckedChange} />
        <InputErrorMessage error={error?.message} />
      </Card>
    </YStack>
  );
}
