import {Modal} from '@/components/modal/Modal';
import {SavingsRateOrderForm} from '@/modules/orders/components/SavingsRateOrderForm';
import {usePostCustomerOrderMutation} from '@/store/queries/customersApi';
import {ModalRef} from '@/types/modal';
import {useTranslate} from '@tolgee/react';
import {toast} from 'burnt';
import {useLocalSearchParams, useRouter} from 'expo-router';
import React, {PropsWithChildren, useEffect, useRef} from 'react';
import {useMedia} from 'tamagui';

type SavingsRateModalProps = {
  customerId: string;
};

export function SavingsRateModal({children, customerId}: PropsWithChildren<SavingsRateModalProps>) {
  const {t} = useTranslate();
  const {triggerSavingsRateChange} = useLocalSearchParams<{
    triggerSavingsRateChange?: string;
  }>();
  const router = useRouter();
  const modalRef = useRef<ModalRef>(null);
  const [postOrder] = usePostCustomerOrderMutation();

  const media = useMedia();

  useEffect(() => {
    if (triggerSavingsRateChange) {
      modalRef.current?.open(true);
      router.setParams({triggerSavingsRateChange: undefined});
    }
  }, [router, triggerSavingsRateChange]);

  return (
    <Modal ref={modalRef}>
      <Modal.Trigger asChild>{children}</Modal.Trigger>
      <Modal.Content>
        <SavingsRateOrderForm
          customerId={customerId}
          onSubmit={async data => {
            const res = await postOrder({
              orderType: 'RECURRING_DEPOSIT_CHANGE',
              customerId,
              amount: data.amount,
              scheduleType: 'MONTHLY',
            });
            if (res.error) {
              toast({
                preset: 'error',
                title: t('SNACKBAR.ORDER-NOT-CREATED'),
              });
            } else {
              modalRef.current?.open(false);
              toast({
                preset: 'done',
                title: t('SNACKBAR.RECURRING-DEPOSIT-CREATED'),
              });
            }
          }}
        />
      </Modal.Content>
    </Modal>
  );
}
