import useDebounce from '@/hooks/useDebounce';
import AssetsAllocationTab from '@/modules/interview/components/planner/AssetsAllocationTab';
import ProjectDevelopmentTab, {
  ProjectDevelopmentTabHandle,
  ProjectDevelopmentTabProps,
} from '@/modules/interview/components/planner/ProjectedDevelopmentTab';
import {Tabbar} from '@/modules/navigation/components/tabbar/Tabbar';
import {useTranslate} from '@tolgee/react';
import {PropsWithChildren, forwardRef, useMemo, useState} from 'react';
import {YStack} from 'tamagui';

type Props = {
  additionalElements?: {label: string}[];
} & ProjectDevelopmentTabProps;

const ProjectDevelopment = forwardRef<ProjectDevelopmentTabHandle, PropsWithChildren<Props>>(
  (
    {
      children,
      initialDeposit,
      initialMonthlySavings,
      product,
      selectedStrategy,
      showInputs,
      additionalElements,
    },
    ref
  ) => {
    const {t} = useTranslate();
    const [debouncedStrategy, setDebouncedStrategy] = useState(selectedStrategy);

    useDebounce(
      () => {
        setDebouncedStrategy(selectedStrategy);
      },
      1000,
      [selectedStrategy]
    );
    const elements = useMemo(
      () => [
        {label: t('PROJECTED-DEVELOPMENT.PROJECT-DEVELOPMENT')},
        {label: t('PROJECTED-DEVELOPMENT.ASSETS-ALLOCATION')},
        ...(additionalElements ?? []),
      ],
      [t, additionalElements]
    );

    return (
      <YStack gap="$3">
        <Tabbar
          elements={elements}
          variant="onBackground"
          fitContent
          wrapperProps={{alignItems: 'flex-start'}}
        >
          <ProjectDevelopmentTab
            ref={ref}
            product={product}
            initialDeposit={initialDeposit}
            initialMonthlySavings={initialMonthlySavings}
            selectedStrategy={debouncedStrategy}
            showInputs={showInputs}
          />
          <AssetsAllocationTab product={product} selectedStrategy={debouncedStrategy} />
          {children}
        </Tabbar>
      </YStack>
    );
  }
);

ProjectDevelopment.displayName = 'ProjectDevelopment';

export default ProjectDevelopment;
