import {Heading6} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {useTranslate} from '@tolgee/react';
import {YStack} from 'tamagui';

import {TipWithSideModal} from '@/modules/interview/components/TipWithSideModal';
import {IncomeOrWealthScreen} from '@/modules/onboarding/components/IncomeOrWealthScreen';
import {useOnboardingSearchParams} from '@/modules/onboarding/hooks/useOnboardingSearchParams';
import {useMemo} from 'react';

const Income = () => {
  const params = useOnboardingSearchParams();
  const {t} = useTranslate();
  const tipContent = useMemo(
    () => (
      <YStack gap="$5" marginVertical="$5">
        <Heading6 variant="medium">{t('PERSONAL_DETAILS.CAREER_FAQ.HEADER')}</Heading6>
        <Paragraph2 color="$neutral400">{t('PERSONAL_DETAILS.CAREER_FAQ.LINE_1')}</Paragraph2>
        <Paragraph2 color="$neutral400">{t('PERSONAL_DETAILS.CAREER_FAQ.LINE_2')}</Paragraph2>
      </YStack>
    ),
    [t]
  );
  return (
    <FullscreenScrollView variant="small">
      <IncomeOrWealthScreen
        type="income"
        href={{pathname: '/onboarding/personal-details/wealth', params}}
      />
      <TipWithSideModal tipTitle={t('ONBOARDING.WHY-ASKING')} tipContent={tipContent} />
    </FullscreenScrollView>
  );
};

export default Income;
