import {Card} from '@/components/cards/Card';
import {PartnerBank} from '@/constants/partner-bank';
import {AgreementsSchemaType, useValidations} from '@/hooks/useValidations';
import {DabAgreement} from '@/modules/onboarding/components/forms/agreements/DabAgreement';
import {GeneralAgreement} from '@/modules/onboarding/components/forms/agreements/GeneralAgreement';
import {GinmonAgreement} from '@/modules/onboarding/components/forms/agreements/GinmonAgreement';
import {GuardianAgreement} from '@/modules/onboarding/components/forms/agreements/GuardianAgreement';
import {UpvestAgreement} from '@/modules/onboarding/components/forms/agreements/UpvestAgreement';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {ShortenedCustomerInformationResponse} from '@/types/api/customers.v2';
import {yupResolver} from '@hookform/resolvers/yup';
import {forwardRef, useImperativeHandle} from 'react';
import {useForm} from 'react-hook-form';
import {YStack} from 'tamagui';

type UserAgreementsFormProps = {
  onValid: (data: AgreementsSchemaType) => void;
  product: ShortenedCustomerInformationResponse['product'];
  showSupplementaryAgreement: boolean;
  showIsSelf: boolean;
  showGinmonDocs: boolean;
  hasDabAccounts: boolean;
  hasUpvestAccounts: boolean;
  isUpvest: boolean;
};
export const UserAgreementsForm = forwardRef<any, UserAgreementsFormProps>(
  function UserAgreementsForm(
    {
      onValid,
      product,
      showSupplementaryAgreement,
      showIsSelf,
      showGinmonDocs,
      hasDabAccounts,
      hasUpvestAccounts,
      isUpvest,
    },
    ref
  ) {
    const {agreementsSchema} = useValidations();
    const posthog = usePostHog();
    const {handleSubmit, control} = useForm({
      resolver: yupResolver(agreementsSchema),
      context: {
        custodian: isUpvest ? PartnerBank.UPVEST : PartnerBank.DAB,
        showSupplementaryAgreement,
      },
      mode: 'onChange',
    });

    useImperativeHandle(ref, () => ({
      submit: handleSubmit(onValid, data => {
        posthog?.capture('form_submit_failed', data);
      }),
    }));

    return (
      <Card>
        <YStack gap="$4">
          {showSupplementaryAgreement && <GuardianAgreement control={control} />}

          {showIsSelf && <GeneralAgreement control={control} product={product} />}

          {showGinmonDocs && (
            <GinmonAgreement control={control} product={product} isUpvest={isUpvest} />
          )}

          {isUpvest && !hasUpvestAccounts && <UpvestAgreement control={control} />}

          {!isUpvest && !hasDabAccounts && <DabAgreement control={control} />}
        </YStack>
      </Card>
    );
  }
);
