import {Button} from '@/components/buttons/Button';
import {FormTextInput} from '@/components/inputs/form/FormTextInput';
import {Modal} from '@/components/modal/Modal';
import {Label3} from '@/components/texts/Label';
import {useShowViolationToast} from '@/hooks/useShowViolationToast';
import {useValidations} from '@/hooks/useValidations';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useChangeEmailMutation, useConfirmEmailMutation} from '@/store/queries/emailVerifyApi';
import {ModalRef} from '@/types/modal';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslate} from '@tolgee/react';
import {toast} from 'burnt';
import {forwardRef, useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {View, XStack, YStack} from 'tamagui';

type Props = {
  onSuccess: () => void;
  newEmail: string;
};

export const ConfirmEmailChangeModal = forwardRef<ModalRef, Props>(({onSuccess, newEmail}, ref) => {
  const {t} = useTranslate();
  const {showViolationToast} = useShowViolationToast();
  const [changeEmailTrigger] = useChangeEmailMutation();
  const [confirmEmailTrigger] = useConfirmEmailMutation();
  const {changeEmailConfirmSchema} = useValidations();
  const {handleSubmit, control, getValues} = useForm({
    resolver: yupResolver(changeEmailConfirmSchema),
    mode: 'onChange',
  });
  const posthog = usePostHog();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSuccessResendCode = () => {
    toast({
      preset: 'done',
      title: t('PROFILE.SECURITY.CHANGE-MAIL.SUCCESS'),
    });
  };

  const onResend = useCallback(async () => {
    await changeEmailTrigger({newEmail}).unwrap();
  }, [changeEmailTrigger, newEmail]);

  const onConfirmMail = useCallback(async () => {
    try {
      await confirmEmailTrigger({
        code: getValues('verificationCode').trim(),
        password: getValues('currentPassword'),
      }).unwrap();
      onSuccess();
      onSuccessResendCode();
      (ref as unknown as any).current?.open?.(false);
    } catch (error) {
      showViolationToast(error);
    }
  }, [confirmEmailTrigger, getValues, onSuccess, onSuccessResendCode, ref, showViolationToast]);

  return (
    <Modal ref={ref}>
      <Modal.Content>
        <YStack gap="$7">
          <View gap="$4">
            <FormTextInput
              control={control}
              label={t('PROFILE.SECURITY.CHANGE-EMAIL.VERIFICATION-CODE')}
              name="verificationCode"
              bordered
              modal
            />
            <Label3 color="$neutral400">{t('PROFILE.SECURITY.CHANGE-EMAIL.NOTE')}</Label3>
          </View>
          <FormTextInput
            control={control}
            label={t('PROFILE.SECURITY.CHANGE-EMAIL.CURRENT-PASSWORD')}
            name="currentPassword"
            bordered
            textInputProps={{secureTextEntry: true}}
            modal
          />
          <XStack gap="$3" justifyContent="space-between">
            <Button
              onPress={handleSubmit(onConfirmMail, data => {
                posthog?.capture('form_submit_failed', data);
              })}
            >
              {t('PROFILE.SECURITY.CHANGE-EMAIL.CONFIRM')}
            </Button>
            <Button secondary onPress={onResend}>
              {t('PROFILE.SECURITY.CHANGE-EMAIL.RESEND')}
            </Button>
          </XStack>
        </YStack>
      </Modal.Content>
    </Modal>
  );
});
