import SvgFile from '@/components/icons/SvgFile';
import {SelectInput} from '@/components/inputs/select-input/SelectInput';
import {Button2} from '@/components/texts/Button';
import {Label2} from '@/components/texts/Label';
import {useSession} from '@/providers/session-provider/SessionProvider';
import {useAppSelector} from '@/store';
import {JWTUserData} from '@/types/jwt';
import {SelectInputRenderItemType} from '@/types/SelectInput';
import {useTranslate} from '@tolgee/react';
import {jwtDecode} from 'jwt-decode';
import React, {useCallback, useMemo} from 'react';
import {useMedia, View, XStack} from 'tamagui';

export function UserEmailButton() {
  const accessToken = useAppSelector(state => state.auth.accessToken);
  const media = useMedia();
  const {t} = useTranslate();
  const {signOut} = useSession();

  const email = useMemo(() => {
    if (accessToken) {
      const jwt = jwtDecode<JWTUserData>(accessToken);
      return jwt.email;
    }
  }, [accessToken]);

  const items = useMemo(
    () => [
      {
        key: 'logout',
        name: t('LOGIN.LOGOUT'),
      },
    ],
    [t]
  );

  const renderItem = useCallback((item: SelectInputRenderItemType) => {
    return (
      <XStack key={item.key} gap="$2" alignItems="center" {...item.props}>
        <SvgFile name="logout" color="$red" />
        <Label2>{item.name}</Label2>
      </XStack>
    );
  }, []);

  if (!email) return;

  return (
    <SelectInput
      items={items}
      onSelect={(item: string) => {
        if (item === 'logout') {
          signOut();
        }
      }}
      cardProps={{
        minWidth: 150,
      }}
      wrapperProps={{
        unstyled: true,
      }}
      renderItem={renderItem}
    >
      <XStack
        hoverStyle={{
          opacity: 0.8,
        }}
        gap="$1"
        cursor="pointer"
        justifyContent="center"
        alignItems="center"
      >
        <View
          backgroundColor="$neutral300"
          borderRadius="$full"
          padding="$1"
          alignSelf="center"
          justifyContent="center"
          alignItems="center"
          $gtSm={{
            marginRight: '$1',
          }}
        >
          <SvgFile name="profile" color="$white" size={16} />
        </View>
        {media.gtSm && <Button2 color="$neutral700">{email}</Button2>}
        <SvgFile size={18} name="chevron-down" color="$primary" />
      </XStack>
    </SelectInput>
  );
}
