/* eslint-disable no-restricted-imports */

import {GetProps, styled, Text} from 'tamagui';

const Paragraph = styled(Text, {
  tag: 'span',
  name: 'Paragraph',
  accessibilityRole: 'text',
  color: '$text',
  fontFamily: '$body',
  fontWeight: 'normal',
  whiteSpace: 'normal',
  style: {
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  variants: {
    variant: {
      medium: {
        fontFamily: '$bodyMedium',
      },
    },
  } as const,
});

export type ParagraphProps = GetProps<typeof Paragraph>;

export const Paragraph1 = styled(Paragraph, {
  name: 'P1',
  fontSize: 18,
  lineHeight: 28,
  $sm: {
    fontSize: 16,
    lineHeight: 26,
  },
});

export const Paragraph2 = styled(Paragraph, {
  name: 'P2',
  fontSize: 16,
  lineHeight: 26,
  $sm: {
    fontSize: 14,
    lineHeight: 22,
  },
});

export const Paragraph3 = styled(Paragraph, {
  name: 'P3',
  fontSize: 14,
  lineHeight: 22,
  $sm: {
    fontSize: 12,
    lineHeight: 20,
  },
});
