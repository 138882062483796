import {Button} from '@/components/buttons/Button';
import SvgFile from '@/components/icons/SvgFile';
import {Modal} from '@/components/modal/Modal';
import {Heading6} from '@/components/texts/Heading';
import {Paragraph2, Paragraph3} from '@/components/texts/Paragraph';
import {useAppSelector} from '@/store';
import {StaticDocument} from '@/types/api/refdata.v2';
import {captureException} from '@sentry/react-native';
import {useTranslate} from '@tolgee/react';
import {PropsWithChildren, useEffect, useState} from 'react';
import {Linking} from 'react-native';
import {ScrollView, View, XStack, YStack, useMedia} from 'tamagui';

type Props = {
  open: boolean;
  handleAgree: () => void;
  handlePostpone: () => void;
  onClose: () => void;
  firstNameLastName: string;
  contractTermDocument?: StaticDocument;
  termsAndCondDocument?: StaticDocument;
};

export default function TermsAndConditionsModal({
  open,
  handleAgree,
  handlePostpone,
  onClose,
  firstNameLastName,
  contractTermDocument,
  termsAndCondDocument,
}: PropsWithChildren<Props>) {
  const [internalOpen, setInternalOpen] = useState(false);
  const {t} = useTranslate();

  useEffect(() => {
    if (open === true) {
      setInternalOpen(true);
    }
  }, [open]);

  const currentLanguage = useAppSelector(state => state.app.language);
  const media = useMedia();

  const handleAgreeInternal = () => {
    setInternalOpen(false);
    handleAgree();
  };

  const handlePostponeInternal = () => {
    setInternalOpen(false);
    handlePostpone();
  };

  const handleEmailLinkPressed = () => {
    Linking.openURL(process.env.EXPO_PUBLIC_MAILTO_SERVICE).catch(captureException);
  };

  const handledOpenDoc = (url?: string) => {
    if (url === undefined) return;
    Linking.openURL(url).catch(captureException);
  };

  return (
    <>
      <Modal
        open={internalOpen}
        onOpenChange={open => {
          if (!open) onClose();
        }}
      >
        <Modal.Content typeContainer="SCROLL">
          <Heading6 textAlign="center" variant="medium" marginBottom="$3">
            {t('HOME.TERMS_AND_COND_BOTTOM_SHEET.TITLE')}
          </Heading6>
          <ScrollView>
            <YStack gap="$5">
              <YStack gap="$5">
                <Paragraph3>
                  {t('HOME.TERMS_AND_COND_BOTTOM_SHEET.DESC_1', {name: firstNameLastName})}
                </Paragraph3>
                <Paragraph3>{t('HOME.TERMS_AND_COND_BOTTOM_SHEET.DESC_2')}</Paragraph3>
                <Paragraph3>{t('HOME.TERMS_AND_COND_BOTTOM_SHEET.DESC_3')}</Paragraph3>
                <Paragraph3>{t('HOME.TERMS_AND_COND_BOTTOM_SHEET.DESC_4')}</Paragraph3>
                <Paragraph3>{t('HOME.TERMS_AND_COND_BOTTOM_SHEET.DESC_5')}</Paragraph3>
                <Paragraph3>
                  {t('HOME.TERMS_AND_COND_BOTTOM_SHEET.DESC_6')}
                  <Paragraph3 cursor="pointer" onPress={handleEmailLinkPressed} color="$primary">
                    service@ginmon.de
                  </Paragraph3>
                  {t('HOME.TERMS_AND_COND_BOTTOM_SHEET.DESC_7')}
                </Paragraph3>
                <Paragraph3>{t('HOME.TERMS_AND_COND_BOTTOM_SHEET.DESC_8')}</Paragraph3>
              </YStack>

              <YStack gap="$3" backgroundColor="$neutralBG" borderRadius="$1" padding="$3">
                <Paragraph2 color="$primary" fontWeight="bold">
                  {t('HOME.TERMS_AND_COND_BOTTOM_SHEET.NEW_TERMS_ARTICLE.TITLE')}
                </Paragraph2>
                <Paragraph3>
                  {t('HOME.TERMS_AND_COND_BOTTOM_SHEET.NEW_TERMS_ARTICLE.DESC_1')}
                </Paragraph3>
                {contractTermDocument !== undefined && (
                  <XStack
                    gap="$3"
                    onPress={() => handledOpenDoc(contractTermDocument.url)}
                    cursor="pointer"
                  >
                    <SvgFile name="circle" size={8} color="$primary" />
                    <Paragraph3 color="$primary">
                      {contractTermDocument.label[currentLanguage]}
                    </Paragraph3>
                  </XStack>
                )}
                {termsAndCondDocument !== undefined && (
                  <XStack
                    gap="$3"
                    onPress={() => handledOpenDoc(termsAndCondDocument.url)}
                    cursor="pointer"
                  >
                    <SvgFile name="circle" size={8} color="$primary" />
                    <Paragraph3 color="$primary">
                      {termsAndCondDocument.label[currentLanguage]}
                    </Paragraph3>
                  </XStack>
                )}
                <Paragraph3>
                  {t('HOME.TERMS_AND_COND_BOTTOM_SHEET.NEW_TERMS_ARTICLE.DESC_2')}
                </Paragraph3>
                <YStack gap="$3">
                  {Array.from({length: 3}).map((_, index) => (
                    <View key={`ITEM_${index + 1}`}>
                      <XStack gap="$3" key={`ITEM_SUB_${index + 1}`}>
                        <Paragraph3 key={`ITEM_SUB_P1_${index + 1}`}>{index + 1}.</Paragraph3>
                        <Paragraph3 key={`ITEM_SUB_P2_${index + 1}`}>
                          {t(
                            `HOME.TERMS_AND_COND_BOTTOM_SHEET.NEW_TERMS_ARTICLE.NEW_TERMS.TERM_${index + 1}.TITLE`
                          )}
                        </Paragraph3>
                      </XStack>
                      <Paragraph3 key={`ITEM_SUB_P3_${index + 1}`} marginLeft="$6">
                        {t(
                          `HOME.TERMS_AND_COND_BOTTOM_SHEET.NEW_TERMS_ARTICLE.NEW_TERMS.TERM_${index + 1}.TEXT`
                        )}
                      </Paragraph3>
                    </View>
                  ))}
                </YStack>
              </YStack>
            </YStack>
          </ScrollView>
          <XStack marginTop="$3">
            {media.sm ? (
              <YStack gap="$3" flex={1}>
                <Button onPress={handleAgreeInternal}>
                  {t('HOME.TERMS_AND_COND_BOTTOM_SHEET.AGREE')}
                </Button>
                <Button secondary onPress={handlePostponeInternal}>
                  {t('HOME.TERMS_AND_COND_BOTTOM_SHEET.LATER')}
                </Button>
              </YStack>
            ) : (
              <XStack flex={1} justifyContent="space-between">
                <Button secondary onPress={handlePostponeInternal} width={300}>
                  {t('HOME.TERMS_AND_COND_BOTTOM_SHEET.LATER')}
                </Button>
                <Button onPress={handleAgreeInternal} width={300}>
                  {t('HOME.TERMS_AND_COND_BOTTOM_SHEET.AGREE')}
                </Button>
              </XStack>
            )}
          </XStack>
        </Modal.Content>
      </Modal>
    </>
  );
}
