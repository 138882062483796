import {registerDevMenuItems} from 'expo-dev-menu';
import {router} from 'expo-router';
import {Platform} from 'react-native';

export function ExpoDevMenuProvider() {
  if (__DEV__ && Platform.OS !== 'web') {
    const devMenuItems = [
      {
        name: 'Storybook',
        callback: () => router.push('/storybook'),
      },
    ];

    registerDevMenuItems(devMenuItems);
  }

  return null;
}
