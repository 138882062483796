import {forwardRef} from 'react';
import {GetProps, Input, styled} from 'tamagui';

import {useTextInput} from '@/components/modal-bottom-sheet/hooks/useTextInput';
import {Surface} from '@/components/views/Surface';

export type BaseInputProps = {
  bordered?: boolean;
  disabled?: boolean;
  iconLeft?: boolean;
  surfaceProps?: GetProps<typeof Surface>;
  modal?: boolean;
} & Omit<GetProps<typeof StyledInput>, 'onChange'>;

export const BaseInput = forwardRef<any, BaseInputProps>(
  (
    {
      onChangeText,
      onBlur: propsOnBlur,
      onFocus: propsOnFocus,
      value,
      bordered,
      disabled,
      surfaceProps,
      iconLeft,
      modal,
      ...rest
    },
    ref
  ) => {
    const {onFocus, onBlur} = useTextInput({onBlur: propsOnBlur, onFocus: propsOnFocus, modal});

    return (
      <StyledSurface bordered={!!bordered} {...surfaceProps}>
        <StyledInput
          ref={ref}
          onChangeText={onChangeText}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          bordered={bordered}
          disabled={disabled}
          iconLeft={iconLeft}
          borderedDisabled={bordered && disabled}
          borderedIconLeft={bordered && iconLeft}
          {...rest}
        />
      </StyledSurface>
    );
  }
);

const StyledSurface = styled(Surface, {
  flexGrow: 0,
  variants: {
    bordered: {
      true: {
        borderRadius: '$0.25',
      },
      false: {
        $sm: {
          borderRadius: '$0.75',
        },
      },
    },
  } as const,
  defaultVariants: {
    bordered: false,
  },
});

const StyledInput = styled(Input, {
  height: 'auto',
  width: '100%',
  color: '$text',
  borderWidth: 0,
  outlineColor: '$primary',
  outlineOffset: -2,
  padding: '$6',
  placeholderTextColor: '$neutral400',
  borderRadius: '$1',

  fontFamily: '$bodyMedium',
  fontSize: 16,
  lineHeight: 22,
  $sm: {
    fontSize: 14,
    lineHeight: 18,
    padding: '$4',
    height: 50,
  },

  style: {
    // @ts-ignore
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  variants: {
    bordered: {
      true: {
        padding: '$3',

        fontFamily: '$body',
        fontSize: 14,
        lineHeight: 18,
        $sm: {
          fontSize: 12,
          lineHeight: 18,
          padding: '$3',
          height: 44, // required due to android default padding that can not be removed
        },

        borderWidth: 1,
        borderColor: '$neutral200',
        borderRadius: '$0.25',
      },
      false: {
        $sm: {
          borderRadius: '$0.75',
        },
      },
    },
    disabled: {
      true: {
        disabled: true,
        cursor: 'auto',
        backgroundColor: '$neutral100',
      },
    },
    borderedDisabled: {
      true: {
        backgroundColor: '$neutral50',
      },
    },
    iconLeft: {
      true: {
        $sm: {
          paddingLeft: '$9',
        },
        paddingLeft: '$9',
      },
    },
    borderedIconLeft: {
      true: {
        $sm: {
          paddingLeft: '$8',
        },
        paddingLeft: '$8',
      },
    },
  } as const,
  defaultVariants: {
    bordered: false,
    disabled: false,
    borderedDisabled: false,
    iconLeft: false,
    borderedIconLeft: false,
  },
});
