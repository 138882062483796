import {DocumentPickerAsset} from 'expo-document-picker';

// TODO: see if we can ditch the dependency and make it easier
export async function uploadDocumentWithUrl(
  serverUrl: string,
  asset: Omit<DocumentPickerAsset, 'mimeType'> & {mimeType: string}
): Promise<Response> {
  return fetch(serverUrl, {
    method: 'PUT',
    body: asset.file,
    headers: {
      'Content-Type': asset.mimeType,
    },
  });
}
