import {FormTextInput} from '@/components/inputs/form/FormTextInput';
import {EmployerAddressDetailsSchemaType, useValidations} from '@/hooks/useValidations';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useAppSelector} from '@/store';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslate} from '@tolgee/react';
import React, {forwardRef, useEffect, useImperativeHandle} from 'react';
import {useForm} from 'react-hook-form';

import {View, XStack, YStack} from 'tamagui';

type EmployerAddressFormProps = {
  onValid: (data: EmployerAddressDetailsSchemaType) => void;
};

export type SubmitFormRef = {submit: () => Promise<void>};

export const EmployerAddressForm = forwardRef<any, EmployerAddressFormProps>(
  function EmployerAddressForm({onValid}, ref) {
    const {t} = useTranslate();
    const {employerAddressDetailsSchema} = useValidations();
    const employer = useAppSelector(state => state.onboarding.personalDetails.employer);
    const posthog = usePostHog();

    useImperativeHandle(ref, () => ({
      submit: handleSubmit(onValid, data => {
        posthog?.capture('form_submit_failed', data);
      }),
    }));

    const {handleSubmit, control, setValue} = useForm({
      resolver: yupResolver(employerAddressDetailsSchema),
      mode: 'onChange',
    });

    useEffect(() => {
      setValue('employer', employer?.name ?? '');
      setValue('street', employer?.address?.street ?? '');
      setValue('streetNumber', employer?.address?.streetNumber ?? '');
      setValue('postCode', employer?.address?.postCode ?? '');
      setValue('city', employer?.address?.city ?? '');
    }, [setValue, employer]);

    return (
      <YStack gap="$6">
        <View>
          <FormTextInput control={control} name="employer" label={t('FORM.LABELS.EMPLOYER')} />
        </View>
        <XStack gap="$4">
          <View flex={3} flexBasis={3 / 4}>
            <FormTextInput control={control} name="street" label={t('FORM.LABELS.STREET')} />
          </View>
          <View flex={1} flexBasis={1 / 4}>
            <FormTextInput
              control={control}
              name="streetNumber"
              label={t('FORM.LABELS.STREET_NUMBER')}
            />
          </View>
        </XStack>
        <XStack gap="$4">
          <View minWidth={100} maxWidth={125}>
            <FormTextInput control={control} name="postCode" label={t('FORM.LABELS.POST_CODE')} />
          </View>
          <View flex={1}>
            <FormTextInput control={control} name="city" label={t('FORM.LABELS.CITY')} />
          </View>
        </XStack>
      </YStack>
    );
  }
);
