import {PartnerBank} from '@/constants/partner-bank';
import {ShortenedCustomerInformationResponse} from '@/types/api/customers.v2';

const PartnerBankByStrategyType: Record<
  ShortenedCustomerInformationResponse['product'],
  keyof typeof PartnerBank
> = {
  INVESTMENT_ACCOUNT: PartnerBank.DAB,
  SAVINGS_ACCOUNT: PartnerBank.DAB,
  VL_ACCOUNT: PartnerBank.DAB,
  CHILD_ACCOUNT: PartnerBank.DAB,
  THEME_ACCOUNT: PartnerBank.UPVEST,
  COMPANY_INVESTMENT_ACCOUNT: PartnerBank.DAB,
  COMPANY_SAVINGS_ACCOUNT: PartnerBank.DAB,
};

const NewPartnerBankByStrategyType: Record<
  ShortenedCustomerInformationResponse['product'],
  keyof typeof PartnerBank
> = {
  INVESTMENT_ACCOUNT: PartnerBank.UPVEST,
  SAVINGS_ACCOUNT: PartnerBank.UPVEST,
  VL_ACCOUNT: PartnerBank.DAB,
  CHILD_ACCOUNT: PartnerBank.DAB,
  THEME_ACCOUNT: PartnerBank.UPVEST,
  COMPANY_INVESTMENT_ACCOUNT: PartnerBank.DAB,
  COMPANY_SAVINGS_ACCOUNT: PartnerBank.DAB,
};

export const getPartnerBank = (
  isUpvestInvestment: boolean,
  product?: ShortenedCustomerInformationResponse['product']
) => {
  if (!product) return PartnerBank.DAB;

  const dataSet = isUpvestInvestment ? NewPartnerBankByStrategyType : PartnerBankByStrategyType;

  return dataSet[product];
};
