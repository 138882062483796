import {Label2, Label3} from '@/components/texts/Label';
import {useTranslate} from '@tolgee/react';
import React from 'react';
import {View, YStack} from 'tamagui';

export function WebGlFallback() {
  const {t} = useTranslate();
  return (
    <View flex={1} justifyContent="center" alignItems="center" gap="$2">
      <Label2 color="$neutral400">{t('CHART.WEBGL-NOT-AVAILABLE')}</Label2>
      <Label3 color="$neutral400">{t('CHART.WEBGL-NOT-AVAILABLE-DESCRIPTION')}</Label3>
      <YStack gap="$2">
        <Label3 color="$neutral400">• {t('CHART.WEBGL-NOT-AVAILABLE-DESCRIPTION.POINT_1')}</Label3>
        <Label3 color="$neutral400">• {t('CHART.WEBGL-NOT-AVAILABLE-DESCRIPTION.POINT_2')}</Label3>
        <Label3 color="$neutral400">• {t('CHART.WEBGL-NOT-AVAILABLE-DESCRIPTION.POINT_3')}</Label3>
      </YStack>
    </View>
  );
}
