import {FormCustomCheckbox} from '@/components/inputs/form/FormCustomCheckbox';
import {Heading6} from '@/components/texts/Heading';
import {Label2} from '@/components/texts/Label';
import {Paragraph2} from '@/components/texts/Paragraph';
import {AgreementsSchemaType} from '@/hooks/useValidations';
import {TipWithSideModal} from '@/modules/interview/components/TipWithSideModal';
import {InfoBox} from '@/modules/orders/components/InfoBox';
import {useTranslate} from '@tolgee/react';
import {Control} from 'react-hook-form';
import {View, YStack} from 'tamagui';

export function GuardianAgreement({control}: {control: Control<AgreementsSchemaType>}) {
  const {t} = useTranslate();
  return (
    <FormCustomCheckbox
      control={control}
      name="guardianTerms"
      label={<Label2 variant="medium">{t('ACCOUNT_SETUP.GUARDIANS_AGREEMENT')}</Label2>}
    >
      <YStack paddingLeft={36} gap="$4">
        <TipWithSideModal
          tipTitle={
            <View>
              <InfoBox wrapperProps={{padding: 0, cursor: 'pointer'}}>
                <Label2 variant="medium" color="$primary">
                  {t('ACCOUNT_SETUP.AGREEMENTS.GUARDIAN_DOC')}
                </Label2>
              </InfoBox>
            </View>
          }
          tipContent={
            <YStack gap="$4">
              <Heading6 variant="medium">
                {t('ACCOUNT_SETUP.AGREEMENTS.GUARDIAN_DOC.TITLE')}
              </Heading6>
              <Paragraph2 color="$neutral400">
                {t('ACCOUNT_SETUP.AGREEMENTS.GUARDIAN_DOC.TEXT')}
              </Paragraph2>
            </YStack>
          }
        />
      </YStack>
    </FormCustomCheckbox>
  );
}
