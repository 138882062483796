import TermsAndConditionsModal from '@/modules/terms-and-conditions/components/TermsAndConditionsModal';
import {useAppDispatch, useAppSelector} from '@/store';
import {useGetStaticDocumentsQuery} from '@/store/queries/referenceApi';
import {useGetSelfUserQuery, useUpdateUserMutation} from '@/store/queries/usersApi';
import {setLastAgreementPostponedAt} from '@/store/reducers/app';
import {StaticDocument} from '@/types/api/refdata.v2';
import {DocumentAgreement} from '@/types/api/users';
import {captureException} from '@sentry/react-native';
import {useFocusEffect} from 'expo-router';
import {useCallback, useMemo, useState} from 'react';

export function TermsAndConditions() {
  const {data: currentUser} = useGetSelfUserQuery();
  const lastAgreementPostponedAt = useAppSelector(state => state.app.lastAgreementPostponedAt);
  const dispatch = useAppDispatch();
  const {data} = useGetStaticDocumentsQuery();
  const documentAgreements = useMemo(() => {
    return currentUser?.documentAgreements;
  }, [currentUser]);
  const [updateUser] = useUpdateUserMutation();
  const [contractTermDocument, setContractTermDocument] = useState<StaticDocument>();
  const [termsAndCondDocument, setTermsAndCondDocument] = useState<StaticDocument>();
  const firstNameLastName = useMemo(() => {
    return currentUser?.firstName + ' ' + currentUser?.lastName;
  }, [currentUser]);
  const [openTermsAndConditionsModal, setOpenTermsAndConditionsModal] = useState(false);

  useFocusEffect(
    useCallback(() => {
      if (!data || !currentUser?.complete) return;

      if (lastAgreementPostponedAt !== undefined) {
        const currTimestamp = Date.now();
        const diff = currTimestamp - lastAgreementPostponedAt;
        const diffHours = diff / HOUR;

        if (diffHours < 24) return;
      }

      const contractTermsDoc = data.find(
        doc => doc.code === process.env.EXPO_PUBLIC_GINMON_CONTRACT_TERMS
      );
      const termsAndCondDoc = data.find(
        doc => doc.code === process.env.EXPO_PUBLIC_GINMON_TERMS_AND_CONDITIONS
      );

      if (!termsAndCondDoc || !contractTermsDoc) return;

      setContractTermDocument(contractTermsDoc);
      setTermsAndCondDocument(termsAndCondDoc);

      let alreadyContractAgreed = false;
      let alreadyTermsAndCondAgreed = false;

      for (const doc of documentAgreements ?? []) {
        if (alreadyTermsAndCondAgreed && alreadyContractAgreed) break;

        if (doc.uuid === termsAndCondDoc.uuid) {
          alreadyTermsAndCondAgreed = true;

          continue;
        }

        if (doc.uuid === contractTermsDoc.uuid) {
          alreadyContractAgreed = true;
        }
      }

      const alreadyAgreed = alreadyContractAgreed && alreadyTermsAndCondAgreed;

      if (alreadyAgreed) return;

      setOpenTermsAndConditionsModal(true);
    }, [data, lastAgreementPostponedAt, documentAgreements, currentUser?.complete])
  );

  const handledPostponeButtonPressed = () => {
    dispatch(setLastAgreementPostponedAt(Date.now()));
  };

  const agreedWithTerms = useCallback(async () => {
    if (!contractTermDocument || !termsAndCondDocument) return;

    try {
      let newDocs: DocumentAgreement[] = [];
      const newContractTermsDoc: DocumentAgreement = {
        type: process.env.EXPO_PUBLIC_GINMON_CONTRACT_TERMS,
        uuid: contractTermDocument.uuid,
      };
      const newTermsAndCondDoc: DocumentAgreement = {
        type: process.env.EXPO_PUBLIC_GINMON_TERMS_AND_CONDITIONS,
        uuid: termsAndCondDocument.uuid,
      };

      if (documentAgreements) {
        let contractTermsExist = false;
        let termsAndCondExist = false;

        for (const docAgree of documentAgreements) {
          if (docAgree.type === process.env.EXPO_PUBLIC_GINMON_CONTRACT_TERMS) {
            contractTermsExist = true;
            newDocs.push(newContractTermsDoc);

            continue;
          }

          if (docAgree.type === process.env.EXPO_PUBLIC_GINMON_TERMS_AND_CONDITIONS) {
            termsAndCondExist = true;
            newDocs.push(newTermsAndCondDoc);

            continue;
          }
          newDocs.push(docAgree);
        }

        if (!contractTermsExist) {
          newDocs.push(newContractTermsDoc);
        }

        if (!termsAndCondExist) {
          newDocs.push(newTermsAndCondDoc);
        }
      } else {
        newDocs = [newContractTermsDoc, newTermsAndCondDoc];
      }

      if (!currentUser) return;
      const {documentAgreements: prev, ...selfWithoutDocs} = currentUser;
      const success = await updateUser({
        currentUser: selfWithoutDocs,
        changes: {
          documentAgreements: newDocs,
        },
      });
      if (success) {
        dispatch(setLastAgreementPostponedAt(undefined));
      }
    } catch (e) {
      captureException(e);
    }
  }, [
    contractTermDocument,
    currentUser,
    dispatch,
    documentAgreements,
    termsAndCondDocument,
    updateUser,
  ]);

  return (
    <TermsAndConditionsModal
      open={openTermsAndConditionsModal}
      handleAgree={agreedWithTerms}
      handlePostpone={handledPostponeButtonPressed}
      firstNameLastName={firstNameLastName}
      contractTermDocument={contractTermDocument}
      termsAndCondDocument={termsAndCondDocument}
      onClose={() => setOpenTermsAndConditionsModal(false)}
    />
  );
}

export const HOUR = 1000 * 60 * 60;
