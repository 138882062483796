import {ZERO_DEPOSIT} from '@/components/forms/InitialDepositForm';
import {CardCheckbox} from '@/components/inputs/CardCheckbox';
import {initialDepositMethod} from '@/types/api/orders';
import React, {useCallback} from 'react';
import {Control, FieldValues, Path, PathValue, useController} from 'react-hook-form';
import {View, useMedia} from 'tamagui';

type FormDepositMethodSelectProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  items: {code: initialDepositMethod | typeof ZERO_DEPOSIT; label: string; caption?: string}[];
};

export function FormDepositMethodSelect<T extends FieldValues>({
  control,
  name,
  items,
}: FormDepositMethodSelectProps<T>) {
  const {
    field,
    fieldState: {error},
  } = useController({
    name,
    control,
    defaultValue: initialDepositMethod.DIRECT_DEBIT as PathValue<T, Path<T>>,
  });

  const media = useMedia();

  const onCheckedChange = useCallback(
    (checked: boolean, code: initialDepositMethod | typeof ZERO_DEPOSIT) => {
      field.onChange(code);
    },
    [field]
  );

  const getChecked = useCallback(
    (code: initialDepositMethod | typeof ZERO_DEPOSIT) => {
      return field.value === code;
    },
    [field.value]
  );

  return (
    <View
      flexDirection={media.sm ? 'column' : 'row'}
      gap="$2"
      justifyContent="space-between"
      flexWrap="wrap"
    >
      {items.map(item => {
        return (
          <CardCheckbox
            variant="radio"
            checked={getChecked(item.code)}
            onCheckedChange={_ => onCheckedChange(_, item.code)}
            key={item.code}
            label={item.label}
            caption={item.caption}
            wrapperProps={{
              minWidth: 200,
              width: media.gtSm ? '49%' : '100%',
            }}
          />
        );
      })}
    </View>
  );
}
