import {Href, Stack, useFocusEffect, usePathname, useRouter} from 'expo-router';
import {useCallback, useState} from 'react';
import {useMedia} from 'tamagui';

import {SideNavigation} from '@/modules/navigation/components/SideNavigation';
import {useScreenOptions} from '@/modules/navigation/hooks/useScreenOptions';

const INVALID_PATHS_ON_MOBILE: Href[] = ['/profile/display-and-language'];

export default function ProfileLayout() {
  const media = useMedia();
  const [previousMedia, setPreviousMedia] = useState(media.sm);
  const router = useRouter();
  const path = usePathname();

  const screenOptions = useScreenOptions();

  // Some profile sub screens are not available on mobile (e.g. display and language)
  // So we redirect to the profile tab
  useFocusEffect(
    useCallback(() => {
      if (media.sm && !previousMedia && INVALID_PATHS_ON_MOBILE.includes(path as Href)) {
        router.navigate('/profile');
      }
      setPreviousMedia(media.sm);
    }, [media.sm, path, previousMedia, router])
  );

  return (
    <SideNavigation>
      <Stack screenOptions={screenOptions} />
    </SideNavigation>
  );
}
