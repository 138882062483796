import {SuggestedStrategyBadge} from '@/components/buttons/SuggestedStrategyBadge';
import {Card} from '@/components/cards/Card';
import {Heading1, Heading3} from '@/components/texts/Heading';
import {Label2, Label3} from '@/components/texts/Label';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {Skeleton} from '@/components/views/Skeleton';
import useDebounce from '@/hooks/useDebounce';
import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {StrategyInfoFactCard} from '@/modules/interview/components/planner/strategy-info/StrategyInfoFactCard';
import {StrategyInfoScrollCard} from '@/modules/interview/components/planner/strategy-info/StrategyInfoScrollCard';
import {useInvestmentStrategiesQuery} from '@/store/queries/referenceApi';
import {useTranslate} from '@tolgee/react';
import React, {PropsWithChildren, useMemo, useState} from 'react';
import {ScrollView, View, XStack, YStack, getTokenValue} from 'tamagui';

export type StrategyInfoProps = {
  selectedStrategy: string;
  recommendedStrategy?: string;
  isSV: boolean;
  hideBadge?: boolean;
};

export default function StrategyInfo({
  selectedStrategy,
  recommendedStrategy,
  isSV,
  hideBadge,
}: PropsWithChildren<StrategyInfoProps>) {
  const {t} = useTranslate();

  const {data: strategies} = useInvestmentStrategiesQuery();
  const {getStrategyByCode} = useRefdataLocalizable();
  const [debouncedStrategy, setDebouncedStrategy] = useState(selectedStrategy);
  const COMMON_OFFSET = getTokenValue('$4');

  useDebounce(
    () => {
      setDebouncedStrategy(selectedStrategy);
    },
    150,
    [selectedStrategy]
  );

  const yearlyReturn = useMemo(
    () => getStrategyByCode(strategies, debouncedStrategy)?.yearlyReturn,
    [getStrategyByCode, debouncedStrategy, strategies]
  );

  const valueAtRisk = useMemo(
    () => getStrategyByCode(strategies, debouncedStrategy)?.valueAtRisk,
    [getStrategyByCode, debouncedStrategy, strategies]
  );

  const varDeltaVsDax = useMemo(
    () => getStrategyByCode(strategies, debouncedStrategy)?.varDeltaVsDax,
    [getStrategyByCode, debouncedStrategy, strategies]
  );

  const etfFees = useMemo(
    () => getStrategyByCode(strategies, debouncedStrategy)?.etfFees,
    [getStrategyByCode, debouncedStrategy, strategies]
  );

  const badge = useMemo(() => {
    if (hideBadge) return null;
    return (
      <SuggestedStrategyBadge
        selectedStrategy={debouncedStrategy}
        recommendedStrategy={recommendedStrategy}
      />
    );
  }, [hideBadge, debouncedStrategy]);

  return (
    <YStack gap="$5">
      <Card padding="$6">
        <View justifyContent="space-between" flexDirection="row" flexWrap="wrap" gap="$3">
          <YStack gap="$3" flex={1} $sm={{flexBasis: '100%'}}>
            <Heading1 variant="medium">
              {getStrategyByCode(strategies, debouncedStrategy)?.label}
            </Heading1>
            {badge}
            <Label2 variant="medium">
              {getStrategyByCode(strategies, debouncedStrategy)?.explanation}
            </Label2>
            <Label2 color="$neutral400">
              {getStrategyByCode(strategies, debouncedStrategy)?.description}
            </Label2>
          </YStack>

          <YStack gap="$3" flex={1}>
            <StrategyInfoFactCard
              title={t('PLANNER.STRATEGY-INFO.BLOCK_2.TITLE')}
              icon="reasonable-return"
              label={t('PLANNER.STRATEGY-INFO.BLOCK_2.TEXT', {
                value: getStrategyByCode(strategies, debouncedStrategy)?.label,
              })}
              value={yearlyReturn || null}
              suffix={t('PLANNER.STRATEGY-INFO.PA')}
              containerProps={{width: '100%'}}
            />

            <XStack gap="$3">
              <StrategyInfoFactCard
                title={t('PLANNER.STRATEGY-INFO.BLOCK_3.TITLE')}
                icon="risk-value"
                label={t('PLANNER.STRATEGY-INFO.BLOCK_3.TEXT', {
                  value: getStrategyByCode(strategies, debouncedStrategy)?.label,
                })}
                containerProps={{
                  flex: 1,
                }}
              />

              {debouncedStrategy.includes('AP') ? (
                <StrategyInfoFactCard
                  title={t('PLANNER.STRATEGY-INFO.BLOCK_4.GLOBAL.TITLE')}
                  icon="stocks"
                  label={t('PLANNER.STRATEGY-INFO.BLOCK_4.GLOBAL.TEXT')}
                  containerProps={{
                    flex: 1,
                  }}
                />
              ) : (
                <StrategyInfoFactCard
                  title={t('PLANNER.STRATEGY-INFO.BLOCK_4.GREEN.TITLE')}
                  icon="eco-leaf"
                  label={t('PLANNER.STRATEGY-INFO.BLOCK_4.GREEN.TEXT')}
                  containerProps={{
                    flex: 1,
                  }}
                />
              )}
            </XStack>
          </YStack>
        </View>
      </Card>

      <ScrollView
        horizontal
        contentContainerStyle={{
          width: '100%',
        }}
        $sm={{
          contentContainerStyle: {
            paddingHorizontal: COMMON_OFFSET,
            width: 'auto',
          },
        }}
        showsHorizontalScrollIndicator={false}
      >
        <XStack gap="$3" width="100%">
          <StrategyInfoScrollCard
            title={t('PLANNER.STRATEGY-INFO.BLOCK_5.TITLE')}
            label={t('PLANNER.STRATEGY-INFO.PA')}
          >
            {yearlyReturn !== undefined && (
              <LocalizedNumbers
                textComponent={Heading3}
                value={yearlyReturn / 100}
                numberStyle={{color: '$primary', variant: 'medium'}}
                variant="percentage"
              />
            )}
          </StrategyInfoScrollCard>
          {!isSV && (
            <StrategyInfoScrollCard
              title={t('PLANNER.STRATEGY-INFO.BLOCK_6.TITLE')}
              label={
                varDeltaVsDax === undefined ||
                (varDeltaVsDax >= 0 && (
                  <Skeleton>
                    {varDeltaVsDax !== undefined && (
                      <LocalizedNumbers
                        value={varDeltaVsDax / 100}
                        variant="percentage"
                        textComponent={Label3}
                        numberStyle={{
                          color: '$neutral400',
                          width: 80,
                          wordWrap: 'break-word',
                          marginBottom: '$1',
                        }}
                      />
                    )}
                  </Skeleton>
                ))
              }
            >
              {valueAtRisk !== undefined && (
                <LocalizedNumbers
                  value={valueAtRisk / 100}
                  variant="percentage"
                  textComponent={Heading3}
                  numberStyle={{variant: 'medium'}}
                />
              )}
            </StrategyInfoScrollCard>
          )}

          <StrategyInfoScrollCard
            title={t('PLANNER.STRATEGY-INFO.BLOCK_7.TITLE')}
            label={t('PLANNER.STRATEGY-INFO.PA')}
          >
            <Heading3 variant="medium">
              {isSV
                ? t('PLANNER.STRATEGY-INFO.BLOCK_7.ZINZ-PERCENT')
                : t('PLANNER.STRATEGY-INFO.BLOCK_7.PERCENT')}
            </Heading3>
          </StrategyInfoScrollCard>

          <StrategyInfoScrollCard
            title={t('PLANNER.STRATEGY-INFO.BLOCK_8.TITLE')}
            label={t('PLANNER.STRATEGY-INFO.PA')}
          >
            {etfFees !== undefined && (
              <LocalizedNumbers
                value={etfFees / 100}
                variant="percentage"
                textComponent={Heading3}
                numberStyle={{variant: 'medium'}}
              />
            )}
          </StrategyInfoScrollCard>
        </XStack>
      </ScrollView>
    </YStack>
  );
}
