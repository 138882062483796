import SvgFile from '@/components/icons/SvgFile';
import {Label2, Label3} from '@/components/texts/Label';
import {forwardRef} from 'react';
import {XStack, YStack} from 'tamagui';

type ProfileListItemProps = {
  label?: string;
  value: string;
  onPress?: () => void;
  cursor?: 'pointer';
  children?: React.ReactNode;
};

export const ProfileListItem = forwardRef<any, ProfileListItemProps>(function ProfileListItem(
  {label, value, onPress, cursor, children},
  ref
) {
  return (
    <XStack ref={ref} onPress={onPress} width="100%" justifyContent="space-between" cursor={cursor}>
      <YStack gap="$1">
        {label !== undefined && <Label3 color="$neutral400">{label}</Label3>}
        <Label2 variant="medium" flex={1}>
          {value}
        </Label2>
      </YStack>

      {children === undefined ? (
        <SvgFile name="chevron-down" size={18} color="$primary" rotate="270deg" />
      ) : (
        children
      )}
    </XStack>
  );
});
