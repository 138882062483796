import {useId} from 'react';
import Svg, {ClipPath, Defs, G, Path, Rect} from 'react-native-svg';

export function LanguageSvg({language}: {language: 'de' | 'en'}) {
  const id = useId();

  if (language === 'de') {
    return (
      <Svg width="24" height="24" fill="none" viewBox="0 0 24 24">
        <G clipPath={`url(#flag-de_svg__a_${id})`}>
          <Rect width="22" height="22" x="1" y="1" fill="#F7F7F7" rx="11" />
          <Path fill="#22252E" d="M30 1H-6v7h36z" />
          <Path fill="#FF4D4D" d="M30 8H-6v8h36z" />
          <Path fill="#FFD74D" d="M30 16H-6v7h36z" />
        </G>
        <Defs>
          <ClipPath id={`flag-de_svg__a_${id}`}>
            <Rect width="22" height="22" x="1" y="1" fill="#fff" rx="11" />
          </ClipPath>
        </Defs>
      </Svg>
    );
  } else {
    return (
      <Svg width="24" height="24" fill="none" viewBox="0 0 24 24">
        <G clipPath={`url(#flag-gb_svg__a_${id})`}>
          <Path fill="#1543E6" d="M-10 1v22h44V1zm0 0 44 22zm44 0-44 22z" />
          <Path
            fill="#fff"
            fillRule="evenodd"
            d="M7.077 12-11 2.945-9.03-1 12 9.534 33.03-1 35 2.945 16.923 12 35 21.055 33.03 25 12 14.466-9.03 25-11 21.055z"
            clipRule="evenodd"
          />
          <Path fill="#1543E6" d="m-10 1 44 22zm44 0-44 22z" />
          <Path
            fill="#FF4D4D"
            fillRule="evenodd"
            d="M8.67 12-11 2.557-9.668 0 12 10.402 33.668 0 35 2.557 15.33 12 35 21.443 33.668 24 12 13.598-9.668 24-11 21.443z"
            clipRule="evenodd"
          />
          <Path fill="#1543E6" d="M12 1v22zm-22 11h44z" />
          <Path
            fill="#fff"
            fillRule="evenodd"
            d="M15.667 15.667V23H8.333v-7.333H-10V8.333H8.333V1h7.334v7.333H34v7.334z"
            clipRule="evenodd"
          />
          <Path fill="#1543E6" d="M12 1v22zm-22 11h44z" />
          <Path
            fill="#FF4D4D"
            fillRule="evenodd"
            d="M14.2 14.2V23H9.8v-8.8H-10V9.8H9.8V1h4.4v8.8H34v4.4z"
            clipRule="evenodd"
          />
        </G>
        <Defs>
          <ClipPath id={`flag-gb_svg__a_${id}`}>
            <Rect width="22" height="22" x="1" y="1" fill="#fff" rx="11" />
          </ClipPath>
        </Defs>
      </Svg>
    );
  }
}
