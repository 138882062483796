import {LabelCard} from '@/components/cards/LabelCard';
import {Skeleton} from '@/components/views/Skeleton';
import {PreviewChart} from '@/modules/overview/components/PreviewChart';
import {useGetPortfolioBalanceQuery, useGetRatesOfReturnQuery} from '@/store/queries/apeironApi';
import {useTranslate} from '@tolgee/react';
import {useRouter} from 'expo-router';
import {useMemo} from 'react';
import {ScrollView, getTokens, useTheme} from 'tamagui';

type PreviewChartScrollviewProps = {
  customerId: string;
};

export function PreviewChartScrollview({customerId}: PreviewChartScrollviewProps) {
  const theme = useTheme();
  const tokens = getTokens();
  const {t} = useTranslate();
  const router = useRouter();
  const {data: ratesOfReturn} = useGetRatesOfReturnQuery(customerId);
  const {data: balances} = useGetPortfolioBalanceQuery(customerId);
  const balanceData = useMemo(() => {
    return balances?.slice(-30).map(_ => ({x: Date.parse(_.date), y: _.value}));
  }, [balances]);
  const ratesOfReturnData = useMemo(() => {
    return ratesOfReturn?.slice(-30).map(_ => ({x: Date.parse(_.date), y: _.value || 0}));
  }, [ratesOfReturn]);

  return (
    <LabelCard
      withoutCard
      gap="$5"
      label={t('OVERVIEW.PERFORMANCE-DETAILS')}
      labelLink={{
        href: {
          pathname: '/overview/[customerId]/performance',
          params: {customerId},
        },
        label: t('SHOW_MORE'),
      }}
    >
      <Skeleton height={75} width="100%">
        {balanceData &&
          balanceData.length > 0 &&
          ratesOfReturnData &&
          ratesOfReturnData.length > 0 && (
            <ScrollView
              horizontal
              height={75}
              showsHorizontalScrollIndicator={false}
              contentContainerStyle={{gap: '$4', width: '100%'}}
              $platform-native={{
                contentContainerStyle: {gap: tokens.size.$4.val},
              }}
            >
              <PreviewChart
                title={t('PERFORMANCE_CHART.BOTTOM_SHEET.TIME_WEIGHTED_RETURN')}
                value={ratesOfReturnData.at(-1)?.y!}
                percentage
                points={ratesOfReturnData}
                lineColor={theme.primary.val}
                onPress={() => {
                  router.navigate({
                    pathname: '/overview/[customerId]/performance',
                    params: {customerId, tab: 'performance'},
                  });
                }}
                theme={theme}
              />
              <PreviewChart
                title={t('OVERVIEW.CURRENT-BALANCE')}
                value={balanceData.at(-1)?.y!}
                points={balanceData}
                lineColor={theme.yellow.val}
                onPress={() => {
                  router.navigate({
                    pathname: '/overview/[customerId]/performance',
                    params: {customerId, tab: 'balance'},
                  });
                }}
                theme={theme}
              />
            </ScrollView>
          )}
      </Skeleton>
    </LabelCard>
  );
}
