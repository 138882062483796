import {Button} from '@/components/buttons/Button';
import {FormTextInput} from '@/components/inputs/form/FormTextInput';
import {ChangeDisplayNameSchemaType, useValidations} from '@/hooks/useValidations';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {GetCustomerResponse} from '@/types/api/customers.v2';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslate} from '@tolgee/react';
import {useForm} from 'react-hook-form';
import {YStack} from 'tamagui';

type ChangeDisplayNameFormProps = {
  customer: GetCustomerResponse;
  onSubmit: (data: ChangeDisplayNameSchemaType) => void;
  modal?: boolean;
};

export function ChangeDisplayNameForm({customer, onSubmit, modal}: ChangeDisplayNameFormProps) {
  const {t} = useTranslate();
  const {changeDisplayNameSchema} = useValidations();
  const posthog = usePostHog();

  const {handleSubmit, control, watch, trigger, setValue} = useForm({
    resolver: yupResolver(changeDisplayNameSchema),
    mode: 'onChange',
  });

  return (
    <YStack gap="$4">
      <FormTextInput
        bordered
        control={control}
        name="displayName"
        label={t('ACCOUNT_DETAILS.DISPLAY_NAME.NEW')}
        modal={modal}
      />
      <Button
        onPress={handleSubmit(onSubmit, data => {
          posthog?.capture('form_submit_failed', data);
        })}
      >
        {t('ACCOUNT_DETAILS.DISPLAY_NAME.SAVE')}
      </Button>
    </YStack>
  );
}
