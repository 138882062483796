import {Card} from '@/components/cards/Card';
import {Note} from '@/components/views/Note';
import {DEFAULT_COUNTRY} from '@/constants/countries';
import {MFARequiredNote} from '@/modules/mfa/components/MFARequiredNote';
import {Tabbar} from '@/modules/navigation/components/tabbar/Tabbar';
import {PreviousTaxes} from '@/modules/profile/components/taxes/PreviousTaxes';
import {TaxExemptionCards} from '@/modules/profile/components/taxes/TaxExemptionCards';
import {TaxExemptionNote} from '@/modules/profile/components/taxes/TaxExemptionNote';
import {useGetCustomerAccountsQuery} from '@/store/queries/customersApi';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';
import {useTranslate} from '@tolgee/react';
import {useMemo} from 'react';

export const TaxExemptionCard = () => {
  const {t} = useTranslate();
  const {data: customers} = useGetCustomerAccountsQuery();
  const hasChildAccount = useMemo(() => {
    return customers?.items.some(item => item.product === 'CHILD_ACCOUNT');
  }, [customers]);
  const hasOnlyChildAccounts = useMemo(() => {
    const dabCustomers = customers?.items.filter(_ => _.custodian === 'DAB');

    return (
      dabCustomers &&
      dabCustomers.length > 0 &&
      dabCustomers.every(item => item.product === 'CHILD_ACCOUNT')
    );
  }, [customers]);
  const {data: user} = useGetSelfUserQuery();
  const defaultCountryTin = useMemo(
    () =>
      user?.taxIdentification?.find(
        tax => tax.country.toLowerCase() === DEFAULT_COUNTRY.code.toLowerCase()
      )?.number,
    [user?.taxIdentification]
  );
  const taxIdUndefined = useMemo(() => defaultCountryTin === undefined, [defaultCountryTin]);
  const noAccounts = useMemo(() => !customers?.items?.length, [customers]);

  const elements = [
    {label: t('TAXES.TAX-EXEMPTION.ACTUAL')},
    {label: t('TAXES.TAX-EXEMPTION.PREVIOUS')},
  ];

  return (
    <Card headerText={t('TAXES.TAX-EXEMPTION')} gap="$5" padding="$6">
      {noAccounts && (
        <Note
          icon={{name: 'warning', size: 25, color: '$yellowDark'}}
          title={t('TAXES.TAX-EXEMPTION.NOTE.NEED-ACCOUNT')}
          color="$yellowDark"
        />
      )}
      {taxIdUndefined && (
        <Note
          icon={{name: 'warning', size: 25, color: '$yellowDark'}}
          title={t('TAXES.TAX-EXEMPTION.NOTE.NEED-ID')}
          color="$yellowDark"
        />
      )}
      {!noAccounts && (
        <>
          <MFARequiredNote />
          {hasChildAccount && <TaxExemptionNote />}
          <Tabbar elements={elements}>
            <TaxExemptionCards newTaxExemptionDisabled={hasOnlyChildAccounts || taxIdUndefined} />
            <PreviousTaxes />
          </Tabbar>
        </>
      )}
    </Card>
  );
};
