import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {EmployerAddress} from '@/modules/onboarding/components/EmployerAddress';
import {useOnboardingSearchParams} from '@/modules/onboarding/hooks/useOnboardingSearchParams';

const VlAddressScreen = () => {
  const {product} = useOnboardingSearchParams();

  return (
    <FullscreenScrollView variant="small" useKeyboardAwareScrollView>
      <EmployerAddress href={{pathname: '/onboarding/personal-details/bank', params: {product}}} />
    </FullscreenScrollView>
  );
};

export default VlAddressScreen;
