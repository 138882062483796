import {getSecureValue, setStorageItemAsync} from '@/hooks/useStorageState';
import {Proposal} from '@/modules/onboarding/types/proposal';
import {DISCOVERY, STORAGE_KEYS} from '@/providers/session-provider/constants';
import {store} from '@/store';
import {baseApi} from '@/store/queries/baseApi';
import {setAccessToken} from '@/store/reducers/auth';
import {JWTUserData} from '@/types/jwt';
import {captureException} from '@sentry/react-native';
import {makeRedirectUri, refreshAsync} from 'expo-auth-session';
import {getCurrentTimeInSeconds} from 'expo-auth-session/build/TokenRequest';
import {router} from 'expo-router';
import {openAuthSessionAsync} from 'expo-web-browser';
import {jwtDecode} from 'jwt-decode';
import {Platform} from 'react-native';

const CLIENT_ID =
  Platform.OS === 'web'
    ? process.env.EXPO_PUBLIC_AUTH_CLIENT_ID
    : process.env.EXPO_PUBLIC_AUTH_CLIENT_ID_NATIVE;

export const resetSession = async () => {
  store.dispatch(setAccessToken());
  store.dispatch(baseApi.util.resetApiState());
  await setStorageItemAsync(STORAGE_KEYS.REFRESH_TOKEN, null);
  await setStorageItemAsync(STORAGE_KEYS.ID_TOKEN, null);
  router.replace('/');
};

export const signOut = async () => {
  const idToken = await getSecureValue(STORAGE_KEYS.ID_TOKEN);
  if (!idToken || !isTokenStillValid(idToken)) {
    resetSession();
    return;
  }
  const logoutRedirectUri = makeRedirectUri({
    scheme: process.env.EXPO_PUBLIC_AUTH_SCHEME,
    path: 'redirect/logout',
  });

  const logoutUrl =
    `${DISCOVERY.endSessionEndpoint}?` +
    `id_token_hint=${encodeURIComponent(idToken)}` +
    `&post_logout_redirect_uri=${encodeURIComponent(logoutRedirectUri)}` +
    `&client_id=${encodeURIComponent(CLIENT_ID)}`;

  if (Platform.OS === 'web') {
    window.location.href = logoutUrl;
  } else {
    const res = await openAuthSessionAsync(logoutUrl, null, {preferEphemeralSession: true});

    if (res.type === 'success') {
      resetSession();
    }
  }
};

export const refreshTokens = async () => {
  try {
    const refreshToken = await getSecureValue(STORAGE_KEYS.REFRESH_TOKEN);

    if (!refreshToken) {
      resetSession();
      return;
    }
    // Sign out when refresh token is expired
    // otherwise keycloak will show logout button in case other session exists
    if (!isTokenStillValid(refreshToken)) {
      resetSession();
      return;
    }

    const dispatch = store.dispatch;
    const tknResponse = await refreshAsync(
      {
        refreshToken,
        clientId: CLIENT_ID,
      },
      {tokenEndpoint: DISCOVERY.tokenEndpoint}
    );

    await setStorageItemAsync(STORAGE_KEYS.REFRESH_TOKEN, tknResponse.refreshToken!);
    dispatch(setAccessToken(tknResponse.accessToken));
    await setStorageItemAsync(STORAGE_KEYS.ID_TOKEN, tknResponse.idToken!);
    if (!tknResponse.refreshToken || !tknResponse.idToken)
      captureException('idToken or refreshToken not present');
  } catch (e) {
    console.warn(e);
    resetSession();
  }
};

export const isTokenStillValid = (token: string) => {
  if (!token) return false;
  const tokenConfig = jwtDecode(token);
  if ((tokenConfig as any)?.typ === 'Offline') return true;
  if (!tokenConfig?.exp) return false;
  return tokenConfig?.exp && tokenConfig?.exp - getCurrentTimeInSeconds() > 0;
};

export const getProposalFromTokenPayload = (
  tokenPayload: JWTUserData | undefined
): Proposal | undefined => {
  return tokenPayload?.proposal && JSON.parse(atob(tokenPayload?.proposal));
};

// on mobile we have offline access so we check accessToken only
export const isSessionStillValid = () => {
  let token;
  if (Platform.OS === 'web') {
    token = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);
  } else {
    token = store.getState().auth.accessToken;
  }

  return Boolean(token && isTokenStillValid(token));
};
