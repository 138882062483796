import {styled} from 'tamagui';

import {Heading4} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';

export const DialogTitleFrame = styled(Heading4, {
  name: 'DialogTitle',
});

export const DialogDescriptionFrame = styled(Paragraph2, {
  name: 'ModalDescription',
});
