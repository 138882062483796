import SvgFile from '@/components/icons/SvgFile';
import {Label2} from '@/components/texts/Label';
import {useTranslate} from '@tolgee/react';
import {XStack} from 'tamagui';

type Props = {
  onPress: () => void;
  label: string;
};

export const OnboardingRemoveItem = ({onPress, label}: Props) => {
  const {t} = useTranslate();

  return (
    <XStack
      onPress={onPress}
      gap="$1"
      cursor="pointer"
      hoverStyle={{opacity: 0.8}}
      pressStyle={{opacity: 0.8}}
    >
      <SvgFile name="trash" size={18} color="$primary" />
      <Label2 color="$primary">{label}</Label2>
    </XStack>
  );
};
