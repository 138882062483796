import {useState} from 'react';
import {View, useEvent} from 'tamagui';

export const AnimatedHeightView = View.styleable((props, ref) => {
  const {children, ...rest} = props;
  const [height, setHeight] = useState(0);

  const onLayout = useEvent(({nativeEvent}) => {
    if (nativeEvent.layout.height !== undefined) {
      setHeight(nativeEvent.layout.height);
    }
  });

  return (
    <View ref={ref} height={height} animation="slow" overflow="hidden" {...rest}>
      <View top={0} left={0} position="absolute" onLayout={onLayout} width="100%">
        {children}
      </View>
    </View>
  );
});
