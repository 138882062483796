import {Card} from '@/components/cards/Card';
import SvgFile from '@/components/icons/SvgFile';
import {Label1, Label3} from '@/components/texts/Label';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {Divider} from '@/components/views/Divider';
import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import useAvailableProducts from '@/modules/interview/hooks/useAvailableProducts';
import {isRiskProfileInterview} from '@/modules/onboarding/components/helpers/isRiskProfileInterview';
import {Proposal} from '@/modules/onboarding/types/proposal';
import {useGetInterviewQuery} from '@/store/queries/interviewApi';
import {useInvestmentStrategiesQuery} from '@/store/queries/referenceApi';
import {skipToken} from '@reduxjs/toolkit/query';
import {useTranslate} from '@tolgee/react';
import {router} from 'expo-router';
import {useCallback} from 'react';
import {XStack} from 'tamagui';

type ProposalCardProps = {
  proposal?: Proposal;
  onContinue: () => void;
};

export function ProposalCard({proposal, onContinue}: ProposalCardProps) {
  const {t} = useTranslate();
  const products = useAvailableProducts(skipToken);
  const product = products.find(product => product.product === proposal?.product);
  const {getByCode} = useRefdataLocalizable();
  const {data: interview} = useGetInterviewQuery(
    proposal?.interviewId && proposal.product !== 'THEME_ACCOUNT'
      ? {id: proposal?.interviewId}
      : skipToken
  );
  const {data: strategies} = useInvestmentStrategiesQuery();

  const customize = useCallback(() => {
    if (interview && isRiskProfileInterview(interview)) {
      router.navigate({
        pathname: '/interview/planner',
        params: {
          riskProfile: interview?.riskProfile,
          initialDeposit: proposal?.initialDepositAmount,
          initialMonthlySavings: proposal?.initialRecurringDepositAmount,
          product: proposal?.product,
          selectedStrategy: proposal?.initialInvestmentStrategy,
        },
      });
    } else {
      onContinue();
    }
  }, [interview, onContinue, proposal]);

  if (!product) return null;

  const strategy =
    proposal?.initialInvestmentStrategy &&
    getByCode(strategies, proposal?.initialInvestmentStrategy)?.label;

  return (
    <Card gap="$4" $gtSm={{minWidth: 400}} maxWidth="100%">
      <XStack
        gap="$2"
        alignItems="center"
        cursor="pointer"
        hoverStyle={{opacity: 0.8}}
        onPress={() => {
          router.navigate('/interview/product-choice');
        }}
      >
        <SvgFile
          name={product.icon}
          width={24}
          height={24}
          color="$primary"
          containerStyle={{
            borderRadius: '$1',
            backgroundColor: '$neutral50',
            padding: '$2',
          }}
        />
        <Label1 variant="medium">{product.title}</Label1>
        <SvgFile name="edit" size={20} />
      </XStack>

      <Divider />
      {proposal?.product === 'THEME_ACCOUNT' ? (
        <>
          <XStack gap="$2" justifyContent="space-between">
            <Label3 variant="medium" color="$neutral500">
              {t('PROPOSAL.INVESTMENT_STRATEGY')}
            </Label3>
            <Label3 variant="medium">{t('PROPOSAL.UPON_SELECTION')}</Label3>
          </XStack>
        </>
      ) : (
        <>
          <XStack gap="$2" justifyContent="space-between">
            <Label3 variant="medium" color="$neutral500">
              {t('PROPOSAL.INVESTMENT_STRATEGY')}
            </Label3>
            {strategy && <Label3 variant="medium">{strategy}</Label3>}
          </XStack>

          {proposal?.initialDepositAmount !== undefined && proposal.product !== 'VL_ACCOUNT' && (
            <XStack gap="$2" justifyContent="space-between">
              <Label3 variant="medium" color="$neutral500">
                {t('PROPOSAL.INITIAL_INVESTMENT')}
              </Label3>
              <LocalizedNumbers
                value={proposal.initialDepositAmount}
                textComponent={Label3}
                numberStyle={{variant: 'medium'}}
              />
            </XStack>
          )}

          {proposal?.initialRecurringDepositAmount !== undefined && (
            <XStack gap="$2" justifyContent="space-between">
              <Label3 variant="medium" color="$neutral500">
                {t('PROPOSAL.MONTHLY_SAVINGS_PLAN')}
              </Label3>
              <LocalizedNumbers
                value={proposal.initialRecurringDepositAmount}
                textComponent={Label3}
                numberStyle={{variant: 'medium'}}
              />
            </XStack>
          )}
        </>
      )}

      <XStack
        gap="$2"
        alignSelf="flex-end"
        cursor="pointer"
        hoverStyle={{opacity: 0.8}}
        onPress={customize}
      >
        <SvgFile name="edit" size={16} color="$primary" />
        <Label3 variant="medium" color="$primary">
          {t('PROPOSAL.CUSTOMIZE')}
        </Label3>
      </XStack>
    </Card>
  );
}
