import {baseApi} from '@/store/queries/baseApi';
import {Violation} from '@/types/api/refdata.v2';

const BASE_PATH = 'emailverify';

const extendedEmailVerifyApi = baseApi.injectEndpoints({
  endpoints: build => ({
    invokeEmailResending: build.mutation<null, {locale: string}>({
      query: ({locale}) => ({
        url: `${BASE_PATH}/initial/start`,
        method: 'POST',
        body: {locale},
        headers: {'Content-Type': 'application/json'},
      }),
    }),
    changeEmail: build.mutation<null | ResponseError, {newEmail: string}>({
      query: ({newEmail}) => ({
        url: `${BASE_PATH}/code/email/start`,
        method: 'POST',
        body: {newEmail},
        headers: {'Content-Type': 'application/json'},
      }),
    }),
    confirmEmail: build.mutation<null | ResponseError, {code: string; password: string}>({
      query: ({code, password}) => ({
        url: `${BASE_PATH}/code/email/complete`,
        method: 'POST',
        body: {code, password},
        headers: {'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['USERS'],
    }),
  }),
});

export type ResponseError = {
  status: number;
  title: string;
  violations: Violation[];
};

export const {useInvokeEmailResendingMutation, useChangeEmailMutation, useConfirmEmailMutation} =
  extendedEmailVerifyApi;
