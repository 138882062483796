import {BaseRecommendationCard} from '@/modules/recommendations/components/BaseRecommendationCard';
import {RecommendationsProps} from '@/modules/recommendations/components/Recommendations';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useAppDispatch} from '@/store';
import {setDismissedRecommendationsMfa} from '@/store/reducers/app';
import {useTranslate} from '@tolgee/react';
import {useRouter} from 'expo-router';

export function MfaRecommendationCard({type}: RecommendationsProps) {
  const {t} = useTranslate();
  const router = useRouter();
  const posthog = usePostHog();
  const dispatch = useAppDispatch();

  const onDismiss = () => {
    dispatch(setDismissedRecommendationsMfa(true));
  };

  return (
    <BaseRecommendationCard
      title={t('RECOMMENDATION.MFA.TITLE')}
      description={t('RECOMMENDATION.MFA.DESCRIPTION')}
      onPress={() => {
        posthog?.capture('recommendation_mfa_click');
        router.navigate('/profile/security');
      }}
      onDismiss={onDismiss}
      iconPath={require('@/assets/images/recommendation-mfa.png')}
      type={type}
    />
  );
}
