import {styled, View} from 'tamagui';

export const Divider = styled(View, {
  backgroundColor: '$neutral300',
  opacity: 0.3,
  height: 1,
  width: '100%',
  variants: {
    vertical: {
      true: {
        width: 1,
        height: '100%',
      },
    },
  } as const,
});
