import '@expo/metro-runtime';
import {registerRootComponent} from 'expo';
import {ExpoRoot} from 'expo-router';
import {setupDev} from 'tamagui';
import './tamagui-web.css';

import {LoadSkiaWeb} from '@shopify/react-native-skia/lib/module/web';

if (__DEV__) {
  setupDev({
    visualizer: true,
  });
}

/* WORKAROUND: https://github.com/software-mansion/react-native-reanimated/issues/6740 */
global._WORKLET = false;
// @ts-expect-error
global._log = console.log;
// @ts-expect-error
global._getAnimationTimestamp = () => performance.now();

export function App() {
  // @ts-ignore
  const ctx = require.context('./src/app');
  return <ExpoRoot context={ctx} />;
}

LoadSkiaWeb({locateFile: () => '/canvaskit.wasm'}).then(async () => {
  registerRootComponent(App);
});
