import {useRouter} from 'expo-router';
import {useCallback} from 'react';

import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {SingleAnswer} from '@/modules/interview/components/SingleAnswer';
import {useOnboardingSearchParams} from '@/modules/onboarding/hooks/useOnboardingSearchParams';
import {useAppDispatch} from '@/store';
import {updateInterview} from '@/store/reducers/interview';

const PREF_BY_USER_CODE = 'SUSTAIN_PREF_YES_SELF';

export default function SustainPrefPage() {
  const router = useRouter();
  const params = useOnboardingSearchParams();

  const dispatch = useAppDispatch();

  const handleSubmit = useCallback(
    (code: string) => {
      if (code !== PREF_BY_USER_CODE) {
        dispatch(
          updateInterview({
            SUSTAIN_LEVEL: undefined,
            SUSTAIN_PERCENTAGE: undefined,
            SUSTAIN_FACTOR: undefined,
          })
        );
      }

      router.navigate({
        pathname:
          code === PREF_BY_USER_CODE
            ? '/interview/question/sustain-level'
            : '/interview/question/inputs',
        params,
      });
    },
    [dispatch, params, router]
  );

  return (
    <FullscreenScrollView variant="small">
      <SingleAnswer questionCode="SUSTAIN_PREF" onSubmit={handleSubmit} />
    </FullscreenScrollView>
  );
}
