import {ThemesDepositCard} from '@/modules/orders/components/Theme/ThemeDepositCard';
import {useGetThemesFromAllocation} from '@/modules/orders/hooks/useGetThemesFromAllocation';
import {useOrderContext} from '@/modules/orders/hooks/useOrderContext';
import {CustomerAllocationTheme} from '@/modules/orders/types/CustomerAllocationTheme';
import {CustomThemeDistribution} from '@/modules/orders/types/CustomThemeDistribution';
import {useGetPerformanceSummaryQuery} from '@/store/queries/apeironApi';
import {ThemeDepositDistribution} from '@/types/api/customers.v2';
import {useTranslate} from '@tolgee/react';
import {useCallback, useEffect, useState} from 'react';

type ThemeDepositProps = {amount: number; customerId: string};
export function ThemeDeposit({amount, customerId}: ThemeDepositProps) {
  const {t} = useTranslate();
  const {setThemeDepositDistribution} = useOrderContext();
  const [themes, setThemes] = useState<CustomerAllocationTheme[]>([]);
  const {data: performanceSummary} = useGetPerformanceSummaryQuery({customerId});
  const initialThemes = useGetThemesFromAllocation(customerId);
  const sum = themes.reduce((sum, theme) => sum + theme.ratio, 0);

  useEffect(() => {
    const distribution: CustomThemeDistribution<ThemeDepositDistribution> = {};
    themes.forEach(({code, amount}) => {
      if (amount === 0) return;
      distribution[code] = {amount};
    });
    setThemeDepositDistribution(distribution);
  }, [setThemeDepositDistribution, themes]);

  const onEqualize = useCallback(
    (thms?: CustomerAllocationTheme[]) => {
      setThemes(themes => {
        const thmsToEqualize = thms || themes;
        const ratio = Math.ceil(100 / thmsToEqualize.length);
        const indexToSplit = thmsToEqualize.length + 100 - ratio * thmsToEqualize.length;
        return thmsToEqualize.map((element, index) => {
          const dynamicRatio = index >= indexToSplit ? ratio - 1 : ratio;

          element.ratio = dynamicRatio;
          element.amount = (dynamicRatio * amount) / 100;
          return element;
        });
      });
    },
    [amount]
  );

  useEffect(() => {
    if (initialThemes) {
      onEqualize(initialThemes);
    }
  }, [initialThemes, onEqualize]);

  const onThemesSelected = useCallback(
    (selectedThemes: CustomerAllocationTheme[]) => {
      onEqualize(themes.concat(selectedThemes));
    },
    [onEqualize, themes]
  );
  const onThemeRemoved = useCallback(
    (code: string) => {
      onEqualize(themes.filter(theme => theme.code !== code));
    },
    [onEqualize, themes]
  );
  const onThemeChanged = useCallback(
    (code: string, ratio: number) => {
      setThemes((prevThemes: CustomerAllocationTheme[]) =>
        prevThemes.map(theme =>
          theme.code === code
            ? {
                ...theme,
                ratio,
                amount: Math.round((Math.round(ratio) * amount) / 100),
              }
            : theme
        )
      );
    },
    [amount]
  );

  return (
    <ThemesDepositCard
      loading={!!performanceSummary?.allocation}
      themes={themes}
      initialThemes={initialThemes}
      onThemesSelected={onThemesSelected}
      onThemeRemoved={onThemeRemoved}
      onThemeChanged={onThemeChanged}
      onEqualize={onEqualize}
      sum={sum}
    />
  );
}
