import {useTranslate} from '@tolgee/react';
import {router} from 'expo-router';
import React, {useMemo} from 'react';
import {useMedia, View, XStack, YStack} from 'tamagui';

import {Button} from '@/components/buttons/Button';
import {ProductBadge} from '@/components/buttons/ProductBadge';
import {Card} from '@/components/cards/Card';
import SvgFile, {SvgName} from '@/components/icons/SvgFile';
import {Label4} from '@/components/texts/Label';
import {Paragraph2} from '@/components/texts/Paragraph';
import {StatusBadge} from '@/components/texts/StatusBadge';
import {RejectedOrderModal} from '@/modules/accounts/components/RejectedOrderModal';
import {IdentityVerificationModal} from '@/modules/overview/modals/IdentityVerificationModal';
import {ChildAccountCreationOrderResponse, OrderResponse} from '@/types/api/orders';

const REJECTED_ACCOUNTS = new Set(['REJECTED', 'CANCELED']);

const IDENTIFY_ACTIONS = new Set([
  'IDENTIFICATION_REQUIRED',
  'SECOND_GUARDIAN_IDENTIFICATION_REQUIRED',
]);

const POR_ACTIONS = new Set([
  'PROOF_OF_RESIDENCE_REQUIRED',
  'SECOND_GUARDIAN_PROOF_OF_RESIDENCE_REQUIRED',
]);

const SECOND_GUARDIAN_ACTIONS = new Set([
  'SECOND_GUARDIAN_CONFIRMATION_REQUIRED',
  'SECOND_GUARDIAN_IDENTIFICATION_REQUIRED',
  'SECOND_GUARDIAN_PROOF_OF_RESIDENCE_REQUIRED',
]);

type Props = {
  userId: number;
  order: OrderResponse;
};

function isChildAccountOrder(order: OrderResponse): order is ChildAccountCreationOrderResponse {
  return order.type === 'CHILD_ACCOUNT_CREATION_ORDER';
}

export const AccountOrderCard = ({userId, order}: Props) => {
  const {t} = useTranslate();
  const media = useMedia();

  const firstGuardian =
    isChildAccountOrder(order) && order.firstGuardianUserId
      ? order.firstGuardianUserId === userId.toString()
      : false;

  const isRejected = useMemo(
    () =>
      REJECTED_ACCOUNTS.has(order.status) ||
      (isChildAccountOrder(order) && !!order.declinedBySecondGuardian),
    [order]
  );

  const isIdentify = useMemo(
    () => order.requiredAction && IDENTIFY_ACTIONS.has(order.requiredAction),
    [order.requiredAction]
  );

  const isPOR = useMemo(
    () => order.requiredAction && POR_ACTIONS.has(order.requiredAction),
    [order.requiredAction]
  );

  const isInProgress = useMemo(
    () =>
      !order.requiredAction ||
      (firstGuardian && SECOND_GUARDIAN_ACTIONS.has(order.requiredAction)) ||
      (order.requiredAction === 'SECOND_GUARDIAN_CONFIRMATION_REQUIRED' &&
        isChildAccountOrder(order) &&
        !!order.confirmedBySecondGuardian),
    [firstGuardian, order]
  );

  const chipTextAndColorVariant = useMemo(() => {
    if (firstGuardian && order.requiredAction === 'SECOND_GUARDIAN_CONFIRMATION_REQUIRED') {
      // TODO: color will be changed
      return {
        colorVariant: 'positive',
        chipText: t('HOME.ACCOUNT_STATUS.WAIT_SECOND_GUARDIAN'),
      };
    }

    if (isInProgress) {
      return {
        colorVariant: 'positive',
        chipText: t('HOME.ACCOUNT_STATUS.IN_PROGRESS'),
      };
    }

    if (isIdentify) {
      return {
        colorVariant: 'warning',
        chipText: t('HOME.ACCOUNT_STATUS.VERIFICATION_REQUIRED'),
      };
    }

    if (isPOR) {
      return {
        colorVariant: 'warning',
        chipText: t('HOME.ACCOUNT_STATUS.DOCUMENTS_REQUIRED'),
      };
    }

    return {
      colorVariant: 'warning',
      chipText: t('HOME.ACCOUNT_STATUS.INFO_REQUIRED'),
    };
  }, [firstGuardian, isIdentify, isInProgress, isPOR, order.requiredAction, t]);

  const renderStatus = useMemo(() => {
    let status = order.status;

    if (isRejected) {
      status = 'REJECTED';
    }

    switch (status) {
      case 'CANCELED':
      case 'REJECTED':
        return {
          colorVariant: 'destructive',
          status: t('HOME.ACCOUNT_STATUS.REJECTED'),
        };
      default:
        const {colorVariant, chipText} = chipTextAndColorVariant;
        return {
          colorVariant,
          status: chipText,
        };
    }
  }, [chipTextAndColorVariant, isRejected, order.status, t]);

  const {colorVariant, status} = renderStatus;

  const renderActionButton = useMemo(() => {
    if (!order.requiredAction || isRejected || isInProgress) return null;

    let content = null;

    if (!firstGuardian && order.requiredAction === 'SECOND_GUARDIAN_CONFIRMATION_REQUIRED') {
      content = (
        <Button
          onPress={() => {
            router.navigate({
              pathname: '/onboarding/personal-details',
              params: {
                orderId: order.id,
                product: 'CHILD_ACCOUNT',
                onboardingType: 'second_guardian',
              },
            });
          }}
        >
          {t('HOME.ACCOUNT_STATUS.COMPLETE_SIGNUP')}
        </Button>
      );
    } else if (isIdentify) {
      content = (
        <IdentityVerificationModal>
          <Button testID="verify-identity-button">{t('HOME.VERIFICATION.VERIFY_IDENTITY')}</Button>
        </IdentityVerificationModal>
      );
    } else {
      content = (
        <Button
          onPress={() => {
            router.navigate('/(user)/proof-of-residence');
          }}
        >
          {t('HOME.ACCOUNT_STATUS.UPLOAD_DOCUMENT')}
        </Button>
      );
    }

    if (!content) return null;

    return (
      <View alignSelf="flex-end" marginTop="$2" width={media.sm ? '100%' : 'auto'}>
        {content}
      </View>
    );
  }, [
    order.requiredAction,
    order.id,
    isRejected,
    isInProgress,
    firstGuardian,
    isIdentify,
    media.sm,
    t,
  ]);

  const renderTip = useMemo(() => {
    if (!order.requiredAction || isRejected || isInProgress) return null;

    let content = null;

    if (isIdentify) {
      content = t('HOME.VERIFICATION.VERIFY_IDENTITY.TIP');
    } else if (isPOR) {
      content = t('HOME.ACCOUNT_STATUS.UPLOAD_DOCUMENT.TIP');
    }

    if (!content) return null;

    return (
      <XStack alignItems="flex-start" gap="$2" alignSelf={media.sm ? 'flex-start' : 'flex-end'}>
        <SvgFile size={15} name="info" color="$primary" />
        <Label4 color="$neutral400" variant="medium" flexShrink={1}>
          {content}
        </Label4>
      </XStack>
    );
  }, [order.requiredAction, isRejected, isInProgress, isIdentify, isPOR, media.sm, t]);

  const icon: SvgName = useMemo(() => {
    if (isRejected) {
      return 'type-rejected';
    }
    if (isInProgress) {
      return 'clock';
    }
    return 'id-card';
  }, [isInProgress, isRejected]);

  return (
    <Card gap="$4">
      <View
        justifyContent="space-between"
        alignItems="center"
        gap="$4"
        flexDirection={media.sm ? 'column' : 'row'}
      >
        <YStack gap="$1" alignSelf="flex-start" $sm={{width: '100%'}}>
          {isRejected ? (
            <RejectedOrderModal>{order.account.displayName}</RejectedOrderModal>
          ) : (
            <Paragraph2>{order.account.displayName}</Paragraph2>
          )}
          <XStack>
            <ProductBadge product={order.account.product} />
          </XStack>
        </YStack>
        <YStack gap="$3" alignSelf="flex-start" $sm={{width: '100%'}}>
          <XStack
            justifyContent="space-between"
            gap="$1"
            $sm={{width: '100%'}}
            alignSelf="flex-end"
          >
            {media.sm && <Paragraph2>{t('HOME.ACCOUNT_STATUS.STATUS')}</Paragraph2>}
            <StatusBadge colorVariant={colorVariant} status={status} icon={icon} />
          </XStack>
          {renderActionButton}
        </YStack>
      </View>
      {renderTip}
    </Card>
  );
};
