import {isStoryBookEnabled} from '@/constants/storybook';
import {StorybookScreen} from '@/modules/app/components/StorybookScreen';
import {Redirect} from 'expo-router';

export default function Storybook() {
  if (isStoryBookEnabled) {
    return <StorybookScreen />;
  }

  return <Redirect href="/" />;
}
