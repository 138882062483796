import {useFocusEffect, useGlobalSearchParams, useRouter} from 'expo-router';
import {useCallback} from 'react';

import {AnimatedSplashLoader} from '@/components/views/AnimatedSplashLoader';
import {useIsUserComplete} from '@/hooks/useIsUserComplete';
import {useTokenUserInfo} from '@/hooks/useTokenUserInfo';
import {useProductChoiceGuard} from '@/modules/onboarding/hooks/useProductChoiceGuard';
import {useGetValidOrdersQuery} from '@/store/queries/ordersApi';
import {ShortenedCustomerInformationResponse} from '@/types/api/customers.v2';

const UserScreen = () => {
  const router = useRouter();

  const {product: productSearchParams} = useGlobalSearchParams<{
    product?: ShortenedCustomerInformationResponse['product'];
  }>();
  const {isUserComplete, isFetching} = useIsUserComplete();
  const userInfo = useTokenUserInfo();
  const {result: productChoiceGuard, isFetching: productChoiceFetching} = useProductChoiceGuard();
  const {data: orders, isFetching: ordersFetching} = useGetValidOrdersQuery();

  const initialNavigation = useCallback(() => {
    if (isFetching || ordersFetching) return;
    if (!userInfo || productChoiceFetching) return;
    const proposal = userInfo?.proposal && JSON.parse(atob(userInfo?.proposal));
    const product = productSearchParams ?? proposal?.product;

    if (!userInfo.email_verified) {
      router.replace({
        pathname: '/(visitor)/emailverify/start',
      });

      return;
    }

    if (isUserComplete || (orders && orders?.items.length > 0)) {
      router.replace('/overview');

      return;
    }

    if (productChoiceGuard || !product) {
      router.navigate('/(visitor)/interview/product-choice');

      return;
    }

    if (proposal) {
      router.navigate({pathname: '/onboarding/proposal', params: {product}});

      return;
    }

    router.navigate({pathname: '/onboarding/personal-details', params: {product}});
  }, [
    isFetching,
    isUserComplete,
    orders,
    userInfo,
    productChoiceFetching,
    ordersFetching,
    productSearchParams,
    productChoiceGuard,
    router,
  ]);

  useFocusEffect(initialNavigation);

  return <AnimatedSplashLoader viewKey="user-loader" />;
};

export default UserScreen;
