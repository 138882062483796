import {Label3} from '@/components/texts/Label';
import {captureException} from '@sentry/react-native';
import {useTranslate} from '@tolgee/react';
import {Linking} from 'react-native';
import {View} from 'tamagui';

export const NotValidMfaMethodDesc = () => {
  const {t} = useTranslate();

  const handlePressedOnServiceText = () => {
    Linking.openURL('mailto:service@ginmon.de').catch(captureException);
  };

  return (
    <View>
      <Label3 color="$neutral500">
        {t('MFA.NOT_VALID_PHONE_DESC_1')}
        {/* TODO: we need some link text component with common style */}
        <Label3
          cursor="pointer"
          color="$primary"
          variant="underline"
          onPress={handlePressedOnServiceText}
          hoverStyle={{opacity: 0.75}}
        >
          service@ginmon.de
        </Label3>
        {t('MFA.NOT_VALID_PHONE_DESC_2')}
      </Label3>
    </View>
  );
};
