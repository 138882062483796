import {createContext, PropsWithChildren, useContext} from 'react';

import {INITIAL_DOMAIN, INITIAL_MIN_MAX, INITIAL_OFFSET_DATA, INITIAL_SIZE} from '@/constants/skia';
import {ChartContext as ChartContextType} from '@/types/skia';
import {useTheme} from 'tamagui';

const mockFunc = () => {
  throw new Error('Function is not implemented');
};

const ChartContext = createContext<ChartContextType>({
  size: INITIAL_SIZE,
  offsetData: INITIAL_OFFSET_DATA,
  domain: INITIAL_DOMAIN,
  linesDomain: INITIAL_DOMAIN,
  minMaxX: INITIAL_MIN_MAX,
  xAxis: {},
  yAxis: {},
  lang: 'de',
  updateAxis: mockFunc,
  updateLineDomain: mockFunc,
  updateOffsetData: mockFunc,
  updateSelectedPoint: mockFunc,
  theme: {} as ReturnType<typeof useTheme>,
});

type Props = {
  value: ChartContextType;
};

export const useChartContext = () => {
  const context = useContext(ChartContext);

  if (!context) {
    throw new Error('ChartContext must be defined');
  }

  return context;
};

export const ChartContextProvider = ({children, value}: PropsWithChildren<Props>) => (
  <ChartContext.Provider value={value}>{children}</ChartContext.Provider>
);
