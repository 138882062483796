import {useTranslate} from '@tolgee/react';
import {YStack} from 'tamagui';

import {Heading6} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';

export const VLInvestTipModalContent = () => {
  const {t} = useTranslate();

  return (
    <YStack gap="$5" marginVertical="$5">
      <Heading6 variant="medium">{t('INVESTMENT_PLANNER.VL_SAVINGS_CALC.TITLE')}</Heading6>
      <Paragraph2 color="$neutral400">{t('INVESTMENT_PLANNER.VL_SAVINGS_CALC.DESC')}</Paragraph2>
    </YStack>
  );
};
