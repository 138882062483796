import {MINUTE, baseApi} from '@/store/queries/baseApi';
import {
  AddressChangeOrderRequest,
  DepositOrderRequest,
  EssentialCustomerInformationListResponse,
  FullWithdrawalOrderRequest,
  GetCustomerResponse,
  InboxDocumentListResponse,
  InboxDocumentSummaryResponse,
  InternalTransferOrderRequest,
  OrderConstraintsResponse,
  OrderListResponse,
  OrderResponse,
  OrderSummaryResponse,
  RecurringDepositChangeOrderRequest,
  ReferenceAccountChangeOrderRequest,
  StrategyChangeOrderRequest,
  TaxExemptionOrderRequest,
  ThemeDepositOrderRequest,
  ThemeWithdrawalOrderRequest,
  WireDepositOrderRequest,
  WithdrawalOrderRequest,
} from '@/types/api/customers.v2';

const BASE_PATH = '/customers/v2';

const extendedCustomerApi = baseApi.injectEndpoints({
  endpoints: build => ({
    getCustomerAccount: build.query<GetCustomerResponse, string>({
      query: customerId => {
        return {url: `${BASE_PATH}/${customerId}`};
      },
      providesTags: ['CUSTOMERS'],
      keepUnusedDataFor: 5 * MINUTE,
    }),
    getCustomerAccounts: build.query<Required<EssentialCustomerInformationListResponse>, void>({
      // Required<> is used until openapi fixed
      query: () => ({
        url: BASE_PATH,
        params: {
          verbosity: 'ESSENTIAL_DATA',
        },
      }),
      providesTags: ['CUSTOMERS'],
      keepUnusedDataFor: 5 * MINUTE,
    }),
    getCustomerOrders: build.query<OrderListResponse, OrdersReqParams>({
      query: arg => {
        const {customerId, offset, limit, category} = arg;
        const params = {
          limit,
          offset,
          ...(category === 'ALL' ? {} : {orderType: category}),
        };
        return {url: `${BASE_PATH}/${customerId}/orders`, params};
      },
      providesTags: ['CUSTOMERS', 'CUSTOMERS_ORDERS'],
      keepUnusedDataFor: 5 * MINUTE,
    }),
    getCustomerOrdersSummary: build.query<OrderSummaryResponse, {customerId: string}>({
      query: arg => {
        const {customerId} = arg;
        return {url: `${BASE_PATH}/${customerId}/orders-summary`};
      },
      providesTags: ['CUSTOMERS', 'CUSTOMERS_ORDERS'],
      keepUnusedDataFor: 5 * MINUTE,
    }),
    postCustomerOrder: build.mutation<OrderResponse, OrderRequestParams>({
      query: ({customerId, ...body}) => ({
        url: `${BASE_PATH}/${customerId}/orders`,
        method: 'POST',
        body,
        headers: {'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['CUSTOMERS', 'CUSTOMERS_ORDERS'],
    }),
    getCustomerInbox: build.query<InboxDocumentListResponse, InboxReqParams>({
      query: arg => {
        const {customerId, offset, limit, category, sourceFilter} = arg;
        let params: {limit: number; offset: number; category?: string; source?: string} = {
          offset,
          limit,
        };
        if (category !== 'ALL') params = {...params, category};
        if (sourceFilter !== 'ALL') params = {...params, source: sourceFilter};
        return {
          url: `${BASE_PATH}/${customerId}/inbox-documents`,
          params,
        };
      },
      providesTags: ['CUSTOMERS'],
      keepUnusedDataFor: 5 * MINUTE,
    }),
    getCustomerInboxSummaries: build.query<InboxDocumentSummaryResponse, string>({
      query: customerId => {
        const params = new URLSearchParams();
        params.append('customerId', customerId);

        return {
          url: `${BASE_PATH}/${customerId}/inbox-documents-summary`,
          params,
        };
      },
      providesTags: ['CUSTOMERS'],
      keepUnusedDataFor: 5 * MINUTE,
    }),
    getCustomerOrderConstraints: build.query<OrderConstraintsResponse, string>({
      query: customerId => ({
        url: `${BASE_PATH}/${customerId}/order-constraints`,
      }),
      keepUnusedDataFor: 5 * MINUTE,
      providesTags: ['CUSTOMERS_ORDERS'],
    }),
    patchDisplayName: build.mutation<
      GetCustomerResponse,
      {customerId: string; displayName: string}
    >({
      query: ({customerId, displayName}) => ({
        url: `${BASE_PATH}/${customerId}`,
        method: 'PATCH',
        body: JSON.stringify([
          {
            path: '/displayName',
            op: 'replace',
            value: displayName,
          },
        ]),
        headers: {'Content-Type': 'application/json-patch+json'},
      }),
      invalidatesTags: ['CUSTOMERS'],
    }),
  }),
  overrideExisting: false,
});

export type OrdersReqParams = {
  customerId: string;
  offset: number;
  limit: number;
  category: string;
};

export type InboxReqParams = OrdersReqParams & {
  sourceFilter: string;
};

export enum DocumentSource {
  GINMON = 'GINMON',
  DAB = 'DAB',
  UPVEST = 'UPVEST',
  ALL = 'ALL',
}

export type OrderRequestParams = (
  | AddressChangeOrderRequest
  | DepositOrderRequest
  | FullWithdrawalOrderRequest
  | InternalTransferOrderRequest
  | RecurringDepositChangeOrderRequest
  | ReferenceAccountChangeOrderRequest
  | StrategyChangeOrderRequest
  | TaxExemptionOrderRequest
  | ThemeDepositOrderRequest
  | ThemeWithdrawalOrderRequest
  | WireDepositOrderRequest
  | WithdrawalOrderRequest
) & {
  customerId: string;
};

export const {
  useGetCustomerAccountsQuery,
  useGetCustomerAccountQuery,
  useGetCustomerOrdersQuery,
  useGetCustomerInboxQuery,
  useGetCustomerOrderConstraintsQuery,
  usePostCustomerOrderMutation,
  useGetCustomerInboxSummariesQuery,
  useGetCustomerOrdersSummaryQuery,
  usePatchDisplayNameMutation,
} = extendedCustomerApi;
