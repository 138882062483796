import {AnimatedSplashLoader} from '@/components/views/AnimatedSplashLoader';
import {captureException} from '@sentry/react-native';
import * as Linking from 'expo-linking';
import {Href, useLocalSearchParams, useRouter} from 'expo-router';
import {useCallback, useEffect} from 'react';

export default function ConfirmPage() {
  const {c} = useLocalSearchParams();
  const router = useRouter();

  const verify = useCallback(async () => {
    try {
      const res = await fetch(`${process.env.EXPO_PUBLIC_EMAIL_VERIFY_PATH}confirm?c=${c}`);
      if (Linking.parse(res.url).path) router.replace(Linking.parse(res.url).path as Href);
      else router.replace('/');
    } catch (e) {
      captureException(e);
      router.replace('/');
    }
  }, [c, router]);

  useEffect(() => {
    verify();
  }, [verify]);

  return <AnimatedSplashLoader viewKey="email-confirm-loader" />;
}
