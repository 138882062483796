import {Button} from '@/components/buttons/Button';
import {useTranslate} from '@tolgee/react';
import React from 'react';
import {GetProps} from 'tamagui';

export function InterviewBackButton({onPress}: {onPress: GetProps<typeof Button>['onPress']}) {
  const {t} = useTranslate();

  return (
    <>
      <Button alignSelf="stretch" onPress={onPress} secondary>
        {t('BACK')}
      </Button>
    </>
  );
}
