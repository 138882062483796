import {Button} from '@/components/buttons/Button';
import {Card} from '@/components/cards/Card';
import {Heading5} from '@/components/texts/Heading';
import {Label2} from '@/components/texts/Label';
import {Paragraph3} from '@/components/texts/Paragraph';
import {useAppDispatch, useAppSelector} from '@/store';
import {useGetCustomerAccountsQuery} from '@/store/queries/customersApi';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';
import {updateInterview} from '@/store/reducers/interview';
import {useTranslate} from '@tolgee/react';
import {router, useLocalSearchParams} from 'expo-router';
import React, {useEffect, useMemo, useState} from 'react';
import {Linking} from 'react-native';
import {TextArea, XStack, YStack} from 'tamagui';

type WithdrawalReasonBoxProps = {
  onConfirm: () => void;
};

export function WithdrawalReasonStrategyChange({onConfirm}: WithdrawalReasonBoxProps) {
  const {t} = useTranslate();
  const locale = useAppSelector(state => state.app.language);
  const {customerId} = useLocalSearchParams<{customerId: string}>();
  const {data: user} = useGetSelfUserQuery();
  const dispatch = useAppDispatch();
  const {data: customers} = useGetCustomerAccountsQuery();
  const hasThemeAccount = useMemo(() => {
    return customers?.items.some(item => item.product === 'THEME_ACCOUNT');
  }, [customers]);
  const hasSavingAccount = useMemo(() => {
    return customers?.items.some(item => item.product === 'SAVINGS_ACCOUNT');
  }, [customers]);

  return (
    <Card gap="$4" padding="$4">
      <Heading5 variant="medium">
        {t('ORDER_SUMMARY.WITHDRAWAL-REASON.STRATEGY-CHANGE.TITLE')}
      </Heading5>
      <Label2 variant="medium">
        {t('ORDER_SUMMARY.WITHDRAWAL-REASON.STRATEGY-CHANGE.TEXT1-1')}
      </Label2>
      <Paragraph3>{t('ORDER_SUMMARY.WITHDRAWAL-REASON.STRATEGY-CHANGE.TEXT1-2')}</Paragraph3>
      <Button
        onPress={() =>
          Linking.openURL(
            locale === 'de'
              ? process.env.EXPO_PUBLIC_MAILTO_CALENDLY_DE
              : process.env.EXPO_PUBLIC_MAILTO_CALENDLY_EN
          )
        }
      >
        {t('ORDER_SUMMARY.WITHDRAWAL-REASON.STRATEGY-CHANGE.TEXT1-BUTTON')}
      </Button>
      <Label2 variant="medium">
        {t('ORDER_SUMMARY.WITHDRAWAL-REASON.STRATEGY-CHANGE.TEXT2-1')}
      </Label2>
      <Paragraph3>{t('ORDER_SUMMARY.WITHDRAWAL-REASON.STRATEGY-CHANGE.TEXT2-2')}</Paragraph3>
      <XStack gap="$2" flexWrap="wrap">
        <Button
          onPress={() => {
            router.navigate({
              pathname: '/overview/[customerId]/order/change-strategy',
              params: {
                customerId,
              },
            });
          }}
        >
          {t('ORDER_SUMMARY.WITHDRAWAL-REASON.STRATEGY-CHANGE.TEXT2-BUTTON1')}
        </Button>
        {!hasSavingAccount && (
          <Button
            secondary
            onPress={() => {
              router.navigate({
                pathname: `/interview/question`,
                params: {product: 'SAVINGS_ACCOUNT'},
              });
              dispatch(
                updateInterview({type: 'BASIC_V2', userId: user?.id ? String(user?.id) : undefined})
              );
            }}
          >
            {t('ORDER_SUMMARY.WITHDRAWAL-REASON.STRATEGY-CHANGE.TEXT2-BUTTON2')}
          </Button>
        )}
      </XStack>

      <Label2 variant="medium">
        {t('ORDER_SUMMARY.WITHDRAWAL-REASON.STRATEGY-CHANGE.TEXT3-1')}
      </Label2>
      <Paragraph3>{t('ORDER_SUMMARY.WITHDRAWAL-REASON.STRATEGY-CHANGE.TEXT3-2')}</Paragraph3>
      <XStack gap="$2" flexWrap="wrap">
        <Button
          onPress={() => {
            router.navigate({
              pathname: '/overview/[customerId]/order/change-strategy',
              params: {
                customerId,
              },
            });
          }}
        >
          {t('ORDER_SUMMARY.WITHDRAWAL-REASON.STRATEGY-CHANGE.TEXT3-BUTTON1')}
        </Button>
        {!hasThemeAccount && (
          <Button
            secondary
            onPress={() => {
              router.navigate({
                pathname: `/interview/question`,
                params: {product: 'THEME_ACCOUNT'},
              });
              dispatch(
                updateInterview({type: 'BASIC_V2', userId: user?.id ? String(user?.id) : undefined})
              );
            }}
          >
            {t('ORDER_SUMMARY.WITHDRAWAL-REASON.STRATEGY-CHANGE.TEXT3-BUTTON2')}
          </Button>
        )}
      </XStack>

      <Label2 variant="medium">
        {t('ORDER_SUMMARY.WITHDRAWAL-REASON.STRATEGY-CHANGE.TEXT4-1')}
      </Label2>
      <Paragraph3>{t('ORDER_SUMMARY.WITHDRAWAL-REASON.STRATEGY-CHANGE.TEXT4-2')}</Paragraph3>
      <Button secondary onPress={onConfirm}>
        {t('ORDER_SUMMARY.WITHDRAWAL-REASON.STRATEGY-CHANGE.TEXT4-BUTTON')}
      </Button>
    </Card>
  );
}

export function WithdrawalReasonPerformance({onConfirm}: WithdrawalReasonBoxProps) {
  const {t} = useTranslate();
  const {customerId} = useLocalSearchParams<{customerId: string}>();
  const {data: user} = useGetSelfUserQuery();
  const dispatch = useAppDispatch();
  const {data: customers} = useGetCustomerAccountsQuery();
  const hasThemeAccount = useMemo(() => {
    return customers?.items.some(item => item.product === 'THEME_ACCOUNT');
  }, [customers]);
  const hasSavingAccount = useMemo(() => {
    return customers?.items.some(item => item.product === 'SAVINGS_ACCOUNT');
  }, [customers]);

  return (
    <Card gap="$4" padding="$4">
      <Heading5 variant="medium">{t('ORDER_SUMMARY.WITHDRAWAL-REASON.PERFORMANCE.TITLE')}</Heading5>

      <Label2 variant="medium">{t('ORDER_SUMMARY.WITHDRAWAL-REASON.PERFORMANCE.TEXT2-1')}</Label2>
      <Paragraph3>{t('ORDER_SUMMARY.WITHDRAWAL-REASON.PERFORMANCE.TEXT2-2')}</Paragraph3>
      <XStack gap="$2" flexWrap="wrap">
        <Button
          onPress={() => {
            router.navigate({
              pathname: '/overview/[customerId]/order/change-strategy',
              params: {
                customerId,
              },
            });
          }}
        >
          {t('ORDER_SUMMARY.WITHDRAWAL-REASON.PERFORMANCE.TEXT2-BUTTON1')}
        </Button>
        {!hasThemeAccount && (
          <Button
            secondary
            onPress={() => {
              router.navigate({
                pathname: `/interview/question`,
                params: {product: 'THEME_ACCOUNT'},
              });
              dispatch(
                updateInterview({type: 'BASIC_V2', userId: user?.id ? String(user?.id) : undefined})
              );
            }}
          >
            {t('ORDER_SUMMARY.WITHDRAWAL-REASON.PERFORMANCE.TEXT2-BUTTON2')}
          </Button>
        )}
      </XStack>

      <Label2 variant="medium">{t('ORDER_SUMMARY.WITHDRAWAL-REASON.PERFORMANCE.TEXT3-1')}</Label2>
      <Paragraph3>{t('ORDER_SUMMARY.WITHDRAWAL-REASON.PERFORMANCE.TEXT3-2')}</Paragraph3>
      <XStack gap="$2" flexWrap="wrap">
        <Button
          onPress={() => {
            router.navigate({
              pathname: '/overview/[customerId]/order/change-strategy',
              params: {
                customerId,
              },
            });
          }}
        >
          {t('ORDER_SUMMARY.WITHDRAWAL-REASON.PERFORMANCE.TEXT3-BUTTON1')}
        </Button>
        {!hasSavingAccount && (
          <Button
            secondary
            onPress={() => {
              router.navigate({
                pathname: `/interview/question`,
                params: {product: 'SAVINGS_ACCOUNT'},
              });
              dispatch(
                updateInterview({type: 'BASIC_V2', userId: user?.id ? String(user?.id) : undefined})
              );
            }}
          >
            {t('ORDER_SUMMARY.WITHDRAWAL-REASON.PERFORMANCE.TEXT3-BUTTON2')}
          </Button>
        )}
      </XStack>

      <Label2 variant="medium">{t('ORDER_SUMMARY.WITHDRAWAL-REASON.PERFORMANCE.TEXT4-1')}</Label2>
      <Paragraph3>{t('ORDER_SUMMARY.WITHDRAWAL-REASON.PERFORMANCE.TEXT4-2')}</Paragraph3>
      <Button secondary onPress={onConfirm}>
        {t('ORDER_SUMMARY.WITHDRAWAL-REASON.PERFORMANCE.TEXT4-BUTTON')}
      </Button>
    </Card>
  );
}

export function WithdrawalReasonNeedMoney({onConfirm}: WithdrawalReasonBoxProps) {
  const {t} = useTranslate();
  const locale = useAppSelector(state => state.app.language);
  return (
    <Card gap="$4" padding="$4">
      <Heading5 variant="medium">{t('ORDER_SUMMARY.WITHDRAWAL-REASON.NEED-MONEY.TITLE')}</Heading5>

      <Label2 variant="medium">{t('ORDER_SUMMARY.WITHDRAWAL-REASON.NEED-MONEY.TEXT1-1')}</Label2>
      <Paragraph3>{t('ORDER_SUMMARY.WITHDRAWAL-REASON.NEED-MONEY.TEXT1-2')}</Paragraph3>

      <Label2 variant="medium">{t('ORDER_SUMMARY.WITHDRAWAL-REASON.NEED-MONEY.TEXT2-1')}</Label2>
      <Paragraph3>{t('ORDER_SUMMARY.WITHDRAWAL-REASON.NEED-MONEY.TEXT2-2')}</Paragraph3>

      <Label2 variant="medium">{t('ORDER_SUMMARY.WITHDRAWAL-REASON.NEED-MONEY.TEXT3-1')}</Label2>
      <Paragraph3>{t('ORDER_SUMMARY.WITHDRAWAL-REASON.NEED-MONEY.TEXT3-2')}</Paragraph3>
      <XStack gap="$2">
        <Button
          onPress={() =>
            Linking.openURL(
              locale === 'de'
                ? process.env.EXPO_PUBLIC_MAILTO_CALENDLY_DE
                : process.env.EXPO_PUBLIC_MAILTO_CALENDLY_EN
            )
          }
        >
          {t('ORDER_SUMMARY.WITHDRAWAL-REASON.NEED-MONEY.TEXT3-BUTTON1')}
        </Button>
        <Button secondary onPress={onConfirm}>
          {t('ORDER_SUMMARY.WITHDRAWAL-REASON.NEED-MONEY.TEXT3-BUTTON2')}
        </Button>
      </XStack>
    </Card>
  );
}

export function WithdrawalReasonLeave({onConfirm}: WithdrawalReasonBoxProps) {
  const {t} = useTranslate();
  const locale = useAppSelector(state => state.app.language);
  return (
    <Card gap="$4" padding="$4">
      <Heading5 variant="medium">{t('ORDER_SUMMARY.WITHDRAWAL-REASON.LEAVE.TITLE')}</Heading5>

      <Label2 variant="medium">{t('ORDER_SUMMARY.WITHDRAWAL-REASON.LEAVE.TEXT1-1')}</Label2>
      <Paragraph3>{t('ORDER_SUMMARY.WITHDRAWAL-REASON.LEAVE.TEXT1-2')}</Paragraph3>

      <Label2 variant="medium">{t('ORDER_SUMMARY.WITHDRAWAL-REASON.LEAVE.TEXT2-1')}</Label2>
      <Paragraph3>{t('ORDER_SUMMARY.WITHDRAWAL-REASON.LEAVE.TEXT2-2')}</Paragraph3>

      <Label2 variant="medium">{t('ORDER_SUMMARY.WITHDRAWAL-REASON.LEAVE.TEXT3-1')}</Label2>
      <Paragraph3>{t('ORDER_SUMMARY.WITHDRAWAL-REASON.LEAVE.TEXT3-2')}</Paragraph3>
      <XStack gap="$2">
        <Button
          onPress={() =>
            Linking.openURL(
              locale === 'de'
                ? process.env.EXPO_PUBLIC_MAILTO_CALENDLY_DE
                : process.env.EXPO_PUBLIC_MAILTO_CALENDLY_EN
            )
          }
        >
          {t('ORDER_SUMMARY.WITHDRAWAL-REASON.LEAVE.TEXT3-BUTTON1')}
        </Button>
        <Button secondary onPress={onConfirm}>
          {t('ORDER_SUMMARY.WITHDRAWAL-REASON.LEAVE.TEXT3-BUTTON2')}
        </Button>
      </XStack>
    </Card>
  );
}

export function WithdrawalReasonOther({
  onConfirm,
  setOtherReason,
}: WithdrawalReasonBoxProps & {setOtherReason: (feedback?: string) => void}) {
  const {t} = useTranslate();
  const [feedback, setFeedback] = useState<string>();
  const locale = useAppSelector(state => state.app.language);
  useEffect(() => {
    if (!feedback) return;
    setOtherReason(feedback);
  }, [feedback, setOtherReason]);

  return (
    <Card gap="$4" padding="$4">
      <Heading5 variant="medium">{t('ORDER_SUMMARY.WITHDRAWAL-REASON.OTHER.TITLE')}</Heading5>

      <Label2 variant="medium">{t('ORDER_SUMMARY.WITHDRAWAL-REASON.OTHER.TEXT1-1')}</Label2>
      <Paragraph3>{t('ORDER_SUMMARY.WITHDRAWAL-REASON.OTHER.TEXT1-2')}</Paragraph3>

      <YStack gap="$1">
        <Paragraph3 color="$neutral400">
          {t('ORDER_SUMMARY.WITHDRAWAL-REASON.OTHER.TEXT3-1')}
        </Paragraph3>
        <TextArea
          color="$text"
          height={106}
          borderColor="$neutral200"
          borderRadius="$0.5"
          onChangeText={setFeedback}
        />
      </YStack>

      <XStack gap="$2">
        <Button
          onPress={() =>
            Linking.openURL(
              locale === 'de'
                ? process.env.EXPO_PUBLIC_MAILTO_CALENDLY_DE
                : process.env.EXPO_PUBLIC_MAILTO_CALENDLY_EN
            )
          }
        >
          {t('ORDER_SUMMARY.WITHDRAWAL-REASON.OTHER.TEXT3-BUTTON1')}
        </Button>
        <Button secondary onPress={onConfirm}>
          {t('ORDER_SUMMARY.WITHDRAWAL-REASON.OTHER.TEXT3-BUTTON2')}
        </Button>
      </XStack>
    </Card>
  );
}
