import SvgFile from '@/components/icons/SvgFile';
import {Modal} from '@/components/modal/Modal';
import {Label2} from '@/components/texts/Label';
import {Paragraph2} from '@/components/texts/Paragraph';
import {useTranslate} from '@tolgee/react';
import {XStack} from 'tamagui';

export const NationalityDocumentHintModal = () => {
  const {t} = useTranslate();

  return (
    <Modal>
      <Modal.Trigger>
        <XStack
          alignItems="center"
          gap="$2"
          alignSelf="flex-start"
          marginTop="$2"
          cursor="pointer"
          hoverStyle={{opacity: 0.8}}
          pressStyle={{opacity: 0.8}}
        >
          <SvgFile name="info" size={20} color="$primary" />
          <Label2 color="$primary">{t('PERSONAL_DETAILS.NATIONALITY.MODAL.TRIGGER')}</Label2>
        </XStack>
      </Modal.Trigger>
      <Modal.Content side>
        <Modal.Title>{t('PERSONAL_DETAILS.NATIONALITY.MODAL.TITLE')}</Modal.Title>
        <Paragraph2 color="$neutral400">{t('PERSONAL_DETAILS.NATIONALITY.MODAL.TEXT')}</Paragraph2>
        <Modal.IconClose />
      </Modal.Content>
    </Modal>
  );
};
