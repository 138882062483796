import {ThemeIcon} from '@/components/icons/ThemeIcon';
import {Label3} from '@/components/texts/Label';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {useGetThemesFromAllocation} from '@/modules/orders/hooks/useGetThemesFromAllocation';
import {ThemeCode} from '@/modules/portfolio/types/ThemeCode';
import {ThemeWithdrawalDistribution} from '@/types/api/customers.v2';
import {useTranslate} from '@tolgee/react';
import {XStack} from 'tamagui';

type ThemeWithdrawalSummaryRowProps = {
  code: ThemeCode;
  distribution: ThemeWithdrawalDistribution;
  customerId: string;
};

export function ThemeWithdrawalSummaryRow({
  code,
  distribution,
  customerId,
}: ThemeWithdrawalSummaryRowProps) {
  const {t} = useTranslate();
  const themes = useGetThemesFromAllocation(customerId);
  const amount = themes?.find(theme => theme.code === code)?.amount;
  return (
    <XStack alignItems="center" justifyContent="space-between">
      <ThemeIcon code={code} showLabel />
      <XStack gap="$2">
        {distribution?.amount !== undefined && (
          <LocalizedNumbers value={distribution.amount} textComponent={Label3} />
        )}
        {distribution?.full !== undefined && (
          <>
            <Label3>{t('THEME_WITHDRAWAL_ORDER.DETAILS-FULL')}</Label3>
            <Label3>
              (
              <LocalizedNumbers value={amount} textComponent={Label3} />)
            </Label3>
          </>
        )}
      </XStack>
    </XStack>
  );
}
