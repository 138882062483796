import {skipToken} from '@reduxjs/toolkit/query';
import {useRouter} from 'expo-router';
import React, {useCallback} from 'react';
import {YStack, useMedia} from 'tamagui';

import {Card} from '@/components/cards/Card';
import SvgFile from '@/components/icons/SvgFile';
import {Label1, Label3} from '@/components/texts/Label';
import {Skeleton} from '@/components/views/Skeleton';
import {useIsUserComplete} from '@/hooks/useIsUserComplete';
import {SustainableChoiceHint} from '@/modules/interview/components/SustainableChoiceHint';
import useAvailableProducts from '@/modules/interview/hooks/useAvailableProducts';
import {Product} from '@/types/fixedTypes/customers.v2/Product';

export function ProductChoiceList() {
  const router = useRouter();

  const media = useMedia();

  const {isUserComplete, isFetching} = useIsUserComplete();

  const products = useAvailableProducts(!isUserComplete ? skipToken : undefined);

  const onPress = useCallback(
    (product: Product) => {
      if (product === undefined) return;

      router.navigate({pathname: `/interview/question`, params: {product}});
    },
    [router]
  );

  return (
    <YStack gap="$4" alignSelf="stretch">
      {products.length === 0 || isFetching ? (
        <>
          <Skeleton width="100%" height={75} />
          <Skeleton width="100%" height={75} />
          <Skeleton width="100%" height={75} />
          <Skeleton width="100%" height={75} />
          <Skeleton width="100%" height={75} />
        </>
      ) : (
        products.map(product => (
          <Card
            padding="$4"
            key={product.product}
            flexDirection="row"
            gap="$4"
            alignItems="center"
            onPress={() => onPress(product.product)}
            testID={product.product}
          >
            <SvgFile
              name={product.icon}
              width={media.sm ? 30 : 50}
              height={media.sm ? 30 : 50}
              color="$primary"
              containerStyle={{
                borderRadius: '$1',
                backgroundColor: '$neutral50',
                padding: media.sm ? '$2' : '$4',
              }}
            />
            <YStack flex={1}>
              <Label1 variant="medium">{product.title}</Label1>
              <Label3 color="$neutral400">{product.description}</Label3>
            </YStack>
          </Card>
        ))
      )}
      <SustainableChoiceHint />
    </YStack>
  );
}
