import {Badge} from '@/components/buttons/Badge';
import {Card} from '@/components/cards/Card';
import SvgFile from '@/components/icons/SvgFile';
import {Heading3, Heading5} from '@/components/texts/Heading';
import {Label2} from '@/components/texts/Label';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {Paragraph3} from '@/components/texts/Paragraph';
import {Skeleton} from '@/components/views/Skeleton';
import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {ThemeInfoModal} from '@/modules/interview/components/planner/ThemeInfoModal';
import {useInvestmentThemesQuery, useSecuritiesQuery} from '@/store/queries/referenceApi';
import {useTranslate} from '@tolgee/react';
import React, {useMemo} from 'react';
import {XStack, YStack} from 'tamagui';

export function MoneyMarketUsCard() {
  const {t} = useTranslate();
  const {data: investmentThemes} = useInvestmentThemesQuery();
  const {data: securities} = useSecuritiesQuery();
  const {getByCode} = useRefdataLocalizable();

  const moneyMarketTheme = useMemo(
    () => getByCode(investmentThemes, 'MONEY_MARKET'),
    [getByCode, investmentThemes]
  );

  const moneyMarketSecurity = useMemo(
    () => moneyMarketTheme && getByCode(securities, moneyMarketTheme.security),
    [getByCode, moneyMarketTheme, securities]
  );

  if (!moneyMarketTheme || !moneyMarketSecurity) return <Skeleton width="100%" height={200} />;

  return (
    <ThemeInfoModal theme={moneyMarketTheme} security={moneyMarketSecurity}>
      <Card onPress={() => {}}>
        <XStack gap="$4">
          <YStack gap="$4" flex={3}>
            <Badge
              alignSelf="flex-start"
              backgroundColor="$primary"
              borderColor="transparent"
              paddingHorizontal="$4"
              paddingVertical="$1.5"
            >
              <Label2 color="$white">{t('PLANNER.MOTIVE.RECOMMENDED_CHOICE')}</Label2>
            </Badge>
            <XStack gap="$4">
              <Heading5 variant="medium">{moneyMarketTheme.label}</Heading5>
              <SvgFile name="info" color="$primary" />
            </XStack>
            <Paragraph3 color="$neutral400" textAlign="left">
              {t('MOTIVE_INVEST_OPPORTUNITY.TOPIC_OVERVIEW_DESC')}
            </Paragraph3>
          </YStack>

          <YStack justifyContent="space-between">
            <SvgFile name="flag-us" size={50} alignSelf="flex-end" />
            <XStack gap="$3" alignSelf="flex-end">
              {moneyMarketTheme.yearlyReturn && (
                <LocalizedNumbers
                  textComponent={Heading3}
                  value={moneyMarketTheme.yearlyReturn / 100}
                  numberStyle={{
                    color: '$primary',
                    variant: 'medium',
                    minWidth: 100,
                    alignSelf: 'flex-end',
                  }}
                  variant="percentage"
                />
              )}
              <Label2 color="$neutral400" alignSelf="flex-end">
                {t('PLANNER.STRATEGY-INFO.PA')}
              </Label2>
            </XStack>
          </YStack>
        </XStack>
      </Card>
    </ThemeInfoModal>
  );
}
