import {useTranslate} from '@tolgee/react';
import {useRouter} from 'expo-router';
import {useCallback, useEffect, useState} from 'react';
import {YStack} from 'tamagui';

import {CardCheckbox} from '@/components/inputs/CardCheckbox';
import {Heading4} from '@/components/texts/Heading';
import {InputErrorMessage} from '@/components/texts/InputErrorMessage';
import {Paragraph2} from '@/components/texts/Paragraph';
import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {InterviewContinueButton} from '@/modules/interview/components/InterviewContinueButton';
import {useOnboardingSearchParams} from '@/modules/onboarding/hooks/useOnboardingSearchParams';
import {GuardianType} from '@/modules/onboarding/types/onboarding-type';
import {useAppDispatch, useAppSelector} from '@/store';
import {useGetAvailableUsersQuery} from '@/store/queries/usersApi';
import {setAnotherGuardianInfo, setGuardianDocuments} from '@/store/reducers/onboarding';

const AnotherGuardian = () => {
  const {t} = useTranslate();

  const router = useRouter();

  const anotherGuardian = useAppSelector(state => state.onboarding.personalDetails.anotherGuardian);

  const [name, setName] = useState('');
  const [error, setError] = useState<string>();
  const [guardianType, setGuardianType] = useState<GuardianType | undefined>(
    anotherGuardian ? 'shared' : undefined
  );

  const {product, childID} = useOnboardingSearchParams();

  const dispatch = useAppDispatch();

  const {data} = useGetAvailableUsersQuery();

  useEffect(() => {
    if (!data) return;

    const child = data.find(user => user.id === Number(childID));

    if (!child) return;

    setName(child.firstName ?? '');
  }, [data, childID]);

  const handleCardPressed = useCallback(
    (type: GuardianType) => () => {
      setError(undefined);
      setGuardianType(type);
    },
    []
  );

  const handleContinuePressed = useCallback(() => {
    if (!guardianType) {
      setError(t('PERSONAL_DETAILS.ERROR_VALUE_SHOULD_BE_SET'));

      return;
    }

    setError(undefined);

    if (guardianType === 'solo') {
      dispatch(setAnotherGuardianInfo(undefined));
    }

    if (
      (!!anotherGuardian && guardianType === 'solo') ||
      (!anotherGuardian && guardianType === 'shared')
    ) {
      dispatch(setGuardianDocuments(undefined));
    }

    router.navigate({
      pathname:
        guardianType === 'solo'
          ? '/onboarding/personal-details/child-docs'
          : '/onboarding/personal-details/another-guardian-info',
      params: {product, childID, guardianType},
    });
  }, [childID, dispatch, guardianType, product, router, t, anotherGuardian]);

  return (
    <FullscreenScrollView variant="small">
      <YStack gap="$3">
        <Heading4 variant="medium">{t('PERSONAL_DETAILS.ANOTHER_GUARDIAN.TITLE')}</Heading4>
        <Paragraph2 color="$neutral400">
          {t('PERSONAL_DETAILS.ANOTHER_GUARDIAN.DESC', {firstName: name})}
        </Paragraph2>
        <CardCheckbox
          label={t('PERSONAL_DETAILS.ANOTHER_GUARDIAN.TWO_GUARDIANS')}
          checked={guardianType === 'shared'}
          onCheckedChange={handleCardPressed('shared')}
          variant="radio"
          wrapperProps={{
            testID: 'guardian-shared',
          }}
        />
        <CardCheckbox
          label={t('PERSONAL_DETAILS.ANOTHER_GUARDIAN.ONE_GUARDIAN')}
          checked={guardianType === 'solo'}
          onCheckedChange={handleCardPressed('solo')}
          variant="radio"
          wrapperProps={{
            testID: 'guardian-solo',
          }}
        />
        <InputErrorMessage error={error} />
        <InterviewContinueButton onPress={handleContinuePressed}>
          {t('CONTINUE')}
        </InterviewContinueButton>
      </YStack>
    </FullscreenScrollView>
  );
};

export default AnotherGuardian;
