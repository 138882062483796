import {useMemo} from 'react';
import {Platform} from 'react-native';

export const useWebGlAvailability = () => {
  const isWebGlAvailable = useMemo(() => {
    if (Platform.OS === 'web') {
      return new OffscreenCanvas(1, 1).getContext('webgl') !== null;
    } else {
      return true;
    }
  }, []);

  return isWebGlAvailable;
};
