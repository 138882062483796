import {useTheme, View, YStack} from 'tamagui';

import {Card} from '@/components/cards/Card';
import {Chart, Line} from '@/components/skia';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {Paragraph3} from '@/components/texts/Paragraph';
import {Point} from '@/types/skia';

type Props = {
  onPress?: () => void;
  title: string;
  value: number;
  percentage?: boolean;
  points: Point[];
  lineColor: string;
  theme: ReturnType<typeof useTheme>;
};

export const PreviewChart = ({
  title,
  value,
  percentage,
  points,
  lineColor,
  onPress,
  theme,
}: Props) => (
  <Card onPress={onPress} padding={0} flex={1} flexBasis={0} minWidth={250}>
    <View height="100%" width="100%">
      <Chart removeHorizontalOffset theme={theme}>
        <Line points={points} color={lineColor} gradient={{show: true}} />
      </Chart>
    </View>
    <View position="absolute" top={4} left={4} bottom={4}>
      <View
        position="absolute"
        top={0}
        right={0}
        bottom={0}
        left={0}
        backgroundColor="$neutralBG"
        opacity={0.7}
        borderRadius="$1"
      />
      <YStack flex={1} padding="$2" justifyContent="space-evenly">
        <Paragraph3 color="$neutral500">{title}</Paragraph3>
        <LocalizedNumbers
          value={value}
          numberStyle={{
            fontSize: 16,
            lineHeight: 26,
            $sm: {
              fontSize: 14,
              lineHeight: 22,
            },
          }}
          variant={percentage ? 'percentage' : 'currency'}
        />
      </YStack>
    </View>
  </Card>
);
