import {useTranslate} from '@tolgee/react';

import {useGetSelfUserQuery} from '@/store/queries/usersApi';

import {Heading4} from '@/components/texts/Heading';
import {Skeleton} from '@/components/views/Skeleton';
import {isTouchable, useMedia} from 'tamagui';

export function HomeGreeting() {
  const {t} = useTranslate();
  const media = useMedia();

  const {data: user, isFetching} = useGetSelfUserQuery();

  // TODO: change greeting depending on time of day
  if (media.gtSm)
    return (
      <Skeleton width="50%">
        {!isFetching && (
          <Heading4
            marginLeft={isTouchable ? '$4' : 0}
            variant="medium"
            paddingVertical="$2"
            paddingLeft="$1"
          >
            {t('HELLO', '', {firstName: user ? user.firstName : ''})}
          </Heading4>
        )}
      </Skeleton>
    );
  return null;
}
