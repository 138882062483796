import {forwardRef, PropsWithChildren} from 'react';
import {LayoutChangeEvent} from 'react-native';
import {ScrollView, XStack} from 'tamagui';

import {Variant} from '@/modules/navigation/types/Tabbar';

type Props = {
  variant: Variant;
  onLayout: (event: LayoutChangeEvent) => void;
};

export const FixedTabbar = forwardRef<ScrollView, PropsWithChildren<Props>>(
  ({children, variant, onLayout}, ref) => {
    return (
      <XStack
        backgroundColor={variant === 'onSurface' ? '$surfaceRaised' : '$surface'}
        onLayout={onLayout}
        borderRadius="$0.5"
        padding="$2"
        gap="$2"
      >
        {children}
      </XStack>
    );
  }
);
