import {Label3} from '@/components/texts/Label';
import {WithLabel} from '@/components/texts/WithLabel';
import {Skeleton} from '@/components/views/Skeleton';
import {useGetCustomerAccountQuery} from '@/store/queries/customersApi';
import {skipToken} from '@reduxjs/toolkit/query';
import {useTranslate} from '@tolgee/react';
import {PropsWithChildren, useMemo} from 'react';
import {YStack} from 'tamagui';

type ReferenceAccountInformationProps = {
  customerId: string | undefined;
  column?: boolean;
  variant: 'common' | 'deposit' | 'withdrawal' | 'current' | 'new';
};

export function ReferenceAccountInformation({
  customerId,
  column = false,
  variant,
  children,
}: PropsWithChildren<ReferenceAccountInformationProps>) {
  const {t} = useTranslate();
  const {data: customer} = useGetCustomerAccountQuery(customerId ?? skipToken);

  const title = useMemo(() => {
    switch (variant) {
      case 'deposit':
        return t('ORDER_SUMMARY.DEPOSIT.REFERENCE-TITLE');
      case 'withdrawal':
        return t('ORDER_SUMMARY.WITHDRAWAL.REFERENCE-TITLE');
      case 'current':
        return t('ORDER_SUMMARY.REFERENCE-ACCOUNT');
      case 'new':
        return t('ORDER_SUMMARY.REFERENCE-ACCOUNT-CHANGE.NEW');
      case 'common':
      default:
        return t('ORDER_SUMMARY.REFERENCE-ACCOUNT');
    }
  }, [t, variant]);

  const color = useMemo(() => {
    return variant === 'current' ? '$neutral400' : '$text';
  }, [variant]);

  return (
    <Skeleton width="100%" height={140}>
      {customer && column ? (
        <WithLabel header={title} horizontal>
          <YStack>
            {children ? (
              children
            ) : (
              <>
                <Label3 color={color} textAlign="right">
                  {customer?.bankAccount?.holderFullName}
                </Label3>
                <Label3 color={color} textAlign="right">
                  {customer?.bankAccount?.iban}
                </Label3>
                <Label3 color={color} textAlign="right">
                  {customer?.bankAccount?.bic}
                </Label3>
                <Label3 color={color} textAlign="right">
                  {customer?.bankAccount?.bank}
                </Label3>
              </>
            )}
          </YStack>
        </WithLabel>
      ) : (
        <YStack>
          <Label3 color="$neutral400" marginBottom="$2">
            {title}
          </Label3>
          <Label3>{customer?.bankAccount?.holderFullName}</Label3>
          <Label3>{customer?.bankAccount?.iban}</Label3>
          <Label3>{customer?.bankAccount?.bic}</Label3>
          <Label3>{customer?.bankAccount?.bank}</Label3>
        </YStack>
      )}
    </Skeleton>
  );
}
