import {skipToken} from '@reduxjs/toolkit/query';
import {captureException} from '@sentry/react-native';
import {useTranslate} from '@tolgee/react';
import {useCallback, useRef, useState} from 'react';
import {YStack} from 'tamagui';

import {Heading4} from '@/components/texts/Heading';
import {Label2} from '@/components/texts/Label';
import {FinancialSituationSchemaType} from '@/hooks/useValidations';
import {
  FinancialSituationForm,
  SubmitFormRef,
} from '@/modules/interview/components/FinancialSituationForm';
import {InterviewContinueButton} from '@/modules/interview/components/InterviewContinueButton';
import {fillEmptyTradesAndVolumes} from '@/modules/interview/helpers/fillEmptyTradesAndVolumes';
import {useAppDispatch, useAppSelector} from '@/store';
import {
  CreateInterviewResponse,
  useCreateInterviewMutation,
  useCreatePublicInterviewMutation,
} from '@/store/queries/interviewApi';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';
import {updateInterview} from '@/store/reducers/interview';
import {saveInterviewId, savePublicInterview} from '@/store/reducers/onboarding';

type Props = {
  isSecondGuardian?: boolean;
  onContinue: (riskProfile?: string) => void;
};

export const FinancialSituationScreen = ({isSecondGuardian, onContinue}: Props) => {
  const {t} = useTranslate();

  const ref = useRef<SubmitFormRef>(null);

  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const interview = useAppSelector(state => state.interview);

  const {data: selfUser} = useGetSelfUserQuery(!isSecondGuardian ? skipToken : undefined);

  const [createGinmonInterview] = useCreateInterviewMutation();
  const [createGinmonPublicInterview] = useCreatePublicInterviewMutation();

  const onPress = useCallback(() => {
    ref.current?.submit();
  }, []);

  const createInterview = useCallback(
    async (data: FinancialSituationSchemaType) => {
      const {sumAssets, monthlyCalculated, ...financials} = data;

      const {EXP_V2, INITIAL_DEPOSIT, INITIAL_MONTHLY_SAVINGS, TRADES, VOLUME, ...regularAnswers} =
        fillEmptyTradesAndVolumes(interview);

      const ginmonInterview = {...regularAnswers, ...financials};

      let interviewResponse: CreateInterviewResponse;

      if (isSecondGuardian) {
        if (!selfUser) {
          throw new Error('Failed to get user');
        }

        ginmonInterview.userId = selfUser.id.toString();
        ginmonInterview.type = 'BASIC_V2';
      }

      if (ginmonInterview.userId) {
        interviewResponse = await createGinmonInterview(ginmonInterview).unwrap();
      } else {
        interviewResponse = await createGinmonPublicInterview(ginmonInterview).unwrap();

        dispatch(savePublicInterview(interviewResponse));
      }

      return interviewResponse;
    },
    [
      createGinmonInterview,
      createGinmonPublicInterview,
      dispatch,
      interview,
      isSecondGuardian,
      selfUser,
    ]
  );

  const onValidSubmit = useCallback(
    async (data: FinancialSituationSchemaType) => {
      try {
        setLoading(true);

        const {sumAssets, monthlyCalculated, ...financials} = data;

        const newInterview = await createInterview(data);

        if (newInterview.id) {
          dispatch(saveInterviewId(newInterview.id));
        } else {
          captureException('No interview id');
        }

        const riskProfile =
          newInterview.type === 'RISK_PROFILE_EVALUATION_V2' ? newInterview.riskProfile : undefined;

        dispatch(updateInterview(financials));

        onContinue(riskProfile);
      } catch (e) {
        captureException(e);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, onContinue, createInterview]
  );

  return (
    <YStack gap="$4">
      <YStack gap="$2">
        <Heading4 variant="medium">{t('INTERVIEW.WHATS_YOUR_FINANCIAL_SITUATION')}</Heading4>
        <Label2 color="$neutral500">{t('INTERVIEW.FINANCIAL_SITUATION_DESCRIPTION')}</Label2>
      </YStack>

      <FinancialSituationForm ref={ref} onValid={onValidSubmit} />

      <InterviewContinueButton isLoading={loading} onPress={onPress} />
    </YStack>
  );
};
