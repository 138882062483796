import {useTranslate} from '@tolgee/react';
import {Stack, useLocalSearchParams} from 'expo-router';
import {useMemo, useRef} from 'react';
import {Platform} from 'react-native';
import {YStack} from 'tamagui';

import {Button} from '@/components/buttons/Button';
import {ResponsiveScrollView} from '@/components/views/ResponsiveScrollView';
import {useCustomerIdParamOrRedirect} from '@/hooks/useCustomerIdParamOrRedirect';
import {WebFooter} from '@/modules/navigation/components/footer/WebFooter';
import {Header} from '@/modules/navigation/components/header/Header';
import {OrderSummaryModal} from '@/modules/orders/components/OrderSummaryModal';
import {ThemeDeposit} from '@/modules/orders/components/Theme/ThemeDeposit';
import {useOrderContext} from '@/modules/orders/hooks/useOrderContext';
import {ModalRef} from '@/types/modal';

export default function DepositMotive() {
  const customerId = useCustomerIdParamOrRedirect();
  const {t} = useTranslate();
  const {value} = useLocalSearchParams<{
    value: string;
  }>();
  const {themeDepositDistribution} = useOrderContext();
  const modalRef = useRef<ModalRef>(null);

  const amount = useMemo(() => {
    return parseFloat(value);
  }, [value]);

  return (
    <ResponsiveScrollView>
      <Stack.Screen
        options={{
          headerShown: Platform.OS !== 'web',
          header: () => (
            <Header
              title={t('NAVIGATION.DEPOSIT-MOTIVE.TITLE')}
              backButton={Platform.OS !== 'web'}
            />
          ),
        }}
      />
      <YStack gap="$3">
        <ThemeDeposit amount={amount} customerId={customerId} />
        <Button onPress={() => modalRef.current?.open(true)}>{t('CONTINUE')}</Button>
      </YStack>
      <OrderSummaryModal
        ref={modalRef}
        customerId={customerId}
        value={amount}
        orderType="THEME_DEPOSIT"
        themeDepositDistribution={themeDepositDistribution}
      />
      <WebFooter />
    </ResponsiveScrollView>
  );
}
