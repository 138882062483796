import {Card} from '@/components/cards/Card';
import {Heading5} from '@/components/texts/Heading';
import {ThemeInfoCard} from '@/modules/interview/components/planner/ThemeInfoCard';
import {useInvestmentThemesQuery} from '@/store/queries/referenceApi';
import {useTranslate} from '@tolgee/react';
import {useMemo} from 'react';
import {XStack, YStack} from 'tamagui';

export function ThemeInfoCardList() {
  const {t} = useTranslate();
  const {data: investmentThemes} = useInvestmentThemesQuery();

  const themeCards = useMemo(() => {
    return investmentThemes
      ?.filter(_ => _.code !== 'MONEY_MARKET')
      ?.map((_, i) => <ThemeInfoCard key={_.code} code={_.code} />);
  }, [investmentThemes]);

  return (
    <Card>
      <YStack>
        <Heading5 variant="medium">{t('PLANNER.MOTIVE.AVAILABLE_THEMES.TITLE')}</Heading5>
        <XStack flexWrap="wrap" justifyContent="space-between">
          {themeCards}
        </XStack>
      </YStack>
    </Card>
  );
}
