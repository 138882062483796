import {useEffect, useState} from 'react';

import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {useGetCustomerOrderConstraintsQuery} from '@/store/queries/customersApi';
import {useViolationsQuery} from '@/store/queries/referenceApi';
import {OrderConstraintResponse, OrderConstraintsResponse} from '@/types/api/customers.v2';
import {OrderConstraintViolationResponse} from '@/types/fixedTypes/customers.v2/OrderConstraintViolationResponse';

type Violations = Omit<OrderConstraintViolationResponse, 'label'> & {
  label: string;
};

type Constraint = Omit<OrderConstraintResponse, 'violations'> & {
  violations: Violations[];
  firstViolationLabel: string | undefined;
};

type Res = {
  constraint?: Constraint;
  loading: boolean;
};

export function useGetSpecificOrderConstraint(
  customerId: string,
  orderType: keyof OrderConstraintsResponse
) {
  const [res, setRes] = useState<Res>({loading: true});

  const {data: orderConstraints, isFetching: orderConstraintsFetching} =
    useGetCustomerOrderConstraintsQuery(customerId);
  const {data: violations, isFetching} = useViolationsQuery();
  const {getByCode} = useRefdataLocalizable();

  useEffect(() => {
    if (!orderConstraints || !violations) {
      setRes({loading: orderConstraintsFetching || isFetching});

      return;
    }

    const orderConstraintsResponse = orderConstraints[orderType];

    // TODO: ! can be removed after https://ginmon.atlassian.net/browse/CUSTOMERS-927
    let violation;
    if (orderConstraintsResponse.violations) {
      violation = orderConstraintsResponse.violations.map(
        violation =>
          getByCode<OrderConstraintViolationResponse>(violations, violation.code!) || violation
      );
    }

    const newConstraint: Constraint = {
      ...orderConstraintsResponse,
      violations: violation as Constraint['violations'],
      // @ts-ignore // TODO: need to fix types here
      firstViolationLabel: violation?.at(0)?.label,
    };

    setRes({constraint: newConstraint, loading: orderConstraintsFetching || isFetching});
  }, [orderType, orderConstraints, violations, orderConstraintsFetching, isFetching, getByCode]);

  return res;
}
