import {Redirect, useLocalSearchParams, useUnstableGlobalHref} from 'expo-router';

import {useSession} from '@/providers/session-provider/SessionProvider';
import {isSessionStillValid} from '@/providers/session-provider/utils';
import {Platform} from 'react-native';

export default function RootPage() {
  const {code} = useLocalSearchParams();
  const url = useUnstableGlobalHref();
  const {session} = useSession();

  if (session && isSessionStillValid()) {
    return <Redirect href="/(user)" />;
  } else if (Platform.OS !== 'web' || !code) {
    // on web we need the code redirect to be handled by useCustomAuthRequest
    return (
      <Redirect
        href={{
          pathname: '/login',
          params: {url},
        }}
      />
    );
  }
}
