import {useTranslate} from '@tolgee/react';
import {forwardRef} from 'react';
import {ScrollView, YStack} from 'tamagui';

import {Modal} from '@/components/modal/Modal';
import {Heading5} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {ModalRef} from '@/types/modal';

type Props = {
  title: string;
};

export const SustainLevelEcoModal = forwardRef<ModalRef, Props>(({title}, ref) => {
  const {t} = useTranslate();

  return (
    <Modal ref={ref}>
      <Modal.Content typeContainer="SCROLL" side>
        <ScrollView>
          <Modal.Title asChild="except-style">
            <Heading5 variant="medium">{title}</Heading5>
          </Modal.Title>
          <YStack paddingTop="$3" gap="$3">
            <Paragraph2 color="$neutral400">
              {t('INTERVIEW.WHAT_LEVEL_OF_SUSTAINABILITY.ECOLOGICAL.FIRST')}
            </Paragraph2>
            <Paragraph2 color="$neutral400">
              {t('INTERVIEW.WHAT_LEVEL_OF_SUSTAINABILITY.ECOLOGICAL.SECOND')}
            </Paragraph2>
            <Paragraph2 color="$neutral400">
              {t('INTERVIEW.WHAT_LEVEL_OF_SUSTAINABILITY.ECOLOGICAL.THIRD')}
            </Paragraph2>
          </YStack>
          <Modal.IconClose />
        </ScrollView>
      </Modal.Content>
    </Modal>
  );
});
