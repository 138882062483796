import {BaseRecommendationCard} from '@/modules/recommendations/components/BaseRecommendationCard';
import {RecommendationsProps} from '@/modules/recommendations/components/Recommendations';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useAppDispatch} from '@/store';
import {setDismissedRecommendationsFriends} from '@/store/reducers/app';
import {useTranslate} from '@tolgee/react';
import {useRouter} from 'expo-router';

export function FriendsRecommendationCard({type}: RecommendationsProps) {
  const {t} = useTranslate();
  const router = useRouter();
  const posthog = usePostHog();
  const dispatch = useAppDispatch();

  const onDismiss = () => {
    dispatch(setDismissedRecommendationsFriends(true));
  };

  return (
    <BaseRecommendationCard
      title={t('RECOMMENDATION.FRIENDS.TITLE')}
      description={t('RECOMMENDATION.FRIENDS.DESCRIPTION')}
      onPress={() => {
        posthog?.capture('recommendation_friends_click');
        router.navigate('/(user)/friends');
      }}
      onDismiss={onDismiss}
      iconPath={require('@/assets/images/recommendation-friends.png')}
      type={type}
    />
  );
}
