import {Button} from '@/components/buttons/Button';
import {Card} from '@/components/cards/Card';
import {InternalTransferCustomerSelector} from '@/components/inputs/InternalTransferCustomerSelector';
import {NonEditableInput} from '@/components/inputs/NonEditableInput';
import {FormAmountInput} from '@/components/inputs/form/FormAmountInput';
import {InputErrorMessage} from '@/components/texts/InputErrorMessage';
import {Label3} from '@/components/texts/Label';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {Skeleton} from '@/components/views/Skeleton';
import {InternalTransferOrderSchemaType, useValidations} from '@/hooks/useValidations';
import {TipWithSideModal} from '@/modules/interview/components/TipWithSideModal';
import {InternalTransferTipModalContent} from '@/modules/orders/components/InternalTransfer/InternalTransferTipModalContent';
import {WarningBox} from '@/modules/orders/components/WarningBox';
import {useGetSpecificOrderConstraint} from '@/modules/orders/hooks/useGetSpecificOrderConstraint';
import {useGetPerformanceSummaryQuery} from '@/store/queries/apeironApi';
import {useGetCustomerAccountQuery} from '@/store/queries/customersApi';
import {yupResolver} from '@hookform/resolvers/yup';
import {skipToken} from '@reduxjs/toolkit/query';
import {useTranslate} from '@tolgee/react';
import {usePostHog} from 'posthog-react-native';
import {useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {View, YStack} from 'tamagui';

type InternalAssetsTransferFormProps = {
  onSubmit: (data: InternalTransferOrderSchemaType) => void;
  customerId: string;
};

export function InternalAssetsTransferForm({
  customerId,
  onSubmit,
}: InternalAssetsTransferFormProps) {
  const {t} = useTranslate();
  const posthog = usePostHog();
  const {data: customer, isFetching} = useGetCustomerAccountQuery(customerId);
  const {internalTransferOrderSchema} = useValidations();
  const {data: performanceSummary, isFetching: isPerformarnceFetching} =
    useGetPerformanceSummaryQuery(
      customerId
        ? {
            customerId,
          }
        : skipToken
    );

  const {constraint: {canBeCreated, firstViolationLabel} = {}, loading} =
    useGetSpecificOrderConstraint(customerId, 'INTERNAL_TRANSFER');

  const {
    handleSubmit,
    control,
    watch,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(internalTransferOrderSchema),
    defaultValues: {
      amount: 0,
      targetCustomerId: '',
    },
    context: {
      maxAmount: performanceSummary?.balance ?? 0,
    },
    mode: 'onChange',
  });

  const amount = watch('amount');

  const remainder = useMemo(() => {
    return (performanceSummary?.balance ?? 0) - amount;
  }, [performanceSummary?.balance, amount]);

  return (
    <Card gap="$4" padding="$6">
      <YStack gap="$6">
        <Skeleton width={250}>
          {!isFetching && !isPerformarnceFetching && (
            <YStack gap="$6">
              <View
                flexDirection="row"
                gap="$4"
                alignItems="flex-start"
                $sm={{flexDirection: 'column'}}
              >
                <View
                  width="100%"
                  $gtSm={{
                    maxWidth: 350,
                  }}
                >
                  <FormAmountInput
                    control={control}
                    name="amount"
                    label={t('ORDER.INTERNAL_ASSETS_TRANSFER.AMOUNT-TITLE')}
                    bordered
                    decimal
                  />
                </View>
                <View
                  flexDirection="column"
                  gap="$1"
                  marginTop="$6"
                  $sm={{flexDirection: 'row', alignSelf: 'center', marginTop: 0}}
                >
                  <Label3 color="$neutral400">{t('WITHDRAWAL_PAGE.FORM.REST.TITLE')}</Label3>
                  <LocalizedNumbers value={remainder} textComponent={Label3} />
                </View>
              </View>
              <YStack
                width="100%"
                $gtSm={{
                  maxWidth: 350,
                }}
                gap="$6"
              >
                <NonEditableInput
                  label={t('ORDER.INTERNAL_ASSETS_TRANSFER.FROM')}
                  text={customer?.displayName ?? ''}
                  bordered
                />
                <InternalTransferCustomerSelector
                  currentCustomerId={customerId}
                  onSelect={() => {}}
                  control={control}
                  name="targetCustomerId"
                />
                <InputErrorMessage error={errors.root?.message} />
                {errors.root?.message}
              </YStack>
            </YStack>
          )}
        </Skeleton>
        <WarningBox show={!loading && !canBeCreated} text={firstViolationLabel} />
        <View
          flexDirection="row"
          gap="$6"
          $sm={{flexDirection: 'column', gap: '$5', paddingBottom: '$4'}}
          alignItems="center"
        >
          <View $sm={{width: '100%'}}>
            <Button
              disabled={!canBeCreated}
              onPress={handleSubmit(onSubmit, data => {
                posthog?.capture('form_submit_failed', data);
              })}
            >
              {t('CONTINUE')}
            </Button>
          </View>
          <TipWithSideModal
            tipTitle={t('ORDER.INTERNAL_ASSETS_TRANSFER.TIP.TITLE')}
            tipContent={<InternalTransferTipModalContent />}
          />
        </View>
      </YStack>
    </Card>
  );
}
