import {Card} from '@/components/cards/Card';
import {FormDocumentUpload} from '@/components/inputs/form/FormDocumentUpload';
import {Heading5} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {GuardianDocumentsSchemaType} from '@/hooks/useValidations';
import {useTranslate} from '@tolgee/react';
import {toast} from 'burnt';
import {Control, Path} from 'react-hook-form';
import {XStack, YStack} from 'tamagui';

type Props = {
  title: string;
  description: string;
  control: Control<GuardianDocumentsSchemaType>;
  name: Path<GuardianDocumentsSchemaType>;
  userID?: number;
};

export const GuardianDocumentsCard = ({title, description, control, name, userID}: Props) => {
  const {t} = useTranslate();
  return (
    <Card>
      <XStack gap="$6" justifyContent="space-between">
        <YStack flexShrink={1} maxWidth="50%" gap="$2">
          <Heading5 variant="medium">{title}</Heading5>
          <Paragraph2 color="$neutral400">{description}</Paragraph2>
        </YStack>
        <XStack flex={1} maxWidth="40%">
          <FormDocumentUpload
            control={control}
            name={name}
            userID={userID}
            fileContextPrefix="guardianship-proof"
            container={{flex: 1}}
            uploadAreaStyle={{minHeight: 150}}
            multi={false}
            pdf={false}
            maxFileSize={1000 * 1000 * 20} // 20 MB
            onError={reason => {
              switch (reason) {
                case 'FILE_SIZE':
                  toast({
                    preset: 'error',
                    title: t('PROOF_OF_RESIDENCE.DOCUMENT.FILE_TO_BIG'),
                  });
                  break;
                case 'FILE_FORMAT':
                  toast({
                    preset: 'error',
                    title: t('PROOF_OF_RESIDENCE.DOCUMENT.FILE_WRONG_FORMAT'),
                  });
              }
            }}
          />
        </XStack>
      </XStack>
    </Card>
  );
};
