import {CheckBox} from '@/components/inputs/Checkbox';
import {InputErrorMessage} from '@/components/texts/InputErrorMessage';
import {FormInputProps} from '@/types/FormInputProps';
import {PropsWithChildren, useCallback, useId} from 'react';
import {FieldValues, useController} from 'react-hook-form';
import {Label, View, XStack, YStack} from 'tamagui';

type FormCustomCheckboxProps<T extends FieldValues> = PropsWithChildren<
  Omit<FormInputProps<T>, 'label'>
> & {label: React.ReactNode};

export function FormCustomCheckbox<T extends FieldValues>({
  control,
  name,
  label,
  children,
}: FormCustomCheckboxProps<T>) {
  const id = useId();

  const {
    field,
    fieldState: {error},
  } = useController({
    name,
    control,
    // @ts-ignore
    defaultValue: false,
  });

  const onCheckedChange = useCallback(() => {
    field.onChange(!field.value);
  }, [field]);

  return (
    <YStack gap="$4">
      <View cursor="pointer" testID={name}>
        <XStack alignItems="center" gap="$4">
          <CheckBox
            checked={field.value}
            onCheckedChange={onCheckedChange}
            id={id}
            alignSelf="flex-start"
            testID={`${name}-checkbox`}
          />
          <Label htmlFor={id} cursor="pointer" flex={1}>
            {label}
          </Label>
        </XStack>
      </View>
      {children}
      <InputErrorMessage error={error?.message} />
    </YStack>
  );
}
