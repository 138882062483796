import {useAppSelector} from '@/store';

export default function useExistingTradesStoreAnswers() {
  const answers = useAppSelector(state => state.interview);
  if (!answers) return {};
  return JSON.parse(
    JSON.stringify(
      (({
        ASSET_TRADES_BONDS,
        ASSET_TRADES_STOCKS,
        ASSET_TRADES_ETF,
        ASSET_TRADES_DERIVATIVES,
        ASSET_VOLUME_BONDS,
        ASSET_VOLUME_DERIVATIVES,
        ASSET_VOLUME_ETF,
        ASSET_VOLUME_STOCKS,
      }) => ({
        ASSET_TRADES_BONDS,
        ASSET_TRADES_STOCKS,
        ASSET_TRADES_ETF,
        ASSET_TRADES_DERIVATIVES,
        ASSET_VOLUME_BONDS,
        ASSET_VOLUME_DERIVATIVES,
        ASSET_VOLUME_ETF,
        ASSET_VOLUME_STOCKS,
      }))(answers)
    )
  );
}
