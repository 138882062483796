import {Button} from '@/components/buttons/Button';
import {FormCountrySelect} from '@/components/inputs/form/FormCountrySelect';
import {FormTextInput} from '@/components/inputs/form/FormTextInput';
import {AddressDetailsSchemaType, useValidations} from '@/hooks/useValidations';
import {MFARequiredNote} from '@/modules/mfa/components/MFARequiredNote';
import {useMfaAvailability} from '@/modules/mfa/hooks/useMfaAvailability';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslate} from '@tolgee/react';
import {useRouter} from 'expo-router';
import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {View, XStack, useMedia} from 'tamagui';

type AddressChangeCardProps = {
  onClose: () => void;
};

export function AddressChangeCard({onClose}: AddressChangeCardProps) {
  const {t} = useTranslate();
  const {addressDetailsSchema} = useValidations();
  const router = useRouter();
  const {handleSubmit, control} = useForm({
    resolver: yupResolver(addressDetailsSchema),
    mode: 'onChange',
  });
  const {isMFAUsable} = useMfaAvailability();
  const posthog = usePostHog();
  const media = useMedia();
  const onSubmit = useCallback(
    (data: AddressDetailsSchemaType) => {
      router.navigate({pathname: '/profile/change-address-proof', params: data});
    },
    [router]
  );

  return (
    <View gap="$6">
      <MFARequiredNote />
      <XStack gap="$4" flexWrap="wrap">
        <FormTextInput
          bordered
          control={control}
          name="street"
          label={t('PERSONAL_DETAILS.ADDRESS_CHANGE.STREET')}
          textInputProps={{
            disabled: !isMFAUsable,
          }}
          smFullWidth
        />
        <FormTextInput
          bordered
          control={control}
          name="streetNumber"
          label={t('PERSONAL_DETAILS.ADDRESS_CHANGE.STREET_NUMBER')}
          textInputProps={{
            disabled: !isMFAUsable,
          }}
          smFullWidth
        />
        <FormTextInput
          bordered
          control={control}
          name="postCode"
          label={t('PERSONAL_DETAILS.ADDRESS_CHANGE.POST_CODE')}
          textInputProps={{
            disabled: !isMFAUsable,
          }}
          smFullWidth
        />
      </XStack>

      <XStack gap="$4" flexWrap="wrap">
        <FormTextInput
          bordered
          control={control}
          name="city"
          label={t('PERSONAL_DETAILS.ADDRESS_CHANGE.CITY')}
          textInputProps={{
            disabled: !isMFAUsable,
          }}
          smFullWidth
        />
        <FormCountrySelect
          bordered
          control={control}
          name="countryCode"
          label={t('PERSONAL_DETAILS.ADDRESS_CHANGE.COUNTRY')}
          disabled={!isMFAUsable}
          smFullWidth
        />
      </XStack>
      <View gap="$4" flexDirection={media.sm ? 'column' : 'row'}>
        <Button
          disabled={!isMFAUsable && t('SNACKBAR.MFA.REQUIRED')}
          onPress={handleSubmit(onSubmit, data => {
            posthog?.capture('form_submit_failed', data);
          })}
        >
          {t('PERSONAL_DETAILS.ADDRESS_CHANGE.SAVE')}
        </Button>
        <Button tertiary onPress={onClose}>
          {t('PERSONAL_DETAILS.ADDRESS_CHANGE.CANCEL')}
        </Button>
      </View>
    </View>
  );
}
