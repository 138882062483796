import {useTranslate} from '@tolgee/react';
import {XStack, YStack} from 'tamagui';

import {Heading6} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {useCallback, useMemo} from 'react';

export const InternalTransferTipDisabledAccountModalContent = () => {
  const {t} = useTranslate();

  const points = useMemo<string[]>(
    () => [
      t('ORDER.INTERNAL_ASSETS_TRANSFER.TIP.DISABLED_ACCOUNT.CONTENT.POINT_1'),
      t('ORDER.INTERNAL_ASSETS_TRANSFER.TIP.DISABLED_ACCOUNT.CONTENT.POINT_2'),
    ],
    [t]
  );

  const getNumberText = useCallback((value: number) => `${value}.`, []);

  return (
    <YStack gap="$4" marginVertical="$5">
      <Heading6 variant="medium">
        {t('ORDER.INTERNAL_ASSETS_TRANSFER.TIP.DISABLED_ACCOUNT.CONTENT.TITLE')}
      </Heading6>
      <Paragraph2 color="$neutral400">
        {t('ORDER.INTERNAL_ASSETS_TRANSFER.TIP.DISABLED_ACCOUNT.CONTENT.TEXT_1')}
      </Paragraph2>
      <YStack gap="$2" paddingHorizontal="$2">
        {points.map((key, index) => (
          <XStack key={index} gap="$2">
            <Paragraph2 variant="medium" color="$primary">
              {getNumberText(index + 1)}
            </Paragraph2>
            <Paragraph2 color="$neutral400">{key}</Paragraph2>
          </XStack>
        ))}
      </YStack>
      <Paragraph2 color="$neutral400">
        {t('ORDER.INTERNAL_ASSETS_TRANSFER.TIP.DISABLED_ACCOUNT.CONTENT.TEXT_2')}
      </Paragraph2>
    </YStack>
  );
};
