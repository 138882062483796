import {Heading4} from '@/components/texts/Heading';
import {Label2} from '@/components/texts/Label';
import {AnimatedBounceView} from '@/components/views/animated/AnimatedBounceView';
import {FullScreenCenterView} from '@/components/views/FullScreenCenterView';
import {useTranslate} from '@tolgee/react';
import {Image} from 'expo-image';
import React from 'react';

export function EmailVerificationSuccess() {
  const {t} = useTranslate();

  return (
    <FullScreenCenterView>
      <AnimatedBounceView>
        <Image
          source={require('@/assets/images/mail-success.png')}
          style={{width: 108, height: 100}}
        />
      </AnimatedBounceView>
      <Heading4 variant="medium">{t('EMAIL_VERIFICATION.SUCCESS.TITLE')}</Heading4>
      <Label2 color="$neutral500">{t('EMAIL_VERIFICATION.SUCCESS.DESCRIPTION')}</Label2>
    </FullScreenCenterView>
  );
}
