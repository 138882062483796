import {captureException} from '@sentry/react-native';
import {useCallback, useState} from 'react';

import {useInterval} from '@/hooks/useInterval';
import {useStorageState} from '@/hooks/useStorageState';
import {useSession} from '@/providers/session-provider/SessionProvider';
import {STORAGE_KEYS} from '@/providers/session-provider/constants';
import {refreshTokens} from '@/providers/session-provider/utils';
import {useAppSelector} from '@/store';
import {useInvokeEmailResendingMutation} from '@/store/queries/emailVerifyApi';

export default function useEmailVerificationState() {
  const [emailVerified, setEmailVerified] = useState(false);
  const [[_, refreshToken]] = useStorageState(STORAGE_KEYS.REFRESH_TOKEN);
  const [isResending, setIsResending] = useState(false);
  const {getUserInfo} = useSession();
  const [invokeEmailResendingTrigger] = useInvokeEmailResendingMutation();
  const language = useAppSelector(state => state.app.language);

  const emailVerifiedChecking = useCallback(async () => {
    if (!refreshToken) return;

    const info = await getUserInfo();

    if (info?.email_verified) {
      await refreshTokens();
    }

    setEmailVerified(!!info?.email_verified);
  }, [getUserInfo, refreshToken]);

  useInterval(
    () => {
      emailVerifiedChecking().catch(captureException);
    },
    {delay: 3000}
  );

  const onEmailResend = useCallback(async () => {
    if (isResending) return;
    setIsResending(true);
    await invokeEmailResendingTrigger({locale: language});
    setTimeout(() => {
      setIsResending(false);
    }, 2000);
  }, [invokeEmailResendingTrigger, isResending, language]);

  return {emailVerified, onEmailResend, isResending};
}
