import {isAccountOrderExpired} from '@/helpers/isAccountOrderExpired';
import {useMfaAvailability} from '@/modules/mfa/hooks/useMfaAvailability';
import {useAppSelector} from '@/store';
import {useGetCustomerAccountsQuery} from '@/store/queries/customersApi';
import {
  useGetInterviewQuery,
  useGetLatestAcceptableInterviewsQuery,
} from '@/store/queries/interviewApi';
import {useGetValidOrdersQuery} from '@/store/queries/ordersApi';
import {skipToken} from '@reduxjs/toolkit/query';
import {useMemo} from 'react';

export type Recommendation = {
  dismissed: boolean;
  condition: boolean;
  loading: boolean;
};

export const useRecommendations = () => {
  const dismissedRecommendations = useAppSelector(state => state.app.dismissedRecommendations);
  //MFA Recomandation
  const {isSetup, isLoading: isMfaStatusLoading} = useMfaAvailability();
  const mfa: Recommendation = useMemo(
    () => ({
      dismissed: dismissedRecommendations?.mfa,
      condition: !isSetup,
      loading: isMfaStatusLoading,
    }),
    [dismissedRecommendations?.mfa, isMfaStatusLoading, isSetup]
  );

  //TopZins Recomandation
  const {data: customers, isLoading: isCustomersLoading} = useGetCustomerAccountsQuery();
  const {data: orders, isLoading: isOrdersLoading} = useGetValidOrdersQuery();

  const topzins: Recommendation = useMemo(
    () => ({
      condition: !(
        (customers && customers.items.filter(c => c.product === 'SAVINGS_ACCOUNT').length > 0) ||
        (orders &&
          orders.items
            .filter(item => !isAccountOrderExpired(item))
            .filter(o => o.account.product === 'SAVINGS_ACCOUNT').length > 0)
      ),
      dismissed: dismissedRecommendations?.topzins,
      loading: isCustomersLoading || isOrdersLoading,
    }),
    [customers, dismissedRecommendations?.topzins, isCustomersLoading, isOrdersLoading, orders]
  );

  //Friends Recomandation
  const friends: Recommendation = useMemo(
    () => ({
      condition: true,
      dismissed: dismissedRecommendations?.friends,
      loading: false,
    }),
    [dismissedRecommendations?.friends]
  );

  //SavingRate Recomandation
  const {data: interviews, isLoading: loadingInterviews} = useGetLatestAcceptableInterviewsQuery();
  const {data: lastInterview, isLoading: loadingParticularInterview} = useGetInterviewQuery(
    interviews?.[0]?.id !== undefined ? {id: interviews[0].id} : skipToken
  );
  const lastMonthlyNetIncome = useMemo(() => {
    return lastInterview?.MONTHLY_NET_INCOME;
  }, [lastInterview?.MONTHLY_NET_INCOME]);

  const sumSavingsRates = useMemo(() => {
    return customers?.items.reduce((acc, curr) => {
      return acc + (curr?.currentRecurringDeposit?.amount || 0);
    }, 0);
  }, [customers?.items]);
  // Show when: SUM(Savings rate) all subaccounts < 20% latest interview MONTHLY_NET_INCOME

  const savingrate: Recommendation = useMemo(
    () => ({
      condition: !(
        customers?.items?.[0]?.id === undefined ||
        sumSavingsRates === undefined ||
        lastMonthlyNetIncome === undefined ||
        sumSavingsRates >= 0.2 * lastMonthlyNetIncome
      ),
      dismissed: dismissedRecommendations?.savingsrate,
      loading: isCustomersLoading || loadingInterviews || loadingParticularInterview,
    }),
    [
      customers?.items,
      dismissedRecommendations?.savingsrate,
      isCustomersLoading,
      lastMonthlyNetIncome,
      loadingInterviews,
      loadingParticularInterview,
      sumSavingsRates,
    ]
  );

  const isSomeSmallToShow = useMemo(() => {
    return (
      (mfa.condition && !mfa.dismissed && !mfa.loading) ||
      (topzins.condition && !topzins.dismissed && !topzins.loading) ||
      (friends.condition && !friends.dismissed && !friends.loading) ||
      (savingrate.condition && !savingrate.dismissed && !savingrate.loading)
    );
  }, [
    mfa.condition,
    mfa.dismissed,
    mfa.loading,
    topzins.condition,
    topzins.dismissed,
    topzins.loading,
    friends.condition,
    friends.dismissed,
    friends.loading,
    savingrate.condition,
    savingrate.dismissed,
    savingrate.loading,
  ]);

  return {
    mfa,
    topzins,
    friends,
    savingrate,
    isSomeSmallToShow,
  };
};
