import {Label3} from '@/components/texts/Label';
import {PropsWithChildren} from 'react';
import {View, styled} from 'tamagui';

const StyledView = styled(View, {
  gap: '$2',
  variants: {
    horizontal: {
      false: {
        flexDirection: 'column',
      },
      true: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    },
  } as const,
});

export type WithLabelProps = {
  header: string;
  horizontal?: boolean;
};

export function WithLabel({header, children, horizontal}: PropsWithChildren<WithLabelProps>) {
  return (
    <StyledView horizontal={horizontal}>
      <Label3 color="$neutral500" flex={1}>
        {header}
      </Label3>
      {typeof children === 'string' ? (
        <Label3 flex={1} textAlign="right">
          {children}
        </Label3>
      ) : (
        children
      )}
    </StyledView>
  );
}
