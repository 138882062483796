import SvgFile from '@/components/icons/SvgFile';
import {Label2} from '@/components/texts/Label';
import {useTranslate} from '@tolgee/react';
import {XStack} from 'tamagui';

export function SustainableChoiceHint() {
  const {t} = useTranslate();

  return (
    <XStack alignItems="center" justifyContent="flex-start" gap="$2">
      <SvgFile name="green-leaf-circle" size={24} />
      <Label2 flex={1} color="$green">
        {t('STRATEGY_SELECTION.SUSTAINABLE_CHOICE_AVAILABLE')}
      </Label2>
    </XStack>
  );
}
