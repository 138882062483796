import React, {forwardRef, PropsWithChildren, useCallback} from 'react';
import {
  DialogHandle,
  GetProps,
  styled,
  Dialog as TamaguiDialog,
  View,
  ViewProps,
  VisuallyHidden,
  withStaticProperties,
} from 'tamagui';

import {Button} from '@/components/buttons/Button';
import {IconButton, IconButtonProps} from '@/components/buttons/IconButton';
import {DialogDescriptionFrame, DialogTitleFrame} from '@/components/dialog/Text';
import {SvgName} from '@/components/icons/SvgFile';
import {DialogRootProps} from '@/types/dialog';
import {ViewRef} from '@/types/refs';

const DialogTrigger = View.styleable((props, forwardRef) => {
  return (
    <TamaguiDialog.Trigger asChild>
      <View
        role="button"
        cursor="pointer"
        hoverStyle={{opacity: 0.85}}
        ref={forwardRef}
        {...props}
      />
    </TamaguiDialog.Trigger>
  );
});

const DialogButtonTrigger = Button.styleable((props, forwardRef) => {
  return (
    <DialogTrigger asChild>
      <Button ref={forwardRef} {...props} />
    </DialogTrigger>
  );
});

const DialogClose = TamaguiDialog.Close;

const DialogButtonClose = Button.styleable((props, forwardRef) => {
  return (
    <DialogClose asChild>
      <Button ref={forwardRef} {...props} />
    </DialogClose>
  );
});

type Props = {
  name?: SvgName;
  dialogIconContainerStyle?: Omit<ViewProps, 'children'>;
} & Omit<IconButtonProps, 'name'>;

const DialogIconButtonClose = forwardRef<ViewRef, Props>(
  ({dialogIconContainerStyle, name, ...rest}, ref) => (
    <View position="absolute" top="$2" right="$2" {...dialogIconContainerStyle}>
      <DialogClose asChild>
        <IconButton
          ref={ref}
          name={name ?? 'close'}
          size={24}
          color="$neutral300"
          containerStyle={{
            backgroundColor: '$neutralBG',
            hoverStyle: {opacity: 0.8},
            pressStyle: {opacity: 0.8},
          }}
          {...rest}
        />
      </DialogClose>
    </View>
  )
);

const DialogTitle = DialogTitleFrame;

const DialogDescription = DialogDescriptionFrame;

const StyledDialogContent = styled(TamaguiDialog.Content, {
  backgroundColor: '$surface',
  borderRadius: '$1',
  animateOnly: ['transform', 'opacity'],
  animation: 'slow',
  enterStyle: {opacity: 0, y: 50},
  exitStyle: {opacity: 0},
  maxWidth: 900,
  maxHeight: '85%',
  padding: '$4',
  borderWidth: 0,
  variants: {
    side: {
      true: {
        maxHeight: '100%',
        borderTopLeftRadius: '$1',
        borderBottomLeftRadius: '$1',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        width: 450,
        marginRight: 0,
        marginLeft: 'auto',
        enterStyle: {x: '110%', y: 0},
        exitStyle: {x: '110%'},
        height: '100%',
      },
    },
  } as const,
});

export type DialogContentProps = GetProps<typeof StyledDialogContent>;

const DialogContent = ({children, ...rest}: PropsWithChildren<DialogContentProps>) => {
  return (
    <TamaguiDialog.Portal>
      <TamaguiDialog.Overlay
        key="dialog-overlay"
        opacity={0.4}
        enterStyle={{opacity: 0}}
        exitStyle={{opacity: 0}}
        animation="slow"
        backgroundColor="black"
      />
      <StyledDialogContent key="dialog-content" {...rest}>
        <VisuallyHidden>
          <TamaguiDialog.Title />
        </VisuallyHidden>
        <VisuallyHidden>
          <TamaguiDialog.Description />
        </VisuallyHidden>
        {children}
      </StyledDialogContent>
    </TamaguiDialog.Portal>
  );
};

export const Root = forwardRef<DialogHandle, PropsWithChildren<DialogRootProps>>(
  ({children, open, defaultOpen, onOpenChange, onDismiss}, ref) => {
    const handleOpenChanged = useCallback(
      (open: boolean) => {
        if (onOpenChange) {
          onOpenChange(open);
        }

        if (!open && onDismiss) {
          onDismiss();
        }
      },
      [onOpenChange, onDismiss]
    );

    return (
      <TamaguiDialog
        ref={ref}
        modal
        open={open}
        defaultOpen={defaultOpen}
        onOpenChange={handleOpenChanged}
      >
        {children}
      </TamaguiDialog>
    );
  }
);

export const Dialog = withStaticProperties(Root, {
  Content: DialogContent,
  Trigger: DialogTrigger,
  ButtonTrigger: DialogButtonTrigger,
  Close: DialogClose,
  ButtonClose: DialogButtonClose,
  IconClose: DialogIconButtonClose,
  Title: DialogTitle,
  Description: DialogDescription,
});
