import {Card} from '@/components/cards/Card';
import {useSelectInput} from '@/hooks/useSelectInput';
import {SelectInputItem} from '@/types/FormInputProps';
import {SelectInputAddressProps} from '@/types/SelectInput';
import {FlashList} from '@shopify/flash-list';
import {useFocusEffect} from 'expo-router';
import {PropsWithChildren, useCallback, useMemo, useRef, useState} from 'react';
import {Portal, View, useWindowDimensions} from 'tamagui';

const CONTEXT_MENU_WINDOW_PADDING = 8;

export const SelectInputAddress = ({
  items,
  onSelect,
  selectedItem,
  renderItem: renderItemProps,
  additionalContent,
  align,
}: PropsWithChildren<SelectInputAddressProps>) => {
  const [open, setOpen] = useState(true);
  const [listSize, setListSize] = useState({width: 0, height: 0});
  const {width, height} = useWindowDimensions();
  const flashListRef = useRef<FlashList<SelectInputItem>>(null);
  const [position, setPosition] = useState({left: 0, top: 0, width: 0, height: 0});
  // @ts-ignore
  const portalAnchorRef = useRef<View>(null);

  const menuOffset = useMemo(() => {
    return {
      top:
        position.top +
        Math.min(0, height - position.top - listSize.height - 2 * CONTEXT_MENU_WINDOW_PADDING) +
        position.height +
        1,
      left: position.left,
    };
  }, [position.left, position.top, position.height, height, listSize.height]);

  const menuHeight = useMemo(() => {
    return height - 4 * CONTEXT_MENU_WINDOW_PADDING;
  }, [height]);

  const onCardLayout = useCallback((e: any) => {
    setListSize({width: e.nativeEvent.layout.width, height: e.nativeEvent.layout.height});
  }, []);

  const handleFlashListOnLayout = useCallback(() => {
    if (!flashListRef.current) return;

    const selectedIndex = items.findIndex(item => item.key === selectedItem);

    if (selectedIndex === -1) return;

    flashListRef.current.scrollToIndex({index: selectedIndex});
  }, [items, selectedItem]);

  const handleSelect = useCallback(
    (item: SelectInputItem) => {
      onSelect(item.key);
      setOpen(false);
    },
    [onSelect]
  );

  const {renderItem} = useSelectInput(selectedItem, renderItemProps, handleSelect);

  useFocusEffect(
    useCallback(() => {
      if (items.length > 0) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }, [items])
  );

  return (
    <>
      <View
        ref={portalAnchorRef}
        onLayout={() => {
          portalAnchorRef.current &&
            (portalAnchorRef.current as any).measure(
              (fx: any, fy: any, width: any, height: any, px: any, py: any) => {
                setPosition({left: px, top: py, width, height});
              }
            );
        }}
      />
      {open && (
        <Portal onPress={() => setOpen(false)} pointerEvents="auto">
          <Card
            width={position.width}
            borderRadius="$1"
            overflow="hidden"
            maxHeight={menuHeight}
            maxWidth={width - 4 * CONTEXT_MENU_WINDOW_PADDING}
            padding={0}
            onLayout={onCardLayout}
            position="absolute"
            left={menuOffset.left}
            top={menuOffset.top}
            shadowOpacity={1}
            shadowOffset={{width: 0, height: 4}}
          >
            {additionalContent}
            <FlashList
              ref={flashListRef}
              renderItem={renderItem}
              data={items}
              estimatedItemSize={20}
              keyExtractor={({key}) => key}
              onLayout={handleFlashListOnLayout}
              keyboardShouldPersistTaps="always"
              contentContainerStyle={{paddingHorizontal: 8}}
            />
          </Card>
        </Portal>
      )}
    </>
  );
};
