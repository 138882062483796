import {useTranslate} from '@tolgee/react';
import {Control, useWatch} from 'react-hook-form';
import {YStack} from 'tamagui';

import {Label3} from '@/components/texts/Label';
import {isUpvestCustomer} from '@/helpers/isUpvestCustomer';
import {DepositWireMethodContent} from '@/modules/orders/components/DepositMethod/DepositWireMethodContent';
import {SecurityTransferInfoBlock} from '@/modules/orders/components/DepositMethod/SecurityTransferInfoBlock';
import {SecurityTransferTaxBlock} from '@/modules/orders/components/DepositMethod/SecurityTransferTaxBlock';
import {InfoBox} from '@/modules/orders/components/InfoBox';
import {DepositMethodType} from '@/modules/orders/types/DepositMethod';
import {useGetCustomerAccountQuery} from '@/store/queries/customersApi';

export function DepositMethodAccordionContent({
  customerId,
  depositMethod,
  control,
}: {
  customerId: string;
  depositMethod: DepositMethodType;
  control: Control;
}) {
  const {t} = useTranslate();
  const amount = useWatch({control, name: 'deposit'});
  const {data: customer} = useGetCustomerAccountQuery(customerId);
  const isUpvest = isUpvestCustomer(customer);

  switch (depositMethod) {
    case 'direct':
      return (
        <YStack gap="$1">
          <Label3>
            {t('ORDERS.DEPOSIT_METHOD.DIRECT')}
            {isUpvest && (
              <Label3 variant="medium"> {t('ORDERS.DEPOSIT_METHOD.DIRECT_UPVEST')}</Label3>
            )}
          </Label3>
          {!isUpvest && <InfoBox text={t('ORDERS.DEPOSIT_METHOD.DIRECT_NOTE')} />}
        </YStack>
      );
    case 'wire':
      return <DepositWireMethodContent customerId={customerId} amount={amount} />;
    case 'transfer':
      return (
        <YStack gap="$4">
          <Label3>{t('ORDERS.DEPOSIT_METHOD.TRANSFER')}</Label3>
          <SecurityTransferInfoBlock />
          <SecurityTransferTaxBlock customerId={customerId} />
        </YStack>
      );
  }
}
