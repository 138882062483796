import {LogoutButton} from '@/components/buttons/LogoutButton';
import {Card} from '@/components/cards/Card';
import SvgFile from '@/components/icons/SvgFile';
import {SideNavigationItem} from '@/modules/navigation/components/SideNavigationItem';
import {SideNavigationSubItem} from '@/modules/navigation/components/SideNavigationSubItem';
import {LanguageSelector} from '@/modules/profile/components/LanguageSelector';
import {useGetCustomerAccountsQuery} from '@/store/queries/customersApi';
import {skipToken} from '@reduxjs/toolkit/query';
import {useTranslate} from '@tolgee/react';
import {useRouter} from 'expo-router';
import {PropsWithChildren, useMemo} from 'react';
import {
  ScrollView,
  View,
  XStack,
  YStack,
  isTouchable,
  useMedia,
  useTheme,
  useWindowDimensions,
} from 'tamagui';

type Props = {
  show?: boolean;
};

export function SideNavigation({children, show = true}: PropsWithChildren<Props>) {
  const theme = useTheme();
  const {t} = useTranslate();
  const media = useMedia();
  const router = useRouter();
  const {width} = useWindowDimensions();
  const {data: customers, isFetching: isCustomersFetching} = useGetCustomerAccountsQuery(
    !show ? skipToken : undefined
  );
  const innerShow = useMemo(() => (media.sm || isTouchable ? false : show), [media.sm, show]);

  return (
    <XStack flex={1} flexBasis={0} marginLeft={innerShow ? Math.max(0, (width - 1300) / 2) : 0}>
      {innerShow && (
        <YStack width={300} paddingVertical="$7" paddingRight="$2">
          <Card alignSelf="flex-start" flex={1} padding={0} width="100%">
            <ScrollView contentContainerStyle={{flex: 1}}>
              <YStack justifyContent="space-between" flex={1}>
                <YStack>
                  <View
                    marginLeft="$7"
                    marginTop="$7"
                    marginBottom="$8"
                    width={117}
                    alignSelf="flex-start"
                    onPress={() => {
                      router.navigate('/');
                    }}
                    cursor="pointer"
                    hoverStyle={{
                      opacity: 0.8,
                    }}
                  >
                    <SvgFile color={theme.primary.val} name="ginmon" width={117} />
                  </View>
                  <SideNavigationItem
                    href="/overview"
                    title={t('NAVIGATION.OVERVIEW')}
                    icon="overview"
                  >
                    {!!customers &&
                      customers.items.length > 0 &&
                      customers.items.map(customer => (
                        <SideNavigationSubItem
                          key={customer.id}
                          href={{
                            pathname: '/overview/[customerId]',
                            params: {customerId: customer.id},
                          }}
                          title={customer.displayName}
                        />
                      ))}
                  </SideNavigationItem>
                  <SideNavigationItem
                    href="/recommendations"
                    title={t('NAVIGATION.RECOMMENDATIONS')}
                    icon="recommendations"
                  />
                  <SideNavigationItem
                    href="/profile/personal-data"
                    title={t('NAVIGATION.PROFILE')}
                    icon="profile"
                  >
                    <SideNavigationSubItem
                      href="/profile/personal-data"
                      title={t('NAVIGATION.PROFILE.PERSONAL_DATA')}
                    />
                    <SideNavigationSubItem
                      href="/profile/taxes"
                      title={t('NAVIGATION.PROFILE.TAXES')}
                    />
                    <SideNavigationSubItem
                      href="/profile/documents"
                      title={t('NAVIGATION.PROFILE.DOCUMENTS')}
                    />
                    <SideNavigationSubItem
                      href="/profile/security"
                      title={t('NAVIGATION.PROFILE.SECURITY')}
                    />
                    <SideNavigationSubItem
                      href="/profile/display-and-language"
                      title={t('SETTINGS.DISPLAY_AND_LANGUAGE')}
                    />
                  </SideNavigationItem>
                  <SideNavigationItem
                    href="/friends"
                    title={t('NAVIGATION.FRIENDS')}
                    icon="friends"
                  />
                </YStack>
                <YStack display="flex" justifyContent="space-between" paddingBottom="$2">
                  <LanguageSelector />
                  <LogoutButton variant="large" />
                </YStack>
              </YStack>
            </ScrollView>
          </Card>
        </YStack>
      )}
      <View flex={1} flexBasis={0}>
        {children}
      </View>
    </XStack>
  );
}
