import {Button} from '@/components/buttons/Button';
import {Modal} from '@/components/modal/Modal';
import {Heading5} from '@/components/texts/Heading';
import {Label2} from '@/components/texts/Label';
import {Paragraph2} from '@/components/texts/Paragraph';
import {useTokenUserInfo} from '@/hooks/useTokenUserInfo';
import {useSession} from '@/providers/session-provider/SessionProvider';
import {useAppDispatch} from '@/store';
import {useFetchReferrerQuery} from '@/store/queries/referralApi';
import {setReferralCode} from '@/store/reducers/onboarding';
import {ModalRef} from '@/types/modal';
import {skipToken} from '@reduxjs/toolkit/query';
import {useTranslate} from '@tolgee/react';
import {useLocalSearchParams, useRouter} from 'expo-router';
import {useCallback, useEffect, useRef} from 'react';
import {View, XStack, YStack} from 'tamagui';

export function ReferralCodeModal() {
  const {r, ...rest} = useLocalSearchParams();
  const dispatch = useAppDispatch();
  const modalRef = useRef<ModalRef>(null);
  const {t} = useTranslate();
  const router = useRouter();
  const {session} = useSession();
  const userInfo = useTokenUserInfo();
  const {
    data: referrer,
    isFetching: isReferrerFetching,
    status,
  } = useFetchReferrerQuery(userInfo?.user_id ? {id: userInfo?.user_id} : skipToken);

  const onAccept = useCallback(() => {
    modalRef.current?.open(false);
    dispatch(setReferralCode(r as string));
    router.setParams(rest);
  }, [dispatch, r, rest, router]);

  const onDecline = useCallback(() => {
    modalRef.current?.open(false);
    dispatch(setReferralCode());
    router.setParams(rest);
  }, [dispatch, rest, router]);

  useEffect(() => {
    // for public interview we show referrer code no matter what
    // for user aware interview we show referrer code only if it's not the one from user
    if (
      r !== 'undefined' &&
      typeof r === 'string' &&
      (!session || (referrer?.referrerCode !== undefined && r !== referrer?.referrerCode))
    ) {
      modalRef.current?.open(true);
    }
  }, [dispatch, isReferrerFetching, r, referrer?.referrerCode, session, status]);

  return (
    <Modal ref={modalRef}>
      <Modal.Content typeContainer="SCROLL">
        <YStack gap="$4" maxWidth={500}>
          <Modal.Title asChild="except-style">
            <Heading5 variant="medium">{t('INTERVIEW.REFERRAL_MODAL.TITLE')}</Heading5>
          </Modal.Title>
          <Paragraph2 color="$neutral400">{t('INTERVIEW.REFERRAL_MODAL.POINT_1')}</Paragraph2>
          <Paragraph2 color="$neutral400">{t('INTERVIEW.REFERRAL_MODAL.POINT_2')}</Paragraph2>

          <YStack alignSelf="center" padding="$4" backgroundColor="$neutralBG" borderRadius="$1">
            <Label2 color="$neutral500" textAlign="center">
              {t('INTERVIEW.REFERRAL_MODAL.CODE')}
            </Label2>
            <Label2 color="$primary" textAlign="center" variant="medium">
              {r}
            </Label2>
          </YStack>

          <XStack gap="$2">
            <View flex={1}>
              <Button secondary alignSelf="stretch" onPress={onDecline}>
                {t('INTERVIEW.REFERRAL_MODAL.NO')}
              </Button>
            </View>
            <View flex={1}>
              <Button alignSelf="stretch" onPress={onAccept}>
                {t('INTERVIEW.REFERRAL_MODAL.YES')}
              </Button>
            </View>
          </XStack>
        </YStack>
      </Modal.Content>
    </Modal>
  );
}
