import {Redirect, Stack, useRouter, useUnstableGlobalHref} from 'expo-router';

import useAppState from '@/hooks/useAppState';
import {useScreenOptions} from '@/modules/navigation/hooks/useScreenOptions';
import {useSession} from '@/providers/session-provider/SessionProvider';
import {isSessionStillValid} from '@/providers/session-provider/utils';
import React from 'react';
import {Platform} from 'react-native';

export default function AppLayout() {
  const {session, getIsActive} = useSession();
  const screenOptions = useScreenOptions({headerShown: false});
  const url = useUnstableGlobalHref();
  const router = useRouter();
  const {appState} = useAppState({
    onForeground: () => {
      try {
        if (!isSessionStillValid()) router.replace('/login');
      } catch (err) {}
    },
  });

  if (!session || (Platform.OS !== 'web' && getIsActive() === false)) {
    return (
      <Redirect
        href={{
          pathname: '/login',
          params: {url},
        }}
      />
    );
  }

  return <Stack screenOptions={screenOptions} />;
}
