import {useTranslate} from '@tolgee/react';
import {Stack} from 'expo-router';

import {Heading4} from '@/components/texts/Heading';
import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {ProductChoiceList} from '@/modules/interview/components/ProductChoiceList';
import {ReferralCodeModal} from '@/modules/interview/components/ReferralCodeModal';
import {Header} from '@/modules/navigation/components/header/Header';

export default function Page() {
  const {t} = useTranslate();

  return (
    <FullscreenScrollView variant="large">
      <Stack.Screen
        options={{
          header: () => (
            <Header
              withSidePanel={false}
              style={{
                '$platform-web': {
                  alignSelf: 'center',
                  maxWidth: 1000,
                },
              }}
            />
          ),
        }}
      />
      <Heading4 variant="medium">{t('STRATEGY_SELECTION.CHOOSE_A_PRODUCT')}</Heading4>
      <ProductChoiceList />
      <ReferralCodeModal />
    </FullscreenScrollView>
  );
}
