import SvgFile from '@/components/icons/SvgFile';
import {Label2} from '@/components/texts/Label';
import {DepositMethodType} from '@/modules/orders/types/DepositMethod';
import {useTranslate} from '@tolgee/react';
import {XStack} from 'tamagui';

// TODO: add variant with radio button
export function DepositMethodAccordionTriggerContent({
  depositMethod,
}: {
  depositMethod: DepositMethodType;
}) {
  const {t} = useTranslate();
  switch (depositMethod) {
    case 'direct':
      return (
        <XStack gap="$2">
          <SvgFile name="deposit-direct" withCircle="onSurface" color="$green" size={16} />
          <Label2 variant="medium">{t('ORDER_SUMMARY.DEPOSIT_MENU.DIRECT')}</Label2>
        </XStack>
      );
    case 'wire':
      return (
        <XStack gap="$2">
          <SvgFile name="deposit-wire" withCircle="onSurface" color="$orange" size={16} />
          <Label2 variant="medium">{t('ORDER_SUMMARY.DEPOSIT_MENU.WIRE')}</Label2>
        </XStack>
      );
    case 'transfer':
      return (
        <XStack gap="$2">
          <SvgFile name="deposit-transfer" withCircle="onSurface" color="$cerulean" size={16} />
          <Label2 variant="medium">{t('ORDER_SUMMARY.DEPOSIT_MENU.TRANSFER')}</Label2>
        </XStack>
      );
  }
}
