import {BaseInput} from '@/components/inputs/form/BaseInput';
import {FormDocumentUpload} from '@/components/inputs/form/FormDocumentUpload';
import {NationalityDocumentHintModal} from '@/components/inputs/form/nationality/NationalityDocumentHintModal';
import {OnboardingAddItem} from '@/components/inputs/form/OnboardingAddItem';
import {OnboardingRemoveItem} from '@/components/inputs/form/OnboardingRemoveItem';
import {SelectInput} from '@/components/inputs/select-input/SelectInput';
import {InputErrorMessage} from '@/components/texts/InputErrorMessage';
import {Label3} from '@/components/texts/Label';
import {Skeleton} from '@/components/views/Skeleton';
import {IMPORTANT_COUNTRIES} from '@/constants/countries';
import {useAppSelector} from '@/store';
import {useCountriesQuery, useIdentityProofsQuery} from '@/store/queries/referenceApi';
import {FieldArrayFormInputProps} from '@/types/FormInputProps';
import {useTranslate} from '@tolgee/react';
import {toast} from 'burnt';
import {useMemo} from 'react';
import {ArrayPath, Controller, FieldArray, FieldValues, Path, useFieldArray} from 'react-hook-form';
import {XStack, YStack} from 'tamagui';

const LIMIT_NATIONALITIES = 3;

export function FormNationalitySelect<T extends FieldValues>({
  control,
  name,
  bordered,
  label,
  userID,
}: FieldArrayFormInputProps<T> & {userID: number | undefined}) {
  const {t} = useTranslate();
  const language = useAppSelector(state => state.app.language);
  const {data: countries, isFetching: isCountriesFetching} = useCountriesQuery();
  const {data: proofs} = useIdentityProofsQuery();
  const {fields, append, remove} = useFieldArray({
    control,
    name,
  });

  const reorderedCountries = useMemo(() => {
    if (!countries) return undefined;
    const res = countries
      .map(_ => ({
        name: _.label[language],
        key: _.code,
      }))
      .sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        } else {
          return 1;
        }
      });
    return [
      ...res.filter(_ => IMPORTANT_COUNTRIES.includes(_.key)),
      ...res.filter(_ => !IMPORTANT_COUNTRIES.includes(_.key)),
    ];
  }, [countries, language]);

  return (
    <YStack gap="$4">
      <YStack gap="$9">
        {countries &&
          userID &&
          reorderedCountries &&
          fields.map((fieldItem, index) => {
            return (
              <YStack key={fieldItem.id} gap="$2">
                <XStack justifyContent="space-between">
                  <Label3 color="$neutral500">{label}</Label3>
                  {index !== 0 && (
                    <OnboardingRemoveItem
                      label={t('PERSONAL_DETAILS.NATIONALITY.REMOVE')}
                      onPress={() => remove(index)}
                    />
                  )}
                </XStack>
                <Controller
                  render={({field, fieldState: {error}}) => {
                    const proof = proofs?.find(p => field.value === p.country);
                    return (
                      <YStack gap="$6">
                        <YStack gap="$2">
                          <SelectInput
                            key={fieldItem.id}
                            items={reorderedCountries}
                            selectedItem={field.value}
                            onSelect={field.onChange}
                            placeholder="--"
                            wrapperProps={{
                              borderWidth: 0,
                              bordered,
                            }}
                          />
                          <InputErrorMessage error={error?.message} />
                        </YStack>
                        {proof && (
                          <YStack gap="$2" maxWidth={500}>
                            <Controller
                              render={({field: numberField, fieldState: {error}}) => (
                                <YStack gap="$2">
                                  <Label3 color="$neutral500">{proof.label[language]}</Label3>
                                  <BaseInput
                                    key={fieldItem.id}
                                    {...numberField}
                                    onChangeText={numberField.onChange}
                                  />
                                  <InputErrorMessage error={error?.message} />
                                </YStack>
                              )}
                              name={`${name}.${index}.number` as Path<T>}
                              control={control}
                            />
                          </YStack>
                        )}
                        {proof?.uploadRequired && (
                          <YStack gap="$2">
                            <FormDocumentUpload
                              uploadAreaStyle={{minHeight: 150}}
                              control={control}
                              name={`${name}.${index}.document` as Path<T>}
                              userID={userID}
                              fileContextPrefix="identity-proof"
                              maxFileSize={1000 * 1000 * 20} // 20 MB
                              multi={false}
                              onError={reason => {
                                switch (reason) {
                                  case 'FILE_SIZE':
                                    toast({
                                      preset: 'error',
                                      title: t('PROOF_OF_RESIDENCE.DOCUMENT.FILE_TO_BIG'),
                                    });
                                    break;
                                  case 'FILE_FORMAT':
                                    toast({
                                      preset: 'error',
                                      title: t('PROOF_OF_RESIDENCE.DOCUMENT.FILE_WRONG_FORMAT'),
                                    });
                                }
                              }}
                            />
                            <InputErrorMessage error={error?.message} />
                            <NationalityDocumentHintModal />
                          </YStack>
                        )}
                      </YStack>
                    );
                  }}
                  name={`${name}.${index}.country` as Path<T>}
                  control={control}
                />
              </YStack>
            );
          })}
      </YStack>
      {isCountriesFetching && <Skeleton width="100%" />}
      {fields.length < LIMIT_NATIONALITIES && (
        <OnboardingAddItem
          label={t('PERSONAL_DETAILS.NATIONALITY.ADD')}
          onPress={() => append({} as FieldArray<T, ArrayPath<T>>)}
        />
      )}
    </YStack>
  );
}
