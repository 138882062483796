import {Surface} from '@/components/views/Surface';
import {styled, useMedia} from 'tamagui';

// TODO: I think we need to do the same as for BaseInput and pass borderedDisabled as prop combination, can be solved in FRONTEND-252
export const SelectInputWrapperBase = styled(Surface, {
  flexDirection: 'row',
  alignItems: 'center',
  userSelect: 'none',
  cursor: 'pointer',
  gap: '$2',
  justifyContent: 'space-between',
  padding: '$6',

  variants: {
    unstyled: {
      true: {
        padding: 0,
        borderWidth: 0,
        borderRadius: 0,
        backgroundColor: 'transparent',
        shadowColor: 'transparent',
        elevation: 0,
        elevationAndroid: 0,
      },
    },
    bordered: {
      true: {
        padding: '$3',
        borderWidth: 1,
        borderColor: '$neutral200',
        borderRadius: '$0.25',
        backgroundColor: '$surface',
        shadowColor: 'transparent',
        elevation: 0,
        elevationAndroid: 0,
      },
    },
    disabled: {
      true: {
        disabled: true,
        cursor: 'auto',
        backgroundColor: '$neutral50',
      },
    },
    borderedDisabled: {
      true: {
        backgroundColor: '$neutral50',
      },
    },
  } as const,
  defaultVariants: {
    bordered: false,
    disabled: false,
    borderedDisabled: false,
    unstyled: false,
  },
});

export const SelectInputWrapperBaseSm = styled(Surface, {
  flexDirection: 'row',
  alignItems: 'center',
  userSelect: 'none',
  cursor: 'pointer',
  gap: '$2',
  justifyContent: 'space-between',
  padding: '$4',
  borderRadius: '$0.75',
  pressStyle: {
    opacity: 0.8,
  },

  variants: {
    unstyled: {
      true: {
        padding: 0,
        borderWidth: 0,
        borderRadius: 0,
        backgroundColor: 'transparent',
        shadowColor: 'transparent',
        elevation: 0,
        elevationAndroid: 0,
      },
    },
    bordered: {
      true: {
        padding: '$3',
        borderWidth: 1,
        borderColor: '$neutral200',
        borderRadius: '$0.25',
        backgroundColor: '$surface',
        shadowColor: 'transparent',
        elevation: 0,
        elevationAndroid: 0,
        height: 50,
      },
    },
    disabled: {
      true: {
        disabled: true,
        cursor: 'auto',
        backgroundColor: '$neutral50',
      },
    },
    borderedDisabled: {
      true: {
        backgroundColor: '$neutral50',
      },
    },
  } as const,
  defaultVariants: {
    bordered: false,
    disabled: false,
    borderedDisabled: false,
    unstyled: false,
  },
});

export const SelectInputWrapper = SelectInputWrapperBase.styleable((props, ref) => {
  const media = useMedia();
  if (media.sm)
    return (
      <SelectInputWrapperBaseSm
        ref={ref}
        {...props}
        borderedDisabled={props.bordered && props.disabled}
      />
    );
  return (
    <SelectInputWrapperBase
      ref={ref}
      {...props}
      borderedDisabled={props.bordered && props.disabled}
    />
  );
});
