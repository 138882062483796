import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslate} from '@tolgee/react';
import {useRouter} from 'expo-router';
import {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {YStack} from 'tamagui';

import {FormDateInput} from '@/components/inputs/form/FormDateInput';
import {FormTextInput} from '@/components/inputs/form/FormTextInput';
import {Heading4} from '@/components/texts/Heading';
import {Label2} from '@/components/texts/Label';
import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {Note} from '@/components/views/Note';
import {useValidations} from '@/hooks/useValidations';
import {InterviewContinueButton} from '@/modules/interview/components/InterviewContinueButton';
import {UserDetailsForm} from '@/modules/onboarding/components/forms/UserDetailsForm';
import {useOnboardingSearchParams} from '@/modules/onboarding/hooks/useOnboardingSearchParams';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useAppDispatch, useAppSelector} from '@/store';
import {useGetAvailableUsersQuery} from '@/store/queries/usersApi';
import {setAnotherGuardianInfo} from '@/store/reducers/onboarding';
import {WithGuardianType} from '@/types/routeParams';

type AnotherGuardianInfoValues = {
  guardianType: string;
  selfEmail: string;
  title?: string;
  firstName: string;
  lastName: string;
  dateOfBirth?: string;
  email?: string;
};

const AnotherGuardianInfo = () => {
  const {t} = useTranslate();

  const router = useRouter();

  const [childName, setChildName] = useState('');

  const params = useOnboardingSearchParams<WithGuardianType>();

  const dispatch = useAppDispatch();
  const posthog = usePostHog();

  const anotherGuardian = useAppSelector(state => state.onboarding.personalDetails.anotherGuardian);

  const {anotherGuardianFormSchema} = useValidations();

  const {data} = useGetAvailableUsersQuery();

  const {control, handleSubmit, setValue} = useForm<AnotherGuardianInfoValues>({
    resolver: yupResolver(anotherGuardianFormSchema),
    defaultValues: {guardianType: params.guardianType, ...(anotherGuardian ?? {})},
    mode: 'all',
  });

  useEffect(() => {
    if (!data || !params.childID) return;

    const self = data.find(user => user.type === 'SELF');

    if (self) {
      setValue('selfEmail', self.email);
    }

    const child = data.find(user => user.id === Number(params.childID));

    if (child) {
      setChildName(child.firstName ?? '');
    }
  }, [data, params.childID, setValue]);

  const handleValidSubmit = useCallback(
    (data: AnotherGuardianInfoValues) => {
      const {title, firstName, lastName, dateOfBirth, email} = data;

      if (!title || !dateOfBirth || !email) return;

      dispatch(
        setAnotherGuardianInfo({
          title,
          firstName,
          lastName,
          dateOfBirth,
          email,
        })
      );

      router.navigate({pathname: '/onboarding/personal-details/child-docs', params});
    },
    [dispatch, params, router]
  );

  return (
    <FullscreenScrollView variant="small" useKeyboardAwareScrollView>
      <YStack gap="$6">
        <Heading4 variant="medium">{t('PERSONAL_DETAILS.ANOTHER_GUARDIAN_INFO.TITLE')}</Heading4>
        <UserDetailsForm control={control} showAcademicTitle={false} />
        <FormDateInput control={control} name="dateOfBirth" label={t('FORM.LABELS.BIRTH_DATE')} />
        <FormTextInput
          control={control}
          name="email"
          label={t('PERSONAL_DETAILS.ANOTHER_GUARDIAN.EMAIL')}
          textInputProps={{placeholder: t('PERSONAL_DETAILS.ANOTHER_GUARDIAN.EMAIL')}}
        />
        <InterviewContinueButton
          width="100%"
          maxWidth={400}
          alignSelf="center"
          onPress={handleSubmit(handleValidSubmit, data => {
            posthog?.capture('form_submit_failed', data);
          })}
        >
          {t('CONTINUE')}
        </InterviewContinueButton>
        <Note
          icon={{name: 'info', color: '$primary', size: 24}}
          title={
            <Label2 color="$neutral400">
              <Label2 variant="medium">{t('INTERNAL_TRANSFER.TRANSFER_WARNING_6')}</Label2>
              {t('PERSONAL_DETAILS.ANOTHER_GUARDIAN.NOTE', {userName: childName})}
            </Label2>
          }
          asChild="except-style"
        />
      </YStack>
    </FullscreenScrollView>
  );
};

export default AnotherGuardianInfo;
