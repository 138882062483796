import {skipToken} from '@reduxjs/toolkit/query';
import {useTranslate} from '@tolgee/react';
import {useRouter} from 'expo-router';
import {useCallback} from 'react';
import {View, YStack} from 'tamagui';

import {Heading6} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {FullscreenScrollView} from '@/components/views/FullscreenScrollView';
import {LoadingSpinner} from '@/components/views/LoadingSpinner';
import {FinancialSituationScreen} from '@/modules/interview/components/FinancialSituationScreen';
import {TipWithSideModal} from '@/modules/interview/components/TipWithSideModal';
import {useOnboardingSearchParams} from '@/modules/onboarding/hooks/useOnboardingSearchParams';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';

export default function InputsPage() {
  const {t} = useTranslate();

  const router = useRouter();

  const params = useOnboardingSearchParams();

  const {data: selfUser, isFetching} = useGetSelfUserQuery(
    params.onboardingType !== 'second_guardian' ? skipToken : undefined
  );

  const tipContent = (
    <YStack gap="$5" marginVertical="$5">
      <Heading6 variant="medium">{t('INTERVIEW.FAQ.MONTLY.HEADER')}</Heading6>
      <Paragraph2 variant="medium">{t('INTERVIEW.FAQ.MONTLY.TITLE_DESCRIPTION_1')}</Paragraph2>
      <Paragraph2 color="$neutral400">{t('INTERVIEW.FAQ.MONTLY.DESCRIPTION_1')}</Paragraph2>
      <Paragraph2 variant="medium">{t('INTERVIEW.FAQ.MONTLY.TITLE_DESCRIPTION_2')}</Paragraph2>
      <Paragraph2 color="$neutral400">{t('INTERVIEW.FAQ.MONTLY.DESCRIPTION_2')}</Paragraph2>
      <Paragraph2 variant="medium">{t('INTERVIEW.FAQ.MONTLY.TITLE_DESCRIPTION_3')}</Paragraph2>
      <Paragraph2 color="$neutral400">{t('INTERVIEW.FAQ.MONTLY.DESCRIPTION_3')}</Paragraph2>
      <Paragraph2 variant="medium">{t('INTERVIEW.FAQ.MONTLY.TITLE_DESCRIPTION_4')}</Paragraph2>
      <Paragraph2 color="$neutral400">{t('INTERVIEW.FAQ.MONTLY.DESCRIPTION_4')}</Paragraph2>
    </YStack>
  );

  const handleContinue = useCallback(
    (riskProfile?: string) => {
      const screenType = selfUser?.complete ? 'child' : undefined;

      router.navigate(
        params.onboardingType === 'second_guardian'
          ? {pathname: '/onboarding/personal-details/summary', params: {...params, screenType}}
          : {
              pathname:
                params.product === 'THEME_ACCOUNT'
                  ? '/interview/planner'
                  : '/interview/question/deposits',
              params: {product: params.product, riskProfile},
            }
      );
    },
    [params, router, selfUser]
  );

  return (
    <FullscreenScrollView variant="small" useKeyboardAwareScrollView>
      <FinancialSituationScreen
        isSecondGuardian={params.onboardingType === 'second_guardian'}
        onContinue={handleContinue}
      />
      <TipWithSideModal tipTitle={t('INTERVIEW.FAQ.MONTLY.TITLE')} tipContent={tipContent} />
      {isFetching && (
        <View
          flex={1}
          position="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          justifyContent="center"
          alignItems="center"
        >
          <LoadingSpinner color="$primary" size={64} />
        </View>
      )}
    </FullscreenScrollView>
  );
}
