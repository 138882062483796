import {baseApi} from '@/store/queries/baseApi';
import {ReferralsResponse} from '@/types/api/referrals';

const BASE_PATH = 'referrals';

const extendedReferralApi = baseApi.injectEndpoints({
  endpoints: build => ({
    fetchReferrer: build.query<ReferralsResponse, {id: string}>({
      query: ({id}) => ({
        url: `${BASE_PATH}/${id}`,
      }),
    }),
  }),
});

export const {useFetchReferrerQuery} = extendedReferralApi;
