import {useTranslate} from '@tolgee/react';
import {XStack, YStack} from 'tamagui';

import {Label2, Label3sm2} from '@/components/texts/Label';
import {LocalizableRefData} from '@/hooks/useRefdataLocalizable';
import {InvestmentStrategy} from '@/types/api/refdata.v2';

export type Strategy = LocalizableRefData<InvestmentStrategy> & {riskProfileLabel: string};

type Props = {
  currentStrategyLabel?: string;
  newStrategyLabel?: string;
};

export const ComparisonStrategyName = ({currentStrategyLabel, newStrategyLabel}: Props) => {
  const {t} = useTranslate();

  return (
    <XStack justifyContent="space-between">
      <YStack flexShrink={1}>
        <Label2 variant="medium">{t('CHANGE_STRATEGY.COMPARISON.STRATEGY_COMPARISON')}</Label2>
      </YStack>
      <YStack flexShrink={1}>
        <XStack flexShrink={1} gap="$3">
          <YStack flexShrink={1}>
            <Label3sm2 variant="medium">{currentStrategyLabel}</Label3sm2>
            <Label3sm2 color="$neutral400">
              {t('CHANGE_STRATEGY.COMPARISON.CURRENT_STRATEGY')}
            </Label3sm2>
          </YStack>
          <YStack flexShrink={1}>
            <Label3sm2 variant="medium">{newStrategyLabel}</Label3sm2>
            <Label3sm2 color="$neutral400">
              {t('CHANGE_STRATEGY.COMPARISON.NEW_STRATEGY')}
            </Label3sm2>
          </YStack>
        </XStack>
      </YStack>
    </XStack>
  );
};
