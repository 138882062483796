import {PrimaryGradientSvg} from '@/components/icons/PrimaryGradientSvg';
import {PropsWithChildren} from 'react';
import {GetProps, View, YStack, useMedia} from 'tamagui';

type PrimaryHeaderProps = {
  containerStyle?: GetProps<typeof YStack>;
  roundEdges?: boolean;
};

export function PrimaryHeader({
  children,
  containerStyle,
  roundEdges = false,
}: PropsWithChildren<PrimaryHeaderProps>) {
  const media = useMedia();
  return (
    <YStack overflow="hidden" borderRadius={media.sm ? '$0' : '$1'} {...containerStyle}>
      <PrimaryGradientSvg />
      {children}
      {roundEdges && (
        <View
          width="100%"
          height="$6"
          backgroundColor="$neutralBG"
          borderTopLeftRadius={24}
          borderTopRightRadius={24}
        />
      )}
    </YStack>
  );
}
