import {useMemo} from 'react';

import {useChartContext} from '../context';
import {degreesToRadians} from '../utils';

export const useLinesTransformProperties = () => {
  const {size, yAxis, xAxis, offsetData, removeHorizontalOffset, overlapYAxes, chartHeight} =
    useChartContext();

  const commonOffset = useMemo(() => offsetData.width / 2 + offsetData.pointSize / 2, [offsetData]);

  const translateY = useMemo(() => {
    const bottomXAxisOffset = xAxis?.bottom?.size?.height ?? 0;

    return size.height - bottomXAxisOffset - commonOffset;
  }, [size.height, xAxis, commonOffset]);

  const translateX = useMemo(() => {
    const leftYAxisOffset = overlapYAxes ? 0 : yAxis?.left?.size?.width ?? 0;
    let commonOffset = offsetData.width / 2;

    if (!removeHorizontalOffset) {
      commonOffset += offsetData.pointSize / 2;
    }

    return leftYAxisOffset + commonOffset;
  }, [yAxis, offsetData, removeHorizontalOffset, overlapYAxes]);

  const rotateX = useMemo(() => degreesToRadians(180), []);

  const lineTranslateY = useMemo(() => {
    if (chartHeight === undefined) return 0;

    return translateY - chartHeight - commonOffset;
  }, [translateY, chartHeight, commonOffset]);

  const gradientTranslateY = useMemo(() => {
    if (chartHeight === undefined) return 0;

    return -(size.height - chartHeight - commonOffset);
  }, [size.height, chartHeight, commonOffset]);

  return {
    translateX,
    translateY,
    rotateX,
    lineTranslateY,
    gradientTranslateY,
  };
};
