import {Heading2} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {FullScreenCenterView} from '@/components/views/FullScreenCenterView';
import {useTokenUserInfo} from '@/hooks/useTokenUserInfo';
import {InterviewContinueButton} from '@/modules/interview/components/InterviewContinueButton';
import {NativeLogoutButton} from '@/modules/navigation/components/header/NativeLogoutButton';
import {ProposalCard} from '@/modules/onboarding/components/ProposalCard';
import {getProposalFromTokenPayload} from '@/providers/session-provider/utils';
import {useAppDispatch} from '@/store';
import {saveEmployer, saveInitialDeposit, savePlanner} from '@/store/reducers/onboarding';
import {useTranslate} from '@tolgee/react';
import {useRouter} from 'expo-router';
import {useCallback} from 'react';
import {YStack} from 'tamagui';

export default function Proposal() {
  const {t} = useTranslate();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const userInfo = useTokenUserInfo();
  const proposal = getProposalFromTokenPayload(userInfo);

  const onContinue = useCallback(() => {
    if (proposal?.initialDepositAmount !== undefined)
      dispatch(
        saveInitialDeposit({method: 'DIRECT_DEBIT', amount: proposal?.initialDepositAmount})
      );
    dispatch(
      savePlanner({
        selectedStrategy: proposal?.initialInvestmentStrategy,
        initialDepositAmount: proposal?.initialDepositAmount,
        initialRecurringDepositAmount: proposal?.initialRecurringDepositAmount,
      })
    );
    if (proposal?.product === 'VL_ACCOUNT') {
      dispatch(
        saveEmployer({
          savingsRate: proposal?.initialRecurringDepositAmount,
        })
      );
    }
    router.navigate({
      pathname: '/onboarding/personal-details/name',
      params: {product: proposal?.product},
    });
  }, [dispatch, proposal, router]);

  return (
    <FullScreenCenterView>
      <YStack gap="$4" width="100%" maxWidth={500}>
        <Heading2>{t('PROPOSAL.TITLE')}</Heading2>
        <Paragraph2>{t('PROPOSAL.SUBTITLE')}</Paragraph2>
        <ProposalCard proposal={proposal} onContinue={onContinue} />
        <InterviewContinueButton
          testID="proposal-continue-button"
          onPress={onContinue}
          alignSelf="stretch"
        >
          {t('PROPOSAL.CONTINUE')}
        </InterviewContinueButton>
      </YStack>
      <NativeLogoutButton />
    </FullScreenCenterView>
  );
}
